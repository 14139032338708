import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import * as R from 'ramda';
import ScheduleCardDetails from './ScheduleCardDetails';
import { getMySchedule } from 'redux/actions/applicantDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '130vh',
    background: 'linear-gradient(172.7deg, #FBF5FA 0%, #F5F2F8 54.17%, #F4F8FD 100%)',
  },
  titleBasicDetail: {
    display: 'initial',
    ...theme.typography.h5,
    color: '#555555', // theme.palette.common.gray4,
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContent: {
    padding: '1.3rem 0.8rem 0rem 0.8rem',
  },
  card: {
    marginTop: '15px',
    borderRadius: 4,
    boxShadow: 'box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05)',
    cursor: 'pointer'
  },
  roundLabel: {
    alignSelf: 'baseline'
  },
  iconBtn: {
    background: '#fff',
    margin: '0 1rem',
  },
  linkbtn1: {
    border: '1px solid #e4e4e4',
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dae1fba3',
      border: '1px solid #6361CD',
    },
    '&:active': {
      backgroundColor: '#6361CD',
      color: '#ffffff',
    },
  },
  active: {
    backgroundColor: '#dae1fba3',
    border: '1px solid #6361CD',
  }
}));

const ScheduleCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const scheduleCardData = useSelector((state) => {
    return get(state, ['applicantDashboard', 'mySchedule']);
  });
  const [myInterviewSchedule, setMyInterviewSchedule] = useState([]);

  const processReferJob = () => {
    dispatch(getMySchedule());
  };

  useEffect(() => {
    processReferJob();
  }, []);

  useEffect(() => {
    if (isEmpty(myInterviewSchedule)) {
      setMyInterviewSchedule(scheduleCardData?.interviewDetails || []);
    }
  }, [scheduleCardData]);

  return (
    <Box >
      {isEmpty(myInterviewSchedule) &&
        <Card elevation={0}
          className={classes.card}>
          <CardContent className={clsx(classes.cardContent, classes.gridContainer)} style={{ height: 'auto', minHeight: '160px', maxHeight: '220px' }}>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12} md={12} lg={12} className={classes.gridContainer}>
                <Box>
                  <Box style={{ width: '100%' }} >
                    <Typography variant='subtitle1'>No Interviews Scheduled</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
      {
        R.map((scheduleDetails) => {
          return (
            <ScheduleCardDetails data={scheduleDetails} />
          );
        })(myInterviewSchedule)
      }
    </Box>
  );
};
export default ScheduleCard;