import React, { useState } from 'react';
import DPCalendar from 'view/common/DPCalendar/DPCalendar';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { isEmpty, startsWith } from 'lodash';
import { getFormatedDate } from 'view/common/utils/dateUtils';
import { useNavigate } from 'react-router-dom';

const ScheduleInterview = ({ currentTab, dispatcher, data, usedIn }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    if (!isEmpty(data)) {
      switch (usedIn) {
      case 'applicant':
        setEventsData(applicantCalendarJson(data));        
        break;
      default:
        setEventsData(interviewerCalendarJson(data));        
        break;
      }
    }
  }, [data]);

  const fetchRemoteData = (query) => {
    const { start, end } = query;
    const filter = {
      startDate: getFormatedDate(start),
      endDate: getFormatedDate(end)
    };
    dispatch(dispatcher(filter));
  };

  const decideCalendarCardColorByRound = (round) => {
    switch (round) {
    case 1:
      return '#27dbdb';
    case 2:
      return '#F1AE64';
    case 3:   
      return '#ff91c1';
    default:
      return '#fae28c';
    }
  };

  const handleNavigation = (eachEvent) => {
    if (startsWith(location.pathname, '/employer') ) {
      return navigate(`/employer/interviewer/${eachEvent.interviewDetail.id}`) ;
    } else if (startsWith(location.pathname, '/interviewer')) {
      navigate(`/interviewer/home/${eachEvent.interviewDetail.id}`);
    } else {
      return navigate(`/applicant/calendar/${eachEvent.interviewDetail.id}`);
    }
  };

  const interviewerCalendarJson = () => {
    return (data?.interviewDetails || []).map((eachEvent, index) => ({
      event_id: index,
      title: `${eachEvent?.applicantDetail?.firstName} ${eachEvent?.applicantDetail.lastName} ( ${eachEvent?.jobDetail?.position} )`,
      start: new Date(eachEvent?.interviewDetail?.startTime),
      end: new Date(eachEvent?.interviewDetail?.endTime),
      color: decideCalendarCardColorByRound(eachEvent?.interviewDetail?.roundNo),
      meetingLink: eachEvent?.interviewDetail?.interviewMeetingDetailsDTO?.interviewMeetingUrl,
      organiserName : eachEvent?.interviewDetail?.organiserName,
      candidateName: `${eachEvent?.applicantDetail?.firstName} ${eachEvent?.applicantDetail.lastName}`,
      interviewerName: eachEvent?.interviewDetail?.interviewerName,
      redirect: () => handleNavigation(eachEvent)
    }));
  };
  
  const applicantCalendarJson =() => {
    return (data?.interviewDetails || []).map((eachEvent, index) => ({
      event_id: index,
      title: `${eachEvent?.jobDetail?.position} (${eachEvent?.jobDetail?.companyName})`,
      start: new Date(eachEvent?.interviewDetail?.startTime),
      end: new Date(eachEvent?.interviewDetail?.endTime),
      color: decideCalendarCardColorByRound(eachEvent?.interviewDetail?.roundNo),
      meetingLink: eachEvent?.interviewDetail?.interviewMeetingDetailsDTO?.interviewMeetingUrl,
      organiserName : eachEvent?.interviewDetail?.organiserName,
      candidateName: `${eachEvent?.applicantDetail?.firstName} ${eachEvent?.applicantDetail.lastName}`,
      interviewerName: eachEvent?.interviewDetail?.interviewerName,
      redirect: () => handleNavigation(eachEvent) 
    }));
  };

  return (
    <DPCalendar 
      key={currentTab} 
      events={eventsData} 
      CustomEditor={null} 
      fetchRemoteData={fetchRemoteData} 
      usedIn="schedule-interview" 
      startTime={8} 
    /> 
  );
};

export default ScheduleInterview;
