import React, { useState } from 'react';
import ConfirmDialog from '../ConfirmDialog';

export default function DPTermsNConditions({ openTnC=false, setOpenTnC }) {
  const [isOpen, setIsOpen] = useState(openTnC);

  const setConfirmDialog = () => {
    setIsOpen(false);
    setOpenTnC(false);
  };
  
  return (
    <ConfirmDialog 
      isOpen={isOpen}
      setConfirmDialog={setConfirmDialog}
      title="Terms and Conditions"
      onConfirm={setConfirmDialog}
      confirmText='OK'
      hideCancel
    >
      <div className='tnc-container'>
        <iframe className='tnc-content' src={'https://docs.google.com/gview?url=http://127.0.0.1:8080/T&C.docx&embedded=true'}
          width='1366px' height='623px' frameborder='0'></iframe>
      </div>
    </ConfirmDialog>
  );
}
