import * as React from 'react';
import { Box, Button, InputLabel, TextField, Grid, Typography, Divider } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Compatability, ExperienceMap, Location, PostedOn, SalaryRange } from 'mockData/RecommendedJobsData';
import DPSlider from 'view/common/DPSlider';

const useStyle = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  cardContainer: {
    margin: '1rem 2rem',
  },
  spanText: {
    color: '#000',
    paddingLeft: '0.5rem'
  }
}));


const Filter = ({ onClose, applyFilter }) => {
  const classes = useStyle();
  const [filterObj, setfilterObj] = React.useState({});


  const handleChange = (newValue, label ) => {
    const __filterObj = { ...filterObj,
      [label]: newValue
    };
    
    setfilterObj(__filterObj);
  };

  const handleSelect = (e, newValue, state) => {
    const __filterObj = { ...filterObj,
      [state]: newValue?.label
    };
    setfilterObj(__filterObj);
  };

  

  return (
    <Box className={classes.cardContainer} >

      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={0}>
          <Typography variant="h4">Filters</Typography>
        </Grid>

        <Grid item>
          <Button variant="text" color="secondary">Clear All</Button>
        </Grid>
      </Grid>
      <Divider />
      <br />

      <Grid container spacing={6} className={classes.gridContainer} >
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Experience 
            <span className={classes.spanText}>(4yrs-6+yrs)</span>
          </Typography>
          <DPSlider
            label={'Experience'}
            callback={handleChange}
            marks={ExperienceMap}
            color="secondary" />
        </Grid>

        <Grid item xs={12} md={6} >
          <Typography variant="subtitle1">Salary
            <span className={classes.spanText}>(10Lakhs-12Lakhs)</span>
          </Typography>
          <DPSlider
            label={'Salary'}
            callback={handleChange}
            marks={SalaryRange}
            color="secondary" />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Compatability
            <span className={classes.spanText}>(70%-100%)</span>
          </Typography>
          <DPSlider
            label={'Compatability'}
            callback={handleChange}
            marks={Compatability}
            color="secondary" />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ padding: '2rem 0' }}>
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            options={Location}
            onChange={(e, newValue)=>handleSelect(e, newValue, 'location')}
            renderInput={
              params => 
                <>
                  <InputLabel>Location</InputLabel>
                  <TextField variant="outlined" {...params} placeholder="Location" />
                </>
            } />
        </Grid>

        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            options={PostedOn}
            onChange={(e, newValue)=>handleSelect(2, newValue, 'posted')}
            renderInput={
              params => 
                <>
                  <InputLabel>Posted</InputLabel>
                  <TextField variant="outlined" {...params} placeholder="Posted" />
                </>
            } />
        </Grid>
      </Grid>
      <Grid container style={{ textAlign: 'end' }}>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={onClose} color="secondary" style={{ marginRight: '1rem' }}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={()=>applyFilter(filterObj)}>Apply</Button>
        </Grid>
      </Grid>   
    </Box>
  );
};

export default Filter;