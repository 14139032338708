import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import propTypes from 'prop-types';

const styles = () => ({
  default: {
    textTransform: 'capitalize',
    fontStyle: 'normal',
    fontWeight: 400,
    background: '#FF7100',
    color: '#fff',
    padding: '12px 30px',
    borderRadius: '4px',
    fontSize: '14px',
    letterSpacing: '0.02em',
    '&:hover': {
      background: '#CE5B00'
    }
  }
});

const CustomBtn = ({ children, classes, variant }) => {
  return (
    <Button variant={variant} className={classes.default}>
      {children}
    </Button>
  );
};

CustomBtn.propTypes = {
  color: propTypes.string
};

export default withStyles(styles)(CustomBtn);
