export const learningCardData = [
  {
    'CourseTitle': 'Javascript Intermediate level 1 - Mastering the DOM',
    'Description': 'Learn to create really nice functionalies by learning the DOM in Javascript',
    'Rating': '4.9',
    'Duration': '3 total hours',
    'NoOfLectures': '31 lectures',
    'CourseURL': 'https://www.udemy.com/course/javascript-intermediate-mastering-the-dom/',
    'DiscountedPrice': '₹385',
    'Price': '₹6,400'
  }
];

export const learningCardRecord = [
  {
    id: 'First',
    title: 'Learn to Design Enaging User Experiences'
  },
  {
    id: 'Second',
    title: 'UX Fundamentals Learning : User Testing'
  },
  {
    id: 'Third',
    title: 'Psychology of Interaction Design : The Ultimate Guide'
  },
  {
    id: 'Fourth',
    title: 'Learn How to Build Habit - Forming Products'
  },
];