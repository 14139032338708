import React from 'react';
import DPTable from 'view/common/DPTable';

export default function ViewLocation(props) {
  let rowsData = [];

  const columns = [
    { id: 'code', label: 'S.No.', minWidth: 30 },
    { id: 'companyName', label: 'Company Name', minWidth: 100 },
    {
      id: 'address',
      label: 'Address',
      minWidth: 120,
    },
    {
      id: 'action',
      label: 'Actions',
      minWidth: 120,
      align: 'center',
      format: value => value.toFixed(2),
      cellRenderer: 'actionCellRenderer'
    },
  ];

  if (props.data !== null) {
    props.data.forEach((locationDetail, index) => {
      let rowData = {
        id: locationDetail.id,
        code: index + 1,
        companyName: locationDetail ? locationDetail.companyName: '',
        address: [
          locationDetail.companyAddress,
          locationDetail.city,
          locationDetail.zipcode,
          locationDetail.state,
          locationDetail.country,
        ].join(', '),
      };
      rowsData.push(rowData);
    });
  }


  return (
    <DPTable
      {...props}
      columns={columns}
      rowsData={rowsData}
      setPageNumber={props.setPageNumber}
      setPageSize={props.setPageSize} />
  );
}
