export const mentorsTab= [
  {
    id: 'suggestedmentors',
    label: 'Suggested Mentors',
  },
  {
    id: 'mymentors',
    label: 'My Mentors',
  }
];

export const calendarTab= [
  {
    id: 'confirmslot',
    label: 'Confirm your slot',
  },
  {
    id: 'scheduleinterview',
    label: 'Scheduled Interview',
  }
];

export const scheduleFilterTab= [
  {
    id: 'today',
    label: 'Today',
  },
  {
    id: 'month',
    label: 'Month',
  },
  {
    id: 'week',
    label: 'Week',
  }
];