import {
  Box,
  FormControl,
  Grid,
  Typography,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Pagination, PaginationItem } from '@mui/material';
import { get, isEmpty, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePageSize } from 'redux/slices/employerSlices/userManagement';
import UserCard from './UserCard';
import { userManagementPageSizeOptions } from 'constants/employer/userManagement';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'view/employer/route/Constants';

const PaginatedUserList = ({
  dataPath,
  pageOptionsPath,
  dispatcher,
  userType,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listData = useSelector(state => get(state, dataPath, {}));
  const defaultPageSize = useSelector(state => get(state, [...pageOptionsPath, 'pageSize'], 10));
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [page, setPage] = useState(1);

  const onPageChange = (e, page) => {
    setPage(page);
    dispatch(dispatcher({
      pageNo: page - 1,
      pageSize,
      search: ''
    }));
  };

  const onPageSizeChange = (e) => {
    setPageSize(e.target.value);
    dispatch(updatePageSize(e.target.value));
    setPage(1);
    dispatch(dispatcher({
      pageNo: 0,
      pageSize: e.target.value,
      search: ''
    }));
  };

  useEffect(() => {
    dispatch(dispatcher({
      pageNo: page - 1,
      pageSize,
      search: ''
    }));
  }, [userType, pageSize]);

  const onUserClick = (userData) => {
    navigate(`${RoutePath.userDetails}/${userType}/${userData.id}`, {
      state: {
        userData
      }
    });
  };

  if (isEmpty(listData.content)) {
    return (
      <Box style={{ padding: '5rem', textAlign: 'center' }}>No data</Box>
    );
  }
  return (
    <Box>
      {
        map(get(listData, 'content', []), (item) => {
          return <UserCard
            key={item.id}
            data={item}
            userType={userType}
            onCardClick={onUserClick}
          />;
        })
      }
      <Grid container spacing={0} style={{ marginTop: '1rem', marginBottom: '1rem', display: 'flex', alignItems: 'baseline' }}>
        <Grid item xs={12} md={3} style={{ marginTop: '1rem', display: 'flex' }}>
          <Typography variant="subtitle2" style={{ alignSelf: 'center', marginRight: '1rem' }}>Show</Typography>
          <FormControl>
            <Select
              disableUnderline
              style={{ border: '1px solid black', padding: '2px 20px 2px 11px', cursor: 'pointer' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pageSize}
              onChange={onPageSizeChange}
              defaultValue={pageSize}
            >
              {
                map(userManagementPageSizeOptions, item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={9} style={{ justifyContent: 'end', display: 'flex' }}>
          <Pagination
            sx={{ color: '#6360CD' }}
            count={get(listData, ['totalPages'], 0)}
            onChange={onPageChange}
            page={page}
            renderItem={(item) => {
              return <PaginationItem
                components={{ first: 'first', last: 'last' }}
                {...item}
              />;
            }}
          /></Grid>
      </Grid>
    </Box>
  );
};

export default PaginatedUserList;