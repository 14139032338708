import { get, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onUserProfileDetails } from 'redux/actions/userAuthentication';
import Routes from './route/Routes';

const Applicant = () => {
  const profileData = useSelector(state => get(state, ['userProfile', 'applicant'], ''));
  const dispatch = useDispatch();

  useEffect(async() => {
    if (isEmpty(profileData)){
      await dispatch(onUserProfileDetails()); // To get profile details on page refresh
    }
  }, []);
  return <Routes />;
};

export default Applicant;
