import React, { useState, useEffect } from 'react';
import { Box, InputLabel, Grid, TextField } from '@material-ui/core';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { FORM_CARD_TYPE } from 'view/common/utils/formUtils';
import { isEmpty } from 'lodash';

const StartEndDatePicker = ({
  formik,
  showEndDate = true,
  setIsError,
  formType = '',
}) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [endDateMsg, setEndDateMsg] = useState('');
  const [isRequired, setIsRequired] = useState({});

  const handleChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    if ([FORM_CARD_TYPE.educationDetails, FORM_CARD_TYPE.professionalDetails].includes(formType)) {
      setIsRequired({ required: 'required' });
    }
  }, [formType]);


  useEffect(() => {
    setErrorMsg('');
    setEndDateMsg('');
    setIsError(false);
    if (showEndDate && !formik.values.endDate && [
      FORM_CARD_TYPE.educationDetails, FORM_CARD_TYPE.professionalDetails].includes(formType)) {
      setEndDateMsg('This field is required');
      setIsError(true);
    }

    if (!moment(formik.values.endDate).isAfter(formik.values.startDate) && (formik?.values?.endDate && formik?.values?.startDate)) {
      setErrorMsg('Start date should be less than end date');
      setIsError(true);
    }

    if (!moment(moment().add(1, 'days').format('YYYY-MM-DD')).isAfter(formik.values.endDate) && ((showEndDate && formik?.values?.endDate) && formik?.values?.startDate)) {
      setEndDateMsg('End date should not be greater than the todays date');
      setIsError(true);
    }

    if (!moment(moment().add(1, 'days').format('YYYY-MM-DD')).isAfter(formik.values.startDate) && (formik?.values?.startDate)) {
      setErrorMsg('Start date should not be greater than the todays date');
      setIsError(true);
    }

  }, [formik.values.startDate, formik.values.endDate, showEndDate]);

  return (
    <Box>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12} md={6}>
          <InputLabel htmlFor="TextField-input" {...isRequired}>Start Date</InputLabel>
          <TextField
            variant="outlined"
            id="startDate"
            name="startDate"
            placeholder={'Enter Start Date'}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.startDate}
            onChange={handleChange}
            fullWidth
            inputProps={{
              max: moment().format('YYYY-MM-DD'),
            }}
            helperText={formik.errors.startDate}
          />
          {
            errorMsg && (formik.touched.startDate) && (
              <Typography style={{ color: 'red', fontSize: '0.75rem', 'fontWeight': '400' }}>{errorMsg}</Typography>
            )
          }
        </Grid>
        {
          showEndDate && (
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="TextField-input" {...isRequired}>End Date</InputLabel>
              <TextField
                variant="outlined"
                id="endDate"
                name="endDate"
                placeholder={'Enter End Date'}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={formik.values.endDate}
                onChange={handleChange}
                inputProps={{
                  max: moment().format('YYYY-MM-DD'),
                }}
                disabled={isEmpty(formik.values.startDate)}
                helperText={formik.errors.endDate}
              />
              {
                endDateMsg && (formik.touched.endDate) && (
                  <Typography style={{ color: 'red', fontSize: '0.75rem', 'fontWeight': '400' }}>{endDateMsg}</Typography>
                )
              }
            </Grid>
          )
        }
      </Grid>

    </Box>

  );
};

export default StartEndDatePicker;