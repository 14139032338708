import React, { useEffect, useState } from 'react';
// import { workingHours } from '../Components/List';
import { InputLabel, Grid, Typography, Button, TextField, FormControlLabel, FormControl, RadioGroup, FormLabel, Radio, MenuItem, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Tootip from 'asset/ToolTip.svg';

import { FormikSelectField } from '../../../../ui-components';
import { default as MaterialUiSelectField } from '@material-ui/core/Select';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
// import { YupValidator } from 'utils/YupValidator';
// import { emailRegExp } from 'view/common/utils/formUtils';
import CandidateInfo from './CandidateInfo';
import { useDispatch } from 'react-redux';
import { onGetAllJobs } from 'redux/actions/employerActions/employerDashboard';
import { onGetCandidateInfo } from 'redux/actions/employerActions/interviewScheduled';
// import { onGetJobEmployeeParticipant } from 'redux/actions/employerActions/JobPosting/jobPostingActions';
import { onSetSchedule } from 'redux/actions/interviewerActions/interviewerSchedule';
import { useSelector } from 'react-redux';

import timezoneList from './timezone.js';
import { sample } from 'lodash';
// import { compact, includes, sample } from 'lodash';
// import AutocompleteSelect from 'view/ui-components/atom/AutocompleteSelect';


const isScheduleEnabled = true;
const useStyles = makeStyles(theme => ({
  formContainer: {
    backgroundColor: 'white',
    padding: '2em',
    borderRadius: 4,
  },
  fieldWidth: {
    width: '260px',
    height: '44px',
  },
  disabled: {
    '&$disabled': {
      textAlign: 'center',
    },
  },
  counts: {
    display: 'flex',
    gap: '10px',
    cursor: 'pointer',
  },
  jobTitleSection: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  inputLabel: {
    color: '#000',
    marginBottom: '0.5rem',
  },
  infoContainer: {
    paddingTop: '1em',
    paddingLeft: '1em',
    paddingBottom: '1em',
    width: '68%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '1em',
      paddingLeft: '1em',
      paddingBottom: '1em',
    },
  },
  enabledForm: {
    opacity: '1',
  },
  disabledForm: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  button: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '10px',
    width: 'max-content'
  },
  formView: {
    width: '68%',
    marginTop: '10px'
  },
  interviewerButton: {
    marginBottom: '10px'
  },
  formTitle: {
    padding: '10px 0px',
  }
}));


const ScheduleForm = () => {
  const classes = useStyles();
  const [enableForm, setEnableForm] = useState(true);
  const [candidateView, setCandidateView] = useState(false);
 
  const [candidateBoxInfo, setCandidateBoxInfo] = useState();
  const [applicationJobId, setApplicationJobId] = useState();

  const navigate = useNavigate();
  const [jobProfiles, setJobProfiles] = useState();
  const [candidateInfo, setCandidateInfo] = useState([]);
  // const [interviewerName, setInterviewerName] = useState([]);
  const [interviewType, setInterviewType] = useState([]);

  const { t: translate } = useTranslation();

  const slotDetails = useSelector(state => state.employerInterviewManagement.selectedInterviewDetails);

  if (slotDetails === null){
    navigate('/employer/calendar');
  }
  const [candidateId, setCandidateId] = useState(slotDetails.jobId);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetAllJobs({ pageNo: 0, pageSize: 12 })).then(async (response) => {
      if (response && response.payload) {
        const jobProfileList = response.payload?.content?.map(eachProfile => ({
          profileId: eachProfile.id,
          id: eachProfile.jobTitle?.title,
          name: eachProfile.jobTitle?.title,
        }));
        setJobProfiles(jobProfileList);
      }
    });
  }, []);

  useEffect(() => {
    /**
     * Api call to set candidate list 
     * */
    if (candidateId) {
      dispatch(onGetCandidateInfo({ id: candidateId })).then(async (response) => {
        if (response && response.payload) {
          const candidateInfoList = (response.payload?.content || []).map(eachCandidate => ({
            ...eachCandidate,
            name: `${eachCandidate?.applicant?.firstName} ${eachCandidate?.applicant?.lastName}`,
            id: `${eachCandidate?.candidateId}`,
            applicationJobId: `${eachCandidate?.id}`
          }));
         
          setCandidateInfo(candidateInfoList);
          let selectedData = candidateInfoList.find(eachCandidate => eachCandidate.id == slotDetails.applicants[0].applicantId);
          setCandidateBoxInfo(selectedData);
          setApplicationJobId(selectedData?.applicationJobId);
        }
      });
      setCandidateView(true);

      /**
      * Api call to set Interviewer list 
      * */
      // dispatch(onGetJobEmployeeParticipant({ id: candidateId })).then(async (response) => {
      //   if (response && response.payload) {
      //     const interviewerInfoList = (response.payload?.data?.content || []).map(eachInterviewer => ({
      //       name: `${eachInterviewer?.name}`,
      //       id: `${eachInterviewer?.name}`,
      //       interviewerId: `${eachInterviewer?.employeeId}`,
      //       email: `${eachInterviewer?.email}`,
      //     }));
      //     // setInterviewerName(interviewerInfoList);
      //   }
      // });
    }
  }, [candidateId]);

  /*
  TODO : pass values and actions param, call api to schedule
*/
  const handleSubmit = async (values, actions) => {
    actions.setTouched({});
    const prepareRequest = {
      // 'interviewerIds': values?.interviewerDetails?.map(eachInterviewer => eachInterviewer?.interviewerId),
      'slotId': slotDetails?.id,
      'interviewerIds': [slotDetails?.interviewers?.[0]?.interviewerId],
      'jobApplicationId': applicationJobId,
      'startTime': `${values.date}T${values.startTime}:00Z`,
      'endTime': `${values.date}T${values.endTime}:00Z`,
      'interviewMode': values.interviewMode,
      'interviewType': values.interviewType,
      'messageMode': values.messageMode,
      'timezone': values.timeZone,
      'hostEmail': 'yashrandhe@yopmail.com', /* TODO : Replace from api source */
      'round': sample([1, 2, 3]), /* TODO : Replace from api source ,Currently selects random number*/
    };
    dispatch(onSetSchedule(prepareRequest)).then(async (response) => {
      if (response && response.payload) {
        navigate('/employer/calendar');
      }
    });
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // const timeNow = new Date().toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric' });
  // const workingHoursFromNow = (workingHours || []).filter(eachTimeSlot => eachTimeSlot.name >= timeNow);
  // const workingHoursFromNowSlot = (workingHours || []).filter(eachTimeSlot => eachTimeSlot.name > workingHoursFromNow[0]?.name);

  const validationSchema = yup.object({
    jobProfile: yup.string().required('Job Profile is Required'),
    candidate: yup.string().required('Candidate is Required'),
    timeZone: yup.string().required('Time Zone is Required'),
    date: yup.date().required('Date is Required').min(today, ({ min }) => `Date cannot be a date before ${(min)}`),
    // startTime: yup.string()
    //   .test('Time should be greater than passed time','Please select proper start time', function (value) {
    //     return moment(value, 'HH:mm').isSameOrAfter(moment(timeNow, 'HH:mm'));
    //   }).required('Please select start time'),
    // endTime: yup.string()
    //   .test('End time should be greater','Please select proper end time', function (value) {
    //     const currentFields = this.parent;
    //     return moment(value, 'HH:mm').isAfter(moment(currentFields?.startTime, 'HH:mm'));
    //   }).required('Please select end Time'),
    interviewMode: yup.string().required('Interview Mode is Required'),
    interviewType: yup.string().required('Interview Type is Required'),
    messageMode: yup.string().required('Message Mode is Required'),
    // interviewerDetails: yup
    //   .array()
    //   .test('interviewerDetails', 'Please add interviewer', val => val && val.length > 0 && val.find(interviewer => interviewer && interviewer.name)).required('Please add interviewer')
  });

  const cancelSchedule = (formik) => {
    formik.resetForm();
    navigate('/employer/calendar');
  };
  const candidateInformation = {
    name: `${candidateBoxInfo?.applicant?.firstName} ${candidateBoxInfo?.applicant?.lastName}`,
    profile: candidateBoxInfo?.jobDetail?.companyJobTitle?.title,
    experience: candidateBoxInfo?.jobDetail?.experienceMax,
    salary: `${candidateBoxInfo?.jobDetail?.salaryMin} - ${candidateBoxInfo?.jobDetail?.salaryMax}`,
    round: candidateBoxInfo?.round
  };
  const onlineList = [{
    name: 'Video Call',
    id: 'Video Call'
  }, {
    name: 'Audio Call',
    id: 'Audio Call'
  }];

  const decideInterviewModetype = (typeMode) => {

    if (typeMode == 'Online') {
      setInterviewType(onlineList);
    } else {
      setInterviewType([{
        name: 'In Person',
        id: 'In Person'
      }]);
    }

  };

  const interviewModeOptions = ['Online', 'Offline'];
  const messageModeOptions = ['On SMS', 'on Email'];

  const initialValues = {
    jobProfile: slotDetails.jobId,
    candidate: slotDetails.applicants[0].applicantId,
    date: moment(slotDetails.startTime).format('YYYY-MM-DD'),
    timeZone: timezoneList[98]?.id, /* NOTE : 98 id stores default timezone to IST */
    // startTime: workingHoursFromNow[0]?.name,
    // endTime: workingHoursFromNowSlot[0]?.name,
    startTime: moment(slotDetails.startTime).format('HH:mm'),
    endTime: moment(slotDetails.endTime).format('HH:mm'),
    // interviewerDetails: [{
    //   name: '',
    //   id: '',
    // }]
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form>
          <Grid container className={classes.formContainer}>
            <Grid item>
              <Typography variant="h5">{translate('DHIYO.JOBPOSTING_ADD.FORM-01.HEADER.BASIC')}</Typography>
              <Typography variant="subtitle1">{translate('DHIYO.JOBPOSTING_ADD.FORM-01.HEADER.BASIC_DETAILS')}</Typography>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '1rem' }}>
              <Grid item className={classes.formView}>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Grid item xs={12} md={6}>

                    <>
                      <div style={{ display: 'flex' }}>
                        <InputLabel required>Job Profile</InputLabel>
                        <img src={Tootip} />
                      </div>
                      <FormControl
                        variant="outlined"
                        name="jobProfile"
                        error={formik.errors.jobProfile}
                        fullWidth>
                        <MaterialUiSelectField
                          variant="outlined"
                          fullWidth
                          multiple={false}
                          value={formik.values.jobProfile}
                          placeholder={'Select Job Profile'}
                          disabled
                          onChange={(event) => {
                            setCandidateId(event.target.value);
                            setCandidateView(false);
                            formik.setFieldValue('jobProfile', event.target.value);
                          }}
                        >
                          {jobProfiles && jobProfiles.map((code, index) => (
                            <MenuItem automation-testid="dropdownOptionsMenu" key={index} value={code.profileId}>
                              {code['name']}
                            </MenuItem>
                          ))}
                        </MaterialUiSelectField>
                        <FormHelperText>{formik.errors.jobProfile} </FormHelperText>
                      </FormControl>
                    </>

                  </Grid>
                  <Grid item xs={12} md={6}>
                    {candidateInfo && <>
                      <div>
                        <InputLabel required>Candidate</InputLabel>
                      </div>
                      <FormControl
                        variant="outlined"
                        name="candidate"
                        error={formik.errors.candidate}
                        fullWidth>
                        <MaterialUiSelectField
                          variant="outlined"
                          fullWidth
                          multiple={false}
                          value={formik.values.candidate}
                          // disabled={candidateInfo.length == 0}
                          disabled
                          placeholder={'Select Candidate'}
                          onChange={(event) => {
                            let selectedData = candidateInfo.find(eachCandidate => eachCandidate.name == event.target.value);
                            setCandidateView(true);
                            setApplicationJobId(selectedData?.applicationJobId);
                            formik.setFieldValue('candidate', event.target.value);
                            setCandidateBoxInfo(selectedData);
                          }}
                        >
                          {candidateInfo.map((code, index) => (
                            <MenuItem automation-testid="dropdownOptionsMenu" key={index} value={code.id}>
                              {code['name']}
                            </MenuItem>
                          ))}
                        </MaterialUiSelectField>
                        <FormHelperText>{formik.errors && formik.errors.candidate} </FormHelperText>
                      </FormControl>
                    </>}
                  </Grid>
                </div>
              </Grid>
              {candidateView && <CandidateInfo
                enableForm={enableForm}
                setEnableForm={setEnableForm}
                candidateInformation={candidateInformation}
              />}
            </Grid>

            <Grid container>
              <Grid item className={classes.formView}>
                <Typography variant="h6" className={classes.formTitle}>
                  Fill below details to continue scheduling
                </Typography>
                <Grid container
                  alignItems="center"
                  spacing={2} className={enableForm ? classes.enabledForm : classes.disabledForm}>
                  <Grid item xs={12} md={6}>
                    <InputLabel required>Interviewer Name</InputLabel>
                    <TextField automation-testid="interviewerEmail" 
                      // value={interviewer?.email || ''}
                      value={slotDetails.interviewers[0]?.name || ''}
                      disabled fullWidth 
                      variant="outlined" name="openPositions" type="text" placeholder="Enter Email Id" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Interviewer Email</InputLabel>
                    <TextField automation-testid="interviewerEmail" 
                      value={slotDetails.interviewers[0]?.interviewerEmail || ''}
                      disabled fullWidth 
                      variant="outlined" name="openPositions" type="text" placeholder="Enter Email Id" />
                  </Grid>
                  {/* <FieldArray name="interviewerDetails">
                    {({ push, form }) => {
                      let interviewerDetails = compact(form.values.interviewerDetails);
                      const selectedInterviewersList = compact(form.values.interviewerDetails);
                      const selectedInterviewerIds = (selectedInterviewersList || []).map(detail => detail.id);
                      const unselectedInterviewer = (interviewerName || []).filter(intDetails => !includes(selectedInterviewerIds, intDetails.id));
                      return (
                        <Grid item container alignItems="center">
                          {(interviewerDetails || []).map((interviewer, index) => {
                            const name = `interviewerDetails[${index}]`;
                            return interviewer && !interviewer.deleted && (
                              <Grid
                                item
                                container
                                alignItems="center"
                                spacing={2}
                              >

                                <Grid item xs={12} md={6}>
                                  <InputLabel required>Interviewer Name</InputLabel>
                                  <AutocompleteSelect
                                    dropDownData={unselectedInterviewer}
                                    label={'Select Interviewer Name'}
                                    name={name}
                                    // displayValue={interviewer?.name || ''}
                                    displayValue={slotDetails.interviewers[0]?.name || ''}
                                    id={name}
                                    metaError="Interviewer is required" />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <InputLabel>Interviewer Email</InputLabel>
                                  <TextField automation-testid="interviewerEmail" 
                                    // value={interviewer?.email || ''}
                                    value={slotDetails.interviewers[0]?.interviewerEmail || ''}
                                    disabled fullWidth 
                                    variant="outlined" name="openPositions" type="text" placeholder="Enter Email Id" />
                                </Grid>

                              </Grid>
                            );
                          })}
                          <Grid item container><FormHelperText>{formik.values.interviewerDetails.filter(val => val == null).length > 0 && unselectedInterviewer.length == interviewerName?.length ? 'Please select Interviewer' : ''}</FormHelperText></Grid>
                          <Grid item>
                            <Button
                              size="small"
                              className={classes.interviewerButton}
                              type="button"
                              disableRipple
                              color="secondary"
                              disabled={unselectedInterviewer.length == 0}
                              onClick={() =>
                                push({
                                  id: '',
                                  name: '',
                                })
                              }>
                              Add More Interviewer
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    }}
                  </FieldArray> */}

                  <Grid item>
                    <div style={{ display: 'flex', gap: '16px' }}>
                      <Grid item md={3}>
                        <InputLabel htmlFor="TextField-input">Date</InputLabel>
                        <TextField
                          variant="outlined"
                          id="date"
                          name={'date'}
                          placeholder='Enter date'
                          type="date"
                          value={moment(formik.values.date).format('YYYY-MM-DD')}
                          error={formik.errors && formik.errors?.date}
                          fullWidth
                          disabled
                          onChange={(event) => {
                            formik.setFieldValue('date', event.target.value);
                          }}
                          inputProps={{
                            min: moment(today).format('YYYY-MM-DD'),
                          }}
                        />
                        <FormHelperText>{formik.errors && formik.errors?.date} </FormHelperText>

                      </Grid>
                      {timezoneList && timezoneList.length > 0 && <Grid item>
                        <InputLabel htmlFor="TextField-input">Time Zone</InputLabel>
                        <FormikSelectField
                          error={formik.errors && formik.errors?.timeZone}
                          dropDownData={timezoneList}
                          className={classes.fieldWidth}
                          label="TimeZone"
                          id="timeZone"
                          disabled
                          value={formik.values.timeZone}
                          name={'timeZone'} />
                        <FormHelperText>{formik.errors && formik.errors?.timeZone} </FormHelperText>

                      </Grid>}
                      {/* {workingHoursFromNow && workingHoursFromNow.length > 0 && */
                        <Grid item md={3}> 
                          <InputLabel htmlFor="TextField-input">Time (From)</InputLabel>
                          {/* <FormikSelectField
                          error={formik.errors && formik.errors?.startTime}
                          dropDownData={workingHoursFromNow}
                          id="startTime"
                          value={formik.values.startTime}
                          placeholder='Enter time(from)'
                          name={'startTime'}
                          fullWidth
                        /> */}
                          <TextField
                            variant="outlined"
                            id="startTime"
                            name={'startTime'}
                            placeholder='Enter time(from)'
                            value={formik.values.startTime}
                            error={formik.errors && formik.errors?.startTime}
                          />
                        </Grid>}

                      <Grid item md={3}>
                        <InputLabel htmlFor="TextField-input">Time (To)</InputLabel>
                        {/* <FormikSelectField
                          error={formik.errors && formik.errors?.endTime}
                          dropDownData={workingHoursFromNowSlot}
                          id="endTime"
                          value={formik.values.endTime}
                          placeholder='Enter time(to)'
                          name={'endTime'}
                          fullWidth
                        /> */}
                        <TextField
                          variant="outlined"
                          id="endTime"
                          name={'endTime'}
                          placeholder='Enter time(from)'
                          value={formik.values.endTime}
                          error={formik.errors && formik.errors?.startTime}
                        />
                      </Grid>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">Interview Mode</FormLabel>
                      <RadioGroup
                        row
                        style={{ display: 'flex', gap: '16px' }}
                        name={'interviewMode'}
                        value={formik.values.interviewMode}
                        error={formik.errors && formik.errors?.interviewMode}
                        onChange={(event) => {
                          decideInterviewModetype(event.currentTarget.value);
                          formik.setFieldValue('interviewMode', event.currentTarget.value);
                        }}
                      >
                        {interviewModeOptions.map(option => (
                          <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio />}
                            label={option}
                          />
                        ))}
                      </RadioGroup>
                      <FormHelperText>{formik.errors && formik.errors?.interviewMode} </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: 'flex', gap: '16px' }}>
                      <Grid item xs={12} md={6}>

                        <>
                          <div className={classes.jobTitleSection}>
                            <InputLabel>Interview Type</InputLabel>
                          </div>
                          <FormControl
                            variant="outlined"

                            fullWidth>
                            <MaterialUiSelectField
                              variant="outlined"
                              fullWidth
                              multiple={false}
                              disabled={!formik.values.interviewMode}
                              value={formik.values.interviewType}
                              name={'interviewType'}
                              error={formik.error && formik.errors?.interviewType}
                              onChange={(event) => {
                                formik.setFieldValue('interviewType', event.target.value);
                              }}
                            >
                              {interviewType && interviewType.map((code, index) => (
                                <MenuItem automation-testid="dropdownOptionsMenu" key={index} value={code.id}>
                                  {code['name']}
                                </MenuItem>
                              ))}
                            </MaterialUiSelectField>
                            <FormHelperText>{formik.errors && formik.errors?.interviewType} </FormHelperText>
                          </FormControl>
                        </>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel id="radio-buttons-group-label">Send Message to Candidates</FormLabel>
                        <RadioGroup
                          row
                          style={{ display: 'flex', gap: '16px' }}
                          name={'messageMode'}
                          value={formik.values.messageMode}
                          error={formik.errors && formik.errors?.messageMode}
                          onChange={(event) => {
                            formik.setFieldValue('messageMode', event.currentTarget.value);
                          }}
                        >
                          {messageModeOptions.map(option => (
                            <FormControlLabel
                              key={option}
                              value={option}
                              control={<Radio />}
                              label={option}
                            />
                          ))}
                        </RadioGroup>
                        <FormHelperText>{formik.errors && formik.errors?.messageMode}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                </Grid>

              </Grid>

              <Grid item xs={12}>
                <hr style={{ border: '0.5px solid #ddd' }} />
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Grid item xs={12} md={2}>

                    <>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={!isScheduleEnabled}
                        color="secondary">
                        Schedule
                      </Button>
                    </>
                  </Grid>
                  <Grid item xs={12} md={2}>

                    <>
                      <Button variant="outlined" onClick={() => cancelSchedule(formik)}>Cancel</Button>
                    </>
                  </Grid>
                </div>
              </Grid>

            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ScheduleForm;