import * as React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { formatEmptyValues } from 'utils/valueFormatter';

const useStyle = makeStyles(() => ({
  type: {
    color: '#797D89',
    fontWeight: 500,
  },
}));

const JobDetails = ({ formData }) => {
  const classes = useStyle();
  return (
    <>
      <Box sx={{
        maxWidth: '100%'
      }}>
        <Box sx={{ margin: '1rem 0' }}>
          <Typography className={classes.type}>
            Headline
          </Typography>
          <Typography>
            {formatEmptyValues(formData, 'headline', '-')}
          </Typography>
        </Box>
        <Box sx={{ margin: '1rem 0' }}>
          <Typography className={classes.type}>
            Summary
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            {formatEmptyValues(formData, 'summary', '-')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default JobDetails;



































