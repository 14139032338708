import React, { useState, useEffect } from 'react';
import { Container, Grid, TextField, InputLabel } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import moment from 'moment';

const EducationDetails = ({ formik, id, setIsError }) => {
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setErrorMsg('');
    setIsError(false);
    if (!moment(formik?.values?.endYear).isAfter(formik?.values?.startYear) && (formik?.values?.endYear && formik?.values?.startYear)){
      setErrorMsg('Start date should be less than end date');
      setIsError(true);
    } 

    if (!moment(moment().add(1, 'months').format('YYYY-MM')).isAfter(formik?.values?.endYear) && (formik?.values?.endYear && formik?.values?.startYear)){
      setErrorMsg('End date should not be greater than todays date');
      setIsError(true);
    } 
  }, [formik.values.startYear, formik.values.endYear]);

  return (<Container id={id} style={{ padding: '2rem' }}>
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputLabel htmlFor="TextField-input" required>School/University</InputLabel>
          <TextField variant="outlined"
            id="university"
            placeholder='Enter university'
            name="university"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.university}
            fullWidth
            helperText={formik.errors.university}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel htmlFor="TextField-input" style={{ textTransform: 'none' }} required>Field of study</InputLabel>
          <TextField
            variant="outlined"
            id="degree"
            placeholder='Enter field of study'
            name="field"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.field}
            fullWidth
            helperText={formik.errors.field}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel htmlFor="TextField-input" required>Start Date</InputLabel>
          <TextField
            views={['year']}
            variant="outlined"
            id="startMonth"
            name="startYear"
            placeholder={'Enter Start Month'}
            type="month"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.startYear}
            inputProps={{
              max: moment().format('YYYY-MM'),
            }}
            helperText={formik.errors.startYear}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel htmlFor="TextField-input" required>End Date</InputLabel>
          <TextField
            variant="outlined"
            id="endMonth"
            name="endYear"
            placeholder={'Enter End Month'}
            type="month"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.endYear}
            inputProps={{
              max: moment().format('YYYY-MM'),
            }}
            helperText={formik.errors.endYear}
          />
        </Grid>
      </Grid>
      {
        errorMsg && (formik.touched.endYear) && (
          <Typography style={{ color: 'red', fontSize: '0.75rem', 'fontWeight': '400' }}>{errorMsg}</Typography>
        )
      }
    </form>
  </Container >
  );
};

export default EducationDetails;