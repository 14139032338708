import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../themes/colors';
import { Grid, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'asset/icons/EditIcon.svg';
import DeleteIcon from 'asset/icons/DeleteIcon.svg';
import OPEN_EYE from 'asset/icons/openeye.svg';
import CLOSED_EYE from 'asset/icons/closed_eye.svg';
import { setStaticQuestions } from 'redux/slices/employerSlices/addQuestion';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: colors.white,
    padding: '1.5em 30px',
    boxShadow: ' 0px 2px 6px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.gray1,
  },
  hidden: {
    fontWeight: 500,
    fontSize: 16,
    color: '#8e919c',
  },
  text: {
    fontWeight: 400,
    fontSize: 12,
    color: colors.gray3,
  },
  question: {
    fontWeight: 400,
    fontSize: 16,
    color: colors.gray1,
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px'
  },
  radioBtnLabel: {
    fontFamily: 'poppins',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    color: '#24283C',
  },
}));

const QuestionList = ({ questionList = [], onDelete, setConfirmDialog, openInPopup, usedIn, isLoading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [questions, setQuestions ] = useState(questionList);
  const addStaticQuestionsData = useSelector(state => get(state, ['addStaticQuestions', 'staticQuestions'], []));

  useEffect(() => {
    questionList = questionList?.map((question) => {
      let isSelected = true;
      const existingQuestion = addStaticQuestionsData.find(que => que.id === question.id);
      if (usedIn === 'JobPostPreview' && existingQuestion) {
        isSelected = existingQuestion.isSelected;
      }
      return {
        ...question,
        isSelected: isSelected
      };
    });
    setQuestions(questionList);
    if (usedIn === 'JobPostPreview') {
      dispatch(setStaticQuestions(questionList));
    }
  }, [questionList]);
  
  const updateQuestion = (id) => {
    const newQuestions = [...questions];
    const index = newQuestions.findIndex(x=> x.id === id);
    newQuestions[index] = { ...newQuestions[index], isSelected: !newQuestions[index].isSelected };
    dispatch(setStaticQuestions(newQuestions));
    setQuestions(newQuestions);
  };
  
  return (
    <Grid container direction="column" className={classes.root} spacing={1}>
      {questions?.length ? (
        questions.map((question, index) => {
          return (
            <div style={{ marginBottom: '3rem' }}>
              <Grid item lg={12} xs={12} sm={12}>
                <div className={classes.questionHeader}>
                  <Typography className={question.isSelected ? classes.title : classes.hidden}>Question {index + 1} </Typography>
                  <Typography className={question.isSelected ? classes.text : classes.hidden}>{question && question.mandatory ? 'Mandatory' : ''}</Typography>
                  <div>
                    <IconButton color="secondary" title="Edit" aria-label="Edit Data" onClick={() => openInPopup(question.id)}>
                      <img src={EditIcon} />
                    </IconButton>
                    {usedIn !== 'JobPostPreview' && <IconButton
                      color="secondary"
                      aria-label="Delete Data"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: 'Are you sure to delete this record?',
                          subTitle: 'You can\'t undo this operation',
                          onConfirm: () => {
                            onDelete(question.id);
                          },
                        });
                      }}>
                      <img src={DeleteIcon} />
                    </IconButton>}
                    {usedIn === 'JobPostPreview' && !question.mandatory && question.isSelected && <IconButton title='Deselect' color="secondary" aria-label="Edit Data" onClick={() => updateQuestion(question.id)}>
                      <img src={OPEN_EYE} />
                    </IconButton>}
                    {usedIn === 'JobPostPreview' && !question.mandatory && !question.isSelected && <IconButton title='Select' color="secondary" aria-label="Edit Data" onClick={() => updateQuestion(question.id)}>
                      <img src={CLOSED_EYE} />
                    </IconButton>}
                  </div>
                </div>
                <Typography className={question.isSelected ? classes.title : classes.hidden}>{question && question.question}</Typography>
              </Grid>
              {usedIn != 'interviewPrep' && <Grid item md={4} xs={12}>
                <Box
                  component="form"
                  style={{
                    marginTop: '1.2rem'
                  }}
                  noValidate
                  autoComplete="off">
                  <TextField placeholder="Enter your answer" name='answer' disabled variant="outlined" fullWidth />
                </Box>
              </Grid>}
            </div>
          );
        })
      ) : (
        <>
          {!isLoading && <Typography className={classes.title}>No Questions Found</Typography> || null}
        </>
      )}
    </Grid>
  );
};

export default React.memo(QuestionList);