import React from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import Register from 'view/common/components/Register/Register';
import Login from 'view/common/components/Login/Login';
import EmployerRegister from 'view/employer/pages/Registration/EmployerRegister';
import EmployerHome from 'view/employer/pages/Home/Home';
import EmployerLogin from 'view/employer/pages/Login/EmployerLogin';
import InterviewerLogin from 'view/interviewer/pages/Login/InterviewerLogin';

const PublicRoutes = () => {
  const location = useLocation();
  return (
    <Switch>
      <Route exact path="/applicant/register" element={<Register />} />
      <Route exact path={'/applicant/login'} element={<Login />} />
      <Route exact path="/employer/login" element={<EmployerLogin />} />
      <Route exact path="/employer/register" element={<EmployerRegister />} />
      <Route exact path="/employer/home" element={<EmployerHome />} />
      <Route exact path="/interviewer/login" element={<InterviewerLogin />} />
      {
        location.pathname !== '/' && 
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
      }
    </Switch>
  );
};

export default PublicRoutes;
