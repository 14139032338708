import { createAsyncThunk } from '@reduxjs/toolkit';
import { addLocation, deleteLocation, editLocation, getLocationsList } from 'redux/restAPIs/employerAPI/companyLocations';
import { ADD_COMPANY_LOCATIONS_SUCCESS, GET_ALL_COMPANY_LOCATIONS_SUCCESS, EDIT_COMPANY_LOCATIONS_SUCCESS, DELETE_COMPANY_LOCATIONS_SUCCESS } from 'constants';

export const onAddCompanyLocation = createAsyncThunk(
  ADD_COMPANY_LOCATIONS_SUCCESS,
  async (payload) => {
    const response = await addLocation(payload);
    return response.data;
  }
);

export const onEditCompanyLocation = createAsyncThunk(
  EDIT_COMPANY_LOCATIONS_SUCCESS,
  async (payload) => {
    const response = await editLocation(payload);
    return response.data;
  }
);

export const onGetLocationsList = createAsyncThunk(
  GET_ALL_COMPANY_LOCATIONS_SUCCESS,
  async (payload) => {
    const response = await getLocationsList(payload);
    return response.data;
  }
);

export const onDeleteCompanyLocation = createAsyncThunk(
  DELETE_COMPANY_LOCATIONS_SUCCESS,
  async (payload) => {
    const response = await deleteLocation(payload);
    return response.data;
  }
);