import React, { useEffect, useState } from 'react';
import { Icon, Grid, Button } from '@material-ui/core';
import { filter } from 'lodash-es';
import AddLocationForm from './addLocationForm';
import ViewLocation from './ViewLocation';
import { Popup, Notification, ConfirmDialog } from '../../../../ui-components';
import { useTranslation } from 'react-i18next';
import { onAddCompanyLocation, onDeleteCompanyLocation, onEditCompanyLocation, onGetLocationsList } from 'redux/actions/employerActions/Configurations/companyLocationsAction';
import { useDispatch } from 'react-redux';

export default function CompanyLocation() {
  const [openPopup, setOpenPopup] = useState(false);
  const initialValue = {
    id: '',
    name: '',
    address: '',
    city: '',
    zipcode: '',
    state: '',
    country: '',
  };
  const [recordForEdit, setRecordForEdit] = useState(initialValue);
  const { t: translate } = useTranslation();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const [companyLocationData, setCompanyLocationData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      pageNo: pageNumber,
      pageSize: pageSize,
    };
    setIsLoading(true);
    dispatch(onGetLocationsList(payload)).then((response) => {
      setCompanyLocationData(response.payload.content);
      setUpdateData(false);
      setIsLoading(false);
      setTotalElements(response?.payload?.totalElements || 0);
    });
  }, [updateData, pageNumber, pageSize]);

  const addOrEdit = async (location, resetForm) => {
    if (location.id === '') {
      // insert location
      await dispatch(onAddCompanyLocation(location)).then((response) => {
        {
          response && response.id && resetForm();
          setUpdateData(true);
          setOpenPopup(false);
          setNotify({
            isOpen: true,
            message: 'Location Added Successfully',
            type: 'success',
          });
        }
      });
    } else {
      // update location
      await dispatch(onEditCompanyLocation(location)).then((response) => {
        {
          response && response.id && resetForm();
          setUpdateData(true);
          setOpenPopup(false);
          setNotify({
            isOpen: true,
            message: 'Location Updated Successfully',
            type: 'success',
          });
        }
      });
    }
    // setRecordForEdit(null);
  };

  const openInPopup = (id) => {
    let selectedRow = filter(companyLocationData, { id }, []);
    if (selectedRow.length > 0) {
      setRecordForEdit(selectedRow[0]);
      setOpenPopup(true);
    }
  };

  const enableConfirmDialog = (data) => {
    setConfirmDialog(data);
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await dispatch(onDeleteCompanyLocation(id));
    setUpdateData(true);
    setNotify({
      isOpen: true,
      message: 'Deleted Successfully',
      type: 'error',
    });
  };

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Grid container justifyContent="flex-end">
            <Button
              fullWidth={false}
              variant="contained"
              color="secondary"
              startIcon={<Icon>add_circle</Icon>}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(initialValue);
              }}>
              {translate('DHIYO.CONFIGURATION.LOCATION.ADD_FORM.FORM_NAME')}
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: '1em' }}>
          <ViewLocation
            data={companyLocationData}
            openInPopup={openInPopup}
            key="viewLocations"
            onDelete={onDelete}
            setConfirmDialog={enableConfirmDialog}
            confirmDialog={confirmDialog}
            isLoading={isLoading}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalElements={totalElements}
          />
        </Grid>
      </Grid>
      <Popup title={`${recordForEdit?.id ? 'Update' : 'Add'} Company Location`} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <AddLocationForm recordForEdit={recordForEdit} initialValue={initialValue} addOrEdit={addOrEdit} openInPopup={openInPopup} onCancel={() => setOpenPopup(false)} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
}
