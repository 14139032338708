import { isEmpty, get } from 'lodash';

export const formatEmptyValues = (data, field, alternateVal) => {
  const value = get(data, field);
  return isEmpty(value) ? alternateVal : value;
};

export const getFormattedAddress = (formData) => {
  const address = [];
  ['city', 'stateResiding', 'country'].forEach((item) => {
    const value = get(formData, item);
    if (!isEmpty(value)) {
      address.push(value);
    }
  });
  return address.join(',');
};