import React, { useEffect } from 'react';
import { FormControl, Grid, InputLabel, TextField, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikChipSelector, FormikSelectField, FormikTextField } from '../../../../ui-components';
import { lovKeys } from 'utils/LOVUtils';
import { workingHours, timeZones } from '../Components/List';
import { useSelector } from 'react-redux';
import { get } from 'lodash';


const useStyles = makeStyles(theme => ({
  formContainer: {
    backgroundColor: 'white',
    padding: '2em',

    [theme.breakpoints.down('md')]: {
      marginTop: '2em',
      paddingLeft: '0.25em',
      paddingRight: '0.25em',
      paddingBottom: '1em',
    },
  },
  titleBasicDetail: {
    ...theme.typography.title,
  },
}));

const CompanyInformation = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const employerProfileData = useSelector(state => get(state, ['userProfile', 'employer']));
  let employeeBenifitData = '';
  if (employerProfileData && employerProfileData.employeeBenefits && employerProfileData.employeeBenefits.length) {
    employeeBenifitData = `✅${employerProfileData.employeeBenefits.map(benefit => benefit.name).join('\n\n✅')}`;
  }
  const companyDetails = `${employerProfileData.about}\n\nWebsite - ${employerProfileData.website}\n\nAddress - ${get(employerProfileData, 'address[0].city', '')}, ${get(employerProfileData, 'address[0].state', '')}, ${get(employerProfileData, 'address[0].country', '')}`;
  const companyValues = employerProfileData.companyValues;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid item className={classes.formContainer} container direction="column" spacing={2}>
        <Grid item container direction="column">
          <Grid>
            <Typography className={classes.titleBasicDetail}>{translate('DHIYO.JOBPOSTING_ADD.FORM-02.HEADER.BASIC')}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2">
              {translate('DHIYO.JOBPOSTING_ADD.FORM-02.HEADER.BASIC_DETAILS')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={2} style={{ paddingTop: '1em' }}>
          <Grid item xs={12} md={3}>
            {workingHours && workingHours.length > 0 &&
            <Grid item>
              <InputLabel required htmlFor="TextField-input">Working Hours (From)</InputLabel>
              <FormikSelectField
                dropDownData={workingHours}
                className={classes.fieldWidth}
                label="workTimingsFrom"
                id="workTimingsFrom"
                name="workTimingsFrom" />
            </Grid>}
          </Grid>
          <Grid item xs={12} md={3}>
            {workingHours && workingHours.length > 0 && <Grid item>
              <InputLabel required htmlFor="TextField-input">Working Hours (To)</InputLabel>
              <FormikSelectField
                dropDownData={workingHours}
                className={classes.fieldWidth}
                label="workTimingsTo"
                id="workTimingsTo"
                name="workTimingsTo" />
            </Grid>}
          </Grid>
          <Grid item xs={12} md={5}>
            {timeZones && timeZones.length > 0 && <Grid item>
              <InputLabel required htmlFor="TextField-input">Select Time Zone</InputLabel>
              <FormikSelectField
                dropDownData={timeZones}
                className={classes.fieldWidth} 
                label="TimeZone"
                id="timeZone"
                name="timeZone" />
            </Grid>}
          </Grid>

          <Grid item xs={12} md={12} style={{ marginTop: '20px' }}>
            <InputLabel required htmlFor="TextField-input">Job Functions</InputLabel>
            <FormikChipSelector
              apiKey={lovKeys.jobFunctions}
              label="Select Job Functions"
              name="jobFunctions"
              maxAllowedSelection={5}
              id="jobFunctions" />
          </Grid>
        </Grid>

        <Grid item>
          <InputLabel htmlFor="TextField-input">Company Details</InputLabel>
          <TextField
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            disabled
            value={companyDetails}
            aria-label="minimum height"
          />
        </Grid>

        <Grid item>
          <InputLabel htmlFor="TextField-input">Values of Company</InputLabel>
          <TextField
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            value={companyValues}
            disabled
          />
        </Grid>

        <Grid item>
          <InputLabel htmlFor="TextField-input">Perks & Benefits</InputLabel>
          <TextField
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            value={employeeBenifitData}
            disabled
          />
        </Grid>

        <Grid item>
          <InputLabel required htmlFor="TextField-input">Roles & Responsiblities</InputLabel>
          <FormControl fullWidth>
            {/*
              keeping it for future 
              <RichTextEditor name='rolesAndResponsibilities' label={'Write a short description'} width={'100%'} height={'150px'} /> 
            */}
            <FormikTextField
              multiline rows={8} 
              placeholder="Write a short description'" 
              maxRows={10} 
              variant="outlined" 
              name="rolesAndResponsibilities" 
              id="rolesAndResponsibilities" />
          </FormControl>
        </Grid>

        <Grid item>
          <InputLabel required htmlFor="TextField-input">Impact of Position</InputLabel>
          <FormControl fullWidth>
            {/*
              keeping it for future 
              <RichTextEditor name='impactOfPosition' label={'Write a short description'} width={'100%'} height={'150px'} /> 
            */}
            <FormikTextField
              multiline rows={8} 
              placeholder="Write a short description'" 
              maxRows={10} 
              variant="outlined" 
              name="impactOfPosition" 
              id="impactOfPosition" />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyInformation;
