import React, { useState } from 'react';
import * as R from 'ramda';
import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ComposerPanelWrapper from '../../../common/wrappers/ComposerPanelWrapper';
import { calendarTab } from 'constants/applicant/applicant';
import MySchedule from '../MySchedule';
import ConfirmSlotCalendar from '../../../common/components/ConfirmSlotCalendar/ConfirmSlotCalendar';
import ScheduleInterviewCalendar from '../../../common/components/ScheduleInterviewCalendar/ScheduleInterviewCalendar';
import { useSelector } from 'react-redux';
import { onGetInterviewerSlots, onInterviewerMyScheduleWithFilter } from 'redux/actions/interviewerActions/interviewerSchedule';

const useStyles = makeStyles(theme => ({
  gridMain: {
    padding: '3rem 0rem 3rem 2rem', marginBottom: '3rem'
  },
  pageTitle: {
    ...theme.typography.title,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
}));

const InterviewerCalendar = () => {
  
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const calendarSchedule = useSelector(state => state.interviewManagement.myCustomSchedule);
  const slots = useSelector(state => state.interviewManagement.slots);
  
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const RendererExample = () => {
    return (
      <MySchedule />
    );
  };

  const PanelConfig = {
    isOpen: false,
    anchor: 'right',
    Renderer: RendererExample, // ComponentRenderer
    rendererConfigs: {
      usedIn:'interviewer'
    },
  };
  
  return (
    <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
      <Grid
        container
        direction="column"
        className={classes.gridMain}
        spacing={2}>
        <Grid container spacing={0} className={classes.pageTitleContainer} alignItems="baseline">
          <Grid item xs={12}>
            <Typography className={classes.pageTitle}>Calendar</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={9}>
            <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example" >
              {
                R.map((tab) => {
                  return <Tab key={tab.label} label={tab.label} {...a11yProps(tab.id)} />;
                })(calendarTab)
              }
            </Tabs>
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '1rem' }}>
          {currentTab == 0? 
            <ConfirmSlotCalendar
              currentTab={currentTab}
              dispatcher={onGetInterviewerSlots}
              slots={slots}
              usedIn="interviewer" />
            :
            <ScheduleInterviewCalendar
              currentTab={currentTab}
              dispatcher={onInterviewerMyScheduleWithFilter}
              data={calendarSchedule}
              usedIn="interviewer" /> 
          }
        </Grid>
      </Grid>
    </ComposerPanelWrapper>
  );
};

export default InterviewerCalendar;
