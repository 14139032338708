import React from 'react';
import AppliedJobs from './Applied';
import Reffered from './Reffered';
import Saved from './Saved';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ComposerPanelWrapper from '../../../common/wrappers/ComposerPanelWrapper';

const useStyles = makeStyles(() => ({
  gridMain: {
    padding: '3rem 1rem', marginBottom: '3rem'
  },
}));


const MyJobs = () => {
  const PanelConfig = {
    isOpen: false,
    anchor: 'right',
    Renderer: false, // ComponentRenderer
    rendererConfigs: {},
  };
  const classes = useStyles();
  return (
    <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
      <Container maxWidth="lg" className={classes.gridMain}>
        <Typography variant="h5">My Jobs</Typography>
        <AppliedJobs />
        <Reffered />
        <Saved />
      </Container>
    </ComposerPanelWrapper>
  );
};

export default MyJobs;
