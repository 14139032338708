import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Grid } from '@mui/material';
import Button from '@material-ui/core/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { onGetAppliedJobs } from 'redux/actions/applicantDashboard';
import TablePagination from '@mui/material/TablePagination';
import { get } from 'lodash';
import Progress from './Progress';
import { StyledEngineProvider } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    marginBottom: '1rem',
  },
  jobStatus: {
    background: '#E9F8F8',
    border: '1px solid #06B9A3',
    padding: '1px 7px',
    textTransform: 'uppercase',
    color: '#06B9A3',
    '&:hover': {
      background: '#E9F8F8',
    }
  },
  percent: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'right',
    color: '#FF5B15',
  },
}));


const AppliedJobs = () => {
  const [show, setShow] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(2);

  const myAppliedJobs = useSelector(state => get(state, ['applicantDashboard', 'myAppliedJobs'], ''));
  const dispatch = useDispatch();

  useEffect(() => {
    if (myAppliedJobs) {
      dispatch(onGetAppliedJobs(`?pageNo=${pageNumber}&pageSize=${pageSize}&sortBy=id`));
    }
  }, [pageNumber, pageSize]);

  const showStepper = () => {
    setShow(!false);
  };

  const handlePaginationClick = (e, selectedPage) => {
    setPageNumber(selectedPage - 1);
  };

  const handlePageSelection = (event) => {
    setPageSize(parseInt(event.target.value));
  };
  
  const classes = useStyles();
  return (
    <Box sx={{ marginTop: '26px' }}>
      <Typography className={classes.pageTitle}>Applied Jobs ({myAppliedJobs?.totalElements})</Typography>
      {!!myAppliedJobs?.totalElements && (
        <>
          {myAppliedJobs?.content?.map((data) => {
            return (
              <>
                <Card className={classes.jobCard} sx={{ marginBottom: '15px' }} elevation={0}>
                  <CardContent>
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                      <Grid item xs={10} sx={{ display: 'flex' }}>
                        <Grid item>
                          <Typography variant="h5">
                            <b>{data?.jobDetail?.companyJobTitle?.title} | {data?.jobDetail?.companyName}</b>
                            <Typography variant="subtitle2" style={{ marginTop: '0.2rem' }}>{data?.jobDetail?.jobLocation}</Typography>
                          </Typography>
                        </Grid>
                        <Grid item sx={{ marginLeft: '29px' }}>
                          <Button onClick={showStepper} variant="outlined" size="small" className={classes.jobStatus}>
                            {data?.state}
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid item xs={2} sx={{ textAlign: 'right' }}>
                        <Typography variant="subtitle1" style={{ fontSize: '11px' }}>
                      Compatibility
                          <Typography className={classes.percent}>{data?.companyJobTitle?.title}0%</Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                    {show && (
                      <Grid sx={{ overflowX: 'auto', padding: '1rem 0' }} >
                        <StyledEngineProvider injectFirst>
                          <Progress />
                        </StyledEngineProvider>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </>
            ); 
          })}
     
          <Grid container spacing={0} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Grid item xs={3} style={{ display: 'flex' }}>
              <TablePagination
                component="div"
                rowsPerPageOptions={[2, 5, 10, 25, 50]}
                count={myAppliedJobs?.totalPages || 0}
                page={pageNumber}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handlePageSelection}
              />
            </Grid>
            <Grid item xs={9} style={{ justifyContent: 'end', display: 'flex' }}>
              <Pagination
                count={myAppliedJobs?.totalPages || 0}
                onChange={handlePaginationClick}
                renderItem={(item) => {
                  return <PaginationItem components={{ first: 'first', last: 'last' }} {...item} />;
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default AppliedJobs;
