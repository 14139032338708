import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {
  Grid, Typography, Chip, Box, Button, Accordion, AccordionSummary, Checkbox,
  FormControlLabel, AccordionDetails, Table, Switch, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Stack from '@mui/material/Stack';
import { colors } from '../../../../../themes/colors';
import rolepic from 'asset/icons/rolepic.svg';
import deletecard from 'asset/icons/delete.svg';
import editcard from 'asset/icons/editcard.svg';
import { get } from 'lodash-es';

const chipData = [
  { label: 'Register', },{ label: 'Configurations', },{ label: 'User Management', }, { label: 'Payments', }
];

function createData(name) {
  return { name };
}

const registerOpt = [
  createData('Signup'),
];

const paymentOpt = [
  createData('Choose a Plan'),
  createData('Make Payment'),
];

const userManagementOpt = [
  createData('Add New User'),
  createData('Update User'),
  createData('Delete User'),
  createData('View User Details'),
  createData('Search User'),
  createData('Assign Job Post'),
  createData('Edit Job Post'),
  createData('Job Posting'),
  createData('View Calender'),
];

const configurationsOpt = [
  createData('Create Users'),
  createData('Company Information'),
  createData('Job Title & Department'),
  createData('Organisation Roles'),
  createData('Job Codes'),
  createData('Static Questions'),
  createData('Interview Feedback Questions'),
  createData('Company Locations'),
];

const miscellaneousOpt = [
  createData('Interview'),
  createData('Decisions'),
  createData('Message'),
  createData('Notifications'),
];

const Android12Switch = styled(Switch)(() => ({
  padding: 7,
  width: '56.5px',
  height: '35px',
  '& .MuiSwitch-switchBase': {
    top: '0.5px',
    left: '1px',
  },
  '& .MuiSwitch-switchBase .Mui-checked': {
    transform: 'translateX(21px)',
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: '#6360CD',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-55%)',
      width: 16,
      height: 16
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    backgroundColor: '#fff',
    border: 'none',
  }
}));

// const columns = [
//   { id: 'id', label: 'S.NO', minWidth: 10 },
//   { id: 'role', label: 'Role', minWidth: 30 },
//   {
//     id: 'designation',
//     label: 'Designtion',
//     minWidth: 30,
//   },
//   {
//     id: 'reportsTo',
//     label: 'Reports To',
//     minWidth: 30,
//   },
  
//   {
//     id: 'permission',
//     label: 'Permission',
//     minWidth: 30, 
//     align: 'center', 
//   },
//   {
//     id: 'action',
//     label: 'Action',
//     minWidth: 10,
//     align: 'center',
//     format: value => value.toFixed(2),
//   },
// ];

const useStyles = makeStyles(() => ({
  customicon: {
    width: '40px',
  },
  detailsCardContainer: {
    // border: `1px solid ${colors.gray7}`,
    background: '#fff',
    padding: '15px',
    borderRadius: '6px',    
  },
  cardDetailsContainer: {
    paddingLeft: '0.6rem',
    paddingRight: '1rem',
    '&.MuiTypography-body1': {
      testAlign: 'justify'
    },
  },
  mainTitle: {
    fontSize: '20px',
    fontWeight: '700',
    fontFamily: 'poppins',
  },
  title: {
    fontSize: '1rem',
    fontWeight: '600',
    color: colors.gray3,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'baseline',
  },
  container: {
    maxHeight: 440,
  },
  formCardContainer: {
    margin: '1rem 0',
  },
  iconBtn: {
    cursor: 'pointer',
  },
  chip: {
    margin: '0.4rem 0.6rem 0.4rem 0rem',
    border: '1px solid #6361CD',
    color: '#6361CD',
  },
  accordian: {
    background: '#E1E0FF',
    borderRadius: '4px',
  },
  mainAccord: {
    marginBottom: '1rem',
    boxShadow: 'none',
  },
  accordDetails: {
    padding: 0,
  }
}));
export default function ViewOrgRoles(props) {
  const classes = useStyles();
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let rowsData = [];
  if (props.data !== null) {
    props.data.forEach((item) => {
      let rowData = {
        id: item['id'],
        role: item.role,
        designation: item.designation,
        reportsTo: get(item,'reportsTo.role',''),
        permission: [
          
          item.createJob,
          item.scheduleInterview,
          item.createJobTitle,
          item.addTeamMember,
        ] ,
      };

      rowsData.push(rowData);
    });
  }
  
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  return (
    <>
      <Grid container className={classes.detailsCardContainer} xs={12}>
        <Grid item container xs={11}>
          <Grid item xs={1} style={{ flexBasis: 'auto' }}>
            <img src={rolepic} alt='rolepic icon' className={classes.customicon} />
          </Grid>
          <Grid item xs={10} className={classes.cardDetailsContainer}>
            <Typography variant="h4" className={classes.mainTitle}>HR Admin</Typography>
            <Typography variant="h3" className={classes.title} style={{ marginTop: '1rem' }}>Permissions</Typography>
            <div>
              {
                chipData.map(data => (
                  <Chip label={data.label} variant="outlined" className={classes.chip} />
                ))
              }
            </div>
          </Grid>
        </Grid>
        <Grid item xs={1} className={classes.btnContainer}>
          <IconButton className={classes.actionIcon}>
            <img src={editcard} alt='edit card icon' className={classes.iconBtn} />
          </IconButton>
          <IconButton className={classes.actionIcon}>
            <img src={deletecard} alt='delete card icon' className={classes.iconBtn} />
          </IconButton>
        </Grid>
      </Grid>

      <Box className={classes.formCardContainer} width="100%">
        <Grid container xs={12}>
          <Grid item xs={6} className={classes.cardDetailsContainer}>
            <Typography variant="h4" className={classes.mainTitle}>Permissions</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'end' }}>
            <Button variant="outlined" color="secondary" style={{ marginRight: '10px' }}>Cancel</Button>
            <Button variant="contained" color="secondary" disableElevation>Save</Button>
          </Grid>
        </Grid>
        
        <Box sx={{ backgroundColor: 'white', borderRadius: '6px', marginTop: '0.3rem' }}>
          <Grid container className={classes.detailsCardContainer} xs={12} >
            <Grid item xs={12}>
              <Accordion className={classes.mainAccord}>
                <AccordionSummary
                  disableElevation
                  className={classes.accordian}
                  expandIcon={<ArrowDropDownIcon fontSize='large' color='secondary' />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"  
                >
                  <Typography variant="h5"><b>Register</b></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordDetails}>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableBody>
                        {registerOpt.map(row => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row" >
                              {row.name}
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1} style={{ flexDirection: 'row-reverse', alignItems: 'center' }}>
                                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>Allowed</Typography>
                                <FormControlLabel
                                  control={<Android12Switch color="secondary" />}
                                />
                                <Typography variant="subtitle1" style={{ marginRight: '4px', marginLeft: 0 }}>Not Allowed</Typography>
                              </Stack>  
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>  
                  
              <Accordion className={classes.mainAccord}>
                <AccordionSummary
                  className={classes.accordian}
                  expandIcon={<ArrowDropDownIcon fontSize='large' color='secondary' />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h5"><b>Payment</b></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordDetails}>
                  <TableContainer>
                    <Table aria-label="simple table2">
                      <TableBody>
                        {paymentOpt.map(row => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row" >
                              {row.name}
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1} style={{ flexDirection: 'row-reverse', alignItems: 'center' }}>
                                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>Allowed</Typography>
                                <FormControlLabel
                                  control={<Android12Switch color="secondary" />}
                                />
                                <Typography variant="subtitle1" style={{ marginRight: '4px', marginLeft: 0 }}>Not Allowed</Typography>
                              </Stack>  
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.mainAccord} >
                <AccordionSummary
                  className={classes.accordian}
                  expandIcon={<ArrowDropDownIcon fontSize='large' color='secondary' />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h5"><b>Configurations</b></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordDetails}>
                  <TableContainer>
                    <Table aria-label="simple table2">
                      <TableBody>
                        {configurationsOpt.map(row => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row" >
                              {row.name}
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1} style={{ flexDirection: 'row-reverse', alignItems: 'center' }}>
                                <Typography variant="subtitle1">Read</Typography>
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  icon={<CircleUnchecked />}
                                  checkedIcon={<CircleCheckedFilled />}
                                />
                              </Stack>  
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1} style={{ flexDirection: 'row-reverse', alignItems: 'center' }}>
                                <Typography variant="subtitle1">Create</Typography>
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  icon={<CircleUnchecked />}
                                  checkedIcon={<CircleCheckedFilled />}
                                />
                              </Stack>  
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1} style={{ flexDirection: 'row-reverse', alignItems: 'center' }}>
                                <Typography variant="subtitle1">Update</Typography>
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  icon={<CircleUnchecked />}
                                  checkedIcon={<CircleCheckedFilled />}
                                />
                              </Stack>  
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1} style={{ flexDirection: 'row-reverse', alignItems: 'center' }}>
                                <Typography variant="subtitle1">Delete</Typography>
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  icon={<CircleUnchecked />}
                                  checkedIcon={<CircleCheckedFilled />}
                                />
                              </Stack>  
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.mainAccord}>
                <AccordionSummary
                  className={classes.accordian}
                  expandIcon={<ArrowDropDownIcon fontSize='large' color='secondary' />}
                  aria-controls="panel4a-content"
                  id="panel5a-header"
                >
                  <Typography variant="h5"><b>User Management</b></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordDetails}>
                  <TableContainer>
                    <Table aria-label="simple table2">
                      <TableBody>
                        {userManagementOpt.map(row => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row" >
                              {row.name}
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1} style={{ flexDirection: 'row-reverse', alignItems: 'center' }}>
                                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>Allowed</Typography>
                                <FormControlLabel
                                  control={<Android12Switch color="secondary" />}
                                />
                                <Typography variant="subtitle1" style={{ marginRight: '4px', marginLeft: 0 }}>Not Allowed</Typography>
                              </Stack>  
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  className={classes.accordian}
                  expandIcon={<ArrowDropDownIcon fontSize='large' color='secondary' />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography variant="h5"><b>Miscellaneous</b></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordDetails}>
                  <TableContainer>
                    <Table aria-label="simple table2">
                      <TableBody>
                        {miscellaneousOpt.map(row => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row" >
                              {row.name}
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1} style={{ flexDirection: 'row-reverse', alignItems: 'center' }}>
                                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>Everyone</Typography>
                                <FormControlLabel
                                  control={<Android12Switch color="secondary" />}
                                />
                                <Typography variant="subtitle1" style={{ marginRight: '4px', marginLeft: 0 }}>Only Me</Typography>
                              </Stack>  
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>

            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

// https://github.com/mbrn/material-table/issues/1996
// https://codesandbox.io/s/react-material-table-row-forked-hp7m7?file=/src/App.tsx
