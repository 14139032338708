import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
 
}));

const theme = createTheme({
  overrides: {
    MuiInputLabel: {
      outlined: {
        '.MuiAutocomplete-root &:not(.MuiInputLabel-shrink)': {
          transform: 'translate(14px, 12.5px) scale(1)'
        }
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"] $input': {
          padding: '4px 10px',
          fontFamily: 'poppins',
          color: '#a8a8a8',
          fontWeight: 100,
          fontSize: '15px',
        }
      }
    }
  }
});


const AutocompleteSelect = ({
  localePrefix,
  name,
  label,
  maxAllowedSelection = -1,
  dropDownData,
  displayValue = '',
  metaError = false,
  ...rest
}) => {
  const { t: translate } = useTranslation();
  const [, meta, helpers] = useField(name);
  let lbl = null;
  const [dropdownOptions, setDropdownOptions] = useState(dropDownData);
  const [userOptions, setUserOptions] = useState('');
  const classes = useStyles();
  if (localePrefix !== null) {
    lbl = translate(`${localePrefix}.LABEL`);
  }
  if (label) {
    lbl = label;
  }

  const { setValue } = helpers;
  if (maxAllowedSelection === -1) {
    maxAllowedSelection = dropdownOptions.length;
  }
  const [selectedItem, setSelectedItem] = React.useState(null);

  useEffect(() => {
    if (dropDownData || !dropdownOptions) {
      setDropdownOptions(dropDownData);
    }
  });

  const autoSuggestionItemSelectEvt = (event, newValue) => {
    setSelectedItem(newValue);
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        fullWidth
        options={dropdownOptions}
        getOptionSelected={option => selectedItem && option.id === selectedItem.id}
        getOptionLabel={option => option.name || displayValue}
        value={selectedItem || displayValue}
        onChange={autoSuggestionItemSelectEvt}
        renderInput={params => (
          <TextField
            className={classes.selectField}
            fullWidth
            {...params}
            placeholder={lbl}
            value={userOptions || displayValue}
            onChange={e => setUserOptions(e.target.value)}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && Boolean(meta.error) ? metaError || meta.error : undefined}
            variant="outlined" />
        )}
        {...rest} />
    </ThemeProvider>

  );
};

AutocompleteSelect.propTypes = {
  localePrefix: PropTypes.string,
  name: PropTypes.string.isRequired,
  maxAllowedSelection: PropTypes.number,
};

export default AutocompleteSelect;
