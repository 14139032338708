import React, { useEffect, useState } from 'react';
import DPTable from 'view/common/DPTable';

const JobTitleList = ({ jobsData,setPageNumber,setPageSize, ...rest }) => {
  const [rowsData, setRowsData] = useState([]);
  const columns = [
    { id: 'code', label: 'S.No.', minWidth: 30 },
    { id: 'title', label: 'Job Title', minWidth: 100 },
    {
      id: 'designation',
      label: 'Designation',
      minWidth: 120,
    },
    {
      id: 'department',
      label: 'Department',
      minWidth: 120,
    },
    {
      id: 'action',
      label: 'Actions',
      minWidth: 60,
      align: 'center',
      format: value => value.toFixed(2),
      cellRenderer: 'actionCellRenderer'
    },
  ];

  const jobTitileDetails = () => {
    let jobsList = [];
    jobsData && jobsData.length && jobsData.map((jobDetails, index) =>{
      let jobsData = {
        id: jobDetails.id,
        code: index + 1,
        title: jobDetails.title,
        designation: jobDetails.designation,
        department: jobDetails && jobDetails.department && jobDetails.department.name,
      };
      jobsList.push(jobsData);
    });
    setRowsData(jobsList);
  };
  
  useEffect(() =>{
    jobTitileDetails();
  }, [jobsData]);
   
  return (
    <>
      <DPTable
        {...rest}
        columns={columns}
        rowsData={rowsData}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default JobTitleList;