/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { onGetInterviewSlots, onShareCandidateSlot } from 'redux/actions/employerActions/interviewScheduled';

const initialState = {
  interviewerSlots: [],
  selectedInterviewDetails: null,
  status: 'idle',
  slotShared: false,
};

const employerInterviewManagement = createSlice({
  name: 'employerInterviewManagement',
  initialState,
  reducers: {
    updateInterviewDetails: (state, action) => {
      state.selectedInterviewDetails = action.payload;
    },
    updateSlotShared: (state) => {
      state.slotShared = false;
    },
  },
  extraReducers: {
    [onGetInterviewSlots.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetInterviewSlots.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.interviewerSlots = action.payload;
    },
    [onGetInterviewSlots.rejected]: (state) => {
      state.status = 'failed';
    },

    [onShareCandidateSlot.pending]: (state) => {
      state.status = 'loading';
    },
    [onShareCandidateSlot.fulfilled]: (state) => {
      state.status = 'succeeded';
      state.slotShared = true;
    },
    [onShareCandidateSlot.rejected]: (state) => {
      state.status = 'failed';
    },
  },
});

export const { updateInterviewDetails, updateSlotShared } = employerInterviewManagement.actions;
export default employerInterviewManagement.reducer;
