import * as React from 'react';
import { Grid, Box, CardContent, Typography, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as R from 'ramda';
import { JrHeaderCards_Data } from 'mockData/RecommendedJobsData';


const useStyle = makeStyles(() => ({
  title: {
    '&.MuiTypography-root': {
      color: 'gray',
      fontWeight: '300',
      lineHeight: '27px',
      fontSize: '18px'
    },
  },
  headerCard: {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  },
  cardContent: {
    marginTop: '5px',
  },
  number: {
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '45px',
    color: '#24283C'
  }
}));

const JrHeaderCards = () => {
  const classes = useStyle();

  return (
    <Box>
      <Grid container spacing={2} >
        {
          R.map((headercardItem) => {
            return (
              <Grid item xs={12} md={4} >
                <Card className={classes.headerCard}>
                  <CardContent className={classes.cardContent}>
                    <Grid container>
                      <Grid item xs={2}>
                        <img src={headercardItem.icon} style={{ width: '35px' }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography className={classes.title}>
                          {headercardItem.title}
                        </Typography>
                        <Typography className={classes.number}>
                          {headercardItem.number}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })(JrHeaderCards_Data)
        }
      </Grid>
    </Box>
  );
};

export default JrHeaderCards;