import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { User_Profile_Card_Data } from 'mockData/CandidateCardData';
import * as R from 'ramda';

const useStyle = makeStyles(theme => ({
  box: {
    marginTop: '20px',
    backgroundColor: 'White',
    borderRadius: 4,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
    padding: '1.5rem'
  },
  positionsData: {
    fontSize: '16px',
    color: '#5755BE',
  },
  gridContent: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  details:{
    textAlign: 'end',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'initial',
      marginBottom: '1rem'
    },
  },
  expires: {
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
      marginLeft: '1rem',
    },
  },
  typoh6: {
    display: 'inline-flex', marginRight: '1rem'
  },
  jobid: {
    display: 'inline-flex',
    border: '1px solid #8692A6',
    padding: '0px 5px',
    borderRadius: 4,
    marginLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: '1rem'
    },
  },
  mainJobid: {
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '32px'
    },
  },
}));

// UserProfile

const UserProfileApp = () => {
  const classes = useStyle();

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '13px', color: '#000' }}
    >
      •
    </Box>
  );

  return (
    <>    
      <Box className={classes.box}>
        <Grid container className={classes.gridContent}>
          <Grid item xs={12} md={9} >
            <Typography variant="h4" className={classes.mainJobid}>
              UI/UX Designer | Mumbai, Maharashtra
              <Typography variant="subtitle1" className={classes.jobid}>
                Job ID: PINWD1011
              </Typography>
            </Typography>
            
            <Grid container xs={12} style={{ margin: '5px 0' }}>
              <Typography variant="h3" style={{ fontSize: '16px' }} >
                10 Positions {bull}
              </Typography>
              <Typography variant="h3" className={classes.positionsData} >
                30 Applied {bull}
              </Typography>
              <Typography variant="h3" className={classes.positionsData} >
                {/* {get(data, ['shortlisted'], 0)}  */}
                20 Shortlisted
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3} className={classes.details}>
            <Typography variant='h6' color='secondary' className={classes.typoh6}>
                Job Details
            </Typography>
            <Typography variant='body2' style={{ display: 'inline-flex' }}>
                1 day ago
            </Typography>
            <Typography variant="subtitle1" className={classes.expires}>
              Expires: Apr 10, 2021
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '1rem' }}>
          {
            R.map((data) => {
              return (
                <Grid item xs={6} md={3}>
                  <Typography variant="subtitle1" style={{ fontWeight: 300 }}>
                    {data.name}
                  </Typography>
                  <Typography variant='subtitle2'>
                    {data.value}
                  </Typography>
                </Grid>
              );
            })(User_Profile_Card_Data)
          }
        </Grid>
      </Box>
    </>
  );
};
export default UserProfileApp;