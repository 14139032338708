import React from 'react';
import {
  Grid, Box, makeStyles, Typography,
  AccordionDetails, AccordionSummary, Button, Accordion, InputLabel, Select
} from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { TextField } from '@mui/material';
import Feedback from 'asset/icons/feedback.svg';

const useStyle = makeStyles(() => ({
  accordian: {
    background: '#EEF3F9',
    border: '1px solid #6361CD',
    boxShadow: 'none',
    borderRadius: '50px'
  },
  '&.Mui-expanded': {
    margin: '10px 0'
  },
  accordDetails: {
    background: 'white', marginTop: '1rem', padding: '28px', borderRadius: 4, display: 'block'
  },
  feedbackTypo: {
    alignSelf: 'center',
    marginLeft: '-70px',    
  }
}));

const validationSchema = Yup.object({
  select: Yup.string().required('field is required'),
  feedback: Yup.string().required('field is required')
});

const FormInitialValue = {
  select: '',
  feedback: '',
};

const FeedbackForm = () => {
  const classes = useStyle();
  const [expanded, setExpanded] = React.useState(false);

  const formik = useFormik({
    initialValues: FormInitialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      formik.resetForm();
    }
  });

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = () => {
    formik.resetForm();
    setExpanded(false);
  };

  return (
    <Box>
      {/* Feedback */}
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: '1rem' }}>
          <Accordion
            style={{ background: 'transparent', boxShadow: 'none' }}
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              className={classes.accordian}
              disableElevation
              expandIcon={<ArrowDropDownIcon fontSize='large' color='secondary' />}
              aria-controls="panel1a-content"
              id="panel1a-header"  
            >
              <img src={Feedback} alt='Feedback Icon' style={{ width: '35px', marginRight: '0.5rem' }} />
              <Typography variant="h6" style={{ alignSelf: 'center' }}><b>Feedback</b>
                <Typography variant="subtitle1" style={{ lineHeight: '17px' }}>share your feedback regrading jobs</Typography>
              </Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.accordDetails}>
              <Grid container>
                <Grid item xs={12}>
                  <Formik>
                    <Box>
                      <form onSubmit={formik.handleSubmit} >
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <InputLabel> Select Topic</InputLabel>
                            <Select
                              variant='outlined'
                              id='select'
                              name='select'
                              fullWidth
                              onChange={formik.handleChange}
                              value={formik.values.select}
                              error={formik.touched.select && Boolean(formik.errors.select)}
                              helperText={formik.touched.select && formik.errors.select}
                            >
                              <MenuItem value={'UI designer'}>UI designer</MenuItem>
                              <MenuItem value={'Angular developer'}>Angular developer</MenuItem>
                              <MenuItem value={'Software Tester'}>Software Tester</MenuItem>
                            </Select>
                          </Grid>
                          <Grid container style={{ marginTop: '1rem' }}>
                            <Grid item xs={12}>
                              <InputLabel htmlFor="input-with-icon-adornment">
                                FeedBack
                              </InputLabel>
                              <TextField
                                id="feedback"
                                name="feedback"
                                fullWidth
                                multiline
                                onChange={formik.handleChange}
                                value={formik.values.feedback}
                                error={formik.touched.feedback && Boolean(formik.errors.feedback)}
                                helperText={formik.touched.feedback && formik.errors.feedback}
                                rows={4}
                                placeholder="Type in a short description"
                              />
                            </Grid>
                          </Grid>
                          <Grid container xs={12} style={{ margin: '1rem 0px' }}>
                            <Grid item>
                              <Button variant="contained" color='secondary' type="submit" style={{ marginRight: '1rem' }}>Submit</Button>
                              <Button
                                variant="outlined"
                                type="button"
                                name="cancel"
                                color='secondary'
                                value="cancel"
                                style={{ marginLeft: '-4px' }}
                                onClick={handleClose}
                              >
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Formik>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>  
        </Grid>
      </Grid>
    </Box>
  );
};
export default FeedbackForm;