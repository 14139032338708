import axios from './interceptedAxios';
import { toast } from 'react-toastify';
import { get, isUndefined } from 'lodash';
import { BASE_URL } from 'constants/index';


export function callApi(data, method, endpoint, authenticated, isAI) {
  
  const url = isAI ? endpoint : BASE_URL + endpoint;
  const token = localStorage.getItem('deepailytics.auth.token') || null;
  let config = { method, url, responseType: 'json' };
  if (isAI) {
    let username= 'svc_deepailytics_use';
    let password= '6KUvDr40cG';
    let basictoken = btoa(`${username}:${password}`);
    config.headers = { Authorization: `Basic ${basictoken}` };
  }
  if (authenticated) {
    if (!token) {
      return Promise.resolve('NO_TOKEN');
    }
    config.headers = { Authorization: `Bearer ${token}` };
  }

  if (data) {
    config.data = data;
  }

  return axios(config)
    .then((response) => {
      // set Token in localStorage
      const newToken = get(response, 'data.accessToken');
      if (!isUndefined(newToken)) {
        const userRole = get(response, 'data.userType');
        const refreshToken = get(response, 'data.refreshToken');
        localStorage.setItem('deepailytics.auth.token', newToken);
        localStorage.setItem('deepailytics.user.role', userRole);
        localStorage.setItem('deepailytics.auth.refreshToken', refreshToken);
      }

      return response;
    })
    .catch((errorResponse) => {
      let { code, response: { data: { message: error } } } = errorResponse;
      if (isUndefined(error)) {
        error = errorResponse;
        toast.error('Something went wrong.');
      } else {
        (error === 'Request failed with status code 401') ? toast.error('Invalid credentials') :
          toast.error(error);
      }

      if (code === 'REFRESH_TOKEN_NOT_VALID') {
        localStorage.removeItem('deepailytics.auth.token');
        localStorage.removeItem('deepailytics.user.role');
        localStorage.removeItem('deepailytics.auth.refreshToken');
        setTimeout(() => {
          window.location.href='/';
        }, 3000);
      }

      return error;
    });
}
