import React from 'react';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { Static_Ques_Data } from 'mockData/CandidateCardData';
import * as R from 'ramda';

const useStyle = makeStyles(() => ({
  qstNo: {
    textAlign: 'center', display: 'inline-block', borderRadius: 4, background: '#EEF3F9', padding: '10px',
  },
  bodyText: {
    color: '#8692A6', fontWeight: 200, padding: '0 0.7rem', lineHeight: '1.3rem'
  },
}));

// StaticQuestions

const StaticQuestions = () => {
  const classes = useStyle();
  return (
    <Box>
      {
        R.map((data) => {
          return (
            <Grid container style={{ marginBottom: '1.2rem' }}>
              <Grid item xs={1} align="left" style={{ alignSelf: 'flex-start' }}>
                <Typography className={classes.qstNo}> {data.id} </Typography>
              </Grid>
              <Grid item xs={11} align="left" style={{ alignSelf: 'flex-start' }}>
                <Typography variant='body2' className={classes.bodyText}>
                  {data.question}
                  <Typography variant='body1'>
                    {data.ans}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          );
        })(Static_Ques_Data)
      }
    </Box>
  );
};
export default StaticQuestions;