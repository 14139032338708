import JobPosting from '../pages/JobPosting';
import AddJobPost from '../pages/JobPosting/AddJobPost';
import JobReport from '../pages/JobPosting/JobReport';
import CompanyInfo from '../pages/Configuration/CompanyInfo/index';
import UserManagement from '../pages/UserManagement';
// import DPComingSoon from 'view/common/DPComingSoon';
import AllJobPostings from '../pages/JobPosting/AllJobsPosting/AllJobPostings';
import Activity from '../pages/JobPosting/AllJobsPosting/Activity';
import JobDetailsContainer from 'view/common/components/JobCard/JobDetailsContainer';
import ApplicantProfile from '../pages/JobPosting/ApplicantProfile';
import ApplicantJobProfileContainer from '../pages/JobPosting/ApplicantProfile/ApplicantJobProfileContainer';
import UserDetails from '../pages/UserManagement/UserDetails';
import Interviews from '../pages/Interviews/index';
import Schedule from '../pages/JobPosting/AllJobsPosting/Schedule'; 
import CalendarLanding from '../pages/Calendar/Calendar';
import Decision from '../pages/Decision';
import Interviewer from 'view/interviewer/pages/Home/Home';

export const RoutePath = {
  editJobDetails: '/employer/jobPosting/edit',
  jobDetails: '/employer/jobDetails',
  edit: '/employer/configuration/CompanyInfo',
  login: '/employer/login',
  allJobsPosting: '/employer/allJobs',
  activity: '/employer/activity',
  jobPosting: '/employer/jobposting',
  calendar: '/employer/calendar',
  schedule: '/employer/schedule',
  interviews: '/employer/interviews',
  Decision: '/employer/decision',
  userManagement: '/employer/usermanagement',
  userDetails: '/employer/usermanagement',
  company: '/employer/configuration/CompanyInfo',
  addJob: '/employer/jobposting/add',
  jobReport: '/employer/jobposting/jobreport',
  interviewerHome: '/employer/interviewer',
  home: '/'
};
export const routePaths = [
  {
    name: 'Home',
    link: RoutePath.jobPosting,
    activeIndex: 0,
    component: JobPosting,
  },
  {
    name: 'Calendar',
    link: RoutePath.calendar,
    activeIndex: 1,
    component: CalendarLanding,
  },
  {
    name: 'Interview(s)',
    link: RoutePath.interviews,
    activeIndex: 2,
    component: Interviews,
  },
  {
    name: 'Decision',
    link: RoutePath.Decision,
    activeIndex: 3,
    component: Decision,
  },
  {
    name: 'User Management',
    link: RoutePath.userManagement,
    activeIndex: 4,
    component: UserManagement,
  },
  {
    name: 'User Details',
    link: `${RoutePath.userDetails}/:userType/:id`,
    visible: false,
    component: UserDetails
  },
  {
    name: 'Add Job Postings',
    link: RoutePath.addJob,
    component: AddJobPost,
    visible: false
  },
  {
    name: 'Applicant Profile',
    link: '/employer/jobPosting/applicantProfile',
    component: ApplicantProfile,
    visible: false
  },
  {
    name: 'Edit',
    link: RoutePath.edit,
    component: CompanyInfo,
    visible: false
  },
  {
    name: 'All Jobs',
    link: RoutePath.allJobsPosting,
    component: AllJobPostings,
    visible: false
  },
  {
    name: 'Activity',
    link: `${RoutePath.activity}/:jobId`,
    component: Activity,
    visible: false
  },
  {
    name: 'Activitytable Rowclick',
    link: `${`${RoutePath.activity}/:jobId/:applicantId`}`,
    component: ApplicantJobProfileContainer,
    visible: false
  },
  {
    name: 'Job details',
    link: `${RoutePath.jobDetails}/:jobId`,
    component: JobDetailsContainer,
    visible: false
  },
  {
    name: 'Edit Job details',
    link: `${RoutePath.editJobDetails}/:id`,
    component: AddJobPost,
    visible: false
  },
  {
    name: 'Job Report',
    link: `${RoutePath.jobReport}/:jobId`,
    component: JobReport,
    visible: false
  },
  {
    name: 'Schedule',
    link: `${RoutePath.schedule}`,
    component: Schedule,
    visible: false
  },
  {
    name: 'InterviewerHome',
    link: `${RoutePath.interviewerHome}/:interviewId`,
    component: Interviewer,
    visible: false
  }
];
