import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { filter, find, get, isNull, result } from 'lodash';

import { default as MaterialUiSelectField } from '@material-ui/core/Select';
import {
  FormControl,
  FormHelperText,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fetchLovByKey } from 'redux/actions/lovActions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'none',
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SelectField = ({
  id,
  helperText,
  value,
  dropdownOptions,
  localePrefix,
  label,
  margin = 'none',
  error = false,
  displayKey = 'name',
  ...rest
}) => {
  const classes = useStyles();
  const { t : translate } = useTranslation();
  let lbl = null;
  if (localePrefix !== null) {
    lbl = translate(`${localePrefix}.LABEL`);
  }
  if (label) {
    lbl = label;
  }
  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={error}
      margin={margin}>
      {/* <InputLabel style={{ marginTop: '-15px' }} id={`${id}_extendedLablel`}>
        {translate(`${localePrefix}.LABEL`)}
      </InputLabel> */}
      <MaterialUiSelectField
        variant="outlined"
        key={id}
        value={value}
        labelId={`${id}_extendedLablel`}
        // label={lbl}
        placeholder={lbl}
        fullWidth
        multiple={false}
        renderValue={() => (
          <div automation-testid="dropdownOptions" className={classes.chips} key={value}>
            {filter(dropdownOptions, { id: value }).map(v => v[displayKey])}
          </div>
        )}
        MenuProps={MenuProps}
        {...rest}>
        {dropdownOptions.map(code => (
          <MenuItem automation-testid="dropdownOptionsMenu" key={code.id} value={code.id}>
            { code[displayKey]}
          </MenuItem>
        ))}
      </MaterialUiSelectField>
      <FormHelperText>{helperText} </FormHelperText>
    </FormControl>
  );
};

SelectField.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  dropDownData:PropTypes.array,

  value: PropTypes.string,
  type: PropTypes.string,
  InputLabelProps: PropTypes.object,
  error: PropTypes.bool,
  localePrefix: PropTypes.string,
  margin: PropTypes.string,
};

const FormikSelectField = (props) => {
  const { apiKey, handleAIDescriptionReqObject, isAIDescriptionActionRequired, AIDescriptionReqObject } = props;
  const options = useSelector(state => get(state, ['lov', apiKey], null));
  const dispatch = useDispatch();
  const [, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;
  const getOptions = () => {
    if (apiKey){
      return isNull(options) ? [] : options;
    }
    return get(props, 'dropDownData', []);
  };

  const [dropdownOptions, setDropdownOptions] = useState(getOptions());

  useEffect(() => {
    if (apiKey && isNull(options)) {
      dispatch(fetchLovByKey(apiKey));
    }
  }, []);

  useEffect(() => {
    if (options){
      setDropdownOptions(options);
    }
  }, [options]);

  return (
    <SelectField
      onChange={(evt) => {
        const selectedName = result(find( get(props, 'dropDownData', []), function(obj) {
          return obj.id === evt.target.value;
        }), 'name');
        isAIDescriptionActionRequired && handleAIDescriptionReqObject({ ...AIDescriptionReqObject, [props.name]: selectedName });
        setValue(evt.target.value);
      }}
      value={get (value ,['id'], value)}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && Boolean(meta.error) ? meta.error : undefined}
      dropdownOptions = {dropdownOptions}
      {...props}
      fullWidth
    />
  );
};

FormikSelectField.propTypes = {
  dropDownData: PropTypes.array,
  localePrefix: PropTypes.string,
  helperText: PropTypes.string,
};

export default SelectField;
export { FormikSelectField };
