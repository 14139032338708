import * as React from 'react';
import { Box } from '@material-ui/core';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { onGetLearning } from 'redux/actions/applicantDashboard';
import LearningCard from 'view/common/LearningCard/LearningCard';

const LearningCardList = () => {
  const [learninglist, setlearninglist] = useState({});
  const dispatch = useDispatch();
  const courses = useSelector(state => get(state, ['applicantDashboard', 'learning'], {}));
  const profileData = useSelector(state => R.pathOr('', ['userProfile', 'applicant'], state));
 
  useEffect(() => {
    if (profileData && !courses?.length) {
      let preparePayload = [];
      profileData?.professionalDetails?.map((experience) => {
        preparePayload.push(experience.description);
      });

      if (preparePayload.length > 2) {
        dispatch(onGetLearning({
          'Experience': preparePayload,
          'Certifications': ['NA']
        }));
      }
    }

  }, [profileData]);

  useEffect(() => {
    if (courses && courses[0] && courses[0][0]) {
      setlearninglist(courses[0][0]);
    }
  }, [courses]);
  
  return (
    <Box>
      <LearningCard data={learninglist} bgcolor='white' viewAllLink='/applicant/learning' height={true} />
    </Box>
  );
};
export default LearningCardList; 