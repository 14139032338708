import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Icon, Grid, Typography, Breadcrumbs, IconButton, Button, Box } from '@material-ui/core';
import ComposerPanelWrapper from '../../../common/wrappers/ComposerPanelWrapper';
import CompanyLocation from './CompanyLocation';
import { useTranslation } from 'react-i18next';
import rolepic from 'asset/icons/rolepic.svg';
import deletecard from 'asset/icons/delete.svg';
import ShowConfig from './ShowConfig';
import { filter } from 'lodash-es';
import OrganisationRoles from './OrganisationRoles';
import ViewInfo from './CompanyInfo/ViewInfo';
import SearchField from '../../../ui-components/atom/SearchBox/Search';
import JobTitle from './JobTitle';
import StaticQuestions from './StaticQuestion';
import InterviewQuestions from './InterviewQuestions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  customicon: {
    width: '30px',
  },
  mainContainer: {
    paddingLeft: '15em',
    paddingRight: '15em',
    paddingBottom: '10em',
    [theme.breakpoints.down('md')]: {
      marginTop: '3em',
      paddingLeft: '1em',
      paddingRight: '1em',
      paddingBottom: '1em',
    },
  },
  mainContainer1: {
    paddingLeft: '1.8em',
    paddingRight: '1.8em',
  },
  sidePanel: {
    background: 'linear-gradient(172.7deg, #FBF5FA 0%, #F5F2F8 54.17%, #F4F8FD 100%)',
    padding: '1rem',
    paddingTop: '3rem'
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem',
  },
  pageTitle: {
    ...theme.typography.title,
  },
  breadcumbLink: {
    ...theme.typography.linkLabel,
  },
  breadcumbText: {
    ...theme.typography.linkLabel,
    fontWeight: 400,
  },
  detailsCardContainer: {
    margin: '1rem 0',
    background: '#fff',
    padding: '10px 15px',
    borderRadius: '4px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      backgroundColor: '#E1E0FF',
    },
    '&:active': {
      backgroundColor: '#E1E0FF',
    },
  },
  cardDetailsContainer: {
    alignSelf: 'center',
    padding: '0 1rem',
    '&.MuiTypography-body1': {
      textAlign: 'justify'
    },
  },
}));

const rolesCard = [
  {
    roleName: 'HR Admin',
  },
  {
    roleName: 'HR Manager',
  },
  {
    roleName: 'HR Associate',
  },
  {
    roleName: 'Accounts Manager',
  },
];

const Configuration = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const RendererExample = () => {
    return null;
  };
  
  const PanelConfig = {
    isOpen: false,
    anchor: 'right',
    Renderer: matchesSM ? RendererExample : null, // ComponentRenderer
    rendererConfigs: {},
  };
  const { t : translate } = useTranslation();
  const [selectedConfig, setSelectedConfig] = useState(null);
  const classes = useStyles();
  const configRoutes = [
    { key: 'COMP_INFO', component: ViewInfo },
    { key: 'JOB_TITLES', component: JobTitle },
    { key: 'ORG_ROLES', component: OrganisationRoles },
    { key: 'STATIC_QUES', component: StaticQuestions },
    { key: 'INTERVIEW_FEEDBACK', component: InterviewQuestions },
    { key: 'COMP_LOC', component: CompanyLocation },
  ];

  const getComponent = (k) => {
    const com = filter(configRoutes, { key: selectedConfig }, []);
    if (com.length > 0) {
      const Component = com[0]['component'];
      return <Component key={k} />;
    }
    return '';
  };

  const loadComponent = (k) => {
    setSelectedConfig(k);
  };

  return (
    <>
      <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
        <Grid container xs={12} style={{ minHeight: '100vh' }}>
          <Grid item xs={12} md={ selectedConfig === 'ORG_ROLES' ? 9 : 12 } style={{ margin: '4rem 0' }}>
            <Grid
              container
              direction="column"
              className={ selectedConfig === 'ORG_ROLES' ? classes.mainContainer1 : classes.mainContainer }
              spacing={2}>
              <Grid item xs={12} md={8}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    onClick={()=> { 
                      if (selectedConfig) {
                        setSelectedConfig(null);
                      } 
                    }}
                    to="/employer/configuration"
                    className={`${classes.breadcumbLink} MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary`}>
                    {translate('DHIYO.CONFIGURATION.HEADER.TITLE')}
                  </Link>
                  {selectedConfig && (
                    <Typography className={classes.breadcumbText}>
                      {translate(`DHIYO.CONFIGURATION.CONFIG.${selectedConfig}`)}
                    </Typography>
                  )}
                </Breadcrumbs>
              </Grid>
              {selectedConfig && (
                <Grid item xs={12}>
                  <Typography className={classes.pageTitle}>
                    {translate(`DHIYO.CONFIGURATION.CONFIG.${selectedConfig}`)}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                {selectedConfig !== null ? (
                  getComponent()
                ) : (
                  <ShowConfig
                    key="showconfig"
                    items={configRoutes}
                    onSelection={loadComponent} />
                )}
              </Grid>
            </Grid>
          </Grid>
          {
            selectedConfig === 'ORG_ROLES' ? (
              <Grid item md={3} display={{ xs: 'none', lg: 'block' }} component={Box} className={classes.sidePanel}>
                <SearchField />
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h3" className={classes.title} style={{ margin: '1rem 0' }}>Roles</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ alignSelf: 'center', textAlign: 'end' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<Icon>add_circle</Icon>}
                      onClick={() => {}}
                      disableElevation
                    >
                      New Role
                    </Button>
                  
                  </Grid>
                </Grid>
                {
                  rolesCard.map((rolesCard) => {
                    return (
                      <Grid container className={classes.detailsCardContainer} xs={12}>
                        <Grid item container xs={11}>
                          <Grid item xs={2} style={{ flexBasis: 'auto', alignSelf: 'center', }}>
                            <img src={rolepic} alt='rolepic icon' className={classes.customicon} />
                          </Grid>
                          <Grid item xs={9} className={classes.cardDetailsContainer}>
                            <Typography variant="h6" className={classes.mainTitle}>{rolesCard.roleName}</Typography>                    
                          </Grid>
                        </Grid>
                        <Grid item xs={1} className={classes.btnContainer}>
                          <IconButton className={classes.actionIcon}>
                            <img src={deletecard} alt='delete card icon' className={classes.iconBtn} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })
                }
              
              </Grid>
            ) : ( '' )
          }
        
        </Grid>
      </ComposerPanelWrapper>
    </>
    
  );
};

export default Configuration;
