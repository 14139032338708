import React, { useState, useEffect } from 'react';
import { Icon, Grid, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddLocationForm from './AddJobListForm';
import { Popup, Notification, ConfirmDialog } from '../../../../ui-components';
import JobTitlesList from './JobTitleList';
import { onAddJobTitle, onEditJobTitle, onGetJobTitleList, onDeleteJobTitle } from 'redux/actions/employerActions/Configurations/jobActions';
import { useDispatch } from 'react-redux';
import { filter } from 'lodash-es';
import SearchBar from 'material-ui-search-bar';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';


const useStyles = makeStyles(() => ({
  searchBar: {
    width: 200,
    boxShadow: '0px 2px 6px rgb(0 0 0 / 5%)',
    borderRadius: 50,
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
    fontSize: 18,
    color: '#24283C',
    margin: '0 0 -24px 8px',
  },
  loginBtn: {
    display: 'none',
  },
  formContainer: {
    marginRight: '1rem',
    display: 'inline-flex',
  },
}));

const JobTitles = () => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const { t: translate } = useTranslation();
  const [updateData, setUpdateData] = useState(true);
  const [jobTitlesData, setJobTitleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [jobIntialValue, setJobInitialValue] = useState();
  const [search, setSearch] = useState('');
  const [searchField, setSearchField] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: () => {
      if (searchField.length > 2) {
        setSearch(searchField);
      } else {
        if (searchField.length === 2) {
          setSearch('');
        }
      }
    },
  });
  const dispatch = useDispatch();
  const getJobTitleList = () => {
    const payload = {
      search: search,
      pageNo: pageNumber,
      pageSize: pageSize,
      sortBy:'createdOn'
    };
    setIsLoading(true);
    dispatch(onGetJobTitleList(payload)).then((response) => {
      setJobTitleData(response && response.payload.content);
      setUpdateData(false);
      setIsLoading(false);
      setTotalElements(response?.payload?.totalElements || 0);
    });
  };

  useEffect(() => {
    getJobTitleList();
  }, [updateData, search, pageSize, pageNumber]);

  const addJobTitleorEditJobTitle = async (jobTitleData, resetForm) => {
    if (!jobTitleData.id) {
      // add jobTitle
      await dispatch(onAddJobTitle(jobTitleData)).then((response) => {
        {
          response && response.id && resetForm();
          setUpdateData(true);
          setModal(false);
          setNotify({
            isOpen: true,
            message: 'JobTitle Added Successfully',
            type: 'success',
          });
        }
      });
    } else {
      // update jobtitle
      await dispatch(onEditJobTitle(jobTitleData)).then((response) => {
        {
          response && response.id && resetForm();
          setUpdateData(true);
          setModal(false);
          setNotify({
            isOpen: true,
            message: 'JobTitle Updated Successfully',
            type: 'success',
          });
        }
      });
    }
  };

  const cancelSearch = () => {
    setSearch('');
  };

  const openInPopup = (id) => {
    let selectedRow = filter(jobTitlesData, { id }, []);
    if (selectedRow.length > 0) {
      setJobInitialValue(selectedRow[0]);
      setModal(true);
    }
  };

  const enableConfirmDialog = (data) => {
    setConfirmDialog(data);
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await dispatch(onDeleteJobTitle(id));
    setUpdateData(true);
    setNotify({
      isOpen: true,
      message: 'Deleted Successfully',
      type: 'error',
    });
  };

  const initialValues = {
    id: jobIntialValue ? jobIntialValue.id : '',
    title: jobIntialValue ? jobIntialValue.title : '',
    designation: jobIntialValue ? jobIntialValue.designation : '',
    department: jobIntialValue ? jobIntialValue.department.name : '',
    belongsToRecuritment: jobIntialValue ? jobIntialValue.belongsToRecuritment : '',
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} style={{ alignSelf: 'center' }} >
          <Typography className={classes.title}>Job Titles</Typography>
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: 'end' }}>
          
          <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
            <SearchBar 
              id="search" 
              name="search" 
              className={classes.searchBar}
              value={searchField}
              onChange={(searchvalue) =>{
                if (searchvalue.length === 0) {
                  setSearch('');
                }
                setSearchField(searchvalue);
              }} 
              onCancelSearch={() => cancelSearch()} />
            <Button color="primary" variant="contained" fullWidth type="submit" className={classes.loginBtn}>
                Login
            </Button>
          </form>
          <Button
            fullWidth={false}
            variant="contained"
            color="secondary"
            startIcon={<Icon>add_circle</Icon>}
            onClick={() => {
              setModal(true);
              setJobInitialValue('');
            }}>
            {translate('DHIYO.CONFIGURATION.JOBTITLE.ADD_FORM.FORM_NAME')}
          </Button>
        
        </Grid>
        <Grid item style={{ marginTop: '1em' }} xs={12}>
          <JobTitlesList 
            jobsData={jobTitlesData} 
            onDelete={onDelete} 
            openInPopup={openInPopup} 
            setConfirmDialog={enableConfirmDialog} 
            confirmDialog={confirmDialog} 
            isLoading={isLoading}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalElements={totalElements} />
        </Grid>
      </Grid>

      <Popup title={jobIntialValue && jobIntialValue.id ? 'Update Job Title' : translate('DHIYO.CONFIGURATION.JOBTITLE.ADD_FORM.FORM_NAME')} openPopup={modal} setOpenPopup={setModal}>
        <AddLocationForm jobIntialValue={jobIntialValue} initialValues={initialValues} addJobTitleorEditJobTitle={addJobTitleorEditJobTitle} onCancel={() => setModal(false)} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default JobTitles;
