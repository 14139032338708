import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmployerActions } from 'redux/actions/actionTypes';
import { getCompanyInfo, postCompanyInfo } from 'redux/restAPIs/employerAPI/companyInfo';
import { GET_COMAPNY_INFORMATION_SUCCESS } from '../../../../constants';

export const getCompanyInformation = createAsyncThunk(
  GET_COMAPNY_INFORMATION_SUCCESS,
  // eslint-disable-next-line no-unused-vars
  async (payload) => {
    const response = await getCompanyInfo();
    return response.data;
  }
);

export const postCompanyInformation = createAsyncThunk(
  EmployerActions.ADD_COMPANY_INFO,
  async (payload) => {
    const response = await postCompanyInfo(payload);
    return response;
  }
);
