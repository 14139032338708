import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5),
  },
  dialogTitle: {
    padding: '0px 24px',
  },
  titleBasicDetail: {
    ...theme.typography.title,
    color: '#24283C',
    fontSize: '1.2rem',
  },
}));

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={openPopup}
      maxWidth="md"
      classes={{ paper: classes.dialogWrapper }}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container >
          <Grid
            item
            xs={11}
            container
            alignContent="center"
            justifyContent="flex-start">
            <Typography
              className={classes.titleBasicDetail}
              style={{
                flexGrow: 1,
                verticalAlign: 'middle',
                justifyContent: 'center',
              }}>
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            container
            alignContent="center"
            justifyContent="flex-end">
            <IconButton
              color="secondary"
              aria-label="Close Dialog"
              onClick={() => {
                setOpenPopup(false);
              }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
 