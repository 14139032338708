import React from 'react';
import { Routes as Switch, Route, useLocation } from 'react-router-dom';
import Header from '../../common/components/Header/Header';
import { RoutePath, routePaths } from './Constants';
import { isEmpty, isNull, startsWith } from 'lodash';
import Footer from 'view/common/components/Footer/Footer';
import { isProfileCompleted } from '../../common/utils/userProfileUtil';
import DPNotFoundRoute from 'view/common/DPNotFoundRoute';
import Configuration from '../pages/Configuration';
import CompanyInfo from '../pages/Configuration/CompanyInfo';
import InterviewerHome from '../../interviewer/pages/Home/Home';

const Routes = () => {
  const location = useLocation();
  const isTokenPresent = localStorage.getItem('deepailytics.auth.token');
  // const isEmployerPath = !isNull(isTokenPresent) && startsWith(location.pathname, '/employer');
  const activeRoutePaths = isProfileCompleted() ? routePaths : [];
  const defaultPath = isEmpty(activeRoutePaths) ? '/employer/configuration' : '/employer/jobposting';
  const renderHeaderFooter = () => {
    const loggedInUserRole = localStorage.getItem('deepailytics.user.role');
    return !isNull(isTokenPresent) && startsWith(location.pathname, '/employer') || ['ROLE_EMPLOYER'].includes(loggedInUserRole);
  };
  return (
    <>
      {renderHeaderFooter() ? <Header routes={activeRoutePaths} /> : null}
      <Switch>
        {/* Add authenticated and protected routes here */}
        {!isEmpty(activeRoutePaths) ? routePaths.map((route) => {
          return (
            <Route
              key={route.name}
              exact
              path={route.link}
              element={<route.component />}
            />
          );
        }) : <Route path={RoutePath.edit} exact element={<CompanyInfo />} /> }

        <Route path='/employer/configuration' exact element={<Configuration />} />
        <Route path='/employer/interviewer' exact element={<InterviewerHome />} />
        <Route path='*' exact={true} element={<DPNotFoundRoute defaultPath={defaultPath} />} />
      </Switch>
      <div className='footer-container'>
        {renderHeaderFooter() ? <Footer /> : null }
      </div>
    </>
  );
};

export default Routes;
