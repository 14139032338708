import React, { useState } from 'react';
import { Button, DialogActions, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { onConfirmSlots, setSlotsConfirmed } from 'redux/actions/interviewerActions/interviewerSchedule';

const ConfirmApplicantSlotPopover = (props) => {
  const dispatch = useDispatch();
  const slots = useSelector(state => state.interviewManagement.slots);
  const [confirmed, setConfirmed] = useState(props.applicantConfirmation || false);
  
  const handleSubmit = (data) => {
    const slot = (slots || []).map((item) => {
      return item.id === data?.event_id ? { ...item, ...{        
        applicantConfirmation: true
      } }
        :
        item;
    });
    dispatch(onConfirmSlots(data.event_id));
    dispatch(setSlotsConfirmed(slot));
    setConfirmed(true);
  };

  return (
    <div>
      <div style={{ padding: '1rem' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography variant="subtitle1">Organisation Name: </Typography>
          <Typography variant="subtitle2" style={{ marginLeft: '10px' }}>{props.companyName}</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography variant="subtitle1">Job Title:</Typography>
          <Typography variant="subtitle2" style={{ marginLeft: '10px' }}>{props.jobTitle}</Typography>
        </div>
      </div>
      <DialogActions>
        <Button variant='contained'
          color='secondary'
          id='basic-button'
          aria-controls='basic-menu'
          aria-haspopup='true'
          disabled={confirmed}
          onClick={() => handleSubmit(props)}>Confirm</Button>
      </DialogActions>
    </div>
  );
};

export default ConfirmApplicantSlotPopover;