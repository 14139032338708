import React from 'react';
import { Divider, Drawer, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

const useStyles=makeStyles(()=>({
  root: {
    width: '450px',
    padding: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  title: {

  }
}));

const DPDrawer = ({ children, isDrawerOpen, anchor, dispatcherEvent, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Drawer
      anchor={anchor}
      open={isDrawerOpen}
      onClose={()=>dispatch(dispatcherEvent(false))}
      variant="temporary"
      classes={{
        paper: classes.root,
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Grid container xs={12} md={12} lg={12} style={{ marginTop: '1rem' }}>
        <Grid item xs={11}>
          <Typography variant="h4" className={classes.title} >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          container
          alignContent="center"
          justifyContent="flex-end">
          <IconButton
            color="secondary"
            aria-label="Close Dialog">
            <CloseIcon onClick={()=>dispatch(dispatcherEvent(false))} />
          </IconButton>
        </Grid>
        <Divider style={{ width: '100%' }} />
        <Grid container xs={12} md={12} lg={12} >
          <Grid item xs={12} md={12} lg={12} >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default DPDrawer;


DPDrawer.defaultProps = {
  anchor: 'right'
};
