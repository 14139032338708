import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DPInfiniteScrollable from './DPInfiniteScrollable';

const DPInfiniteScrollableWrapper = ({
  dispatcher,
  dataPath,
  itemRendererConfig,
  styleConfig,
  pageConfig,
  getRequestParams,
  setTotalItemsCountsInParent
}) => {
  const { pageSize } = pageConfig;
  const resp = useSelector(state => get(state, dataPath, {}));
  const { content = [], totalPages = 0, totalElements = 0, number = 0 } = resp;
  const [pageCount, setPageCount] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNoMoreData, setIsNoMoreData] = useState(false);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(totalElements);
  const [, setTotalPageCount] = useState(totalPages);

  const [items, setItems] = useState(content);
  const dispatch = useDispatch();

  const _loadNextPage = async () => {
    await setIsNextPageLoading(true);
    if (pageCount < totalPages || (pageCount === 0)) {
      setIsNoMoreData(pageCount === (Math.ceil(totalElements/pageSize) - 1));
      const payload = getRequestParams ? getRequestParams({ pageCount, pageSize }) : {
        pageNo: pageCount,
        pageSize: pageSize,
        search: ''
      };
      dispatch(dispatcher(payload)).then(async () => {
        setPageCount(pageCount + 1);
        setIsNextPageLoading(false);
      });
    } else {
      setHasNextPage(false);
    }
  };

  const cleanup = () => {
    setPageCount(0);
    setHasNextPage(true);
    setIsNoMoreData(false);
    setIsNextPageLoading(false);
    setTotalItems(0);
    setTotalPageCount(0);
    setItems([]);
  };

  useEffect(() => {
    return () => {
      if (number === (totalPages - 1)) {
        cleanup();
      }
    };
  }, []);

  useEffect(() => {
    if (number === 0) {
      setItems([]);
    }
    if (!isEmpty(content)){
      if (setTotalItemsCountsInParent) {
        setTotalItemsCountsInParent(totalElements);
      }
      
      if (items.length < totalElements) {
        const finalItems = number === 0 ? content : [...items, ...content];
        setItems(finalItems);
      }
    }
  }, [content]);

  useEffect(() => {
    setTotalPageCount(totalPages);
  }, [totalPages]);

  useEffect(() => {
    setTotalItems(totalElements);
  }, [totalElements]);

  return (
    <DPInfiniteScrollable
      hasNextPage={hasNextPage}
      isNextPageLoading={isNextPageLoading}
      items={items}
      totalItems={totalItems}
      loadNextPage={_loadNextPage}
      itemRendererConfig={itemRendererConfig}
      styleConfig={styleConfig}
      totalItemCount={totalPages || 1000}
      isNoMoreData={isNoMoreData}
    />
  );
};

export default DPInfiniteScrollableWrapper;
