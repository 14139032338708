export const experienceList = [
  {
    id: 'INTERN',
    label: 'Intern',
  },
  {
    id: 'BEGINNER',
    label: 'Beginner',
  },
  {
    id: 'INTERMEDIATE',
    label: 'Intermediate',
  },
  {
    id: 'SENIOR',
    label: 'Senior',
  },
];

export const jopTypeList = [
  {
    id: 'PERMANENT',
    label: 'Permanent',
  },
  {
    id: 'CONTRACT',
    label: 'Contract',
  },
  {
    id: 'FREELANCE',
    label: 'Freelance',
  },
  {
    id: 'INTERNSHIP',
    label: 'InternShip',
  },
  {
    id: 'REMOTE',
    label: 'Remote',
  },
];

export const workingHours = [
  {
    id: '00:00',
    name: '00:00'
  },
  {
    id: '01:00',
    name: '01:00'
  },
  {
    id: '02:00',
    name: '02:00'
  },
  {
    id: '03:00',
    name: '03:00'
  },
  {
    id: '04:00',
    name: '04:00'
  },
  {
    id: '05:00',
    name: '05:00'
  },
  {
    id: '06:00',
    name: '06:00'
  },
  {
    id: '07:00',
    name: '07:00'
  },
  {
    id: '08:00',
    name: '08:00'
  },
  {
    id: '09:00',
    name: '09:00'
  },
  {
    id: '10:00',
    name: '10:00'
  },
  {
    id: '11:00',
    name: '11:00'
  },
  {
    id: '12:00',
    name: '12:00'
  },
  {
    id: '13:00',
    name: '13:00'
  },
  {
    id: '14:00',
    name: '14:00'
  },
  {
    id: '15:00',
    name: '15:00'
  },
  {
    id: '16:00',
    name: '16:00'
  },
  {
    id: '17:00',
    name: '17:00'
  },
  {
    id: '18:00',
    name: '18:00'
  },
  {
    id: '19:00',
    name: '19:00'
  },
  {
    id: '20:00',
    name: '20:00'
  },
  {
    id: '21:00',
    name: '21:00'
  },
  {
    id: '22:00',
    name: '22:00'
  },
  {
    id: '23:00',
    name: '23:00'
  }
];

export const timeZones = [
  { name: '(GMT -12:00) Eniwetok, Kwajalein', id: '-12:00' },
  { name: '(GMT -11:00) Midway Island, Samoa', id: '-11:00' },
  { name: '(GMT -10:00) Hawaii', id: '-10:00' },
  { name: '(GMT -9:30) Taiohae', id: '-09:50' },
  { name: '(GMT -9:00) Alaska', id: '-09:00' },
  { name: '(GMT -8:00) Pacific Time (US &amp; Canada)', id: '-08:00' },
  { name: '(GMT -7:00) Mountain Time (US &amp; Canada)', id: '-07:00' },
  { name: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City', id: '-06:00' },
  { name: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima', id: '-05:00' },
  { name: '(GMT -4:30) Caracas', id: '-04:50' },
  { name: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz', id: '-04:00' },
  { name: '(GMT -3:30) Newfoundland', id: '-03:50' },
  { name: '(GMT -3:00) Brazil, Buenos Aires, Georgetown', id: '-03:00' },
  { name: '(GMT -2:00) Mid-Atlantic', id: '-02:00' },
  { name: '(GMT -1:00) Azores, Cape Verde Islands', id: '-01:00' },
  { name: '(GMT) Western Europe Time, London, Lisbon, Casablanca', id: '+00:00' },
  { name: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris', id: '+01:00' },
  { name: '(GMT +2:00) Kaliningrad, South Africa', id: '+02:00' },
  { name: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg', id: '+03:00' },
  { name: '(GMT +3:30) Tehran', id: '+03:50' },
  { name: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi', id: '+04:00' },
  { name: '(GMT +4:30) Kabul', id: '+04:50' },
  { name: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent', id: '+05:00' },
  { name: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi', id: '+05:30' },
  { name: '(GMT +5:45) Kathmandu, Pokhara', id: '+05:75' },
  { name: '(GMT +6:00) Almaty, Dhaka, Colombo', id: '+06:00' },
  { name: '(GMT +6:30) Yangon, Mandalay', id: '+06:50' },
  { name: '(GMT +7:00) Bangkok, Hanoi, Jakarta', id: '+07:00' },
  { name: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong', id: '+08:00' },
  { name: '(GMT +8:45) Eucla', id: '+08:75' },
  { name: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk', id: '+09:00' },
  { name: '(GMT +9:30) Adelaide, Darwin', id: '+09:50' },
  { name: '(GMT +10:00) Eastern Australia, Guam, Vladivostok', id: '+10:00' },
  { name: '(GMT +10:30) Lord Howe Island', id: '+10:50' },
  { name: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia', id: '+11:00' },
  { name: '(GMT +11:30) Norfolk Island', id: '+11:50' },
  { name: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka', id: '+12:00' },
  { name: '(GMT +12:45) Chatham Islands', id: '+12:75' },
  { name: '(GMT +13:00) Apia, Nukualofa', id: '+13:00' },
  { name: '(GMT +14:00) Line Islands, Tokelau', id: '+14:00' }
];