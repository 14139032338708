import { getDateFormatter, getLocationFormatter } from 'utils/DPTableFormatters';

export const allRecentJobPostingCols = [
  {
    id: 'jobCode',
    label: 'Job Code',
    minWidth: 100,
  },
  {
    id: 'jobTitle',
    label: 'Job Title',
    minWidth: 140,
    dataPath: ['companyJobTitle', 'title'],
  },
  {
    id: 'jobLocation',
    label: 'Location',
    minWidth: 220,
    formatter: getLocationFormatter(['employerLocation'])
  },
  // {
  //   id: 'candidate',
  //   label: 'Candidate',
  //   minWidth: 100,
  // },

  {
    id: 'expiry',
    label: 'Expiry Date',
    minWidth: 125,
    formatter: getDateFormatter('')
  },
  {
    id: 'createdBy',
    label: 'Posted By',
    minWidth: 200,
  },
  {
    id: 'application',
    label: 'Applications',
    minWidth: 80,
  },
  // {
  //   id: 'interviewstatus',
  //   label: 'Interview Status',
  //   minWidth: 120,
  //   cellRenderer: 'colorCellRenderer',
  //   rendererProps: {
  //     colorMap: {
  //       'round1': ['Round 1', '#EFFFF3', '#1DBA44'],
  //       'round2': ['Round 2', '#1DBA44', '#D93131'],
  //       'round3': ['Round 3', '#D93131', '#FFC000']
  //     },
  //     fieldPath: ['interviewstatus']
  //   }
  // },
  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    cellRenderer: 'dpMenuActionCellRenderer'
  }
];

export const allJobPostingHistoryCols = [
  {
    id: 'jobCode',
    label: 'Job Code',
    minWidth: 100,
  },
  {
    id: 'jobTitle',
    label: 'Job Title',
    minWidth: 100,
    dataPath: ['companyJobTitle', 'title'],
  },
  {
    id: 'jobLocation',
    label: 'Location',
    minWidth: 30,
    dataPath: ['employerLocation', 'city'],

  },
  {
    id: 'openPositions',
    label: 'Number of Positions',
    minWidth: 120,
  },
  {
    id: 'postedOn',
    label: 'Posted On',
    minWidth: 40,
  },
  {
    id: 'createdBy',
    label: 'Posted By',
    minWidth: 120,
  },
  {
    id: 'application',
    label: 'Applications',
    minWidth: 120,
  },
  {
    id: 'jobStatus',
    label: 'Status',
    minWidth: 120,
    cellRenderer: 'colorCellRenderer',
    rendererProps: {
      colorMap: {
        closed: ['Closed', '#FFC000', '#373E5A'],
      },
      fieldPath: ['jobStatus']
    }
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 120,
  },
];

export const AJTabColumns = {
  recent: allRecentJobPostingCols,
  history: allJobPostingHistoryCols,
};

export const AJNestedTabWiseList = [
  {
    id: 'recent',
    label: 'Recent Job Posting',
  },
  {
    id: 'history',
    label: 'History',
  },
];
