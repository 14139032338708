import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOrganizationRoles } from 'redux/restAPIs/employerAPI/organizationRoles';
import { EmployerActions } from '../actionTypes';

export const onGetAllOrgRoles = createAsyncThunk(
  EmployerActions.GET_ORGANIZATION_ROLES,
  async (payload) => {
    const response = await getOrganizationRoles(payload);
    return response.data;
  }
);