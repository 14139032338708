import React from 'react';
import { Button, Icon, Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { updateInterviewDetails } from 'redux/slices/employerSlices/employerInterviewManagement';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  scheduleButton: {
    marginTop: '2rem',
    marginBottom: '2rem'
  }
}));
const ScheduleInterview = (data) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onScheduleClick = () => {
    dispatch(updateInterviewDetails(data));
    navigate('/employer/schedule');
  };

  return (
    <Box className={classes.scheduleButton} >
      <Button
        variant='contained'
        color='secondary'
        id='basic-button'
        aria-controls='basic-menu'
        aria-haspopup='true'
        onClick={onScheduleClick}
        startIcon={<Icon>add_circle</Icon>}>
                  Schedule Interview
      </Button>
    </Box>
  );
};

export default ScheduleInterview;