import React from 'react';
import EditIcon from 'asset/icons/EditIcon.svg';
import DeleteIcon from 'asset/icons/DeleteIcon.svg';
import { IconButton, Box } from '@material-ui/core';

const ActionCellRenderer = ({ row, openInPopup, setConfirmDialog, onDelete }) => {
  return (
    <Box>
      <IconButton color="secondary" aria-label="Edit Data" onClick={() => openInPopup(row.id)}>
        <img src={EditIcon} />
      </IconButton>
      <IconButton
        color="secondary"
        aria-label="Delete Data"
        onClick={() => {
          setConfirmDialog({
            isOpen: true,
            title: 'Are you sure to delete this record?',
            subTitle: 'You can\'t undo this operation',
            onConfirm: () => {
              onDelete(row.id);
            },
          });
        }}>
        <img src={DeleteIcon} />
      </IconButton>
    </Box>
  );
};

export default ActionCellRenderer;