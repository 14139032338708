import React from 'react';
import PropTypes from 'prop-types';
import { default as MaterialUiSelectField } from '@material-ui/core/Select';
import {
  Checkbox,
  ListItemText,
  MenuItem, 
  Chip, 
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { makeStyles , useTheme } from '@material-ui/core/styles';
import { colors } from '../../../../themes/colors';

import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => ({
  formControl: {},
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'none',
  },
  chip: {
    margin: 1,
    backgroundColor: `${colors.blue1}`,
    color: 'white',
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(key, selectedKeys, theme) {
  return {
    fontWeight:
      selectedKeys.indexOf(key) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MultiSelect = ({
  onChange,
  id,
  required = false,
  fullWidth = true,
  value = [],
  dropDownData,
  localePrefix,
  itemKeyPrefix,
  name,
}) => {
  const classes = useStyles();
  const { t : translate } = useTranslation();
  const theme = useTheme();

  const onchangeField = (evt) => {
    onChange(evt);
  };
 
  return (
    <FormControl
      variant="outlined"
      required={required}
      fullWidth={fullWidth}
      className={classes.formControl}>
      <InputLabel id={`${id}_extendedLablel`}>
        {translate(`${localePrefix}.LABEL`)}
      </InputLabel>
      <MaterialUiSelectField
        variant="outlined"
        id={id}
        multiple
        name={name}
        value={value}
        onChange={onchangeField}
        labelId={`${id}_extendedLablel`}
        placeholder={translate(`${localePrefix}.LABEL`)}
        fullWidth={fullWidth}
        renderValue={selected => (
          <div className={classes.chips}>
            {selected.map(value => (
              <Chip
                key={value}
                label={
                  itemKeyPrefix ? translate(`${itemKeyPrefix}.${value}`) : value
                }
                className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}>
        {dropDownData.map(code => (
          <MenuItem
            key={code}
            value={code}
            style={getStyles(code, value, theme)}>
            <Checkbox checked={value.indexOf(code)> -1} />
            <ListItemText
              primary={
                itemKeyPrefix ? translate(`${itemKeyPrefix}.${code}`) : code
              } />
          </MenuItem>
        ))}
      </MaterialUiSelectField>
    </FormControl>
  );
};

MultiSelect.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
  InputLabelProps: PropTypes.object,
  error: PropTypes.bool,
  localePrefix: PropTypes.string,
  itemKeyPrefix: PropTypes.string,
  name: PropTypes.string,
};
export default MultiSelect;
