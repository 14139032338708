import React from 'react';
import { UserForms } from 'view/common/utils/userProfileUtil';
import { useLocation } from 'react-router-dom';
import UserProfileWrapper from 'view/common/wrappers/UserProfileWrapper';

const EmployerLogin = () => {
  const location = useLocation();
  
  return (
    <UserProfileWrapper
      renderer={UserForms.interviewerLoginForm}
      role={location.state.role} />
  );
};

export default EmployerLogin;