import { Button, Icon, Box, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserForm from '../forms/UserForm';
import UserFilter from './UserFilter';
import { addEmpUserMap } from 'constants/employer/userManagement';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import SearchBar from 'material-ui-search-bar';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  btn: {
    margin: '1rem 0',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem'
    },
  },
  searchBtnBox: {
    columnGap: '1rem',
    alignItems: 'center',
    justifyContent: 'end',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      margin: '1rem 0'
    },
  },
  search: {
    height: '36px',
    boxShadow: '0px 2px 6px rgb(0 0 0 / 5%)',
    borderRadius: 50,
    width: '270px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  btnSize: {
    height: '36px',
  }
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  m: '0 auto',
  borderRadius: 6,
};

const UserManagementTopBar = ({
  currentTab,
  dispatcher
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [search, setSearch] = useState('');

  const pageOptions = useSelector(state => get(state, ['userManagement', 'pageOptions'], {
    pageSize: 5,
    pageNo: 1,
    search: ''
  }));

  const onSearchChange = (searchVal) => {
    setSearch(searchVal);
  };

  const cancelSearch = () => {
    setSearch('');
    dispatch(dispatcher({
      ...pageOptions,
      search: ''
    }));
  };

  const toggleModal = (value) => {
    setOpenModal(value);
  };

  const toggleFilter = (value) => {
    setOpenFilter(value);
  };

  const onSearch = (value) => {
    if (value.length > 3) {
      dispatch(dispatcher({
        ...pageOptions,
        search: value
      }));
    }
  };

  useEffect(() => {
    setSearch('');
  }, [currentTab]);

  return (
    <Box className={classes.searchBtnBox}>
      <SearchBar
        value={search}
        className={classes.search}
        onChange={onSearchChange}
        onCancelSearch={cancelSearch}
        onRequestSearch={onSearch}
      />
      <Button
        color='secondary'
        type="button"
        variant="outlined"
        onClick={() => toggleFilter(true)}
        className={classes.btnSize}
      >Filters</Button>
      <Modal
        onClose={() => toggleFilter(false)}
        open={openFilter}
      >
        <Box sx={style}>
          <UserFilter
            handleClose={() => toggleFilter(false)}
            currentTab={currentTab}
            formLabel={addEmpUserMap[currentTab]}
          />
        </Box>
      </Modal>
      <Button
        className={clsx(classes.btn, classes.btnSize)}
        color='secondary'
        type="button"
        variant="contained"
        startIcon={<Icon>add_circle</Icon>}
        sx={{ width: '200px' }}
        onClick={() => toggleModal(true)}
      >{`New ${addEmpUserMap[currentTab]}`}</Button>
      <Modal
        open={openModal}
        onClose={() => toggleModal(false)}
      >
        <Box sx={style}>
          <UserForm
            onCancel={() => toggleModal(false)}
            currentTab={currentTab}
            formLabel={addEmpUserMap[currentTab]}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default UserManagementTopBar;