import React, { useState } from 'react';
import { Grid, IconButton, Box, Typography, Avatar, Modal, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { get, map } from 'lodash';
import { onUserActiveStatusChange } from 'redux/actions/employerActions/userManagementActions';
import UserForm from '../forms/UserForm';
import { addEmpUserMap } from 'constants/employer/userManagement';
import Tooltip from '@material-ui/core/Tooltip';
import OpenEye from 'asset/icons/openeye.svg';
import EditIcon from 'asset/icons/EditIcon.svg';
import { makeStyles } from '@material-ui/styles';
import PhoneIcon from 'asset/icons/phone.svg';
import EmailIcon from 'asset/icons/openmail.svg';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'view/employer/route/Constants';

const style = {
  position: 'absolute',
  top: '48%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: '#fff',
  boxShadow: 24,
  p: 4,
  m: '0 auto',
  borderRadius: 6,
};

const TMP_INTERVIEWE_ACTIVITIES = [
  {
    label: 'Create Job Post'
  },
  {
    label: 'Schedule Interview'
  }
];

const useStyle = makeStyles(theme => ({
  card: {
    '&.MuiCard-root': {
      padding: '12px',
    }
  },
  typography: {
    '&.MuiTypography-root': {
      fontWeight: 'bold',
      marginTop: '15px'
    }
  },
  box: {
    marginTop: '20px',
    backgroundColor: 'White',
    borderRadius: 4,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
    padding: '1rem'
  },
  avtar: {
    '&.MuiAvatar-root': {
      marginRight: '2px',
      marginTop: '11px',
      marginLeft: '10px',
    }
  },
  post: {
    '&.MuiTypography-root': {
      color: 'darkgrey',
      fontWeight: '200',
    }
  },
  icon: {
    '&.MuiSvgIcon-root ': {
      marginLeft: '-20px',
    }
  },
  jobHandling: {
    '&.MuiTypography-root': {
      marginLeft: '70px',
      marginTop: '12px',
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        marginLeft: '10px'
      },
    }
  },
  activites: {
    '&.MuiTypography-root': {
      color: 'darkgray',
    }
  },
  chipbox: {
    '&.MuiBox-root': {
      marginLeft: '70px',
      marginTop: '15px',
      paddingBottom: '20px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '10px'
      },
    }
  },
  pageTitle: {
    ...theme.typography.title,
    marginTop: '2rem',
  },
  iconBtn: {
    padding: '0 1rem',
  },
  section: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px'
    },
  }
}));

const UserProfile = ({ 
  userDetails, 
  userType,
  userData,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const onEditClick = () => {
    setOpenModal(true);
  };

  const onDeactivateUser = () => {
    const updatedUserData = {
      userType,
      activate: false,
      id: userData.id
    };
    dispatch(onUserActiveStatusChange(updatedUserData)).then(() => {
      navigate(RoutePath.userManagement);
    });
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box className={classes.box}>
        <Box>
          <Grid container>
            <Grid item container xs={9} md={11}>
              <Grid item xs={12} md={8} style={{ display: 'flex' }}>
                <div>
                  <Avatar src="/broken-image.jpg" className={classes.avtar} />
                </div>
                <div>
                  <Box style={{ marginLeft: '1rem' }} >
                    <Typography className={classes.typography} > {get(userDetails, 'name', '-')} </Typography>
                    <Typography className={classes.post}> {get(userDetails, ['role', 'displayName'], userType === 'interviewer' ? 'Interviewer' : '')}</Typography>
                  </Box>

                  <Box sx={{ margin: '0.5rem 0' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="Contact">
                        <IconButton aria-label="delete" className={classes.iconBtn}>
                          <img src={PhoneIcon} />
                        </IconButton>
                      </Tooltip>
                      <Typography variant="p" gutterBottom component="div">{get(userDetails, 'contactNumber', '-')}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="E-Mail">
                        <IconButton aria-label="delete" className={classes.iconBtn}>
                          <img src={EmailIcon} />
                        </IconButton>
                      </Tooltip>
                      <Typography variant="p" gutterBottom component="div">{get(userDetails, 'email', '-')}</Typography>
                    </Box>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className={classes.section}>
                <Typography className={classes.typography}> Activities </Typography>
                {
                  map(TMP_INTERVIEWE_ACTIVITIES, item => <Typography className={classes.activites}>{item.label}</Typography>)
                }
              </Grid>
            </Grid>
            <Grid item xs={3} md={1} style={{ textAlign: 'end' }}>
              <Tooltip title="Edit">
                <IconButton onClick={onEditClick} >
                  <img src={EditIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Deactivate">
                <IconButton aria-label="delete" type="button" onClick={onDeactivateUser} >
                  <img src={OpenEye} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              {
                userType !== 'interviewer' && (
                  <>
                    <Typography className={classes.jobHandling}> Job Title Handling</Typography>
                    <Box className={classes.chipbox}>
                      {
                        map(get(userDetails, ['employeeJobTitleXrefs'], []), (item) => {
                          return <Chip label={get(item, ['jobTitle', 'title'], '')} variant="outlined" color="secondary" />;
                        })
                      }
                    </Box>
                  </>
                )
              }
            </Grid>
          </Grid>
        </Box>

        <Modal
          open={openModal}
          onClose={handleClose}
        >
          <Box sx={style}>
            <UserForm
              onCancel={handleClose}
              currentTab={userType}
              formLabel={addEmpUserMap[userType]}
              formData={userDetails}
            />
          </Box>
        </Modal>
      </Box>
    </>
  );
};
export default UserProfile;