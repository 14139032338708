import { Container, Grid, InputLabel, TextField, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import StartEndDatePicker from 'view/common/components/StartEndDatePicker/StartEndDatePicker';

const useStyle = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    alignItems: 'baseline'
  }
}));

const ProjectDetailForm = (props) => {
  const { id, formik, setIsError } = props;
  const classes = useStyle();

  return (<Container style={{ padding: '2rem' }} >
    <form onSubmit={formik.handleSubmit} id={id}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} md={6}>
          <InputLabel htmlFor="TextField-input" required>Project Title</InputLabel>
          <TextField variant="outlined"
            id="title"
            placeholder='Enter project title'
            name="projectTitle"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.projectTitle}
            fullWidth
            helperText={formik.errors.projectTitle}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel htmlFor="TextField-input" required>Role</InputLabel>
          <TextField
            variant="outlined"
            id="role"
            placeholder='Enter role'
            name="role"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.role}
            fullWidth
            helperText={formik.errors.role}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <StartEndDatePicker
            formik={formik}
            showEndDate={!formik.values.currentProject}
            setIsError={setIsError}
          />
        </Grid>

      </Grid>
      <Grid
        item
        xs={12}
        className={classes.switchContainer}
      >
        <FormGroup>
          <FormControlLabel
            defaultChecked={formik.values.currentProject}
            control={<Switch
              checked={formik.values.currentProject}
              onChange={value => formik.setFieldValue('currentProject', value.target.checked)}
            />}
            labelPlacement="start"
            label="Current Project"
            name="currentProject"
            value={formik.values.currentProject}
            fullWidth
          />
        </FormGroup>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel htmlFor="TextField-input" style={{ textTransform: 'none' }} required>Details of Project</InputLabel>
          <TextField
            style={{ padding: '0px 0 7px' }}
            id="multiline-static"
            placeholder="Enter details of project"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            name="details"
            value={formik.values.details}
            onChange={formik.handleChange}
            helperText={formik.errors.details}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel htmlFor="TextField-input" required>Responsibilities</InputLabel>
          <TextField
            style={{ padding: '0px 0 7px' }}
            id="multiline-static"
            placeholder="Enter responsibilities"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            name="responsibilities"
            value={formik.values.responsibilities}
            onChange={formik.handleChange}
            helperText={formik.errors.responsibilities}
          />
        </Grid>
      </Grid>
    </form>
  </Container >
  );
};

export default ProjectDetailForm;