import { CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, Grid } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { onGetJobDetails } from 'redux/actions/applicantDashboard';
import { useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import clsx from 'clsx';
import { fetchLovByKey } from 'redux/actions/lovActions';

const useStyle = makeStyles(theme => ({
  jobID: {
    display: 'inline-flex',
    border: '1px solid #8692A6',
    padding: '0px 5px',
    borderRadius: 4,
    marginLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: '1rem'
    },
  },
  mainJobid: {
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '32px'
    },
  },
  font: {
    fontSize: '18px',
  },
  positionsData: {
    color: '#5755BE',
    cursor: 'pointer'
  },
}));

const bulletPoint = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '13px', color: '#000' }}
  >
    •
  </Box>
);

const ActivityCard = ({ applied }) => {
  const classes = useStyle();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activityDetailsData, setActivityDetailsData] = useState({});
  const [qualificationName, setQualificationName] = useState(null);


  const getQualifications = async () => {
    const { payload } = await dispatch(fetchLovByKey('qualification'));
    if (payload && payload.data) {
      const qualificationObj = payload.data.items.find(qualification => qualification.id === get(activityDetailsData, ['qualification'], ''));
      if (qualificationObj && qualificationObj.name) {
        setQualificationName(qualificationObj.name);
      }
    }
  };

  useEffect(() => {
    dispatch(onGetJobDetails({
      id: params.jobId
    })).then((res) => {
      setActivityDetailsData({
        ...res.payload,
      });

    });
  }, []);

  useEffect(() => {
    if (!isEmpty(activityDetailsData)) {
      getQualifications();
    }
  },[activityDetailsData]);
  
  const onJobDetailsClick = () => {
    navigate(`/employer/jobDetails/${params.jobId}`, {
      state: {
        isApplicant: false
      }
    });
  };

  return (
    <Box>
      <Card style={{ marginTop: '20px', marginBottom: '20px' }} elevation={0}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <Typography variant="h4" className={classes.mainJobid}>
                {get(activityDetailsData, ['companyJobTitle', 'title'], '')} | {get(activityDetailsData, ['employerLocation', 'city'], '')},{get(activityDetailsData, ['employerLocation', 'state'], '')}
                <Typography variant="subtitle1" className={classes.jobID}>
                  Job ID :{get(activityDetailsData, ['jobCode'], '')}
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} style={{ textAlign: 'end', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Typography variant="h6" color="secondary" style={{ marginRight: '1.5rem', cursor: 'pointer' }} onClick={onJobDetailsClick}>Job Details</Typography>
              <Typography variant="h6"> 1 days ago</Typography>
            </Grid>
          </Grid>

          <Grid container xs={12} style={{ marginBottom: '1.2rem', marginTop: '0.3rem', justifyContent: 'space-between' }}>
            <Grid item xs={12} md={10} style={{ display: 'flex' }}>
              <Typography variant="h3" className={classes.font}>
                10 Positions {bulletPoint}
              </Typography>
              <Typography variant="h3" className={clsx(classes.positionsData, classes.font)} >
                {applied} Applied {bulletPoint}
              </Typography>
              <Typography variant="h3" className={clsx(classes.positionsData, classes.font)} >
                {get(activityDetailsData, [''], 0)} Shortlisted
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} style={{ textAlign: 'end' }}>
              <Typography variant="subtitle1">
                Expires  {get(activityDetailsData, ['expiryDate'], '')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container xs={12}>
            <Grid item xs={12} md={2} >
              <Typography variant="subtitle1" >
                Experience
              </Typography>
              <Typography variant="subtitle2">
                {get(activityDetailsData, ['experienceMin'], '')} - {get(activityDetailsData, ['experienceMax'], '')} years
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} >
              <Typography variant="subtitle1" >
                Employement
              </Typography>
              <Typography variant="subtitle2">
                {get(activityDetailsData, ['jobType'], '')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} >
              <Typography variant="subtitle1">
                Salary
              </Typography>
              <Typography variant="subtitle2">
                {get(activityDetailsData, ['salaryMin'], '')} - {get(activityDetailsData, ['salaryMax'], '')} Lakhs per annum
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} >
              <Typography variant="subtitle1">
                Education
              </Typography>
              <Typography variant="subtitle2" >
                {qualificationName}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

    </Box>
  );
};
export default ActivityCard;