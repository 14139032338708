import React from 'react';
import { Button, Card, CardContent, Grid, Avatar, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { floor, get } from 'lodash';
import Modal from '@mui/material/Modal';
import ShortlistPopup from 'view/employer/pages/JobPosting/AllJobsPosting/ShortlistPopup';

const useStyle = makeStyles(() => ({
  jobCard: {
    marginBottom: '1.3rem',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    padding: '0.5rem 0'
  },
  percent: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'right',
    color: '#FF5B15',
    marginTop: '0.3rem'
  },
  smallBtn: {
    fontWeight: 100,
    marginTop: '0.5rem'
  },
  
}));

const CandidateCard = ({ data }) => {
  const classes = useStyle();
  // eslint-disable-next-line no-unused-vars

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        marginTop: '0.5rem'
      }} >
      <Card elevation={0} className={classes.jobCard}>
        <CardContent style={{ padding: '10px 25px' }}>
          <Grid container>
            <Grid item xs={9} style={{ display: 'flex' }}>
              <Avatar style={{ marginRight: '0.8rem' }} />
              <Grid container style={{ display: 'flex' }}>
                <Grid item xs={12} style={{ marginTop: '17px' }}>
                  <Typography variant="h4" style={{ fontSize: '17px', lineHeight: 0 }}>
                    {get(data, ['applicant', 'firstName'], '')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2' style={{ lineHeight: '1rem' }}>
                    {get(data, ['jobTitle'])}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" style={{ lineHeight: 0 }}>
                    {get(data, ['applicant', 'city'], '-')},  {get(data, ['applicant', 'state'], '')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3} style={{ textAlign: 'end' }}>
              <Typography variant='subtitle1' style={{ fontSize: '12px' }}>Compatibility</Typography>
              <Typography className={classes.percent}>
                {floor(get(data, ['compatibility']), 2)}%
              </Typography>
              <Button variant="contained" color="secondary" className={classes.smallBtn} size='small' disableElevation onClick={handleClick}>Shortlist</Button>
              <Modal style={{ marginLeft:'350px', marginTop: '160px' }}
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <ShortlistPopup onClose={handleClose} />
              </Modal>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CandidateCard;