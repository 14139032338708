import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, AppBar, Card, CardContent, IconButton, Divider, Link, Chip, Toolbar, Grid, Typography, Menu, MenuItem, Box, TextField, Button, InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Applicant from 'view/applicant/';
import Employer from 'view/employer/';
import Interviewer from 'view/interviewer/';
import MenuIcon from '@mui/icons-material/Menu';
import { isNull } from 'lodash';
import PublicRoutes from './PublicRoutes';
import HomeBtns from '../ui-components/atom/Button/HomeBtns';
import Logo from 'asset/Logo.svg';
import DHIYO from 'asset/DHIYO.svg';
import HeroImg1 from 'asset/images/homeimg1.png';
import HomeBg1 from 'asset/images/homebg1.png';
import BgTriangle from 'asset/icons/bgtriangle.svg';
import BussinessImg from 'asset/images/bussinessimg.png';
import EmployersImg from 'asset/images/employersimg.png';
import JobSeekerImg from 'asset/images/jobseekerimg.png';
import Logo1 from 'asset/images/logo1.svg';
import Logo2 from 'asset/images/logo2.svg';
import Logo3 from 'asset/images/logo3.svg';
import Logo4 from 'asset/images/logo4.svg';
import Logo5 from 'asset/images/logo5.svg';
import Quote from 'asset/icons/quote.svg';
import HappyClient from 'asset/icons/happyClient.svg';
import checkcircle from 'asset/icons/checkcircle.svg';
// import checkcirclehover from 'asset/icons/checkcirclehover.svg';
import CustomBtn from '../common/components/CustomBtn/CustomBtn';
import Image from 'asset/images/pricecardbg.svg';
import testimonialBg from 'asset/images/testimonialbg.svg';
import pricingBg from 'asset/images/pricingbg.svg';
import Linkedin from 'asset/icons/linkedincir.svg';
import Fb from 'asset/icons/fbcir.svg';
import Twitter from 'asset/icons/twittercir.svg';
import Insta from 'asset/icons/instacir.svg';
import WhiteArrow from 'asset/icons/whitearrow.svg';
import BlueArrow from 'asset/icons/bluearrow.svg';
import '../../../src/Style.css';
const pages = ['Why we', 'Our vision', 'How it works'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const footerLinks = ['Privacy Policy', 'Terms and Conditions', 'Support'];

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: 'transparent',
  },
  navItem: {
    flexGrow: 1, justifyContent: 'flex-end', display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
  },
  logoName: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  logoSize: {
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    },
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    },
  },
  homeText: {
    fontFamily:
      [
        'Inter',
        'poppins',
        'sans-serif',
      ].join(','),
    color: '#333333',
    fontSize: '36px',
    lineHeight: '46px',
  },
  subText: {
    fontFamily:
      [
        'Inter',
        'poppins',
        'sans-serif',
      ].join(','),
    fontSize: '20px',
    lineHeight: '30px',
    margin: '1rem 0',
  },
  paraText: {
    fontSize: '13px',
    lineHeight: '20px',
    color: '#848199',
    fontWeight: 300,
  },
  quote: {
    margin: '0 1rem', placeSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  companyLogos: {
    marginTop: '13rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5rem',
    },
  },
  testimonialBg: {
    marginTop: '2rem', position: 'relative', bottom: '-5rem',
    backgroundImage: `url(${testimonialBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'initial',
    backgroundPosition: 'right top',
  },
  empPlan: {
    marginTop: '8rem',
    backgroundImage: `url(${pricingBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'initial',
    backgroundPosition: 'left bottom',
  },
  priceCard: {
    padding: '1.5rem 1rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundImage: `url(${Image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'right',
      backgroundColor: '#2754CE',
      boxShadow: '0px 42px 34px rgba(82, 67, 194, 0.295755)',
      borderRadius: '26px',
      transform: 'scale(1.1)',
      '& $paraText': {
        color: '#fff'
      },
      '& $amt': {
        color: '#fff'
      },
      '& $amt1': {
        color: '#fff'
      },
      '& $amt2': {
        color: '#fff'
      },
      '& $popularChip': {
        display: 'block',
      },
      '& $pChip': {
        background: '#4031B3',
      },
    },
  },
  circleIcon: {
    marginRight: '10px',
  },
  amt: {
    color: '#231D4F',
    fontSize: '36px',
    fontWeight: '600',
  },
  amt1: {
    fontSize: '26px',
    marginTop: '2.5rem',
    color: '#24283C',
  },
  amt2: {
    color: '#8692A6',
  },
  popularChip: {
    textAlign: 'end',
  },
  pChip: {
    background: 'transparent',
    color: '#fff',
    padding: '10px',
  },
  footerMainText: {
    color: '#fff',
    marginTop: '20px',
    fontSize: '36px',
    fontWeight: 500,
    paddingBottom: '1.3rem'
  },
  footerLinks: {
    color: '#fff',
  },
  subBtn: {
    background: 'white',
    borderRadius: 4,
    padding: '8px',
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  },
  sm: {
    margin: '0.3rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#187d7e',
      borderRadius: '50px',
      opacity: 0.5
    },
  },
  arrowBtns: {
    display: 'flex',
    justifyContent: 'end',
    padding: '1rem',
  },
  testimonialCard: {
    border: '1px solid #E6F7F8', boxShadow: '0px 20px 60px rgba(0, 0, 0, 0.16)', borderRadius: 26, padding: '2rem',
    backgroundImage: `url(${BgTriangle})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    backgroundSize: 'auto',
    backgroundPositionX: 'right',
    backgroundPositionY: 'bottom',
  },
  testimonial: {
    fontWeight: '300', lineHeight: 'normal', padding: '2rem 0', marginTop: '0.5rem', fontSize: '22px'
  },
  heroSection: {
    alignSelf: 'center', padding: '0 10rem',
    [theme.breakpoints.down('md')]: {
      padding: '0 1rem',
      textAlign: 'center',
    },
  },
  footerSm: {
    [theme.breakpoints.down('md')]: {
      padding: '0 1.5rem',
    },
  },
  heroImg: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
  heroImgGrid: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '2rem'
    },
  },
  empGrid: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  copyright: {
    color: '#fff', padding: '3rem 0', marginTop: '3rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0rem',
      padding: '1.5rem 0',
    },
  },
}));

const styles = {
  paperContainer: {
    backgroundImage: `url(${HomeBg1})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    backgroundSize: 'auto',
    backgroundPosition: 'end left',
  }
};

function Copyright(props) {
  const classes = useStyles();
  return (
    <Typography variant="body2" align="center" {...props} className={classes.copyright}>
      {'Copyright © '}
      <Link color="inherit" href="#" style={{ color: '#fff' }}>
        {new Date().getFullYear()} DHIYO. All rights reserved.
      </Link>{' '}
    </Typography>
  );
}

const sm = [
  {
    icon: Linkedin
  },
  {
    icon: Fb
  },
  {
    icon: Twitter
  },
  {
    icon: Insta
  },
];
const tiers = [
  {
    title: 'Intro',
    subheader: 'For most businesses that want to otpimize web queries',
    price: '20',
    chipTitle: 'STANDARD',
    description: [
      'All limited links',
      'Own analytics platform',
      'Chat support',
      'Optimize hashtags',
      'Unlimited users',
    ],
  },
  {
    title: 'Base',
    subheader: 'For most businesses that want to otpimize web queries',
    price: '50',
    chipTitle: 'BASIC',
    description: [
      'All limited links',
      'Own analytics platform',
      'Chat support',
      'Optimize hashtags',
      'Unlimited users',
    ],
  },
  {
    title: 'Pro',
    subheader: 'For most businesses that want to otpimize web queries',
    price: '100',
    chipTitle: 'MOST POPULAR',
    description: [
      'All limited links',
      'Own analytics platform',
      'Chat support',
      'Optimize hashtags',
      'Unlimited users',
    ],
  },
  {
    title: 'Enterprise',
    subheader: 'For most businesses that want to otpimize web queries',
    price: '200',
    chipTitle: 'POPULAR',
    description: [
      'All limited links',
      'Own analytics platform',
      'Chat support',
      'Optimize hashtags',
      'Unlimited users',
    ],
  },
];

const Home = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isTokenPresent = localStorage.getItem('deepailytics.auth.token');
  const classes = useStyles();

  const renderLoggedInUserRoute = () => {
    const loggedInUserRole = localStorage.getItem('deepailytics.user.role');
    return (
      {
        'ROLE_EMPLOYER': <Employer />,
        'ROLE_APPLICANT': <Applicant />,
        'ROLE_INTERVIEWER': <Interviewer />
      }[loggedInUserRole]
    );
  };

  const renderProtectedRoutes = () => renderLoggedInUserRoute();

  const SubscribeButton = () => (
    <CustomBtn variant="contained">Subscribe</CustomBtn>
  );

  // **********************

  const [anchorElNav, setAnchorElNav] = React.useState(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState(
    null
  );

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      {location.pathname === '/' &&
        <div>
          <div style={styles.paperContainer}>
            <Container maxWidth="lg" style={{ padding: '1.5rem 0' }}>
              <AppBar position="static" className={classes.appBar} elevation={0}>
                <Container maxWidth="xl">
                  <Toolbar disableGutters className={classes.toolbar}>
                    <img src={Logo} style={{
                      marginRight: 5
                    }} alt="logoIcon" />
                    <img src={DHIYO} alt="logoName" />

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} style={{ justifyContent: 'flex-end' }}>
                      <IconButton

                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                      >
                        <MenuIcon />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                          display: { xs: 'block', md: 'none' }
                        }}

                      >
                        {pages.map(page => (
                          <MenuItem key={page} onClick={handleCloseNavMenu} >
                            <Typography textAlign="center">{page}</Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ justifyContent: 'flex-end' }}>
                      {pages.map(page => (
                        <Button
                          key={page}
                          onClick={handleCloseNavMenu}
                          sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                          {page}
                        </Button>
                      ))}
                      <HomeBtns
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate('/applicant/login', { state: { role: 'applicant' } })}
                      >
                        {translate('DHIYO.HOME_PAGE.LABEL.FOR_APPLICANT')}
                      </HomeBtns>
                      <HomeBtns
                        variant="outlined"
                        onClick={() => navigate('employer/home', { state: { role: 'employer' } })}
                      >
                        {translate('DHIYO.HOME_PAGE.LABEL.FOR_EMPLOYERS')}
                      </HomeBtns>
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                      <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {settings.map(setting => (
                          <MenuItem key={setting} onClick={handleCloseUserMenu}>
                            <Typography textAlign="center">{setting}</Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </Toolbar>
                </Container>
              </AppBar>



              <main style={{ marginTop: '4rem' }}>

                <Grid container>
                  <Grid item xs={12} md={7} className={classes.heroSection}>
                    <Typography variant="h4" className={classes.homeText}>The easiest job board software on the market.</Typography>
                    <Box style={{ margin: '1.3rem 0', justifyContent: 'center' }} sx={{ display: { xs: 'flex', md: 'none' } }}>
                      <HomeBtns
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate('/applicant/login', { state: { role: 'applicant' } })}
                      >
                        {translate('DHIYO.HOME_PAGE.LABEL.FOR_APPLICANT')}
                      </HomeBtns>
                      <HomeBtns
                        variant="outlined"
                        onClick={() => navigate('employer/home', { state: { role: 'employer' } })}
                      >
                        {translate('DHIYO.HOME_PAGE.LABEL.FOR_EMPLOYERS')}
                      </HomeBtns>
                    </Box>
                    <Typography variant="subtitle1" className={classes.subText}>
                      We’ve built the most robust and flexible job board platform out there.
                      It also happens to be the most cost effective.
                    </Typography>
                    <CustomBtn variant="contained">Try it now!</CustomBtn>
                  </Grid>
                  <Grid item xs={12} md={5} className={classes.heroImgGrid}>
                    <img src={HeroImg1} alt="hero img" className={classes.heroImg} />
                  </Grid>
                </Grid>
              </main>

              <Grid container className={classes.companyLogos}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant="body1" className={classes.homeText}>Featured on</Typography>
                </Grid>

                <Grid container style={{ textAlign: 'center', padding: '2rem', background: '#fff' }}>
                  <Grid item xs={6} md={2}>
                    <img src={Logo3} alt="company logo" className={classes.logoSize} />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <img src={Logo5} alt="company logo" className={classes.logoSize} />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <img src={Logo1} alt="company logo" />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <img src={Logo4} alt="company logo" />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <img src={Logo2} alt="company logo" />
                  </Grid>
                </Grid>

                <Divider />
              </Grid>
            </Container>

            <main style={{ marginTop: '6rem' }}>
              <Grid container>
                <Grid item xs={12} md={5}>
                  <img src={BussinessImg} alt="main img" width="100%" />
                </Grid>
                <Grid item xs={12} md={7} className={classes.heroSection}>
                  <Typography variant="h5" className={classes.homeText}>For your business.</Typography>
                  <Typography variant="subtitle1" className={classes.subText} style={{ color: '#777777', fontSize: '18px' }}>
                    Shaping a happy career is a priority for all budding professionals. However, the stakes to get there never seemed to be this high. It's though undeniable that countless job portals claim to be the best when it comes to providing the right careers, however, we recommend the best website for job search. Indeed we have reasons to state this tall claim.
                  </Typography>
                  {/* <Link>Try it now!</Link> */}
                </Grid>
              </Grid>
            </main>

            <main style={{ marginTop: '6rem' }}>
              <Grid container className={classes.empGrid}>
                <Grid item xs={12} md={7} className={classes.heroSection}>
                  <Typography variant="h5" className={classes.homeText}>For employers.</Typography>
                  <Typography variant="subtitle1" className={classes.subText} style={{ color: '#777777', fontSize: '18px' }}>
                    Shares your job across its network of 500+ million professionals, making it visible to anyone on the system. Emails and displays the ad to potential candidates whose skills and location match the job posting. It  that allows you to connect with members outside your immediate network and company insights, such as industry trends and competitor data. Recruiter provides postings that help you find and manage job candidates.
                  </Typography>
                  <Link style={{ textDecoration: 'underline', marginTop: '1rem' }}>Select plan</Link>
                </Grid>
                <Grid item xs={12} md={5}>
                  <img src={EmployersImg} alt="main img" width="100%" />
                </Grid>
              </Grid>
            </main>


            <main style={{ marginTop: '6rem' }}>
              <Grid container>
                <Grid item xs={12} md={5}>
                  <img src={JobSeekerImg} alt="main img" width="100%" />
                </Grid>
                <Grid item xs={12} md={7} className={classes.heroSection}>
                  <Typography variant="h5" className={classes.homeText}>For job seekers.</Typography>
                  <Typography variant="subtitle1" className={classes.subText} style={{ color: '#777777', fontSize: '18px' }}>
                    DHIYO is unquestionably the social network for job-seeking professionals. Instead, they copy and paste their resume and hope the right employer finds them. A DHIYO account allows you to maintain a professional profile on the system.
                    Account is designed for active job seekers and professionals seeking to network and find growth opportunities in their field.
                  </Typography>
                </Grid>
              </Grid>
            </main>


            <Grid container className={classes.empPlan}>
              <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '4rem' }}>
                <Typography variant="h5" className={classes.homeText}>Employer plan</Typography>
              </Grid>

              <Container maxWidth="lg" component="main">
                <Grid container style={{ border: '1px solid #E6F7F8', borderRadius: 26, padding: '2rem', background: '#fff' }}>
                  {tiers.map(tier => (
                    <Grid
                      item
                      key={tier.title}
                      xs={12}
                      sm={6}
                      md={3}
                    >
                      <Card elevation={0} className={classes.priceCard}>
                        <CardContent>
                          <Box className={classes.popularChip}>
                            <Chip label={tier.chipTitle} size='small' className={classes.pChip} />
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'baseline',
                              mb: 2,
                            }}
                          >
                            <Typography
                              variant="h4"
                              className={classes.amt}
                            >
                              ${tier.price}
                            </Typography>
                            <Typography variant="subtitle1" className={classes.amt2}>
                              /month
                            </Typography>
                          </Box>

                          <Typography
                            variant="h5"
                            align="left"
                            className={classes.amt1}
                          >
                            {tier.title}
                          </Typography>

                          <Typography
                            variant="body2"
                            align="left"
                            className={classes.paraText}
                            style={{ margin: '0.8rem 0' }}
                          >
                            {tier.subheader}
                          </Typography>

                          {tier.description.map(line => (
                            <Box style={{ display: 'flex' }} >
                              <img src={checkcircle} width='16px' className={classes.circleIcon} />
                              <Typography
                                className={classes.paraText}
                                style={{ margin: '0.4rem 0' }}
                                variant="subtitle1"
                                align="left"
                                key={line}
                              >
                                {line}
                              </Typography>
                            </Box>
                          ))}
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Grid>

            <Grid container className={classes.testimonialBg}>
              <Container maxWidth="lg" component="main" >
                <Grid container className={classes.testimonialCard}>
                  <Grid item xs={12} md={4} style={{ padding: '2rem' }}>
                    <Typography variant="h3" style={{ fontWeight: 600 }}> People are satisfied to be focus </Typography>
                    <Box className={classes.arrowBtns} >
                      <img src={WhiteArrow} alt="white arrow" style={{ margin: '0 1rem' }} />
                      <img src={BlueArrow} alt="blue arrow" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} style={{ padding: '2rem' }}>
                    <Box style={{ display: 'flex' }}>
                      <img src={Quote} alt="quote" className={classes.quote} />
                      <Box>
                        <Typography variant="h6" className={classes.testimonial}>
                          Solid time blocks, free from distractions and interruptions, so that you can focus and do your best work.
                        </Typography>
                        <Box style={{ display: 'flex' }}>
                          <img src={HappyClient} alt="Happy Client" />
                          <Box style={{ margin: '0 1rem' }}>
                            <Typography variant="h6">
                              — Amelia Earhart
                            </Typography>
                            <Typography variant="subtitle1">
                              Wipro
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Grid>

            {/* Footer */}
            <Grid
              component="footer"
              style={{
                paddingTop: '10rem',
                background: 'linear-gradient(33.99deg, #118B8C 10.52%, #1B9E9F 25.4%, #37CCCD 65.57%, #4DDDDE 84.91%)',
                borderRadius: '88px 88px 0px 0px',
              }}
            >
              <Grid container className={classes.footerSm}>
                <Grid item xs={12} md={2} />
                <Grid item xs={12} md={4}>
                  <Typography variant="h3" className={classes.footerMainText} style={{ marginLeft: '4px' }}>Plan. Focus. Optimize</Typography>
                  {
                    sm.map(sm => (
                      <img src={sm.icon} className={classes.sm} />
                    ))
                  }
                  <Box style={{ marginTop: '0.5rem' }}>
                    {footerLinks.map(link => (
                      <Button
                        className={classes.footerLinks}
                        size='small'
                        key={link}
                      // onClick={handleCloseNavMenu}
                      >
                        {link}
                      </Button>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="h3" className={classes.footerMainText}>Get Great News from Us</Typography>
                  <Box>
                    <InputLabel style={{ color: '#fff', textTransform: 'none', paddingBottom: '5px' }}>E-mail</InputLabel>
                    <TextField
                      className={classes.subBtn}
                      variant="standard"
                      id="subscribe"
                      placeholder="name@domain.com"
                      InputProps={{ classes, endAdornment: <SubscribeButton /> }}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={2} />
              </Grid>
              <Copyright />
            </Grid>
            {/* End footer */}
          </div>
        </div>
      }
      {
        !isNull(isTokenPresent) ? renderProtectedRoutes() : <PublicRoutes />
      }
    </>
  );
};

export default Home;
