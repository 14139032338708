import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Button, Card, CardContent, Grid, Avatar, FormControlLabel, Switch, Chip, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import { onGetProfileDetails, setSimilarCandidateFlag } from 'redux/actions/employerActions/employerDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import PinterestIcon from 'asset/icons/pinterest.svg';

const useStyle = makeStyles(theme => ({
  jobCard: {
    marginBottom: '1.3rem',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    padding: '0.5rem 0'
  },
  percent: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'right',
    color: '#FF5B15',
    marginTop: '0.3rem'
  },
  smallBtn: {
    fontWeight: 100,
    marginTop: '0.5rem'
  },
  company: {
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 500,
    color: '#797D89',
    margin: '5px 0px',
    fontSize: '13px',
    background: '#E1E1E1',
    borderRadius: '1px',
    padding: 2,
  },
  chip: {
    margin: '0.4rem 0.6rem 0.4rem 0rem',
    color: '#24283C',
    background: '#E0DFFF'
  },
  pageTitle: {
    ...theme.typography.title,
  },
  bodyText: {
    fontSize: '18px', color: '#8692A6', margin: '1.3rem 0px 0.2rem 0px', fontWeight: 200
  }
}));

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '10px' }}
  >
    •
  </Box>
);

const CandidateCard = ({ candidateId, isInterviewer, children }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const candidateData = useSelector(state => get(state, ['employerDashboard', 'applicantsProfileById', candidateId], {}));
  const similarCandidateData = useSelector(state => get(state, ['employerDashboard', 'similarCandidate'], {}));
  // eslint-disable-next-line no-unused-vars
  const [shortlist, setShortlist] = useState(false);

  useEffect(() => {
    dispatch(onGetProfileDetails({
      id: candidateId
    }));
    return ()=>{
      dispatch(setSimilarCandidateFlag(false));
    };
  }, [candidateId]);

  const toggleChecked =(e)=>{
    const { checked } = e.currentTarget;
    dispatch(setSimilarCandidateFlag(checked));
  };

  return (
    <Box
      sx={{
        marginTop: '0.5rem'
      }} >
      {!isEmpty(candidateData?.id) &&
        <Card elevation={0} className={classes.jobCard}>
          <CardContent style={{ padding: '10px 25px' }}>
            <Grid container xs={12}>
              <Grid item xs={9} style={{ display: 'flex' }}>
                <Avatar style={{ marginRight: '0.8rem' }} />
                <Grid container style={{ display: 'flex', textAlign: 'initial' }}>
                  <Grid item xs={12} style={{ marginTop: '0.5rem' }}>
                    <Typography variant="h4" style={{ fontSize: '20px', lineHeight: '25px' }}>
                      {get(candidateData, ['firstName'], '')} {get(candidateData, ['lastName'], '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='subtitle2' style={{ fontSize: '16px' }} >
                      {get(candidateData, ['professionalDetails', 'title'], '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} >
                    <Typography variant="subtitle1">
                      {get(candidateData, ['city'], '')},{get(candidateData, ['stateResiding'])} |  {get(candidateData, ['email'], '')}  |  +{get(candidateData, ['contactNumber'], '')}
                    </Typography>
                  </Grid>
                  {!isInterviewer &&
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.company} >
                          Actively Looking for Job {get(candidateData, ['activelyLookingForJob'], '')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ display: 'flex' }}>
                        <Typography variant="subtitle2" style={{ fontSize: '16px', alignSelf: 'center' }}>
                          Show Similar Candidates
                        </Typography>
                        <FormControlLabel
                          style={{ margin: 0 }}
                          control={<Switch checked={similarCandidateData.isSimilarCandidate} onChange={toggleChecked} />}
                          name="similarcandidates"
                        />
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>
              {!isInterviewer &&
                <Grid item xs={3} style={{ textAlign: 'end' }}>
                  <Typography variant='subtitle1' style={{ fontSize: '12px' }}>Compatibility</Typography>
                  <Typography className={classes.percent}>
                    89%
                  </Typography>
                  {
                    shortlist ? (
                      <Button variant="contained" color="secondary" className={classes.smallBtn} size='small' disableElevation>Shortlist</Button>
                    ) : (
                      <Button variant="outlined" color="secondary" className={classes.smallBtn} size='small'>Revoke</Button>
                    )
                  }
                </Grid>
              }
            </Grid>

            <Grid container xs={12} >
              <Grid item xs={12} align="left">
                <Typography variant="body2" className={classes.bodyText}>
                  Experience Details
                </Typography>
              </Grid>
              {
                R.map((data) => {
                  return (
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-start', margin: '0.6rem 0' }}>

                      <img src={PinterestIcon} alt='pinterest Icon12' style={{ width: '50px', marginRight: '1rem' }} />
                      <Grid container align="left" style={{ display: 'flex' }}>
                        <Grid item xs={12}>
                          <Typography variant='subtitle2' style={{ fontSize: '16px' }} >
                            {get(data, ['title'], '')} | {get(data, ['company'], '')}
                            <Typography variant='subtitle2' style={{ fontSize: '14px' }} >
                              {get(data, ['endDate'], '')} - {get(data, ['endDate'], '')}{bull}{get(data, [''], '')}
                            </Typography>
                            <Typography variant="subtitle1" style={{ lineHeight: '21px' }}>
                              {get(data, ['location'], '')}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })(get(candidateData, 'professionalDetails', []))
              }
            </Grid>

            <Grid container xs={12} >
              <Grid item xs={12} align="left">
                <Typography variant="body2" className={classes.bodyText}>
                  Educational Details
                </Typography>
              </Grid>
              {
                R.map((data) => {
                  return (
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-start', margin: '0.6rem 0' }}>

                      <img src={PinterestIcon} alt='pinterest Icon' style={{ width: '50px', marginRight: '1rem' }} />

                      <Grid container align="left" style={{ display: 'flex' }}>
                        <Grid item xs={12}>
                          <Typography variant='subtitle2' style={{ fontSize: '16px' }} >
                            {get(data, ['field'], '')} - {get(data, [''], '-')} | {get(data, ['university'], '')}
                            <Typography variant="subtitle1" style={{ lineHeight: '21px' }}>
                              {get(data, ['startYear'], '')} - {get(data, ['endYear'], '')}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })(get(candidateData, 'educationDetails', []))
              }
            </Grid>

            <Grid container xs={12} >
              <Grid item xs={12} align="left">
                <Typography variant="body2" className={classes.bodyText}>
                  Summary
                </Typography>
              </Grid>
              <Grid item xs={12} align="left">
                <Typography variant="body1" style={{ fontSize: '14px' }}>
                  {get(candidateData, ['summary'], '')}              </Typography>
              </Grid>
            </Grid>

            <Grid container xs={12} >
              <Grid item xs={12} align="left">
                <Typography variant="body2" className={classes.bodyText}>
                  Skills
                </Typography>
              </Grid>
              <Grid item xs={12} align="left">

                {
                  R.map(data => (
                    <Chip label={get(data, ['skill'], '')} color="secondary" className={classes.chip} />
                  ))(get(candidateData.skillDetails, 'skills', []))
                }

              </Grid>
            </Grid>

            <Grid container xs={12} >
              <Grid item xs={12} align="left">
                <Typography variant="body2" className={classes.bodyText}>
                  Notice Period
                </Typography>
              </Grid>
              <Grid item xs={12} align="left">
                <Typography variant="body1" style={{ fontSize: '14px' }}>
                  03 Months - Currently Serving
                </Typography>
              </Grid>
            </Grid>
            {children}
          </CardContent>
        </Card>
      }
    </Box>
  );
};

export default CandidateCard;