import { getDateFormatter, getLocationFormatter } from 'utils/DPTableFormatters';

export const decisionColumn = [
  {
    id: 'jobTitle',
    label: 'Job Title',
    minWidth: 140,
    dataPath: ['companyJobTitle', 'title'],
  },
  {
    id: 'jobLocation',
    label: 'Location',
    minWidth: 220,
    formatter: getLocationFormatter(['employerLocation'])
  },
  {
    id: 'candidate',
    label: 'Candidate',
    minWidth: 100,
  },

  {
    id: 'expiry',
    label: 'Expiry Date',
    minWidth: 125,
    formatter: getDateFormatter('')
  },
  {
    id: 'createdBy',
    label: 'Posted By',
    minWidth: 200,
  },
  {
    id: 'application',
    label: 'Applications',
    minWidth: 80,
  },
  {
    id: 'interviewstatus',
    label: 'Interview Status',
    minWidth: 120,
    cellRenderer: 'colorCellRenderer',
    rendererProps: {
      colorMap: {
        'Round 1': ['Round 1', '#EFFFF3', '#1DBA44'],
        'Round 2': ['Round 2', '#1DBA44', '#D93131'],
        'Round 3': ['Round 3', '#D93131', '#FFC000']
      },
      fieldPath: ['interviewstatus']
    }
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    cellRenderer: 'dpMenuActionCellRenderer'
  }
];

export const decisionTablColumns = {
  InProgress: decisionColumn,
  OnHold: decisionColumn,
  Completed: decisionColumn,
};

export const decisionNestedTabWiseList= [
  {
    id: 'InProgress',
    label: 'In Progress',
  },
  {
    id: 'OnHold',
    label: 'On Hold',
  },
  {
    id: 'Completed',
    label: 'Completed',
  }
];