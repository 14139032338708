import React, { useState } from 'react';
import { Box, makeStyles, Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import UserProfileApp from './UserProfileApp';
import ApplicantUserProfile from './ApplicantUserProfile';
import RecommendedCandidates from './RecommendedCandidates';
import AppliedCandidates from './AppliedCandidates';
import SimilarCandidates from './SimilarCandidates';
import { Container } from '@material-ui/core';
import JrHeaderCards from '../../../../common/components/HeaderCards/JrHeaderCards';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    margin: '1rem 0rem',
  },
}));
const headerCards = false;

// Component shows profile view
const ApplicantProfile = ({ userType, userData }) => {
  const classes = useStyles();
  const userDetails = useSelector(state => get(state, ['userManagement','selectedUser'], {}));
  // eslint-disable-next-line no-unused-vars
  const [candidateSection, setCandidateSection] = useState(true);

  return (
    <Container style={{ marginTop: '5rem', marginBottom: '5rem' }}>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.pageTitle}> Recommended Candidate Details </Typography>
            <UserProfileApp userDetails={userDetails} userType={userType} userData={userData} />
          </Grid>
        </Grid>

        {
          headerCards ? (
            <Grid container style={{ marginTop: '2rem' }}>
              <Grid item xs={12}>
                <JrHeaderCards />
              </Grid>
            </Grid>
          ) : ''
        } 

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <ApplicantUserProfile />
          </Grid>
          <Grid item xs={12} md={4}>
            {
              candidateSection ? (<SimilarCandidates />) : (<AppliedCandidates />)
            }
            <RecommendedCandidates />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ApplicantProfile;