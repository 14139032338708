import * as React from 'react';
import { Grid, Box, CardContent, Typography, Card, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as R from 'ramda';
import { HeaderCards_Data_Employer } from 'mockData/RecommendedJobsData';
import dhiyosmpost from 'asset/images/dhiyosmpost.svg';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onGetStatsData } from 'redux/actions/employerActions/employerDashboard';
import { isEmpty } from 'lodash';
import { useState } from 'react';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    alignSelf: 'center',
    fontSize: '18px',
    lineHeight: '27px'
  },
  title: {
    '&.MuiTypography-root': {
      color: 'gray',
      fontWeight: '300',
      lineHeight: '27px',
      fontSize: '18px'
    },
  },
  headerCard: {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  },
  cardContent: {
    marginTop: '5px',
  },
  number: {
    fontWeight: 600,
    fontSize: '25px',
    lineHeight: '38px',
    color: '#24283C'
  },
  monthLinks: {
    textAlign: 'end',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.only('md')]: {
      textAlign: 'initial',
    },
  },
  cardListMain: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '5px',
    },
  },
  linkClr: {
    color: '#6360CD'
  },
  smpost: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  weekly: {
    margin: '0px 10px 0px 10px',
    [theme.breakpoints.down('md')]: {
      margin: '0px 10px 0px 0px',
    },
  },
  dhiyoPost: {
    padding: '8px', width: '100%', height: 'auto',
  }
}));

const HeaderCardsEmployer = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const statsDetails = useSelector(state => state.employerDashboard.statsDetails);
  const [jobCardData, setjobCardData] = useState([]);
  const [filter, setFilter] = useState('WEEKLY');
  
  useEffect(() => {
  }, [jobCardData]);
  

  useEffect(() => {
    dispatch(onGetStatsData({ state: '', filter }));
    dispatch(onGetStatsData({ state: 'SHORTLISTED', filter }));
    dispatch(onGetStatsData({ state: 'INTERVIEW', filter }));
    dispatch(onGetStatsData({ state: 'ARCHIVED', filter }));
  }, [filter]);

  useEffect(() => {
    if (!isEmpty(statsDetails)) {
      const data = HeaderCards_Data_Employer.map((obj) => {
        return { ...obj, ['number']: statsDetails[obj.id] };
      });
      setjobCardData(data);
    }
  }, [statsDetails]);
  
  
  return (
    <Box className={classes.cardListMain}>
      <Grid container spacing={2}>
        <Grid item xs={4} md={12} lg={4}>
          <Typography className={classes.pageTitle}>Statistics</Typography>
        </Grid>
        <Grid item xs={8} md={12} lg={8} className={classes.monthLinks}>
          <Link onClick={() => setFilter('WEEKLY')} href="#" underline="hover" className={clsx(classes.linkClr, classes.weekly)}>
            Weekly
          </Link>

          <Link onClick={() => setFilter('MONTHLY')} href="#" underline="hover" className={classes.linkClr} style={{ marginRight: '10px' }}>
            Monthly
          </Link>

          <Link onClick={() => setFilter('YEARLY')} href="#" underline="hover" className={classes.linkClr}>
            Yearly
          </Link>
        </Grid>
        {
          R.map((headercardItem) => {
            return (
              <>
                <Grid item xs={12}>
                  <Card className={classes.headerCard}>
                    <CardContent className={classes.cardContent}>
                      <Grid container>
                        <Grid item xs={2}>
                          <img src={headercardItem?.icon} style={{ width: '40px' }} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography className={classes.title}>
                            {headercardItem?.title}
                          </Typography>
                          <Typography className={classes.number}>
                            {headercardItem?.number}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            );
          })(jobCardData)
        }
        <img src={dhiyosmpost} alt="smpost" width="300px" height="auto" className={classes.dhiyoPost} />
      </Grid>
    </Box >
  );
};

export default HeaderCardsEmployer;