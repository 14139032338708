import React from 'react';
import { Button, Grid, Typography, Paper, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getUserInfoFormRenderer } from '../utils/userProfileUtil';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Image from 'asset/images/blueframe.svg';

const useStyle = makeStyles(() => ({
  type: {
    color: '#8692A6',
    fontSize: '0.75rem',
  },
  wrapper: {
    height: '100vh',
  },
  leftContainer: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#2754CEE5',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  backButton: {
    fontSize: '0.75rem',
    padding: 0,
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    color: 'gray',
    '&:hover': {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
    }
  },
  primaryRendererContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '80%',
    alignItems: 'center',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem'
  },
  centerAligned: {
    '&.MuiGrid-root': {
      display: 'grid',
      textAlign: 'end',
      alignItems: 'center',
      color: '#8692A6',
    }
  },
  customicon: {
    width: '30px',
  },
}));

const UserProfileWrapper = ({ renderer, role }) => {
  const navigate = useNavigate();
  const classes = useStyle();
  const FormRenderer = getUserInfoFormRenderer(renderer);
  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <Grid container spacing={0} className={classes.wrapper}>
      <Grid
        item
        xs={false}
        sm={5}
        md={5}
        className={classes.leftContainer}
      >
        {/* <FamousQuotes /> */}
      </Grid>
      <Grid item xs={12} sm={7} md={7} component={Paper} elevation={6} square>
        <Grid container spacing={0} style={{ padding: '2rem' }}>
          <Grid item lg={6} md={6} xs={6}>
            <Button
              variant="contained"
              className={classes.backButton}
              startIcon={<ArrowBackIosIcon fontSize='small' style={{ marginLeft: '0px', marginRight: '0px', }} />}
              onClick={onBackClick}>
              Back
            </Button>
          </Grid>
          <Grid item lg={6} md={6} xs={6} className={classes.centerAligned}>
            {
              renderer === 'loginForm' && <Typography variant="subtitle1">Don't have an account? <Link className="button" onClick={() => role === 'applicant' ? navigate('/applicant/register') : navigate('/employer/register')}>Register</Link></Typography>
            }
            {
              includes(['employerHome', 'employerLoginForm'], renderer) && <Typography variant="subtitle1">Don't have an account? <Link className="button" onClick={() => role === 'applicant' ? navigate('/applicant/register') : navigate('/employer/register')}>Register</Link></Typography>
            }
          </Grid>
        </Grid>
        <Grid container className={classes.primaryRendererContainer}>
          <FormRenderer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserProfileWrapper;

UserProfileWrapper.propTypes = {
  renderer: PropTypes.string.isRequired,
};
