import React from 'react';
import * as yup from 'yup';
import { YupValidator } from '../../../../../utils/YupValidator';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Formik, Form } from 'formik';
import {
  Grid, 
  Button, 
  Paper,
  InputLabel, 
} from '@material-ui/core'; 
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormikInputField } from '../../../../ui-components';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  title: {
    ...theme.typography.title,
  },
  titleBasicDetail: {
    ...theme.typography.title,
    color: '#24283C',
    fontSize: '1.4rem',
  },
  rightAligned: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  spaceAround: { display: 'flex', justifyContent: 'space-around' },
  imageContainer: {
    height: '100px',
    width: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 15,
    backgroundColor: '#EEF3F9',
  },
  profilePhoto: {
    height: '70px',
    width: '70px',
  },
  linkLabel: {
    ...theme.typography.linkLabel,
  },
  label: {
    ...theme.typography.linkLabel,
    fontWeight: 400,
  },
  Caption: {
    ...theme.typography.Caption,
  },
  button: {
    color: theme.palette.common.blue,
    textTransform: 'none',
  },
  deleteButton: {
    color: theme.palette.common.red,
    textTransform: 'none',
  },
  formContainer: {},
  formContainer1: {
    backgroundColor: 'white',
    paddingTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingBottom: '1em',

    [theme.breakpoints.down('md')]: {
      marginTop: '2em',
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
      paddingBottom: '1em',
    },
  },
  divider: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
    marginLeft: '-1rem',
    marginRight: '-1rem',
  },
  groupContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5em',
      border: '1px solid',
      borderColor: theme.palette.common.gray4,
      borderRadius: 6,
    },
  },
}));

export default function AddLocationForm(props) {
  const { addOrEdit, recordForEdit } = props;
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  const handleSubmit = async (values, actions) => {
    addOrEdit(values, () => {
      actions.setTouched({});
    });
  };

  const onCancelClick = (formik) => {
    const isEdit = recordForEdit && recordForEdit.id;
    if (isEdit !== '') {
      props.onCancel();
    } else {
      formik.resetForm();
    }
  };
  const validationSchema = yup.object({
    companyName: YupValidator('Required'),
    companyAddress: YupValidator('Required'),
    city: YupValidator('Required'),
    zipcode: YupValidator('ZipCode'),
    state: YupValidator('Required'),
    country: YupValidator('Required'),
  });
  return (
    <Paper elevation={0}>
      <Formik
        initialValues={recordForEdit}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {formik => (
          <Form>
            <Grid
              container
              spacing={2}
              direction={matchesMD ? 'column' : 'row'}>
              <Grid item md={matchesMD ? 12 : 3}>
                <InputLabel>Company Name</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.LOCATION.ADD_FORM.COMPANY"
                  name="companyName" />
              </Grid>
              <Grid item md={matchesMD ? 12 : 9}>
                <InputLabel>Address</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.LOCATION.ADD_FORM.ADDRESS"
                  name="companyAddress" />
              </Grid>
              <Grid item md={matchesMD ? 12 : 3}>
                <InputLabel>Zipcode</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.LOCATION.ADD_FORM.ZIPCODE"
                  name="zipcode" />
              </Grid>
              <Grid item md={matchesMD ? 12 : 3}>
                <InputLabel>City</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.LOCATION.ADD_FORM.CITY"
                  name="city" />
              </Grid>
              <Grid item md={matchesMD ? 12 : 3}>
                <InputLabel>State</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.LOCATION.ADD_FORM.STATE"
                  name="state" />
              </Grid>
              <Grid item md={matchesMD ? 12 : 3}>
                <InputLabel>Country</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.LOCATION.ADD_FORM.COUNTRY"
                  name="country" />
              </Grid>
              {matchesMD ? <Grid item md={9} /> : ''}
              <Grid item md={12} container justifyContent={'flex-end'}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={e => onCancelClick(formik, e)}
                  type="button"
                  style={{ marginRight: 10 }}
                  disableRipple>
                  {recordForEdit && recordForEdit.id !== ''
                    ? 'Cancel'
                    : 'Reset'}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: 10 }}
                  disableRipple
                  type="submit">
                  {recordForEdit && recordForEdit.id !== ''
                    ? 'Update'
                    : 'Add'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}
