import React, { useState } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ConfirmDialog from 'view/common/ConfirmDialog';
import ResumeUploadView from './ResumeUploadView';

import { uploadResume } from 'redux/actions/uploadResume';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'view/applicant/route/constants';
import uploadCv from 'asset/icons/uploadresume.svg';
import buildProfile from 'asset/icons/buildprofile.svg';
import { get } from 'lodash';
import { getFormattedProfileDetails } from 'view/common/utils/formUtils';
import { setApplicantProfile } from 'redux/slices/userProfile';

const useStyle = makeStyles(() => ({
  text: {
    '&.MuiTypography-root': {
      align: 'left',
      marginBottom: '2rem',
      color: 'gray',
      fontSize: '18px',
      fontFamily: 'poppins',
    },
  },
  gridContainer: {
    padding: '1rem',
    textAlign: 'initial',
    fontFamily: 'poppins',
  },
  title: {
    color: 'black',
    fontFamily: 'poppins',
    fontWeight: 500,
  },
  icon: {
    fontFamily: 'poppins',
    '&.MuiSvgIcon-root': {
      marginTop: '0.5rem',
      marginRight: '2rem',
      width: '32px',
      color: '#1565D8',
    },
  },
  customicon: {
    width: '42px',
  },
  subtitle: {
    fontFamily: 'poppins',
    '&.MuiTypography-root': {
      align: 'left',
      color: 'gray',
      fontSize: '12px',
    },
  },
  
  button: {
    fontFamily: 'poppins',
    padding: '12px 0px',
    margin: '1.2rem 0rem',
    borderRadius:'6px',
    boxShadow: '1px 1px 4px #e1dede',
    border: '1px solid #ffffff',
    '&:hover': {
      border: '1px solid #1565D8',
      backgroundColor: '#F5F9FF',
    },
  },
  iconSvg: {
    alignSelf: 'center',
  },
  ConfirmDialog: {
    '.MuiDialogTitle-root': {
      padding: '16px 24px 0px',
    },
    '.MuiTypography-h6': {
      fontFamily: 'poppins',
      fontSize: '1.3rem',
      fontWeight: 600,
      lineHeight: '32px',
    },
  },
}));

const RegisterAccount = () => {
  const classes = useStyle();
  const [isOpen, setConfirmDialogOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const processUpload = async () => {
    const resultAction = await dispatch(uploadResume({ file: uploadedFile[0] }));
    const data = get(resultAction, ['payload', 'data'], {});
    const profileData = getFormattedProfileDetails(data);
    await dispatch(setApplicantProfile(profileData));
    navigate(RoutePaths.editProfile);
  };

  const openUploadModal = () => {
    setConfirmDialogOpen(true);
  };

  const onBuildProfileClick = () => {
    navigate(RoutePaths.editProfile, {
      state: {
        isNew: 'true'
      }
    });
  };


  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="auto"
      >
        <div>
          <Typography variant="h4" style={{ fontFamily: 'poppins', fontSize: '1.3rem', fontWeight: 600, lineHeight: '32px', }}>Build Profile!</Typography>
          <Typography variant="subtitle1" style={{ marginBottom: '1rem' }}>To begin this journey, share your profile information to avail best services</Typography>  
        </div>

        <Grid item>
          <Button onClick={openUploadModal} className={classes.button}>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={2} className={classes.iconSvg}>
                <img src={uploadCv} alt='uploadCv icon' className={classes.customicon} />
              </Grid>
              <Grid item xs={9} style={{ paddingRight: '0.9rem' }}>
                <Typography className={classes.title}>
                  Upload Resume
                </Typography>
                <Typography className={classes.subtitle}>
                  Share your resume with us and we will create your profile
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.iconSvg}>
                <ArrowForwardIcon className={classes.icon} />
              </Grid>
            </Grid>
          </Button>
        </Grid>

        <Grid item>
          <Button className={classes.button} onClick={onBuildProfileClick}>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={2} className={classes.iconSvg}>
                <img src={buildProfile} alt='buildProfile icon' className={classes.customicon} />
              </Grid>
              <Grid item xs={9}>
                <Typography className={classes.title}>
                  Build Your Profile
                </Typography>
                <Typography className={classes.subtitle}>
                  Create your customised profile by providing the information
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.iconSvg}>
                <ArrowForwardIcon className={classes.icon} />
              </Grid>
            </Grid>
          </Button>
        </Grid>

        <ConfirmDialog className={classes.ConfirmDialog} isOpen={isOpen} setConfirmDialog={setConfirmDialogOpen} title={'Upload Your Resume'} onConfirm={processUpload} confirmText="Upload" cancelText="Cancel">
          <ResumeUploadView uploadedFiles={uploadedFile} setUploadedFiles={setUploadedFile} />
        </ConfirmDialog>
      </Grid>    
    </>
  );
};

export default RegisterAccount;
