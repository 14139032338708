export const NEWS = {
  'articles': [
    {
      'source': {
        'id': null,
        'name': 'Forbes'
      },
      'author': 'Billy Bambrough, Senior Contributor, \n Billy Bambrough, Senior Contributor\n https://www.forbes.com/sites/billybambrough/',
      'title': '‘Very Hard To Predict’—Binance CEO Reveals ‘90%’ Failure Rate Fear After $500 Million Elon Musk Twitter Bet Amid Bitcoin, Ethereum And Crypto Price Crash',
      'description': 'The billionaire chief executive of bitcoin and crypto exchange Binance, Changpeng "CZ" Zhao, has said he expects 90% of new Twitter features to fail after investing $500 million in Musk\'s acquisition...',
      'url': 'https://www.forbes.com/sites/billybambrough/2022/11/03/very-hard-to-predict-ceo-of-bitcoin-ethereum-and-crypto-exchange-binance-reveals-90-failure-rate-fear-after-500-million-bet-on-elon-musks-twitter/',
      'urlToImage': 'https://imageio.forbes.com/specials-images/imageserve/63638003228dea7b19de53f3/0x0.jpg?format=jpg&width=1200',
      'publishedAt': '2022-11-03T12:15:20Z',
      'content': 'BitcoinBTC\r\n, ethereum and other major cryptocurrencies have seen an uplift from Elon Musk\'s acquisition of TwitterTWTR\r\n as traders bet he could incorporate crypto.\r\nSubscribenow to Forbes\' CryptoAs… [+3661 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Crypto Daily'
      },
      'author': 'Amara Khatri',
      'title': 'Deribit Hot Wallets Lose $28M, Crypto Daily TV 3/11/2022',
      'description': 'In Todays Headline TV CryptoDaily News:\n\nCrypto exchange Deribit loses $28M in hot wallet hack.\nCryptocurrency options and futures exchange Deribit has been hacked, with $28 million being drained from its hot wallet. The company tweeted that client assets hav…',
      'url': 'https://cryptodaily.co.uk/2022/11/deribit-hot-wallets-lose-28-m-crypto-daily-tv-3112022',
      'urlToImage': 'https://cryptodailycdn.ams3.cdn.digitaloceanspaces.com/1450-700-deribit.jpg',
      'publishedAt': '2022-11-03T12:10:00Z',
      'content': 'In Todays Headline TV CryptoDaily News:\r\nCrypto exchange Deribit loses $28M in hot wallet hack.\r\nCryptocurrency options and futures exchange Deribit has been hacked, with $28 million being drained fr… [+3506 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'BeInCrypto'
      },
      'author': 'Brian Becerra',
      'title': 'Agenda de eventos del ecosistema cripto hispanohablante: 3 de noviembre',
      'description': 'BeInCrypto presenta a través de la siguiente agenda los eventos más relevantes del ecosistema cripto del mundo hispanohablante correspondiente al jueves 3 de noviembre, para que puedas participar en los que sean de tu mayor interés. En caso de estar organizan…',
      'url': 'https://es.beincrypto.com/agenda-eventos-ecosistema-cripto-hispanohablante-03-11-22/',
      'urlToImage': 'https://s32679.pcdn.co/wp-content/uploads/2022/08/BIC_daily-crypto-events-03.jpg.webp',
      'publishedAt': '2022-11-03T12:04:16Z',
      'content': 'BeInCrypto presenta a través de la siguiente agenda los eventos más relevantes del ecosistema cripto del mundo hispanohablante correspondiente al jueves 3 de noviembre, para que puedas participar en … [+3350 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Forbes'
      },
      'author': 'Lawrence Wintermeyer, Contributor, \n Lawrence Wintermeyer, Contributor\n https://www.forbes.com/sites/lawrencewintermeyer/',
      'title': 'The Silent Quantum Threat To Web3 Cyber Resilience',
      'description': 'If you are worried about DeFi’s war with cyber-hackers, take a deep breath. Quantum computing is coming and could render today’s digital cryptography used in DeFi and Web3 useless. When is the right time to start making Web3 quantum cyber-resilient if not now?',
      'url': 'https://www.forbes.com/sites/lawrencewintermeyer/2022/11/03/the-silent-quantum-threat-to-web3-cyber-resilience/',
      'urlToImage': 'https://imageio.forbes.com/specials-images/imageserve/6363a4c35abea41b73dd29a1/0x0.jpg?format=jpg&width=1200',
      'publishedAt': '2022-11-03T12:00:04Z',
      'content': 'IBM Q System One Quantum (Photo by Misha Friedman/Getty Images)\r\nGetty Images\r\nIf you are worried about DeFis war with the cyber-hackers and cyber-marauders at the gates of Web3, take a deep breath. … [+9408 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Forbes'
      },
      'author': 'Kenneth Rapoza, Senior Contributor, \n Kenneth Rapoza, Senior Contributor\n https://www.forbes.com/sites/kenrapoza/',
      'title': 'Why Ethereum’s Post-Post-Merge Will Be Better Than The Merge',
      'description': 'Ethereum’s slow growth won’t put the alternative chains out of business, most people agree. But as an investment, ETH has got them beat this year.',
      'url': 'https://www.forbes.com/sites/kenrapoza/2022/11/03/why-ethereums-post-post-merge-will-be-better-than-the-merge/',
      'urlToImage': 'https://imageio.forbes.com/specials-images/imageserve/63505cc07b1e5fcb2aa808f9/0x0.jpg?format=jpg&width=1200',
      'publishedAt': '2022-11-03T12:00:00Z',
      'content': 'Ethereum co-founder Vitalik Buterin speaks at ETHDenver on February 18, 2022 in Denver, Colorado. ... [+] The Ethereum "merge" was completed in September. (Photo by Michael Ciaglo/Getty Images)\r\nGett… [+7072 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Decrypt'
      },
      'author': 'Alys Key',
      'title': 'Australian Asset Manager Delists Bitcoin, Ethereum ETFs After Just 6 Months',
      'description': 'After launching just before the industry\'s May meltdown, Cosmos Asset Management is shuttering its Bitcoin and Ethereum ETFs today.',
      'url': 'https://decrypt.co/113454/australian-asset-manager-delists-bitcoin-ethereum-etfs-after-6-months',
      'urlToImage': 'https://cdn.decrypt.co/resize/1024/height/512/wp-content/uploads/2022/11/bitcoin-australia-gID_2.jpg',
      'publishedAt': '2022-11-03T11:59:02Z',
      'content': 'The asset manager behind two of Australias first crypto exchange-traded funds (ETFs) has applied to delist the much-hyped investment vehicles just a few months after launch.\r\nThe fund responsible for… [+2432 chars]'
    },
    {
      'source': {
        'id': 'globo',
        'name': 'Globo'
      },
      'author': null,
      'title': 'Bitcoin e ether defendem patamares recentes apesar de pessimismo pós-Fed',
      'description': 'Maior criptomoeda segue acima de US$ 20 mil mesmo após a Nasdaq, referência das empresas de tecnologia, derreter mais de 3% na quarta e os futuros do índice indicarem mais um dia de perdas Bitcoin (BTC), ethereum (ETH) e algumas das principais moedas digitais…',
      'url': 'https://valor.globo.com/financas/criptomoedas/noticia/2022/11/03/bitcoin-e-ether-defendem-patamares-recentes-apesar-de-pessimismo-ps-fed.ghtml',
      'urlToImage': 'https://s2.glbimg.com/emkn6bOnJ5WbVbtDQpyl1ARYM5A=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_63b422c2caee4269b8b34177e8876b93/internal_photos/bs/2022/S/A/NpTT6nSAWXUwwiUcbg2g/20opin-200-col-op1-a12-img01.jpg',
      'publishedAt': '2022-11-03T11:52:21Z',
      'content': 'Bitcoin (BTC), ethereum (ETH) e algumas das principais moedas digitais tentam defender os patamares alcançados nos últimos dias apesar do forte pessimismo nos mercados tradicionais após o presidente … [+2832 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'GlobeNewswire'
      },
      'author': 'ReportLinker',
      'title': 'NFTs and the Virtual Economy',
      'description': 'Non-fungible Tokens (NFTs) refer to unique and distinguishable digital assets stored on a distributed ledger/blockchain. Following the emergence of the bitcoin digital currency in early 2009, “colored coins” were coined by Yoni Assia in 2012; these were small…',
      'url': 'https://www.globenewswire.com/news-release/2022/11/03/2547519/0/en/NFTs-and-the-Virtual-Economy.html',
      'urlToImage': 'https://ml.globenewswire.com/Resource/Download/3e0b82c2-02d7-44c8-b22f-561e0f321f39?size=1',
      'publishedAt': '2022-11-03T11:51:00Z',
      'content': 'New York, Nov. 03, 2022 (GLOBE NEWSWIRE) -- Reportlinker.com announces the release of the report "NFTs and the Virtual Economy" - https://www.reportlinker.com/p06360733/?utm_source=GNW\r\nIn 2017, John… [+735 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'CryptoSlate'
      },
      'author': 'Samuel Wan',
      'title': 'CryptoSlate Daily wMarket Update – Nov. 2: Polygon stands out among flat large caps',
      'description': 'Over the last 24 hours, the cryptocurrency market cap saw net inflows of $4.6 billion to $1,009.4 billion from $1,004.8 billion as of press time — up 0.5%. \nThe post CryptoSlate Daily wMarket Update – Nov. 2: Polygon stands out among flat large caps appeared …',
      'url': 'https://cryptoslate.com/cryptoslate-daily-wmarket-update-nov-2-polygon-stands-out-among-flat-large-caps/',
      'urlToImage': 'https://cryptoslate.com/wp-content/uploads/2022/11/image_2022-11-03_163715078.png',
      'publishedAt': '2022-11-03T11:45:53Z',
      'content': 'Over the last 24 hours, the cryptocurrency market cap saw net inflows of $4.6 billion to $1,009.4 billion from $1,004.8 billion as of press time up 0.5%.\r\nDuring the reporting period, Bitcoins market… [+3980 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'The Daily Hodl'
      },
      'author': 'Daily Hodl Staff',
      'title': 'Historically Accurate Signal Suggests Macro Bitcoin Shift Has Arrived, According to Trader Who Called May 2021 BTC Collapse',
      'description': 'A popular crypto analyst who called the May 2021 Bitcoin (BTC) collapse says a historically reliable indicator is flashing bullish for the digital asset. Pseudonymous analyst Dave the Wave tells his 129,100 Twitter followers that the moving average convergenc…',
      'url': 'https://dailyhodl.com/2022/11/03/historically-accurate-signal-suggests-macro-bitcoin-shift-has-arrived-according-to-trader-who-called-may-2021-btc-collapse/',
      'urlToImage': 'https://dailyhodl.com/wp-content/uploads/2022/11/historically-reliable-turnaround-here.jpg',
      'publishedAt': '2022-11-03T11:45:20Z',
      'content': 'A popular crypto analyst who called the May 2021 Bitcoin (BTC) collapse says a historically reliable indicator is flashing bullish for the digital asset.\r\nPseudonymous analyst Dave the Wave tells his… [+1825 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Decrypt'
      },
      'author': 'Will McCurdy',
      'title': 'Instagram Influencer Pleads Guilty for Stealing $2.5M in Bitcoin',
      'description': 'The accused allegedly offered to purchase Bitcoin at above-market rates, before forging fake wire transfer receipts to victims.',
      'url': 'https://decrypt.co/113451/instagram-influencer-pleads-guilty-stealing-2-5m-bitcoin',
      'urlToImage': 'https://cdn.decrypt.co/resize/1024/height/512/wp-content/uploads/2022/11/bitcoin-fraud-scheme-gID_1.jpg',
      'publishedAt': '2022-11-03T11:40:50Z',
      'content': 'New York-based Instagram influencer Jebara Igbarawho went by the online pseudonym Jay Mazinihas pled guilty to an assortment of crimes including stealing $2.5 million worth of Bitcoin from his follow… [+2286 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Seeking Alpha'
      },
      'author': 'Stock Market Live',
      'title': 'Caterpillar: Digging For Levels Of Entry',
      'description': 'Apart from the usual stuff, this week, Daniel Snyder and Austin Hankwitz dig deep into Caterpillar, Inc. Click here to listen / read this week\'s Stock Market Live.',
      'url': 'https://seekingalpha.com/article/4552419-caterpillar-inc-digging-for-levels-for-entry',
      'urlToImage': 'https://static.seekingalpha.com/assets/og_image_1200-29b2bfe1a595477db6826bd2126c63ac2091efb7ec76347a8e7f81ba17e3de6c.png',
      'publishedAt': '2022-11-03T11:30:00Z',
      'content': '0 seconds of 1 hour, 21 secondsVolume 0%\r\nPress shift question mark to access a list of keyboard shortcutsKeyboard ShortcutsEnabledDisabled\r\nPlay/PauseSPACE\r\nIncrease Volume↑\r\nDecrease Volume↓\r\nSeek … [+70244 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Dutchcowboys.nl'
      },
      'author': 'Redactie Dutchcowboys, Redactie Dutchcowboys',
      'title': '​Coinbase krijgt groen licht van Nederlandse toezichthouder om uit te breiden in de hele EU',
      'description': 'Het Amerikaanse cryptocurrency platform Coinbase zet zijn snelle Europese uitbreiding voort na goedkeuring van de regelgevende instanties in Nederland.',
      'url': 'https://www.dutchcowboys.nl/online/coinbase-krijgt-groen-licht-van-nederlandse-toezichthouder-om-uit-te-breiden-in-de-hele-eu',
      'urlToImage': 'https://www.dutchcowboys.nl/uploads/posts/list/coinbase-nl.jpg',
      'publishedAt': '2022-11-03T11:30:00Z',
      'content': 'De adoptie van cryptocurrency is weer een stap dichterbij na het akkoord van de Nederlandse toezichthouder om uit te breiden in de hele EU. Het Amerikaanse cryptocurrency platform Coinbase zet zijn s… [+5501 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'CoinDesk'
      },
      'author': 'Marc Hochstein, Daniel Kuhn, David Z. Morris, Ben Schiller, Sage D. Young',
      'title': 'Vitalik Buterin\'s \'Proof of Stake\': The CoinDesk Megareview',
      'description': 'What a decade of essays – covering everything from Soulbound tokens to superrational DAOs – says about Ethereum and crypto.',
      'url': 'https://www.coindesk.com/layer2/2022/11/03/vitalik-buterins-proof-of-stake-the-coindesk-megareview/',
      'urlToImage': 'https://www.coindesk.com/resizer/eVU9Opfk6JBtdMd1_96-ulsauYQ=/1200x628/center/middle/cloudfront-us-east-1.images.arcpublishing.com/coindesk/JCGUMB55GBGCNPOKZZKGGOE2BQ.jpg',
      'publishedAt': '2022-11-03T11:30:00Z',
      'content': 'While hes best known as the co-creator of Ethereum, Vitalik Buterin began his cryptocurrency journey in a role that often gets a bit less respect: journalist.\r\nDriven by his interest in Bitcoin, Bute… [+17356 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Finbold.com'
      },
      'author': 'Paul L.',
      'title': 'Record inflow of stablecoins flood spot exchanges; Is a Bitcoin rally imminent?',
      'description': 'The value of Bitcoin (BTC) is facing bearish pressure, with the asset staring at a possible breach below the crucial... Continue reading \nThe post Record inflow of stablecoins flood spot exchanges; Is a Bitcoin rally imminent? appeared first on Finbold.',
      'url': 'https://finbold.com/record-inflow-of-stablecoins-flood-spot-exchanges-is-a-bitcoin-rally-imminent/',
      'urlToImage': 'https://finbold.com/app/uploads/2022/11/Record-inflow-of-stablecoins-flood-spot-exchanges-Is-a-Bitcoin-rally-imminent.jpeg',
      'publishedAt': '2022-11-03T11:22:53Z',
      'content': 'The value of Bitcoin (BTC) is facing bearish pressure, with the asset staring at a possible breach below the crucial $20,000 level in the wake of the latest Federal Reserve interest rate hike. \r\nAmid… [+2170 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'newsBTC'
      },
      'author': 'Jake Simmons',
      'title': 'Bitcoin Whales Worth $3.8 Billion Emerge As Price Aims for $21k',
      'description': 'Despite the macroeconomic headwinds, the Bitcoin price has been holding up far better than the traditional markets in recent weeks. As Bitcoinist reported, Bitcoin showed strength just yesterday once again. As the US Federal Reserve sent rather hawkish signal…',
      'url': 'https://www.newsbtc.com/news/bitcoin/bitcoin-whales-worth-3-8-billion-emerge-as-price-aims-for-21k/',
      'urlToImage': 'https://www.newsbtc.com/wp-content/uploads/2021/12/btc-whale.jpeg',
      'publishedAt': '2022-11-03T11:18:29Z',
      'content': 'Despite the macroeconomic headwinds, the Bitcoin price has been holding up far better than the traditional markets in recent weeks. As Bitcoinist reported, Bitcoin showed strength just yesterday once… [+3310 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Motley Fool'
      },
      'author': 'newsfeedback@fool.com (Nicholas Rossolillo)',
      'title': 'Alphabet Blames Crypto Ad Spend for a Revenue Slowdown',
      'description': 'But even without wanton crypto spending, digital ads can still grow.',
      'url': 'https://www.fool.com/investing/2022/11/03/alphabet-blames-crypto-ad-spend-for-a-revenue-slow/',
      'urlToImage': 'https://g.foolcdn.com/editorial/images/707098/bitcoin-digital-payment.jpg',
      'publishedAt': '2022-11-03T11:15:00Z',
      'content': 'Google parent Alphabet (GOOGL -3.87%) (GOOG -3.79%) has been clobbered this year. After another round of punishment following the third-quarter 2022 update, shares are now down 38% this year with jus… [+3930 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'City A.M.'
      },
      'author': 'Darren Parkin',
      'title': 'The serious money will be bullish on Fed decision that gave volatility back to crypto markets',
      'description': 'DeVere chief Nigel Green explains why the price of Bitcoin surged and then pulled back following Fed Chair Jerome Powell\'s latest speech.\nThe post The serious money will be bullish on Fed decision that gave volatility back to crypto markets appeared first on …',
      'url': 'https://www.cityam.com/the-serious-money-will-be-bullish-on-fed-decision-that-gave-volatility-back-to-crypto-markets/',
      'urlToImage': 'https://www.cityam.com/wp-content/uploads/2021/11/CAMD-G89-1024-NGreen-copy.jpg',
      'publishedAt': '2022-11-03T11:12:21Z',
      'content': 'Thursday 03 November 2022 11:12 am\r\nBitcoin and cryptocurrency investors who have been bemoaning the lack of volatility have had their wishes come true it would seem.\r\nThe worlds largest digital asse… [+2980 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'City A.M.'
      },
      'author': 'Darren Parkin',
      'title': 'Bitcoin clings on to $20k in wake of rate hike announcement',
      'description': 'After almost touching $20,000 yesterday, Bitcoin opened at $20,312 this morning, down by less than a percentage point on the day.\nThe post Bitcoin clings on to $20k in wake of rate hike announcement  appeared first on CityAM.',
      'url': 'https://www.cityam.com/bitcoin-clings-on-to-20k-in-wake-of-rate-hike-announcement/',
      'urlToImage': 'https://www.cityam.com/wp-content/uploads/2022/08/CAMD-G89-1024-luno.jpg',
      'publishedAt': '2022-11-03T11:08:34Z',
      'content': 'Thursday 03 November 2022 11:08 am\r\nIt seems that yesterdays announcement by Fed Chair Jerome Powell that the Fed would continue raising interest rates was priced in by cryptocurrency markets.\r\nAfter… [+5315 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Seeking Alpha'
      },
      'author': 'Wall Street Breakfast',
      'title': 'Wall Street Breakfast: No Pivot Yet',
      'description': 'Listen on the go! A daily podcast of Wall Street Breakfast will be available by 8:00 a.m. on Seeking Alpha, iTunes, Stitcher and Spotify.',
      'url': 'https://seekingalpha.com/article/4552465-wall-street-breakfast-no-pivot-yet',
      'urlToImage': 'https://static.seekingalpha.com/cdn/s3/uploads/getty_images/1242146684/image_1242146684.jpg?io=getty-c-w750',
      'publishedAt': '2022-11-03T11:05:09Z',
      'content': 'Listen on the go! A daily podcast of Wall Street Breakfast will be available by 8:00 a.m. on Seeking Alpha, iTunes, Stitcher and Spotify.\r\nGetty Images\r\nNo pivot yet\r\n Investors need some time off af… [+8616 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'PRNewswire'
      },
      'author': null,
      'title': 'Opera premieres deep NFT analytics tool DegenKnows, releases NEAR, Elrond, and Fantom support in the Opera Crypto Browser',
      'description': 'Opera\'s new NFT analytics tool DegenKnows allows users to discover and track NFT projects\' ratings and reputation while providing access to actionable insights from top wallets including celebrities, Alpha groups, and Influencers. Opera Crypto Browser adds su…',
      'url': 'https://www.prnewswire.com/news-releases/opera-premieres-deep-nft-analytics-tool-degenknows-releases-near-elrond-and-fantom-support-in-the-opera-crypto-browser-301667467.html',
      'urlToImage': 'https://mma.prnewswire.com/media/1937275/Slide_16_9___102.jpg?p=facebook',
      'publishedAt': '2022-11-03T11:03:00Z',
      'content': '<ul><li>Opera\'s new NFT analytics tool DegenKnows allows users to discover and track NFT projects\' ratings and reputation while providing access to actionable insights from top wallets including cele… [+6784 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Resilience'
      },
      'author': 'Richard Heinberg',
      'title': 'The Final Doubling',
      'description': 'It is better to anticipate the final doubling too soon rather than too late, because it will take time to shift expectations away from continuous growth.',
      'url': 'https://www.resilience.org/stories/2022-11-03/the-final-doubling/',
      'urlToImage': 'https://www.resilience.org/wp-content/uploads/2022/11/1280px-Cryptocurrency_Mining_Farm.jpg',
      'publishedAt': '2022-11-03T10:59:55Z',
      'content': 'This essay is dedicated to the memory of Herman Daly, the father of ecological economics, who began writing about the absurdity of perpetual economic growth in the 1970s; Herman died on October 28 at… [+10719 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'BeInCrypto'
      },
      'author': 'Luis Jesús Blanco Crespo',
      'title': 'Los mineros de Bitcoin (BTC) luchan por sobrevivir: la dificultad alcanza ATH',
      'description': 'Los mineros de Bitcoin ya no encuentran rentable minar BTC. El aumento de los precios de la energía, la inflación y la recesión que se avecina han puesto bajo presión a muchas de estas empresas. La minería de Bitcoin se ha convertido en una tarea cada vez más…',
      'url': 'https://es.beincrypto.com/mineros-bitcoin-btc-luchan-sobrevivir-dificultad-alcanza-ath/',
      'urlToImage': 'https://s32679.pcdn.co/wp-content/uploads/2022/10/BIC_bitcoin_minin2_111022.jpg.webp',
      'publishedAt': '2022-11-03T10:58:00Z',
      'content': 'Los mineros de Bitcoin ya no encuentran rentableminar BTC. El aumento de los precios de la energía, la inflación y la recesión que se avecina han puesto bajo presión a muchas de estas empresas.\r\nLa m… [+3925 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'CoinDesk'
      },
      'author': 'Parikshit Mishra',
      'title': 'Digital Asset Platform Bakkt to Buy Apex Crypto for up to $200M',
      'description': 'The firm will pay $55 million in cash, upon deal close, and $145 million in stock and seller notes upon achieving certain financial targets.',
      'url': 'https://www.coindesk.com/business/2022/11/03/digital-asset-platform-bakkt-to-buy-apex-crypto-for-up-to-200m/',
      'urlToImage': 'https://www.coindesk.com/resizer/pevz4ApVQ1ul9VUeOWU3EOj0ZnM=/1200x628/center/middle/cloudfront-us-east-1.images.arcpublishing.com/coindesk/6U3KA35YE5ANJDETQM6BOVEBKY.jpg',
      'publishedAt': '2022-11-03T10:44:59Z',
      'content': 'Digital asset platform Bakkt Holdings (BKKT) said it will acquire crypto trading infrastructure firm Apex Crypto from Apex Fintech Solutions, the firm said on Thursday.\r\nBakkt, which is majority-owne… [+1545 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Moneycontrol'
      },
      'author': 'Hindi.Moneycontrol.com Team, Moneycontrol Hindi',
      'title': 'Crypto Price: क्रिप्टो मार्केट में चहल-पहल लेकिन फिसला BitCoin, चेक करें टॉप-10 करेंसीज के लेटेस्ट भाव',
      'description': 'Crypto Price: क्रिप्टो मार्केट में आज (3 नवंबर) अच्छी चहल-पहल दिख रही है। मार्केट कैप के हिसाब से टॉप-10 क्रिप्टो में शुमार सिर्फ एक को छोड़ अन्य क्रिप्टोकरेंसीज ग्रीन जोन में है। टॉप-10 क्रिप्टो में सबसे अधिक तेजी बीएनबी (BNB) में दिख रही है, वहीं सिर्फ बिटक…',
      'url': 'https://hindi.moneycontrol.com/news/business/crypto-market-in-green-zone-zone-only-one-down-in-top-10-crypto-bitcoin-price-slips-check-top-10-cryptocurrencies-price-today-864791.html',
      'urlToImage': 'https://images.moneycontrol.com/static-hindinews/2022/11/crypto-pixabay-1-770x430.jpg',
      'publishedAt': '2022-11-03T10:39:31Z',
      'content': 'Crypto Price: (3 ) - -10 -10 (BNB) , (BitCoin) \r\n (BitCoin) 0.62 20,325.51 (16.85 ) (BitCoin Price) 0.13% 1.01 (83.75 ) \r\nDogeCoin \r\n 10 - (DogCoin) 79 - 1 (Ethereum) \r\nAdani Wilmar , 2% \r\n 10 \r\n<tab… [+688 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Blockworks.co'
      },
      'author': 'Sebastian Sinclair',
      'title': 'Bitcoin Miner Iris Energy Says its Bad Debt Contained in ‘Rotten Arms’',
      'description': 'Embattled Iris Energy appears confident that its $103 million debt has been contained within special purpose vehicles\nThe post Bitcoin Miner Iris Energy Says its Bad Debt Contained in ‘Rotten Arms’ appeared first on Blockworks.',
      'url': 'http://blockworks.co/bitcoin-miner-iris-energy-says-its-bad-debt-contained-in-rotten-arms/',
      'urlToImage': 'https://blockworks.co/wp-content/uploads/2022/11/irisenergy.jpg',
      'publishedAt': '2022-11-03T10:38:06Z',
      'content': 'Webinar Tomorrow: The Bull Case for AMMs as an Institutional Yield Product. Register.\r\nThe Bull Case for AMMs as an Institutional Yield ProductBy\r\n Jayne Ritter/October 21, 2022, 1:12 pm EDT\r\nThe hig… [+4429 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Ambcrypto.com'
      },
      'author': 'Suzuki Shillsalot',
      'title': 'Marathon Digital records highest BTC production in October',
      'description': 'United States-based cryptocurrency mining organization Marathon Digital Holdings mined 615 BTC this October, the highest monthly total in its history. Marathon’s October mining production is only one BTC below its production during the third quarter of 2022, …',
      'url': 'https://ambcrypto.com/marathon-digital-records-highest-btc-production-in-october/',
      'urlToImage': 'https://ambcrypto.com/wp-content/uploads/2022/11/ricardo-gomez-angel-F2iCP_knaj8-unsplash-1000x600.jpg',
      'publishedAt': '2022-11-03T10:30:55Z',
      'content': 'United States-based cryptocurrency mining organization Marathon Digital Holdings mined 615 BTC this October, the highest monthly total in its history.\r\nMarathons October mining production is only one… [+2654 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'PRNewswire'
      },
      'author': null,
      'title': 'Hut 8 Mining Production and Operations Update for October 2022',
      'description': '299 Bitcoin mined, bringing reserves to 8,687 TORONTO, Nov. 3, 2022 /PRNewswire/ -- Hut 8 Mining Corp. (Nasdaq: HUT) (TSX: HUT), ("Hut 8" or the "Company") one of North America\'s largest, innovation-focused digital asset mining pioneers and high performance c…',
      'url': 'https://www.prnewswire.com/news-releases/hut-8-mining-production-and-operations-update-for-october-2022-301667265.html',
      'urlToImage': 'https://mma.prnewswire.com/media/1936977/Hut_8_Mining_Corp_Hut_8_Mining_Production_and_Operations_Update.jpg?p=facebook',
      'publishedAt': '2022-11-03T10:30:00Z',
      'content': '299 Bitcoin mined, bringing reserves to 8,687\r\nTORONTO, Nov. 3, 2022 /PRNewswire/ -- Hut 8 Mining Corp. (Nasdaq: HUT) (TSX: HUT), ("Hut 8" or the "Company") one of North America\'s largest, innovation… [+8025 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'PR Newswire UK'
      },
      'author': null,
      'title': 'Hut 8 Mining Production and Operations Update for October 2022',
      'description': '299 Bitcoin mined, bringing reserves to 8,687 TORONTO, Nov. 3, 2022 /PRNewswire/ -- Hut 8 Mining Corp. (Nasdaq: HUT) (TSX: HUT), ("Hut 8" or the "Company") one of North America\'s largest, innovation-focused digital asset mining pioneers and high performance c…',
      'url': 'https://www.prnewswire.co.uk/news-releases/hut-8-mining-production-and-operations-update-for-october-2022-301667265.html',
      'urlToImage': 'https://mma.prnewswire.com/media/1936977/Hut_8_Mining_Corp_Hut_8_Mining_Production_and_Operations_Update.jpg?p=facebook',
      'publishedAt': '2022-11-03T10:30:00Z',
      'content': '299 Bitcoin mined, bringing reserves to 8,687\r\nTORONTO, Nov. 3, 2022 /PRNewswire/ -- Hut 8 Mining Corp. (Nasdaq: HUT) (TSX: HUT), ("Hut 8" or the "Company") one of North America\'s largest, innovation… [+8035 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Yahoo Entertainment'
      },
      'author': 'SPHERICAL INSIGHTS LLP',
      'title': 'Global Blockchain AI Market Size to grow USD 980.70 Million by 2030 | CAGR of 24.06%',
      'description': 'The Global Blockchain AI Market Size was valued at USD 230.10 Million in 2021, the market is projected to grow USD 980.70 Million in 2030, at a CAGR of 24.06...',
      'url': 'https://finance.yahoo.com/news/global-blockchain-ai-market-size-103000861.html',
      'urlToImage': 'https://media.zenfs.com/en/globenewswire.com/bd0306a12d00a73916fcea92dc37a9f3',
      'publishedAt': '2022-11-03T10:30:00Z',
      'content': 'The Global Blockchain AI Market Size was valued at USD 230.10 Million in 2021, the market is projected to grow USD 980.70 Million in 2030, at a CAGR of 24.06%. Companies Covered: Cyware, NeuroChain T… [+9527 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Yahoo Entertainment'
      },
      'author': 'CNW Group',
      'title': 'Hut 8 Mining Production and Operations Update for October 2022',
      'description': 'Hut 8 Mining Corp. (Nasdaq: HUT) (TSX: HUT), ("Hut 8" or the "Company") one of North America\'s largest, innovation-focused digital asset mining pioneers and ...',
      'url': 'https://finance.yahoo.com/news/hut-8-mining-production-operations-103000193.html',
      'urlToImage': 'https://media.zenfs.com/en/cnwgroup.com/9c5ae97df211dcc31c84c07f8e0a3e9d',
      'publishedAt': '2022-11-03T10:30:00Z',
      'content': '299 Bitcoin mined, bringing reserves to 8,687\r\nTORONTO, Nov. 3, 2022 /CNW/ - Hut 8 Mining Corp. (Nasdaq: HUT) (TSX: HUT), ("Hut 8" or the "Company") one of North America\'s largest, innovation-focused… [+8133 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'PRNewswire'
      },
      'author': null,
      'title': 'Hut 8 Mining Production and Operations Update for October 2022',
      'description': '299 Bitcoin mined, bringing reserves to 8,687 TORONTO, Nov. 3, 2022 /PRNewswire/ - Hut 8 Mining Corp. (Nasdaq: HUT) (TSX: HUT), ("Hut 8" or the "Company") one of North America\'s largest, innovation-focused digital asset mining pioneers and high performance co…',
      'url': 'https://www.prnewswire.com/news-releases/hut-8-mining-production-and-operations-update-for-october-2022-301667221.html',
      'urlToImage': 'https://mma.prnewswire.com/media/1936977/Hut_8_Mining_Corp_Hut_8_Mining_Production_and_Operations_Update.jpg?p=facebook',
      'publishedAt': '2022-11-03T10:30:00Z',
      'content': '299 Bitcoin mined, bringing reserves to 8,687\r\nTORONTO, Nov. 3, 2022 /PRNewswire/ - Hut 8 Mining Corp. (Nasdaq: HUT) (TSX: HUT), ("Hut 8" or the "Company") one of North America\'s largest, innovation-… [+7786 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'GlobeNewswire'
      },
      'author': 'SPHERICAL INSIGHTS LLP',
      'title': 'Global Blockchain AI Market Size to grow USD 980.70 Million by 2030 | CAGR of 24.06%',
      'description': 'The Global Blockchain AI Market Size was valued at USD 230.10 Million in 2021, the market is projected to grow USD 980.70 Million in 2030, at a CAGR of 24.06%. Companies Covered: Cyware, NeuroChain Tech, SingularityNET, Core Scientific, Inc, etObjex, Fetch.ai…',
      'url': 'https://www.globenewswire.com/news-release/2022/11/03/2547348/0/en/Global-Blockchain-AI-Market-Size-to-grow-USD-980-70-Million-by-2030-CAGR-of-24-06.html',
      'urlToImage': 'https://ml.globenewswire.com/Resource/Download/b27a94e8-407e-46ae-a5c2-47bb2a1c8a2e?size=1',
      'publishedAt': '2022-11-03T10:30:00Z',
      'content': 'New York, United States , Nov. 03, 2022 (GLOBE NEWSWIRE) -- The Global Blockchain AI Market Size was valued at USD 230.10 Million in 2021, the market is projected to grow USD 980.70 Million in 2030, … [+9117 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Decrypt'
      },
      'author': 'Ziwang',
      'title': 'Polygon, Arweave Jump Double Digits as Meta’s Instagram Announces New NFT Tools',
      'description': 'Like Reddit, Instagram turned to Polygon for its NFT plans, while attempting to offer users permanent storage through Arweave.',
      'url': 'https://decrypt.co/113446/polygon-arweave-jump-double-digits-meta-instagram-announces-new-nft-tools',
      'urlToImage': 'https://cdn.decrypt.co/resize/1024/height/512/wp-content/uploads/2022/08/Meta-Instagram-NFT-Shutterstock-scaled-gID_5.jpg',
      'publishedAt': '2022-11-03T10:27:07Z',
      'content': 'It\'s been a big day for Arweave and Polygon.\r\nNews that Instagram will leverage the Polygon blockchain to let users mint and sell NFTs has sent blockchains MATIC soaring by 14.3% since yesterday, per… [+2046 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Finbold.com'
      },
      'author': 'Ana Nicenko',
      'title': 'Trading expert identifies key Bitcoin price level to hold for rebound to $21,000',
      'description': 'After a brief recovery, the cryptocurrency market has taken a new blow with the recent Federal Reserve rate hike of... Continue reading \nThe post Trading expert identifies key Bitcoin price level to hold for rebound to $21,000 appeared first on Finbold.',
      'url': 'https://finbold.com/trading-expert-identifies-key-bitcoin-price-level-to-hold-for-rebound-to-21000/',
      'urlToImage': 'https://finbold.com/app/uploads/2022/11/Trading-expert-identifies-key-Bitcoin-price-level-to-hold-for-rebound-to-21000.jpg',
      'publishedAt': '2022-11-03T10:15:05Z',
      'content': 'After a brief recovery, the cryptocurrency market has taken a new blow with the recent Federal Reserve rate hike of 75 basis points, and now investors and traders are wondering about the future of it… [+2241 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Motley Fool'
      },
      'author': 'newsfeedback@fool.com (Katie Brockman)',
      'title': 'How Should Crypto Play Into Your Retirement Plan?',
      'description': 'Here\'s how to take advantage of crypto while keeping your savings safe.',
      'url': 'https://www.fool.com/investing/2022/11/03/how-should-crypto-play-into-your-retirement-plan/',
      'urlToImage': 'https://g.foolcdn.com/editorial/images/707163/person-smiling-and-holding-a-tablet.jpg',
      'publishedAt': '2022-11-03T10:15:00Z',
      'content': 'Cryptocurrency can be a potentially lucrative investment. And with the right strategy, it could help pad your retirement savings.\r\nBut it can also be incredibly risky. Even if you do everything right… [+3370 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Coinjournal.net'
      },
      'author': 'Dan Ashmore',
      'title': 'One in three Brits now own crypto',
      'description': 'One in three Brits now own crypto We published a piece recently outlining how British interest in cryptocurrency has fallen 82% this year.  We also mentioned, however, that there was a positive spin coming out of all the data: “(The data shows) ownership of c…',
      'url': 'https://coinjournal.net/news/one-in-three-brits-now-own-crypto/',
      'urlToImage': 'https://coinjournal.net/wp-content/uploads/2017/06/1654075053869-47770d63-48af-42db-8f71-2e08f6ec84b5.jpg',
      'publishedAt': '2022-11-03T10:12:50Z',
      'content': 'One in three Brits now own crypto\r\nWe published a piece\r\n recently outlining how British interest in cryptocurrency has fallen 82% this year. \r\nWe also mentioned, however, that there was a positive s… [+3228 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Coinjournal.net'
      },
      'author': 'Dan Ashmore',
      'title': 'Decentraland the fastest growing crypto – eToro',
      'description': 'Key Takeaways Decentraland has added more holders than any token since last year, increasing 469% according to eToro Top 3 movers comprise MANA (metaverse), SHIBA (meme) and ENJ (gaming) Bitcoin is the most popular crypto, pushing last year’s leader Cardano d…',
      'url': 'https://coinjournal.net/news/decentraland-the-fastest-growing-crypto-etoro/',
      'urlToImage': 'https://coinjournal.net/wp-content/uploads/2017/06/1667470009917-2ad88d45-9fd6-4fcd-b101-fb995fb6bb75.jpg',
      'publishedAt': '2022-11-03T10:07:03Z',
      'content': 'Key Takeaways\r\n<ul><li>Decentraland has added more holders than any token since last year, increasing 469% according to eToro</li><li>Top 3 movers comprise MANA (metaverse), SHIBA (meme) and ENJ (gam… [+3229 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'MakeUseOf'
      },
      'author': 'Katie Rees',
      'title': 'Can You Mine Crypto Using Your Smartphone?',
      'description': 'You use a computer to mine crypto, so it stands to reason that you could also use a smartphone, right?',
      'url': 'https://www.makeuseof.com/can-you-mine-crypto-using-your-smartphone/',
      'urlToImage': 'https://static1.makeuseofimages.com/wordpress/wp-content/uploads/2022/10/crypto-smartphone-1.jpg',
      'publishedAt': '2022-11-03T10:00:14Z',
      'content': 'Today, millions of people around the world are making money through cryptocurrency mining. Not only are you securing blockchain networks through mining, but you can earn a steady stream of income. Wh… [+6341 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Coinjournal.net'
      },
      'author': 'Hassan Maishera',
      'title': 'Crypto winter ‘only going to get worse,’ says Tezos co-founder',
      'description': 'The crypto winter has been in play for the past 12 months, and Tezos’s co-founder believes it could get worse in the coming months. Kathleen Breitman, the co-founder of the Tezos blockchain, believes that the crypto winter is only going to get worse. The cryp…',
      'url': 'https://coinjournal.net/news/crypto-winter-only-going-to-get-worse-says-tezos-co-founder/',
      'urlToImage': 'https://coinjournal.net/wp-content/uploads/2017/06/1667468079601-4e257d77-f8e4-4f1d-b06d-60a194746e25.jpg',
      'publishedAt': '2022-11-03T09:36:03Z',
      'content': 'The crypto winter has been in play for the past 12 months, and Tezos’s co-founder believes it could get worse in the coming months.\r\nKathleen Breitman, the co-founder of the Tezos blockchain\r\n, belie… [+2196 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'U.Today'
      },
      'author': 'U.Today',
      'title': 'Litecoin (LTC) "On Nice Run," Unlike Other Crypto, Here Are Reasons',
      'description': 'Litecoin is still holding at $62 high achieved yesterday, here\'s what has been fueling it',
      'url': 'https://u.today/litecoin-ltc-on-nice-run-unlike-other-crypto-here-are-reasons',
      'urlToImage': 'https://u.today/sites/default/files/styles/twitter/public/2022-11/25413.jpg',
      'publishedAt': '2022-11-03T09:34:00Z',
      'content': 'Disclaimer: The opinion expressed here is not investment advice it is provided for informational purposes only. It does not necessarily reflect the opinion of U.Today. Every investment and all tradin… [+1900 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Yahoo Entertainment'
      },
      'author': 'Oisin Mcilroy',
      'title': 'Markets: Bitcoin, Ether fall; top 10 crypto mixed following Fed rate hike',
      'description': 'Bitcoin and Ether dropped in Thursday afternoon trading in Asia, while the rest of the top 10 cryptocurrencies by market capitalization, excluding...',
      'url': 'https://finance.yahoo.com/news/markets-bitcoin-ether-fall-top-093238883.html',
      'urlToImage': 'https://s.yimg.com/ny/api/res/1.2/wcekHpS4OMJ3Hyz5yoUqHg--/YXBwaWQ9aGlnaGxhbmRlcjt3PTEyMDA7aD04MDA-/https://media.zenfs.com/en/forkast_news_articles_672/1affd46d1d3e1e8defdf01c66ffd531e',
      'publishedAt': '2022-11-03T09:32:38Z',
      'content': 'Bitcoin and Ether dropped in Thursday afternoon trading in Asia, while the rest of the top 10 cryptocurrencies by market capitalization, excluding stablecoins, posted mixed results. The Hong Kong Han… [+1970 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Forkast.news'
      },
      'author': 'Oisin Mcilroy',
      'title': 'Markets: Bitcoin, Ether fall; top 10 crypto mixed following Fed rate hike',
      'description': 'Bitcoin and Ether dropped in Thursday afternoon trading in Asia, while the rest of the top 10 cryptocurrencies by market capitalization, excluding stablecoins, posted mixed results.',
      'url': 'https://forkast.news/headlines/bitcoin-ether-crypto-mixed-fed-rate-hike/',
      'urlToImage': 'https://forkast.news/wp-content/uploads/2022/10/1847131312-1260x840.jpg',
      'publishedAt': '2022-11-03T09:32:38Z',
      'content': 'Bitcoin and Ether dropped in Thursday afternoon trading in Asia, while the rest of the top 10 cryptocurrencies by market capitalization, excluding stablecoins, posted mixed results. The Hong Kong Han… [+1961 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Tiktok.com'
      },
      'author': null,
      'title': 'ΒΓΑΛΤΕ 9.150€ ΑΠΟ ΤΟ AFFILIATE MARKETING ΚΑΙ ΤΑ ΚΡΥΠΤΟ ΣΕ 14 ΜΕΡΕΣ 2 #DIVRAMIS #CRYPTO #foryou #bitcoin #cryptocurrency #CRYPTOK #affiliate',
      'description': 'ΒΓΑΛΤΕ 9.150€ ΑΠΟ ΤΟ AFFILIATE MARKETING ΚΑΙ ΤΑ ΚΡΥΠΤΟ ΣΕ 14 ΜΕΡΕΣ 2 #DIVRAMIS #CRYPTO #foryou #bitcoin #cryptocurrency #CRYPTOK #affiliatetiktok.com',
      'url': 'https://www.tiktok.com/@divramis1/video/7161708736061443334',
      'urlToImage': 'https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/dd9192b5da2647dfa6cc6d851fc18d16_1667465270~tplv-tiktok-play.jpeg?x-expires=1668070800&x-signature=TdkKtBljZMDzE%2BoR3%2F%2F4tZc3A%2B8%3D',
      'publishedAt': '2022-11-03T09:30:24Z',
      'content': 'Log in to follow creators, like videos, and view comments.\r\nLog in'
    },
    {
      'source': {
        'id': null,
        'name': 'Yahoo Entertainment'
      },
      'author': 'South China Morning Post',
      'title': 'Cryptocurrencies, blockchain are reshaping future of finance, say global banking chiefs',
      'description': 'Technologies such as blockchain and central bank digital currencies are reshaping the future of finance, leaders of major banks said during a global...',
      'url': 'https://finance.yahoo.com/news/cryptocurrencies-blockchain-reshaping-future-finance-093000877.html',
      'urlToImage': 'https://s.yimg.com/ny/api/res/1.2/B1t4ZW21djWQVuN62CnxgA--/YXBwaWQ9aGlnaGxhbmRlcjt3PTEyMDA7aD03ODg-/https://media.zenfs.com/en/south_china_morning_post_us_228/b16389cef0f95245f122594892f9aee3',
      'publishedAt': '2022-11-03T09:30:00Z',
      'content': 'Technologies such as blockchain and central bank digital currencies are reshaping the future of finance, leaders of major banks said during a global financial conference in Hong Kong.\r\nSenior figures… [+4808 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Bitcoinist'
      },
      'author': 'Denis',
      'title': 'Rising Bitcoin Hash Rate Sets The Stage For Energy Companies',
      'description': 'Bitcoin Mining hash rates are important security metrics as they signify the network’s overall resistance to malicious attacks. Hash rates also measure a blockchain network’s ability to process transactions. Calculations of hash rates may enable miners to for…',
      'url': 'https://bitcoinist.com/bitcoin-hash-rate-sets-stage-energy-companies/',
      'urlToImage': 'https://bitcoinist.com/wp-content/uploads/2022/11/pexels-tima-miroshnichenko-7567430.jpg',
      'publishedAt': '2022-11-03T09:28:56Z',
      'content': 'Bitcoin Mining hash rates are important security metrics as they signify the network’s overall resistance to malicious attacks. Hash rates also measure a blockchain networks ability to process transa… [+2813 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Yahoo Entertainment'
      },
      'author': 'iProUp',
      'title': 'Comprás todos los que quieras, cuando quieras, pero no son iguales: 4 tipos de dólares digitales (y cuál conviene)',
      'description': 'Comprás todos los que quieras, cuando quieras, pero no son iguales: 4 tipos de dólares digitales (y cuál conviene)',
      'url': 'https://es-us.finanzas.yahoo.com/noticias/compr%C3%A1s-quieras-quieras-iguales-4-091500180.html',
      'urlToImage': 'https://media.zenfs.com/es/iproup_407/c3e235d4140666ef4554fbf5d1c12e85',
      'publishedAt': '2022-11-03T09:15:00Z',
      'content': 'Con el nuevo "dólar Qatar" en los $330, los argentinos encuentran una nueva valla para resguardar ahorros pensando en sus vacaciones o para tener una reserva ante un 2023 que luce complicado.\r\nLas cr… [+8077 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Bitcoinist'
      },
      'author': 'Jake Simmons',
      'title': 'Bitcoin After The FOMC Dust Settled: This Date Is Crucial',
      'description': 'Yesterday’s FOMC meeting of the U.S. Federal Reserve (FED) brought less volatility to the Bitcoin market than many experts had expected. The Bitcoin price moved in a narrow range during and after the meeting. Ultimately, the Fed raised interest rates by 75 ba…',
      'url': 'https://bitcoinist.com/bitcoin-after-the-fomc-dust-this-date-is-crucial/',
      'urlToImage': 'https://bitcoinist.com/wp-content/uploads/2022/11/art-rachen-sM4r-swmcoY-unsplash-980x653.jpg',
      'publishedAt': '2022-11-03T08:00:15Z',
      'content': 'Yesterday’s FOMC meeting of the U.S. Federal Reserve (FED) brought less volatility to the Bitcoin market than many experts had expected. The Bitcoin price moved in a narrow range during and after the… [+2964 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Coinjournal.net'
      },
      'author': 'Hassan Maishera',
      'title': 'Litecoin soars by 13% following MoneyGram’s adoption announcement',
      'description': 'Litecoin is outperforming the other major cryptocurrencies after major adoption news from MoneyGram. LTC, the native coin of the Litecoin blockchain, is the best performer amongst the top 20 cryptocurrencies by market cap in the last 24 hours. The coin has ad…',
      'url': 'https://coinjournal.net/news/litecoin-soars-by-13-following-moneygrams-adoption-announcement/',
      'urlToImage': 'https://coinjournal.net/wp-content/uploads/2017/06/1665516621283-00a39a5d-cfe2-4f98-9317-d257e802701f.jpg',
      'publishedAt': '2022-11-03T07:47:45Z',
      'content': 'Litecoin is outperforming the other major cryptocurrencies after major adoption news from MoneyGram.\r\nLTC, the native coin of the Litecoin\r\n blockchain, is the best performer amongst the top 20 crypt… [+2726 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Ambcrypto.com'
      },
      'author': 'Jacob Thomas',
      'title': 'Orbeon Protocol provides access to venture capital and crowdfunding for everyday investors',
      'description': 'The crypto market’s largest types of tokens are now utility coins and meme coins. While meme coins have been extremely popular, they are not for everyone. Most investors opt to invest their hard-earned cash in a token with real-world potential, and the demand…',
      'url': 'https://ambcrypto.com/orbeon-protocol-provides-access-to-venture-capital-and-crowdfunding-for-everyday-investors/',
      'urlToImage': 'https://ambcrypto.com/wp-content/uploads/2022/11/obrn-1000x595.png',
      'publishedAt': '2022-11-03T07:45:57Z',
      'content': 'The crypto market’s largest types of tokens are now utility coins and meme coins. While meme coins have been extremely popular, they are not for everyone. Most investors opt to invest their hard-earn… [+3782 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Coinjournal.net'
      },
      'author': 'Charles Thuo',
      'title': 'Roobet Casino review: features, pros and cons',
      'description': 'Choosing a reliable cryptocurrency casino requires due diligence and if you have been perusing the internet for one, you must have come across Roobet Casino, which is a popular name in the casino market. This review looks into Roobet features, how it works, g…',
      'url': 'https://coinjournal.net/news/roobet-casino-review-features-pros-and-cons/',
      'urlToImage': 'https://coinjournal.net/wp-content/uploads/2017/06/1667461408011-940a320e-9887-46ac-b69f-f20c68837798.jpg',
      'publishedAt': '2022-11-03T07:45:21Z',
      'content': 'Choosing a reliable cryptocurrency casino requires due diligence and if you have been perusing the internet for one, you must have come across Roobet Casino, which is a popular name in the casino mar… [+5194 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Barron\'s'
      },
      'author': 'Joe Light',
      'title': 'Coinbase\'s Earnings Will Show It Needs More Than Just Crypto Trading',
      'description': 'The company will likely report continued losses when it reports its third-quarter results after the close on Thursday.',
      'url': 'https://www.barrons.com/articles/coinbase-earnings-stock-price-51667422895',
      'urlToImage': 'https://images.barrons.com/im-655031/social',
      'publishedAt': '2022-11-03T07:40:51Z',
      'content': 'Coinbase Global will likely show another drop in sales and continued losses when it reports its third-quarter results after the close on Thursday, as crypto investors settle into the sluggish market … [+3073 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'newsBTC'
      },
      'author': 'Denis',
      'title': 'These Key Factors Might Push Ethereum To Outplay Bitcoin',
      'description': 'Bitcoin, Ethereum, and the entire crypto market is often prone to price fluctuations. That’s why crypto investors must carefully monitor the market movement to avoid huge losses when prices are low. The crypto bear market could also be a time for long-term in…',
      'url': 'https://www.newsbtc.com/news/bitcoin/these-key-factors-might-push-ethereum-to-outplay-bitcoin/',
      'urlToImage': 'https://www.newsbtc.com/wp-content/uploads/2022/11/currency-g12160e7b6_1280.jpg',
      'publishedAt': '2022-11-03T07:38:59Z',
      'content': 'Bitcoin, Ethereum, and the entire crypto market is often prone to price fluctuations. Thats why crypto investors must carefully monitor the market movement to avoid huge losses when prices are low. T… [+3048 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Coinjournal.net'
      },
      'author': 'Hassan Maishera',
      'title': 'MATIC rallies by 10% after Meta enables Polygon-powered NFT minting on Instagram',
      'description': 'MATIC is one of the best performers amongst the top 20 cryptocurrencies by market cap after Meta announced an important update. MATIC, the native coin of the Polygon network, is the second-best performer amongst the top 20 cryptocurrencies by market cap, only…',
      'url': 'https://coinjournal.net/news/matic-rallies-by-10-after-meta-enables-polygon-powered-nft-minting-on-instagram/',
      'urlToImage': 'https://coinjournal.net/wp-content/uploads/2022/06/1646989292335-f7cc1b5a-1ee6-4fd3-92fd-b1244271943f.jpg',
      'publishedAt': '2022-11-03T07:23:47Z',
      'content': 'MATIC is one of the best performers amongst the top 20 cryptocurrencies by market cap after Meta announced an important update.\r\nMATIC, the native coin of the Polygon network, is the second-best perf… [+2572 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Yahoo Entertainment'
      },
      'author': 'Abigail Moses and Richard Henderson',
      'title': 'Europe Futures, Asian Shares Slip on Fed Warning: Markets Wrap',
      'description': '(Bloomberg) -- Stocks fell after Jerome Powell said the Federal Reserve would raise interest rates more than previously anticipated, sapping risk appetite...',
      'url': 'https://finance.yahoo.com/news/stocks-drop-fed-pound-falls-080654843.html',
      'urlToImage': 'https://s.yimg.com/ny/api/res/1.2/rdK6GwGn2PDbm4XOCzYr5w--/YXBwaWQ9aGlnaGxhbmRlcjt3PTEyMDA7aD04MDA-/https://media.zenfs.com/en/bloomberg_markets_842/295450f72efee4bc725147aa128c0742',
      'publishedAt': '2022-11-03T07:12:31Z',
      'content': '(Bloomberg) -- Stocks fell after Jerome Powell said the Federal Reserve would raise interest rates more than previously anticipated, sapping risk appetite. Global bond yields rose.\r\nMost Read from Bl… [+3514 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'CoinDesk'
      },
      'author': 'Sam Reynolds',
      'title': 'Did Sponsoring Stadiums Bring New Crypto Traders, or Tourists?',
      'description': 'By some metrics, Crypto.com and FTX’s stadium sponsorship campaigns were a remarkable success. Whether they succeeded in onboarding a new generation of crypto traders remains to be seen.',
      'url': 'https://www.coindesk.com/business/2022/11/03/did-sponsoring-stadiums-bring-new-crypto-traders-or-tourists/',
      'urlToImage': 'https://www.coindesk.com/resizer/qNDlMMw8soBbjePXxfxbaDlStKA=/1200x628/center/middle/cloudfront-us-east-1.images.arcpublishing.com/coindesk/HUWQPJCNNJCJVIILO4F2LRI764.jpg',
      'publishedAt': '2022-11-03T07:06:36Z',
      'content': 'Last November, at the height of the bull market, Crypto.comannounced it had obtained the naming rights to the stadium that houses the National Basketball Association (NBA) team Los Angeles Lakers, an… [+5292 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Yahoo Entertainment'
      },
      'author': 'Sam Reynolds',
      'title': 'Did Sponsoring Stadiums Bring New Crypto Traders, or Tourists?',
      'description': 'By some metrics, Crypto.com and FTX’s stadium sponsorship campaigns were a remarkable success. Whether they succeeded in onboarding a new generation of...',
      'url': 'https://finance.yahoo.com/news/did-sponsoring-stadiums-bring-crypto-070636398.html',
      'urlToImage': 'https://media.zenfs.com/en/coindesk_75/a99b0d52967a71420d16b548cba555ca',
      'publishedAt': '2022-11-03T07:06:36Z',
      'content': 'Last November, at the height of the bull market, Crypto.comannounced it had obtained the naming rights to the stadium that houses the National Basketball Association (NBA) team Los Angeles Lakers, an… [+5402 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Bitcoin Magazine'
      },
      'author': 'Bitcoin Magazine',
      'title': 'Bitcoin Is A Defensive Weapon Against War And Tyranny',
      'description': 'While some have compared Bitcoin to a tool of war, it is more like a defensive weapon that protects those who use it from authoritarianism and overreach.',
      'url': 'https://bitcoinmagazine.com/culture/bitcoin-is-a-defensive-weapon-against-war',
      'urlToImage': 'https://bitcoinmagazine.com/.image/t_share/MTkxMDI5NjYxMDQyNTUwMTI2/peace-warrior-fight-war-battle-top-photo.png',
      'publishedAt': '2022-11-03T07:00:00Z',
      'content': 'This is a transcribed excerpt of the “Bitcoin Magazine Podcast,” hosted by P and Q. In this episode, they are joined by Erik Dale to talk about how bitcoin can be used as a defensive weapon and the p… [+2216 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Theregister.com'
      },
      'author': 'Laura Dobberstein',
      'title': 'Ethereum co-founder Vitalik Buterin: if Musk\'s Twitter flops it\'s not such a bad thing',
      'description': 'Predicts heir to Twitter, without its problems, is waiting to fly. Hopes the same improvements come to crypto\nEthereum co-founder Vitalik Buterin told an audience at Singapore\'s FinTech Festival on Thursday he believes a social media platform to replace Twitt…',
      'url': 'https://www.theregister.com/2022/11/03/vitalik_buterin_elom_musk_twitter/',
      'urlToImage': 'https://regmedia.co.uk/2021/05/18/ethereum.jpg',
      'publishedAt': '2022-11-03T06:57:10Z',
      'content': 'Ethereum co-founder Vitalik Buterin told an audience at Singapore\'s FinTech Festival on Thursday he believes a social media platform to replace Twitter one that avoids many of the bird-brand\'s existi… [+3590 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Biztoc.com'
      },
      'author': 'moneytree',
      'title': 'What The Fed\'s Latest Interest Rate Hike Means For The Crypto World - BNB (BNB/USD), Bitcoin (BTC/USD)',
      'description': 'The U.S. Federal Reserve has raised interest rates by 75 basis points. The move is expected to have no impact on the value of cryptocurrencies such as Bitcoin and Ether. The Dow Jones, Nasdaq and... #benzinga #interestratehike #kempenaer #supportarea #cryptoc…',
      'url': 'https://biztoc.com/p/gd4m4hhn?ref=rss',
      'urlToImage': 'https://cdn.biztoc.com/og/gd4m4hhn.jpg',
      'publishedAt': '2022-11-03T06:14:00Z',
      'content': 'The U.S. Federal Reserve has raised interest rates by 75 basis points. The move is expected to have no impact on the value of cryptocurrencies such as Bitcoin and Ether. The Dow Jones, Nasdaq and S&a… [+1568 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Gadgets360.com'
      },
      'author': 'Radhika Parashar, Siddharth Suvarna',
      'title': 'BTC, ETH Hit With Losses as Overall Crypto Market Cap Drops by 0.86 Percent in 24 Hours',
      'description': 'The overall crypto market cap fell by 0.86 percent in the last 24 hours. BTC and ETH, the top two cryptocurrencies, opened with losses today and lead most altcoins onto a similar trajectory. Binance Coin, Ripple, Cardano emerged as loss-making crypto assets. …',
      'url': 'https://www.gadgets360.com/cryptocurrency/news/bitcoin-ether-price-today-crypto-market-cap-drop-losses-3485738',
      'urlToImage': 'https://i.gadgets360cdn.com/large/crypto_pixabay_worldspectrum_large_1647840432595.jpg',
      'publishedAt': '2022-11-03T05:55:57Z',
      'content': 'After registering continuous growth for at least five consecutive days, the crypto price charts reflected losses for most altcoins on Thursday, November 3. Bitcoin opened with a price dip of 0.86 per… [+1986 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Www.is.fi'
      },
      'author': null,
      'title': 'Poliisi jäljitti Vastaamo-tutkinnassa uhrien virtuaali­valuutalla maksamia lunnaita',
      'description': 'Toisin kuin usein luullaan, eivät virtuaalivaluutat ole anonyymeja vaihdon välineitä. Viranomaisilla on kyky jäljittää niitä.',
      'url': 'https://www.is.fi/digitoday/art-2000009176708.html',
      'urlToImage': 'https://is.mediadelivery.fi/img/some/default/7b8b53f6c198d74470258dea78e4cae4.jpg',
      'publishedAt': '2022-11-03T05:33:00Z',
      'content': 'Toisin kuin usein luullaan, eivät virtuaalivaluutat ole anonyymeja vaihdon välineitä. Viranomaisilla on kyky jäljittää niitä.Virtuaalivaluuttoja pidetään yhä usein käyttäjän henkilöllisyyden suojaavi… [+4930 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'Yahoo Entertainment'
      },
      'author': 'PR Newswire',
      'title': 'Cryptocurrency Mining Hardware Market Size to Grow by USD 12053.16 million From 2022 to 2027, Assessment on Parent Market, Five Forces Analysis, Market Dynamics & Segmentation - Technavio',
      'description': 'The Cryptocurrency Mining Hardware Market share is set to increase by USD 12053.16 million from 2022 to 2027. However, the market\'s growth momentum will...',
      'url': 'https://finance.yahoo.com/news/cryptocurrency-mining-hardware-market-size-053000399.html',
      'urlToImage': 'https://media.zenfs.com/en/prnewswire.com/9c24f401b08c37d70b0cfc9df9541224',
      'publishedAt': '2022-11-03T05:30:00Z',
      'content': 'NEW YORK, Nov. 3, 2022 /PRNewswire/ -- The Cryptocurrency Mining Hardware Market share is set to increase by USD 12053.16 million from 2022 to 2027. However, the market\'s growth momentum will acceler… [+14575 chars]'
    },
    {
      'source': {
        'id': null,
        'name': 'PRNewswire'
      },
      'author': null,
      'title': 'Cryptocurrency Mining Hardware Market Size to Grow by USD 12053.16 million From 2022 to 2027, Assessment on Parent Market, Five Forces Analysis, Market Dynamics & Segmentation - Technavio',
      'description': 'NEW YORK, Nov. 3, 2022 /PRNewswire/ -- The Cryptocurrency Mining Hardware Market share is set to increase by USD 12053.16 million from 2022 to 2027. However, the market\'s growth momentum will accelerate at a CAGR of 11.35% as per the latest market forecast re…',
      'url': 'https://www.prnewswire.com/news-releases/cryptocurrency-mining-hardware-market-size-to-grow-by-usd-12053-16-million-from-2022-to-2027---assessment-on-parent-market-five-forces-analysis-market-dynamics--segmentation---technavio-301666105.html',
      'urlToImage': 'https://mma.prnewswire.com/media/1936028/Technavio_Global_Cryptocurrency_Mining_Hardware_Market_2023_2027.jpg?p=facebook',
      'publishedAt': '2022-11-03T05:30:00Z',
      'content': 'NEW YORK, Nov. 3, 2022 /PRNewswire/ -- The Cryptocurrency Mining Hardware Market share is set to increase by USD 12053.16 million from 2022 to 2027. However, the market\'s growth momentum will acceler… [+26982 chars]'
    },
    {
      'source': {
        'id': 'the-times-of-india',
        'name': 'The Times of India'
      },
      'author': 'Pawan Nahar',
      'title': 'Crypto Price Today: Bitcoin holds $20k after Fed rate hikes; Dogecoin & Shiba Inu tank',
      'description': 'Crypto venture capital company Digital Currency Group has promoted Chief Operating Officer Mark Murphy to president amid a restructuring in which some 13% of its staff departed, Bloomberg reported.',
      'url': 'https://economictimes.indiatimes.com/markets/cryptocurrency/crypto-prices-today-live-news-bitcoin-dogecoin-ethereum-shibha-inu-cryptocurrency-latest-updates-3-november-2022/articleshow/95269041.cms',
      'urlToImage': 'https://img.etimg.com/thumb/msid-95269196,width-1070,height-580,imgsize-155172,overlay-etmarkets/photo.jpg',
      'publishedAt': '2022-11-03T05:21:12Z',
      'content': 'New Delhi: Crypto market was in the red on Thursday after the US Federal Reserve increased interest rates by another 75 basis points. Bitcoin posted mild cuts but outperformers like Dogecoin and Shib… [+3261 chars]'
    }
  ]
};