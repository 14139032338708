const { endPoints } = require('constants/employerEndpoints');
const { callApi } = require('middleware/api');

export const signIn = (data) => {
  return callApi(data, 'POST', endPoints.login, false);
};

export const signOut = () => {
  return callApi(null, 'GET', endPoints.logout, true);
};

export const registerNow = (data, type='applicant') =>{
  const resultingEndpoint = type == 'applicant' ? endPoints.register: endPoints.registerEmployer;
  return callApi( data,'POST', resultingEndpoint,false);
};

export const getUserProfileDetails = (data) => {
  return callApi(data, 'GET', `${endPoints.userProfile}/me`, true);
};

export const getEmployerProfileDetails = (data) => {
  return callApi(data, 'GET', `${endPoints.configuration.loggedInCompanyInfo}`, true);
};


export const onVerifyOtp=(data) =>{
  return callApi(data,'POST',endPoints.verifyEmail,false);
};

export const ResendOtp =(data)=>{
  return callApi(data,'POST',endPoints.resendOTP,false);
};

export const forgotPassword = (data) => {
  return callApi(data, 'POST', endPoints.forgotPassword, false);
};  