import React, { useState } from 'react';
import { Box, CircularProgress, Container, Grid, makeStyles, Menu, useMediaQuery, useTheme, Button } from '@material-ui/core';
import ComposerPanelWrapper from 'view/common/wrappers/ComposerPanelWrapper';
import InterviewCards from './InterviewCards';
import InterviewFilters from './InterviewFilters';
// import DPInfiniteScrollableWrapper from 'view/common/DPInfiniteScrollable/DPInfiniteScrollableWrapper';
// import { onGetInterviewScheduledCalls } from 'redux/actions/employerActions/interviewScheduled';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    margin: '1rem 0rem',
  },
  flexDir: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
  breadLink: {
    color: '#24283C',
    fontWeight: 600,
  },
  //* imp
  circularProgress: {
    margin: '0% auto', display: 'flex', zIndex: 1
  },
  scrolContainer: {
    height: '890px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '440px',
    },
  },
  btn: {
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  }
}));

const index=()=> {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const RendererExample = () => {
    return null;
  };
  
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const getRequestParams = useCallback(({ pageCount, pageSize }) => {
  //   return {
  //     // companyDetailsId: employerState.id,
  //     entryStatus: ['DRAFT'],
  //     // sortBy: ['lastUpdatedOn'],
  //     pageNo: pageCount,
  //     pageSize,
  //     currentTab: 'DRAFT'
  //   };
  // }, []);

  const data = [
    { designation: 'Frontend Developer', name: 'Pratik Soni', interviewer: 'Rohit Patil', organiser:'Pratik Soni', date: '23 Apr, 2021 | 09:00 - 09:30', redirect: '/employer/interviewer', interviewId: 'c164ac59-8c90-487e-8ab0-c6caeabf8394' },
    { designation: 'UI Designer', name: 'Pratik Soni', interviewer: 'Rohit Patil', organiser:'Pratik Soni', date: '23 Apr, 2021 | 09:00 - 09:30', redirect: '/employer/interviewer', interviewId: 'c164ac59-8c90-487e-8ab0-c6caeabf8394' },
    { designation: 'AEM Developer', name: 'Pratik Soni', interviewer: 'Rohit Patil', organiser:'Pratik Soni', date: '23 Apr, 2021 | 09:00 - 09:30', redirect: '/employer/interviewer', interviewId: 'c164ac59-8c90-487e-8ab0-c6caeabf8394' },
    { designation: 'Python Developer', name: 'Pratik Soni', interviewer: 'Rohit Patil', organiser:'Pratik Soni', date: '23 Apr, 2021 | 09:00 - 09:30', redirect: '/employer/interviewer', interviewId: 'c164ac59-8c90-487e-8ab0-c6caeabf8394' }
  ];

  const PanelConfig = {
    isOpen: false,
    anchor: 'right',
    Renderer: matchesSM ? RendererExample : null, // ComponentRenderer
    rendererConfigs: {},
  };

  const classes = useStyles();
  return (
    <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
      <Container style={{ marginTop: '5rem', marginBottom: '5rem', minHeight: '100vh', padding: 0 }}>
        <Box>
          <Grid container style={{ marginBottom: '20px', justifyContent: 'end' }}>
            <Button
              onClick={handleOpen}
              color='secondary'
              type="button"
              variant="outlined"
              className={classes.btn}
            >Filters</Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <InterviewFilters />
            </Menu>
          </Grid>
          <Grid container >
            {/* <DPInfiniteScrollableWrapper
              dispatcher={onGetInterviewScheduledCalls}
              dataPath={['']}
              itemRendererConfig={
                {
                  Renderer: InterviewCards,
                }
              }
              styleConfig={matchesSM ? { rowHeight: 340 } : { rowHeight: 300 }}
              pageConfig={{
                pageSize: 10,
              }}
              // getRequestParams={getRequestParams}
              // setTotalItemsCountsInParent={setTotalInterviewScheduledCalls}
            /> */}
            {data.map((item, i) => <InterviewCards key={i} {...item} />)}
          </Grid>
        </Box>
        {false && <CircularProgress color="secondary" className={classes.circularProgress} />}
      </Container>
    </ComposerPanelWrapper>
  );
};

export default index;