import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { Divider, makeStyles, Typography, InputLabel, Input, InputAdornment, FormControl, IconButton, TextField, Link, Modal, Box } from '@material-ui/core';
import { colors } from '../../../../themes/colors';
import { useDispatch } from 'react-redux';
import { onSignIn, onUserProfileDetails, onEmployerProfileDetails, onResendOtp } from 'redux/actions/userAuthentication';
import { useNavigate, useLocation } from 'react-router-dom';
import { isUndefined, isEmpty, startsWith, get } from 'lodash';
import VerifyOtpForm from 'view/applicant/forms/VerifyOtpForm';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ForgotPasswordForm from './ForgotPassword';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(6).max(15).required('Required'),
});

const useStyle = makeStyles(theme => ({
  loginBtn: {
    backgroundColor: colors.blue2,
    '&:hover': {
      backgroundColor: colors.blue1,
    },
    color: colors.white,
    fontSize: 16,
    fontWeight: 200,
    padding: '14px 16px',
    lineHeight: 1.5,
  },
  formContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  topSection: {
    marginBottom: '1rem'
  },    
  customInput: {
    marginBottom: '0.8rem',
    '& .MuiInputBase-input': {
      borderRadius: 6,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '20px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:focus': {
        boxShadow: '0px 4px 10px 3px rgba(0, 0, 0, 0.11)',
        borderColor: '#1565D8',
      },
    },
  },
  showHidePswd: {
    color: '#6361CD', position: 'absolute', right: '1rem',
  },
}));

const LoginForm = () => {

  const classes = useStyle();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [forgotPasswordOTP, setForgotPasswordOTP] = useState('');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleClose = () => {
    setShowVerifyOtp(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      showPassword: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      dispatch(onSignIn(values)).then(async (res) => {
        if (!isUndefined(res.payload)) {
          // Route to applicant flow if url has /applicant else it is employer flow
          startsWith(location.pathname, '/applicant') ?
            fetchUserProfileDetails(values) :
            fetchEmployerProfileDetails(values);
        }
      });
    },
  });

  const formikForgotPassword = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: (values) => {
      processForgotPassword(values).then(() => {
        setShowVerifyOtp(true);
      });
      
    },
  });
 
  // const processLogout = () => {
  //   dispatch(onSignOut()).then(() => {
  //     localStorage.removeItem('deepailytics.auth.token');
  //     localStorage.removeItem('deepailytics.user.role');
  //     localStorage.removeItem('deepailytics.auth.refreshToken');
  //     window.location.href = '/';
  //   });
  // };
  const fetchEmployerProfileDetails = async (values) => {
    try {
      const response = await dispatch(onEmployerProfileDetails(values));
      if (isUndefined(response.payload)) {
        throw 'No response';
      }
      if (isEmpty(response.payload)) {
        navigate('/employer/configuration');
      } else {
        navigate('/employer');
      }
    } catch (err) {
      // ToDo: Commmented for future use
      // processLogout();
    }

  };
  const fetchUserProfileDetails = async (values) => {
    try {
      const response = await dispatch(onUserProfileDetails(values));
      if (isUndefined(response.payload)) {
        throw 'No response';
      }
      if (!get(response, ['payload', 'profileCompleted'])) {
        navigate('/build-profile');
      } else {
        navigate('/applicant');
      }
    } catch (err) {
      // processLogout();
    }
  };

  const toggleForgotPassword = async () => {
    setShowForgotPassword(!showForgotPassword);
  };

  const processForgotPassword = async (values) => {
    setForgotPasswordEmail(values.email);
    await dispatch(onResendOtp({ 'email': values.email, 'type': 'FORGOT_PASSWORD' }));
  };


  // password show/hide 
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // end 

  if (showForgotPassword && isEmpty(forgotPasswordOTP)) {
    return (
      <div>
        <div className={classes.topSection}>
          <Typography variant="h4">Forgot Password</Typography>
          <Typography variant="subtitle1">Enter your email address and we'll send you an OTP to reset your password.</Typography>
        </div>
        <Divider />
        <form onSubmit={formikForgotPassword.handleSubmit} className={classes.formContainer}>
          <InputLabel required>
                Email Address
          </InputLabel>
          <TextField
            required
            InputProps={{ disableUnderline: true }}
            className={classes.customInput}
            variant="standard"
            fullWidth
            id="email"
            name="email"
            placeholder="Enter email address"
            value={formikForgotPassword.values.email}
            onChange={formikForgotPassword.handleChange}
            error={formikForgotPassword.touched.email && Boolean(formikForgotPassword.errors.email)}
            helperText={formikForgotPassword.touched.email && formikForgotPassword.errors.email} />

          <Button
            color="primary"
            variant="contained"
            fullWidth type="submit"
            // onClick={processForgotPassword}
            className={classes.loginBtn}>
                Submit
          </Button>
        </form>
        <div style={{ marginBottom: '1rem', textAlign: 'end' }}>
          Return to <Link onClick={toggleForgotPassword} style={{ color: '#1565D8', cursor: 'pointer' }}>Login</Link>
        </div>

        <Modal
          open={showVerifyOtp}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <VerifyOtpForm
              email={'preetinair3@yopmail.com'}
              handleClose={handleClose}
              type={'FORGOT_PASSWORD'}
              setForgotPasswordOTP={setForgotPasswordOTP} />
          </Box>
        </Modal>
        
      </div>
    );
  }

  return (
    <div>
      {
        isEmpty(forgotPasswordOTP) ?
          <>
            <div className={classes.topSection}>
              <Typography variant="h4">Login</Typography>
              <Typography variant="subtitle1">For the purpose of industry regulation, your details are required</Typography>
            </div>
            <Divider />
            <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
              <InputLabel required>
                Email Address
              </InputLabel>
              <TextField
                required
                InputProps={{ disableUnderline: true }}
                className={classes.customInput}
                variant="standard"
                fullWidth
                id="email"
                name="email"
                placeholder="Enter email address"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email} />

              <InputLabel required htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <FormControl fullWidth variant="outlined" className={classes.customInput} >
                <Input
                  required
                  disableUnderline
                  fullWidth
                  id="outlined-adornment-password"
                  name="password"
                  placeholder="Enter password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange('password')}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helpertext={formik.touched.password && formik.errors.password}
                  endAdornment={
                    <InputAdornment>
                      <IconButton
                        className={classes.showHidePswd}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        aria-label="toggle password visibility"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <div style={{ marginBottom: '1rem', textAlign: 'end' }}>
                <Link onClick={toggleForgotPassword} style={{ color: '#1565D8', cursor: 'pointer' }}>Forgot Password?</Link>
              </div>

              <Button color="primary" variant="contained" fullWidth type="submit" className={classes.loginBtn}>
                Login
              </Button>
            </form>
          </>
          :
          <ForgotPasswordForm
            forgotPasswordEmail={forgotPasswordEmail}
            forgotPasswordOTP={forgotPasswordOTP}
            role={'applicant'} />
      }
    </div>
  );
};

export default LoginForm;