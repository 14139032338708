import { createTheme } from '@material-ui/core/styles';
import { colors } from './colors';
import { alpha } from '@material-ui/core/styles';

const theme = createTheme({

  // components
  components: {
    MuiLink: {
      styleOverrides: {
        sx: { my: 1, mx: 1.5 },
        string: {
          fontFamily: 'inter',
          fontWeight: 400,
          fontSize: '18px',
          letterSpacing: '0.01em',
          color: '#00f',
          textDecoration: 'none',
          disableFocusRipple: 'true',
        },
      },
    },
  },

  // color palette
  palette: {
    common: {
      gray: `${colors.background1}`,
      blue: `${colors.blue1}`,
      red :'red',
      gray4:`${colors.gray4}`
    },
    primary: {
      main: `${colors.background1}`, // background1
    },
    secondary: {
      main: `${colors.blue1}`, // blue1
    },
  },

  // typography
  typography: {
    fontFamily:
      [
        'poppins',
        '"Inter"',
        'sans-serif',
      ].join(','),
    fontStyle: 'normal',
    h2: {
      fontWeight: 300,
    },
    h4: {
      color: colors.black,
      fontSize: '27px',
      fontWeight: 700,
      lineHeight: '44px',
    },
    h5: {
      color: colors.gray1,
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '27px',
    },
    h6: {
      color: colors.gray1,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    body1:{
      color :  colors.gray1,
      fontSize: '0.95rem',
      fontWeight: 400,
      lineHeight: '1.31rem'
    },
    body2:{
      fontWeight: 400,
      lineHeight: '1.7rem'
    },
    button: {
      fontSize: '1rem',
      textTransform: 'none',
      color: `${colors.blue1}`,
    },
    Caption: {
      fontSize: '1rem',
      fontWeight: '500',
      textTransform: 'none',
      color: `${colors.gray3}`,
      paddingTop: '0.2em',
      paddingBottom:'0.2em'
    },
    linkLabel: {
      fontSize: '1.0rem',
      fontWeight: '600',
      textTransform: 'none',
      color: `${colors.gray1}`,
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: '700',
      textTransform: 'none',
      color: '#555555',
    },
    subtitle1:{
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '28px',
      textTransform: 'none',
      color: colors.gray6,
    },
  },
});

theme.props = {
  MuiButton: {
    disableElevation: true,
    disableTouchRipple: true
  },
};

theme.overrides = {
  MuiTextField: {
    root: {
      helperText: {
        color: 'red'
      },
    },
  },
  MuiFormHelperText: {
    root: {
      color: 'red'
    },
  },
  MuiCssBaseline: {
    '@global': {
      body: {
        background:
          'linear-gradient(152.34deg, #FBEDF6 0%, #E8E6F5 40.61%, #DFECF9 76.45%, #F7EEF7 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      },
    },
  },
  MuiAvatar: {
    colorDefault: {
      color: '#fff',
      backgroundColor: '#dae1fb',
    }
  },
  MuiInputLabel: {
    asterisk: {
      color: '#FF0303',
    } 
  },
  MuiSwitch: {
    root: {
      width: '50px',
      height: '35px',
    },
    thumb: {
      width: '16px',
      height: '16px',
      border: '1px solid white',
      boxSizing: 'border-box',
      boxShadow: 'none',
    },
    switchBase: {
      color: colors.gray5,
      '& + $track': {
        backgroundColor: colors.background4,
      },
      '&&$checked': {
        color: colors.blue1,
        '& + $track': {
          backgroundColor: colors.blue1,
          opacity: 1,
        },
      },
    },
  },
  MuiStepButton: {
    completed: {
      color: `${colors.blue1}`,
    },
    active: {
      color: '#fe7a51!important',
    },
  },
  MuiStepIcon: {
    completed: {
      color: '#6361CD!important',
    },
    active: {
      color: '##797D89!important',
    },
    text: {
      fontSize: '0!important',
    },
  },
  MuiStepLabel: {
    label: {
      fontSize: '0.625rem',
    },
    labelContainer: {
      fontSize: '0.625rem',
    },
  },
  MuiFormLabel: {
    root: { 
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '23px',
      fontWeight: '400',
      textTransform: 'capitalize',
      color: '#797D89',
      '&$focused': {  
        color: `${colors.gray3}`
      }
    }
  },
  MuiSelect: {
    select: {
      fontWeight: 400,
      '&:focus': {
        backgroundColor: 'none'
      }
    },
    icon: {
      color: `${colors.blue1}`,
    },
  },
  MuiDialogContent: {
    dividers: {
      borderBottom: 'none',
    }
  },
  MuiDivider: {
    root: {
      backgroundColor: 'rgb(0 0 0 / 7%)'
    },
  },
  MuiFormControlLabel: {
    root: {
      marginRight: 0,
      color: `${colors.gray8}`,
    }
  },
  MuiOutlinedInput: {
    input: {
      padding: '13px',
    },
    root: {
      '& $notchedOutline': {
        borderRadius: 4,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '100%',
      },
      '&$focused $notchedOutline': {
        boxShadow: `${alpha('#6361CD', 0.15)} 0 0 0 0.2rem`,
        borderColor: '#6361CD',
      },
    },  
  },
  MuiLink: {
    root: {
      color: `${colors.blue4}`,
      fontSize: '14px',
      padding: 0,
      cursor: 'pointer',
    },
  },
  MuiBackdrop: {
    root: {
      // zIndex: 1000  // This is breaking in other pages
    }
  }
};

export default theme;