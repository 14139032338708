/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import UserProfileApp from './ApplicantProfile/UserProfileApp';
import { Container } from '@material-ui/core';
import JrHeaderCards from '../../../common/components/HeaderCards/JrHeaderCards';
import { onGetJobDetailsById } from 'redux/actions/employerActions/employerDashboard';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    margin: '1rem 0rem',
  },
}));
const headerCards = true;

// Component shows profile view
const ApplicantProfile = ({ userType, userData }) => {
  const classes = useStyles();
  const userDetails = useSelector(state => get(state, ['userManagement','selectedUser'], {}));
  // eslint-disable-next-line no-unused-vars
  const [candidateSection, setCandidateSection] = useState(true);
  const [jobDetails, setJobDetails] = useState({});
  const dispatch = useDispatch();
  const params = useParams();
  const getJobDetails = () => {
    dispatch(onGetJobDetailsById({ id: params.jobId })).then(async (response) => {
      if (response && response.payload) {
        await setJobDetails(response.payload);
      }
    });
  };
  useEffect(() => {
    getJobDetails();
  }, []);
  return (
    <Container style={{ marginTop: '5rem', marginBottom: '5rem' }}>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.pageTitle}> Recommended Candidate Details </Typography>
            <UserProfileApp userDetails={jobDetails} userType={userType} userData={userData} />
          </Grid>
        </Grid>

        {
          headerCards ? (
            <Grid container style={{ marginTop: '2rem' }}>
              <Grid item xs={12}>
                <JrHeaderCards />
              </Grid>
            </Grid>
          ) : ''
        } 

      </Box>
    </Container>
  );
};

export default ApplicantProfile;