import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Modal, InputLabel, Grid, Typography, Box, FormControl, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { onGetAllJobs } from 'redux/actions/employerActions/employerDashboard';
import { onGetCandidateInfo, onShareCandidateSlot } from 'redux/actions/employerActions/interviewScheduled';
import { onGetJobEmployeeParticipant } from 'redux/actions/employerActions/JobPosting/jobPostingActions';
import { default as MaterialUiSelectField } from '@material-ui/core/Select';
import { updateSlotShared } from 'redux/slices/employerSlices/employerInterviewManagement';

const useStyles = makeStyles(() => ({
  applyConfirmation: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    backgroundColor: 'white',
    boxShadow: 24,
    padding: '1rem'
  },
}));

const CandidateSelection = (
  {
    interviewerSlot,
    setInterviewerSlot,
    candidateSlot,
    setCandidateSlot
  }
) => {
  const classes = useStyles();

  const [isCandidateSelected, setIsCandidateSelected] = useState(false);
  const [jobProfiles, setJobProfiles] = useState();
  const [candidateInfo, setCandidateInfo] = useState([]);
  const [interviewerName, setInterviewerName] = useState([]);

  const dispatch = useDispatch();

  const slotSharedResponse = useSelector(state => state.employerInterviewManagement.slotShared);

  useEffect(() => {
    dispatch(onGetAllJobs({ pageNo: 0, pageSize: 12 })).then(async (response) => {
      if (response && response.payload) {
        const jobProfileList = (response.payload?.content || []).filter(item => item.jobTitle?.title).map(eachProfile => ({
          profileId: eachProfile.id,
          id: eachProfile.jobTitle?.title,
          name: `${eachProfile.jobTitle?.title} - (${eachProfile.jobCode})`,
        }));
        setJobProfiles(jobProfileList);
      }
    });
    if (slotSharedResponse){
      dispatch(updateSlotShared());
    }
  }, []);

  useEffect(() => {
    /**
         * Api call to set candidate list 
         * */
    if (candidateSlot?.jobRole) {
      dispatch(onGetCandidateInfo({ id: candidateSlot.jobRole })).then(async (response) => {
        if (response && response.payload) {
          const candidateInfoList = (response.payload?.content || []).map(eachCandidate => ({
            ...eachCandidate,
            name: `${eachCandidate?.applicant?.firstName} ${eachCandidate?.applicant?.lastName}`,
            id: `${eachCandidate?.applicant?.firstName} ${eachCandidate?.applicant?.lastName}`,
            applicationJobId: `${eachCandidate?.id}`
          }));
          setCandidateInfo(candidateInfoList);
        }
      });
    }
  }, [candidateSlot?.jobRole]);

  useEffect(() => {
    /**
          * Api call to set Interviewer list 
          * */
    if (interviewerSlot?.jobRole) {
      dispatch(onGetJobEmployeeParticipant({ id: interviewerSlot.jobRole, employeeType: 'INTERVIEWER' })).then(async (response) => {
        if (response && response.payload) {
          const interviewerInfoList = (response.payload?.data?.content || []).map(eachInterviewer => ({
            name: `${eachInterviewer?.name}`,
            id: `${eachInterviewer?.id}`,
            interviewerId: `${eachInterviewer?.employeeId}`,
            email: `${eachInterviewer?.email}`,
          }));
          setInterviewerName(interviewerInfoList);
        }
      });
    }
  }, [interviewerSlot?.jobRole]);

  const handleInterviewerSelection = (value) => {
    setInterviewerSlot({ ...interviewerSlot, ...value });
    if (value?.interviewer) {
      setIsCandidateSelected(true);
    }
  };

  const handleCandidateSelection = (value) => {
    setCandidateSlot({ ...candidateSlot, ...value });
  };

  const handleClose = () => {
    setIsCandidateSelected(false);
  };

  const handleShareSlot = () => {
    dispatch(onShareCandidateSlot({
      candidateIds: [
        candidateSlot.candidate
      ],
      jobId: candidateSlot.jobRole
    }));
  };

  const handleCloseSlotShared = () => {
    dispatch(updateSlotShared());
  };

  return (
    <>
      <Grid item xs={12}>
        <Box style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#ffff',
          padding: '1rem',
          marginRight: '2rem',
          marginBottom: '2rem'
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='h5'>Interviewer</Typography>
          </div>
          <Grid item xs={4} style={{ marginLeft: '1rem' }}>
            <div style={{ display: 'flex' }}>
              <InputLabel required>Job Profile</InputLabel>
            </div>
            <FormControl
              variant="outlined"
              name="jobProfile"
              fullWidth>
              <MaterialUiSelectField
                variant="outlined"
                fullWidth
                multiple={false}
                value={interviewerSlot.jobRole}
                placeholder={'Select Job Profile'}
                onChange={(event) => {
                  handleInterviewerSelection({ jobRole: event.target.value });
                  setCandidateSlot({ jobRole: '', candidate: '' });
                }}
              >
                {jobProfiles && jobProfiles.map((code, index) => (
                  <MenuItem automation-testid="dropdownOptionsMenu" key={index} value={code.profileId}>
                    {code['name']}
                  </MenuItem>
                ))}
              </MaterialUiSelectField>
            </FormControl></Grid>
          <Grid item xs={4} style={{ marginLeft: '1rem' }}>
            <div>
              <InputLabel required>Interviewer</InputLabel>
            </div>
            <FormControl
              variant="outlined"
              name="candidate"
              fullWidth>
              <MaterialUiSelectField
                variant="outlined"
                fullWidth
                multiple={false}
                value={interviewerSlot.interviewer}
                disabled={interviewerName.length == 0}
                placeholder={'Select Interviewer'}
                onChange={(event) => {
                  handleInterviewerSelection({ interviewer: event.target.value });
                }}
              >
                {interviewerName.map((code, index) => (
                  <MenuItem automation-testid="dropdownOptionsMenu" key={index} value={code.interviewerId}>
                    {code['name']}
                  </MenuItem>
                ))}
              </MaterialUiSelectField>
            </FormControl>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#ffff',
          padding: '1rem',
          marginRight: '2rem',
          marginBottom: '2rem'
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='h5'>Candidate</Typography>
          </div>
          <Grid item xs={4} style={{ marginLeft: '1rem' }}>
            <div style={{ display: 'flex' }}>
              <InputLabel required>Job Profile</InputLabel>
            </div>
            <FormControl
              variant="outlined"
              name="jobProfile"
              fullWidth>
              <MaterialUiSelectField
                variant="outlined"
                fullWidth
                multiple={false}
                value={candidateSlot.jobRole}
                placeholder={'Select Job Profile'}
                onChange={(event) => {
                  handleCandidateSelection({ jobRole: event.target.value });
                  setInterviewerSlot({ jobRole: '', interviewer: '' });
                }}
              >
                {jobProfiles && jobProfiles.map((code, index) => (
                  <MenuItem automation-testid="dropdownOptionsMenu" key={index} value={code.profileId}>
                    {code['name']}
                  </MenuItem>
                ))}
              </MaterialUiSelectField>
            </FormControl>
          </Grid>
          <Grid item xs={4} style={{ marginLeft: '1rem' }}>
            <div>
              <InputLabel required>Candidate</InputLabel>
            </div>
            <FormControl
              variant="outlined"
              name="candidate"
              fullWidth>
              <MaterialUiSelectField
                variant="outlined"
                fullWidth
                multiple={false}
                value={candidateSlot.candidate}
                disabled={!candidateInfo.length}
                placeholder={'Select Candidate'}
                onChange={(event) => {
                  handleCandidateSelection({ candidate: event.target.value });
                }}
              >
                {candidateInfo.map((code, index) => (
                  <MenuItem automation-testid="dropdownOptionsMenu" key={index} value={code.candidateId}>
                    {code['name']}
                  </MenuItem>
                ))}
              </MaterialUiSelectField>
            </FormControl>
          </Grid>
          <Grid item xs={2} style={{ marginLeft: '1rem',marginTop: '1.5rem' }}>
            <Button
              variant="contained"
              type="submit"
              disabled={!candidateSlot.candidate}
              onClick={handleShareSlot}
              color="secondary">
                Share Slot
            </Button>
          </Grid>
        </Box>
        <Modal
          open={isCandidateSelected}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.applyConfirmation}>
            <Box>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Select Slots
              </Typography>
            </Box>
            <Divider />
            <Typography style={{ mt: 2, marginTop: '20px' }}>
              Please Select the slots, and request for interviewer availability.
            </Typography>
            <Grid container spacing={1} justifyContent={'right'} style={{ marginTop: '20px' }}>
              <Grid item xs={0}>
                <Button variant="contained" color="secondary" onClick={handleClose}>Ok</Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Grid>
      <Grid item xs={12}>
        <Box style={{
          flexDirection: 'row',
          backgroundColor: '#ffff',
          padding: '1rem',
          marginRight: '2rem',
          marginBottom: '2rem'
        }}>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <Grid item xs={2} style={{ marginLeft: '1rem', display: 'flex' }}>
              <Typography variant='h6'>Scheduled</Typography><Box style={{ backgroundColor: '#64C364', height: '1rem', width: '1rem', marginLeft: '1rem', alignSelf: 'center', display: 'flex' }}></Box>
            </Grid>
            <Grid item xs={4} style={{ marginLeft: '1rem', display: 'flex' }}>
              <Typography variant='h6'>Approved by Interviewer</Typography><Box style={{ backgroundColor: '#A8D5FC', height: '1rem', width: '1rem', marginLeft: '1rem', alignSelf: 'center', display: 'flex' }}></Box>
            </Grid>
            <Grid item xs={4} style={{ marginLeft: '1rem', display: 'flex' }}>
              <Typography variant='h6'>Approved by Applicant</Typography><Box style={{ backgroundColor: '#e5d5f7', height: '1rem', width: '1rem', marginLeft: '1rem', alignSelf: 'center', display: 'flex' }}></Box>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', marginTop: '1rem' }}>
            <Grid item xs={4} style={{ marginLeft: '1rem', display: 'flex' }}>
              <Typography variant='h6'>Requested to Interviewer</Typography><Box style={{ backgroundColor: '#6361cd', height: '1rem', width: '1rem', marginLeft: '1rem', alignSelf: 'center', display: 'flex' }}></Box>
            </Grid>
          </Grid>
        </Box>
        <Modal
          open={slotSharedResponse}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.applyConfirmation}>
            <Box>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Success
              </Typography>
            </Box>
            <Divider />
            <Typography style={{ mt: 2, marginTop: '20px' }}>
              Slots Shared successfully with Applicant
            </Typography>
            <Grid container spacing={1} justifyContent={'right'} style={{ marginTop: '20px' }}>
              <Grid item xs={0}>
                <Button variant="contained" color="secondary" onClick={handleCloseSlotShared}>Ok</Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </>
  );
};

export default CandidateSelection;