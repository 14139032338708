import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCandidateInfo, getCustomMySchedule, getInterviewScheduledCalls, getInterviewSlots, shareSlot, shareSlotCandidate } from 'redux/restAPIs/employerAPI/interviewScheduled';
import { EmployerActions } from '../actionTypes';


export const onGetInterviewScheduledCalls = createAsyncThunk(
  EmployerActions.GET_INTERVIEW_SCHEDULED_CALLS,
  async (payload) => {
    const response = await getInterviewScheduledCalls(payload);
    return response.data;
  }
);

export const onCustomMySchedule = createAsyncThunk(
  EmployerActions.GET_CUSTOM_MY_SCHEDULE,
  async (data) => {
    const response = await getCustomMySchedule(data);
    return response.data;
  });

export const onGetCandidateInfo = createAsyncThunk(
  EmployerActions.GET_CANDIDATE_BY_JOB_ID,
  async (payload) => {
    const response = await getCandidateInfo(payload);
    return response.data;
  }
);

export const onGetInterviewSlots = createAsyncThunk(
  EmployerActions.GET_INTERVIEW_SLOTS,
  async (payload) => {
    const response = await getInterviewSlots(payload);
    return response.data;
  }
);

export const onShareSlot = createAsyncThunk(
  EmployerActions.SHARE_INTERVIEW_SLOTS,
  async (payload) => {
    const response = await shareSlot(payload);
    return response.data;
  }
);

export const onShareCandidateSlot = createAsyncThunk(
  EmployerActions.SHARE_INTERVIEW_SLOTS_CANDIDATE,
  async (payload) => {
    const response = await shareSlotCandidate(payload);
    return response.data;
  }
);