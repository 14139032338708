
export const PersonalDetailsFormInitialState = {
  firstName: '',
  lastName: '',
  contactNumber: '',
  email: '',
  address: '',
  zipcode: '',
  city: '',
  stateResiding: '',
  country: '',
  experienceCount: '',
  experiencePeriod: '',
  salaryMin: '',
  salaryMax: '',
  headline: '',
  summary: ''
};

export const EducationalDetailsInitialState = {
  university: '',
  field: '',
  startYear: '',
  endYear: ''
};

export const ProfessionalDetailInitialState = {
  title: '',
  company: '',
  location: '',
  description: '',
  startDate: '',
  endDate: '',
};

export const ProjectDetailsInitialState = {
  projectTitle: '',
  role: '',
  startDate: '',
  endDate:  '',
  details: '',
  responsibilities: ''

};

export const CertificationDetailsInitialState = {
  name: '',
  issuer: '',
  issueDate: '',
  credentialId: '',
};

export const SocialContributionFormInitialState = {
  organization: '',
  role: '',
  startDate: '',
  endDate: '',
  cause: '',
  description: ''

};

export const HonorAchievementsFormInitialState = {
  title: '',
  issuer: '',
  issueDate: '',
  description: ''
};

export const ResearchInnovationFormInitialState = {
  title: '',
  issueDate: '',
  description: ''
};

export const SkillDetailsFormInitialState = {
  skillField: '',
  mentorShipField: ''
};