import JobContainer from 'view/common/components/JobCard/JobContainer';
import DPComingSoon from 'view/common/DPComingSoon';
import CalendarLanding from 'view/common/components/CalendarLanding/CalendarLanding';
import Home from '../pages/Home/Home';
import MyJobs from '../pages/MyJobs/MyJobs';
import Mentors from '../pages/Mentors';
import Help from '../pages/Help/Help';
import News from '../pages/News/News';
// import Schedule from '../../../../src/view/employer/pages/JobPosting/AllJobsPosting/Schedule';
// import CalendarLanding from 'view/employer/pages/JobPosting/AllJobsPosting/CalendarLanding';
import Learning from '../pages/Learning/Learning';
import VideoCall from '../pages/VideoCall/VideoCall';

export const RoutePaths = {
  jobDetails: '/jobDetails',
  home: '/applicant',
  news: '/applicant/news',
  learning:'/applicant/learning',
  calendar:'/applicant/calendar',
  myjobs:'/applicant/myjobs',
  mentors:'/applicant/mentors',
  resume: '/applicant/resume',
  editProfile: '/applicant/edit-profile',
  buildProfile: '/build-profile',
  help: 'applicant/help',
};

export const routePaths = [
  { name: 'Home', link: RoutePaths.home, activeIndex: 0, component: Home },
  { name: 'News', link: RoutePaths.news, activeIndex: 1, component: News }, // There is already a compnenet News. Use that later
  {
    name: 'Learning',
    link: RoutePaths.learning,
    activeIndex: 2,
    component: Learning,
  },
  {
    name: 'Calender',
    link: RoutePaths.calendar,
    activeIndex: 3,
    component: CalendarLanding,
  },
  {
    name: 'My Jobs',
    link: RoutePaths.myjobs,
    activeIndex: 4,
    component: MyJobs,
  },
  {
    name: 'Mentors',
    link: RoutePaths.mentors,
    activeIndex: 5,
    component: Mentors,
  },
  {
    name: 'Resume',
    link: RoutePaths.resume,
    activeIndex: 6,
    component: DPComingSoon,
  },
  {
    name: 'JobDetails',
    link: `${RoutePaths.jobDetails}/:jobId`,
    component: JobContainer,
    visible: false
  },
  {
    name: 'help',
    link: `${RoutePaths.help}`,
    component: Help
  },
  {
    name: 'VideoCall',
    link: `${RoutePaths.calendar}/:interviewId`,
    component: VideoCall,
    visible: false
  }
];
