export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const API_KEY = process.env.REACT_APP_API_KEY;

export const USER_LOGOUT = 'USER_LOGOUT';

export const UPLOAD_RESUME_SUCCESS = 'UPLOAD_RESUME_SUCCESS';

// company locations configuration reducer constants
export const ADD_COMPANY_LOCATIONS_SUCCESS = 'ADD_COMPANY_LOCATIONS_SUCCESS';
export const GET_ALL_COMPANY_LOCATIONS_SUCCESS = 'GET_ALL_COMPANY_LOCATIONS_SUCCESS';
export const EDIT_COMPANY_LOCATIONS_SUCCESS = 'EDIT_COMPANY_LOCATIONS_SUCCESS';
export const DELETE_COMPANY_LOCATIONS_SUCCESS = 'DELETE_COMPANY_LOCATIONS_SUCCESS';


// job title configuration reducer constants
export const ADD_JOB_TITLE_SUCCESS = 'ADD_JOB_TITLE_SUCCESS';
export const GET_ALL_JOB_TITLE_SUCCESS = 'GET_ALL_JOB_TITLE_SUCCESS';
export const GET_AI_JOB_DESCRIPTION = 'GET_AI_JOB_DESCRIPTION';
export const EDIT_JOB_TITLE_SUCCESS = 'EDIT_JOB_TITLE_SUCCESS';
export const DELETE_JOB_TITLE_SUCCESS = 'DELETE_JOB_TITLE_SUCCESS';
// company information constants
export const GET_COMAPNY_INFORMATION_SUCCESS = 'GET_COMAPNY_INFORMATION_SUCCESS';

// static questions reducer constants
export const ADD_QUESTIONS_SUCCESS = 'ADD_QUESTIONS_SUCCESS';
export const GET_ALL_QUESTIONS_SUCCESS = 'GET_ALL_QUESTIONS_SUCCESS';
export const EDIT_QUESTIONS_SUCCESS = 'EDIT_QUESTIONS_SUCCESS';
export const DELETE_QUESTIONS_SUCCESS = 'DELETE_QUESTIONS_SUCCESS';

// interview questions
export const ADD_INTERVIEW_QUESTIONS_SUCCESS = 'ADD_INTERVIEW_QUESTIONS_SUCCESS';
export const EDIT_INTERVIEW_QUESTIONS_SUCCESS = 'EDIT_INTERVIEW_QUESTIONS_SUCCESS';
export const GET_ALL_INTERVIEW_QUESTIONS_SUCCESS = 'GET_ALL_INTERVIEW_QUESTIONS_SUCCESS';
export const DELETE_INTERVIEW_QUESTIONS_SUCCESS = 'DELETE_INTERVIEW_QUESTIONS_SUCCESS';


// job posting 
export const ADD_JOB_POST_SUCCESS = 'ADD_JOB_POST_SUCCESS';
export const SET_JOB_EMPLOYEE_PARTICIPANT = 'SET_JOB_EMPLOYEE_PARTICIPANT';
export const SET_JOB_STATIC_QUESTIONS = 'SET_JOB_STATIC_QUESTIONS';
export const EDIT_JOB_POST_SUCCESS = 'EDIT_JOB_POST_SUCCESS';
export const GET_ALL_JOBS_SUCCESS = 'GET_ALL_JOBS_SUCCESS';
