import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Rating from '@mui/material/Rating';
import { makeStyles } from '@material-ui/core';

import SentimentVeryDissatisfiedIcon from 'asset/icons/rating1.svg';
import SentimentDissatisfiedIcon from 'asset/icons/rating2.svg';
import SentimentSatisfiedIcon from 'asset/icons/rating3.svg';
import SentimentSatisfiedAltIcon from 'asset/icons/rating4.svg';
import SentimentVerySatisfiedIcon from 'asset/icons/rating5.svg';

const useStyle = makeStyles(() => ({
  iconsContainer: {
    paddingRight: '0.5em'
  },
  selectedOne: {
    filter: 'drop-shadow(2px 4px 6px #E7B503)'
  },
  selectedTwo: {
    filter: 'drop-shadow(2px 4px 6px #E04A89)'
  },
  selectedThree: {
    filter: 'drop-shadow(2px 4px 6px #DE8318)'
  },
  selectedFour: {
    filter: 'drop-shadow(2px 4px 6px #44B8B8)'
  },
  selectedFive: {
    filter: 'drop-shadow(2px 4px 6px #23AF31)'
  },
  noClick: {
    pointerEvents: 'none'
  }
}));

const getCustomIcons = (classes, selectedRating) => {
  const iconData = {
    1: {
      icon: <img
        className={`${classes.iconsContainer} ${selectedRating === 1 ? classes.selectedOne : ''}`}
        src={SentimentVeryDissatisfiedIcon} />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <img
        className={`${classes.iconsContainer} ${selectedRating === 2 ? classes.selectedTwo : ''}`}
        src={SentimentDissatisfiedIcon} />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <img
        className={`${classes.iconsContainer} ${selectedRating === 3 ? classes.selectedThree : ''}`}
        src={SentimentSatisfiedIcon} />,
      label: 'Neutral',
    },
    4: {
      icon: <img
        className={`${classes.iconsContainer} ${selectedRating === 4 ? classes.selectedFour : ''}`}
        src={SentimentSatisfiedAltIcon} />,
      label: 'Satisfied',
    },
    5: {
      icon: <img
        className={`${classes.iconsContainer} ${selectedRating === 5 ? classes.selectedFive : ''}`}
        src={SentimentVerySatisfiedIcon} />,
      label: 'Very Satisfied',
    },
  };
  return iconData;
};

function IconContainer(props) {
  const classes = useStyle();
  const { value, selectedRating, ...other } = props;
  return <span {...other}>{getCustomIcons(classes, selectedRating)[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function DPQuestionRating({
  disabled = false,
  ratingValue = null
}) {
  const [selectedRating, setSelectedRating] = useState(ratingValue);
  const classes = useStyle();
  return (
    <Rating
      name="highlight-selected-only"
      className={disabled ? classes.noClick : ''}
      value={selectedRating}
      IconContainerComponent={iconProps => <IconContainer selectedRating={selectedRating} {...iconProps} />}
      highlightSelectedOnly
      onChange={(event, newValue) => {
        if (!disabled) {
          setSelectedRating(newValue);
        }
      }}
    />
  );
}