import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Chip,
  Typography,
  Box,
  Link,
  Modal,
  Divider,
  Menu,
  MenuItem,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onApplyJob, onGetJobDetails, onReferJob, onSaveJob } from 'redux/actions/applicantDashboard';
import { Alert } from '@mui/material';
import { floor, get, isEmpty } from 'lodash';
// import CircleIcon from '@mui/icons-material/Circle';
import clsx from 'clsx';
import PinterestIcon from 'asset/icons/pinterest.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { fetchLovByKey } from 'redux/actions/lovActions';
import { getRelativeDate } from 'view/common/utils/formUtils';
import moment from 'moment';
import { onGetAllJobLocation } from 'redux/actions/employerActions/Configurations/jobActions';
import ReferJob from 'view/applicant/forms/ReferJob';

const useStyle = makeStyles(theme => ({
  expiresOn: {
    marginTop: '-2rem',
    marginRight: '1.5rem',
    fontSize: '12px',
  },
  jobCard: {
    marginBottom: '1.3rem',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    padding: '0.5rem 0',
  },
  percent: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'right',
    color: '#FF5B15',
  },
  referBtn: {
    '&.MuiButton-root': {
      marginLeft: '1rem'
    },
  },
  gridMargins: {
    marginBottom: '2.5rem'
  },
  gridMargins1: {
    marginTop: '2.5rem'
  },
  titleGrid: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'grid',
    },
  },
  sectionMargins: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '0.8rem',
    },
  },
  wrapText: {
    overflowWrap: 'anywhere'
  },
  company: {
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 500,
    color: '#797D89',
    margin: '5px 0px',
    fontSize: '13px',
    background: '#E1E1E1',
    borderRadius: '1px',
    padding: 2,
  },
  chip: {
    margin: '0.4rem 0.6rem 0.4rem 0rem',
    color: '#24283C',
    background: '#E0DFFF',
  },
  pageTitle: {
    ...theme.typography.title,
  },
  pageTitleContainer: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  jobID: {
    border: '1px solid #8692A6',
    borderRadius: '4px',
    padding: '1px 3px',
    height: 'fit-content',
    textOverflow: 'ellipsis',
    widmaxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: '5px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      marginUserCard: '10px 0',
    },
  },
  circleIcon: {
    color: '#797D89',
    marginLeft: '0.5rem',
    marginRight: '0.5rem'
  },
  applyConfirmation: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: 4
  },
  font: {
    fontSize: '18px',
  },
  positionsData: {
    textDecoration: 'underline',
    color: '#5755BE',
    cursor: 'pointer'
  },
  qualifyGrid: {
    marginTop: '1rem',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  mainGrid: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  circularProgress: {
    margin: '0% auto', display: 'flex', zIndex: 1
  },
  textLineBreak: {
    whiteSpace: 'pre-wrap'
  }
}));

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '13px', color: '#797D89' }}
  >
    •
  </Box>
);

const JobDetails = ({ jobIdInterviewer, isInterviewer }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const params = useParams();
  const { state } = useLocation();
  const addJobPostingData = useSelector((state) => {
    // console.log('state => ', state);
    return get(state, ['addJobPosting']);
  });
  // eslint-disable-next-line no-console
  console.log('addJobPostingData ==> ', addJobPostingData);
  const { applied, isApplicant: isApplicantStatus } = state || {};
  const [isApplied, setIsApplied] = useState(applied);
  const [isApplicant, setIsApplicant] = useState(isApplicantStatus);
  const [jobDetailsData, setJobDetailsData] = useState({});
  const [open, setOpen] = useState(false);
  const [qualificationName, setQualificationName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [jobSavedRefered, setJobSavedRefered] = useState('');
  const [showReferJob, setShowReferJob] = useState(false);
  const jobId = isInterviewer ? jobIdInterviewer : params.jobId;
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const onActivityBtnClick = (tabId) => {
    navigate(`/employer/activity/${jobId}`, {
      state: {
        currentTab: 'Applied',
        tabId
      }
    });
  };

  useEffect(() => {
    jobId && dispatch(onGetJobDetails({
      id: jobId
    })).then((res) => {
      setJobDetailsData({
        ...res.payload,
        state: 'apply'
      });
      getQualifications(res.payload && res.payload.qualification);
      getJobLocation(res.payload && res.payload.jobLocation);
    });
  }, [jobId]);

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const getQualifications = async (qualificationID) => {
    const { payload } = await dispatch(fetchLovByKey('qualification'));
    if (payload && payload.data) {
      const qualificationObj = payload.data.items.find(qualification => qualification.id === qualificationID);
      if (qualificationObj && qualificationObj.name) {
        setQualificationName(qualificationObj.name);
      }
    }
  };

  const getJobLocation = (jobLocation) => {
    // setIsLoading(true);
    dispatch(onGetAllJobLocation({})).then((response) => {
      if (response && response.payload) {
        const list = response.payload.filter((location) => {
          // console.log('location => ', location, jobLocation);
          // return { ...location, name: `${location.city}, ${location.state}` };
          return location.id === jobLocation;
        });
        // eslint-disable-next-line no-console
        console.log('list', list);
        // setJobLocationList(list);
      }
      // setIsLoading(false);
    });
  };

  const onApplyClick = () => {
    if (get(jobDetailsData, 'state', 'Apply') !== 'applied' && !isApplied) {
      setOpen(true);
    }
  };

  const applyForJob = async () => {
    const resp = await dispatch(onApplyJob({
      jobId: get(params, 'jobDetailId', '')
    }));
    const state = get(resp, ['payload', 'state'], '');
    if (state === 'APPLIED') {
      setIsApplied(true);
      setJobSavedRefered('');
    }
    setOpen(false);
  };

  useEffect(() => {
    setIsApplied(applied);
    setJobSavedRefered('');
  }, [applied]);

  useEffect(() => {
    setIsApplicant(isApplicantStatus);
  }, [isApplicantStatus]);

  if (isEmpty(jobDetailsData)) {
    return (<div>
      <CircularProgress color="secondary" className={classes.circularProgress} />
    </div>);
  }

  const saveJob = () => {
    dispatch(onSaveJob({
      jobId: get(params, 'jobId', ''),
      save: true
    })).then(() => {
      setJobSavedRefered('Job Saved Successfully !');
    });
  };

  const referJob = () => setShowReferJob(!showReferJob);
  
  const processReferJob = (email, name) => {
    dispatch(onReferJob({
      jobId: get(params, 'jobId', ''),
      email,
      name
    })).then(() => {
      setJobSavedRefered('Job Referred Successfully !');
    });
  };

  return (
    <Box
      sx={{
        marginTop: '0.5rem',
      }}
    >
      <Grid container spacing={0} className={classes.pageTitleContainer} alignItems="baseline">
        {isApplicant ? (
          <Grid item xs={12} style={{ marginBottom: '1rem' }}>
            {(get(jobDetailsData, 'state', 'Apply') === 'applied' || isApplied) &&
              <Alert severity="success" >
                <div style={{ display: 'flex' }}>Job Applied Successfully !</div>
              </Alert>
            }
            {jobSavedRefered.length > 0 && !isApplied &&
            <Alert severity="success" >
              <div style={{ display: 'flex' }}>{jobSavedRefered}</div>
            </Alert>}
          </Grid>
        ) : (
          null
        )}
        <Grid item xs={12}>
          <Typography className={classes.pageTitle}>Job Details</Typography>
        </Grid>
      </Grid>

      <Card elevation={0} className={classes.jobCard}>
        <CardContent style={{ padding: '10px 15px' }}>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item md={8} xs={12}>
              <Box style={{ display: 'flex' }}>
                <Box>
                  <img
                    src={PinterestIcon}
                    alt="pinterest Icon"
                    style={{
                      width: '50px',
                      marginRight: '1rem',
                      marginBottom: '19px',
                    }}
                  />
                </Box>
                <Box>
                  <Box className={classes.titleGrid}>
                    <Typography variant="h5" style={{ width: '210px', overflowWrap: 'break-word' }}>
                      {get(jobDetailsData, ['companyJobTitle', 'title'], '')}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.jobID}>Job ID :{get(jobDetailsData, ['jobCode'], '')}</Typography>
                  </Box>

                  <Box display={'flex'} alignItems="center">
                    <Typography variant="subtitle2" style={{ fontSize: '16px', color: '#797D89', fontWeight: 500 }}>
                      {get(jobDetailsData, ['companyName'], '')}
                    </Typography>
                    {!isInterviewer &&
                      (
                        <>
                          {/* <CircleIcon className={classes.circleIcon} sx={{ fontSize: 10 }} /> */}
                          {/* <Link href="#" underline="none">Reviews12</Link> */}
                        </>
                      )}
                  </Box>
                  {!isInterviewer &&
                    (
                      <Box>
                        <Typography variant="subtitle2" style={{ fontSize: '14px' }}>
                          {/* Positions -{get(jobDetailsData, ['openPositions'], '')} */}
                        </Typography>
                      </Box>
                    )}
                </Box>
              </Box>
            </Grid>
            {!isInterviewer &&
              <Grid item container xs={12} md={4} style={{ justifyContent: 'end', padding: '8px 0' }}>
                {isApplicant ? (
                  <Grid item md={6} xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                    <Typography variant="subtitle1" style={{ fontSize: '12px' }}>
                      Compatibility
                    </Typography>
                    <Typography className={classes.percent}>{floor(get(jobDetailsData, ['compatibility'], ''), 2)}%</Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid item style={{ display: 'flex', alignItems: 'baseline' }}>
                      <Link
                        color="secondary"
                        onClick={onActivityBtnClick}
                        style={{ paddingRight: '0.5rem' }}
                      >
                        Activity
                      </Link>
                      <Typography>
                        {getRelativeDate(get(jobDetailsData, ['postedOn'], ''))} 
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item>
                  <IconButton
                    id="basic-button"
                    style={{ paddingTop: 0 }}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    fontSize="small"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    style={{ marginTop: '40px', marginLeft: '-100px' }}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleMenuClose}>Edit</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Assign Job Post</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
                  </Menu>
                </Grid>
                <Typography className={classes.expiresOn} variant="subtitle1">
                  <em automation-testID="expiresDraftJob">Expires: {moment(get(jobDetailsData, ['expiryDate'], '')).format('ll')}</em> 
                </Typography>
              </Grid>
            }
          </Grid>
          {isApplicant || isInterviewer ? (null) :
            (
              <Grid container xs={12} style={{ margin: '20px 0' }}>
                <Typography variant="h3" className={classes.font}>
                  {get(jobDetailsData, ['openPositions'], '')} Positions {bull}
                </Typography>
                <Typography variant="h3" className={clsx(classes.positionsData, classes.font)} onClick={() => onActivityBtnClick('applied')}>
                  {get(jobDetailsData, ['applied'], 0)} Applied {bull}
                </Typography>
                <Typography variant="h3" className={clsx(classes.positionsData, classes.font)} onClick={() => onActivityBtnClick('shortlisted')}>
                  {get(jobDetailsData, ['shortlisted'], 0)} Shortlisted
                </Typography>
              </Grid>
            )}


          <Grid container xs={12} className={classes.gridMargins1}>
            <Grid item xs={12} md={3} className={classes.sectionMargins}>
              <Typography variant="subtitle1">
                Experience
              </Typography>
              <Typography variant="subtitle2">
                {get(jobDetailsData, ['experienceMin'], '')} - {get(jobDetailsData, ['experienceMax'], '')} years
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.sectionMargins}>
              <Typography variant="subtitle1">
                Salary
              </Typography>
              <Typography variant="subtitle2" >
                {get(jobDetailsData, ['salaryMin'], '')} - {get(jobDetailsData, ['salaryMax'], '')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.sectionMargins}>
              <Typography variant="subtitle1">
                Location
              </Typography>
              <Typography variant="subtitle2" >
                {get(jobDetailsData, ['employerLocation', 'city'], '')},  {get(jobDetailsData, ['employerLocation', 'state'], '')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.sectionMargins}>
              <Typography variant="subtitle1">
                Working Hours
              </Typography>
              <Typography variant="subtitle2" >
                {get(jobDetailsData, ['workTimingsFrom'], '')} - {get(jobDetailsData, ['workTimingsTo'], '')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container xs={12} className={classes.qualifyGrid} >
            <Grid item xs={12} md={3} className={classes.sectionMargins}>
              <Typography variant="subtitle1">
                Qualification
              </Typography>
              <Typography variant="subtitle2" >
                {qualificationName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.sectionMargins}>
              <Typography variant="subtitle1">
                Specific Qualification
              </Typography>
              <Typography variant="subtitle2" >
                {get(jobDetailsData, ['qualificationSpecific'], '')}
              </Typography>
            </Grid>
            {isApplicant ? (
              <Grid item xs={12} md={3} className={classes.sectionMargins}>
                <Typography variant="subtitle1">
                  Employment
                </Typography>
                <Typography variant="subtitle2" >
                  {get(jobDetailsData, ['jobType'], '')}
                </Typography>
              </Grid>
            ) : (
              null
            )}
            <Grid item xs={12} md={3} className={classes.sectionMargins}>
              <Typography variant="subtitle1">
                Senority level
              </Typography>
              <Typography variant="subtitle2" >
                {get(jobDetailsData, ['seniorityLevel'], '')}
              </Typography>
            </Grid>
          </Grid>

          {isApplicant ? (
            <Box style={{ margin: '1rem 0' }}>
              <Button variant="contained" size="small" color="secondary" onClick={onApplyClick}>
                {(get(jobDetailsData, 'state', 'Apply') === 'applied' || isApplied) ? 'Applied' : 'Apply'}
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.referBtn}
                onClick={saveJob}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.referBtn}
                onClick={referJob}
              >
                Refer Jobs
              </Button>
            </Box>
          ) : (
            null
          )
          }

          <Grid container xs={12}>
            <Grid item xs={12} className={classes.gridMargins}>
              <Typography variant="subtitle1">
                Job Description
              </Typography>
              <Typography variant="body1" className={classes.gridMargins}>
                {get(jobDetailsData, ['jobDescription'], '')}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.gridMargins}>
              <Typography variant="subtitle1">
                Preferred Skills
              </Typography>
              {R.map(data => (
                <Chip
                  label={data.skill}
                  color="secondary"
                  className={classes.chip}
                />
              ))(get(jobDetailsData, ['preferredSkills'], ''))}
            </Grid>

            <Grid item xs={12} className={classes.gridMargins}>
              <Typography variant="subtitle1">
                Required Skills
              </Typography>
              {R.map(data => (
                <Chip
                  label={data.skill}
                  color="secondary"
                  className={classes.chip}
                />
              ))(get(jobDetailsData, ['requiredSkills'], ''))}
            </Grid>

            <Grid item xs={12} className={classes.gridMargins}>
              <Typography variant="subtitle1">
                Roles & Responsibilities
              </Typography>
              <Typography variant="body1" className={[classes.gridMargins, classes.textLineBreak]}>
                {get(jobDetailsData, ['rolesAndResponsibilities'], '')}
              </Typography>
              {/* 
                keeping it for future
              <RichTextEditor
                readOnly={true}
                label={'Roles and Responsiblities'}
                name="rolesAndResponsibilities"
                width={'100%'}
                height={'150px'}
                customValue={get(jobDetailsData, ['rolesAndResponsibilities'], '')}
                isFormik={false}
              /> */}
            </Grid>

            <Grid item xs={12} className={classes.gridMargins}>
              <Typography variant="subtitle1">
                Perks Benefits & culture
              </Typography>
              {R.map(data => (
                <Typography variant="body1" className={classes.wrapText}>
                  <li>{data.offering}</li>
                </Typography>
              ))(get(jobDetailsData, ['perksAndBenefits'], ''))}
            </Grid>

            <Grid item xs={12} className={classes.gridMargins}>
              <Typography variant="subtitle1">
                Impact of Position
              </Typography>
              <Typography variant="body1" className={[classes.gridMargins, classes.textLineBreak]}>
                {get(jobDetailsData, ['impactOfPosition'], '')}
              </Typography>
              {/* 
                keeping it for future
              <RichTextEditor
                readOnly={true}
                label={'Impact of Position'}
                name="impactOfPosition"
                width={'100%'}
                height={'150px'}
                customValue={get(jobDetailsData, ['impactOfPosition'],)}
                isFormik={false}
              /> */}
            </Grid>

            <Grid item xs={12} className={classes.gridMargins}>
              <Typography variant="subtitle1">
                Company Details
              </Typography>
              <Typography variant="body1" className={classes.wrapText}>
                {get(jobDetailsData, ['companyDetails'], '')}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.gridMargins}>
              <Typography variant="subtitle1">
                Website
              </Typography>
              <Typography
                variant="body1"
              >
                {get(jobDetailsData, ['companyWebsite'], '')}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.gridMargins}>
              <Typography variant="subtitle1">
                Address
              </Typography>
              <Typography
                variant="body1"
              >
                {get(jobDetailsData, ['employerLocation', 'companyAddress'], '')}, {get(jobDetailsData, ['employerLocation', 'city'], '')}, {get(jobDetailsData, ['employerLocation', 'state'], '')}, {get(jobDetailsData, ['employerLocation', 'country'], '')}, {get(jobDetailsData, ['employerLocation', 'zipcode'], '')}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.gridMargins}>
              <Typography variant="subtitle1">
                Values of Company
              </Typography>
              <Typography variant="body1" className={classes.wrapText}>
                {get(jobDetailsData, ['companyValues'], '')}
              </Typography>
            </Grid>
          </Grid>

          {isApplicant ? (
            <Box>
              <Button variant="contained" size="small" color="secondary" onClick={onApplyClick}>
                {(get(jobDetailsData, 'state', 'Apply') === 'applied' || isApplied) ? 'Applied' : 'Apply'}
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.referBtn}
                onClick={saveJob}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.referBtn}
                onClick={referJob}
              >
                Refer Jobs
              </Button>
            </Box>
          ) : (
            null
          )}
        </CardContent>
      </Card>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.applyConfirmation}>
          <Box>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Apply For Job
            </Typography>
          </Box>
          <Divider />
          <Typography style={{ mt: 2, marginTop: '20px' }}>
            Do you want to apply for this Job?
          </Typography>
          <Grid container spacing={1} justifyContent={'right'} style={{ marginTop: '20px' }}>
            <Grid item xs={0}>
              <Button variant="contained" color="secondary" onClick={applyForJob}>Apply</Button>
            </Grid>
            <Grid item xs={1}>
              <Button variant="outlined" onClick={handleClose}>Cancel</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={showReferJob}
        onClose={referJob}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <ReferJob
            processReferJob={processReferJob}
            onClose={referJob} />
        </Box>
      </Modal>
    </Box >
  );
};

export default JobDetails;