/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { getEmpUsers, getInterviewers, onAddEmpUser, getEmpUserById, fetchJobPostsByUser, onUserActiveStatusChange } from 'redux/actions/employerActions/userManagementActions';
import { get } from 'lodash';

const initialState = {
  users: {
    content: []
  },
  interviewers: {},
  pageOptions: {
    pageSize: 5,
    pageNo: 1,
    search: ''
  },
  byUserId: {},
  currentTab: 'user',
  selectedUser: {},
};

const userManagement = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    updatePageSize: (state, action) => {
      state.pageOptions.pageSize = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    }
  },
  extraReducers: {
    [onAddEmpUser.pending]: (state) => {
      state.userUpdateStatus = 'loading';
    },
    [onAddEmpUser.fulfilled]: (state, action) => {
      state.userUpdateStatus = 'succeeded';
      if (get(action, ['meta', 'arg', 'isEdit'], false)) {
        state.selectedUser = action.payload;
      }
    },
    [onAddEmpUser.rejected]: (state, action) => {
      state.userUpdateStatus = 'failed';
    },
    [getInterviewers.pending]: (state, action) => {
      state.status = 'loading';
      const pageOptions = get(action, ['meta', 'arg'], {
        pageSize: 5,
        pageNo: 1,
        search: ''
      });
      state.pageOptions = pageOptions;
    },
    [getInterviewers.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.interviewers = action.payload;
    },
    [getInterviewers.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [getEmpUsers.pending]: (state, action) => {
      state.status = 'loading';
      const pageOptions = get(action, ['meta', 'arg'], {
        pageSize: 5,
        pageNo: 1,
        search: ''
      });
      state.pageOptions = pageOptions;
    },
    [getEmpUsers.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.users = action.payload;
    },
    [getEmpUsers.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [getEmpUserById.pending]: (state) => {
      state.status = 'loading';
    },
    [getEmpUserById.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [getEmpUserById.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [fetchJobPostsByUser.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchJobPostsByUser.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [fetchJobPostsByUser.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [onUserActiveStatusChange.pending]: (state) => {
      state.status = 'loading';
    },
    [onUserActiveStatusChange.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onUserActiveStatusChange.rejected]: (state, action) => {
      state.status = 'failed';
    },
    onUserActiveStatusChange
  },
});

export const { updatePageSize, setSelectedTab, setSelectedUser } =
  userManagement.actions;

export default userManagement.reducer;
