import { endPoints } from 'constants/applicantEndPoints';
import { callApi } from 'middleware/api';

export const onUserPersonalDetails = (data) => {
  const {
    payload,
    method
  } = data;
  return callApi(payload, method, endPoints.userPersonalDetails, true);
};

export const onUserExperienceDetails = (data) => {
  const {
    payload,
    method
  } = data;
  return callApi(payload, method, endPoints.userExperience, true);
};

export const onEducationInfo = (data) => {
  const {
    payload,
    method
  } = data;
  return callApi(payload, method, endPoints.educationInfo, true);
};