import { Button, Card, CardContent, Grid, Avatar, Typography, Box, ButtonGroup } from '@material-ui/core';
import React from 'react';
import activity from 'asset/icons/activity.svg';
import jobdetails from 'asset/icons/jobdetails.svg';
import jobreport from 'asset/icons/jobreport.svg';
import clock from 'asset/icons/clock.svg';
import expandmore from 'asset/icons/expandmore.svg';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { getRelativeDate } from 'view/common/utils/formUtils';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';

const useStyle = makeStyles(theme => ({
  jobCard: {
    marginBottom: '1.3rem',
    height: '288px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    padding: '0',
    [theme.breakpoints.down('sm')]:{
      height: '430px'
    }
  },
  company: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '15px',
    lineHeight: '15px',
    // textTransform: 'uppercase',
    color: '#797D89',
    marginBottom: '20px',
    fontSize: '13px',
    background: '#F0F0F0',
    borderRadius: '1px',
    padding: 0,
    [theme.breakpoints.down('sm')]:{
      height: '20px'
    }
  },
  iconBtn: {
    padding: '7px'
  },
  iconBtn1: {
    padding: '0 1rem',
    '&.MuiButtonGroup-groupedTextHorizontal:not(:last-child)': {
      borderRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  btnGroup: {
    padding: '0.5rem 0',
    width: '100%',
    justifyContent: 'space-around',
    background: 'linear-gradient(90deg, #F9F9FC 0%, #FBF6FA 100%)',
    borderRadius: '0px 0px 4px 4px',
  },
  font: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500
  },
  positionsData: {
    textDecoration: 'underline',
    color: '#5755BE',
    cursor: 'pointer'
  },
  recommend: {
    margin: '0.5rem'
  },
  recommendSpan: {
    fontSize: '1.3rem', marginLeft: '2px', lineHeight: 0
  },
  expiryMain: {
    textAlign: 'end',
  },
  main: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  location: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '24px',
    marginBottom: '10px'
  },
  role: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '30px'
  },
  btnContainer: {
    height: '42px',
    [theme.breakpoints.down('sm')]:{
      height: '62px'
    }
  },
  mainContainer: {
    height: '240px',
    padding: '20px 20px 0',
    lineHeight: 0,
    [theme.breakpoints.down('sm')]:{
      height: '360px'
    }
  },
  recom_title: {
    color: '#797D89',
    fontWeight: 400,
    lineHeight: '18px',
    fontSize: '12px'
  },
  avatar: {
    display: 'flex',
    lineHeight: '20px',
    height: '65px',
    [theme.breakpoints.down('sm')]:{
      height: '75px'
    }
  },
  jobID: {
    display: 'inline',
    alignItems: 'center',
    marginLeft: '20px',
    border: '1px solid #8692A6',
    borderRadius: '4px',
    padding: '2px 3px',
    height: 'fit-content',
    textOverflow: 'ellipsis',
    widmaxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      marginUserCard: '10px 0',
    },
  },
}));

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '13px', color: '#797D89', padding: 0 }}
  >
    •
  </Box>
);

const JobCardEmployer = (props) => {
  const classes = useStyle();
  const { data } = props;
  const navigate = useNavigate();

  const onActivityBtnClick = (tabId) => {
    navigate(`/employer/activity/${data.id}`,{
      state: {
        currentTab: 'Applied',
        tabId
      }
    });
  };
  const onJobReportBtnClick = () => {
    navigate(`/employer/jobposting/jobreport/${data.id}`);
  };

  const onJobDetailsBtnClick = () => {
    navigate(`/employer/jobDetails/${data.id}`, {
      state: {
        isApplicant: false
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          marginTop: '0.5rem',
          marginBottom: '20px'
        }} >
        <Card elevation={0} className={classes.jobCard}>
          <CardContent className={classes.mainContainer}>
            <Grid container className={classes.main}>
              <Grid item xs={12} md={8} >
                <Typography variant="h5" className={classes.role}>
                  {get(data, ['jobTitle', 'title'], '')}
                  <Typography variant="subtitle1" className={classes.jobID}>Job ID :{get(data, ['jobCode'], '')}</Typography>
                </Typography>
                <Typography variant="h3" className={classes.location} >
                  {get(data, ['location', 'city'], '')}{get(data, ['location', 'state'], '')? ',': null} {get(data, ['location', 'state'], '')}
                </Typography>
                <Typography className={classes.company} >
                  Posted By:  {get(data, ['createdBy', 'name'], '')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.expiryMain}>
                <Typography variant='h6' style={{ display: 'inline-flex', alignItems: 'flex-start' }}>
                  <Typography variant="subtitle1" style={{ fontWeight: 300 }}>
                    <Typography style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <img src={clock} alt='posted time Icon' style={{ marginRight: '7.5px' }} />
                      {getRelativeDate(get(data, ['postedOn'], ''))}
                    </Typography>
                    <Typography variant="subtitle1">
                      <em automation-testID="expiresDraftJob">Expires: {moment(get(data, ['expiryDate'], '')).format('ll')}</em> 
                    </Typography>
                    {/* Data not coming from backend */}
                  </Typography>
                  <img src={expandmore} alt='menu icon' style={{ marginLeft: '18px', marginTop: '4px' }} />
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} >
              <Typography variant="h3" className={classes.font}>
                {get(data, ['positions'], '')} Positions {bull}
              </Typography>
              <Typography variant="h3" className={clsx(classes.positionsData, classes.font)} onClick={() => onActivityBtnClick('applied')}>
                {get(data, ['applied'], '')} Applied {bull}
              </Typography>
              <Typography variant="h3" className={clsx(classes.positionsData, classes.font)} onClick={() => onActivityBtnClick('shortlisted')}>
                {get(data, ['shortlisted'], '')} Shortlisted
              </Typography>
            </Grid>
            <Grid container xs={12} style={{ marginTop: '15px' }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.recom_title}>
                  Deepailytics Recommendations
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.avatar} style={{ display: 'flex' }}>
                <div className={classes.recommend} ><Avatar /><span className={classes.recommendSpan}>{bull}</span></div>
                <div className={classes.recommend}><Avatar /><span className={classes.recommendSpan}>{bull}</span></div>
                <div className={classes.recommend}><Avatar /><span className={classes.recommendSpan}>{bull}</span></div>
                <div className={classes.recommend}><Avatar /><span className={classes.recommendSpan}>{bull}</span></div>
                <div className={classes.recommend}><Avatar /><span className={classes.recommendSpan}>{bull}</span></div>
                <div className={classes.recommend} ><Avatar /><span className={classes.recommendSpan}>{bull}</span></div>
                <div className={classes.recommend}><Avatar /><span className={classes.recommendSpan}>{bull}</span></div>
              </Grid>
            </Grid>
          </CardContent>
          <Grid container className={classes.btnContainer}>
            <Grid item xs={12}>
              <ButtonGroup variant="text" aria-label="text button group" color='secondary' className={classes.btnGroup}>
                <Button startIcon={<img src={jobdetails} className={classes.iconBtn} />} className={classes.iconBtn1} onClick={onJobDetailsBtnClick}>Job Details</Button>
                <Button startIcon={<img src={activity} className={classes.iconBtn} />} className={classes.iconBtn1} onClick={onActivityBtnClick}>Activity</Button>
                <Button startIcon={<img src={jobreport} className={classes.iconBtn} />} className={classes.iconBtn1} onClick={onJobReportBtnClick}>Job Report</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default JobCardEmployer;
