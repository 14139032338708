import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import propTypes from 'prop-types';
import { colors } from '../../../../themes/colors';

const styles = () => ({
  default: {
    textTransform: 'capitalize',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#fff',
    padding: '11px 24px',
    borderRadius: '8px',
    fontSize: '18px',
    backgroundColor: colors.blue2,
    '&:hover': {
      backgroundColor: colors.blue1,
    },
    margin: '0 1rem',
  },
  outlinedHomeBtn: {
    border: '1px solid #FF7100',
    color: '#FF7100',
    margin: '0px',
    backgroundColor: '#fff',
    '&:hover': {
      background: '#fff'
    },
  }
});

const HomeBtns = ({ children, classes, variant, color, onClick }) => {
  return (
    <Button
      variant={variant}
      color={color}
      classes={{ root: classes.default, outlined: classes.outlinedHomeBtn }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

HomeBtns.propTypes = {
  color: propTypes.string
};

export default withStyles(styles)(HomeBtns);
