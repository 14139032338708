import React, { useState } from 'react';
import * as R from 'ramda';
import { Container, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ComposerPanelWrapper from '../../../common/wrappers/ComposerPanelWrapper';
import MentorCard from './MentorCard';
import { mentorsTab } from 'constants/applicant/applicant';
import Profile from '../Profile/Profile';
import S1 from '../../../../asset/images/Ellipse_1.png';
import S2 from '../../../../asset/images/Ellipse_2.png';
import S3 from '../../../../asset/images/Ellipse_3.png';
import S4 from '../../../../asset/images/Ellipse_4.png';


const useStyles = makeStyles(theme => ({
  gridMain: {
    padding: '3rem 1rem', marginBottom: '3rem'
  },
  pageTitle: {
    ...theme.typography.title,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
}));


const MyJobs = () => {
   
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const RendererExample = () => {
    return (
      <Profile PanelConfig={PanelConfig} />
    );
  };
  const mentors = [
    { avatar:S1, name:'Semon Jack', designation:'UX Designer', company:'ABC Consulting', experience:3, skills:['UI/UX Designing','Design Thinking', 'Sketching', 'Whiteboarding', 'JavaScript', 'HTML', 'Bootstrap'], rating: 3 },
    { avatar:S2, name:'Jenny Smith', designation:'UX Designer', company:'ABC Consulting', experience:3, skills:['UI/UX Designing','Design Thinking', 'Sketching', 'Whiteboarding', 'JavaScript'], rating: 3.5 },
    { avatar:S3, name:'Alen Jack', designation:'UX Designer', company:'ABC Consulting', experience:4, skills:['UI/UX Designing','Design Thinking', 'Sketching', 'Whiteboarding', 'JavaScript', 'HTML', 'Bootstrap'], rating: 3 },
    { avatar:S4, name:'Maddy Parker', designation:'UX Designer', company:'ABC Consulting', experience:3, skills:['UI/UX Designing','Design Thinking', 'Sketching', 'Whiteboarding'], rating: 3 },
  ];
  const a11yProps=(index)=> {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const PanelConfig = {
    isOpen: false,
    anchor: 'right',
    Renderer: RendererExample, // ComponentRenderer
    rendererConfigs: {
      usedIn:'mentor'
    },
  };

  return (
    <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
      <Container maxWidth="lg" className={classes.gridMain}>
        <Grid
          container
          direction="column"
          className={classes.mainContainer}
          spacing={2}>
          <Grid container spacing={0} className={classes.pageTitleContainer} alignItems="baseline">
            <Grid item xs={12}>
              <Typography className={classes.pageTitle}>All Job Posting</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example" >
                {
                  R.map((tab) => {
                    return <Tab label={tab.label} {...a11yProps(tab.id)} />;
                  })(mentorsTab)
                }
              </Tabs>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: '1rem' }}>
            {mentors.length && mentors.map(mentorProps => 
              <Grid item xs={12} md={6}>
                <MentorCard {...mentorProps} />
              </Grid>
            )}
          </Grid>
        </Grid>

      </Container>
    </ComposerPanelWrapper>
  );
};

export default MyJobs;
