export const endPoints = {
  login: '/security/auth/employer/login',
  logout: '/security/auth/logout',
  register: '/security/auth/employer/signup',
  registerEmployer: '/security/auth/employer/signup',
  signup: '/security/auth/signup',
  verifyEmail: '/security/auth/employer/verifyEmail',
  resendOTP: '/security/auth/employer/send/otp',
  employerProfile: '/employer/employee',
  interviewScheduledCalls: '',
  decisionDetails: '',
  configuration: {
    companyInformation: '/employer/company-info',
    loggedInCompanyInfo: '/employer/company-info/me',
    companyLocation: '/employer/address',
    jobTitle: '/employer/jobTitle',
    allJobTitle: '/employer/jobTitle/all',
    staticQuestions: '/employer/staticQuestions',
    interviewQuestions: '/employer/feedbackQuestions',
    AIjobdescription: 'https://ml.deepailytics.com'
  },
  jobPosting:'/job/job-details',
  jobPostingStatus:'/job/job-details/status',
  jobStaticQuestions:'/job/staticQuestions',
  jobEmployeeParticipants:'/job/job-employee-participant',
  hiringManagerList: '/employer/employee/hiring-manager',
  interviewersList: '/employer/interviewer',
  userManagement: {
    empUser: '/employer/employee',
    addInterviewer: '/employer/interviewer',
    allInterviewers: '/employer/interviewer/readByPage',
  },
  job: {
    assignedJobPosts: '/job/job-details/assigned',
    jobDetails: '/job/job-details',
    allJobDetails: '/job/job-details/my',
    allJobsCriteria: '/job/job-details/criteria',
    appliedJobs: '/job/jobs/applicants',
    similarApplicants: '/job/similarApplicants/all',
    recommendedCandidates: '/job/jobs/applicants',
    candidates: '/job/activity/application/all',
  },
  candidateByJobProfiles: '/job/activity/application/all/',
  roles: {
    roles: '/security/roles',
    rolesByPage: '/security/roles/readByPage'
  },
  forgotPassword: '/security/auth/employer/changePassword',
  profileDetails: '/profile/user-details',
  customMySchedule: '/job/organiser/interview/custom/mySchedule',
  getInterviewSlots: '/job/interview/slot/organiser/all',
  shareSlot: '/job/interview/slot/organiser/create',
  shareSlotCandidate: '/job/interview/slot/organiser/select/applicants'
};
  
