import { endPoints } from 'constants/employerEndpoints';
import { pick } from 'lodash';
import { callApi } from 'middleware/api';

export const getAllJobs = (data) => {
  const { pageNo, pageSize } = data;
  return callApi(data, 'GET', `${endPoints.job.allJobDetails}?pageNo=${pageNo}&pageSize=${pageSize}`, true);
};

export const getJobDetails = (data) => {
  return callApi(data, 'GET',`${endPoints.job.jobDetails}?id=${data.id}` , true);
};

export const getJobsCriteria = (data) => {
  const payload = pick(data, ['entryStatus',
    'companyDetailsId',
    'pageNo',
    'pageSize',
    'sortBy']);
  return callApi(payload, 'POST', endPoints.job.allJobsCriteria, true);
};

export const getAppliedJobs = (data) => {
  return callApi(data, 'GET', `${endPoints.job.appliedJobs}?jobId=${data.id}&pageNo=${data.pageNo}&pageSize=${data.pageSize}`, true);
};

export const getSimilarJobs = (data) => {
  return callApi(data, 'GET', `${endPoints.job.similarApplicants}/${data.id}`, true);
};

export const getCandidates = (data) => {
  return callApi(data, 'GET', `${endPoints.job.candidates}${data}`, true);
};

export const getCandidatesRecommended = (data) => {
  return callApi(null, 'GET', `${endPoints.job.recommendedCandidates}${data}`, true);
};

export const getProfileDetails = (data) => {
  return callApi(data, 'GET', `${endPoints.profileDetails}/${data.id}`, true);
};

export const shortlistCandidate = (data) => {
  return callApi(data, 'POST', '/job/activity/application/shortlist', true);
};
export const getStatsDetails = (state, filter) => {
  return callApi(null, 'GET', `/job/activity/application/count?state=${state}&period=${filter}`, true);
};
