import { endPoints } from 'constants/interviewerEndpoints';
import { callApi } from 'middleware/api';

export const addPrepQuestion = (body) => {
  return callApi(body.data, 'POST', `${endPoints.interviewer}/${body.id}/prepQuestions`, true);
};

export const getPrepQuestionsList = (id) => {
  return callApi(null, 'GET', `${endPoints.interviewer}/${id}/prepQuestions`, true);
};

export const editPrepQuestion = (body) => {
  return callApi(body.data, 'PUT', `${endPoints.interviewer}/${body.interviewId}/prepQuestions/${body.questionId}`, true);
};

export const deletePrepQuestion = ({ interviewId, questionId }) => {
  return callApi(null, 'DELETE', `${endPoints.interviewer}/${interviewId}/prepQuestions/${questionId}`, true);
};
