import * as React from 'react';
import { AppBar, Box, Drawer, IconButton, Toolbar, Button, Hidden, Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const drawerWidth = 400;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      flexShrink: 0,
      backgroundColor: '#ffffff',
    },
  },
  appBar: {
    backgroundColor: 'transparent', // '#f6edff', // #eee8f5
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    width: '4%' ,
    ml: { sm: '400px' },
    '&.MuiAppBar-positionFixed': {
      left: 0,
      right: 'auto',
    }
  },
  menuButton: {
    backgroundColor: '#eee8f5',
    '&:hover': {
      backgroundColor: '#eee8f5',
    },
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    position: 'initial',
    borderLeft: 'none',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  logocontainer: {
    padding: 5,
  },
  backButton: {
    fontSize: '1rem',
    padding: '1.5rem',
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    color: 'gray',
    '&:hover': {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
    }
  },
}));

// Wrap you page in this component when you want panel. Currently its fixed and always open. Can be enhaced based on requirement
const ComposerPanelWrapper = ({ children, panelConfig }, props) => {
  const classes = useStyles();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { Renderer, rendererConfigs, componentRenderer } = panelConfig;
  const container = window !== undefined ? () => window().document.body : undefined;
  const onBackClick = () => {
    setMobileOpen(false);
  };
  return (
    <>
      <Box className={classes.root}>
        <CssBaseline />
        <AppBar
          style={{ zIndex: 2, }}
          className={classes.appBar}
          position="fixed"
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        

        <Box
          className={classes.content}
          component="main"
        >
          {children}
        </Box>
        
        {(Renderer || componentRenderer) && <Box
          component="nav"
          className={classes.drawer}
          aria-label="applicant profile"
        >
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <Grid container>
                <Grid item xs={12} style={{ textAlign: 'end' }}>
                  <Button
                    variant="contained"
                    className={classes.backButton}
                    startIcon={<ArrowBackIosIcon fontSize='small' />}
                    onClick={onBackClick}>
                    Back
                  </Button>
                </Grid>
              </Grid>
              <div className={classes.grow} />
              
              {/*  imp - do not delete this section
              <Typography>Menu item here</Typography> */}
              
              {Renderer && <Renderer {...rendererConfigs} />}
              {componentRenderer && componentRenderer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              className={classes.drawer}
              variant="permanent"
              anchor="right"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.grow} />
              {Renderer && <Renderer {...rendererConfigs} />}
              {componentRenderer && componentRenderer}
            </Drawer>
          </Hidden>
        </Box>}
      </Box>

      {/* <Drawer
        anchor={_.get(panelConfig, 'anchor', 'right')}
        variant={_.get(panelConfig, 'variant', 'permanent')}
        open={isOpen}
        onClose={onClose}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <Renderer {...rendererConfigs} />
      </Drawer> */}
    </>
  );
};

ComposerPanelWrapper.propTypes = {
  window: PropTypes.func,
  container: PropTypes.object,
  panelConfig: PropTypes.shape({
    // anchor: PropTypes.string.isRequired,
    Renderer: PropTypes.func.isRequired,
    rendererConfigs: PropTypes.any,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  
};

export default ComposerPanelWrapper;