import React, { useState } from 'react';
import * as yup from 'yup';
import { YupValidator } from '../../../../../utils/YupValidator';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Formik, Form } from 'formik';
import { Grid, Button, InputLabel } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormikInputField } from '../../../../ui-components';

const useStyles = makeStyles(() => ({
  labelSubtext: {
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '0.8rem',
    lineHeight: '1.5rem',
    color: '#24283C',
  },
  check: {
    display: 'flex',
  },
}));

const AddJobTitleForm = ({ initialValues, addJobTitleorEditJobTitle, jobIntialValue }) => {
  const [checked, setChecked] = useState(jobIntialValue ? jobIntialValue.belongsToRecuritment : false);

  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const validationSchema = yup.object({
    title: YupValidator('Required'),
    designation: YupValidator('Required'),
    department: YupValidator('Required'),
  });

  const handleSubmit = async (values, actions) => {
    const jobDetails = {
      id: values && values.id,
      title: values && values.title,
      designation: values && values.designation,
      department: {
        name: values.department,
      },
      belongsToRecuritment: checked,
    };
    addJobTitleorEditJobTitle(jobDetails, () => {
      actions.setTouched({});
    });
  };

  const handleCheckedChange = (e) => {
    const { checked } = e.target;
    setChecked(checked);
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {() => (
          <Form>
            <Grid container spacing={3} direction={matchesMD ? 'column' : 'row'}>
              <Grid item md={matchesMD ? 12 : 4}>
                <InputLabel required>Job Title</InputLabel>
                <FormikInputField localePrefix="DHIYO.CONFIGURATION.JOBTITLE.ADD_FORM.JOB_TITLE" name="title" />
              </Grid>
              <Grid item md={matchesMD ? 12 : 4}>
                <InputLabel required>Designation</InputLabel>
                <FormikInputField localePrefix="DHIYO.CONFIGURATION.JOBTITLE.ADD_FORM.DESIGNATION" name="designation" />
                <span className={classes.labelSubtext}>as per your company</span>
              </Grid>
              <Grid item md={matchesMD ? 12 : 4}>
                <InputLabel required >Department</InputLabel>
                <FormikInputField localePrefix="DHIYO.CONFIGURATION.JOBTITLE.ADD_FORM.DEPARTMENT" name="department" />
                <div className={classes.check}>
                  <div>
                    <input type="checkbox" name="belongsToRecuritment" checked={checked} onChange={handleCheckedChange} />
                  </div>
                  <span className={classes.labelSubtext}>belongs to Recruitment</span>
                </div>
              </Grid>
              {matchesMD ? <Grid item md={9} /> : ''}
              <Grid item md={12} style={{ padding: '0 14px' }}>
                <Button type="submit" variant="contained" color="secondary" style={{ minWidth: 100 }} disableElevation disableRipple>
                  {jobIntialValue && jobIntialValue.id ? 'Update' : 'Add'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddJobTitleForm;
