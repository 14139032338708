import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { isEmpty } from 'lodash';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import uploadFile from 'asset/icons/uploadfile.svg';

const useStyle = makeStyles(() => ({
  dropzoneStyle: {
    '&.MuiTypography-root': {
      borderWidth: 1,
      borderColor: '#797D89',
      borderStyle: 'dashed',
      borderRadius: 3,
      height: '200px',
      width: '350px',
      backgroundColor: '#F4F4F4',
      paddingTop: '2.5rem',
    },
  },
  italicText: {
    color: '#797D89',
  },
  subTitle: {
    fontFamily: 'poppins',
    fontSize: '0.8rem',
    fontWeight: 500, 
  },
  customicon: {
    width: '70px',
    display: 'block',
    margin: 'auto',
    paddingBottom: '10px',
  },
}));

const ResumeUploadView = ({ uploadedFiles, setUploadedFiles }) => {
  const classes = useStyle();
  const [dropError, setDropError] = useState('');

  const onDropRejected = () => {
    setDropError('Maximum file upload size is 2MB');
  };

  return (
    <>
      <Grid
        container
        component="main"
        maxWidth="sm"
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ padding: '0px 30px' }}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle" className={classes.subTitle}>
          Drag and drop your resume file (PDF, Word document) or upload.
          </Typography>
        </Grid>
        <Grid>
          <Dropzone
            onDrop={(acceptedFiles) => {
              setDropError('');
              setUploadedFiles(acceptedFiles);
            }}
            accept=".pdf, .docx, .doc"
            maxSize={2097152}
            onDropRejected={onDropRejected}>
            {({ getRootProps, getInputProps }) => (
              <section style={{ paddingTop: '20px' }}>
                <div {...getRootProps()}>
                  
                  <Typography align="center" color="gray" className={classes.dropzoneStyle} style={{ borderColor: `${isEmpty(dropError) ? 'gray' : 'red'}` }}>
                    <img src={uploadFile} alt='uploadFile icon' className={classes.customicon} />
                    <input {...getInputProps()} />
                    {isEmpty(uploadedFiles) ? (
                      <>
                        {'Drag & Drop or '}
                        <a style={{ textDecorationLine: 'underline', color: 'blue', cursor: 'default' }}>Browse</a>
                        {' your file'}
                      </>
                    ) : (
                      `Uploaded - ${uploadedFiles[0].path}`
                    )}
                  </Typography>
                </div>
              </section>
            )}
          </Dropzone>

          <p className={classes.italicText}><i>Max file size 2MB.</i></p>
        </Grid>
      </Grid>
    </>
  );
};

export default ResumeUploadView;
