import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import JobCard from './JobCard';
import { onGetSavedJobs } from 'redux/actions/applicantDashboard';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import TablePagination from '@mui/material/TablePagination';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex', alignItems: 'center', justifyContent: 'space-between'
  },
  pageTitle: {
    ...theme.typography.title,
  },
  img: {
    height: 25,
    width: 25,
    cursor: 'pointer',
  },
  icon: {
    marginLeft: 20,
  },
}));

const SavedJobs = () => {
  const classes = useStyles();

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(3);

  const mySavedJobs = useSelector(state => get(state, ['applicantDashboard', 'mySavedJobs'], ''));
  const dispatch = useDispatch();

  useEffect(() => {
    if (mySavedJobs) {
      dispatch(onGetSavedJobs(`?pageNo=${pageNumber}&pageSize=${pageSize}&sortBy=id`));
    }
  }, [pageNumber, pageSize]);

  const handlePaginationClick = (e, selectedPage) => {
    setPageNumber(selectedPage - 1);
  };

  const handlePageSelection = (event) => {
    setPageSize(parseInt(event.target.value));
  };

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <Grid className={classes.grid}>
        <Grid item>
          <Typography className={classes.pageTitle}>Saved Jobs ({mySavedJobs?.totalElements})</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {!!mySavedJobs?.content?.length && mySavedJobs?.content.map(job => 
          <Grid item xs={12} md={4}>
            <JobCard job={job} />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={0} style={{ display: 'flex', alignItems: 'flex-start' }}>
        {!!mySavedJobs?.content?.length && (
          <>
            <Grid item xs={3} style={{ display: 'flex' }}>
              <TablePagination
                component="div"
                rowsPerPageOptions={[1, 2, 3, 5, 10, 25, 50]}
                count={mySavedJobs?.totalPages || 0}
                page={pageNumber}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handlePageSelection}
              />
            </Grid>
            <Grid item xs={9} style={{ justifyContent: 'end', display: 'flex' }}>
              <Pagination
                count={mySavedJobs?.totalPages || 0}
                onChange={handlePaginationClick}
                renderItem={(item) => {
                  return <PaginationItem components={{ first: 'first', last: 'last' }} {...item} />;
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default SavedJobs;
