import React, { useState } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Filter from './Filter';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import JobCard from 'view/common/components/JobCard/JobCard';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { onGetRecommendedJobs } from 'redux/actions/applicantDashboard';
import DPInfiniteScrollableWrapper from 'view/common/DPInfiniteScrollable/DPInfiniteScrollableWrapper';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    margin: '25px 0 14px 0',
  },
  jobPostedGrid: {
    height: '909px',
    [theme.breakpoints.down('md')]: {
      height: '350px',
    },
  },
}));

const JobCardList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const [ModalOpen, setfromModalOpen] = useState(false);
  const totalJobs = useSelector(state => get(state, ['applicantDashboard', 'recommendedJob', 'totalElements'], 0));
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setfromModalOpen(true);
  };
  const handleClose = () => {
    setfromModalOpen(false);
  };

  React.useEffect(() => {
    dispatch(onGetRecommendedJobs());
  }, []);

  const handleApplyFilter = () =>{
    // const payload = {
    //   ...data,
    //   pageNo: 0,
    //   pageSize: 10,
    // };
    
    setfromModalOpen(false);
    // on Filter Apply set requst payload and uncomment the below line for API call
    // dispatch(onGetRecommendedJobs(payload));
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Grid container xs={12} alignItems="baseline">
        <Grid item xs={10}>
          <Typography className={classes.pageTitle}>
            {' '}
            Recommended Jobs({totalJobs})
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'end' }}>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              size='small'
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              Filters
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={ModalOpen}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Typography sx={{ paddingTop: '6px' }}>
                <Filter onClose={handleClose} applyFilter={handleApplyFilter} />
              </Typography>
            </Menu>
          </div>
        </Grid>
      </Grid>
      <Box className={classes.jobPostedGrid}>
        <DPInfiniteScrollableWrapper
          dispatcher={onGetRecommendedJobs}
          dataPath={['applicantDashboard', 'recommendedJob']}
          itemRendererConfig={
            {
              Renderer: JobCard,
            }
          }
          styleConfig={ matchesSM ? { rowHeight: 255 } : { rowHeight: 250 } }
          pageConfig={{
            pageSize: 10,
          }}
        />
      </Box>
    </Box>
  );
};

export default JobCardList;
