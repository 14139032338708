import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Card, Typography, CardContent, Box, Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cardContainer: {
    minWidth: '540px',
    margin: '1.2rem'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  title: {
    fontSize: '16px',
    color: '#333333',
    fontFamily: 'Poppins',
    fontWeight: '500'
  },
  duration: {
    fontSize: '10px',
    color: '#828282',
    fontFamily: 'Poppins',
    fontWeight: '500',
    marginTop: '0.1rem'
  },
  lectures: {
    fontSize: '10px',
    color: '#828282',
    fontFamily: 'Poppins',
    fontWeight: '500'
  },
  rating: {
    fontSize: '12px',
    color: '#333333',
    fontFamily: 'Poppins',
    fontWeight: '500',
    marginTop: '0.4rem'
  },
  description: {
    fontSize: '12px',
    color: '#333333',
    fontFamily: 'Poppins',
    fontWeight: '500',
    marginTop: '0.4rem',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const LearningCard = ({ item }) => {
  const classes = useStyles();
  return (
    <>
      <a href={item?.CourseURL} target="_blank" style={{ textDecoration: 'none' }}>
        <Card className={classes.cardContainer}>
          <CardContent className={classes.content}>
            <Box>
              <Typography className={classes.title}>
                {item?.CourseTitle}
              </Typography>
              <Typography className={classes.duration}>
                {item?.Duration}
              </Typography>
              <Typography className={classes.lectures}>
                {item?.NoOfLectures}
              </Typography>
              <Button size='small' variant="contained" color="secondary" >
                <Typography style={{ fontSize: '12px', color: '#FFFFFF', fontFamily: 'Poppins', fontWeight: '400' }}>
                Rating {item?.Rating}
                </Typography>
              </Button>
            </Box>
            <Box >
              <Typography className={classes.description}>
                {item?.Description}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </a>
    </>
  );
};

export default LearningCard;
