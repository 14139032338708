import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Tab, Tabs, Button, IconButton, Menu, MenuItem, Badge, Box } from '@material-ui/core';
import Logo from 'asset/icons/LogoName.svg';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import HeaderActions from './HeaderActions';
import { get, includes, isEmpty, isNull } from 'lodash';
import { useLocation } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import gmail from 'asset/icons/gmail.svg';
import { useDispatch } from 'react-redux';
import help from 'asset/icons/help.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import notify from 'asset/icons/noti.svg';
import account from 'asset/icons/account.svg';
import { onSignOut } from 'redux/actions/userAuthentication';

const useStyles = makeStyles(theme => ({
  toolbarMargin: {
    marginTop: 60,
  },
  logoWrapper: {
    width: '15%',
  },
  logocontainer: {
    padding: 5,
    [theme.breakpoints.down('xs')]: {
      left: '2.5rem',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  appBar: {
    backgroundColor: '#eee8f5',
    boxShadow: 'none',
    // zIndex: 1201,
    zIndex: 2,
  },
  grow: {
    flexGrow: 1,
  },
  toolbarContainer: {
    marginLeft: '30px',
    marginRight: '30px',
  },
  tab: {
    minWidth: 'auto',
    fontSize: '0.9rem',
  },
  navMenu: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const HIDE_HEADER_ROUTES = ['/build-profile'];

export default function Header(props) {
  const dispatch = useDispatch();
  const isTokenPresent = localStorage.getItem('deepailytics.auth.token');
  const location = useLocation();
  const [value, setValue] = useState();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    const currentPathDetails = props.routes.filter((route => route.link === location.pathname));
    if (!isEmpty(currentPathDetails)) {
      setValue(currentPathDetails[0].activeIndex);
    } else {
      setValue();
    }
  }, [props]);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  const routes = props.routes.filter(r => get(r, 'visible', true));
  const tabs = (
    <React.Fragment>
      <Tabs
        value={value}
        className={classes.tabContainer}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="secondary">
        {routes.map((route, index) => (
          <Tab
            key={`${route.name}${index}`}
            component={Link}
            to={route.link}
            label={route.name}
            className={classes.tab} />
        ))}
      </Tabs>
    </React.Fragment>
  );

  if (localStorage.getItem('deepailytics.user.role') === 'ROLE_APPLICANT'
    && (isNull(isTokenPresent) || includes(HIDE_HEADER_ROUTES, location.pathname))) {
    return null;
  }


  // ***********************

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // logout function
  const handleMobileLogout = () => {
    dispatch(onSignOut()).then(() => {
      localStorage.removeItem('deepailytics.auth.token');
      localStorage.removeItem('deepailytics.user.role');
      localStorage.removeItem('deepailytics.auth.refreshToken');
      window.location.href='/';
    });

  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="small" aria-label="show 4 new mails" color="#6361CD" style={{ marginRight: '1rem' }}>
          <Badge badgeContent={4} color="error">
            <img src={gmail} alt='gmail icon' style={{ width: '30px' }} />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="small"
          aria-label="show 17 new notifications"
          color="#6361CD"
          style={{ marginRight: '1rem' }}
        >
          <Badge badgeContent={17} color="error">
            <img src={notify} alt='notify icon' style={{ width: '30px' }} />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="small"
          aria-label="show 17 new notifications"
          color="#6361CD"
          style={{ marginRight: '1rem' }}
        >
          <img src={help} alt='help icon' style={{ width: '30px' }} />
        </IconButton>
        <p>Help</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="#6361CD"
          style={{ marginRight: '1rem' }}
        >
          <img src={account} alt='account icon' style={{ width: '30px' }} />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={handleMobileLogout}>
        <IconButton
          size="small"
          aria-label="Logout"
          color="#6361CD"
          style={{ marginRight: '1rem' }}
        >
          <LogoutIcon style={{ width: '30px', color: '#6361CD' }} />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  // ***************************

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}> 
        {/*  style={{ zIndex:'1000' }} */}
        <Toolbar>
          <div className={classes.logoWrapper}>
            <Button
              disableRipple
              className={classes.logocontainer}
              component={Link}
              to={routes && routes.length && props.routes[0].link}>
              <img alt="DHIYO" src={Logo} className={classes.logo} />
            </Button>
          </div>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {tabs}
          </Box>

          <div className={classes.grow} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <HeaderActions tabValue={value} />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}
      <div className={classes.toolbarMargin} />
    </>
  );
}
