import React, { useEffect, useState } from 'react';
import {
  Link,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Badge,
} from '@material-ui/core';
import PercentageButton from '../../../ui-components/PercentageButton';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../../themes/colors';
import styled from 'styled-components';
import ImageAvatar from '../../../ui-components/ImageAvatar';

import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../route/constants';
import SectionScroll from 'view/common/components/SectionScroll/SectionScroll';
import ProfileStats from 'view/common/components/ProfileStats/ProfileStats';
import { useDispatch, useSelector } from 'react-redux';
import { assocPath, pathOr } from 'ramda';
// import { calculateProfileCompletionPercent } from 'view/common/utils/formUtils';
import { setApplicantProfile } from 'redux/slices/userProfile';
import { onPersonalDetailsAddEdit } from 'redux/actions/userProfile';
import { isEmpty } from 'lodash';
import editprofile from 'asset/icons/editprofile.svg';

const useStyles = makeStyles(() => ({
  grayLabel: {
    fontSize: '0.75rem',
    color: colors.gray3,
    lineHeight: '1.125rem',
    fontWeight: 'normal',
    marginTop: '20px',
  },
  designationLabel: {
    marginTop: '2px',
  },
  link: {
    fontWeight: 500,
    fontSize: '0.8rem',
  },
  switchWrapper: {
    justifyContent: 'space-between',
    marginRight: '16px',
    marginTop: '1.5rem',
  },
  profilePic: {
    width: '7rem',
    height: '7rem',
  },
}));

const Profile = ({ PanelConfig }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const profileData = useSelector(state => pathOr('', ['userProfile', 'applicant'], state));
  const onEditUpdateProfile = () => navigate(RoutePaths.editProfile);
  const { firstName, lastName, headline, profileCompleted, email, id } = profileData;
  const profileCompletionStatus = profileCompleted ? 100 : profileData.profileCompletionPercentage;
  const [checkedA, setCheckedA] = useState(false);
  const [checkedFresher, setCheckedFresher] = useState(false);
  //
  //
  // **TODO: Left nav conditional data display. TRUE: Applicant Dashboard, FALSE: edit profile form links
  const onFresherToggle = async (e) => {
    const updatedProfileData = assocPath(['fresher'], e.target.checked, profileData);
    dispatch(setApplicantProfile(updatedProfileData));
    await dispatch(onPersonalDetailsAddEdit({ payload: updatedProfileData, method: 'PUT' }));
  };

  const onActivelyLookingJobToggle = async (e) => {
    const updatedProfileData = assocPath(['activelyLookingForJob'], e.target.checked, profileData);
    dispatch(setApplicantProfile(updatedProfileData));
    await dispatch(onPersonalDetailsAddEdit({ payload: updatedProfileData, method: 'PUT' }));
  };

  useEffect(() => {
    setCheckedA(profileData.activelyLookingForJob);
  }, [profileData.activelyLookingForJob]);

  useEffect(() => {
    setCheckedFresher(profileData.fresher);
  }, [profileData.fresher]);

  const usedIn = PanelConfig?.rendererConfigs?.usedIn || false;
  return (
    <ProfileContainer>
      <ProfileImageContainer>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <img src={editprofile} alt='edit profile icon' style={{ width: '30px' }} />
          }
        >
          <ImageAvatar />
        </Badge>
      </ProfileImageContainer>
      <Typography align="center" variant="h5">
        {`${firstName || ''} ${lastName || ''}`}
      </Typography>
      <Typography align="center" variant="h5">
        {`${email}`}
      </Typography>
      <Typography
        align="center"
        variant="h5"
        className={classes.designationLabel}>
        {headline}
      </Typography>

      <PercentBtnWrapper>
        <Typography
          align="center"
          className={classes.grayLabel}>{`Your profile is complete for ${profileCompletionStatus}%`}
        </Typography>
        <PercentageButton
          onClick={onEditUpdateProfile}
          label="Update/Edit Profile"
          percent={profileCompletionStatus} />
      </PercentBtnWrapper>

      {usedIn != 'mentor' &&
        <>
          <Typography align="center" style={{ marginTop: '1.6rem' }}>
            <Link href="#" onClick={() => { }} variant="button" color={colors.blue1} className={classes.link}>
              Change Password
            </Link>
          </Typography>
          <FormGroup aria-label="position" row className={classes.switchWrapper}>
            <FormControlLabel
              value={profileData.activelyLookingForJob}
              checked={profileData.activelyLookingForJob}
              onChange={onActivelyLookingJobToggle}
              control={<Switch color="primary" disabled={isEmpty(id)} checked={checkedA} />}
              label={<Typography variant="subtitle2">Actively looking for Job</Typography>}
              labelPlacement="start" />
            <FormControlLabel
              value={profileData.fresher}
              onChange={onFresherToggle}
              checked={profileData.fresher}
              control={<Switch color="primary" disabled={isEmpty(id)} checked={checkedFresher} />}
              label={<Typography variant="subtitle2">Fresher</Typography>}
              labelPlacement="start" />
          </FormGroup>
        </>
      }
      {
        usedIn != 'mentor' ? PanelConfig ? (
          <div>
            <ProfileStats
              sectionDataPath={['userProfile', 'applicantProfileCompletion']}
            />
          </div>
        ) : (
          <div>
            <SectionScroll
              sectionDataPath={['userProfile', 'applicantProfileCompletion']}
            />
          </div>
        )
          : ''
      }
      
    </ProfileContainer>
  );
};

const PercentBtnWrapper = styled.div`
  margin-left: 5rem;
  margin-right: 5rem;
`;

const ProfileContainer = styled.div`
  padding: 1rem;
`;

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem;
`;

export default Profile;
