import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import * as R from 'ramda';
import Icons from '../../../../constants/icons';
import BadgeIcon from '../../../ui-components/BadgeIcon';
import { useNavigate } from 'react-router-dom';
import { onSignOut } from 'redux/actions/userAuthentication';
import { useLocation } from 'react-router-dom';
import { isNull, startsWith, endsWith, get } from 'lodash';
import { getUserType, isProfileCompleted } from '../../utils/userProfileUtil';
import { setDrawer } from 'redux/actions/applicantDashboard';
import { learningCardRecord } from 'mockData/LearningCardsData';
import LearningCard from 'view/common/LearningCard/LearningCard';
import DPDrawer from 'view/common/DPDrawer';

const HeaderActions = ({ tabValue }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isTokenPresent = localStorage.getItem('deepailytics.auth.token');
  const isEmployerPath = !isNull(isTokenPresent) && startsWith(location.pathname, '/employer');
  const isDrawerOpen = useSelector(state => get(state, ['applicantDashboard', 'isDrawerOpen']));

  const Actions = [
    {
      key: 'mail',
      label: 'Show new mails',
      Renderer: BadgeIcon,
      badgeContent: '4',
      icon: 'MailOutlineIcon',
      onClick: () => dispatch(setDrawer(true)),
    },
    {
      key: 'notification',
      label: 'Show new notifications',
      Renderer: BadgeIcon,
      badgeContent: '17',
      icon: 'NotificationsNoneIcon',
      onClick: () => { },
    },
    {
      key: 'help',
      label: 'help',
      Renderer: Icons.HelpOutlineIcon,
      isConfigOption: true,
      onClick: () => {
        navigate('/applicant/help');
      },
    },
    {
      key: 'edit-profile',
      label: isEmployerPath ? 'Configuration' : 'Account of current user',
      Renderer: isEmployerPath ? Icons.BuildOutlinedIcon : Icons.AccountCircle,
      isConfigOption: true,
      onClick: () => {
        isEmployerPath ? navigate('/employer/configuration') : navigate('/applicant/edit-profile');
      },
    },
    {
      key: 'logout',
      label: 'Logout',
      Renderer: Icons.LogoutIcon,
      onClick: () => {
        dispatch(onSignOut()).then(() => {
          localStorage.removeItem('deepailytics.auth.token');
          localStorage.removeItem('deepailytics.user.role');
          localStorage.removeItem('deepailytics.auth.refreshToken');
          window.location.href = '/';
        });
      },
    },
  ];
  let activeActions = Actions;
  if (!isProfileCompleted()) {
    activeActions = Actions.filter(action => action.label.toLowerCase() === 'logout');
    const userType = getUserType();
    if (userType === 'interviewer') {
      const interViewerProfile = {
        label: 'My Profile',
        Renderer: Icons.AccountCircle,
        isConfigOption: true,
        onClick: () => { },
      };
      activeActions.unshift(interViewerProfile);
    }
  }

  return (
    <>
      {_.map(activeActions, ({ label, key, Renderer, onClick, ...rest }, index) => {
        return (
          <IconButton
            key={`icon-${index}`}
            aria-label={label}
            onClick={onClick}
            style={{ color: '#6361CD', backgroundColor: tabValue === undefined 
            && endsWith(location.pathname, `/${key}`) ? 'rgba(0, 0, 0, 0.04)' : '' }}
          >
            <Renderer {...rest} />
          </IconButton>
        );
      })}
      <DPDrawer isDrawerOpen={isDrawerOpen} dispatcherEvent={setDrawer} title="Email Received">
        {/* added temporary dummy learningCardData */}
        <Box>
          {
            R.map((learningDetails) => {
              return (
                <LearningCard data={learningDetails} />
              );
            })(learningCardRecord)
          }
        </Box>
      </DPDrawer>
    </>
  );
};

export default HeaderActions;
