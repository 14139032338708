import Interviewer from 'view/interviewer/pages/Home/Home';
import InterviewerCalendar from '../pages/Calendar/InterviewerCalendar';

export const RoutePath = {
  home: '/interviewer/home',
  calendar: '/interviewer/calendar',
};
export const routePaths = [
  {
    name: 'Home',
    link: RoutePath.home,
    activeIndex: 0,
    component: Interviewer,
    visible: true
  },
  {
    name: 'InterviewerHome',
    link: `${RoutePath.home}/:interviewId`,
    activeIndex: 0,
    component: Interviewer,
    visible: false
  },
  {
    name: 'Calendar',
    link: RoutePath.calendar,
    activeIndex: 1,
    component: InterviewerCalendar,
    visible: true
  }
];
