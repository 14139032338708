import PropTypes from 'prop-types';
import { useEffect } from 'react';
// Handle the no routes match condition
const DPNotFoundRoute = ({ defaultPath = null }) => {
  useEffect(() => {
    if (defaultPath) {
      window.location.href = defaultPath;
    }
  }, [defaultPath]) ;
  return null;
};

DPNotFoundRoute.propTypes = {
  defaultPath: PropTypes.string,
};

export default DPNotFoundRoute;