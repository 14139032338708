import Pinterest from 'asset/icons/pinterest.svg';
import Twitter from 'asset/icons/twitter.svg';
import MockImg from 'asset/icons/mockImg.svg';

export const Candidate_Card_Data = [
  {
    id: 1,
    name: 'Jackson Roy',
    jobTitle: 'Visual Designer',
    location: 'Mumbai, Bengaluru',
    compatability: '89',
  },
  {
    id: 2,
    name: 'Jackson Roy',
    jobTitle: 'UI Designer',
    location: 'Mumbai, Bengaluru',
    compatability: '89',
  },
  {
    id: 3,
    name: 'Jackson Roy',
    jobTitle: 'Senior UX Designer',
    location: 'Mumbai, Bengaluru',
    compatability: '89',
  },
  {
    id: 4,
    name: 'Jackson Roy',
    jobTitle: 'Sr.Consultant-UI UX Designer',
    location: 'Mumbai, Bengaluru',
    compatability: '89',
  },
];

export const Social_Media_Data = [
  {
    id: 'First',
    icon: Pinterest,
    jobTitle: 'Visual Designer | Pinterest Pvt. Ltd.',
    location: 'Mumbai, Maharashtra',
    joining: 'Oct 2016-Mar 2019',
    experience: '2 yrs 05 m',
  },
  {
    id: 'First',
    icon: Twitter,
    jobTitle: 'UI Designer | twitter Pvt. Ltd.',
    location: 'Mumbai, Maharashtra',
    joining: 'Oct 2016-Mar 2019',
    experience: '2 yrs 05 m',
  },
];

export const Educational_Data = [
  {
    id: 'First',
    icon: MockImg,
    degree: 'BFA - Designing | Academy of Art University',
    date: 'Oct 2013-Mar 2015',
  },
  {
    id: 'First',
    icon: MockImg,
    degree: 'BFA - Designing | Academy of Art University',
    date: 'Oct 2016-Mar 2019',
  },
];

export const Chip_Data = [
  {
    label: 'Desigining',
  },
  {
    label: 'Visual Desigining',
  },
  {
    label: 'Figma',
  },
  {
    label: 'Adobe XD',
  },
  {
    label: 'Sketch',
  },
  {
    label: 'Illustrator',
  },
];

export const Report_Data = [
  {
    points: '3 - Average',
    question: 'Is the candidate able to point to past disagreements in the workplace that was handled with professionalism and showed resolve?',
    comments: 'UI/UX Designer with a strong design aesthetic who can bring a modern, contemporary and minimalistic style to all our market facing collateral. The ideal candidate would be a person who is a visual thinker and can combine a deep understanding of design with disciplined execution to manage and deliver multiple projects on time.'
  },
  {
    points: '4 - Above Average',
    question: 'When asked about how they prioritize their work, does the candidate have a clear methodology?',
    comments: ''
  },
  {
    points: '3 - Average',
    question: 'How familiar is the candidate with patterns of design? How familiar is the candidate with basic design theory?',
    comments: 'UI/UX Designer with a strong design aesthetic who can bring a modern, contemporary and minimalistic style to all our market facing collateral. The ideal candidate would be a person who is a visual thinker and can combine a deep understanding of design.'
  },
]; 

export const Static_Ques_Data = [
  {
    id: '01',
    question: 'Have you worked in this organization before?',
    ans: '- No'
  },
  {
    id: '02',
    question: 'If you’re currently working, how much notice do you need to give to your employer?',
    ans: '- 3 Months - Serving'
  },
  {
    id: '03',
    question: 'What are your salary expectations?',
    ans: '- 12- 14 Lakh p.a.'
  },
  {
    id: '04',
    question: 'Are you eligible to work in X country?',
    ans: '- Yes'
  },
  {
    id: '05',
    question: 'Are you willing to relocate? If so, when would you be available?',
    ans: '- Yes | After June 15, 2021'
  },
  {
    id: '06',
    question: 'This is a remote position. Do you have access to (e.g. a computer, Internet connection and a private space) to work remotely?',
    ans: '- Yes, I have laptop, however there is some stability issue with internet'
  },
  {
    id: '07',
    question: 'Are you available/willing to travel 40% of the time?',
    ans: '- No'
  },
];

export const Note_Data = [
  {
    note: 'Note 1',
    date: 'Apr 22, 2021 | 12:30 PM',
    status: 'Candidate is ready to join immediately'
  },
  {
    note: 'Note 1',
    date: 'Apr 22, 2021 | 12:30 PM',
    status: 'Candidate is ready to join immediately'
  },
];

export const User_Profile_Card_Data = [
  {
    name: 'Experience',
    value: '04 - 06 Years',
  },
  {
    name: 'Employment',
    value: 'Full Time',
  },
  {
    name: 'Salary',
    value: '10 - 12 Lakh per annum',
  },
  {
    name: 'Education',
    value: 'UG Qualification',
  },
];
