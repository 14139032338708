import * as React from 'react';
import { makeStyles, styled } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Link,
  Typography,
  Grid,
  LinearProgress,
  Divider,
} from '@material-ui/core';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

const useStyle = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0.5rem'
  },
  cardContent: {
    padding: '1rem'
  },
  card: {
    marginTop: '15px',
    border: '1px solid #E4E4E4',
    background: '#fafafa',
    borderRadius: 4,
  },
  link: {
    fontSize: '12px',
    padding: '5px'
  },
  barTitle: {
    fontSize: '12px',
    textAlign: 'end',
    marginRight: '7px'
  },
  tableText: {
    marginBottom: '1rem'
  }
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 0,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#FFBEA3'
  }
}));

const profileStatsData = [
  {
    title: '10+ Years',
    percent: '82',
    value: '500K'
  },
  {
    title: '5-10 Years',
    percent: '82',
    value: '600K'
  },
  {
    title: '3-5 Years',
    percent: '32',
    value: '700K'
  },
  {
    title: '1-3 Years',
    percent: '55',
    value: '800K'
  },
  {
    title: '1 Years',
    percent: '72',
    value: '900K'
  },
  {
    title: 'Fresher',
    percent: '32',
    value: '1000K'
  }
];

const topCompany = [
  {
    title: 'Google',
    percent: '80'
  },
  {
    title: 'Accenture',
    percent: '65'
  },
  {
    title: 'Pinterest',
    percent: '52'
  },
  {
    title: 'Twitter',
    percent: '42'
  },
  {
    title: 'Accenture',
    percent: '82'
  },
  {
    title: 'TCS',
    percent: '32'
  }
];

const keySkill = [
  {
    title: 'Indore',
    percent: '80'
  },
  {
    title: 'Mumbai',
    percent: '65'
  },
  {
    title: 'Bangalore',
    percent: '52'
  },
  {
    title: 'Pune',
    percent: '42'
  },
  {
    title: 'Delhi',
    percent: '82'
  },
  {
    title: 'Chennai',
    percent: '92'
  }
];

const ProfileStats = ({
  sectionDataPath
}) => {
  // eslint-disable-next-line no-unused-vars
  const sectionData = useSelector(state => pathOr([], sectionDataPath, state));
  const classes = useStyle();

  return (
    <>
      <Card elevation={0}
        className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.tableText}>
                Average salary by years of experience
              </Typography>
            </Grid>
          </Grid>

          {profileStatsData.map(bar => (
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={3} >
                <Typography className={classes.barTitle}>{bar.title}</Typography>
              </Grid>

              <Grid item xs={9} className={classes.barGrid}>
                <BorderLinearProgress variant="determinate" value={bar.percent}
                />
              </Grid>
            </Grid>
          ))}
          <Divider />
          <Grid container justifyContent='space-evenly' >
            {profileStatsData.map(bar => (
              <span style={{ fontSize: '10px', marginLeft: '5px' }}>
                {bar.value}
              </span>
            ))}
          </Grid>
        </CardContent>
      </Card>

      <Card elevation={0} className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.tableText}>
                  Top Companies hiring candidates like you
                </Typography>
              </Grid>
            </Grid>

            {topCompany.map(bar => (
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={3}>
                  <Typography className={classes.barTitle}>
                    {bar.title}
                  </Typography>
                </Grid>

                <Grid item xs={9} className={classes.barGrid}>
                  <BorderLinearProgress
                    variant="determinate"
                    style={{ Color: 'red' }}
                    value={bar.percent}
                  />
                </Grid>
              </Grid>  
            ))}
          </div>
        </CardContent>
      </Card>

      <Card elevation={0}
        className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={0}>
            <Grid item xs={7}>
              <Typography variant="h6" className={classes.tableText}>
              Top Locations
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ textAlign: 'right' }}>
              <Link variant='text' className={classes.link} >Countries</Link>
              <Link variant='text' className={classes.link} >Cities</Link>
            </Grid>
          </Grid>

          {keySkill.map(bar => (
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={3} >
                <Typography className={classes.barTitle}>{bar.title}</Typography>
              </Grid>

              <Grid item xs={9} className={classes.barGrid}>
                <BorderLinearProgress variant="determinate" value={bar.percent}
                />
              </Grid>
            </Grid>
          ))}
        </CardContent>
      </Card>
    </>
  );
};
export default ProfileStats;