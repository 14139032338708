import { createAsyncThunk } from '@reduxjs/toolkit';
import { addJobTitle,getJobTitleList,editJobTitle,deleteJobTitle, getAllJobTitleList, getAllJobTitleLocation, getAllJobTitles, getAIJobDescription } from 'redux/restAPIs/employerAPI/jobTitle';
import { getInterviewersList, getHiringManagerList } from 'redux/restAPIs/employerAPI/jobPosting';
import { ADD_JOB_TITLE_SUCCESS, GET_ALL_JOB_TITLE_SUCCESS,EDIT_JOB_TITLE_SUCCESS,DELETE_JOB_TITLE_SUCCESS, GET_AI_JOB_DESCRIPTION } from 'constants';
import { EmployerActions } from 'redux/actions/actionTypes';

export const onAddJobTitle = createAsyncThunk(
  ADD_JOB_TITLE_SUCCESS,
  async (payload) => {
    const response = await addJobTitle(payload);
    return response.data;
  }
);

export const onEditJobTitle = createAsyncThunk(
  EDIT_JOB_TITLE_SUCCESS,
  async (payload) => {
    const response = await editJobTitle(payload);
    return response.data;
  }
);

export const onGetJobTitleList = createAsyncThunk(
  GET_ALL_JOB_TITLE_SUCCESS,
  async (payload) => {
    const response = await getJobTitleList(payload);
    return response.data;
  }
);

export const onDeleteJobTitle = createAsyncThunk(
  DELETE_JOB_TITLE_SUCCESS,
  async (payload) => {
    const response = await deleteJobTitle(payload);
    return response.data;
  }
);

export const onGetAllJobTitle = createAsyncThunk(
  GET_ALL_JOB_TITLE_SUCCESS,
  async (payload) => {
    const response = await getAllJobTitleList(payload);
    return response.data;
  }
);

export const onGetAllInterviewers = createAsyncThunk(
  GET_ALL_JOB_TITLE_SUCCESS,
  async (payload) => {
    const response = await getInterviewersList(payload);
    return response.data;
  }
);

export const onGetAllHiringManagers = createAsyncThunk(
  GET_ALL_JOB_TITLE_SUCCESS,
  async (payload) => {
    const response = await getHiringManagerList(payload);
    return response.data;
  }
);


export const onGetAllJobLocation = createAsyncThunk(
  GET_ALL_JOB_TITLE_SUCCESS,
  async (payload) => {
    const response = await getAllJobTitleLocation(payload);
    return response.data;
  }
);
export const onGetAllJobTitleList = createAsyncThunk(
  EmployerActions.GET_ALL_JOB_TITLES,
  async (payload) => {
    const response = await getAllJobTitles(payload);
    return response.data;
  }
);
export const onGetAIJobDescription = createAsyncThunk(
  GET_AI_JOB_DESCRIPTION,
  async (payload) => {
    const response = await getAIJobDescription(payload);
    return response.data;
  }
);
