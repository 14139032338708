import React from 'react';
import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { CellRendererMap } from './CellRendererMap';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '&& .MuiButtonBase-root':{
      marginRight: '10px'
    }
  },
  container: {
    maxHeight: 440,
  },
  head: {
    backgroundColor: '#E1E0FF',
  },
  iconBtn: {
    cursor: 'pointer',
  },
  // imp*
  noRecordFound1: {
    padding: '1rem 0', zIndex: 999, display: 'block', position: 'sticky', left: '45%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  //* imp
  circularProgress: {
    margin: '3% 35%', display: 'flex', zIndex: '1', position: 'absolute'
  },
}));

export default function DPTable(props) {
  const { columns, rowsData, isLoading, setPageNumber, setPageSize, onRowClick, totalElements } = props;

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setPageSize(+event.target.value);
  };

  return (
    <Paper className={classes.root} key={`${props.id}_root`} elevation={0}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" key={`${props.id}_table`}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }} className={classes.head}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading && (
            <div>
              <CircularProgress color="secondary" className={classes.circularProgress} />
            </div>
          )}

          <TableBody>
            {isEmpty(rowsData) && <TableRow className={classes.noRecordFound1}> No records found </TableRow>}
            {rowsData?.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code} onClick={() => onRowClick(row)}>
                  {columns.map((column) => {
                    const { cellRenderer = 'defaultCellRenderer', rendererProps } = column;
                   
                    const Renderer = CellRendererMap[cellRenderer];
                    return (
                      <TableCell key={column.id} align={column.align} style={{ overflowWrap: 'anywhere' }}>
                        <Renderer column={column} row={row} {...props} {...rendererProps} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalElements || rowsData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

// columns
