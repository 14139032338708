import React, { useEffect } from 'react';
import {
  Typography, Grid, Card, CardContent, Avatar, IconButton, Button, Box, CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { DatePicker } from '@material-ui/pickers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import round2 from 'asset/icons/round2.svg';
import round1 from 'asset/icons/round1.svg';
import round3 from 'asset/icons/round3.svg';
import graph from 'asset/images/graph.svg';
import moment from 'moment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { onCustomMySchedule } from 'redux/actions/employerActions/interviewScheduled';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '130vh',
    background: 'linear-gradient(172.7deg, #FBF5FA 0%, #F5F2F8 54.17%, #F4F8FD 100%)',
  },
  main:{
    padding: '16px 30px 30px', 
    marginTop: '3rem'
  },
  titleBasicDetail: {
    display: 'initial',
    ...theme.typography.h5,
    color: '#555555', // theme.palette.common.gray4,
  },
  profileContainer: {
    background: 'linear-gradient(172.7deg, #FBF5FA 0%, #F5F2F8 54.17%, #F4F8FD 100%)',
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContent: {
    padding: '10px',
  },
  card: {
    marginTop: '15px',
    borderRadius: 4,
    boxShadow: '0px 2px 6px rgb(0 0 0 / 5%)',
    height: 'auto',
    minHeight: '110px',
    lineHeight: '18px'
  },
  roundLabel: {
    alignSelf: 'baseline'
  },
  iconBtn: {
    background: '#fff',
    margin: '0 1rem',
  },
  mobIconBtn: {
    marginLeft: 0
  },
  scheduleContainer: {
    maxHeight: '500px',
    minHeight: '200px',
    overflowX: 'hidden',
    overflowY: 'auto'
  }
}));


const blankJobPosting= {
  noActiveJob: false,
};



const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsloading] = useState(true);
  const [filter, setfilter] = useState({
    next: false, prev: 'Today', startDate: moment().format('YYYY-MM-DDT00:00'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DDT00:00')
  });
  const [mySchedule, setMySchedule] = useState([]);
  const myScheduleData = useSelector(state => state.employerDashboard.employerMySchedule);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIsloading(true);
    const data = {
      startDate: filter.startDate,
      endDate: filter.endDate
    };
    dispatch(onCustomMySchedule(data));
  }, [filter]);
  
  useEffect(() => {
    if (!isEmpty(myScheduleData)) {
      setMySchedule(myScheduleData.interviewDetails);
      setIsloading(false);
    }
  }, [myScheduleData]);

  const roundImg=(round)=>{
    return {
      1:<img src={round1} className={classes.roundLabel} />,
      2:<img src={round2} className={classes.roundLabel} />,
      3:<img src={round3} className={classes.roundLabel} />
    }[round];
  };
  const next=()=> {
    setfilter({ 
      next: 'Tomorrow', 
      prev: false, 
      startDate: moment().add(1, 'days').format('YYYY-MM-DDT00:00'), 
      endDate: moment(moment().add(2, 'days')).format('YYYY-MM-DDT00:00')
    });
  };

  const prev=() => {
    setfilter({ 
      next: false, 
      prev: 'Today', 
      startDate: moment().format('YYYY-MM-DDT00:00'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DDT00:00')
    });
  };

  const getFullName =(f, l) => {
    return `${f} ${l}`;
  };

  return (
    <div className={classes.root}>
      {
        blankJobPosting.noActiveJob ? (
          <ProfileContainer className={classes.profileContainer}>
            <Typography
              align="left"
              variant="h5"
              className={classes.titleBasicDetail}>
          My Schedule
            </Typography>
            <ProfileImageContainer>
              <DatePicker
                orientation="landscape"
                variant="static"
                openTo="date"
                disableToolbar="false" 
                maxDate={moment()}
              />
            </ProfileImageContainer>
          </ProfileContainer>
        ) : (
          <>
            <Grid container xs={12} className={classes.main}>
              <Grid item xs={5}>
                <Typography
                  align="left"
                  variant="h5"
                  className={classes.titleBasicDetail}>
                    My Schedule
                </Typography>
              </Grid>
              <Grid item xs={7} style={{ textAlign: 'end' }}>
                <IconButton aria-label="left arrow" size="small" component="span" className={clsx(classes.iconBtn, classes.mobIconBtn)} disabled={filter.prev}>
                  <ArrowBackIcon onClick={prev} />
                </IconButton>
                <Typography
                  align="center"
                  variant="h6" style={{ display: 'contents' }}>
                  {filter.next || filter.prev}
                </Typography>
                <IconButton aria-label="right arrow" size="small" component="span" className={classes.iconBtn} style={{ marginRight: 0 }} disabled={filter.next}>
                  <ArrowForwardIcon onClick={next} />
                </IconButton>
              </Grid>
              <Grid item xs={12} className={clsx(classes.scheduleContainer, { [classes.gridContainer]: isLoading })}>
                { !isLoading ?
                  mySchedule.length ? mySchedule.map(({ applicantDetail,interviewDetail,jobDetail }) => (
                    <Card elevation={0}
                      className={classes.card}>
                      <CardContent className={classes.cardContent} style={{ paddingBottom: '10px' }}>
                        <Grid container spacing={0} className={classes.gridContainer}>
                          <Grid item xs={3} style={{ borderRight: '1px solid #cccccc', paddingRight: '3px' }}>
                            <span style={{ fontSize: '20px' }}><b>{moment(interviewDetail?.startTime).format('D')}</b></span> <span style={{ fontSize: '11px' }}>{moment(interviewDetail?.startTime).format('MMM')}, {moment(interviewDetail?.startTime).format('YYYY')}</span><br />
                            <span style={{ fontSize: '12px' }}><b>{moment(interviewDetail?.startTime).format('h:mm a')}</b></span>
                          </Grid>
                          <Grid item xs={9} style={{ display: 'flex' }}>
                            <Box>
                              <Avatar style={{ margin: '0 7px', width:'30px', height:'30px' }} />
                            </Box>
                            <Box style={{ width: '100%' }}>
                              <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Box>
                                  <Typography variant="h6">                            
                                    {getFullName(applicantDetail?.firstName, applicantDetail?.lastName)}
                                  </Typography>
                                </Box>
                                {interviewDetail.status === 'REVIEW_PENDING' ? (
                                  <Box><span style={{ fontSize: '11px' }}>Pending</span></Box>
                                ) : (
                                  <Box>
                                    {roundImg(interviewDetail?.roundNo)}
                                  </Box>
                                )}
                              </Box>
                              <Box>
                                <Typography variant="body1" style={{ fontSize: '13px' }}>
                                  {jobDetail?.position}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))
                    : 
                    <Card elevation={0}
                      className={classes.card}>
                      <CardContent className={classes.gridContainer}>
                        <Grid container spacing={0} className={classes.gridContainer}>
                          <Grid item xs={9} style={{ display: 'flex' }}>
                            <Box style={{ width: '100%' }}>
                              <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                No interviews scheduled
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  :
                  <CircularProgress color="secondary" className={classes.circularProgress} />   
                }
              </Grid>
              <Grid item container xs={12} style={{ marginTop: '1rem' }}>
                <Grid item xs={8} style={{ alignSelf: 'center' }}>
                  <Typography
                    align="center"
                    variant="body2"
                    style={{ textAlign: 'left' }}>
                      Statistics of Active Applications
                  </Typography>
                </Grid>
                <Grid item xs={4}style={{ textAlign: 'right' }} >
                  <Button variant='text' color="secondary" style={{ fontSize: '16px' }}
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleMenuClick}
                    endIcon={<ArrowDropDownIcon />}
                  >
                      Actions
                  </Button>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={handleClose}>Weekly</MenuItem>
                    <MenuItem onClick={handleClose}>Monthly</MenuItem>
                    <MenuItem onClick={handleClose}>Yearly</MenuItem>
                  </Menu>
                </Grid>
                <Grid item xs={12}>
                  <img src={graph} alt="graph" style={{ marginTop: '1rem', width: '100%', objectFit:'contain', objectPosition:'center' }} />
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      }
    </div>
  );
};

const ProfileContainer = styled.div`
  padding: 1rem;
`;

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  background-color: #fff
`;
export default React.memo(Profile);
