const KEYS = {
  locations: 'locations',
  id: 'id'
};
const defaultData = [
  {
    id: '1',
    name: 'Pinterest',
    address: '389 RanchView Dr Richardson62639',
    city: 'Chicago',
    zipcode: '91331',
    state: 'illinos',
    country: 'United State',
  },
  {
    id: '2',
    name: 'Pinterest',
    address: 'Richmond Circle',
    city: 'Bengaluru',
    zipcode: '570001',
    state: 'Karnataka',
    country: 'India',
  },
];
export function insertCompanyLocation(data) {
  let locations = getAllCompanyLocations();
  data['id'] = generateLocationId();
  locations.push(data);
  localStorage.setItem(KEYS.locations, JSON.stringify(locations));
}

export function deleteCompanyLocation(id) {
  let locations = getAllCompanyLocations();
  locations = locations.filter(x => x.id != id);
  localStorage.setItem(KEYS.locations, JSON.stringify(locations));
}

export function updateCompanyLocation(data) {
  let locations = getAllCompanyLocations();
  let recordIndex = locations.findIndex(x => x.id == data.id);
  locations[recordIndex] = { ...data };
  localStorage.setItem(KEYS.locations, JSON.stringify(locations));
}

export function generateLocationId() {
  if (localStorage.getItem(KEYS.id) == null) {
    localStorage.setItem(KEYS.id, '0');
  }
  let id = parseInt(localStorage.getItem(KEYS.id));
  localStorage.setItem(KEYS.id, (++id).toString());
  return id;
}

export function getAllCompanyLocations() {
  if (localStorage.getItem(KEYS.locations) == null) {
    localStorage.setItem(KEYS.locations, JSON.stringify(defaultData));
  }
  let locations = JSON.parse(localStorage.getItem(KEYS.locations));
  return locations; 
}