import * as React from 'react';
import { Grid, Box, CardContent, Typography, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as R from 'ramda';
import { Descision_data } from 'mockData/RecommendedJobsData';


const useStyle = makeStyles(theme => ({
  title: {
    '&.MuiTypography-root': {
      color: 'gray',
      fontWeight: '300',
      lineHeight: '27px',
      fontSize: '18px'
    },
  },
  headerCard: {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  },
  cardContent: {
    marginTop: '5px',
  },
  number: {
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '45px',
    color: '#24283C'
  },
  headerCards: {
    width: '100%',
    [theme.breakpoints.only('md')]: {
      margin: '4rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4rem',
    },
  },
}));

const HeaderCards = ({ headerData }) => {
  const classes = useStyle();
  let data = headerData?.length? headerData : Descision_data;
  return (
    <Box style={{ width: '100%' }}>
      <Grid container spacing={2} className={classes.headerCards}>
        {
          R.map((headercardItem) => {
            return (
              <Grid item xs={12} md={4} lg={4}>
                <Card className={classes.headerCard}>
                  <CardContent className={classes.cardContent}>
                    <Grid container>
                      <Grid item xs={3}>
                        <img src={headercardItem.icon} style={{ width: '40px', height: '40px' }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography className={classes.title}>
                          {headercardItem.title}
                        </Typography>
                        <Typography className={classes.number}>
                          {headercardItem.number}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })(data)
        }
      </Grid>
    </Box>
  );
};

export default HeaderCards;