import React from 'react';
import UserProfileWrapper from 'view/common/wrappers/UserProfileWrapper';
import { UserForms } from 'view/common/utils/userProfileUtil';
import { useLocation } from 'react-router-dom';

const Home = () => {
  const location = useLocation();
  return (
    <>
      <UserProfileWrapper
        renderer={UserForms.employerHome}
        role={location.state.role} />
    </>
  );
};

export default Home;