import React from 'react';
import { Box, Chip, Typography, Button } from '@material-ui/core';
import { isEmpty, map, filter } from 'ramda';
import { makeStyles } from '@material-ui/styles';
import { pathOr } from 'ramda';

const useStyle = makeStyles(() => ({
  typeSpace: {
    padding: '0.8rem 0',
  },
  chip: {
    backgroundColor:'#6361CD', color: '#ffffff', margin: '0.6rem'
  },
}));

const SkillDetailsView = ({ formData, metaData }) => {
  const classes = useStyle();
  const skills = pathOr([], ['skillDetails','skills'], formData);
  const { setFormView, setDefaultSkillDetailsOpen } = metaData;
  const mentorShips = pathOr([], ['skillDetails','mentorships'], formData);

  const onAddMore = (value) => {
    setFormView('editView');
    setDefaultSkillDetailsOpen(value);
  };
  return (
    <Box style={{ padding: '25px 30px 15px 30px' }}>
      <Typography className={classes.typeSpace}><b> Skills </b></Typography>
      {
        isEmpty(skills) ? <Button style={{ padding: '6px 0px' }} variant="text" color="secondary" onClick={() => onAddMore('skills')}>Add More</Button> : map((skillItem) => {
          return <Chip label={skillItem.skill} className={classes.chip} />;
        }, filter(item => !item.deleted, skills))
      }
      <Typography className={classes.typeSpace}><b> Need Mentorship In </b></Typography>
      {
        isEmpty(mentorShips) ? <Button style={{ padding: '6px 0px' }} variant="text" color="secondary" onClick={() => onAddMore('mentorships')}>Add More</Button> :
          map((item) => {
            return <Chip label={item.mentorshipNeeds} className={classes.chip} />;
          }, filter(item => !item.deleted, mentorShips))
      }
    </Box>
  );
};

export default SkillDetailsView;