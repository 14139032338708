import React from 'react';
import { Grid, Box, makeStyles, Typography, AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import question from 'asset/icons/accQuestion.svg';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title
  },
  accordian: {
    background: '#EEF3F9',
    border: '1px solid #6361CD',
    boxShadow: 'none',
    borderRadius: '50px'
  },
  '&.Mui-expanded': {
    margin: '10px 0'
  },
  accordDetails: {
    background: 'white',
    marginTop: '1rem',
    padding: '28px',
    borderRadius: 4,
    display: 'block'
  }
}));

const DPOtherDetails = ({ data, children }) => {
  const classes = useStyle();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: '1rem' }}>
          <Accordion style={{ background: 'transparent', boxShadow: 'none' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary className={classes.accordian} disableElevation expandIcon={<ArrowDropDownIcon fontSize="large" color="secondary" />} aria-controls="panel1a-content" id="panel1a-header">
              <img src={question} alt="pinterest Icon" style={{ width: '35px', marginRight: '0.5rem' }} />
              <Typography variant="h6" style={{ alignSelf: 'center' }}>
                <b>{data.name}</b>
              </Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.accordDetails}>{children}</AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};
export default DPOtherDetails;
