import React from 'react';
import { Button, Card, CardContent, Grid, IconButton, Box, Typography, makeStyles } from '@material-ui/core';
import LocationOnIcon from 'asset/Location.svg';
import WorkOutlineIcon from 'asset/JobsIcon.svg';
import AttachMoneyIcon from 'asset/RupeeIcon.svg';
import HighlightOffRoundedIcon from 'asset/CloseIcon.svg';
import Pinterest from 'asset/icons/pinterest.svg';

const useStyle = makeStyles(() => ({
  gridContainer: {
    marginTop: '0.4rem',
  },
  item: {
    display: 'flex',
    margin: '5px 0'
  },
  company: {
    paddingLeft: '0.5rem',
  },
  smIconBtn: {
    display: 'flex',
    padding: 0,
    marginRight: '1rem'
  },
}));

const JobCard = ({ job }) => {
  const classes = useStyle();
  return (
    <Box sx={{ marginTop: '10px' }} enableReinitialize>
      <Card elevation={0}>
        <CardContent>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" >
            <Grid item xs={1}>
              <IconButton fontSize='large' className={classes.smIconBtn}>
                <img src={Pinterest} alt='pinterest Icon' />
              </IconButton>
            </Grid>
            <Grid item xs={9} style={{ paddingLeft: '1.2rem' }}>
              <Typography variant="h6">
                <b>{job?.jobTitle}</b>
                <Typography variant="subtitle2">{job?.jobLocation}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'end', alignSelf: 'baseline' }}>
              <IconButton style={{ padding: 0 }}>
                <img alt="Forward" src={HighlightOffRoundedIcon} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} className={classes.item}>
              <img alt="Forward" src={WorkOutlineIcon} />
              <Typography variant="subtitle2" className={classes.company}>{job?.experience}</Typography>
            </Grid>

            <Grid item xs={12} className={classes.item}>
              <img alt="Forward" src={AttachMoneyIcon} />
              <Typography variant="subtitle2" className={classes.company}>{job?.experience}</Typography>
            </Grid>

            <Grid item xs={12} className={classes.item}>
              <img alt="Forward" src={LocationOnIcon} />
              <Typography variant="subtitle2" className={classes.company}>{job?.jobLocation}</Typography>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '1rem' }}>
            <Grid item xs={6}>
              <Button size='small' variant="contained" color="secondary">
                Apply
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Button size='small' variant="outlined" color="secondary">
                Refer Job
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default JobCard;
