import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  background: '#FFFFFF',
  boxShadow: '0px 2px 6px rgb(0 0 0 / 5%)',
  borderRadius: '34px',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    margin: '1rem 0',
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '13px',
  lineHeight: '21px',
  width: '100%',
  '& .MuiInputBase-placeholder': {
    color: '#797D89',
  },

  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5, 1.5, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%'
      }
    }
  }
}));

const SearchField = () => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon style={{ color: '#797D89' }} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search by name"
        inputProps={{ 'aria-label': 'search' }}
      />
    </Search>
  );
};

export default SearchField;
