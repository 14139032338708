import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Typography, Box, Chip, Badge } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../ui-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../../../../themes/colors';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import editprofile from 'asset/icons/editprofile.svg';
import Pinterest from 'asset/icons/pinterest.svg';
import TwitterCircle from 'asset/icons/twittercircle.svg';
import LinkedinCircle from 'asset/icons/linkedincircle.svg';
import FbCircle from 'asset/icons/fbcircle.svg';
import Phone from 'asset/icons/phone.svg';
import Mail from 'asset/icons/openmail.svg';
import Location from 'asset/icons/location1.svg';
import Web from 'asset/icons/web.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.white,
    padding: '2em',
    marginTop: '1rem',
    borderRadius: 4,
  },
  chip: {
    margin: '0.15rem',
    backgroundColor: `${colors.purpule1}`,
  },
  spacing: {
    paddingBottom: '5px'
  },
  icon: {
    padding: '5px',
    cursor: 'pointer'
  },
  detailIcons: {
    width: '20px',
    margin: '5px',
  },
  info: {
    display: 'flex', margin: '1', alignItems: 'center', overflowWrap: 'anywhere'
  },
  sectionSpace: {
    marginBottom: '2rem'
  },
  detailGrid: {
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
    },
  },
  badge: {
    flexDirection: 'column',
    height: 'fit-content'
  },
}));

const ViewInfo = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const companyInfo = useSelector(state =>
    get(state, ['userProfile', 'employer'])
  );
  const [companyInformation, setCompanyInformation] = useState(companyInfo);
  const navigate = useNavigate();

  useEffect(async () => {
    setCompanyInformation(companyInfo);
  }, [companyInfo]);

  const onEditClick = () => {
    navigate('/employer/configuration/CompanyInfo');
  };

  return (
    <Grid container direction="column" className={classes.root} spacing={1}>
      <Grid container style={{ marginBottom: '2.5rem' }}>
        <Grid item xs={9} container direction="column">
          <Grid item>
            <Typography variant="h5">
              {translate(
                'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.HEADER.BASIC'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {translate(
                'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.HEADER.BASIC_DETAILS'
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'end' }}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={onEditClick}
            text="Edit"
          />
        </Grid>
      </Grid>

      <Grid item container direction={matchesSM ? 'row' : 'row'} style={{ marginBottom: '2.5rem' }}>
        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          spacing={1}
        >
          <Grid item style={{ display: 'flex' }}>
            <Badge
              className={classes.badge}
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <img src={editprofile} alt='edit profile icon' style={{ width: '30px' }} />
              }
            >
              <img src={Pinterest} alt='Pinterest icon' style={{ width: '110px' }} />
            </Badge>
            <Box style={{ paddingLeft: '1.5rem' }}>
              <Grid item className={classes.spacing}>
                <Typography variant="h6">{companyInformation.companyName}</Typography>
              </Grid>
              <Grid item className={classes.spacing} style={{ overflowWrap: 'anywhere' }}>
                {
                  get(companyInformation, 'profileCompleted', true) && (
                    <Typography>
                      {get(companyInformation, 'address[0].city', '')},
                      {get(companyInformation, 'address[0].state', '')},
                      {get(companyInformation, 'address[0].country', '')}
                    </Typography>
                  )
                }
              </Grid>
              <Grid item className={classes.spacing}>
                {get(companyInformation, 'companyIndustry', []).map(item => (
                  <Chip key={item.id} label={item.name} className={classes.chip} />
                ))}
              </Grid>
              <Grid item className={classes.spacing}>
                {get(companyInformation, 'socialMediaAccounts', []).map(
                  (media) => {
                    if (media.name == 'TWITTER') {
                      return (
                        <img src={TwitterCircle} alt="twitter icon" className={classes.icon} onClick={() => (window.location.href = media.link)} />
                      );
                    } else if (media.name == 'FACEBOOK') {
                      return (
                        <img src={FbCircle} alt="fb icon" className={classes.icon} onClick={() => (window.location.href = media.link)} />
                      );
                    } else if (media.name == 'LINKEDIN') {
                      return (
                        <img src={LinkedinCircle} alt="linkedin icon" className={classes.icon} onClick={() => (window.location.href = media.link)} />
                      );
                    }
                    return '';
                  }
                )}
              </Grid>
            </Box>
          </Grid>

        </Grid>
        <Grid container item xs={12} md={6} direction="column" spacing={1} className={classes.detailGrid}>
          <Grid
            item
            container
            direction='row'
            spacing={matchesSM ? 1 : 4}
          >
            <Grid item xs={12} md={6} className={classes.info}>
              <img src={Web} alt="web icon" className={classes.detailIcons} />
              <Typography variant="body2">{get(companyInformation, 'profileCompleted', true) && get(companyInformation, 'website', '')}</Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.info}>
              <img src={Mail} alt="mail icon" className={classes.detailIcons} />
              <Typography variant="body2">{get(companyInformation, 'profileCompleted', true) && get(companyInformation, 'email', '')}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction='row'
            spacing={matchesSM ? 1 : 4}
          >
            <Grid item xs={12} md={6} className={classes.info}>
              <img src={Location} alt="Location icon" className={classes.detailIcons} />
              {
                get(companyInformation, 'profileCompleted', true) && (
                  <Typography variant="body2">
                    {get(companyInformation, 'address[0].city', '')},
                    {get(companyInformation, 'address[0].state', '')},
                    {get(companyInformation, 'address[0].country', '')}
                  </Typography>
                )
              }
            </Grid>
            <Grid item xs={12} md={6} className={classes.info}>
              <img src={Phone} alt="phone icon" className={classes.detailIcons} />
              <Typography variant="body2">
                {get(companyInformation, 'profileCompleted', true) && get(companyInformation, 'phone', '')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="h5">
          {translate(
            'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.EMPLOYER_CONTACT_FIELD.LABEL'
          )}
        </Typography>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} md={4} container direction="column" >
          <Grid item>
            <Typography variant="subtitle1">
              {translate(
                'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.PRIMARY_CONTACT_PERSON_FIELD.LABEL'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {get(companyInformation, 'contactDetails[0].name', '')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} container direction="column">
          <Grid item>
            <Typography variant="subtitle1">
              {translate(
                'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.MAIL_FIELD.LABEL'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {get(companyInformation, 'profileCompleted', true) && get(companyInformation, 'contactDetails[0].email', '')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} container direction="column">
          <Grid item>
            <Typography variant="subtitle1">
              {translate(
                'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.MOB_FIELD.LABEL'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {get(companyInformation, 'profileCompleted', true) && get(companyInformation, 'contactDetails[0].phone', '')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: '1.5rem' }}>
        <Typography variant="h5">
          {translate(
            'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.OTHER_DETAIL_HEADER.LABEL'
          )}
        </Typography>
      </Grid>
      <Grid item className={classes.sectionSpace}>
        <Typography variant="subtitle1">
          {translate(
            'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.ABOUT_CMPNY_FIELD.LABEL'
          )}
        </Typography>
        <Typography>{get(companyInformation, 'about', '')}</Typography>
      </Grid>
      <Grid item className={classes.sectionSpace}>
        <Typography variant="subtitle1">
          {translate(
            'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.MISSION_FIELD.LABEL'
          )}
        </Typography>
        <Typography>{get(companyInformation, 'mission', '')}</Typography>
      </Grid>
      <Grid item className={classes.sectionSpace}>
        <Typography variant="subtitle1">
          {translate(
            'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.VALUE_OF_CMPNY_FIELD.LABEL'
          )}
        </Typography>
        <Typography>{get(companyInformation, 'companyValues', '')}</Typography>
      </Grid>
      <Grid item style={{ marginBottom: '0.5rem' }}>
        <Typography variant="subtitle1">
          {translate(
            'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.EMP_BENIFIT_FIELD.LABEL'
          )}
        </Typography>
      </Grid>

      <Grid item container>
        {get(companyInformation, 'employeeBenefits', []).map(
          (benifit) => {
            return (
              <Grid item xs={12} md={3} key={benifit}>
                <ul>
                  <li> {benifit.name} </li>
                </ul>
              </Grid>
            );
          }
        )}
      </Grid>
    </Grid>
  );
};

export default ViewInfo;