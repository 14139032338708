import React, { useEffect, useState } from 'react';
import { Button, Menu, Breadcrumbs, Select, MenuItem, Box } from '@material-ui/core';
import { Typography, Tab, Tabs, makeStyles, Grid } from '@material-ui/core';
import * as R from 'ramda';
import DPTable from 'view/common/DPTable';
import { useDispatch, useSelector } from 'react-redux';
import { EANestedTabWiseList, EATabColumns, TABS } from 'constants/employer/employerActivity';
import { onGetAllJobs, onGetCandidates, onGetCandidatesRecommended, onGetShortlistedCandidates } from 'redux/actions/employerActions/employerDashboard';
import SearchBar from 'material-ui-search-bar';
import ActivityFilter from './ActivityFilter';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ActivityCard from './ActivityCard';
import { findIndex, get } from 'lodash';
import { Alert } from '@mui/material';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
  },
  searchBar: {
    width: '100%',
    borderRadius: 40,
    height: 40,
    boxShadow: 'none',

  },
  pageTitleContainer: {
    marginBottom: '1rem'
  },
  mainContainer: {
    paddingTop: '3em',
    paddingLeft: '10em',
    paddingRight: '10em',
    paddingBottom: '10em',
    [theme.breakpoints.down('md')]: {
      marginTop: '3em',
      paddingLeft: '2em',
      paddingRight: '2em',
      paddingBottom: '5em',
    },
  },
}));

const getActiveTabId = (tabId) => {
  if (tabId) {
    return findIndex(EANestedTabWiseList, item => item.id === tabId);
  }
  return 0;
};

const Activity = () => {
  const params = useParams();
  const location = useLocation();
  const classes = useStyle();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(getActiveTabId(get(location, ['state', 'tabId'], 'recommended'))); const [, setValue] = useState('');

  const tableData = useSelector((state) => {
    return get(state, ['employerDashboard', 'candidatesData'], []);
  });

  const shortListed = useSelector((state) => {
    return get(state, ['employerDashboard', 'shortListed'], false);
  });

  const jobDetails = useSelector(state => get(state, ['applicantDashboard', 'jobDetails'], []));
  const [pageNumberRecommended, setPageNumberRecommended] = useState(0);
  const [pageSizeRecommended, setPageSizeRecommended] = useState(10);

  const [pageNumberApplied, setPageNumberApplied] = useState(0);
  const [pageSizeApplied, setPageSizeApplied] = useState(10);

  const [pageNumberShortlisted, setPageNumberShortlisted] = useState(0);
  const [pageSizeShortlisted, setPageSizeShortlisted] = useState(10);


  const dispatch = useDispatch();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
    setPageNumberRecommended(0);
    setPageNumberShortlisted(0);
    setPageNumberApplied(0);
  };

  const handleChange1 = (event) => {
    setValue(event.target.value);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(onGetAllJobs());
  }, []);

  useEffect(() => {
    switch (TABS[currentTab]?.Type) {
    case 'NEW':
      dispatch(onGetCandidatesRecommended(`?jobId=${params.jobId}&page=${pageNumberRecommended}&size=${pageSizeRecommended}&sortBy=id`));
      break;
    case 'APPLIED':
      dispatch(onGetCandidates(`/${params.jobId}/${TABS[currentTab].Type}?pageNo=${pageNumberApplied}&pageSize=${pageSizeApplied}&sortBy=id`));
      break;
    case 'SHORTLISTED':
      dispatch(onGetShortlistedCandidates(`/${params.jobId}/${TABS[currentTab].Type}?pageNo=${pageNumberShortlisted}&pageSize=${pageSizeShortlisted}&sortBy=id`));
      break;
    }
  }, [pageNumberRecommended, pageSizeRecommended, pageNumberApplied, pageSizeApplied, pageNumberShortlisted, pageSizeShortlisted, currentTab, shortListed]);


  const onRowClick = (row) => {
    navigate(`/employer/activity/${params.jobId}/${get(row, ['applicant', 'id'])}`);
  };

  const getPageNumber = () => {
    switch (TABS[currentTab]?.Type) {
    case 'NEW':
      return setPageNumberRecommended;
    case 'APPLIED':
      return setPageNumberApplied;
    default:
      return setPageNumberShortlisted;
    }
  };

  const getPageSize = () => {
    switch (TABS[currentTab]?.Type) {
    case 'NEW':
      return setPageSizeRecommended;
    case 'APPLIED':
      return setPageSizeApplied;
    default:
      return setPageSizeShortlisted;
    }
  };

  return (
    <Grid container direction="column" className={classes.mainContainer} spacing={1}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="secondary"
            to="/employer/jobposting"
            className={`${classes.linkLabel} MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary`}>
            Job Posting
          </Link>
          <Typography className={classes.label}>
            Activity
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container spacing={0} className={classes.pageTitleContainer} display="flex">
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography className={classes.pageTitle}>Activity</Typography>
          <Box>
            <Select
              style={{ width: '300px', border: '1px solid #6361CD', color: '#6361CD' }}
              variant='outlined'
              id="demo-simple-select"
              value={params.jobId}
              label='Job Id'
              labelId="demo-simple-select-label"
              onChange={handleChange1}
            >
              <MenuItem value={params.jobId}>Job ID: {jobDetails.jobCode}</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ActivityCard applied={tableData['applied']?.totalElements} />
        </Grid>
      </Grid>
      {shortListed && (
        <Grid container spacing={0} className={classes.pageTitleContainer} alignItems="baseline">
          <Grid item xs={12} style={{ marginBottom: '1rem' }}>
            <Alert severity="success" >
              <div style={{ display: 'flex' }}>Shortlisted candidate Successfully!</div>
            </Alert>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example" >
            {
              R.map((tab) => {
                return <Tab label={tab.label} {...a11yProps(tab.id)} />;
              })(EANestedTabWiseList)
            }
          </Tabs>
        </Grid>
        <Grid item xs={3} md={1} style={{ textAlign: 'end', marginTop: '5px' }}>
          <Button
            onClick={handleOpen}
            color='secondary'
            type="button"
            variant="outlined"
            size='small'
          >Filters</Button>
          <Menu
            style={{ marginTop: '50px' }}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <ActivityFilter onClose={handleClose}
              currentTab={currentTab}
              formLabel={EANestedTabWiseList[currentTab]} />
          </Menu>
        </Grid>
        <Grid item xs={6} md={2} style={{ textAlign: 'end' }}>
          <SearchBar className={classes.searchBar} />
        </Grid>
      </Grid>
      <DPTable
        columns={EATabColumns[EANestedTabWiseList[currentTab].id]}
        rowsData={tableData[EANestedTabWiseList[currentTab].id]?.content}
        setPageNumber={getPageNumber()}
        setPageSize={getPageSize()}
        onRowClick={onRowClick}
        totalElements={tableData[EANestedTabWiseList[currentTab].id]?.totalElements}
      />
    </Grid>
  );
};
export default Activity;
