import { createAsyncThunk } from '@reduxjs/toolkit';
import { addJobPost, updateJobPost, updateJobPostStatus, setJobStaticQuestions, getJobStaticQuestions, setJobEmployeeParticipants, getJobEmployeeParticipants } from 'redux/restAPIs/employerAPI/jobPosting';
import { ADD_JOB_POST_SUCCESS } from 'constants/index';

export const onAddJobPost = createAsyncThunk(
  ADD_JOB_POST_SUCCESS,
  async (payload) => {
    const response = await addJobPost(payload);
    return response.data;
  }
);

export const onUpdateJobPost = createAsyncThunk(
  ADD_JOB_POST_SUCCESS,
  async (payload) => {
    const response = await updateJobPost(payload);
    return response.data;
  }
);

export const onChangeJobPostStatus = createAsyncThunk(
  ADD_JOB_POST_SUCCESS,
  async (payload) => {
    const response = await updateJobPostStatus(payload);
    return response.data;
  }
);

export const onSetJobStaticQuestion = createAsyncThunk(
  ADD_JOB_POST_SUCCESS,
  async (payload) => {
    const response = await setJobStaticQuestions(payload);
    response.savedStaticQuestions = response.data;
    response.staticQuestions = response.data;
    return response;
  }
);

export const onSetJobEmployeeParticipant = createAsyncThunk(
  ADD_JOB_POST_SUCCESS,
  async (payload) => {
    const response = await setJobEmployeeParticipants(payload);
    let resp ={};
    if (response.data && response.data.length) {
      resp.hiringManager = response.data.find(participant => participant.employeeType === 'HIRING_MANAGER');
      resp.savedHiringManager = response.data.find(participant => participant.employeeType === 'HIRING_MANAGER');
      resp.interviewerDetails = response.data.filter(participant => participant.employeeType === 'INTERVIEWER');
      resp.savedInterviewerDetails = response.data.filter(participant => participant.employeeType === 'INTERVIEWER');
    }
    return resp;
  }
);

export const onGetJobEmployeeParticipant = createAsyncThunk(
  ADD_JOB_POST_SUCCESS,
  async (payload) => {
    const response = await getJobEmployeeParticipants(payload);
    return response;
  }
);

export const onGetJobStaticQuestion = createAsyncThunk(
  ADD_JOB_POST_SUCCESS,
  async (payload) => {
    const response = await getJobStaticQuestions(payload);
    response.staticQuestions = response.data;
    return response;
  }
);
