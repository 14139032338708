import * as React from 'react';
import { Box, Button, InputLabel, Grid, Typography, Divider, Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, useFormik } from 'formik';
import { ExperienceMap, SalaryRange } from 'mockData/RecommendedJobsData';

const useStyle = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    padding: '20px',
    alignItems: 'center',
  },
  cardContainer: {
    margin: '1rem',
    backgroundColor: 'white'
  },
  spanText: {
    color: '#000',
    paddingLeft: '0.5rem'
  }
}));

function valuetext(value) {
  return `${value}`;
}

const INITIAL_VALUES = {
  0: {
    location: '',
    source: '',
  },
  1: {
    location: '',
    source: '',
    interviewStatus: '',
    education: '',
    noticeperiod: '',
    experience: '',

  },
  2: {
    location: '',
    source: '',
  },
  3: {
    location: '',
    source: '',
  },

};
const ActivityFilter = ({ onClose, currentTab }) => {
  const formik = useFormik({
    initialValues: INITIAL_VALUES[currentTab],
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    }
  });

  const onClearClick = (formik) => {
    formik.resetForm();
  };
  const classes = useStyle();
  const [value, setValue] = React.useState([20, 40]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const md = currentTab === 2 ? 4 : 6;
  // const width = currentTab === 2 ? 1300 : 900;
  // const margin = currentTab === 2 ? 1100 : 705;

  return (

    <Formik>
      <Box className={classes.cardContainer}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <Typography variant="h4">Filters</Typography>
              <Button
                style={{ marginLeft: '1rem' }}
                onClick={() => onClearClick(formik)}
                variant='text'
                color='secondary'
              >
                Clear All
              </Button>
            </Grid>

            <Divider />

            <Grid item xs={12} md={6}>
              <InputLabel> Location</InputLabel>
              <Select
                variant='outlined'
                id='location'
                name='location'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.location}
              >
                <MenuItem value={'UI designer'}>UI designer</MenuItem>
                <MenuItem value={'Angular developer'}>Angular developer</MenuItem>
                <MenuItem value={'Software Tester'}>Software Tester</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>Source</InputLabel>
              <Select
                variant='outlined'
                id='source'
                name='source'
                type='source'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.source}
              >
                <MenuItem value={'Teacher'}>Teacher</MenuItem>
                <MenuItem value={'hr'}>Hr</MenuItem>
                <MenuItem value={'developer'}>Developer</MenuItem>
              </Select>
            </Grid>
            {currentTab === 2 && (
              <Grid item xs={12} md={4}>
                <InputLabel>Interview Status</InputLabel>
                <Select
                  variant='outlined'
                  fullWidth
                  id='interviewStatus'
                  name='interviewStatus'
                  type='interviewStatus'
                  onChange={formik.handleChange}
                  value={formik.values.interviewStatus}
                >
                  <MenuItem value={'hr'} >Hr</MenuItem>
                  <MenuItem value={'Teacher'}>Teacher</MenuItem>
                  <MenuItem value={'Manager'}>manager</MenuItem>
                </Select>
              </Grid>
            )}
            <>
              {currentTab === 2 && (
                <Grid container spacing={6} className={classes.gridContainer} >
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">Notice Period
                    </Typography>
                    <Slider
                      getAriaLabel={() => 'Experience'}
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      marks={ExperienceMap}
                      color="secondary" />
                  </Grid>

                  <Grid item xs={12} md={4} >
                    <Typography variant="subtitle1">Experience
                    </Typography>
                    <Slider
                      getAriaLabel={() => 'Salary'}
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      marks={SalaryRange}
                      color="secondary" />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel> Education</InputLabel>
                    <Select
                      style={{ width: '410px', marginLeft: '-15px' }}
                      variant='outlined'
                      id='education'
                      name='education'
                      onChange={formik.handleChange}
                      value={formik.values.education}
                    >
                      <MenuItem value={'UI designer'}>UI designer</MenuItem>
                      <MenuItem value={'Angular developer'}>Angular developer</MenuItem>
                      <MenuItem value={'Software Tester'}>Software Tester</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              )}
            </>

            <Grid container xs={12} style={{ margin: '1rem 10px' }} >
              <Button
                variant="outlined"
                type="button"
                name="cancel"
                color='secondary'
                value="cancel"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button variant="contained" color='secondary' type="submit" style={{ marginLeft: '1rem' }}>Apply</Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Formik>

  );
};

export default ActivityFilter;