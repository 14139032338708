import { Dialog, Typography, Box, Button } from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessModal = ({ open, setOpen, title, subtitle }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ padding: '3rem', textAlign: 'center' }}>
        <CheckCircleIcon color="primary" sx={{ fontSize: '4rem' }} />
        <Typography variant="h5" gutterBottom component="div">{title}</Typography>
        <Typography variant="p" gutterBottom component="div">{subtitle}</Typography>
        <Button type="button" color="primary" variant="contained" onClick={handleClose}>Okay</Button>
      </Box>
    </Dialog>
  );
};

export default SuccessModal;