import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { onResendOtp, onVerfiyEmail } from 'redux/actions/employerActions/employerAuthentication';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  grid: {
    backgroundColor: 'white',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '10px',
    padding: '60px',
  },
  loginBtn: {
    backgroundColor: '#2754CE',
    '&:hover': {
      backgroundColor: '#6361CD',
    },
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 200,
    padding: '14px 16px',
    lineHeight: 1.5,
  },
  paper: {
    marginTop: '45%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  resendbtn: {
    '&.MuiButtonBase-root ': {
      color: '#797D89',
      width: '325px',
      marginTop: '20px',
    },
  },
}));

const VerifyOtpForm = ({ email, handleClose, type = 'OTP', setForgotPasswordOTP = null }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState('');
  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  // const handleClick = (otp) => { setOtp("") };

  const verifyOtp = async () => {
    try {
      const response = await dispatch(onVerfiyEmail({ otp, email }));
      const status = get(response, 'payload', '');
      if (status === 'Email Verified Successfully') {
        navigate('/employer/login', { state: { role: 'employer' } });
        toast.success('Registration completed successfully. Please login again to continue');
      }
      handleClose();
    } catch (err) {
      handleClose();
    }
  };

  const resendOtp = async () => {
    try {
      const response = await dispatch(onResendOtp({ email: email, type: 'SEND_OTP' }));
      const status = get(response, 'payload', '');
      if (status === 'OTP sent on email') {
        toast.success('OTP sent on email');
      }
    } catch (err) {
      handleClose();
    }
  };

  const processForgotPassword = () => {
    setForgotPasswordOTP(otp);
  };
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container className={classes.grid}>
          <Grid item>
            <Typography variant="h4" style={{ fontSize: '1.3rem', fontWeight: 600, lineHeight: '32px' }}>
              Verification Code
            </Typography>
            <Typography variant="subtitle1">Please check your email for the Verification Code</Typography>
          </Grid>
          <Grid item>
            <TextField style={{ marginTop: '25px' }} value={otp} variant="standard" onChange={handleChange} />
          </Grid>
          <Grid item>
            <Button size="small" onClick={resendOtp} className={classes.resendbtn}>
              Resend OTP
            </Button>
          </Grid>
          <Grid item>
            {type == 'FORGOT_PASSWORD' ? (
              <Button onClick={processForgotPassword} className={classes.loginBtn} color="primary" size="small" variant="contained" type="submit"> Verify and Change Password</Button>
            ) : (
              <Button onClick={verifyOtp} className={classes.loginBtn} color="primary" size="small" variant="contained" type="submit"> Verify OTP </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default VerifyOtpForm;
