import React from 'react';
import PercentageBar from './PercentageBar';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../themes/colors';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '0.75rem',
    color: colors.white,
    fontWeight: 'normal',
    textAlign: 'center',
    display: 'flex',
    padding: '8px',
    margin: '0px 36px',
    position: 'absolute',
  },
}));

const PercentageButton = ({ label, onClick, percent }) => {
  const classes = useStyles();
  return (
    <PercentButton onClick={onClick}>
      <Typography align="center" variant="h2" className={classes.label}>
        {label}
      </Typography>
      <PercentageBar percent={percent} height={30} variant={'rounded'} />
    </PercentButton>
  );
};

const PercentButton = styled.div`
  cursor: pointer;
  margin: 5px;
`;

export default PercentageButton;

PercentageButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  percent: PropTypes.number.isRequired,
};

PercentageButton.defaultProps = {
  label: '',
};
