import {
  Container,
  Grid,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  makeStyles,
  InputBase,
  TextField,
} from '@material-ui/core';
import { pathOr } from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import MaterialUiPhoneNumber from 'material-ui-phone-number';

const useStyle = makeStyles(() => ({
  select: {
    cursor: 'pointer',
    padding: '7px 12px 4px 12px',
    border: '1px solid rgb(206, 212, 218)',
    borderRadius: '4px',
  },
  selectdisabled: {
    padding: '7px 12px 4px 12px',
    border: '1px solid rgb(206, 212, 218)',
    borderRadius: '4px',
    // color: '#a2a2a2',
    fontWeight: 100,
  },
  menuitemhidden: {
    display: 'none',
  },
}));

const PersonalDetailsForm = ({ formik, id }) => {
  const profileData = useSelector(state =>
    pathOr('', ['userProfile', 'applicant'], state)
  );
  const classes = useStyle();

  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState('none');
  const [showPlaceholder, setShowPlaceholder] = useState(
    value === 'none'
  );

  return (
    <Container style={{ padding: '2rem' }} id={id}>
      <form onSubmit={formik.handelsubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputLabel required>First Name</InputLabel>
            <TextField
              autoComplete="current-password"
              id="firstName"
              variant="outlined"
              name="firstName"
              placeholder="Enter first name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.firstName ? formik.values.firstName : ''}
              fullWidth
              helperText={formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="TextField-input" required>
              Last Name
            </InputLabel>
            <TextField
              autoComplete="current-password"
              id="lastName"
              variant="outlined"
              name="lastName"
              placeholder="Enter last name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              fullWidth
              helperText={formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="TextField-input" required>
              Contact Number
            </InputLabel>
            <MaterialUiPhoneNumber id="contactNumber"
              variant="outlined"
              name="contactNumber"
              placeholder='Enter phone'
              autoComplete="off"
              type="tel"
              defaultCountry='in'
              onChange={value => formik.setFieldValue('contactNumber', value)}
              value={formik.values.contactNumber}
              fullWidth
              error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
              helperText={formik.touched.contactNumber && formik.errors.contactNumber} />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="TextField-input" required>
              Email
            </InputLabel>
            <TextField
              autoComplete="current-password"
              id="email"
              variant="outlined"
              name="email"
              placeholder="Enter email id"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.email}
              fullWidth
              helperText={formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="TextField-input" required>
              Address
            </InputLabel>
            <TextField
              autoComplete="current-password"
              id="address"
              variant="outlined"
              placeholder="Enter address"
              name="address"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.address}
              fullWidth
              helperText={formik.errors.address}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="TextField-input" required>
              Zipcode
            </InputLabel>
            <TextField
              autoComplete="current-password"
              id="zipcode"
              variant="outlined"
              placeholder="Enter zipcode"
              name="zipcode"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.zipcode ? formik.values.zipcode : null}
              fullWidth
              helperText={formik.errors.zipcode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="TextField-input" required>
              City
            </InputLabel>
            <TextField
              autoComplete="current-password"
              id="city"
              variant="outlined"
              name="city"
              placeholder="Enter city"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.city}
              fullWidth
              helperText={formik.errors.city}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="TextField-input" required>
              State
            </InputLabel>
            <TextField
              autoComplete="current-password"
              id="state"
              variant="outlined"
              name="stateResiding"
              placeholder="Enter state"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.stateResiding}
              fullWidth
              helperText={formik.errors.stateResiding}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="TextField-input" required>
              Country
            </InputLabel>
            <TextField
              autoComplete="current-password"
              id="country"
              variant="outlined"
              name="country"
              placeholder="Enter country"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.country}
              fullWidth
              helperText={formik.errors.country}
            />
          </Grid>

          {!profileData.fresher && (
            <>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <InputLabel htmlFor="TextField-input" required>
                      Experience
                    </InputLabel>
                    <TextField
                      autoComplete="current-password"
                      id="experienceCount"
                      variant="outlined"
                      name="experienceCount"
                      placeholder="Enter Experience"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.experienceCount ? formik.values.experienceCount : null}
                      fullWidth
                      helperText={formik.errors.experienceCount}
                    />
                  </Grid>

                  <Grid item xs={6} style={{ alignSelf: 'end' }}>
                    <FormControl fullWidth>
                      <Select
                        className={clsx(
                          classes.select,
                          value === 'none' ? classes.selectdisabled : null
                        )}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formik.values.experiencePeriod}
                        onChange={formik.handleChange}
                        onFocus={() => setShowPlaceholder(false)}
                        onClose={e =>
                          setShowPlaceholder(e.target.value === undefined)
                        }
                        variant="outlined"
                        defaultValue="none"
                        input={<InputBase />}
                        fullWidth
                        name="experiencePeriod"
                      >
                        <MenuItem
                          className={clsx(
                            !showPlaceholder ? classes.menuitemhidden : null
                          )}
                          key="0"
                          disabled
                          value="none"
                        >
                          month(s)/year(s)
                        </MenuItem>
                        <MenuItem value={'months'}>Month(s)</MenuItem>
                        <MenuItem value={'years'}>Year(s)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <InputLabel htmlFor="TextField-input">Minimum Salary</InputLabel>
                    <TextField
                      autoComplete="current-password"
                      id="salary"
                      variant="outlined"
                      placeholder="Minimum salary"
                      name="salaryMin"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.salaryMin ? formik.values.salaryMin : null}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} style={{ alignSelf: 'end' }}>
                    <InputLabel htmlFor="TextField-input">Maximum Salary</InputLabel>
                    <TextField
                      autoComplete="current-password"
                      id="salary"
                      variant="outlined"
                      placeholder="Maximum salary"
                      name="salaryMax"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.salaryMax ? formik.values.salaryMax : null}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <InputLabel htmlFor="TextField-input">Resume Headline</InputLabel>
            <TextField
              autoComplete="current-password"
              id="resumeHeadline"
              variant="outlined"
              placeholder="Enter resume headline"
              name="headline"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.headline}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="TextField-input">Summary</InputLabel>
            <TextField
              autoComplete="current-password"
              variant="outlined"
              id="summary"
              placeholder="Enter summary"
              name="summary"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.summary}
              fullWidth
              multiline
              rows={4}
              // inputProps={
              //   { maxLength: '250' }
              // }
              helperText={formik.errors.summary}
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default PersonalDetailsForm;