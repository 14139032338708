
import { Container, Grid, FormControlLabel, FormGroup, Switch, InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import StartEndDatePicker from 'view/common/components/StartEndDatePicker/StartEndDatePicker';

const useStyle = makeStyles(() => (
  {
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }
  }
));

const ProfessionalDetail = ({ formik, id, setIsError, formType }) => {
  const classes = useStyle();
  return (
    <Container style={{ padding: '2rem' }} id={id}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <InputLabel htmlFor="TextField-input" required>Title</InputLabel>
            <TextField variant="outlined"
              id="jobTitle"
              placeholder='Enter job title'
              name="title"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.title}
              fullWidth 
              helperText={formik.errors.title}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputLabel htmlFor="TextField-input" required>Company</InputLabel>
            <TextField variant="outlined"
              id="company"
              placeholder='Enter company'
              name="company"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.company}
              fullWidth 
              helperText={formik.errors.company}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputLabel htmlFor="TextField-input" required>Location</InputLabel>
            <TextField variant="outlined"
              id="location"
              placeholder='Enter location'
              name="location"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.location}
              fullWidth 
              helperText={formik.errors.location}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor="TextField-input">Description</InputLabel>
            <TextField
              id="multiline-static"
              placeholder="Enter description"
              multiline
              name="description"
              rows={4}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.description}
              fullWidth />
          </Grid>
          <Grid item xs={12}>
            <StartEndDatePicker
              formik={formik}
              showEndDate={!formik.values.workingHere}
              setIsError={setIsError}
              formType={formType}
            />
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.switchContainer}
          >
            <FormGroup>
              <FormControlLabel
                defaultChecked={formik.values.workingHere}
                control={<Switch
                  checked={formik.values.workingHere}
                  onChange={value => formik.setFieldValue('workingHere', value.target.checked)}
                />}
                labelPlacement="end"
                label="I am currently working in this role"
                name="workingHere"
                value={formik.values.workingHere}
                fullWidth
              />
            </FormGroup>
          </Grid>
        </Grid>
      </form>
    </Container >
  );
};

export default ProfessionalDetail;