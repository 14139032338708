import React from 'react';
import { Button, Grid, IconButton, Typography, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyle = makeStyles(() => ({
  applyConfirmation: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1000px',
    backgroundColor: 'white',
    boxShadow: 24,
    padding: '1rem'
  },
  exprnc: {
    fontSize: '14px',
    color: 'black',
    margin: '1.3rem 0px 0.2rem 0px',
    fontWeight: 500,
    marginTop: '-15px',
  },
}));


const ShortlistPopup = ({ onClose, row, handleSubmit }) => {
  const classes = useStyle();
  return (
    <Box sx={{ width: '700px', backgroundColor: 'white', padding: '10px' }}>
      <Grid container xs={12} style={{ marginTop: '1rem' }}>
        <Grid item xs={11}>
          <Typography variant="h4" style={{ marginLeft: '20px' }} >
            Shortlist
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          container
          alignContent="center"
          justifyContent="flex-end">
          <IconButton
            color="secondary"
            aria-label="Close Dialog">
            <CloseIcon onClick={onClose} />
          </IconButton>
        </Grid>
      </Grid>
      <Divider style={{ width: '665px', marginLeft: '18px' }} />
      <Grid container xs={12} style={{ marginTop: '1rem', marginLeft: '21px' }}>
        <Grid item xs={4}>
          <Typography variant="subtitle1" >
            Candidate
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">
            Job Profile
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">
            Location
          </Typography>
        </Grid>
      </Grid>

      <Grid container xs={12} style={{ marginTop: '1rem', marginLeft: '21px' }}>
        <Grid item xs={4}>
          <Typography variant="body2" className={classes.exprnc} >
            {row?.applicant?.firstName} {row?.applicant?.lastName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" className={classes.exprnc}>
            {row?.jobDetail?.companyJobTitle?.title}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" className={classes.exprnc}>
            {row?.jobDetail?.employerLocation?.city}
          </Typography>
        </Grid>
      </Grid>

      <Grid container xs={12} style={{ marginTop: '1rem', marginLeft: '21px' }}>
        <Grid item xs={4}>
          <Typography variant="subtitle1" >
            Compatibility
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">
            Expected Salary
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">
            Source
          </Typography>
        </Grid>
      </Grid>

      <Grid container xs={12} style={{ marginTop: '1rem', marginLeft: '21px' }}>
        <Grid item xs={4}>
          <Typography variant="body2" className={classes.exprnc} >
            {row?.compatibility}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" className={classes.exprnc}>
            {row?.applicant?.salaryMax}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" className={classes.exprnc}>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent={'right'} style={{ marginTop: '20px', marginLeft: '480px' }}>
        <Grid item xs={0}>
          <Button variant="outlined" type="button" onClick={onClose} >Cancel</Button>
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" color="secondary" onClick={handleSubmit}>Shortlist</Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ShortlistPopup;