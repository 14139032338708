import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Box,
  Paper,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    cursor: 'pointer',
    marginBottom: '0.5rem',
    boxShadow: '0px 0px 10px rgba(33, 32, 77, 0.1)',
  },
}));

const ShowConfig = (props) => {
  const { t : translate } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container direction="column" spacing={1}>
      {props.items.map((c) => {
        return (
          <Grid item xs={12} md={12} key={c.key}>
            <Paper
              className={classes.paper}
              onClick={() => props.onSelection(c.key)}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h5">
                    {translate(`DHIYO.CONFIGURATION.CONFIG.${c.key}`)}
                  </Typography>
                </Box>
                <ArrowForwardIcon color="secondary" />
              </Box>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ShowConfig;
