import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import Theme from './themes/Theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'react-toastify/dist/ReactToastify.css';
import Home from 'view/home';

function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <ToastContainer autoClose={5000} />
      </div>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <Home />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
