import React from 'react'; 
import { Button, Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import CharImageMaker from './CharImageMaker';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    alignSelf: 'center',
    fontSize: '18px',
    lineHeight: '27px'
  },
  title: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 500
  },
  headerCard: {
    height: '181px',
    width: '620px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]:{
      height: 'auto%',
      width: '100%',
    }
  },
  cardContent: {
    marginTop: '5px',
  },

  linkClr: {
    color: '#6360CD'
  },
  imageSize: {
    width: '30px',
    height: '30px'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  m20: {
    marginTop: '20px'
  },
  m15: {
    marginTop: '15px'
  },
  subTitle: {
    fontSize: '12px',
    lineHeight: '18px'
  },
  btn: {
    width: '53px',
    height: '24px',
    padding: '14px 15px',
    '& .MuiButton-root':{
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px'
    }
  },
  baseline: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end'
  }
}));

const InterviewCards=({ key, name, designation, interviewer, organiser, date, redirect, interviewId })=> {
  const classes = useStyle();

  return (
    <>
      <Grid key={key} item xs={12} md={6} lg={6} style={{ padding: 0, marginBottom: '20px' }}>
        <Card className={classes.headerCard}>
          <CardContent className={classes.cardContent}>
            <Grid container className={classes.flexCenter}>
              <Grid item xs={2} md={1}>
                <CharImageMaker name={designation} classname={classes.imageSize} />
              </Grid>
              <Grid item xs={10} md={11}>
                <Typography variant='subtitle2' className={classes.title}>
                  {designation}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.m15}>
              <Grid item xs={4}>
                <Typography variant='subtitle1' className={classes.subTitle}>
                  Candidate
                </Typography>
                <Typography variant="subtitle2" className={classes.subTitle} style={{ marginTop: '2px' }}>
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='subtitle1' className={classes.subTitle}>
                  Interviewer
                </Typography>
                <Typography variant="subtitle2" className={classes.subTitle} style={{ marginTop: '2px' }}>
                  {interviewer}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.m20}>
              <Grid item xs={4}>
                <Typography variant='subtitle1' className={classes.subTitle}>
                  Organiser
                </Typography>
                <Typography variant="subtitle2" className={classes.subTitle} style={{ marginTop: '2px' }}>
                  {organiser}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle1' className={classes.subTitle}>
                 Date & Time
                </Typography>
                <Typography variant="subtitle2" className={classes.subTitle} style={{ marginTop: '2px' }}>
                  {date}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.baseline}>
                <Button 
                  component={Link} 
                  variant="contained" 
                  className={classes.btn} 
                  color='secondary' 
                  type="submit" 
                  style={{ marginLeft: '1rem' }}
                  to={`${redirect}/${interviewId}`}
                > 
                Join
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default InterviewCards;

InterviewCards.propTypes={
  date: PropTypes.string,
  name: PropTypes.string,
  organiser: PropTypes.string,
  interviewer: PropTypes.string,
  designation: PropTypes.string
};

InterviewCards.defaultProps={
  date: '29 2022',
  name: 'Pratik Soni',
  organiser: 'Pratik Soni',
  interviewer: 'Rohit Patil',
  designation: 'NA'
};