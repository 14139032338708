import { Button, Card, CardContent, Grid, IconButton, Typography, Box, Modal, Divider } from '@material-ui/core';
import React from 'react';
import rupees from 'asset/icons/rupees.svg';
import location from 'asset/icons/location.svg';
import experience from 'asset/icons/experience.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { floor, get } from 'lodash';
import moment from 'moment';
import { onApplyJob } from 'redux/actions/applicantDashboard';
import { useDispatch } from 'react-redux';
import PinterestIcon from 'asset/icons/pinterest.svg';

const useStyle = makeStyles(() => ({
  jobCard: {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    padding: '0.5rem 0',
    cursor: 'pointer'
  },
  company: {
    '&.MuiTypography-root': {
      color: '#9D9D9D',
      fontSize: '18px'
    }
  },
  iconBtn: {
    padding: '7px'
  },
  iconsection: {
    display: 'flex',
    alignItems: 'center',
  },
  percent: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'right',
    color: '#FF5B15',
  },
  smIconBtn: {
    display: 'flex',
    padding: 0,
    marginRight: '1rem',
    alignItems: 'flex-start'
  },
  oneLine: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'initial'
  },
  applyConfirmation: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: 4
  },
  label: {
    fontSize: '12px'
  }
}));

const JobCard = (props) => {
  const classes = useStyle();
  const { data } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };
  const onCardClick = () => {
    navigate(`/jobDetails/${data.jobDetailId}`, {
      state: {
        isApplicant: true
      }
    });
  };

  const onApplyClick = async (e) => {
    e.stopPropagation();
    const resp = await dispatch(onApplyJob({
      jobId: get(data, 'jobDetailId', '')
    }));
    const state = get(resp, ['payload', 'state'], '');
    if (state === 'APPLIED') {
      navigate(`/jobDetails/${data.jobDetailId}`, {
        state: {
          applied: state === 'APPLIED',
          isApplicant: true,
        }
      });
    }
  };

  return (
    <Box
      sx={{
        marginTop: '0.5rem'
      }} >
      <Card elevation={0} className={classes.jobCard} onClick={onCardClick}>
        <CardContent style={{ padding: '25px' }}>
          <Grid container>
            <Grid item xs={9} style={{ display: 'flex' }}>
              <IconButton fontSize='large' className={classes.smIconBtn}>
                <img src={PinterestIcon} alt='pinterest Icon' />
              </IconButton>
              <Box width={'95%'}>
                <Typography className={classes.oneLine} variant="h5" style={{ fontSize: '20px' }}>
                  {get(data, ['jobTitle'], '')}
                </Typography>
                <Typography className={classes.company} >
                  {get(data, ['company', 'companyName'], '')}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={3} style={{ textAlign: 'end' }}>
              <Typography variant='subtitle1' style={{ fontSize: '12px' }}>Compatibility</Typography>
              <Typography className={classes.percent}>
                {floor(get(data, ['compatibility'], ''), 2)}%
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ margin: '1rem 0' }}>
            <Grid item xs={12} md={3} className={classes.iconsection}>
              <IconButton className={classes.iconBtn}>
                <img src={experience} alt='Experience Icon' />
              </IconButton>
              <Typography className={classes.oneLine} variant='subtitle2'>{get(data, ['experience'], '')} Years</Typography>
            </Grid>

            <Grid item xs={12} md={4} className={classes.iconsection}>
              <IconButton className={classes.iconBtn}>
                <img src={rupees} alt='Rupee Icon' />
              </IconButton>
              <Typography className={classes.oneLine} variant='subtitle2'>{get(data, ['salary'], '')}</Typography>
            </Grid>

            <Grid item xs={12} md={5} className={classes.iconsection}>
              <IconButton className={classes.iconBtn}>
                <img src={location} alt='Location Icon' />
              </IconButton>
              <Typography className={classes.oneLine} variant='subtitle2'>{data?.jobLocation?.split(' ')?.splice(-4)}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4} md={2}>
              <Button variant="contained"
                color="secondary"
                disableElevation
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                className={classes.label}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpen}>
                Apply
              </Button>
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes.applyConfirmation}>
                  <Box>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                      Apply For Job
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography style={{ mt: 2, marginTop: '20px' }}>
                    Do you want to apply for this Job?
                  </Typography>
                  <Grid container spacing={1} justifyContent={'right'} style={{ marginTop: '20px' }}>
                    <Grid item xs={0}>
                      <Button variant="contained" color="secondary" onClick={onApplyClick}>Apply</Button>
                    </Grid>
                    <Grid item xs={1}>
                      <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </Grid>

            <Grid item xs={4} md={2}>
              <Button variant="outlined" className={classes.label} color="secondary">Save</Button>
            </Grid>

            <Grid item xs={4} md={3}>
              <Button variant="outlined" className={classes.label} color="secondary">Refer Job</Button>
            </Grid>

            <Grid item xs={12} md={5} style={{ alignSelf: 'end' }}>
              <Typography variant="body2" style={{ textAlign: 'right' }}>
                Posted: {moment(get(data, ['postedOn'], '')).fromNow().split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default JobCard;