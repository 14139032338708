import ComposerPanelWrapper from '../../../common/wrappers/ComposerPanelWrapper';
import InterviewerDashboard from '../InterviewerDashboard/InterviewerDashboard';
import MySchedule from '../MySchedule';
import React,{ useState } from 'react';

const Home = () => {
  const [candidateId, setCandidateId] = useState();
  const [jobId, setJobId] = useState();
  const handleClick = (cards) => {
    setCandidateId(cards.applicantDetail.applicantId);
    setJobId(cards.jobDetail.jobId);
  };
  const PanelConfig = {
    isOpen: false,
    anchor: 'right',
    componentRenderer: <MySchedule handleClick={handleClick} setCandidateId={setCandidateId} setJobId={setJobId} />,
    rendererConfigs: {},
  };

  return (
    <>
      <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
        <InterviewerDashboard candidateId={candidateId} jobId={jobId} />
      </ComposerPanelWrapper>
    </>
  );
};

export default Home;