const { endPoints } = require('constants/applicantEndPoints');
const { callApi } = require('middleware/api');

export const signIn = (data) => {
  return callApi(data, 'POST', endPoints.login, false);
};

export const signOut = () => {
  return callApi(null, 'GET', endPoints.logout, true);
};

export const registerNow = (data) => {
  return callApi(data, 'POST', endPoints.register, false);
};

export const getUserProfileDetails = (data) => {
  return callApi(data, 'GET', `${endPoints.userDetails}`, true);
};

export const getEmployerProfileDetails = (data) => {
  return callApi(data, 'GET', `${endPoints.employerProfile}/${data.email}`, true);
};



export const onVerifyOtp=(data) =>{
  return callApi(data,'POST',endPoints.verifyEmail, false);
};

export const ResendOtp = (data) => {
  return callApi(data, 'POST', endPoints.resendOTP, false);
};

export const forgotPassword = (data, role) => {
  let endpoint = '';
  switch (role) {
  case 'applicant':
    endpoint = endPoints.forgotPassword;
    break;
  case 'employer':
    endpoint = '/security/auth/employer/changePassword';
    break;
  case 'interviewer':
    endpoint = '/security/auth/interviewer/changePassword';
    break;
  }
  return callApi(data, 'POST', endpoint, false);
};

export const getJobs = (data) => {
  return callApi(data, 'GET', endPoints.jobApplicants, true);
};