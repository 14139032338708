import React, { useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormNavigation from '../FormNavigation/FormNavigation';
import { StepLabel, Stepper, Step } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  stepper: {
    backgroundColor: 'transparent',
    padding: '20px 0',
    width: '40%',
    float: 'right',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'max-content',
    },
  },
  stepLabel: {
    textTransform: 'uppercase',
  },
}));

const MultiStepForm = ({ children, initialValues, formikRef, onSubmit, onNext, onBack, width, float }) => {
  const classes = useStyles();
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapShot, setSnapShot] = useState(initialValues);
  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapShot(values);
    if (onNext) {
      onNext(values, stepNumber, setStepNumber);
    } else {
      setStepNumber(stepNumber + 1);
    }
  };
  const previous = (values) => {
    setSnapShot(values);
    if (onBack) {
      onBack(values, stepNumber, setStepNumber);
    } else {
      setStepNumber(stepNumber - 1);
    }
  };

  const handleSubmit = async (values, actions) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values);
    }

    if (isLastStep) {
      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      next(values);
    }
  };

  useEffect(() => {
    setSnapShot(initialValues);
  }, [initialValues]);

  return (
    <>
      <Formik
        ref={formikRef}
        initialValues={snapShot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
              <Stepper
                activeStep={stepNumber}
                className={classes.stepper}
                style={{ width: width, float: float }}>
                {steps.map((currentStep, index) => {
                  if (index === (totalSteps - 1)) {
                    return null;
                  }
                  const label = currentStep.props.stepName;
                  return (
                    <Step key={label}>
                      <StepLabel className={classes.stepLabel}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {step}

              <FormNavigation
                isLastStep={isLastStep}
                hasPrevious={stepNumber > 0}
                onBackClick={() => previous(formik.values)} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

MultiStepForm.propTypes = {
  children: PropTypes.node,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};
export default MultiStepForm;

export const FormStep = ({ children }) => children;
