/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { get, isEmpty } from 'lodash';
import { onExperienceDetailsAddEdit, onPersonalDetailsAddEdit } from 'redux/actions/userProfile';
import { onUserProfileDetails } from 'redux/actions/userAuthentication';
import { FORM_CARD_TYPE, getCalculatedProfileCompletionStatus } from 'view/common/utils/formUtils';
import { onEmployerProfileDetails } from 'redux/actions/employerActions/employerAuthentication';

export const ApplicantProfileInitialState = [
  {
    label: 'Personal Details',
    active: false,
    id: FORM_CARD_TYPE.personalDetails,
  },
  {
    label: 'Education Details',
    active: false,
    id: FORM_CARD_TYPE.educationDetails,
  },
  {
    label: 'Professional Details',
    active: false,
    id: FORM_CARD_TYPE.professionalDetails
  },

  {
    label: 'skill Details',
    active: false,
    id: FORM_CARD_TYPE.skillDetails
  },
  {
    label: 'Honor & Achievements',
    active: false,
    id: FORM_CARD_TYPE.achievementDetails
  },
  {
    label: 'Licenses And Certification',
    active: false,
    id: FORM_CARD_TYPE.certificationDetails,
  },
  {
    label: 'Social Contribution',
    active: false,
    id: FORM_CARD_TYPE.socialContributions
  },
  {
    label: 'Research & Innovation',
    active: false,
    id: FORM_CARD_TYPE.researchAndInnovations
  },
  {
    label: 'Project Details',
    active: false,
    id: FORM_CARD_TYPE.projectDetails
  },
];
export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    applicant: {},
    employer: {},
    applicantProfileCompletion: ApplicantProfileInitialState
  },
  reducers: {
    setProfile: (state, action) => {
      const [formType, formData] = R.paths(
        [
          ['payload', 'formType'],
          ['payload', 'formData'],
        ],
        action
      );
      state[formType] = formData;
    },
    setApplicantProfile: (state, action) => {
      state.applicant = action.payload;
    },
    updateProfileCompletionStatus: (state, action) => {
      const { ids, isCompleted } = R.propOr({}, 'payload', action);
      state.applicantProfileCompletion = R.map((item) => {
        if (R.includes(item.id, ids)) {
          return {
            ...item,
            active: isCompleted
          };
        }
        return item;
      })(state.applicantProfileCompletion);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(onUserProfileDetails.pending, (state, action) => {
    });
    builder.addCase(onUserProfileDetails.fulfilled, (state, action) => {
      const data = get(action, ['payload']);
      state.applicantProfileCompletion = getCalculatedProfileCompletionStatus(data);
      state.applicant = isEmpty(data) ? {} : data;
    });
    builder.addCase(onUserProfileDetails.rejected, (state, action) => {
    });
    builder.addCase(onPersonalDetailsAddEdit.pending, (state, action) => {
    });
    builder.addCase(onPersonalDetailsAddEdit.fulfilled, (state, action) => {
      const data = get(action, ['payload', 'data']);
      state.applicant = isEmpty(data) ? {} : data;
    });
    builder.addCase(onPersonalDetailsAddEdit.rejected, (state, action) => {
    });
    builder.addCase(onExperienceDetailsAddEdit.pending, (state, action) => {
    });
    builder.addCase(onExperienceDetailsAddEdit.fulfilled, (state, action) => {
      const data = get(action, ['payload']);
      state.applicant = isEmpty(data) ? {} : data;
    });
    builder.addCase(onExperienceDetailsAddEdit.rejected, (state, action) => {
    });
    builder.addCase(onEmployerProfileDetails.pending, (state, action) => {
    });
    builder.addCase(onEmployerProfileDetails.fulfilled, (state, action) => {
      const data = get(action, ['payload']);
      state.employer = isEmpty(data) ? {} : data;
    });
    builder.addCase(onEmployerProfileDetails.rejected, (state, action) => {
    });
  }
});

// Action creators are generated for each case reducer function
export const { setProfile, setApplicantProfile, updateProfileCompletionStatus } = userProfileSlice.actions;

export default userProfileSlice.reducer;
