import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, } from '@material-ui/core';
import { Button } from 'view/ui-components';

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: theme.spacing(4),
    position: 'absolute',
    // top: theme.spacing(6),
  },
  dialogTitle: {
    textAlign: 'center',
    padding: '16px 24px 0px',
    '& .MuiTypography-h6': {
      fontSize: '1.3rem',
      fontWeight: 600,
      lineHeight: '32px',
    },  
  },
  dialogContent: {
    textAlign: 'center',
  },
  dialogAction: {
    justifyContent: 'center',
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      cursor: 'default',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '8rem',
    },
  },
  uploadBtn: {
    backgroundColor: '#2754CE',
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 200,
    padding: '14px 16px',
    lineHeight: 1.5,
    border: '1px solid #2754CE',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#6361CD',
      boxShadow: 'none',
    },
    
  },
  cancelBtn: {
    borderColor: '#2754CE',
    '&:hover': {
      borderColor: '#6361CD',
    },
    color: '#2754CE',
    fontSize: 16,
    fontWeight: 200,
    padding: '14px 16px',
    lineHeight: 1.5,
  },
}));

export default function ConfirmDialog({ isOpen, setConfirmDialog, title, children, onConfirm, confirmText = 'Yes', cancelText = 'No', hideCancel=false }) {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      <DialogActions className={classes.dialogAction}>
        {!hideCancel &&<Button className={classes.cancelBtn} text={cancelText} color="primary" size="small" variant="outlined" type="cancel" onClick={() => setConfirmDialog(false)} />}
        <Button className={classes.uploadBtn} text={confirmText} color="primary" size="small" variant="contained" type="submit" onClick={onConfirm} />
      </DialogActions>
    </Dialog>
  );
}
