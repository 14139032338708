const { endPoints } = require('constants/interviewerEndpoints');
import { callApi } from 'middleware/api';

const endPoint = () => {
  const loggedInUserRole = localStorage.getItem('deepailytics.user.role');
  return (
    {
      'ROLE_EMPLOYER': endPoints.employerSchedule,
      'ROLE_APPLICANT': endPoints.applicantSchedule,
      'ROLE_INTERVIEWER': endPoints.customInterviewerSchedule
    }[loggedInUserRole]
  );
};

const endPointSlot = () => {
  const loggedInUserRole = localStorage.getItem('deepailytics.user.role');
  return (
    {
      'ROLE_APPLICANT': { get_slot: endPoints.applicantSlots, mySchedule: endPoints.applicantSchedule },
      'ROLE_INTERVIEWER': { get_slot: endPoints.interviewerSlots, mySchedule: endPoints.customInterviewerSchedule }
    }[loggedInUserRole]
  );
};

export const getMySchedule = ({ startDate, endDate }) => {
  const scheduleEndpoint = endPoint();
  return callApi(null, 'GET', `${scheduleEndpoint}?startDate=${startDate}&endDate=${endDate}`, true);
};

export const getInterviewerMyScheduleWithFilter = ({ startDate, endDate }) => {
  return callApi(null, 'GET', `${endPointSlot().mySchedule}?startDate=${startDate}&endDate=${endDate}`, true);
};

export const setSchedule = (data) => {
  return callApi(data, 'POST','/job/organiser/interview/schedule', true);
};

export const getSlots = ({ startTime, endTime }) => {
  let scheduleEndpoint = endPointSlot();
  return callApi(null, 'GET',`${scheduleEndpoint.get_slot}/all?startTime=${startTime}&endTime=${endTime}`, true);
};

export const confirmSlots = (data) => {
  const loggedInUserRole = localStorage.getItem('deepailytics.user.role');
  if (loggedInUserRole === 'ROLE_APPLICANT') {
    return callApi(null, 'PATCH',`${endPointSlot().get_slot}/select/${data}`, true);
  } else {
    return callApi(data, 'POST',`${endPointSlot().get_slot}/select`, true);
  }
}; 