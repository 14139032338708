import { Container, Grid, InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import StartEndDatePicker from 'view/common/components/StartEndDatePicker/StartEndDatePicker';

const SocialContributionForm = ({ formik, id, setIsError }) => {
  return (<Container style={{ padding: '2rem' }} id={id}>
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        
        <Grid item xs={12} md={4}>
          <InputLabel htmlFor="TextField-input" required>Organization</InputLabel>
          <TextField variant="outlined"
            id="organization"
            placeholder='Enter organization'
            name="organization"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.organization}
            fullWidth 
            helperText={formik.errors.organization}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputLabel htmlFor="TextField-input" required>Role</InputLabel>
          <TextField variant="outlined"
            id="role"
            placeholder='Enter role'
            name="role"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.role}
            fullWidth 
            helperText={formik.errors.role}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputLabel htmlFor="TextField-input">Program</InputLabel>
          <TextField variant="outlined"
            id="activity"
            placeholder='Enter program'
            name="cause"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.cause}
            fullWidth 
            helperText={formik.errors.cause}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel htmlFor="TextField-input">Description</InputLabel>
          <TextField
            id="multiline-static"
            placeholder="Enter description"
            name={'description'}
            multiline
            rows={4}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.description}
            fullWidth />
        </Grid>

        <Grid item xs={12} md={8}>
          <StartEndDatePicker
            formik={formik}
            setIsError={setIsError}
          />
        </Grid>

      </Grid>
    </form>
  </Container >
  );
};
export default SocialContributionForm;