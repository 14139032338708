const { endPoints } = require('constants/employerEndpoints');
const { callApi } = require('middleware/api');

export const addJobPost = (data) => {
  return callApi(data, 'POST', endPoints.jobPosting, true);
};

export const updateJobPost = (data) => {
  return callApi(data, 'PUT', endPoints.jobPosting, true);
};

export const updateJobPostStatus = (data) => {
  return callApi(null, 'PATCH', `${endPoints.jobPostingStatus}?jobId=${data.id}&status=${data.status}`, true);
};

export const getHiringManagerList = () => {
  return callApi(null, 'GET', `${endPoints.hiringManagerList}/all`, true);
};

export const getInterviewersList = () => {
  return callApi(null, 'GET', `${endPoints.interviewersList}/all`, true);
};

export const setJobStaticQuestions = (data) => {
  return callApi(data, 'POST', `${endPoints.jobStaticQuestions}/list`, true);
};

export const setJobEmployeeParticipants = (data) => {
  return callApi(data, 'POST', `${endPoints.jobEmployeeParticipants}/list`, true);
};

export const getJobEmployeeParticipants = (data) => {
  const { pageNo = 0, pageSize = 10, employeeType } = data;
  if (employeeType) {
    return callApi(data, 'GET', `${endPoints.jobEmployeeParticipants}/job?jobId=${data.id}&pageNo=${pageNo}&pageSize=${pageSize}&employeeType=${employeeType}&sortBy=name`, true);
  }
  return callApi(data, 'GET', `${endPoints.jobEmployeeParticipants}/job?jobId=${data.id}&pageNo=${pageNo}&pageSize=${pageSize}&sortBy=name`, true);
};

export const getJobStaticQuestions = (data) => {
  return callApi(data, 'GET', `${endPoints.jobStaticQuestions}/jobs/${data.id}`, true);
};


