import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const options = [
  {
    label: 'Edit',
  },
  {
    label: 'Copy',
  },
  {
    label: 'Assign Job Post',
  },
  {
    label: 'Report',
  },
  {
    label: 'Download',
  },
  {
    label: 'Close',
  },
];

const DPMenuActionCellRenderer = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        //   style={{ marginTop: '20px' }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map(item => (
          <MenuItem key={item} onClose={handleClose}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default DPMenuActionCellRenderer;