import React from 'react';
import { Button, Modal } from '@material-ui/core';
import { onShortList } from 'redux/actions/employerActions/employerDashboard';
import { useDispatch } from 'react-redux';

const BtnCellRenderer = ({ btnMap, row }) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    setOpen(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
  };

  const handleSubmit = (e, row) => {
    let jobId = row?.jobDetailId || row?.jobDetail?.id;
    dispatch(onShortList({ jobId: jobId , candidateEmail: row.applicant.email }));
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
  };


  const onBtnClick = (e, { Renderer, onClick }) => {
    e.stopPropagation();
    e.preventDefault();
    if (Renderer) {
      handleModalOpen(e);
    } else {
      onClick();
    }
  };

  return (
    <div>
      {
        btnMap.map((item) => {
          const { label, variant, onClick, Renderer } = item;
          return (
            <>
              <Button variant={variant} color='secondary' onClick={e => onBtnClick(e, {
                Renderer,
                onClick
              })}>{label}</Button>
              {
                Renderer && (
                  <Modal
                    style={{ marginLeft: '350px', marginTop: '160px' }}
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Renderer onClose={handleClose} handleSubmit={e => handleSubmit(e, row)} row={row} />
                  </Modal>
                )
              }
            </>
          );
        })
      }
    </div>
  );
};

export default BtnCellRenderer;