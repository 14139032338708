import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { default as MaterialUiTextField } from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

 

const TextField = ({
  id,
  required = false,
  disabled,
  label,
  fullWidth = true,
  value = '',
  type,
  autoFocus,
  InputLabelProps,
  localePrefix,
  inputProps,
  error = false,
  showLength = false,
  maxLength = 20,
  ...rest
}) => {
  const { t : translate } = useTranslation();
  const [field, meta] = useField(rest);

  let lbl = null;
  let helperText = null;
  // let placerHolder = null;
  if (localePrefix !== null) {
    lbl = translate(`${localePrefix}.LABEL`);
    helperText = translate(`${localePrefix}.HELPER`);
    // placerHolder = translate(`${localePrefix}.PLACEHOLDER`);
  }
  if (label) {
    lbl = label;
  }

  const textFieldChange = (evt) => {
    if (evt.target.value.length <= maxLength) {
      meta.handleChange(evt);
    }
  };

  return (
    <React.Fragment> 
      
      <MaterialUiTextField
        variant="outlined"
        key={id}
        required={required}
        helperText={error ? helperText : undefined}
        // placeholder={placerHolder}
        value={value}
        error={error}
        onChange={textFieldChange}
        disabled={disabled}
        id={id}
        fullWidth={fullWidth}
        placeholder={lbl}
        type={type}
        autoFocus={autoFocus}
        inputProps={{ ...inputProps }}
        InputLabelProps={{ ...InputLabelProps }}
        {...field}
        {...rest} />
      {showLength ? (
        <div align="right">
          {value.length}/{maxLength}
        </div>
      ) : (
        ''
      )}
      
    </React.Fragment>
  );
};

TextField.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
  InputLabelProps: PropTypes.object,
  error: PropTypes.bool,
  inputProps: PropTypes.object,
  maxLength: PropTypes.number,
};
export default TextField;
