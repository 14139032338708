import React, { useState } from 'react';
import { Slider } from '@material-ui/core'; 

function valuetext(value) {
  return `${value}`;
}

const DPSlider = (props) => {
  const { label, callback, marks, color, propsValue } = props;
  const [value, setValue] = useState(propsValue || [20, 40]);


  const handleChange = (e, newValue, label) => {
    setValue(newValue);
    callback(newValue, label);
  };

  return (
    <Slider
      getAriaLabel={() => label}
      value={value}
      onChange={(e, newValue)=>handleChange (e, newValue, label)}
      valueLabelDisplay="auto"
      getAriaValueText={valuetext}
      marks={marks}
      color={color} />
  );
};

export default DPSlider;