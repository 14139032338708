import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'themes/colors';

const useStyles = makeStyles(() => ({
  label: {
    display: 'inline-flex',
    border: '1px solid #cbd2d9',
    boxSizing: 'border-box',
    borderRadius: '40px',
    cursor: 'pointer',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '15px 22px',
    height: '50px',
    marginBottom: 0,
    '&:hover': {
      background: '#E9E7F5',
      border: ' 1px solid #6361CD',
    },
  },

  btnLabel: {
    display: 'flex',
    gap: '16px',
    fontStyle: 'normal',
    alignItems: 'center',
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '130%',
    color: colors.gray1,
  },
  input: {
    display: 'none',
    '&:checked': {
      '& + label ': {
        background: '#E9E7F5',
        border: ' 1px solid #6361CD',
      },
    },
  },
}));
const CustomRadioButton = ({ id, labelText, ...rest }) => {
  const classes = useStyles();
  return (
    <div className="radio">
      <input type="radio" id={id} {...rest} className={classes.input} />
      <label htmlFor={id} className={classes.label}>
        <div className={classes.btnLabel}>
          <div>
            <span className={classes.text}>{labelText}</span>
          </div>
        </div>
      </label>
    </div>
  );
};

export default CustomRadioButton;