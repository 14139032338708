import { endPoints } from 'constants/employerEndpoints';
import { isEmpty } from 'lodash';
import { callApi } from 'middleware/api';

export const addEmpUser = (data) => {
  const method = isEmpty(data.id) ? 'POST': 'PUT';
  return callApi(data, method, endPoints.userManagement.empUser, true);
};

export const addInterviewer = (data) => {
  const method = isEmpty(data.id) ? 'POST': 'PUT';
  return callApi(data, method, endPoints.userManagement.addInterviewer, true);
};

export const getAllInterviewers = (data) => {
  return callApi(null, 'GET', `${endPoints.userManagement.allInterviewers}?pageSize=${data.pageSize}&pageNo=${data.pageNo}&sortBy=createdOn&keyword=${data.search}`, true);
};

export const getAllEmpUsers = (data) => {
  return callApi(null, 'GET', `${endPoints.userManagement.empUser}/readByPage?pageSize=${data.pageSize}&pageNo=${data.pageNo}&sortBy=createdOn&keyword=${data.search}`, true);
};

export const getUserById = (id, userType) => {
  const url = userType === 'interviewer' ? endPoints.userManagement.addInterviewer : endPoints.userManagement.empUser;
  return callApi(null, 'GET', `${url}/${id}`, true);
};

export const getJobPostsById = (data) => {
  const url = `${endPoints.job.assignedJobPosts}?userId=${data.id}&pageSize=${data.pageSize}&pageNo=${data.pageNo}`;
  return callApi(null, 'GET', url, true);
};

export const patchUserActiveStatus = (data) => {
  const { userType, activate, id } = data;
  const empUrl = userType === 'interviewer' ? endPoints.userManagement.addInterviewer : endPoints.userManagement.empUser;
  const url = `${empUrl}/${activate ? 'activate' : 'deactivate'}/${id}`;
  return callApi(null, 'PATCH', url, true);
};