import React, { useEffect, useState } from 'react';
import { Icon, Grid, Button, makeStyles } from '@material-ui/core';
import { filter } from 'lodash-es';
import { Popup, Notification, ConfirmDialog } from '../../../../ui-components';
import { useDispatch } from 'react-redux';
import AddFeedbackQuestionsForm from './AddFeedbackQuestionsForm';
import { onAddInterviewQuestion, onDeleteInterviewQuestion, onEditInterviewQuestion, onGetInterviewQuestionList } from 'redux/actions/employerActions/Configurations/interviewQuestionsAction';
import ViewInterviewQuestions from './ViewInterviewQuestions';

const useStyles = makeStyles(theme => ({
  intBtn: {
    textAlign: 'end',
    [theme.breakpoints.down('md')]: {
      textAlign: 'initial', margin: '10px 0px'
    },
  }
}));

export default function InterviewQuestions() {
  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const initialValue = {
    id: '',
    question: '',
    isRatingApplicable: false,
  };
  const [recordForEdit, setRecordForEdit] = useState(initialValue);
 
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const [intervewQuestionsData, setIntervewQuestionsData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateData) {
      setIsLoading(true);
      dispatch(onGetInterviewQuestionList()).then((response) => {
        setIntervewQuestionsData(response.payload);
        setUpdateData(false);
        setIsLoading(false);
      });
    }
  }, [updateData]);

  const addOrEdit = async (interviewQuestion, resetForm) => {
    if (interviewQuestion.id === '') {
      // insert location
      await dispatch(onAddInterviewQuestion(interviewQuestion));
    } else {
      // update location
      await dispatch(onEditInterviewQuestion(interviewQuestion));
    }
    setUpdateData(true);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: 'Submitted Successfully',
      type: 'success'
    });
  };
  const openInPopup = (id) => {
    let selectedRow = filter(intervewQuestionsData, { id }, []);
    if (selectedRow.length > 0) {
      setRecordForEdit(selectedRow[0]);
      setOpenPopup(true);
    }
  };

  const enableConfirmDialog = (data) => {
    setConfirmDialog(data);
  };
  const onDelete = async(id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await dispatch(onDeleteInterviewQuestion(id));
    setUpdateData(true);
    setNotify({
      isOpen: true,
      message: 'Deleted Successfully',
      type: 'error',
    });
  };
  
  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12} className={classes.intBtn}>
          <Button
            fullWidth={false}
            variant="contained"
            color="secondary"
            startIcon={<Icon>add_circle</Icon>}
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(initialValue);
            }}>
            {'Create Interview Questions'}
          </Button>
        </Grid>
        <Grid item style={{ marginTop: '1em' }}>
          <ViewInterviewQuestions
            data={intervewQuestionsData}
            openInPopup={openInPopup}
            key="viewInterviewQuestions"
            onDelete={onDelete}
            setConfirmDialog={enableConfirmDialog}
            confirmDialog={confirmDialog}
            isLoading={isLoading} />
        </Grid>
      </Grid>
      <Popup
        title={recordForEdit && recordForEdit.id ? 'Update Question' : 'Create Question'}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}>
        <AddFeedbackQuestionsForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          openInPopup={openInPopup}
          onCancel={()=> setOpenPopup(false)} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog} />
    </>
  );
}
