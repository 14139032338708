import React from 'react';
import UserProfileWrapper from 'view/common/wrappers/UserProfileWrapper';
import { UserForms } from 'view/common/utils/userProfileUtil';

const EmployerRegister = () => {
  return (
    <UserProfileWrapper
      renderer={UserForms.employerRegisterForm} />
  );
};

export default EmployerRegister;