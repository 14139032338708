import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ComposerPanelWrapper from '../../../common/wrappers/ComposerPanelWrapper';
import { onGetLearning } from 'redux/actions/applicantDashboard';
import LeaningCard from './LearningCard/LearningCard';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  gridMain: {
    padding: '1rem 1rem',
    marginBottom: '3rem'
  },
  newsTitle: {
    fontSize: '20px',
    color: '#555555',
    fontFamily: 'Poppins',
    fontWeight: '700'
  },
  subTitle: {
    fontSize: '14px',
    color: '#828282',
    fontFamily: 'Poppins',
    fontWeight: '400'
  }
}));

const Learning = () => {
  const dispatch = useDispatch();
  const PanelConfig = {
    isOpen: false,
    anchor: 'right',
    Renderer: false, // ComponentRenderer
    rendererConfigs: {},
  };
  const classes = useStyles();
  const courses = useSelector(state => get(state, ['applicantDashboard', 'learning'], {}));
  const profileData = useSelector(state => pathOr('', ['userProfile', 'applicant'], state));

  useEffect(() => {
    if (profileData && !courses?.length) {
      let preparePayload = [];
      profileData?.professionalDetails?.map((experience) => {
        preparePayload.push(experience.description);
      });

      if (preparePayload.length > 2) {
        dispatch(onGetLearning({
          'Experience': preparePayload,
          'Certifications': ['NA']
        }));
      }
    }

  }, [profileData]);

  return (
    <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
      <Container maxWidth="lg" className={classes.gridMain}>
        <Box style={{ paddingLeft: '1rem' }}>
          <Typography variant="body2" className={classes.newsTitle}>
            Learnings
          </Typography>
          <Typography variant="body2" className={classes.subTitle}>
            A broad selection of online video courses with new additions published every month
          </Typography>
        </Box>
        <Grid container spacing={12}>
          {courses[0]?.map(item => (
            <Grid item xs={12} md={6} style={{ display: 'flex' }}>
              <LeaningCard item={item} />
            </Grid>
          )
          )}
        </Grid>

        <Box style={{ paddingLeft: '1rem', marginTop: '2rem' }}>
          <Typography variant="body2" className={classes.newsTitle}>
            Trending courses in market
          </Typography>
        </Box>
        <Grid container spacing={12}>
          {courses[1]?.map(item => (
            <Grid item xs={12} md={6} style={{ display: 'flex' }}>
              <LeaningCard item={item} />
            </Grid>
          )
          )}
        </Grid>

        <Box style={{ paddingLeft: '1rem', marginTop: '2rem' }}>
          <Typography variant="body2" className={classes.newsTitle}>
            Courses based on my skills
          </Typography>
        </Box>
      </Container>
    </ComposerPanelWrapper>
  );
};

export default Learning;
