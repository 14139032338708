import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@material-ui/core';
import BackwardIconImage from 'asset/images/ArrowCircleLeft.svg'; 
import ForwardIconImage from 'asset/images/ArrowCircleRight.svg';

const FormNavigation = (props) => { 
  return (
    <div
      style={{
        display: 'flex',
        marginTop: 20,
        justifyContent: props.isLastStep ? 'space-between' : 'flex-end',
      }}>
      { props.hasPrevious && (
        <Button
          variant="contained"
          color="secondary"
          onClick={props.onBackClick}
          type="button"
          disableElevation
          disableRipple
          disabled={!props.hasPrevious}
          startIcon={
            <Icon color="secondary" >
              <img alt="Back" src={BackwardIconImage} />
            </Icon>
          }
          style={{ marginRight: 10 }}>
          Back
        </Button>
      )}

      <Button
        variant="contained"
        color="secondary"
        type="submit"
        disableElevation
        disableRipple
        endIcon={
          <Icon>
            <img alt="Forward" src={ForwardIconImage} />
          </Icon>
        }>
        {props.isLastStep ? 'Submit' : 'Next'}
      </Button>
    </div>
  );
};

FormNavigation.propTypes = {
  label: PropTypes.string,
  isLastStep: PropTypes.bool,
  onBackClick: PropTypes.func,
  localePrefix: PropTypes.string,
  hasPrevious: PropTypes.bool,
};
export default FormNavigation;