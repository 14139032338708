/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { onAddQuestion,onEditQuestion,onDeleteQuestion,onGetQuestionList } from 'redux/actions/employerActions/Configurations/staticActions';

const initialState = {
  staticQuestions: [],
  status: 'idle',
  error: null,
};

const addStaticQuestions = createSlice({
  name: 'addStaticQuestions',
  initialState,
  reducers: {
    setStaticQuestions: (state, action) => {
      state.staticQuestions = action.payload;
    },
  },
  extraReducers: {
    // add location
    [onAddQuestion.pending]: (state) => {
      state.status = 'loading';
    },
    [onAddQuestion.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onAddQuestion.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    // list all locations
    [onGetQuestionList.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetQuestionList.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onGetQuestionList.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    // edit location
    [onEditQuestion.pending]: (state) => {
      state.status = 'loading';
    },
    [onEditQuestion.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onEditQuestion.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    // delete location
    [onDeleteQuestion.pending]: (state) => {
      state.status = 'loading';
    },
    [onDeleteQuestion.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onDeleteQuestion.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});
export const { setStaticQuestions } = addStaticQuestions.actions;
export default addStaticQuestions.reducer;
