import * as React from 'react';
import { Link } from 'react-router-dom'; 
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment';
import { isEmpty } from 'lodash';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontSize: '16px !important',
    fontFamily: 'Poppins !important',
    fontWeight: '500 !important',
    color: '#333333',
    lineHeight: '20px !important',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  description: {
    marginTop: '0.5rem !Important',
    fontSize: '12px !important',
    fontFamily: 'Poppins !important',
    fontWeight: '400 !important',
    lineHeight: '18px',
    color: '#333333',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 6,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  overiteLineCamp: {
    WebkitLineClamp: '2 !important',
  },
  time: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: '500',
    lineHeight: '18px',
    color: '#828282'
  },
  author: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: '500',
    color: '#24283C',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  blue: {
    backdropFilter: 'blur(4px)',
    borderRightColor: '#8C9EFF  ',
    borderRight: '5px solid',
  },
  linkIcon: {
    color: '#24283C',
    marginLeft: '5px'
  },
  link:{
    color: '#24283C',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
    marginBottom: '1rem'
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    height: '220px'
  },
  skills: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFFFFF',
  },
  round: {
    boxSizing: 'border-box',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 10px',
    gap: '5px',
    width: 'fit-content',
    height: '26px',
    background: '#6361CD',
    borderRadius: '20px',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    margin: '5px',
  },
}));

const NewsCard =({ item, viewAllLink, skills }) => {
  const classes = useStyles();
  return (
    <Card sx={{ display: 'flex', marginTop: '20px' }} className={clsx(classes.blue, { [classes.box]: isEmpty(item) })}>
      <Box sx={{ display: 'flex', flexDirection: 'column', pb: 0 }} className={clsx({ [classes.box]: isEmpty(item) })}>
        {!isEmpty(item) && <>
          <CardContent sx={{ flex: '1 0 auto', pb: 0 }}>
            <Typography className={classes.mainTitle}>
              {item.title}
            </Typography>

            <Typography variant="body2" className={clsx(classes.description, { [classes.overiteLineCamp]:skills?.length })}>
              {item.description}
            </Typography>
            { skills?.slice(0, 4).map(item=>(
              <div className={classes.round}>
                <Typography className={classes.skills}>{item}</Typography>
              </div> 
            ))}
          </CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pb: 0 }}>
            <Typography variant="body2" className={classes.time}>
              {moment(item.publishedAt).fromNow()} 
            </Typography>
            <Box style={{ display: 'flex' }}>
              <Typography className={classes.time} >
              &nbsp;by&nbsp;
              </Typography>
              <Typography className={classes.author}>{item.author?.substring(0, 15)}</Typography>
            </Box>
          </Box>
          {viewAllLink && <Link to={viewAllLink} className={classes.link} variant="text">View All<ArrowForwardIcon fontSize='small' className={classes.linkIcon} /></Link>}
        </>
        }
        {isEmpty(item) && <Typography variant='subtitle1' style={{ textAlign: 'center' }}>No Record Found</Typography>}
      </Box>
    </Card>
  );
};

export default React.memo(NewsCard);