import React from 'react';
import PropTypes from 'prop-types';
import { default as MaterialUiInputField } from '@material-ui/core/TextField';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

const FormikInputField = ({
  label,
  localePrefix,
  showLength = false,
  maxLength = 20,
  ...rest
}) => {
  const { t: translate } = useTranslation();
  const [field, meta] = useField(rest);

  let lbl = null;
  if (localePrefix !== null) {
    lbl = translate(`${localePrefix}.LABEL`);
  }
  if (label) {
    lbl = label;
  }

  const textFieldChange = (evt) => {
    if (evt.target.value.length <= maxLength) {
      meta.handleChange(evt);
    }
  };
  return (
    <>
      <MaterialUiInputField
        variant="outlined"
        fullWidth
        helperText={meta.touched && meta.error}
        placeholder={lbl}
        error={meta.touched && Boolean(meta.error)}
        onChange={textFieldChange}
        {...field}
        {...rest} />
      {showLength ? (
        <div align="right">
          {meta.value.length}/{maxLength}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

FormikInputField.propTypes = {
  maxLength: PropTypes.number,
  value: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  localePrefix: PropTypes.string,
  showLength: PropTypes.bool,
};
export default FormikInputField;
