import React from 'react';
import ComposerPanelWrapper from '../../../common/wrappers/ComposerPanelWrapper';
import CalenderSchedule from './CalenderSchedule';
import keyboard from 'asset/icons/keyboard.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Button, IconButton, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { useNavigate } from 'react-router-dom';
import EmployerDashboard from '../Home/EmployerDashboard';

const RendererExample = () => {
  return <CalenderSchedule />;
};

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: '10%',
    minHeight: '100vh',
    [theme.breakpoints.down('md')]: {
      marginTop: '10%',
    },
  },
  title: {
    ...theme.typography.title,
  },
  titleBasicDetail: {
    ...theme.typography.title,
    color: '#24283C',
  },
  image: {
    width: '8rem',
    height: '8rem',
    margin: '1rem'
  },
}));
const JobPosting = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const blankJobPosting= {
    noActiveJob: false,
  };
  const PanelConfig = {
    isOpen: false,
    anchor: 'right',
    Renderer: RendererExample, // ComponentRenderer
    rendererConfigs: {},
  };
  if (matchesSM) {
    PanelConfig.isOpen = false;
  }

  const onNewJobPostClick = () => {
    navigate('/employer/jobposting/add');
  };

  return (
    <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
      {
        blankJobPosting.noActiveJob ? (
          <Grid
            container
            direction="column"
            className={classes.mainContainer}
            spacing={1}
            alignItems="center">
            <Grid item xs={12}>
              <IconButton className={classes.image} onClick='#' disableTouchRipple>
                <img src={keyboard} alt='Add Job Posting' />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.titleBasicDetail}>
              Add Job Post
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant='subtitle2' align='center' style={{ color: '#797D89', marginBottom: '0.5rem' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus augue ante lectus sollicitudin. Mi vitae eget sit feugiat ornare felis at ultrices leo. 
              </Typography>
            </Grid>

            <Grid
              item
              container
              spacing={2}
              justifyContent="center"
              direction={matchesSM ? 'column' : 'row'}
              alignItems={matchesSM ? 'center' : undefined}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary" 
                  disableElevation
                  disableRipple
                  startIcon={
                    <Icon variant="outlined" >
                    add_circle
                    </Icon>
                  }
                  onClick={onNewJobPostClick}>
                New Job Post
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disableElevation
                  disableRipple
                  color="secondary" 
                  startIcon={<Icon >add_circle</Icon>}>
                Add/Edit Job Title
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" align='center' style={{ color: '#797D89', marginTop: '0.4rem' }}>
                <i>*You need to Add Job Title(s) before posting </i>
              </Typography>
            </Grid>
          </Grid>
        ) : (<EmployerDashboard />)
      }
      
    </ComposerPanelWrapper>
  );
};

export default JobPosting;
