import React, { useState } from 'react';
import { Button, DialogActions, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { onConfirmSlots, setSlotsConfirmed } from 'redux/actions/interviewerActions/interviewerSchedule';


const ConfirmSlotPopover = (props) => {
  const dispatch = useDispatch();
  const slots = useSelector(state => state.interviewManagement.slots);
  const [confirmed, setConfirmed] = useState(props.interviewerConfirmation || false);
  
  const handleSubmit = async (data) => {
    const body = [data?.slotID];
    
    const slot = (slots || []).map((item) => {
      return item.id === data?.event_id ? { ...item, ...{        
        interviewerConfirmation: true
      } }
        :
        item;
    });
    dispatch(onConfirmSlots(body));
    dispatch(setSlotsConfirmed(slot));
    setConfirmed(true);
  };

  return (
    <div>
      <div style={{ padding: '1rem' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography variant="subtitle1">Organiser: </Typography>
          <Typography variant="subtitle2" style={{ marginLeft: '10px' }}>{props.organiserName}</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography variant="subtitle1">Job Title:</Typography>
          <Typography variant="subtitle2" style={{ marginLeft: '10px' }}>{props.jobTitle}</Typography>
        </div>
      </div>
      <DialogActions>
        <Button variant='contained'
          color='secondary'
          id='basic-button'
          aria-controls='basic-menu'
          aria-haspopup='true'
          disabled={confirmed}
          onClick={() => handleSubmit(props)}>{confirmed? 'Confirmed' : 'Confirm' }</Button>
      </DialogActions>
    </div>
  );
};

export default ConfirmSlotPopover;