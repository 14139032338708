import React from 'react';
import UserProfileWrapper from 'view/common/wrappers/UserProfileWrapper';
import { UserForms } from 'view/common/utils/userProfileUtil';

const Register = () => {
  return (
    <UserProfileWrapper
      renderer={UserForms.loginForm}
      renderer={UserForms.registerForm} />
  );
};

export default Register;