import { CircularProgress, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { colors } from 'themes/colors';
import DPInterviewQuestion from 'view/common/DPInterviewQuestion';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  head: {
    backgroundColor: `${colors.purpule1}`,
  },
  iconBtn: {
    cursor: 'pointer',
  },
  chip: {
    margin: 1,
    backgroundColor: `${colors.purpule1}`,
  },
  longTextStyle: {
    wordWrap: 'break-word',
    maxWidth: 300,
    align: 'center',
  },
  //* imp
  circularProgress: {
    margin: '0% auto',
    display: 'flex',
    zIndex: 1,
  },
}));

export default function ViewInterviewQuestions(props) {
  const classes = useStyles();

  const { data, isLoading } = props;
  return (
    <Paper className={classes.root} key={`${props.id}_root`} elevation={0}>
      {isLoading && (
        <div>
          <CircularProgress color="secondary" className={classes.circularProgress} />
        </div>
      )}
      {data?.map((question, index) => {
        return <DPInterviewQuestion {...props} key={index + 1} question={question} questionNo={index + 1} disabled />;
      })}
    </Paper>
  );
}
