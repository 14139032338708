import { Container, Box, Typography, Button, Grid, Chip, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';

const useStyle = makeStyles(() => ({
  typeSpace: {
    padding: '0.8rem 0',
  },
  chip: {
    backgroundColor: '#6361CD', color: '#ffffff', margin: '0.6rem'
  },
}));

const SkillDetailsForm = ({ formData, id, metaData }) => {
  const { dispatcher, defaultSkillDetailsOpen } = metaData;
  const dispatch = useDispatch();
  const skills = R.pathOr([], ['skillDetails', 'skills'], formData);
  const mentorShips = R.pathOr([], ['skillDetails', 'mentorships'], formData);
  const [addSkill, setAddSkill] = useState(defaultSkillDetailsOpen === 'skills');
  const [addMentorShips, setAddMentorShips] = useState(defaultSkillDetailsOpen === 'mentorships');
  const [skillField, setSkillField] = useState('');
  const [mentorShipField, setMentorShipField] = useState('');

  const classes = useStyle();

  const profileData = useSelector(state => R.pathOr({}, ['userProfile', 'applicant'], state));

  const onDelete = (index, type, valueKey) => {
    const currentChips = R.path(['skillDetails', type], formData);
    const deletedChip = R.propOr({}, index, currentChips);
    let updatedChips = [...currentChips];
    if (R.isNil(deletedChip.id)) {
      updatedChips = R.filter((item) => {
        return item[valueKey] !== deletedChip[valueKey];
      })(currentChips);
    } else {
      updatedChips = R.assocPath(['skillDetails', type, index], R.assocPath(['deleted'], true, deletedChip), profileData);
    }
    dispatch(dispatcher(updatedChips));
  };

  const onAddSkill = () => {
    const updatedSkills = [...skills];
    updatedSkills.push({
      skill: skillField
    });
    setSkillField('');
    const updatedProfileData = R.assocPath(['skillDetails', 'skills'], updatedSkills, profileData);
    dispatch(dispatcher(updatedProfileData));
  };

  const onAddMentorShip = () => {
    const updatedMentorShips = [...mentorShips];
    updatedMentorShips.push({
      mentorshipNeeds: mentorShipField
    });
    setMentorShipField('');
    const updatedProfileData = R.assocPath(['skillDetails', 'mentorships'], updatedMentorShips, profileData);
    dispatch(dispatcher(updatedProfileData));
  };

  return (
    <Container style={{ padding: '25px 30px 15px 30px', marginTop: '0.6rem' }} id={id}>
      <Box>
        <Typography className={classes.typeSpace}><b> Skills </b></Typography>

        {
          addSkill ? (
            <Grid container marginBottom={'0.6rem'}>
              <Grid item xs={10}>
                <TextField
                  variant="outlined"
                  id="skillField"
                  name="skillField"
                  type="text"
                  placeholder='Ex. Sketching'
                  onChange={e => setSkillField(e.target.value)}
                  fullWidth
                  value={skillField}
                />
              </Grid>
              <Grid item xs={2} align={'left'}>
                <Button onClick={onAddSkill} color='secondary'> Add </Button>
              </Grid>
            </Grid>
          ) : <Button color='primary' style={{ color: '#6361CD', padding: '6px 0px' }} onClick={() => setAddSkill(true)}> Add More </Button>
        }

        {
          R.filter(item => !item.deleted, skills).map((item, index) => {
            return <Chip className={classes.chip}
              label={item.skill}
              onDelete={() => onDelete(index, 'skills', 'skill')}
            />;
          })
        }
      </Box>
      <Box>
        <Typography className={classes.typeSpace}><b>Need Mentorship In</b></Typography>
        {
          addMentorShips ? (
            <Grid container marginBottom={'0.6rem'}>
              <Grid item xs={10}>
                <TextField
                  variant="outlined"
                  id="mentorShipField"
                  name="mentorShipField"
                  type="text"
                  placeholder='Ex. UI/UX'
                  onChange={e => setMentorShipField(e.target.value)}
                  fullWidth
                  value={mentorShipField}
                />
              </Grid>
              <Grid item xs={2} align={'left'}>
                <Button onClick={onAddMentorShip} color='secondary'>Add</Button>
              </Grid>
            </Grid>
          ) : <Button color='primary' style={{ color: '#6361CD', padding: '6px 0px' }} onClick={() => setAddMentorShips(true)}> Add More </Button>
        }

        {
          R.filter(item => !item.deleted, mentorShips).map((item, index) => {
            return <Chip className={classes.chip}
              label={item.mentorshipNeeds}
              onDelete={() => onDelete(index, 'mentorships', 'mentorshipNeeds')}
            />;
          })
        }
      </Box>
    </Container>
  );
};

export default SkillDetailsForm;