import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { Grid, Button, Box, Typography, FormHelperText } from '@material-ui/core';
import moment from 'moment';

const AddSlot = ({ event, slots, setSlots }) => {
  if (event) {
    const [startDate, setStartDate] = useState(event.state?.start.value);
    const [endDate, setEndDate] = useState(event.state?.end.value);
    const [isError, setIsError] = useState(false);
      
    const handleSubmit = async () => {
      if (moment.duration(moment(endDate).diff(moment(startDate))).asHours() === 1){
        const newSlot = { start: new Date(startDate), end: new Date(endDate), color : '#6361cd' };
        setSlots({ ...slots, ...newSlot } );     
        setIsError(false);
        event.close();
      } else {
        setIsError(true);
      }
    };
  
    return (
      <div>
        <div style={{ padding: '1rem' }}>
          <Box style={{ display: 'flex' }}>
            <Typography variant='h5'>Select Slot</Typography><p style={{ marginTop: '0.1rem', marginLeft: '0.5rem' }}>(Select 1 hour slot)</p>
          </Box>
          <Box style={{ display: 'flex', marginTop: '1rem' }}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  renderInput={props => <TextField {...props} />}
                  label="Start Time"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(new Date(newValue));
                  }}
                />
              </LocalizationProvider>
              {isError && (
                <FormHelperText>{'Slot should be exactly 1 hour'} </FormHelperText>
              )}
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={props => <TextField {...props} style={{ marginLeft: '1rem' }} />}
                label="End Time"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(new Date(newValue));
                }}
              />
            </LocalizationProvider>
          </Box>
          <Grid container xs={12} style={{ margin: '1rem 0px', display: 'flex', flexDirection: 'column',alignContent: 'flex-end' }}>
            <Grid item spacing={2}>
              <Button
                variant="contained"
                type="submit"
                disabled={false}
                onClick={handleSubmit}
                color="secondary">
                Share Slot
              </Button>
              <Button
                variant="outlined"
                type="button"
                name="cancel"
                color='secondary'
                value="cancel"
                onClick={() => event.close()}
                style={{ marginLeft: '1rem' }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
};

export default AddSlot;