import * as React from 'react';
import { Grid, Box, Typography, Button, Card, CardContent, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import activity1 from 'asset/icons/activity1.svg';
import jobdetails1 from 'asset/icons/jobdetails1.svg';
import jobreport1 from 'asset/icons/jobreport1.svg';
import expandmore from 'asset/icons/expandmore.svg';
import { useDispatch } from 'react-redux';
import { onGetAllJobs } from 'redux/actions/employerActions/employerDashboard';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';

const useStyle = makeStyles(theme => ({
  typography: {
    '&.MuiTypography-root': {
      fontFamily: 'poppins',
      lineHeight: '30px',
      fontSize: '20px',
      alignSelf: 'end',
    },
  },
  jobCard: {
    marginBottom: '1.3rem',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    padding: '0.5rem 0 0'
  },
  company: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '15px',
    lineHeight: '15px',
    // textTransform: 'uppercase',
    color: '#797D89',
    marginBottom: '20px',
    fontSize: '13px',
    background: '#F0F0F0',
    borderRadius: '1px',
    padding: 0,
    [theme.breakpoints.down('sm')]:{
      height: '20px'
    }
  },
  iconBtn: {
    padding: '7px'
  },
  iconBtn1: {
    padding: '0 1rem',
    '&.MuiButtonGroup-groupedTextHorizontal:not(:last-child)': {
      borderRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  btnGroup: {
    padding: '0.5rem 0',
    width: '100%',
    justifyContent: 'space-around',
    background: 'linear-gradient(90deg, #F9F9FC 0%, #FBF6FA 100%)',
    borderRadius: '0px 0px 4px 4px',
  },
  location: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '24px',
    marginBottom: '10px'
  },
  btnMain: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'initial',
    },
  },
  btn: {
    margin: '0 13px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  cardListMain: {
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
  },
  expiryMain: {
    textAlign: 'end',
  },
  main: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  jobID: {
    display: 'inline',
    alignItems: 'center',
    marginLeft: '20px',
    border: '1px solid #8692A6',
    borderRadius: '4px',
    padding: '2px 3px',
    height: 'fit-content',
    textOverflow: 'ellipsis',
    widmaxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      marginUserCard: '10px 0',
    },
  },
  role: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '30px'
  },
  font: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500
  }
}));


const JobCardDraftView = (props) => {
  const { data } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(onGetAllJobs());
  }, []);

  const onCardClick = () => {
    navigate('/employer/jobposting/add');
  };

  const onJobDetailsClick = (e) => {
    e.stopPropagation();
    navigate(`/employer/jobPosting/edit/${data.id}`);
  };

  return (
    <Box className={classes.cardListMain}>
      <Grid container xs={12}>
        <Card elevation={0} className={classes.jobCard} style={{ width: '100%' }} onClick={onCardClick}>
          <CardContent style={{ padding: '20px 20px 0px' }}>
            <Grid container className={classes.main}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5" className={classes.role}>
                  {get(data, ['companyJobTitle','title'], '')}
                  <Typography variant="subtitle1" className={classes.jobID}>Job ID :{get(data, ['jobCode'], '')}</Typography>
                </Typography>
                <Typography variant="h3" className={classes.location} >
                  {`${get(data, ['employerLocation','city'], '')}, ${get(data, ['employerLocation','state'], '')}, ${get(data, ['employerLocation','country'], '')}`}
                </Typography>
                <Typography className={classes.company} >
                  Last Updated by: {get(data, ['lastUpdatedBy'], '')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.expiryMain}>
                <Typography variant='h6' style={{ display: 'inline-flex', alignItems: 'flex-start' }}>
                  <Typography variant="subtitle1" style={{ fontWeight: 300 }}>
                    <Typography style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: '#EA9E0C' }}>
                    ({get(data, ['jobStatus'], '')})
                    </Typography>
                    <Typography variant="subtitle1">
                      <em automation-testID="expiresDraftJob">Expires: N/A</em> 
                    </Typography>
                    {/* Data not coming from backend */}
                  </Typography>
                  <img src={expandmore} alt='menu icon' style={{ marginLeft: '18px', marginTop: '4px' }} />
                </Typography>
              </Grid>

            </Grid>
            <Grid container xs={12} style={{ margin: '20px 0' }}>
              <Typography variant="h3" className={classes.font} >
                {get(data, ['openPositions'], '')} Positions
              </Typography>
            </Grid>
          </CardContent>
          <Grid container>
            <Grid item xs={12}>
              <ButtonGroup variant="text" aria-label="text button group" color='secondary' className={classes.btnGroup}>
                <Button startIcon={<img src={jobdetails1} className={classes.iconBtn} />} className={classes.iconBtn1} onClick={onJobDetailsClick}>Job Details</Button>
                <Button startIcon={<img src={activity1} className={classes.iconBtn} />} className={classes.iconBtn1} disabled>Activity</Button>
                <Button startIcon={<img src={jobreport1} className={classes.iconBtn} />} className={classes.iconBtn1} disabled>Job Report</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Box>

  );
};

export default JobCardDraftView;