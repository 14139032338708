import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmployerActions } from '../actionTypes';
import { get } from 'lodash';
import { signIn, signOut, registerNow, onVerifyOtp, getUserProfileDetails, getEmployerProfileDetails, ResendOtp, forgotPassword } from 'redux/restAPIs/employerAPI/employerAuth';

export const onSignIn = createAsyncThunk(
  EmployerActions.AUTH_LOGIN,
  async (payload) => {
    const response = await signIn(payload);
    return response.data;
  }
);

export const onSignOut = createAsyncThunk(
  EmployerActions.AUTH_LOGOUT,
  async () => {
    const response = await signOut();
    return response.data;
  }
);

export const onRegisterNow = createAsyncThunk(
  EmployerActions.AUTH_REGISTER,
  async (payload) => {
    const { values, onregisterSuccess } = payload;
    try {
      const response = await registerNow(values, 'employer');
      if (get(response, ['data', 'success'], false)) {
        onregisterSuccess();
      }
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const onVerfiyEmail = createAsyncThunk(
  EmployerActions.AUTH_VERIFYEMAIL,
  async (payload) => {
    const response = await onVerifyOtp(payload);
    return response.data;
  }
);

export const onResendOtp = createAsyncThunk(
  EmployerActions.AUTH_RESENDOTP,
  async (payload) => {
    const response = await ResendOtp(payload);
    return response.data;
  }
);

export const onUserProfileDetails = createAsyncThunk(
  EmployerActions.AUTH_USER_PROFILE_DETAILS,
  async (payload) => {
    const response = await getUserProfileDetails(payload);
    return response.data;
  }
);

export const onEmployerProfileDetails = createAsyncThunk(
  EmployerActions.AUTH_EMPLOYER_PROFILE_DETAILS,
  async (payload) => {
    const response = await getEmployerProfileDetails(payload);
    return response.data;
  }
);

export const onForgotPassword = createAsyncThunk(
  EmployerActions.AUTH_FORGOT_PASSWORD,
  async (payload) => {
    const response = await forgotPassword(payload);
    return response.data;
  }
);