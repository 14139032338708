import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import ComposerPanelWrapper from '../../../common/wrappers/ComposerPanelWrapper';
import Profile from '../Profile/Profile';
import HeaderCards from '../../../common/components/HeaderCards/HeaderCards';
import JobCardList from './JobCardList';
import LearningCardList from './LearningCardList';
import { makeStyles } from '@material-ui/styles';
import ScheduleCard from './Schedule/ScheduleCard';
import NewsCardList from './NewsCardList';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    margin: '25px 0 20px 0 ',
  },
  mainGrid: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  cardResp: {
    padding: '8px',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));
const RendererExample = () => {
  return (
    <Profile PanelConfig={PanelConfig} />
  );
};
const PanelConfig = {
  isOpen: false,
  anchor: 'right',
  Renderer: RendererExample, // ComponentRenderer
  rendererConfigs: {},
};

const Home = () => {
  const classes = useStyle();

  return (
    <ComposerPanelWrapper {...{ panelConfig: PanelConfig }} >
      <Box className={classes.mainGrid}>
        <HeaderCards />
        <Grid container>
          <Grid item xs={12} md={7}>
            <Box className={classes.cardResp} style={{ height: '100%' }}><JobCardList /></Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box className={classes.cardResp}>
              <Typography className={classes.pageTitle}> Schedule</Typography>
              <ScheduleCard />
              <Typography variant="h6" style={{ fontSize: '20px', marginTop: '2em' }}> Learning and Development</Typography>
              <LearningCardList />
              <Typography variant="h6" style={{ fontSize: '20px', marginTop: '2em' }}> News for you</Typography>
              <NewsCardList />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ComposerPanelWrapper>
  );
};

export default Home;
