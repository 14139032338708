import { Container, Box, Grid, Typography, CircularProgress, useTheme, useMediaQuery } from '@material-ui/core';
import { userManagementTabConfigs, userManagementTabs } from 'constants/employer/userManagement';
import { get } from 'lodash';
import ComposerPanelWrapper from '../../../common/wrappers/ComposerPanelWrapper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { setSelectedTab } from 'redux/slices/employerSlices/userManagement';
import DPTabs from 'view/common/DPTabs/DPTabs';
import PaginatedUserList from './PaginatedUserList';
import UserManagementTopBar from './UserManagementTopBar';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    margin: '1rem 0rem',
  },
  flexDir: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
  breadLink: {
    color: '#24283C',
    fontWeight: 600,
  },
  //* imp
  circularProgress: {
    margin: '0% auto', display: 'flex', zIndex: 1
  },
}));

const UserManagement = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const RendererExample = () => {
    return null;
  };
  
  const PanelConfig = {
    isOpen: false,
    anchor: 'right',
    Renderer: matchesSM ? RendererExample : null, // ComponentRenderer
    rendererConfigs: {},
  };
  const pageStatus = useSelector(state => get(state, ['userManagement', 'status']));
  const currentTab = useSelector(state => get(state, ['userManagement', 'currentTab'], 'user'));

  const classes = useStyles();
  return (
    <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
      <Container style={{ marginTop: '5rem', marginBottom: '5rem', minHeight: '100vh' }}>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.pageTitle}> User Management </Typography>
            </Grid>
            <Grid container className={classes.flexDir}>
              <Grid item xs={12} md={7}>
                <UserManagementTopBar
                  currentTab={currentTab}
                  {
                    ...userManagementTabConfigs[currentTab]
                  }
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <DPTabs
                  tabList={userManagementTabs}
                  dispatcher={setSelectedTab}
                  selectedTab={currentTab}
                />
              </Grid>

            </Grid>
          </Grid>
          <PaginatedUserList
            {
              ...userManagementTabConfigs[currentTab]
            }
          />
        </Box>
        {
          pageStatus === 'loading' && (
            <CircularProgress color="secondary" className={classes.circularProgress} />
          )
        }
      </Container>
    </ComposerPanelWrapper>
  );
};

export default UserManagement;