import React from 'react';
import { Card, Grid, makeStyles, Typography, CardContent } from '@material-ui/core';
import DPLegends from '../DPLegends/DPLegends';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  box: {
    background: '#F9F9F9',
    border: '1px solid #B7B7B7',
    borderRadius: '4px'
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: '#24283C',
    marginBottom: '5px'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  q: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#24283C',
    marginBottom: '5px'
  },
  mb_5: {
    marginBottom: '5px'
  },
  mt_5: {
    marginTop: '5px'
  }
}));

const DPPrimaryDetails = ({ title, content, boxBorder, legends, question, primary }) => {
  const classes = useStyles();

  const renderComponent = (title, content) => {
    return (
      <>
        <Grid item>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
        <Grid item>
          <Typography>{content}</Typography>
        </Grid>
      </>
    );
  };
  return (
    <Card className={clsx({ [classes.box]: boxBorder })} style={{ marginTop: '20px', marginBottom: '20px' }} elevation={0}>
      <CardContent>
        <Grid container className={classes.wrapper}>
          {question ? (
            <Grid item className={classes.q}>
              {question}
            </Grid>
          ) : null}
          {!primary && renderComponent(title, content)}
          <Grid container className={clsx({ [classes.mb_5]: !!primary, [classes.mt_5]: !primary })}>
            {legends?.map(({ remark, ratings }) => (
              <DPLegends key={remark + ratings} remark={remark} ratings={ratings} />
            ))}
          </Grid>
          {!!primary && renderComponent(title, content)}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DPPrimaryDetails;

DPPrimaryDetails.defaultProps = {
  title: 'Title',
  content: 'Content',
  boxBorder: true,
  legends: [],
  primary: null
};
