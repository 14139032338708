import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Box, Typography, makeStyles, Card, CardContent, CircularProgress } from '@material-ui/core';
import CandidateCard from '../../../../common/components/CandidateCard/CandidateCard';
import blackArrow from 'asset/blackArrow.svg';
import { useNavigate } from 'react-router-dom';
import { onGetSimilarJobs } from 'redux/actions/employerActions/employerDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { formatCandidateData } from 'utils/dataFormatters';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
  },
  iconBtn: {
    padding: '7px'
  },
  jobCard: {
    marginBottom: '1.3rem',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    padding: '0.5rem 0'
  },
  percent: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'right',
    color: '#FF5B15',
    marginTop: '0.3rem'
  },
  smallBtn: {
    fontWeight: 100,
    marginTop: '0.5rem'
  },
  circularProgress: {
    margin: '0% auto', display: 'flex', zIndex: 1
  },
}));


const SimilarCandidates = ({ email }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const data = useSelector(state => get(state, ['employerDashboard', 'similarCandidate', 'content'], []));

  useEffect(() => {
    dispatch(onGetSimilarJobs({
      id: email,
    }));
  }, []);

  useEffect(()=>{
    if (data.length){
      setisLoading(false);
    } else {
      setTimeout(()=>{
        setisLoading(false);
      }, 2000);
    }
  }, [data]);

  const onArrowClick = (tabId) => {
    navigate(`/employer/activity/${params.jobId}`,{
      state: {
        currentTab: 'Recommended',
        tabId
      }
    });
  };


  return (
    <Box sx={{
      paddingTop: '25px'
    }}>

      <Grid container xs={12} alignItems="center" style={{ marginBottom: '0.6rem' }}>
        <Grid item xs={10} >
          <Typography className={classes.pageTitle}> Similar Candidates ({get(data, ['recommended'], 0)})
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'end' }}>
          <IconButton className={classes.iconBtn}>
            <img src={blackArrow} alt='Arrow Icon' onClick={() => onArrowClick('recommended')} />
          </IconButton>
        </Grid>
      </Grid>

      {
        data.length > 0 ?(formatCandidateData(data.slice(0, 4))).map((item) => {
          return (
            <CandidateCard data={item} />
          );
        })
          :
          <Grid key={'no-record-found'} item xs={12} md={12} >
            <Card style={{ boxShadow: '0px 2px 2px rgb(0 0 0 / 5%)' }}>
              <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {
                  !isLoading ? <Typography variant="subtitle2">No record found</Typography>
                    :
                    <CircularProgress color="secondary" className={classes.circularProgress} />
                }
              </CardContent>
            </Card>
          </Grid> 
      }
    </Box>
  );
};
export default SimilarCandidates;