import { Container, Grid, InputLabel, TextField } from '@material-ui/core';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

const HonorAchievementsDetail = ({ formik, id, setIsError }) => {
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setErrorMsg('');
    setIsError(false);
    if (!moment(moment().add(1, 'days').format('YYYY-MM-DD')).isAfter(formik?.values?.issueDate) && formik?.values?.issueDate) {
      setErrorMsg('Issue Date should not be greater than todays date');
      setIsError(true);
    }
  }, [formik.values.issueDate]);

  return (<Container style={{ padding: '2rem' }} id={id}>
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <InputLabel htmlFor="TextField-input">Achievement</InputLabel>
          <TextField variant="outlined"
            id="achievement"
            placeholder='Enter title'
            name="title"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.title}
            fullWidth
            helperText={formik.errors.title}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputLabel htmlFor="TextField-input">Issuer</InputLabel>
          <TextField variant="outlined"
            id="company"
            placeholder='Enter issuer'
            name="issuer"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.issuer}
            fullWidth
            helperText={formik.errors.issuer}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLabel htmlFor="TextField-input">Issue Date</InputLabel>
          <TextField
            variant="outlined"
            id="issueDate"
            name="issueDate"
            placeholder='Enter date'
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            inputProps={{
              max: moment().format('YYYY-MM-DD'),
            }}
            onChange={formik.handleChange}
            value={moment(formik.values.issueDate).format('YYYY-MM-DD')}
            helperText={formik.errors.issueDate}
          />
          {
            errorMsg && (formik.touched.issueDate) && (
              <Typography style={{ color: 'red', fontSize: '0.75rem', 'fontWeight': '400' }}>{errorMsg}</Typography>
            )
          }
        </Grid>


        <Grid item xs={12}>
          <InputLabel htmlFor="bootstrap-input">Description</InputLabel>
          <TextField
            style={{ padding: '0px 0 7px' }}
            id="multiline-static"
            placeholder="Enter description"
            multiline
            rows={4}
            variant="outlined"
            onChange={formik.handleChange}
            name="description"
            value={formik.values.description}
            fullWidth />
        </Grid>
      </Grid>
    </form>
  </Container >
  );
};
export default HonorAchievementsDetail;