export const colors = {
  // blues
  blue1: '#6360CD',
  blue2: '#2754CE',
  blue3: '#A8D5FC',
  blue4: '#1565D8',

  // grays
  gray1: '#24283C',
  gray2: '#555555',
  gray3: '#797D89',
  gray4: '#A9ABAF',
  gray5: '#CDCDCD',
  gray6: '#8692A6',
  gray7: '#E4E4E4',
  gray8: '#696F79',
  gray9: '#DCDCE2',
  // base colors
  white: '#FFFFFF',
  black: '#000000',

  // table header color
  purpule1 : '#E1E0FF',

  // background colors
  background1: '#E5E5E5',
  background2: '#43425B',
  background3: '#DAE1FB',
  background4: '#BEBDE0',
};
