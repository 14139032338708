import * as React from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { onGetNews } from 'redux/actions/applicantDashboard';
import { NEWS } from 'mockData/News';
import NewsCard from 'view/common/components/NewsCard/NewsCard';

const NewsCardList = () => {
  const [news, setNews] = useState({});
  const dispatch = useDispatch();
  const newsData = useSelector(state => get(state, ['applicantDashboard', 'news'], ''));

  useEffect(() => {
    if (newsData) {
      dispatch(onGetNews());
    }
    setNews(NEWS.articles[0]);
  }, []);

  useEffect(() => {
    if (newsData && newsData[0]) {
      setNews(newsData[0]);
    }
  }, [newsData]);
  
  
  return (
    <Box>
      <NewsCard item={news} viewAllLink='/applicant/news' />
    </Box>
  );
};
export default NewsCardList; 