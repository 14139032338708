import { Box, Button, Divider, Grid, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { colors } from '../../../../themes/colors';
import { FORM_CARD_LIST_RENDERER, FORM_CARD_TYPE } from '../../utils/formUtils';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import FormCard from '../FormCard/FormCard';
import { get } from 'lodash';
import { onUserProfileDetails } from 'redux/actions/userAuthentication';

const useStyle = makeStyles(() => ({
  formCardContainer: {
    marginBottom: '1.5rem',
  },
  title: {
    backgroundColor: 'transparent',
    fontSize: '1.2rem',
    fontWeight: '600',
    color: colors.gray2,
    margin: '1.5rem 0rem 0.6rem',
  },
  editFormContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: 4
  }
}));

const FormCardList = ({
  title,
  dataPath,
  col = 2,
  renderer = 'defaultRenderer',
  formType,
  isNew,
  meta,
  id,
  customDeleteHandler,
  profileId,
  endpoint,
  isDisable = false
}) => {
  const classes = useStyle();
  const profileData = useSelector(state => state.userProfile.applicant);
  const cardListData = useSelector(state => R.pathOr([], [...dataPath, formType], state));

  const dispatch = useDispatch();
  const [cardList, setCardList] = useState(cardListData);
  const [currentCard, setCurrentCard] = useState(-1);
  const [showCardEdit, setShowCardEdit] = useState(false);
  const [showCardAdd, setShowCardAdd] = useState(false);
  const [deleteCard, setDeleteCard] = useState({ id: null, isDeleteOpen: false, deleteTitle: null });
  const onCardEdit = (cardData) => {
    const dataIndex = R.findIndex(cardItem => cardData.id === cardItem.id)(cardListData);
    setCurrentCard(dataIndex);
    setShowCardEdit(true);
  };

  const onCardRemove = async (id) => {
    const updatedCardList = R.assocPath([R.findIndex(R.propEq('id', id))(cardList), 'deleted'], true, cardList);
    setDeleteCard({ id: null, isDeleteOpen: false, deleteTitle: null });
    await setCardList(updatedCardList);
    await customDeleteHandler(id, endpoint);
  };

  const onAddDetails = () => {
    setCurrentCard(-1);
    setShowCardEdit(true);
    setShowCardAdd(true);
  };

  const onEditClose = () => {
    setCurrentCard(-1);
    setShowCardEdit(false);
    setShowCardAdd(false);
  };

  const onSave = () => {
    setShowCardEdit(false);
    setShowCardAdd(false);
    dispatch(onUserProfileDetails());
  };

  const deleteCallback=(deleteTitle, id)=>{
    setDeleteCard({ id: id, deleteTitle: deleteTitle, isDeleteOpen: true });
  };

  const CardRenderer = FORM_CARD_LIST_RENDERER[renderer];

  const payloadSelector = (data) => {
    const currentData = get(profileData, formType, []);
    const updatedProfileData = {
      ...profileData
    };
    updatedProfileData[formType] = [...currentData, data];

    return updatedProfileData;
  };

  useEffect(() => {
    setCardList(cardListData);
  }, [cardListData]);

  return (
    <>
      <Typography variant="h3" className={classes.title}>{title}</Typography>

      <Box className={classes.formCardContainer} id={id}>
        <Box sx={{
          backgroundColor: 'white',
          borderRadius: '6px',
          pointerEvents: isDisable ? 'none' : 'all'
        }}>
          <Box></Box>
          <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              R.map(cardData => (
                <Grid item xs={12} md={12 / col} style={{ padding: '15px' }}>
                  <CardRenderer
                    data={cardData}
                    formType={formType}
                    onCardEdit={() => onCardEdit(cardData)}
                    deleteCallback={deleteCallback}
                  />
                </Grid>
              ))(cardList)
            }
          </Grid>
    
          <Modal
            open={showCardEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.editFormContainer}>
              <FormCard
                formType={FORM_CARD_TYPE[formType]}
                formDataPath={[...dataPath, formType, currentCard]}
                defaultView="editView"
                onCancel={onEditClose}
                isNew={isNew || showCardAdd}
                meta={{
                  ...meta,
                  payloadSelector
                }}
                title={title}
                onSave={onSave}
                profileId={profileId}
                endpoint={endpoint}
              />
            </Box>
          </Modal>
          <Modal
            open={deleteCard.isDeleteOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.editFormContainer}>
              <Box>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Delete
                </Typography>
              </Box>
              <Divider />
              <Typography style={{ mt: 2, marginTop: '20px' }}>
                Do you want to delete {deleteCard.deleteTitle}?
              </Typography>
              <Grid container spacing={1} justifyContent={'right'} style={{ marginTop: '20px' }}>
                <Grid item xs={0}>
                  <Button variant="contained" color="secondary" onClick={() => onCardRemove(deleteCard.id)}>Delete</Button>
                </Grid>
                <Grid item xs={1}>
                  <Button variant="outlined" onClick={() => setDeleteCard({ id: null, isDeleteOpen: false, deleteTitle: null })}>Cancel</Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>   
          <Button variant="text" color="secondary" onClick={onAddDetails} style={{ padding: '15px' }}>Add Details +</Button>
        </Box>
      </Box>
    </>
  );
};

export default FormCardList;