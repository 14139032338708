import { isEmpty } from 'lodash';
import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';
import Footer from 'view/common/components/Footer/Footer';
import Header from 'view/common/components/Header/Header';
import { routePaths } from './Constants';

const Routes = () => {
  return (
    <>
      <div className='header-container'>
        <Header routes={routePaths} />
      </div>
      <Switch>
        {!isEmpty(routePaths) && routePaths.map((route) => {
          return (
            <Route
              key={route.name}
              exact
              path={route.link}
              element={<route.component />}
            />
          );
        }) }
      </Switch>
      <div className='footer-container'>
        <Footer />
      </div>
    </>
  );
};

export default Routes;