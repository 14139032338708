import { CardContent, Grid, Typography, Box, IconButton, Divider } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import deletecard from 'asset/icons/delete.svg';
import editcard from 'asset/icons/editcard.svg';
import moment from 'moment';

const useStyle = makeStyles(() => ({
  btnContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'baseline',
  },
  iconBtn: {
    width: '15px',
    margin: '4px',
  },
  actionIcon: {
    '&.MuiIconButton-root': {
      padding: '2px',
    }
  },
  type: {
    color: '#797D89',
    fontWeight: 500,
  },
}));

const ProjectDetailsView = ({ data, onCardRemove, onCardEdit }) => {
  const classes = useStyle();
  return (
    <Box
      fullWidth >
      <CardContent style={{ padding: '5px', border: '1px' }}>
        <Grid container spacing={0} xs={12}>
          <Grid item container xs={10}>
            <Grid item xs={12} md={4} style={{ marginBottom: '1rem' }}>
              <Typography className={classes.type}>Project Title</Typography>
              <Typography>{data.projectTitle}</Typography>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginBottom: '1rem' }}>
              <Typography className={classes.type}>Duration</Typography>
              <Typography>{data.startDate && `${moment(data.startDate).format('MMM YYYY')}`}
                {data.endDate && `- ${moment(data.endDate).format('MMM YYYY')}`}</Typography>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginBottom: '1rem' }}>
              <Typography className={classes.type}>Role</Typography>
              <Typography>{data.role}</Typography>
            </Grid>
          </Grid>
          
          <Grid item xs={2} className={classes.btnContainer}>
            <IconButton className={classes.actionIcon} onClick={onCardEdit}>
              <img src={editcard} alt='edit card icon' className={classes.iconBtn} />
            </IconButton>
            <IconButton className={classes.actionIcon} onClick={onCardRemove}>
              <img src={deletecard} alt='delete card icon' className={classes.iconBtn} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ marginBottom: '1rem' }}>
            <Typography className={classes.type}>Details Of Project</Typography>
            <Typography>{data.details}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography className={classes.type}>Responsibilities</Typography>
            <Typography>{data.responsibilities}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Box>
  );
};

export default ProjectDetailsView;