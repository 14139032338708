import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { findIndex, map } from 'lodash';
import { useDispatch } from 'react-redux';

const DPTabs = ({
  tabList,
  selectedTab,
  dispatcher // In case want to store in redux
}) => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(null);

  useEffect(() => {
    setCurrentTab(selectedTab);
  }, [selectedTab]);
  

  const handleChange = (tabId) => {
    setCurrentTab(tabId);
    dispatcher && dispatch(dispatcher(tabId));
  };

  return (
    <Tabs value={findIndex(tabList, item => item.id === currentTab)}
      indicatorColor="secondary"
      textColor="secondary"
    >
      {
        map(tabList, (item, i) => {
          return <Tab key={item.label + i} label={item.label} onClick={() => handleChange(item.id)} />;
        })
      }
    </Tabs>

  );
};

export default DPTabs;