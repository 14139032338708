import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import JobCard from './JobCard';
import { Box, Typography, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { onGetReferredJobs } from 'redux/actions/applicantDashboard';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import TablePagination from '@mui/material/TablePagination';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex', alignItems: 'center', justifyContent: 'space-between'
  },
  pageTitle: {
    ...theme.typography.title,
  },
  img: {
    height: 25,
    width: 25,
    cursor: 'pointer',
  },
  icon: {
    marginLeft: 20,
  },
}));


const RefferedJobs = () => {
  const classes = useStyles();

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(3);

  const myReferredJobs = useSelector(state => get(state, ['applicantDashboard', 'myReferredJobs'], ''));
  const dispatch = useDispatch();

  useEffect(() => {
    if (myReferredJobs) {
      dispatch(onGetReferredJobs(`?pageNo=${pageNumber}&pageSize=${pageSize}&sortBy=id`));
    }
  }, [pageNumber, pageSize]);

  const handlePaginationClick = (e, selectedPage) => {
    setPageNumber(selectedPage - 1);
  };

  const handlePageSelection = (event) => {
    setPageSize(parseInt(event.target.value));
  };
  

  return (
    <Box style={{ marginTop: '2rem' }}>
      <Grid className={classes.grid}>
        <Grid item>
          <Typography className={classes.pageTitle}>Referred with me ({myReferredJobs?.totalElements})</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {!!myReferredJobs?.content?.length && myReferredJobs?.content?.map(job =>
          <Grid item xs={12} md={4}>
            <JobCard job={job} />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={0} style={{ display: 'flex', alignItems: 'flex-start' }}>
        {!!myReferredJobs?.content?.length && (
          <>
            <Grid item xs={3} style={{ display: 'flex' }}>
              <TablePagination
                component="div"
                rowsPerPageOptions={[1, 2, 3, 5, 10, 25, 50]}
                count={myReferredJobs?.totalPages || 0}
                page={pageNumber}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handlePageSelection}
              />
            </Grid>
            <Grid item xs={9} style={{ justifyContent: 'end', display: 'flex' }}>
              <Pagination
                count={myReferredJobs?.totalPages || 0}
                onChange={handlePaginationClick}
                renderItem={(item) => {
                  return <PaginationItem components={{ first: 'first', last: 'last' }} {...item} />;
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default RefferedJobs;
