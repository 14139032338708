import { createAsyncThunk } from '@reduxjs/toolkit';
import { onEducationInfo, onUserExperienceDetails, onUserPersonalDetails } from 'redux/restAPIs/applicantProfileBuilder';
import { ApplicantActions } from './actionTypes';

export const onPersonalDetailsAddEdit = createAsyncThunk(
  ApplicantActions.USER_PERSONAL_DETAILS_ADD,
  async(data) => {
    const { payload } = data;
    const response = await onUserPersonalDetails({
      ...data,
      payload: payload
    });
    return response;
  }
);

export const onExperienceDetailsAddEdit = createAsyncThunk(
  ApplicantActions.USER_EXPERIENCE_DETAILS_ADD_EDIT,
  async(payload) => {
    const response = await onUserExperienceDetails(payload);
    return response.data;
  }
);

export const onEducationInfoAddEdit = createAsyncThunk(
  ApplicantActions.USER_EDUCTAION_DETAILS_ADD_EDIT,
  async(payload) => {
    const response = await onEducationInfo(payload);
    return response.data;
  }
);
