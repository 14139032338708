import React from 'react';
import LinkSimple from 'asset/icons/LinkSimple.svg';
import { Button, Grid } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { startsWith } from 'lodash';

const DPCalendarDetailCard = ({ interviewerName, meetingLink, organiserName, link, candidateName, redirect }) =>{
  const navigate = useNavigate();
  return <div>
    <Grid>
      {!startsWith(location.pathname, '/applicant') ? 
        <p>Candidate Name : <b>{candidateName}</b></p>
        :
        <p>Interviewer : <b>{interviewerName}</b></p>
      }
    </Grid>
    <Grid>
      <img src={LinkSimple} />{'  '}
      <a target="_blank" href={meetingLink}>
        {meetingLink}
      </a>
    </Grid>
    <Grid>
      <Button
        variant='contained'
        color='secondary'
        id='basic-button'
        aria-controls='basic-menu'
        aria-haspopup='true'
        onClick={() => link ? navigate(link) : redirect()}>
            Join
      </Button>
    </Grid>
    <Grid>
      <p> Organizer : <b>{organiserName}</b></p>
    </Grid>
  </div>;
};

export default DPCalendarDetailCard;