import MailOutlineIcon from '@material-ui/icons/MailOutline';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import BuildIcon from '@material-ui/icons/Build';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import DraftsIcon from '@material-ui/icons/Drafts';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LogoutIcon from '@mui/icons-material/Logout';

const Icons = {
  MailOutlineIcon,
  NotificationsNoneIcon,
  BuildIcon,
  HelpOutlineIcon,
  AccountCircle,
  BuildOutlinedIcon,
  LanguageIcon,
  DraftsIcon,
  RoomIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedInIcon,
  PhoneIcon,
  LogoutIcon,

};

export default Icons;