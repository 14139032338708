export const interviewManagementTabs = [
  {
    id: 'interview_prep',
    label: 'Interview Prep',
  },
  {
    id: 'profile',
    label: 'Profile',
  },
  {
    id: 'job_details',
    label: 'Job Details',
  },
  {
    id: 'video_call',
    label: 'Video Call',
  },
  {
    id: 'review',
    label: 'Review',
  }
];

export const slots = [
  {
    'id': '1',
    'jobId': '1',
    'companyDetailsId': '1',
    'startTime': '2022-11-19T17:40:24.244Z',
    'endTime': '2022-11-19T17:40:24.244Z',
    'employerName': 'Rohit',
    'jobTitle': 'Frontend Developer',
    'applicantConfirmation': false,
    'interviewerConfirmation': false,
    'organiserConfirmation': false
  },
  {
    'id': '2',
    'jobId': '2',
    'companyDetailsId': '2',
    'startTime': '2022-11-20T17:40:24.244Z',
    'endTime': '2022-11-20T18:40:24.244Z',
    'employerName': 'Rohit',
    'jobTitle': 'Frontend Developer',
    'applicantConfirmation': false,
    'interviewerConfirmation': false,
    'organiserConfirmation': false
  },
  {
    'id': '3',
    'jobId': '3',
    'companyDetailsId': '3',
    'startTime': '2022-11-20T12:40:24.244Z',
    'endTime': '2022-11-20T13:40:24.244Z',
    'employerName': 'Rohit',
    'jobTitle': 'Frontend Developer',
    'applicantConfirmation': false,
    'interviewerConfirmation': false,
    'organiserConfirmation': false
  },
  {
    'id': '4',
    'jobId': '4',
    'companyDetailsId': '4',
    'startTime': '2022-11-20T14:40:24.244Z',
    'endTime': '2022-11-20T15:40:24.244Z',
    'employerName': 'Rohit',
    'jobTitle': 'Frontend Developer',
    'applicantConfirmation': false,
    'interviewerConfirmation': false,
    'organiserConfirmation': false
  }
];
