import ActionCellRenderer from './ActionCellRenderer';
import BtnCellRenderer from './BtnCellRenderer';
import ColorCellRenderer from './ColorCellRenderer';
import DefaultCellRenderer from './DefaultCellRenderer';
import DPMenuActionCellRenderer from './DPMenuActionCellRenderer';

export const CellRendererMap = {
  defaultCellRenderer: DefaultCellRenderer,
  actionCellRenderer: ActionCellRenderer,
  colorCellRenderer: ColorCellRenderer,
  btnCellRenderer: BtnCellRenderer,
  dpMenuActionCellRenderer: DPMenuActionCellRenderer
};