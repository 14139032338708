import { isNil, pathOr, props } from 'ramda';
import React, { useEffect, useState } from 'react';
import { callApi } from 'middleware/api';
import { useDispatch, useSelector } from 'react-redux';
import { onUserProfileDetails } from 'redux/actions/userAuthentication';
import { setApplicantProfile } from 'redux/slices/userProfile';
import FormCard from '../../../common/components/FormCard/FormCard';
import FormCardList from '../../../common/components/FormCardList/FormCardList';
import { FORM_CARD_TYPE } from '../../../common/utils/formUtils';
import ComposerPanelWrapper from '../../../common/wrappers/ComposerPanelWrapper';
import Profile from '../Profile/Profile';
import { isEmpty } from 'lodash';

const PanelConfig = {
  Renderer: Profile,
  rendererConfigs: {},
};

const AddEditProfile = () => {
  const profileData = useSelector(state => pathOr('', ['userProfile', 'applicant'], state));
  // eslint-disable-next-line no-unused-vars
  const [profileInfo, setProfileInfo] = useState(profileData);
  const [defaultSkillDetailsOpen, setDefaultSkillDetailsOpen] = useState('');

  const dispatch = useDispatch();

  const customDeleteHandler = async (data, endPoint) => {
    const response = await callApi(null, 'DELETE', `${endPoint}/${data}`, true);
    if (response.status == 200) {
      dispatch(onUserProfileDetails());
    }
  };

  const customSkillSubmitHandler = async (setFormView) => {
    const [skillDetails, id] = props(['skillDetails', 'id'], profileData);
    const payload = { ...skillDetails, userDetailId: id };
    payload['skills'] = payload.skills.filter((item) => {
      return !item?.deleted;
    });
    payload['mentorships'] = payload.mentorships.filter((item) => {
      return !item?.deleted;
    });
    const response = await callApi(payload, 'POST', '/profile/skilldetails', true);
    if (response.status == 200) {
      await dispatch(onUserProfileDetails());
    }
    setFormView('readView');
  };

  useEffect(() => {
    setProfileInfo(profileData);
  }, [profileData]);

  const { id } = profileInfo;
  return (
    <>
      <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
        <FormCard
          id={FORM_CARD_TYPE.personalDetails}
          title="Personal Details"
          formType={FORM_CARD_TYPE.personalDetails}
          formDataPath={['userProfile', 'applicant']}
          isNew={isNil(id)}
          profileId={id}
          endpoint={'/profile/user-details'}
        />
        <FormCardList
          isDisable={isEmpty(id)}
          id={FORM_CARD_TYPE.educationDetails}
          title="Education Details"
          formType={FORM_CARD_TYPE.educationDetails}
          dataPath={['userProfile', 'applicant']}
          isNew={isNil(id)}
          customDeleteHandler={customDeleteHandler}
          profileId={id}
          endpoint={'/profile/educations'}
        />
        <FormCardList
          isDisable={isEmpty(id)}
          id={FORM_CARD_TYPE.professionalDetails}
          title="Professional Details"
          formType={FORM_CARD_TYPE.professionalDetails}
          dataPath={['userProfile', 'applicant']}
          col={1}
          isNew={isNil(id)}
          customDeleteHandler={customDeleteHandler}
          profileId={id}
          endpoint={'/profile/experiences'}
        />
        <FormCard
          isDisable={isEmpty(id)}
          id={FORM_CARD_TYPE.skillDetails}
          title="Skill Details"
          formType={FORM_CARD_TYPE.skillDetails}
          formDataPath={['userProfile', 'applicant']}
          isNew={isNil(id)}
          profileId={id}
          endpoint={'/profile/skilldetails'}
          customSubmitHandler={customSkillSubmitHandler}
          meta={{
            dispatcher: setApplicantProfile,
            setDefaultSkillDetailsOpen,
            defaultSkillDetailsOpen
          }}
        />
        <FormCardList
          isDisable={isEmpty(id)}
          id={FORM_CARD_TYPE.achievementDetails}
          title="Honor & Achievements"
          formType={FORM_CARD_TYPE.achievementDetails}
          dataPath={['userProfile', 'applicant']}
          col={1}
          isNew={isNil(id)}
          customDeleteHandler={customDeleteHandler}
          profileId={id}
          endpoint={'/profile/achievements'}
        />
        <FormCardList
          isDisable={isEmpty(id)}
          id={FORM_CARD_TYPE.certificationDetails}
          title="License & Certifications"
          formType={FORM_CARD_TYPE.certificationDetails}
          dataPath={['userProfile', 'applicant']}
          col={2}
          isNew={isNil(id)}
          customDeleteHandler={customDeleteHandler}
          profileId={id}
          endpoint={'/profile/certifications'}
        />
        <FormCardList
          isDisable={isEmpty(id)}
          id={FORM_CARD_TYPE.socialContributions}
          title="Social Contribution"
          formType={FORM_CARD_TYPE.socialContributions}
          dataPath={['userProfile', 'applicant']}
          col={1}
          isNew={isNil(id)}
          customDeleteHandler={customDeleteHandler}
          profileId={id}
          endpoint={'/profile/socialcontributions'}

        />
        <FormCardList
          isDisable={isEmpty(id)}
          id={FORM_CARD_TYPE.researchAndInnovations}
          title="Research & Innovation"
          formType={FORM_CARD_TYPE.researchAndInnovations}
          dataPath={['userProfile', 'applicant']}
          col={1}
          isNew={isNil(id)}
          customDeleteHandler={customDeleteHandler}
          profileId={id}
          endpoint={'/profile/innovations'}
        />
        <FormCardList
          isDisable={isEmpty(id)}
          id={FORM_CARD_TYPE.projectDetails}
          title="Project Details"
          formType={FORM_CARD_TYPE.projectDetails}
          dataPath={['userProfile', 'applicant']}
          col={1}
          renderer={'projectDetailsView'}
          isNew={isNil(id)}
          customDeleteHandler={customDeleteHandler}
          profileId={id}
          endpoint={'/profile/projectdetails'}
        />
      </ComposerPanelWrapper>
    </>
  );
};

export default AddEditProfile;
