import { isEmpty } from 'lodash';
import { getInterviewDate, getInterviewTime } from 'utils/dataFormatters';

export const formatData = (data) => {
  if (!isEmpty(data)) {
    const { interviewDetail, jobDetail } = data;
    const { startTime, endTime, type } = interviewDetail;
    const { position } = jobDetail;
    return [
      { key: 'Date & Time', value: `${getInterviewDate(startTime)} \n${getInterviewTime(startTime)} - ${getInterviewTime(endTime)}` },
      { key: 'Job Title', value: position },
      { key: 'Interviewer', value: 'Nelson Thomas (Project Lead)' },
      { key: 'Interview Type', value: type }
    ];
  } else {
    return [];
  }
};