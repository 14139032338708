import * as React from 'react';
import { Box, Button, InputLabel, TextField, Grid, Typography, Select } from '@material-ui/core';
import { FormikSelectField } from '../../../ui-components';
import { useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { onGetAllJobLocation, onGetAllJobTitle } from 'redux/actions/employerActions/Configurations/jobActions';
import { Divider } from '@mui/material';

const useStyle = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
  },
  cardContainer: {
    margin: '1rem 2rem',
    backgroundColor: 'white'
  },
  spanText: {
    color: '#000',
    paddingLeft: '0.5rem'
  }
}));

const INITIAL_VALUES = {
  0: {
    jobTitle: '',
    location: '',
    postedOn: '',
    expiryDate: '',
    status: '',
  },
  1: {
    jobTitle: '',
    location: '',
    postedOn: '',
    postedBy: '',
  }

};
const DecisionFilter = ({ onClose, currentTab }) => {
  const [jobTitleList, setJobTitleList] = useState([]);
  const [jobLocationList, setJobLocationList] = useState('');
  const dispatch = useDispatch();
  let titlesList = [];
  let locationList = [];

  const formik = useFormik({
    initialValues: INITIAL_VALUES[currentTab],
    onSubmit: () => { }
  });

  const onClearClick = (formik) => {
    formik.resetForm();
  };
  const classes = useStyle();

  const GetAllJobLocation = () => {
    dispatch(onGetAllJobLocation({})).then((response) => {
      if (response && response.payload) {
        const list = response.payload.map((location) => {
          return { ...location, name: `${location.city}, ${location.state}` };
        });
        setJobLocationList(list);
      }
    });
  };

  const GetAllJobTitle = () => {
    dispatch(onGetAllJobTitle({})).then((response) => {
      if (response && response.payload) {
        const jobTitles = response.payload.map((job) => {
          return {
            ...job,
            name: job.title
          };
        });
        setJobTitleList(jobTitles);
      }
    });
  };

  useEffect(() => {
    GetAllJobTitle();
    GetAllJobLocation();
  }, []);

  jobTitleList && jobTitleList.find((job) => {
    titlesList.push(job.title);
  });

  jobLocationList && jobLocationList.find((location) => {
    locationList.push({ id: location.id, name: `${location.ciy, location.state}` });
  });



  return (

    <Formik>
      <Box className={classes.cardContainer}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <Typography variant="h4">Filters</Typography>
              <Button
                style={{ marginLeft: '1rem' }}
                onClick={() => onClearClick(formik)}
                variant='text'
                color='secondary'
              >
                Clear All
              </Button>
            </Grid>
            <Divider style={{ width: 'inherit' }} />
            {jobTitleList && jobTitleList.length > 0 && 
            <Grid item xs={12} md={4}>
              <div className={classes.jobTitileSection}>
                <InputLabel htmlFor="TextField-input" required>
                  Job Title
                </InputLabel>
              </div>
              <FormikSelectField
                localePrefix="DHIYO.JOBPOSTING_ADD.JOB_POSTING_FORM.JOBTITLE"
                dropDownData={jobTitleList}
                className={classes.fieldWidth}
                id="jobTitle"
                name="jobTitle"
                onChange={formik.handleChange}
                value={formik.values.jobTitle}
                fullWidth
              />
            </Grid>}

            {jobLocationList && jobLocationList.length > 0 && 
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="TextField-input" required>
                Job Location
              </InputLabel>
              <FormikSelectField
                localePrefix="DHIYO.JOBPOSTING_ADD.JOB_POSTING_FORM.JOBLOCATION"
                dropDownData={jobLocationList}
                className={classes.fieldWidth}
                onChange={formik.handleChange}
                value={formik.values.location}
                id="location"
                name="location"
              />
            </Grid>}

            <Grid item xs={12} md={4}>
              <InputLabel>Posted On</InputLabel>
              <TextField
                variant="outlined"
                id="postedOn"
                placeholder='postedOn'
                type="date"
                onChange={formik.handleChange}
                value={formik.values.postedOn}
                fullWidth
              />
            </Grid>
            {currentTab === 1 && (
              <>
                <Grid item xs={12} md={4}>
                  <InputLabel>Posted by</InputLabel>
                  <Select
                    variant='outlined'
                    id='postedBy'
                    name='postedBy'
                    type='postedBy'
                    fullWidth
                    value={formik.values.postedBy}
                  >
                    <MenuItem value={'Teacher'}>Teacher</MenuItem>
                    <MenuItem value={'hr'}>Hr</MenuItem>
                    <MenuItem value={'developer'}>Developer</MenuItem>
                  </Select>
                </Grid>
              </>
            )}
            {currentTab === 0 && (
              <>
                <Grid item xs={12} md={4}>
                  <InputLabel>Expiry Date</InputLabel>
                  <TextField
                    variant="outlined"
                    id="expiryDate"
                    placeholder='expiryDate'
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values.expiryDate}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel> Status</InputLabel>
                  <Select
                    fullWidth
                    variant='outlined'
                    id='status'
                    name='status'
                    onChange={formik.handleChange}
                    value={formik.values.status}
                  >
                    <MenuItem value={'UI designer'}>UI designer</MenuItem>
                    <MenuItem value={'Angular developer'}>Angular developer</MenuItem>
                    <MenuItem value={'Software Tester'}>Software Tester</MenuItem>
                  </Select>
                </Grid>
              </>
            )}
            <Grid container xs={12} style={{ margin: '1rem 10px' }}>
              <Button
                variant="outlined"
                type="button"
                name="cancel"
                color='secondary'
                value="cancel"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button variant="contained" color='secondary' type="submit" style={{ marginLeft: '1rem' }}>Apply</Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Formik>

  );
};

export default DecisionFilter;