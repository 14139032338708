import React, { useEffect, useState } from 'react';
import { Scheduler } from '@aldabil/react-scheduler';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { startsWith } from 'lodash';
import DPCalendarDetailCard from './DPCalendarDetailCard';
import AddSlot from '../components/AddSlots/AddSlots';
import ScheduleInterview from '../../employer/pages/Calendar/ScheduleInterview/ScheduleInterview';
import './DPCalendar.css';

const useStyles = makeStyles(theme => ({
  calendarContainer: {
    backgroundColor: 'white',
    paddingTop: '3em',
    paddingLeft: '2em',
    paddingRight: '2em',
    marginRight: '2em',
    marginTop: '0.5em',
    paddingBottom: '10em',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '3em',
      paddingLeft: '2em',
      paddingRight: '2em',
      paddingBottom: '5em',
    },
  },
}));

const DPCalendar = ({ events, CustomEditor, fetchRemoteData, usedIn = 'DPCalendar', startTime = 0, interviewerSlot, isEmployer, currentSlot, setCurrentSlot }) => {
  const [eventsData, setEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const cellClickable = (isEmployer && interviewerSlot.interviewer) ? {} : { cellRenderer: () => <></> };
  
  useEffect(async () => {
    await setEventsData(events || []);
    const timeoutID = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [events]);

  const renderPopupComponent = (eachEvent) => {
    if (eachEvent.organiserConfirmation || !startsWith(location.pathname, '/employer') ){
      return <DPCalendarDetailCard {...eachEvent} />;
    }
    if (eachEvent.applicantConfirmation && startsWith(location.pathname, '/employer') ){
      return <ScheduleInterview {...eachEvent} />;
    }
  };

  const classes = useStyles();
  return <Grid container className="dPCalendarWrapper">
    <Grid item spacing={1} className={classes.calendarContainer}>
      <Scheduler
        key={usedIn}
        events={eventsData}
        view="day"
        loading={isLoading}
        getRemoteEvents={fetchRemoteData}
        month={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 6,
          startHour: startTime,
          endHour: 23,
          cellRenderer: () => <></>
        }}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 6,
          startHour: startTime,
          endHour: 23,
          step: 60,
          ...cellClickable
        }}
        day={{
          startHour: startTime,
          endHour: 23,
          step: 60,
          ...cellClickable
        }}
        customEditor={event => <AddSlot event={event} slots={currentSlot} setSlots={setCurrentSlot} />}
        viewerExtraComponent={(_, eachEvent) => {
          return (
            (eachEvent.confirmByInterviewer && eachEvent.confirmByApplicant) || !CustomEditor ?
              renderPopupComponent(eachEvent)
              :
              <CustomEditor {...eachEvent} />
          );
        }}
      />
    </Grid>

  </Grid>;
};
export default DPCalendar;