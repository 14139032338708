import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { colors } from 'themes/colors';
import StaticQuestion from 'view/employer/pages/Configuration/StaticQuestion';

const useStyles = makeStyles(theme => ({
  formContainer: {
    backgroundColor: 'white',
    padding: '2em',

    [theme.breakpoints.down('md')]: {
      marginTop: '2em',
      paddingLeft: '0.25em',
      paddingRight: '0.25em',
      paddingBottom: '1em',
    },
  },
  titleBasicDetail: {
    ...theme.typography.title,
    color: '#24283C',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '30px',
  },
  subHeading: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: colors.gray1,
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.gray1,
  },
  text: {
    fontWeight: 400,
    fontSize: 12,
    color: colors.gray3,
  },
  question: {
    fontWeight: 400,
    fontSize: 16,
    color: colors.gray1,
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
  },
  radioBtnLabel: {
    fontFamily: 'poppins',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    color: '#24283C',
  },
  questionSection : {
    marginTop: '1rem',
    padding: '10px'
  }
}));

const CompanyInformation = ({ jobId }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <>
      <Grid item className={classes.formContainer} container direction="column" spacing={1}>
        <Grid item container direction="column">
          <Grid>
            <Typography className={classes.titleBasicDetail}>{translate('DHIYO.JOBPOSTING_ADD.FORM-04.HEADER.BASIC')}</Typography>
          </Grid>

          <Grid item>
            <Typography className={classes.subHeading} variant="body2">
              {translate('DHIYO.JOBPOSTING_ADD.FORM-04.HEADER.BASIC_DETAILS')}
            </Typography>
          </Grid>
        </Grid>
        <StaticQuestion showContent={true} usedIn="JobPostPreview" jobID={jobId} />
      </Grid>
    </>
  );
};

export default CompanyInformation;
