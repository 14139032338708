import React, { useState, useEffect } from 'react';
import { Typography, Tab, Tabs, makeStyles, Button, Breadcrumbs, Icon, Menu } from '@material-ui/core';
import * as R from 'ramda';
import DPTable from 'view/common/DPTable';
import { AJNestedTabWiseList, AJTabColumns } from 'constants/employer/AllJobPosting';
import { Grid } from '@mui/material';
import JobsPostingFilter from './JobsPostingFilter';
import { onGetJobsCriteria } from 'redux/actions/employerActions/employerDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { get } from 'lodash';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    marginBottom: '1rem',
    marginTop: '3rem',
  },
  filterBtn: {
    marginLeft: '100px'
  },
  mainContainer: {
    paddingTop: '3em',
    paddingLeft: '10em',
    paddingRight: '10em',
    paddingBottom: '10em',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2em',
      paddingRight: '2em',
      paddingBottom: '3em',
    },
  },
}));

const AllJobPostings = () => {
  const classes = useStyle();
  const [currentTab, setCurrentTab] = useState(0);
  const employerDetails = useSelector(state => get(state, ['userProfile','employer'], ''));
  const recentJobsTableData = useSelector(state => get(state, ['employerDashboard', 'jobsByCriteria', 'RECENT_JOBS', 'content'], [] ));
  const historyTableData = useSelector(state => get(state, ['employerDashboard', 'jobsByCriteria', 'HISTORY', 'content'], [] ));
  const [entryStatus, setEntryStatus] = useState(currentTab);
  const navigate = useNavigate();

  const totalRecentJobsTableData = useSelector(state => get(state, ['employerDashboard', 'jobsByCriteria', 'RECENT_JOBS', 'totalElements'], [] ));
  const totalHistoryTableData = useSelector(state => get(state, ['employerDashboard', 'jobsByCriteria', 'HISTORY', 'totalElements'], [] ));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const rowData = currentTab === 0 ? recentJobsTableData : historyTableData;
  const totalElements = currentTab === 0 ? totalRecentJobsTableData : totalHistoryTableData;
  
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
    setEntryStatus(newValue);
    setPageSize(10);
    setPageNumber(0);
  };
  


  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetJobsCriteria({
      entryStatus: entryStatus === 0 ? ['OPEN', 'HIRED', 'EXPIRED'] : ['CLOSED'],
      companyDetailsId: employerDetails.id,
      pageNo: pageNumber,
      pageSize: pageSize,
      sortBy: ['lastUpdatedOn'],
      currentTab: currentTab === 0 ? 'RECENT_JOBS' : 'HISTORY'
    }));
  }, [currentTab, pageNumber, pageSize]);

  const onNewJobPostClick = () => {
    navigate('/employer/jobposting/add');
  };

  return (
    <Grid
      container
      direction="column"
      className={classes.mainContainer}
      spacing={1}>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="secondary"
            to="/employer/jobposting"
            className={`${classes.linkLabel} MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary`}>
            Job Posting
          </Link>
          <Typography className={classes.label}>
            All Job Posting
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container spacing={0} className={classes.pageTitleContainer} alignItems="baseline">
        <Grid item xs={12}>
          <Typography className={classes.pageTitle}>All Job Posting</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example" >
            {
              R.map((tab) => {
                return <Tab label={tab.label} {...a11yProps(tab.id)} />;
              })(AJNestedTabWiseList)
            }
          </Tabs>
        </Grid>

        <Grid item xs={12} md={3} style={{ paddingBottom: '2rem', textAlign: 'end' }}> 
          <Button
            onClick={handleOpen}
            color='secondary'
            type="button"
            variant="outlined"
            style={{ marginRight: '1rem' }}
          >Filters</Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <JobsPostingFilter onClose={handleClose}
              currentTab={currentTab}
              formLabel={AJNestedTabWiseList[currentTab]} />
          </Menu>
          {currentTab === 0 && (
            <>
              <Button
                variant="contained"
                color="secondary"
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                onClick={onNewJobPostClick}
                startIcon={<Icon>add_circle</Icon>}>
                New Job Post
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <DPTable
        columns={AJTabColumns[AJNestedTabWiseList[currentTab].id]}
        rowsData={rowData}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        totalElements={totalElements}
      />
    </Grid>
  );
};

export default AllJobPostings; 