
import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Box, Icon, Card, CardContent, Avatar, Tabs, Tab, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import { useNavigate } from 'react-router-dom';
import RoundOneIcon from 'asset/icons/round1.svg';
import RoundTwoIcon from 'asset/icons/round2.svg';
import RoundThreeIcon from 'asset/icons/round3.svg';
import scheduleIcon from 'asset/icons/scheduleCalendarIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { onGetInterviewerSlots, onInterviewerMyScheduleWithFilter, onMySchedule } from 'redux/actions/interviewerActions/interviewerSchedule';
import { startsWith } from 'lodash';
import moment from 'moment';
import { calendarTab, scheduleFilterTab } from 'constants/applicant/applicant';
import clsx from 'clsx';
import { getScheduleFilterDate } from 'view/common/utils/dateUtils';
import ConfirmSlotCalendar from '../ConfirmSlotCalendar/ConfirmSlotCalendar';
import ScheduleInterviewCalendar from '../ScheduleInterviewCalendar/ScheduleInterviewCalendar';
import CandidateSelection from '../CandidateSelection/CandidateSelection';
import DPCalendar from 'view/common/DPCalendar/DPCalendar';
import { onShareSlot, onGetInterviewSlots } from 'redux/actions/employerActions/interviewScheduled';
import { getFormatedDateSlot, getFormatedDate } from 'view/common/utils/dateUtils';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    // width: '100%',
    paddingLeft: '2em',
    boxSizing: 'initial',
    paddingBottom: '2em',
    flex: '0 auto',
    [theme.breakpoints.down('md')]: {
      marginTop: '3em',
      paddingLeft: '2em',
      paddingBottom: '5em',
    },
  },
  calenderSection: {
    padding: '8px 0px',
    flexGrow: '1',
    marginTop: '3rem',
  },
  scheduleSection: {
    minHeight: '130vh',
    paddingRight: '1.5em',
    [theme.breakpoints.up('sm')]: {
      width: '27%',
      flexShrink: 0,
      backgroundColor: '#E5E5E5',
    },
  },
  scheduleView: {
    padding: '16px 30px 30px',
    marginTop: '3rem',
  },
  title: {
    ...theme.typography.title,
  },
  linkLabel: {
    ...theme.typography.linkLabel,
  },
  label: {
    ...theme.typography.linkLabel,
    fontWeight: 400,
  },
  disabled: {
    '&$disabled': {
      textAlign: 'center',
    },
  },

  experienceSelectBtn: {
    height: '50px',
  },
  positionSection: {
    display: 'flex',
    width: '30%',
  },

  inputLabel: {
    color: '#000',
    marginBottom: '0.5rem',
  },
  chipSelect: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
  },
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px'
  },
  calendarContainer: {
    backgroundColor: 'white',
    paddingTop: '3em',
    paddingLeft: '2em',
    paddingRight: '2em',
    marginRight: '2em',
    marginTop: '0.5em',
    paddingBottom: '10em',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '3em',
      paddingLeft: '2em',
      paddingRight: '2em',
      paddingBottom: '5em',
    },
  },
  titleBasicDetail: {
    display: 'initial',
    ...theme.typography.h5,
    color: '#555555', // theme.palette.common.gray4,
  },

  roundLabel: {
    alignSelf: 'baseline'
  },
  emptyScheduleList: {
    display: 'flex',
    margin: 'auto',
  },
  main: {
    padding: '16px 30px 30px',
    marginTop: '3rem'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContent: {
    padding: '10px',
  },
  card: {
    marginTop: '15px',
    borderRadius: 4,
    boxShadow: '0px 2px 6px rgb(0 0 0 / 5%)',
    height: 'auto',
    lineHeight: '18px',
    cursor: 'pointer'
  },
  scheduleButton: {
    float: 'right',
    right: '26px',
    minWidth: 'max-content'
  },
  tab: {
    minWidth: '130px'
  },
  applyConfirmation: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    backgroundColor: 'white',
    boxShadow: 24,
    padding: '1rem'
  },
}));

export default function CalendarLanding({ isEmployer }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const myScheduleData = useSelector(state => state.interviewManagement.mySchedule);
  const calendarSchedule = useSelector(state => state.interviewManagement.myCustomSchedule);
  const loggedInUserRole = localStorage.getItem('deepailytics.user.role');
  const slots = useSelector(state => state.interviewManagement.slots);
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [interviewerSlot, setInterviewerSlot] = useState({ jobRole: '', interviewer: '', tes: '' });
  const [candidateSlot, setCandidateSlot] = useState({ jobRole: '', candidate: '' });
  const [interviewSlots, setInterviewSlots] = useState([]);
  const [filterTab, setFilterTab] = useState(0);
  
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const allInterviewSlots = useSelector(state => state.employerInterviewManagement.interviewerSlots);

  useEffect(() => {
    if (allInterviewSlots?.slots?.length || myScheduleData?.interviewDetails?.length) {
      const data = prepareSlots(allInterviewSlots);
      if (data){
        setInterviewSlots([...data, ...prepareCalendarJson()]);
      }
      
    }
  }, [allInterviewSlots, interviewerSlot, candidateSlot, myScheduleData]);

  const filterProps = (index) => {
    return {
      id: `filter-tab-${index}`,
      'aria-controls': `filter-tabpanel-${index}`,
    };
  };

  const a11yProps = (index) => {
    return {
      id: `sample-tab-${index}`,
      'aria-controls': `sample-tabpanel-${index}`,
    };
  };

  const handleFilterTabChange = (event, newValue) => {
    setFilterTab(newValue);
  };

  useEffect(async () => {
    setIsLoading(true);
    const currentFilter = await getScheduleFilterDate(filterTab);
    await dispatch(onMySchedule(currentFilter));
    setIsLoading(false);
  }, [filterTab]);

  const isApplicant = localStorage.getItem('deepailytics.user.role') === 'ROLE_APPLICANT';

  let decideLabelByRound = (round) => {
    switch (round) {
    case 1:
      return RoundOneIcon;
    case 2:
      return RoundTwoIcon;
    default:
      return RoundThreeIcon;
    }
  };

  const generateScheduleList = () => {
    return (myScheduleData?.interviewDetails || []).map(eachEvent => ({
      name: startsWith(location.pathname, '/applicant') ? 
        eachEvent?.jobDetail?.companyName 
        : 
        `${eachEvent?.applicantDetail?.firstName} ${eachEvent?.applicantDetail?.lastName} `,
      interviewerName: startsWith(location.pathname, '/applicant') ? 
        `Interviewer: ${eachEvent?.interviewDetail?.interviewerName}` 
        : 
        `Organiser: ${eachEvent?.interviewDetail?.organiserName}`,
      label: decideLabelByRound(eachEvent?.interviewDetail?.roundNo),
      position: `Position: ${eachEvent?.jobDetail?.position}`,
      date: eachEvent?.interviewDetail?.startTime,
      interviewId: eachEvent?.interviewDetail?.id
    }));
  };

  // To do : require this feature in future
  // const decideCalendarCardColorByRound = (round) => {
  //   switch (round) {
  //   case 1:
  //     return '#2953E8';
  //   case 2:
  //     return '#FF7100';
  //   case 3:
  //     return '#53BA02';
  //   default:
  //     return '#000000';
  //   }
  // };

  // To do : require this feature in future
  function prepareCalendarJson() {
    return (myScheduleData?.interviewDetails || []).map((eachEvent, index) => ({
      event_id: index,
      organiserConfirmation: true,
      title: `Applicant : ${eachEvent?.applicantDetail?.firstName} ${eachEvent?.applicantDetail?.lastName}`,
      start: new Date(eachEvent?.interviewDetail?.startTime),
      end: new Date(eachEvent?.interviewDetail?.endTime),
      color: '#64C364',
      meetingLink: eachEvent?.interviewDetail?.interviewMeetingDetailsDTO?.interviewMeetingUrl,
      organiserName: `${eachEvent?.organiserName}`,
      interviewerName: `${eachEvent?.applicantDetail?.firstName} ${eachEvent?.applicantDetail.lastName}`,
      link: startsWith(location.pathname, '/employer') ? `/employer/interviewer/${eachEvent?.interviewDetail?.id}` : `/applicant/calendar/${eachEvent?.interviewDetail?.id}`
    }));
  }

  const getColor = (itemData) => {
    if (itemData.organiserConfirmation){
      return '#64C364';
    }
    if (itemData.applicantConfirmation){
      return '#e5d5f7';
    }
    if (itemData.interviewerConfirmation){
      return '#A8D5FC';
    }
    return '#6361cd';
  };

  const iterateSlots = (slotDetails) => {
    return slotDetails.map((itemData, index) => ({
      ...itemData,
      event_id: index+1,
      title: `Interviewer : ${itemData?.interviewers[0]?.name}`,
      start: new Date(itemData?.startTime),
      end: new Date(itemData?.endTime),
      color: getColor(itemData)
    }));
  };

  function prepareSlots(data) {
    if (interviewerSlot.jobRole || candidateSlot.jobRole){
      const slotDetails = interviewerSlot.jobRole ? interviewerSlot : candidateSlot;
      if (slotDetails.candidate){
        return iterateSlots(
          data?.slots
            .filter(appointments => appointments.jobId === slotDetails.jobRole)
            .filter(interviewerSlot=> (!interviewerSlot.applicantConfirmation && interviewerSlot.interviewerConfirmation && !interviewerSlot.organiserConfirmation
            ))
        );
      }
      if (slotDetails.interviewer){
        return iterateSlots(
          data?.slots
            .filter(appointments => appointments.jobId === slotDetails.jobRole)
            .filter(interviewerSlot=> interviewerSlot.interviewers[0].interviewerId === slotDetails.interviewer && !interviewerSlot.organiserConfirmation)
        );
      }
      return iterateSlots(data?.slots.
        filter(appointments => appointments.jobId === slotDetails.jobRole)
        .filter(interviewerSlot=> !interviewerSlot.organiserConfirmation));
    }
  }

  const onScheduleClick = () => {
    navigate('/employer/schedule');
  };

  const [currentSlot, setCurrentSlot] = useState();
  useEffect(async() => {
    if (currentSlot) {
      setInterviewSlots([ ...interviewSlots, currentSlot]);
      await dispatch(onShareSlot({
        jobDetailId: interviewerSlot.jobRole,
        interviewers: [
          interviewerSlot.interviewer
        ],
        slots: [
          {
            startTime: getFormatedDateSlot(currentSlot.start),
            endTime: getFormatedDateSlot(currentSlot.end),
          }
        ]
      }
      ));
    }
  }, [currentSlot]);

  const renderTabComponent = () => {
    if (loggedInUserRole === 'ROLE_APPLICANT' || loggedInUserRole === 'ROLE_INTERVIEWER') {
      return <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example" style={{ marginBottom: '20px' }}>
        {
          R.map((tab) => {
            return <Tab label={tab.label} {...a11yProps(tab.id)} />;
          })(calendarTab)
        }
      </Tabs>;
    }
    return null;
  };

  const fetchRemoteData = (query) => {
    const { start, end } = query;
    const filter = {
      startDate: getFormatedDate(start),
      endDate: getFormatedDate(end)
    };
    dispatch(onGetInterviewSlots(filter));
    dispatch(onMySchedule(filter));
  };

  const renderExtraComponent = () => {
    if (loggedInUserRole === 'ROLE_APPLICANT' || loggedInUserRole === 'ROLE_INTERVIEWER') {
      return currentTab === 0 ?
        <ConfirmSlotCalendar currentTab={currentTab} dispatcher={onGetInterviewerSlots} slots={slots} usedIn="applicant" />
        :
        <ScheduleInterviewCalendar currentTab={currentTab} dispatcher={onInterviewerMyScheduleWithFilter} data={calendarSchedule} usedIn="applicant" />;
    }
    // return <DPCalendar events={prepareCalendarJson()} />;
    return <DPCalendar isEmployer events={interviewSlots} fetchRemoteData={fetchRemoteData}
      interviewerSlot={interviewerSlot} currentSlot={currentSlot} setCurrentSlot={setCurrentSlot} startTime={9} />;
  };

  const myScheduleList = generateScheduleList();

  return (
    <Grid className={classes.mainContainer}>
      <Box className={classes.calenderSection}>
        {isEmployer && (
          <CandidateSelection
            interviewerSlot={interviewerSlot}
            setInterviewerSlot={setInterviewerSlot}
            setCandidateSlot={setCandidateSlot}
            candidateSlot={candidateSlot}
            setInterviewSlots={setInterviewSlots}
            interviewSlots={interviewSlots}
            prepareSlots={prepareSlots}
          />
        )}
        {!isEmployer && (
          <Grid item xs={12}>
            <div style={{ display: 'flex' }}>
              <Grid item xs={12} md={9}>
                <>
                  {renderTabComponent()}
                  <Typography variant='h5'>Calendar</Typography>
                </>
              </Grid>
              <Grid item xs={12} md={3}>
                <>
                  {!isApplicant && <Button
                    variant='contained'
                    color='secondary'
                    id='basic-button'
                    aria-controls='basic-menu'
                    aria-haspopup='true'
                    onClick={onScheduleClick}
                    className={classes.scheduleButton}
                    startIcon={<Icon>add_circle</Icon>}>
                  Schedule Interview
                  </Button>}
                </>
              </Grid>
            </div>
          </Grid>
        )}
        {renderExtraComponent()}
      </Box>

      <Box className={classes.scheduleSection}>
        <Grid
          className={classes.scheduleView}
        >
          <Grid item md={12} >
            <Typography
              align='left'
              variant='h5'
              className={classes.titleBasicDetail}>
              Today's Schedule
            </Typography>
          </Grid>
          <Grid item xs={6} md={12} >
            <Tabs value={filterTab} onChange={handleFilterTabChange} aria-label="my-filter-schedule" >
              {
                R.map((tab) => {
                  return <Tab key={tab.label} label={tab.label} {...filterProps(tab.id)} className={classes.tab} />;
                })(scheduleFilterTab)
              }
            </Tabs>
          </Grid>
          <Grid item xs={12} className={clsx({ [classes.circularProgress]: isLoading })}>
            {!isLoading && myScheduleList && myScheduleList.length > 0 ?
              myScheduleList.map((cards, index) => (
                <Card elevation={0}
                  className={classes.card}
                  key={index}
                  onClick={() => navigate(startsWith(location.pathname, '/employer') ? `/employer/interviewer/${cards.interviewId}` : `/applicant/calendar/${cards.interviewId}`)}>
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={0} className={classes.gridContainer}>
                      <Grid item xs={3} style={{ borderRight: '1px solid #cccccc', paddingRight: '3px' }}>
                        <span style={{ fontSize: '24px' }}><b>{moment(cards.date).format('Do')}</b></span> <span style={{ fontSize: '11px' }}>{moment(cards.date).format('MMM')}, {moment(cards.date).format('YYYY')}</span><br />
                        <span style={{ fontSize: '14px' }}><b>{moment(cards.date).format('h:mm a')}</b></span>
                      </Grid>
                      <Grid item xs={9} style={{ display: 'flex' }}>
                        <Avatar style={{ margin: '0 7px' }} />
                        <Typography variant='h6'>
                          {cards.name}
                          <Typography variant='body1' style={{ fontSize: '13px' }}>
                            {cards.interviewerName}
                          </Typography>
                          <Typography variant='body1' style={{ fontSize: '13px' }}>
                            {cards.position}
                          </Typography>
                        </Typography>
                        <img src={cards.label} className={classes.roundLabel} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )) :
              isLoading ?
                <CircularProgress color="secondary" />
                :
                <Grid
                  container
                  spacing={0}
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  style={{ minHeight: '20vh', marginTop: '20px' }}
                >
                  <img src={scheduleIcon} />
                  <Typography variant='body1' style={{ fontSize: '13px' }}>
                    Job Schedule
                  </Typography>
                  <Typography style={{ fontSize: '10px' }}>
                    Interview scheduled will be shown here
                  </Typography>
                  <Grid>
                    {!isApplicant && <Button
                      variant='contained'
                      color='secondary'
                      id='basic-button'
                      aria-controls='basic-menu'
                      aria-haspopup='true'
                      onClick={onScheduleClick}
                      startIcon={<Icon>add_circle</Icon>}>
                      Schedule Interview
                    </Button>}
                  </Grid>
                </Grid>
            }
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
} 
