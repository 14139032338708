import React from 'react';
import { CardContent, Typography } from '@material-ui/core';
import { Card, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const SummarySection = ({ SummaryDetails }) => {
  return (
    <Card style={{ marginTop: '20px', marginBottom: '20px' }} elevation={0}>
      <CardContent>
        <Grid container>
          {SummaryDetails?.length ? (
            SummaryDetails.map(({ key, value }) => (
              <Grid key={key + value} item xs={12} md={2} style={{ marginLeft: '10px' }}>
                <Typography variant="subtitle1">{key}</Typography>
                <Typography variant="subtitle2">{value || '-'}</Typography>
              </Grid>
            ))
          ) : (
            <Grid key={'no-record-found'} item xs={12} md={2} style={{ marginLeft: '10px' }}>
              <Typography variant="subtitle2">No record found</Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SummarySection;

SummarySection.defaultProps = {
  Set: []
};

SummarySection.propTypes = {
  Set: PropTypes.array
};
