import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useNavigate } from 'react-router-dom';
import employer from 'asset/icons/employer.svg';
import user from 'asset/icons/user.svg';

const useStyle = makeStyles(() => ({
  text: {
    '&.MuiTypography-root': {
      align: 'left',
      marginBottom: '2rem',
      color: 'gray',
      fontSize: '18px',
      fontFamily: 'poppins',
    },
  },
  gridContainer: {
    padding: '1rem',
    textAlign: 'initial',
    fontFamily: 'poppins',
  },
  title: {
    color: 'black',
    fontFamily: 'poppins',
    fontWeight: 500,
  },
  icon: {
    fontFamily: 'poppins',
    '&.MuiSvgIcon-root': {
      marginTop: '0.5rem',
      marginRight: '2rem',
      width: '32px',
      color: '#1565D8',
    },
  },
  customicon: {
    width: '40px',
  },
  subtitle: {
    fontFamily: 'poppins',
    '&.MuiTypography-root': {
      align: 'left',
      color: 'gray',
      fontSize: '12px',
    },
  },
  
  button: {
    fontFamily: 'poppins',
    padding: '12px 8px',
    margin: '1.2rem 0rem',
    borderRadius:'6px',
    boxShadow: '1px 1px 4px #e1dede',
    border: '1px solid #ffffff',
    '&:hover': {
      border: '1px solid #1565D8',
      backgroundColor: '#F5F9FF',
    },
  },
  iconSvg: {
    alignSelf: 'center',
  },
}));

const EmployerHome = () => {
  const classes = useStyle();
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  // const onInterviewer = () => {
  //   null;
  // };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <div>
          <Typography variant="h4" style={{ fontFamily: 'poppins', fontSize: '1.3rem', fontWeight: 600, lineHeight: '32px', }}>Login</Typography>
          <Typography variant="subtitle1" style={{ marginBottom: '1rem' }}>To begin this journey, tell us what type of account you'd be opening.</Typography>  
        </div>
        
        <Grid item>
          <Button onClick={()=> navigate('/employer/login', { state: { role:'employer' } })} className={classes.button}>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={2} className={classes.iconSvg}>
                <img src={user} alt='user icon' className={classes.customicon} />
              </Grid>
              <Grid item xs={9}>
                <Typography className={classes.title}>
                  Employer
                </Typography>
                <Typography className={classes.subtitle}>
                  Personal account to manage all your activities.
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.iconSvg}>
                <ArrowForwardIcon className={classes.icon} />
              </Grid>
            </Grid>
          </Button>
        </Grid>

        <Grid item>
          <Button onClick={()=> navigate('/interviewer/login', { state: { role:'interviewer' } })} className={classes.button}>
            <Grid container spacing={1} className={classes.gridContainer}>
              <Grid item xs={2} className={classes.iconSvg}>
                <img src={employer} alt='employer icon' className={classes.customicon} />
              </Grid>
              <Grid item xs={9}>
                <Typography className={classes.title}>
                  Interviewer
                </Typography>
                <Typography className={classes.subtitle}>
                  Own or belong to a company, this is for you.
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.iconSvg}>
                <ArrowForwardIcon className={classes.icon} />
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EmployerHome;
