import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import Header from '../../common/components/Header/Header';
import Footer from '../../common/components/Footer/Footer';
import { RoutePaths, routePaths } from './constants';
import Home from '../pages/Home/Home';
import AddEditProfile from '../pages/AddEditProfile/AddEditProfile';
import { isEmpty, isNull } from 'lodash';
import BuildProfile from '../pages/BuildProfile/BuildProfile';
import MyJobs from '../pages/MyJobs/MyJobs';
import { isProfileCompleted } from '../../common/utils/userProfileUtil';
import DPNotFoundRoute from 'view/common/DPNotFoundRoute';

const Routes = () => {
  const isTokenPresent = localStorage.getItem('deepailytics.auth.token');
  const isApplicantPath = !isNull(isTokenPresent) && localStorage.getItem('deepailytics.user.role') === 'ROLE_APPLICANT';
  const activeRoutePaths = isProfileCompleted() ? routePaths : [];
  const defaultPath = isEmpty(activeRoutePaths) ? '/applicant/edit-profile' : '/applicant';

  return (
    <>
      <div className='header-container'>
        {isApplicantPath ? <Header routes={activeRoutePaths} /> : null}
      </div>
      <div>
        <Switch>
          <Route exact path={RoutePaths.editProfile} element={<AddEditProfile />} />
          {
            !isEmpty(activeRoutePaths) && <>
              <Route exact path={RoutePaths.buildProfile} element={<BuildProfile />} />
              <Route exact path={RoutePaths.home} element={<Home />} />
              <Route exact path={RoutePaths.myjobs} element={<MyJobs />} />
              {/* Add authenticated and protected routes here */}
              {routePaths.map((route) => {
                return (
                  <Route
                    key={route.name}
                    exact
                    path={route.link}
                    element={<route.component />}
                  />
                );
              })}
            </>
          }
          <Route path='*' exact element={<DPNotFoundRoute defaultPath={defaultPath} />} />
        </Switch>
      </div>
      <div className='footer-container'>
        {isApplicantPath ? <Footer /> : null }
      </div>
    </>
  );
};

export default Routes;
