import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllJobs,
  getAppliedJobs,
  getJobDetails,
  getJobsCriteria,
  getProfileDetails,
  getSimilarJobs,
  getCandidates,
  shortlistCandidate,
  getCandidatesRecommended,
  getStatsDetails
} from 'redux/restAPIs/employerAPI/employerDashboard';
import { EmployerActions } from '../actionTypes';

export const onGetAllJobs = createAsyncThunk(
  EmployerActions.GET_ALL_JOBS,
  async (payload) => {
    const response = await getAllJobs(payload);
    return response.data;
  }
);

export const onGetJobDetailsById = createAsyncThunk(
  EmployerActions.GET_JOB_DETAILS,
  async (payload) => {
    const response = await getJobDetails(payload);
    return response.data;
  }
);

export const onGetJobsCriteria = createAsyncThunk(
  EmployerActions.GET_All_JOBS_CRITERIA,
  async (payload) => {
    const response = await getJobsCriteria(payload);
    return response.data;
  }
);

export const onGetAppliedJobs = createAsyncThunk(
  EmployerActions.GET_APPLIED_JOBS,
  async (payload) => {
    const response = await getAppliedJobs(payload);
    return response.data;
  }
);

export const onGetCandidates = createAsyncThunk(
  EmployerActions.GET_JOB,
  async (payload) => {
    const response = await getCandidates(payload);
    return response.data;
  }
);

export const onGetCandidatesRecommended = createAsyncThunk(
  EmployerActions.GET_CANDIDATE_RECOMMENDED,
  async (payload) => {
    const response = await getCandidatesRecommended(payload);
    return response.data;
  }
);

export const onGetShortlistedCandidates = createAsyncThunk(
  EmployerActions.GET_CANDIDATE_SHORTLISTED,
  async (payload) => {
    const response = await getCandidates(payload);
    return response.data;
  }
);

export const onGetProfileDetails = createAsyncThunk(
  EmployerActions.GET_PROFILE_DETAILS,
  async (payload) => {
    const response = await getProfileDetails(payload);
    return response.data;
  }
);

export const setSimilarCandidateFlag = createAsyncThunk(
  EmployerActions.SET_SIMILAR_CANDIDATE_FLAG,
  async (data) => {
    return data;
  }
);

export const onGetSimilarJobs = createAsyncThunk(
  EmployerActions.GET_SIMILAR_APPLICANTS,
  async (payload) => {
    const response = await getSimilarJobs(payload);
    return response.data;
  }
);

export const getCandidateByJobId = createAsyncThunk(
  EmployerActions.GET_CANDIDATE_BY_JOB_ID,
  async (data) => {
    return data;
  }
);

export const onShortList = createAsyncThunk(
  EmployerActions.PATCH_SHORTLIST_CANDIDATE,
  async (payload) => {
    const response = await shortlistCandidate(payload);
    return response.data;
  }
);

export const onGetStatsData = createAsyncThunk(
  EmployerActions.GET_STATS_COUNT,
  async (payload) => {
    const { state, filter } = payload;
    const response = await getStatsDetails(state, filter);
    return { [state || 'TOTAL']: response.data };
  }
);
