/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { getCompanyInformation, postCompanyInformation } from 'redux/actions/employerActions/Configurations/companyInfoAction';

const initialState = {};

const CompanyInfo = createSlice({
  name: 'Company Info',
  initialState,
  reducers: {},
  extraReducers: {
    [getCompanyInformation.pending]: (state) => {
      state.status = 'loading';
    },
    [getCompanyInformation.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [getCompanyInformation.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [postCompanyInformation.pending]: (state) => {
      state.status = 'loading';
    },
    [postCompanyInformation.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [postCompanyInformation.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default CompanyInfo.reducer;
