import { endPoints } from 'constants/applicantEndPoints';
import { callApi } from 'middleware/api';

export const getRecommendedJobs = (data) => {
  return callApi(data, 'GET', `${endPoints.recommendedJob}?page=${data.pageNo}&size=${data.pageSize}`, true);
};

export const getJobDetailsById = (data) => {
  return callApi(data, 'GET', `${endPoints.jobDetails}/${data.id}`, true);
};

export const applyForJobs = (data) => {
  return callApi(data, 'POST', '/job/activity/application/', true);
};

export const saveJob = (data) => {
  return callApi({}, 'PATCH', `${endPoints.saveJob}?jobId=${data.jobId}&save=true`, true);
};

export const referJob = (data) => {
  return callApi(data, 'POST', endPoints.referJob, true);
};

export const mySchedule = () => {
  return callApi({}, 'GET', endPoints.mySchedule, true);
};

export const getNewsDetails = () => {
  return callApi (null ,'GET',`${endPoints.news}`, true, true);
};

export const getLearningDetails = (data) => {
  return callApi (data ,'POST',`${endPoints.learning}`, false, true);
};

export const getAppliedJobs = (data) => {
  return callApi (null ,'GET',`${endPoints.getAppliedJobs}${data}`, true);
};

export const getReferredJobs = (data) => {
  return callApi (null ,'GET',`${endPoints.getReferredJobs}${data}`, true);
};

export const getSavedJobs = (data) => {
  return callApi (null ,'GET',`${endPoints.getSavedJobs}${data}`, true);
};

export const getProfileStats = () => {
  const body = {
    'start_date': '2019-02-26',
    'end_date': '2022-01-28',
    'description': 'something something'
  };
  
  return callApi (body ,'POST',endPoints.profileStats, false, true);
};

export const getKeySkills = () => {
  const body = {
    'start_date': '2019-02-26',
    'end_date': '2022-01-28',
    'description': 'something something'
  };
  
  return callApi (body ,'POST',endPoints.keySkills, false, true);
};

export const getTopCompanies = () => {
  const body = {
    'start_date': '2019-02-26',
    'end_date': '2022-01-28',
    'description': 'something something'
  };
  
  return callApi (body ,'POST',endPoints.topCompanies, false, true);
};