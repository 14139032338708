import moment from 'moment';
import { API_KEY } from 'constants/index';

export const endPoints = {
  login: '/security/auth/login',
  logout: '/security/auth/logout',
  register: '/security/auth/signup',
  signup: '/security/auth/signup',
  verifyEmail: '/security/auth/verifyEmail',
  resendOTP: '/security/auth/send/otp',
  userProfile: '/profile/user-details',
  uploadResume: '/core/resume/upload',
  userPersonalDetails: '/profile/user-details',
  userExperience: '/profile/experiences',
  employerProfile: '/employer/employee',
  userDetails: '/profile/user-details/me',
  educationInfo: '/profile/educations',
  forgotPassword: '/security/auth/changePassword',
  jobApplicants: '/job/job-applicants',
  recommendedJob: '/job/jobs/me',
  jobDetails: '/job/job-details',
  applyJob: '/job/activity/application',
  saveJob: '/job/jobs/save',
  referJob: '/job/jobs/refer',
  learning: 'http://staging-deepai.centralindia.cloudapp.azure.com:9204/course/predict',
  mySchedule: '/job/applicant/mySchedule',
  news: `https://newsapi.org/v2/everything?q=bitcoin&from=${moment().format('MMM YYYY')}&sortBy=publishedAt&apiKey=${API_KEY}`,
  getAppliedJobs: '/job/activity/application/candidate/APPLIED',
  getReferredJobs: '/job/jobs/refer',
  getSavedJobs: '/job/jobs/save',
  profileStats: 'http://staging-deepai.centralindia.cloudapp.azure.com:9209/score',
  topCompanies:'',
  keySkills:''
};
