/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { slots } from 'constants/interviewer/interviewSchedule';
import { onConfirmSlots, onGetInterviewerSlots, onInterviewerMyScheduleWithFilter, onMySchedule, setSlotsConfirmed } from 'redux/actions/interviewerActions/interviewerSchedule';
import { onSetInterviewPrepDetails } from 'redux/actions/interviewerActions/interviewPrepQuestion';

const initialState = {
  currentTab: 'interview_prep',
  mySchedule: {},
  myCustomSchedule: {},
  interviewPrepDetails: {},
  slots: slots
};
const interviewManagement = createSlice({
  name: 'interviewManagement',
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.currentTab = action.payload;
    }
  },
  extraReducers: {
    [onMySchedule.pending]: (state) => {
      state.status = 'loading';
    },
    [onMySchedule.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.mySchedule = action.payload;
    },
    [onMySchedule.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action?.payload;
    },
    [onSetInterviewPrepDetails.pending]: (state) => {
      state.status = 'loading';
    },
    [onSetInterviewPrepDetails.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.interviewPrepDetails = action.payload;
    },
    [onSetInterviewPrepDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action?.payload;
    },
    [onGetInterviewerSlots.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetInterviewerSlots.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.slots = action?.payload;
    },
    [onGetInterviewerSlots.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action?.payload;
    },
    [onConfirmSlots.pending]: (state) => {
      state.status = 'loading';
    },
    [onConfirmSlots.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onConfirmSlots.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action?.payload;
    },
    [setSlotsConfirmed.pending]: (state) => {
      state.status = 'loading';
    },
    [setSlotsConfirmed.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.slots = action.payload;
    },
    [setSlotsConfirmed.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action?.payload;
    },
    [onInterviewerMyScheduleWithFilter.pending]: (state) => {
      state.status = 'loading';
    },
    [onInterviewerMyScheduleWithFilter.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.myCustomSchedule = action.payload;
    },
    [onInterviewerMyScheduleWithFilter.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action?.payload;
    },
  },
});

export const { setSelectedTab } =
interviewManagement.actions;

export default interviewManagement.reducer;