import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../../themes/colors';
import { FORM_CARD_RENDERER, FORM_INITIAL_STATES, FORM_VALIDATIONS } from '../../utils/formUtils';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { callApi } from 'middleware/api';
import { onUserProfileDetails } from 'redux/actions/userAuthentication';
import { isEmpty } from 'lodash';

const useStyle = makeStyles(() => ({
  formCardContainer: {
    marginBottom: '1rem',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: '600',
    color: colors.gray2,
    // margin: '1.8rem 0rem 1rem 0rem',
  },
  btnContainer: {
    margin: 0,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  saveBtn: {
    boxShadow: 'none',
    margin: '10px 10px 10px 0px',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#6361CD',
    },
  }
}));

const FormCard = ({
  title,
  formType,
  formDataPath = [],
  defaultView = 'readView',
  onCancel,
  isNew,
  onSave,
  id,
  profileId,
  endpoint,
  customSubmitHandler,
  meta,
  isDisable = false
}) => {

  const formData = formDataPath[formDataPath.length - 1] < 0 ? {} : useSelector(state => R.path(formDataPath, state));

  const classes = useStyle();
  const [formView, setFormView] = useState(isNew ? 'editView' : defaultView);
  const [isError, setIsError] = useState(false);
  const FormRendererMap = FORM_CARD_RENDERER[formType];
  const dispatch = useDispatch();

  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isEmpty(formData) ? FORM_INITIAL_STATES[formType] : formData,
    validationSchema: FORM_VALIDATIONS[formType],
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!isError) {
        if (R.isNil(customSubmitHandler)) {
          let payload = isNew ? values : { ...values, userDetailId: profileId };
          const method = formData.id ? 'PUT' : 'POST';
          const response = await callApi(payload, method, endpoint, true);
          if (response.status == 200) {
            if (!R.isNil(onSave)) {
              onSave();
            } else {
              await dispatch(onUserProfileDetails());
              setFormView('readView');
            }
          }
        } else {
          customSubmitHandler(setFormView);
        }
      }
    },
  });

  const onEditClick = () => {
    setFormView('editView');
  };

  const Renderer = FormRendererMap[formView];

  const isEdit = formView !== 'readView';

  const onFormClose = () => {
    onCancel();
    formik.setValues(formData);
    setFormView('readView');
  };

  useEffect(() => {
    if (isEmpty(formik.values) && !isEmpty(formData)) {
      formik.setValues(formData);
    }
  }, [formData]);

  return (
    <Box id={id} className={classes.formCardContainer}>
      <Typography variant="h3" className={classes.title}>{defaultView !== 'readView' ? isNew ? 'Add' : 'Edit' : ''} {title}</Typography>
      <Box sx={{
        backgroundColor: 'white',
        borderRadius: '6px',
        marginTop: '0.6rem',
        pointerEvents: isDisable ? 'none' : 'all'
      }}>
        <Renderer
          formik={formik}
          formData={formData}
          setIsError={setIsError}
          formType={formType}
          style={{ marginBottom: '25px' }}
          metaData={{
            ...meta,
            setFormView
          }}
        />
        <Grid container style={{ padding: '0 2rem 2rem 2rem' }}>
          <Grid item xs={12} className={classes.btnContainer}>
            {
              isEdit && <Button variant="outlined" color="secondary" onClick={onFormClose} style={{ margin: '10px 10px 10px 0px' }}>Cancel</Button>
            }
            {
              isEdit ? <Button variant="contained" color="secondary" className={classes.saveBtn} onClick={formik.handleSubmit}>Save</Button> : <Button variant="outlined" color="secondary" style={{ margin: '10px 10px 10px 0px' }} onClick={onEditClick}>Edit</Button>
            }
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FormCard;


FormCard.propTypes = {
  title: PropTypes.string,
  formType: PropTypes.string.isRequired,
  formDataPath: PropTypes.array,
  defaultView: PropTypes.string,
  onCancel: PropTypes.func
};

FormCard.defaultProps = {
  title: '',
  formDataPath: [],
  defaultView: 'readView',
  onCancel: () => { }
};