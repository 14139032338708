import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Checkbox } from '@material-ui/core';
import { Link } from 'react-scroll';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const useStyle = makeStyles(() => ({
  card: {
    borderRadius: '7px',
    marginTop: '20px',
    padding: '13px',
  },
  link: {
    fontWeight: 400,
    marginLeft: '10px',
    fontSize: '16px',
  },
  linkbtn1: {
    marginBottom: '0.3rem',
    border: '1px solid #e4e4e4',
    padding: '10px',
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dae1fba3',
      border: '1px solid #6361CD',
    },
    '&:active': {
      backgroundColor: '#6361CD',
      color: '#ffffff',
    },
  },
}));

// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const SectionScroll = ({
  sectionDataPath
}) => {
  const sectionData = useSelector(state => pathOr([], sectionDataPath, state));
  const classes = useStyle();

  return (
    <Card className={classes.card} style={{ boxShadow: 'none' }}>
      <div>
        {sectionData.map(item => (
          <div className={classes.linkbtn1}>
            <Checkbox
              // label={label}
              checked={item.active}
              color="secondary"
              size="small"
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
            />
            <Link
              to={item.id}
              smooth={true}
              duration={1000}
              offset={-60}
              className={classes.link}
            >
              {item.label}
            </Link>
          </div>
        ))}
      </div>
    </Card>
  );
};
export default SectionScroll;