import React, { useEffect, useState } from 'react';
import { Box, Grid, InputLabel, makeStyles, TextField } from '@material-ui/core';
import DPSummarySection from 'view/common/DPSummarySection';
import DPPrimaryDetails from 'view/common/DPPrimaryDetails';
import { Button } from 'view/ui-components';
import InterviewQuestions from 'view/employer/pages/Configuration/InterviewQuestions';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { formatData } from 'view/common/utils/interviewerUtil';

const useStyles = makeStyles(() => ({
  root: {
    width: '55%',
    padding: '20px'
  }
}));

const Review = () => {
  const classes = useStyles();
  const interviewPrepDetails = useSelector(state => state.interviewManagement.interviewPrepDetails);
  const [interviewSummary, setinterviewSummary] = useState([]);
  const myScheduleData = useSelector(state => state.interviewManagement.mySchedule);
  const [state, setState] = useState({
    overallFeedback: null,
    errorText: null,
    error: false
  });

  

  useEffect(() => {
    if (!isEmpty(myScheduleData) && myScheduleData?.interviewDetails) {
      const { interviewDetails } = myScheduleData;
      const data = formatData(interviewDetails[0]);
      setinterviewSummary(data);
    }
    return () => {
      setinterviewSummary([]);
    };
  }, [myScheduleData]);

  useEffect(() => {
    if (!isEmpty(interviewPrepDetails) ) {
      const data = formatData(interviewPrepDetails);
      setinterviewSummary(data);
    }
    return () => {
      setinterviewSummary([]);
    };
  }, [interviewPrepDetails]);

  const handleOverallFeedback = (e) => {
    setState({ overallFeedback: e.target.value, ...state });
  };

  const ratingData = [
    { remark: 'Exceptional', ratings: 5 },
    { remark: 'Above Average', ratings: 4 },
    { remark: 'Average', ratings: 3 },
    { remark: 'Below Average', ratings: 2 },
    { remark: 'Unsatisfactory', ratings: 1 }
  ];

  return (
    <Box>
      <DPSummarySection SummaryDetails={interviewSummary} />
      <DPPrimaryDetails
        title="Scoring Process and Standards"
        content="Candidate evaluation forms are to be completed by the interviewer to rank the candidate's overall qualifications for the position to which he or she has applied. Under each heading, the interviewer should give the candidate a numerical rating and write specific job-related comments in the space provided. The numerical rating system is based on the following:"
        legends={ratingData}
      />
      <InterviewQuestions />

      <Grid container direction="column" spacing={5} className={classes.root}>
        <Grid item>
          <InputLabel htmlFor="TextField-input">Overall Feedback</InputLabel>
          <TextField
            variant="outlined"
            id="OverallFeedback"
            placeholder="Enter Overall Feedback"
            name="OverallFeedback"
            type="text"
            onChange={handleOverallFeedback}
            value={state.overallFeedback}
            fullWidth
            multiline
            minRows={4}
            helperText={state.errorText}
            error={state.error}
          />
        </Grid>
        <Grid item>
          <Button text="Submit" color="secondary" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Review;
