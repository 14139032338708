import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';
import { get, isArray, isNull } from 'lodash-es';
import { fetchLovByKey } from 'redux/actions/lovActions';
import { useDispatch, useSelector } from 'react-redux';

const AutoSuggestMultiple = ({
  dropdownOptions,
  localePrefix,
  label,
  error,
  helperText,
  ...props
}) => {
  const { t: translate } = useTranslation();
  let lbl = null;
  if (localePrefix !== null) {
    lbl = translate(`${localePrefix}.LABEL`);
  }
  if (label) {
    lbl = label;
  }

  return (
    <div>
      <Autocomplete
        limitTags={2}
        multiple
        options={isNull(dropdownOptions) ? [] : dropdownOptions}
        getOptionLabel={option => get(option, 'name', option)}
        filterSelectedOptions
        ChipProps={{ color: 'secondary' }}
        renderInput={params => (
          <TextField
            
            {...params}
            variant="outlined"
            placeholder={`Enter ${lbl}`}
            error={error}
            helperText={helperText} />
        )}
        {...props} />
    </div>
  );
};

AutoSuggestMultiple.propTypes = {
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  localePrefix: PropTypes.string,
  helperText: PropTypes.string,
};

const FormikAutoSuggestMultiple = (props) => {
  const { apiKey } = props;

  const dispatch = useDispatch();
  const options = useSelector(state => get(state, ['lov', apiKey], null));
  const [, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;

  const [dropdownOptions, setDropdownOptions] = useState(isNull(options) ? [] : options);

  const onChangeFn = (vl) => {
    let res = [];
    if (isArray(vl)) {
      res = vl.map(v1 => v1.id);
    }
    setValue(res);
  };

  const filterData = (values) => {
    if (isArray(dropdownOptions) && isArray(values)) {
      return dropdownOptions.filter(o => values.includes(o.id));
    }
    return [];
  };

  useEffect(() => {
    if (isNull(options)) {
      dispatch(fetchLovByKey(apiKey));
    }
  }, []);

  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  return (
    <AutoSuggestMultiple
      onChange={(evt, value) => onChangeFn(value)}
      value={filterData(value)}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && Boolean(meta.error) ? meta.error : undefined}
      dropdownOptions={dropdownOptions}
      {
        ...props
      }
    />
  );
};
FormikAutoSuggestMultiple.propTypes = {
  localePrefix: PropTypes.string,
  helperText: PropTypes.string,
};

export default AutoSuggestMultiple;
export { FormikAutoSuggestMultiple };
// Ref Link https://material-ui.com/components/autocomplete/#useautocomplete
