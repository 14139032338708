import React from 'react';
import {
  Card,
  CardContent,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InterviewPreparation from './InterviewPreparation';
import Profile from './Profile';
import JobDetailsInterviewer from './JobDetailsInterviewer';
import Review from './Review';
import VideoCall from './VideoCall';

const useStyles = makeStyles(() => ({
  jobCard: {
    marginBottom: '1.3rem',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    padding: '0.5rem 0',
  }
}));

const renderInterviewDetails = (currentTab, candidateId, jobId) => {
  return (
    {
      'interview_prep': <InterviewPreparation currentTab={currentTab} />,
      'profile': <Profile currentTab={currentTab} candidateId={candidateId} />,
      'job_details': <JobDetailsInterviewer currentTab={currentTab} jobId={jobId} />,
      'video_call': <VideoCall currentTab={currentTab} />,
      'review': <Review currentTab={currentTab} />,
    }[currentTab]
  );
};

const InterviewDetails = ({ currentTab, candidateId, jobId }) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        marginTop: '0.5rem',
      }}
    >
      <Card elevation={0} className={classes.jobCard}>
        <CardContent style={{ padding: '10px 15px' }}>
          {renderInterviewDetails(currentTab,candidateId,jobId)}
        </CardContent>
      </Card>
    </Box>
  );
};

export default InterviewDetails;