import React, { useState } from 'react';
import { Typography, Box, FormControlLabel, Checkbox, TextField, Divider, Input, InputAdornment, FormControl, IconButton, makeStyles, InputLabel, Button, Modal } from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import { onRegisterNow } from 'redux/actions/userAuthentication';
import VerifyOtpForm from 'view/applicant/forms/VerifyOtpForm';
import { colors } from '../../../../themes/colors';
import * as Yup from 'yup';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { emailRegExp } from 'view/common/utils/formUtils';
import DPTermsNConditions from 'view/common/DPTermsNConditions';

const useStyle = makeStyles(theme => ({
  formContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },

  button: {
    '&.MuiTextField-root': {
      width: '400px',
      marginTop: '10px',
      marginBottom: '10px'
    }
  },
  topSection: {
    marginBottom: '1rem'
  },
  loginBtn: {
    backgroundColor: colors.blue2,
    '&:hover': {
      backgroundColor: colors.blue1,
    },
    color: colors.white,
    fontSize: 16,
    fontWeight: 200,
    padding: '14px 16px',
    lineHeight: 1.5,
  },
  customInput: {
    marginBottom: '0.8rem',
    '& .MuiInputBase-input': {
      borderRadius: 6,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '20px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:focus': {
        boxShadow: '0px 4px 10px 3px rgba(0, 0, 0, 0.11)',
        borderColor: '#1565D8',
      },
    },
  },
  showHidePswd: {
    color: '#6361CD', position: 'absolute', right: '1rem',
  },
}));

const RegisterFormInitialState = {
  name: '',
  email: '',
  password: ''
};


const validationSchema = Yup.object({
  name: Yup.string().required('Full Name is Required'),
  email: Yup.string().required('Email Id is Required')
    .matches(emailRegExp, 'Email Id  is not valid'),
  password: Yup.string().min(6).max(15).required('Required'),
});

const RegisterNow = () => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [openTnC, setOpenTnC] = useState(false);
  const currentRegisterStep = useSelector(state => _.get(state, ['userAuthentication', 'currentRegisterStep'], 1));
  const classes = useStyle();
  const dispatch = useDispatch();
  const registerFormik = useFormik({
    initialValues: RegisterFormInitialState,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(onRegisterNow({ values, onregisterSuccess: onregisterSuccess }));
    },
  });
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);

  const onregisterSuccess = () => {
    setShowVerifyOtp(true);
  };

  const handleClose = () => {
    setShowVerifyOtp(false);
  };

  const isRegisterFormFilled = (
    !isEmpty(registerFormik.values.name) &&
    !isEmpty(registerFormik.values.email) &&
    !isEmpty(registerFormik.values.password)
  );

  // password show/hide 
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
    
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
    
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
    // end

  return (
    <>
      {
        currentRegisterStep === 1 && (
          <div>
            <div className={classes.topSection}>
              <Typography variant="h4">Register Now!</Typography>
              <Typography variant="subtitle1">For the purpose of industry regulation, your details are required</Typography>
            </div>
            <Divider />
            <form onSubmit={registerFormik.handleSubmit} className={classes.formContainer}>
              <InputLabel required> Your Full Name</InputLabel>
              <TextField
                // required
                InputProps={{ disableUnderline: true }}
                className={classes.customInput}
                variant="standard"
                fullWidth
                id="name"
                name="name"
                type="text"
                placeholder="Enter full name"
                value={registerFormik.values.name}
                onChange={registerFormik.handleChange}
                error={registerFormik.touched.name && Boolean(registerFormik.errors.name)}
                helperText={registerFormik.touched.name && registerFormik.errors.name} />
              <InputLabel required> Email Address</InputLabel>
              <TextField
                InputProps={{ disableUnderline: true }}
                className={classes.customInput}
                variant="standard"
                fullWidth
                id="email"
                name="email"
                type="text"
                placeholder="Enter email address "
                value={registerFormik.values.email}
                onChange={registerFormik.handleChange}
                error={registerFormik.touched.email && Boolean(registerFormik.errors.email)}
                helperText={registerFormik.touched.email && registerFormik.errors.email} />

              <InputLabel required htmlFor="outlined-adornment-password">
                Create Password
              </InputLabel>
              <FormControl fullWidth variant="outlined" className={classes.customInput} >
                <Input
                  required
                  disableUnderline
                  fullWidth
                  id="outlined-adornment-password"
                  name="password"
                  placeholder="Enter password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={registerFormik.values.password}
                  onChange={registerFormik.handleChange}
                  error={registerFormik.touched.password && Boolean(registerFormik.errors.password)}
                  helperText={registerFormik.touched.password && registerFormik.errors.password}
                  endAdornment={
                    <InputAdornment>
                      <IconButton
                        className={classes.showHidePswd}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        aria-label="toggle password visibility"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <div style={{ marginBottom: '0.8rem' }}>
                <FormControlLabel control={<Checkbox 
                  onChange={(event) => {
                    setIsTermsAccepted(event.target.checked);
                  }} 
                />} />I agree to <a href='javascript:void' onClick={() => setOpenTnC(true)}>terms and condition</a>
              </div>
              <Button
                className={classes.loginBtn}
                color="primary"
                fullWidth
                variant="contained"
                type="submit"
                disabled={!(isRegisterFormFilled && isTermsAccepted)}
              >Register Account</Button>
            </form>
            {openTnC && <DPTermsNConditions openTnC={openTnC} setOpenTnC={setOpenTnC} /> }
          </div>
        )
      }
      <Modal
        open={showVerifyOtp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box >
          <VerifyOtpForm email={registerFormik.values.email} handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
};

export default RegisterNow;