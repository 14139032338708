import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import socialcontri from 'asset/icons/socialcontri.svg';
import mockimg from 'asset/icons/mockImg.svg';
import medal from 'asset/icons/Medal.svg';
import fileText1 from 'asset/icons/filetext1.svg';
import deletecard from 'asset/icons/delete.svg';
import editcard from 'asset/icons/editcard.svg';
import research from 'asset/icons/research.svg';
import React from 'react';
import { colors } from '../../../../themes/colors';
import * as R from 'ramda';
import moment from 'moment';

const useStyle = makeStyles(() => ({
  customicon: {
    width: '30px',
  },
  detailsCardContainer: {
    border: `1px solid ${colors.gray7}`,
    padding: '15px',
    borderRadius: '6px',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'baseline',
  },
  iconBtn: {
    width: '15px',
    margin: '4px',
  },
  actionIcon: {
    '&.MuiIconButton-root': {
      padding: '2px',
    }
  },
  cardDetailsContainer: {
    paddingLeft: '0.6rem',
    paddingRight: '1rem',
    '&.MuiTypography-body1': {
      testAlign: 'justify'
    },
  },
}));

const ALL_KEYS_MAP = {
  educationDetails: {
    title: 'field',
    subTitle: 'university',
    startDate: 'startYear',
    endDate: 'endYear',
    description: 'description',
    icon: mockimg,
  },
  professionalDetails: {
    title: 'title',
    subTitle: 'company',
    location: 'location',
    startDate: 'startDate',
    endDate: 'endDate',
    description: 'description',
    icon: mockimg,
  },
  achievementDetails: {
    title: 'title',
    subTitle: 'issuer',
    issueDate: 'issueDate',
    endDate: 'endYear',
    description: 'description',
    icon: medal,
  },
  certificationDetails: {
    title: 'name',
    subTitle: 'issuer',
    issueDate: 'issueDate',
    endDate: 'endYear',
    description: 'description',
    credentialId: 'credentialId',
    icon: fileText1,
  },
  socialContributions: {
    title: 'role',
    subTitle: 'organization',
    description: 'description',
    cause: 'cause',
    startDate: 'startDate',
    endDate: 'endDate',
    icon: socialcontri,
  },
  researchAndInnovations: {
    title: 'title',
    subTitle: 'subTitle',
    issueDate: 'issuedOn',
    description: 'description',
    icon: research,
  }
};

const ShowIssueDates = ['achievementDetails',
  'certificationDetails', 'researchAndInnovations'];

const DetailsCard = ({ data, deleteCallback, onCardEdit, formType }) => {
  const classes = useStyle();
  const allKeys = ALL_KEYS_MAP[formType];
  return (
    <Grid container className={classes.detailsCardContainer} xs={12}>
      <Grid item container xs={11}>
        <Grid item xs={1} style={{ flexBasis: 'auto' }}>
          <img src={allKeys.icon} alt='social contributions icon' className={classes.customicon} />
        </Grid>

        <Grid item xs={10} className={classes.cardDetailsContainer}>
          <Typography variant="h5">{R.propOr('', [allKeys.title], data)}</Typography>
          <Typography variant="h6">{`${R.propOr('', [allKeys.subTitle], data)} ${R.propOr('', [allKeys.cause], data)}`}</Typography>
          {
            ShowIssueDates.includes(formType) ?
              <Typography variant="subtitle1">
                {R.propOr('', [allKeys.issueDate], data) && moment(R.propOr('', [allKeys.issueDate], data)).format('MMM YYYY')}
              </Typography> :
              <>
                <Typography variant="subtitle1">
                  {R.propOr('', [allKeys.startDate], data) && `${moment(R.propOr('', [allKeys.startDate], data)).format('MMM YYYY')}`} 
                  {R.propOr('', [allKeys.endDate], data) && ` - ${moment(R.propOr('', [allKeys.endDate], data)).format('MMM YYYY')}`}
                </Typography>
              </>
          }
          <Typography variant="h6">{R.propOr('', [allKeys.location], data)}</Typography>
          <Typography>{R.propOr('', [allKeys.credentialId], data)}</Typography>
          <Typography>{R.propOr('', [allKeys.description], data)}</Typography>
        </Grid>

      </Grid>
      <Grid item xs={1} className={classes.btnContainer}>
        <IconButton className={classes.actionIcon} onClick={onCardEdit}>
          <img src={editcard} alt='edit card icon' className={classes.iconBtn} />
        </IconButton>
        <IconButton className={classes.actionIcon} onClick={() => deleteCallback(R.propOr('', [allKeys.title], data), data.id)}>
          <img src={deletecard} alt='delete card icon' className={classes.iconBtn} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default DetailsCard;