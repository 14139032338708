import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 30,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconCircle: {
    '&.MuiStepLabel-iconContainer': {
      backgroundColor: 'red'
    }
  }
}));



const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#66CD27'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#66CD27'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}));


function getSteps() {
  return ['Applied', 'Shortlisted', 'Interview Round -1', 'Interview Round -2', 'Interview Round -3', 'Interview Round -4', 'Interview Round -5'];
}

export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} connector={<ColorlibConnector />} alternativeLabel>
        {steps.map(function (label) {
          return (
            <Step key={label}>
              <StepLabel className={classes.iconCircle}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
