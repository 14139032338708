import React from 'react';
import { Grid, Box, Typography, makeStyles, Button, IconButton, Divider } from '@material-ui/core';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { Report_Data } from 'mockData/CandidateCardData';
import playAudio from 'asset/icons/playAudio.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import audioVol from 'asset/icons/audioVol.svg';
import { styled, useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as R from 'ramda';

const useStyle = makeStyles(() => ({
  iconBtn: {
    margin: '7px',
  },
  actionIcon: {
    alignSelf: 'center',
    padding: 0
  },
  font: {
    fontSize: '14px'
  },
}));

// InterviewReport

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

const InterviewReport = () => {
  const theme = useTheme();
  const duration = 200; // seconds
  // eslint-disable-next-line no-unused-vars
  const [position, setPosition] = React.useState(32);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyle();

  return (
    <Box>
      <Grid container style={{ marginBottom: '1rem' }}>
        <Grid item xs={12} md={6} align="left">
          <Typography variant='subtitle1' className={classes.font}>
                      Date & Time
            <Typography variant='h6' className={classes.font}>
                      Apr 28, 2021 | 1:00 PM - 1:30 PM
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} align="left">
          <Typography variant='subtitle1' className={classes.font}>
                      Job Title
            <Typography variant='h6' className={classes.font}>
                      UI/UX Designer
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} align="left" >
          <Typography variant='subtitle1' className={classes.font}>
                      Interviewer
            <Typography variant='h6' className={classes.font}>
                      Pankaj M. (Project Lead)
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} align="left">
          <Typography variant='subtitle1' className={classes.font}>
                      Interview Type
            <Typography variant='h6' className={classes.font}>
                        Video Call
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: '1.2rem' }}>
        <Grid item xs={12} md={8} align="left">
          <Box sx={{ width: '50%', overflow: 'hidden' }}>
                   
            <Stack spacing={0} direction="row" alignItems="center" >
              <IconButton className={classes.actionIcon} >
                <img src={playAudio} alt='play Audio icon' className={classes.iconBtn} />
              </IconButton>
              <>
                <Slider
                  aria-label="time-indicator"
                  size="small"
                  value={position}
                  min={0}
                  step={1}
                  max={duration}
                  sx={{
                    color: '#6360CD',
                    height: 4,
                    '& .MuiSlider-thumb': {
                      width: 8,
                      height: 8,
                      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                      '&:before': {
                        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                      },
                      '&:hover, &.Mui-focusVisible': {
                        boxShadow: `0px 0px 0px 8px ${
                          theme.palette.mode === 'dark'
                            ? 'rgb(255 255 255 / 16%)'
                            : 'rgb(0 0 0 / 16%)'
                        }`,
                      },
                      '&.Mui-active': {
                        width: 20,
                        height: 20,
                      },
                    },
                    '& .MuiSlider-rail': {
                      opacity: 0.28,
                    },
                  }}
                /> 
              </>
              <IconButton className={classes.actionIcon}>
                <img src={audioVol} alt='audio volume icon' className={classes.iconBtn} />
              </IconButton>
            </Stack>
            <Box
              sx={{
                width: '70%',
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: -2,
              }}
            >
              <TinyText>0.08</TinyText>
              <TinyText>1.00</TinyText>
            </Box>
                   
          </Box>
        </Grid>
        <Grid item xs={5} md={2} align="left">
          <Button variant='text' color="secondary" style={{ fontSize: '16px' }}>
                      Get AI Report
          </Button>
        </Grid>
        <Grid item xs={5} md={2} align="left" >
          <Button variant='text' color="secondary" style={{ fontSize: '16px' }}
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuClick}
            endIcon={<ArrowDropDownIcon />}
          >
                      Actions
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Divider />
      <Grid container style={{ marginBottom: '1.2rem' }}>
        <Grid item xs={12} align="left">
          <Typography variant='subtitle1' className={classes.font}>
                      Scoring
            <Typography variant='h6' className={classes.font}>
                    Candidate evaluation forms are to be completed by the interviewer to rank the candidate's overall qualifications for the position to which he or she has applied. Under each heading, the interviewer should give the candidate a numerical rating and write specific job-related comments in the space provided. The numerical rating system is based on the following:
              <br />5 - Exceptional     4 - Above Average      3 - Average      2 - Below Average      1 - Unsatisfactory 
            </Typography>
          </Typography>
        </Grid>
      </Grid>

      {
        R.map((data) => {
          return (
            <Grid container style={{ marginBottom: '1.2rem' }}>
              <Grid item xs={12} align="left">
                <Typography variant='h6' className={classes.font}>
                  {data.question}
                </Typography>
                <Typography variant='h5'className={classes.font}>
                  {data.points}
                </Typography>

                <Typography variant='subtitle1' className={classes.font}>
                          Comments
                  <Typography variant='body1' className={classes.font}>
                    {data.comments}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          );
        })(Report_Data)
      }
      <Grid container>
        <Grid item xs={12} align="left">
          <Typography variant='body1' style={{ fontSize: '18px', marginBottom: '0.5rem' }}>
                    Overall Recommendation
          </Typography>
          <Typography variant='h6' className={classes.font}>
                    Did the candidate pass the interview
            <Typography variant='h6' className={classes.font}>
              <b>Yes</b> 
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default InterviewReport;