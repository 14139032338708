import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useTranslation } from 'react-i18next';
const PhoneField = (props) => {
  const { t : translate } = useTranslation();
  const {
    field: { name, value },
    form: { errors, setFieldValue, touched },
    onChange,
    localePrefix,
    label,
  } = props;
  let lbl = null;
  if (localePrefix !== null) {
    lbl = translate(`${localePrefix}.LABEL`);
  }
  if (label) {
    lbl = label;
  }
  const isError = getIn(touched, name) && getIn(errors, name);

  const onValueChange = (phoneNumber) => {
    setFieldValue(name, phoneNumber);

    if (onChange !== null) {
      onChange(phoneNumber);
    }
  };

  return (
    <MuiPhoneNumber
      variant="outlined"
      fullWidth
      name={name}
      value={value}
      helperText={isError}
      error={isError && isError.length > 0}
      placeholder={lbl}
      onChange={onValueChange}
      defaultCountry="in" />
  );
};

PhoneField.propTypes = {
  form: PropTypes.any.isRequired,
  field: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  country: PropTypes.string,
  disabled: PropTypes.bool,
};

PhoneField.defaultProps = {
  className: '',
  label: '',
  onChange: null,
  country: 'IN',
  disabled: false,
};

export default PhoneField;
