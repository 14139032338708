import React from 'react';
import { Badge } from '@material-ui/core';
import PropTypes from 'prop-types';
import Icons from '../../constants/icons';

const BadgeIcon = ({ badgeContent, color, icon }) => {
  const Icon = Icons[icon];
  return (
    <Badge badgeContent={badgeContent} color={color}>
      <Icon />
    </Badge>
  );
};

BadgeIcon.propTypes = {
  badgeContent: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

BadgeIcon.defaultProps = {
  color: 'error',
};

export default BadgeIcon;