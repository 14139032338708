import React, { useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import ComposerPanelWrapper from '../../../common/wrappers/ComposerPanelWrapper';
import { onGetNews } from 'redux/actions/applicantDashboard';
import { get } from 'lodash';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  gridMain: {
    padding: '1rem 1rem',
    marginBottom: '3rem'
  },
  newsTitle: { 
    fontSize: '20px', 
    color: '#555555', 
    fontFamily: 'Poppins', 
    fontWeight: '700' 
  },
  subTitle: { 
    fontSize: '14px', 
    color: '#828282', 
    fontFamily: 'Poppins', 
    fontWeight: '400' 
  },
  cardContainer: {
    maxWidth: '266px',
    margin: '1.2rem'
  },
  title: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: '500',
    lineHeight: '20px',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  description: {
    marginTop: '0.2rem',
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    lineHeight: '18px',
    color: '#828282',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  time: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: '500',
    lineHeight: '18px',
    color: '#828282'
  },
  author: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: '500',
    color: '#24283C',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const News = () => {

  const newsData = useSelector(state => get(state, ['applicantDashboard', 'news'], ''));
  const dispatch = useDispatch();

  useEffect(() => {
    if (newsData) {
      dispatch(onGetNews());
    }
  }, []);

  const PanelConfig = {
    isOpen: false,
    anchor: 'right',
    Renderer: false, // ComponentRenderer
    rendererConfigs: {},
  };
  const classes = useStyles();

  return (
    <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
      <Container maxWidth="lg" className={classes.gridMain}>
        <Box style={{ paddingLeft: '1rem' }}>
          <Typography variant="body2" className={classes.newsTitle}>
            News
          </Typography>
          <Typography variant="body2" className={classes.subTitle}>
            Stay up to date with our latest news.
          </Typography>
        </Box>
        <Grid container spacing={12}>
          {newsData?.articles?.map((item) => {
            return (
              <Grid item xs={12} md={3}>
                <a href={item.url} target="_blank" style={{ textDecoration: 'none' }}>
                  <Card className={classes.cardContainer} >
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="140"
                      src={item.urlToImage}
                    />
                    <CardContent>
                      <Box >
                        <Typography variant="body2" className={classes.title}>
                          {item.title}
                        </Typography>

                        <Typography variant="body2" className={classes.description}>
                          {item.description}
                        </Typography>
                      </Box>
                      <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.9rem' }}>
                        <Typography variant="body2" className={classes.time}>
                          {moment(item.publishedAt).fromNow()}
                        </Typography>
                        <Box style={{ display: 'flex' }}>
                          <Typography className={classes.time} >
                            by&nbsp;
                          </Typography>
                          <Typography className={classes.author}>{item.author?.substring(0, 15)}</Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </a>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </ComposerPanelWrapper>
  );
};

export default News;
