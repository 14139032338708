import { get, isEmpty } from 'lodash';
import { keys } from 'ramda';
import { CertificationDetailsInitialState, EducationalDetailsInitialState, HonorAchievementsFormInitialState, PersonalDetailsFormInitialState, ProfessionalDetailInitialState, ProjectDetailsInitialState, ResearchInnovationFormInitialState, SkillDetailsFormInitialState, SocialContributionFormInitialState } from 'redux/initialStates/applicant/personalDetailsForm';
import SkillDetailsView from 'view/applicant/formReadonlyViews/SkillDetailsView/SkillDetailsView';
import PersonalDetailsForm from 'view/applicant/forms/PersonalDetailsForm';
import SkillDetailsForm from 'view/applicant/forms/SkillDetailsForm';
import PersonalDetailsView from '../../applicant/formReadonlyViews/PersonalDetailsView/PersonalDetailsView';
import ProjectDetailsView from '../../applicant/formReadonlyViews/ProjectDetailsView/ProjectDetailsView';
import EducationDetailsForm from '../../applicant/forms/EducationDetails';
import HonorAchievementsForm from '../../applicant/forms/HonorAchievementsForm';
import LicensesCertificationsForm from '../../applicant/forms/LicensesCertificationsForm';
import ProfessionalDetail from '../../applicant/forms/ProfessionalDetail';
import ProjectDetailForm from '../../applicant/forms/ProjectDetailForm';
import ResearchInnovationForm from '../../applicant/forms/ResearchInnovationForm';
import SocialContributionForm from '../../applicant/forms/SocialContributionForm';
import DetailsCard from '../components/DetailsCard/DetailsCard';
import * as Yup from 'yup';
import moment from 'moment';

export const FORM_CARD_RENDERER = {
  personalDetails: {
    editView: PersonalDetailsForm,
    readView: PersonalDetailsView
  },
  educationDetails: {
    editView: EducationDetailsForm,
  },
  professionalDetails: {
    editView: ProfessionalDetail
  },
  projectDetails: {
    editView: ProjectDetailForm
  },
  achievementDetails: {
    editView: HonorAchievementsForm
  },
  certificationDetails: {
    editView: LicensesCertificationsForm
  },
  socialContributions: {
    editView: SocialContributionForm
  },
  researchAndInnovations: {
    editView: ResearchInnovationForm
  },
  skillDetails: {
    editView: SkillDetailsForm,
    readView: SkillDetailsView,
  }
};

export const FORM_CARD_TYPE = {
  personalDetails: 'personalDetails',
  educationDetails: 'educationDetails',
  professionalDetails: 'professionalDetails',
  projectDetails: 'projectDetails',
  achievementDetails: 'achievementDetails',
  certificationDetails: 'certificationDetails',
  socialContributions: 'socialContributions',
  researchAndInnovations: 'researchAndInnovations',
  skillDetails: 'skillDetails'
};

export const FORM_INITIAL_STATES = {
  personalDetails: PersonalDetailsFormInitialState,
  educationDetails: EducationalDetailsInitialState,
  professionalDetails: ProfessionalDetailInitialState,
  projectDetails: ProjectDetailsInitialState,
  certificationDetails: CertificationDetailsInitialState,
  socialContributions: SocialContributionFormInitialState,
  researchAndInnovations: ResearchInnovationFormInitialState,
  skillDetails: SkillDetailsFormInitialState,
  achievementDetails: HonorAchievementsFormInitialState
};

export const FORM_CARD_LIST_RENDERER = {
  defaultRenderer: DetailsCard,
  projectDetailsView: ProjectDetailsView
};

export const generatePersonalDetails = (data) => {
  const {
    firstName,
    lastName,
    contactNumber,
    email,
    address,
    zipcode,
    city,
    stateResiding,
    country,
    experienceCount,
    experiencePeriod,
    salaryMax,
    salaryMin,
    headline,
    summary
  } = data;
  return {
    firstName,
    lastName,
    contactNumber,
    email,
    address,
    zipcode,
    city,
    stateResiding,
    country,
    experienceCount,
    experiencePeriod,
    salaryMax,
    salaryMin,
    headline,
    summary
  };
};
export const getFormattedProfileDetails = (data) => {
  const { id } = data;
  const personalDetails = generatePersonalDetails(data);
  const educationDetails = get(data, 'educationDetails', []);
  const professionalDetails = get(data, 'professionalDetails', []);
  const achievementDetails = get(data, 'achievementDetails', []);
  const certificationDetails = get(data, 'certificationDetails', []);
  const socialContributions = get(data, 'socialContributions', []);
  const researchAndInnovations = get(data, 'researchAndInnovations', []);
  const projectDetails = get(data, 'projectDetails', []);

  return {
    id,
    personalDetails,
    achievementDetails,
    educationDetails,
    professionalDetails,
    certificationDetails,
    socialContributions,
    researchAndInnovations,
    projectDetails
  };
};

const isPersonalDetailsComplete = (data) => {
  const personalDetailsMandate = ['firstName', 'contactNumber', 'email'];
  let isComplete = true;
  personalDetailsMandate.forEach((item) => {
    if (isEmpty(data[item])) {
      isComplete = false;
    }
  });
  return isComplete;
};

export const getCalculatedProfileCompletionStatus = (data) => {
  return [
    {
      label: 'Personal Details',
      active: isPersonalDetailsComplete(data),
      id: FORM_CARD_TYPE.personalDetails,
    },
    {
      label: 'Education Details',
      active: !isEmpty(data.educationDetails),
      id: FORM_CARD_TYPE.educationDetails,
    },
    {
      label: 'Professional Details',
      active: !isEmpty(data.professionalDetails),
      id: FORM_CARD_TYPE.professionalDetails
    },

    {
      label: 'Honor & Achievements',
      active: !isEmpty(data.achievementDetails),
      id: FORM_CARD_TYPE.achievementDetails
    },
    {
      label: 'Licenses And Certification',
      active: !isEmpty(data.certificationDetails),
      id: FORM_CARD_TYPE.certificationDetails,
    },
    {
      label: 'Social Contribution',
      active: !isEmpty(data.socialContributions),
      id: FORM_CARD_TYPE.socialContributions
    },
    {
      label: 'Research & Innovation',
      active: !isEmpty(data.researchAndInnovations),
      id: FORM_CARD_TYPE.researchAndInnovations
    },
    {
      label: 'Project Details',
      active: !isEmpty(data.projectDetails),
      id: FORM_CARD_TYPE.projectDetails
    },
  ];
};

export const calculateProfileCompletionPercent = (data) => {
  let completionCount = 0;
  keys(FORM_CARD_TYPE).forEach((item) => {
    if (item === FORM_CARD_TYPE.personalDetails && isPersonalDetailsComplete(data)) {
      completionCount = completionCount + 1;
    } else if (!isEmpty(data[item])) {
      completionCount = completionCount + 1;
    }
  });
  return (completionCount / 8) * 100;
};

export const getRelativeDate = (postedDate) => {
  const startOfToday = moment().startOf('day');
  const startOfDate = moment(postedDate).startOf('day');
  const daysDiff = startOfDate.diff(startOfToday, 'days');
  const relativeDate = daysDiff === 0 ? 'Today' : moment(postedDate).startOf('day').fromNow();
  return relativeDate;
};

export const mobileRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const countryCodeMobileRegExp = /\+?\d[\d -]{8,12}\d/;
export const emailRegExp = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

export const FORM_VALIDATIONS = {
  personalDetails: Yup.object().shape({
    firstName: Yup.string().required('This field is required')
      .nullable(true),
    lastName: Yup.string().required('This field is required')
      .nullable(true),
    contactNumber: Yup.string().required('Mobile number is required')
      .matches(countryCodeMobileRegExp, 'Contact number is not valid')
      .nullable(true),
    email: Yup.string().required('Email Id is required')
      .matches(emailRegExp, 'Email Id  is not valid')
      .nullable(true),
    address: Yup.string().required('This field is required')
      .nullable(true),
    experienceCount: Yup.string().required('This field is required')
      .nullable(true),
    zipcode: Yup.string().min(6).max(6).required('Required')
      .matches(/^[0-9]+$/, 'Enter valid zipcode')
      .nullable(true)
      .transform(value => (!value ? null : value)),
    city: Yup.string().required('This field is required')
      .nullable(true),
    country: Yup.string().required('This field is required')
      .nullable(true),
    stateResiding: Yup.string().required('This field is required')
      .nullable(true),
  }),

  educationDetails: Yup.object({
    field: Yup.string().required('This field is required'),
    university: Yup.string().required('This field is required'),
    startYear: Yup.string().required('This field is required'),
    endYear: Yup.string().required('This field is required'),
  }),

  professionalDetails: Yup.object({
    title: Yup.string().required('This field is required'),
    company: Yup.string().required('This field is required'),
    location: Yup.string().required('This field is required'),
    startDate: Yup.string().required('This field is required')
  }),

  achievementDetails: Yup.object({
    title: Yup.string().required('This field is required'),
    issuer: Yup.string().required('This field is required')
  }),

  certificationDetails: Yup.object({
    name: Yup.string().required('This field is required'),
    issuer: Yup.string().required('This field is required')
  }),

  socialContributions: Yup.object({
    organization: Yup.string().required('This field is required'),
    role: Yup.string().required('This field is required')
  }),

  researchAndInnovations: Yup.object({
    title: Yup.string().required('This field is required')
  }),

  projectDetails: Yup.object({
    projectTitle: Yup.string().required('This field is required'),
    role: Yup.string().required('This field is required'),
    details: Yup.string().required('This field is required'),
    responsibilities: Yup.string().required('This field is required'),
  })
};