import React, { useEffect, useState } from 'react';

const CharImageMaker = ({ name, classname }) => {
  const bgColor = [
    '#C7A734',
    '#6F99EB',
    '#F49A73',
    '#FFCF10',
    '#F8B599',
    '#195F92',
    '#F26C33'
  ];
  const [alphaColor, setAlphaColor] = useState([]);
  const [initials, setInitials] = useState('');

  // 26 alphabets code generation
  const alpha = Array.from(Array(26)).map((e, i) => {
    const obj = {
      caps: i + 65,
      small: i + 97,
    };
    return obj;
  });

  // specific input valie code extraction
  let alphabet = alpha.map((x) => {
    const obj = {
      caps: String.fromCharCode(x.caps),
      small: String.fromCharCode(x.small),
    };
    return obj;
  });
  // on length of input char assign a color
  alphabet = alphabet.map((item, index) => {
    if (index < 7) {
      const obj = {
        alpha: item.caps,
        small: item.small,
        color: bgColor[index],
      };
      return obj;
    } else if (index < 14) {
      const obj = {
        alpha: item.caps,
        small: item.small,
        color: bgColor[index - 7],
      };
      return obj;
    } else if (index < 21) {
      const obj = {
        alpha: item.caps,
        small: item.small,
        color: bgColor[index - 14],
      };
      return obj;
    } else {
      const obj = {
        alpha: item.caps,
        small: item.small,
        color: bgColor[index - 21],
      };
      return obj;
    }
  });

  useEffect(() => {
    let firstLetterCaps =
      name &&
      name.length &&
      name.split(' ').map((item) => {
        if (item !== 'null') {
          return item?.charAt(0)?.toUpperCase();
        } else {
          return '';
        }
      });
    setInitials(firstLetterCaps);
  }, [name]);

  useEffect(() => {
    const color = alphabet.filter((item) => {
      if (name.length === 0) {
        item.color = '#FFFFFF';
        return item;
      } else if (initials[0] === '') {
        item.color = '#FFFFFF';
        return item;
      } else if (
        item.alpha === (initials ? initials[0] : '') ||
        item.small === (initials ? initials[0] : '')
      ) {
        return item;
      }
    });
    setAlphaColor(color);
  }, [initials]);

  return (
    <>
      {alphaColor[0]?.color && (
        <div
          className={classname}
          style={{ background: alphaColor[0].color, 
            borderRadius: '4px', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            color: '#fff', 
            fontWeight: 500 }}
        >
          {initials?.slice(0, 1)}
        </div>
      )}
    </>
  );
};

export default React.memo(CharImageMaker);
