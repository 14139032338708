import React, { useRef } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
// import { Box, CircularProgress } from '@mui/material';

const DPInfiniteScrollable = ({
  hasNextPage,
  isNextPageLoading,
  items,
  loadNextPage,
  itemRendererConfig,
  styleConfig,
}) => {
  const { rowHeight } = styleConfig;
  const listRef = useRef(null);

  const itemCount = hasNextPage ? items.length + 1 : items.length;

  const loadMoreItems = isNextPageLoading ? () => { } : loadNextPage;

  const isItemLoaded = index => !hasNextPage || index < items.length;

  const Item = ({ index, style }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = ''; // <Box display="flex" justifyContent="center"><CircularProgress color="secondary" /></Box>;
    } else {
      const { Renderer, rendererProps } = itemRendererConfig;
      content = items[index];
      content = <Renderer {...rendererProps} data={content} />;
    }

    return <div style={style}>{content}</div>;
  };

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <InfiniteLoader
            ref={listRef}
            isItemLoaded={isItemLoaded}
            itemCount={100000}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <List
                className="List"
                height={height}
                itemCount={itemCount}
                itemSize={rowHeight}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width={width}
              >
                {Item}
              </List>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
};

export default DPInfiniteScrollable;