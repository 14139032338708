import React from 'react';
import { UserForms } from 'view/common/utils/userProfileUtil';
import UserProfileWrapper from 'view/common/wrappers/UserProfileWrapper';

const BuildProfile = () => {
  return (
    <UserProfileWrapper
      renderer={UserForms.buildProfile} />
  );
};

export default BuildProfile;