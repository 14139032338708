import React from 'react';
import { Grid, Typography, Button, Box, Divider, InputLabel, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik, useFormik } from 'formik';

const UserFilter = ({ handleClose }) => {

  const formik = useFormik({
    initialValues: {
      role: '',
      jobtitle: '',
      hrmanager: '',
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    }
  });

  const onClearClick = (formik) => {
    formik.resetForm();
  };

  return (
    <Formik>
      <Box>
        <form onSubmit={formik.handleSubmit} >
          <Grid container spacing={2}>

            <Grid item xs={12} style={{ display: 'flex' }}>
              <Typography variant="h4">Filters</Typography>
              <Button
                style={{ marginLeft: '2rem' }}
                onClick={() => onClearClick(formik)}
                variant='text'
                color='secondary'
              >
                Clear All
              </Button>
            </Grid>
          
            <Divider />
          
            <Grid item xs={12} md={4}>
              <InputLabel> Role</InputLabel>
              <Select
                variant='outlined'
                id='role'
                name='role'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.role}
              >
                <MenuItem value={'UI designer'}>UI designer</MenuItem>
                <MenuItem value={'Angular developer'}>Angular developer</MenuItem>
                <MenuItem value={'Software Tester'}>Software Tester</MenuItem>
              </Select>  
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel>Job Title handling</InputLabel>   
              <Select
                variant='outlined'
                id='jobtitle'
                name='jobtitle'
                type='jobtitle'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.jobtitle}
              >
                <MenuItem value={'Teacher'}>Teacher</MenuItem>
                <MenuItem value={'hr'}>Hr</MenuItem>
                <MenuItem value={'developer'}>Developer</MenuItem>
              </Select>  
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel> HR manager</InputLabel>  
              <Select
                variant='outlined'
                fullWidth
                id='hrmanager'
                name='hrmanager'
                type='hrmanager'
                onChange={formik.handleChange}
                value={formik.values.hrmanager}
              >
                <MenuItem value={'hr'} >Hr</MenuItem>
                <MenuItem value={'Teacher'}>Teacher</MenuItem>
                <MenuItem value={'Manager'}>manager</MenuItem>
              </Select>
            </Grid>
         
            <Grid container xs={12} style={{ margin: '1rem 10px' }}>
              <Button
                variant="outlined"
                type="button"
                name="cancel"
                color='secondary'
                value="cancel"
                onClick={handleClose}
              >
              Cancel
              </Button>
              <Button variant="contained" color='secondary' type="submit" style={{ marginLeft: '1rem' }}>Apply</Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Formik>
  );
};

export default UserFilter;