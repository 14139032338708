import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Box, Typography, Button, Icon, Select, MenuItem } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import JobCardEmployer from 'view/common/components/JobCard/JobCardEmployer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DPInfiniteScrollableWrapper from 'view/common/DPInfiniteScrollable/DPInfiniteScrollableWrapper';
import { useNavigate } from 'react-router-dom';
import { onGetAllJobs, onGetJobsCriteria } from 'redux/actions/employerActions/employerDashboard';
import JobCardDraftView from 'view/common/components/JobCard/JobCardDraftView';
import { useSelector } from 'react-redux';
import { resetJobPosting } from 'redux/slices/jobPosting';
import { useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';

const useStyle = makeStyles(theme => ({
  linkClr: {
    color: '#6360CD'
  },
  weekly: {
    margin: '0px 10px 0px 10px',
    [theme.breakpoints.down('md')]: {
      margin: '0px 10px 0px 0px',
    },
  },
  pageTitle: {
    ...theme.typography.title,
    alignSelf: 'center'
  },
  typography: {
    '&.MuiTypography-root': {
      fontFamily: 'poppins',
      lineHeight: '30px',
      fontSize: '20px',
      alignSelf: 'end',
    },
  },
  jobPostedGrid: {
    height: '275px',
    [theme.breakpoints.down('md')]: {
      height: '350px',
    },
  },
  jobCard: {
    marginBottom: '1.3rem',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    padding: '0.5rem 0 0'
  },
  company: {
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: '#797D89',
    margin: '20px 0px',
    fontSize: '13px',
    background: '#F0F0F0',
    borderRadius: '1px',
    padding: 4,
  },
  iconBtn: {
    padding: '7px'
  },
  iconBtn1: {
    padding: '0 1rem',
    '&.MuiButtonGroup-groupedTextHorizontal:not(:last-child)': {
      borderRight: 0,
    }
  },
  btnGroup: {
    padding: '0.5rem 0',
    width: '100%',
    justifyContent: 'space-around',
    background: 'linear-gradient(90deg, #F9F9FC 0%, #FBF6FA 100%)',
    borderRadius: '0px 0px 4px 4px',
  },
  btnMain: {
    textAlign: 'end',
    [theme.breakpoints.down('md')]: {
      textAlign: 'initial',
    },
  },
  btn: {
    margin: '0 13px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  cardListMain: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
  },
  expiryMain: {
    textAlign: 'end',
  },
  main: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

const JobCardListEmployer = () => {
  const classes = useStyle();
  const theme = useTheme();
  const [isDraftJobsVisibility, setDraftJobsVisibility] = useState(false);
  const [isPublishedJobsVisibility, setPublishedJobsVisibility] = useState(true);
  const [totalDraftedJobs, setTotalDraftedJobs] = useState();
  const [totalPublishedJobs, setTotalPublishedJobs] = useState();
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const employerDetails = useSelector(state => get(state, ['userProfile', 'employer'], ''));
  const [employerState, setEmployerState] = useState(employerDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onNewJobPostClick = () => {
    navigate('/employer/jobposting/add');
  };

  const onAllJobPostBtnClick = () => {
    navigate('/employer/allJobs');
  };

  const getRequestParams = useCallback(({ pageCount, pageSize }) => {
    return {
      companyDetailsId: employerState.id,
      entryStatus: ['DRAFT'],
      sortBy: ['lastUpdatedOn'],
      pageNo: pageCount,
      pageSize,
      currentTab: 'DRAFT'
    };
  }, [employerState]);

  useEffect(() => {
    dispatch(resetJobPosting());
    setEmployerState(employerDetails);
  }, [employerDetails]);

  const displayDraftsJobs = () => {
    setDraftJobsVisibility(true);
    setPublishedJobsVisibility(false);
  };

  const displayPublishedJobs = () => {
    setDraftJobsVisibility(false);
    setPublishedJobsVisibility(true);
  };

  const handleJobsSection = (event) => {
    const selectedSection = event.target.value;
    selectedSection === 'draft' ? displayDraftsJobs() : displayPublishedJobs();
  };
  return (
    <Box>
      <Grid container xs={12} style={{ marginBottom: '0.5rem', paddingTop: '5px' }}>
        <Grid item xs={12} md={12} lg={4}>
          <Typography className={classes.pageTitle}> Jobs
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ marginBottom: '0.5rem', paddingTop: '8px' }}>
        <Grid item xs={12} md={12} lg={4} style={{ marginBottom: '0.5rem' }}>
          <Select
            disableUnderline
            style={{ border: '1px solid black', padding: '2px 20px 2px 11px' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleJobsSection}
            defaultValue='posted'
          >
            <MenuItem value={'posted'}>Posted{totalPublishedJobs ? ` (${totalPublishedJobs})` : ''}</MenuItem>
            <MenuItem value={'draft'}>Draft{totalDraftedJobs ? ` (${totalDraftedJobs})` : ''}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={12} lg={8} className={classes.btnMain}>
          <Button
            className={classes.btn}
            variant="outlined"
            color="secondary"
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            onClick={onAllJobPostBtnClick}
          >
                All Job Postings
          </Button>
          <Button
            variant="contained"
            color="secondary"
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            onClick={onNewJobPostClick}
            startIcon={<Icon>add_circle</Icon>}>
                New Job Post
          </Button>
        </Grid>
      </Grid>
      {/* <JobCardDraftView /> */}
      {isDraftJobsVisibility && <Box style={{ height: '890px' }}>
        {
          !isEmpty(employerState) && (
            <DPInfiniteScrollableWrapper
              dispatcher={onGetJobsCriteria}
              dataPath={['employerDashboard', 'jobsByCriteria', 'DRAFT']}
              itemRendererConfig={
                {
                  Renderer: JobCardDraftView,
                }
              }
              styleConfig={matchesSM ? { rowHeight: 340 } : { rowHeight: 300 }}
              pageConfig={{
                pageSize: 10,
              }}
              getRequestParams={getRequestParams}
              setTotalItemsCountsInParent={setTotalDraftedJobs}
            />
          )
        }
      </Box>}

      {isPublishedJobsVisibility && <Box style={{ height: '890px' }}>
        <DPInfiniteScrollableWrapper
          dispatcher={onGetAllJobs}
          dataPath={['employerDashboard', 'jobs']}
          itemRendererConfig={
            {
              Renderer: JobCardEmployer,
            }
          }
          styleConfig={matchesSM ? { rowHeight: 450 } : { rowHeight: 308 }}
          pageConfig={{
            pageSize: 10,
          }}
          setTotalItemsCountsInParent={setTotalPublishedJobs}
        />
      </Box>}
    </Box>

  );
};

export default JobCardListEmployer;
