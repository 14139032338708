import { endPoints } from 'constants/applicantEndPoints';
import { UPLOAD_RESUME_SUCCESS } from 'constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi } from 'middleware/api';

export const uploadResume = createAsyncThunk(UPLOAD_RESUME_SUCCESS, async (data) => {
  const formData = new FormData();
  formData.append('file',data.file);
  const endpoint = endPoints.uploadResume;
  const response = await callApi(formData, 'post', endpoint, true);
  return response;
});