import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Avatar, Button, Grid, makeStyles, Popover, Typography } from '@material-ui/core';
import { Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import clsx from 'clsx';


const useStyles=makeStyles(theme=>({
  pageTitle: {
    ...theme.typography.title
  },
  title: {
    fontSize: '18px',
    lineHeight: '27px'
  },
  subTitle: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400
  },
  box: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    '& .MuiPaper-root': {
      padding: '10px'
    }
  },
  avatar: {
    width: '100px',
    height: '100px'
  },
  d_flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  expe: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    display: 'flex',
    flexDirection: 'row',
  },
  years: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    marginLeft: '10px'
  },
  rating: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
      margin: '3px 0 0 5px',
      color: '#FF7100'
    }
  },
  skills: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  },
  round: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 10px',
    gap: '5px',
    width: 'fit-content',
    height: '26px',
    background: '#E0DFFF',
    borderRadius: '20px',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    margin: '5px',
  },
  content: {
    flexDirection: 'column'
  },
  outlineRound: {
    background: 'transparent',
    border: '1px solid #6361CD',
    cursor: 'pointer',
    '& .MuiButton-text': {
      color: '#6361CD'
    }
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

export default function MentorCard({ avatar, name, designation, company, experience, rating, skills }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box className={classes.box}> 
      <Card>
        <CardContent style={{ padding: '10px' }}>
          <Grid container>
            <Grid item md={3} className={classes.d_flex}>
              <Avatar className={classes.avatar} src={ avatar } />
              <Typography variant='subtitle2'> Mentor</Typography>
              <div className={classes.rating}>
                <Rating 
                  name="half-rating-read" 
                  defaultValue={rating}
                  value={rating} 
                  precision={0.5} 
                  readOnly size='small' 
                  style={{ color: '#FF7100' }}
                  emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                <Typography>{rating % 1 === 0 ? `${rating}.0` : rating}</Typography>
              </div>
            </Grid>
            <Grid item md={9}>
              <Grid container className={classes.content}>
                <Grid item style={{ marginLeft: '5px' }}>
                  <div className={classes.titleContainer}>
                    <Typography variant='subtitle2' className={classes.title}>{name}</Typography>
                    <Button className={clsx(classes.round, classes.outlineRound, classes.years)} style={{ color: '#6360CD', borderRadius: '4px' }}>
                      Connect
                    </Button>
                  </div>
                  <Typography variant='subtitle2' className={classes.subTitle}>{designation} - {company}</Typography>
                  <Typography variant='subtitle1' className={classes.expe}>Mentorship Experience: <Typography className={classes.years}>{experience} Years</Typography></Typography>
                </Grid>
                <Grid item style={{ display: 'flex', flexWrap: 'inherit', marginTop: '20px' }}>
                  { skills?.slice(0, 4).map(item=>(
                    <div className={classes.round}>
                      <Typography className={classes.skills}>{item}</Typography>
                    </div> 
                  ))}
                  { skills?.length > 4 && <div className={clsx(classes.round, classes.outlineRound)}>
                    <Button className={classes.skills} onClick={handleClick}>+ {skills.length - 4 } More</Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      { skills?.slice(4).map(item=>(
                        <div className={classes.round}>
                          <Typography className={classes.skills}>{item}</Typography>
                        </div> 
                      ))}
                    </Popover>
                  </div>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        {/* <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions> */}
      </Card>
    </Box>
  );
}
