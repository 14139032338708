import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import DPTImer from 'view/common/DPTimer';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { find, isEmpty, result } from 'lodash';

const VideoCall = () => {
  const param = useParams();
  const interviewPrepDetails = useSelector(state => state.interviewManagement.interviewPrepDetails);
  const myScheduleData = useSelector(state => state.interviewManagement.mySchedule);
  const [candidateDetails, setcandidateDetails] = useState({
    remainingTime:'',
    interviewLink:''
  });
  
  useEffect(() => {
    if (!isEmpty(interviewPrepDetails)) {
      const remainingTime = new Date(interviewPrepDetails?.interviewDetail?.startTime).getTime() - new Date().getTime();
      setcandidateDetails({ remainingTime: remainingTime, interviewLink: interviewPrepDetails?.interviewDetail?.interviewMeetingDetailsDTO?.interviewMeetingUrl });
    }
  }, [interviewPrepDetails]);
  
  useEffect(() => {
    if (!isEmpty(myScheduleData) && isEmpty(interviewPrepDetails)){
      if (param?.interviewId) {
        const selectedScheduled = result(find(myScheduleData.interviewDetails, 
          ({ interviewDetail }) => interviewDetail.id === param?.interviewId), 'interviewDetail');
        const remainingTime = new Date(selectedScheduled?.startTime).getTime() - new Date().getTime();
        setcandidateDetails({ remainingTime: remainingTime, interviewLink: selectedScheduled?.interviewMeetingDetailsDTO?.interviewMeetingUrl });    
      } else {
        const remainingTime = new Date(myScheduleData?.interviewDetails[0]?.interviewDetail?.startTime).getTime() - new Date().getTime();
        setcandidateDetails({ remainingTime: remainingTime, interviewLink: myScheduleData?.interviewDetails[0]?.interviewDetail?.interviewMeetingDetailsDTO?.interviewMeetingUrl });
      }
    } 
  }, [myScheduleData]);

  return (
    <Box>
      <Box>
        <DPTImer isOnlytimer remainingTime={candidateDetails.remainingTime} link={candidateDetails.interviewLink} />
      </Box>
    </Box>
  );
};

export default VideoCall;