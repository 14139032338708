import axios from 'axios';
import { get, isUndefined } from 'lodash';
import { toast } from 'react-toastify';
import { BASE_URL } from 'constants/index';

axios.defaults.headers['Content-Type'] ='application/json';
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && err.response.data === 'Authorization header is invalid' && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const url = `${BASE_URL}/security/auth/token/refresh`;
          const token = localStorage.getItem('deepailytics.auth.refreshToken');
          let config = { method: 'get', url, responseType: 'json' };

          config.headers = { Authorization: `Bearer ${token}` };

          const refreshTokenResponse = await axios(config);
          const newToken = get(refreshTokenResponse, 'data.accessToken');

          if (!isUndefined(newToken)) {
            const refreshToken = get(refreshTokenResponse, 'data.refreshToken');
            localStorage.setItem('deepailytics.auth.token', newToken);
            localStorage.setItem('deepailytics.auth.refreshToken', refreshToken);
          }
          
          // Set the AUTH token for any request
          axios.interceptors.request.use(function (config) {
            config.headers.Authorization = newToken ? `Bearer ${newToken}` : null;
            return config;
          });
          

          return axios(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data &&
            (_error.response.data.code === 'REFRESH_TOKEN_EXPIRED' || _error.response.data.code === 'REFRESH_TOKEN_NOT_VALID')){
            localStorage.removeItem('deepailytics.auth.token');
            localStorage.removeItem('deepailytics.user.role');
            localStorage.removeItem('deepailytics.auth.refreshToken');
            toast.error('Logging out due to inactivity. Please login again.');
            setTimeout(() => {
              window.location.href='/';
            }, 3000);
          }
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);

export default axios;