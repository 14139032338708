import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Typography, Grid, makeStyles, Button, Divider } from '@material-ui/core';
import { CardContent, Box, Chip } from '@material-ui/core';
import { onGetAllJobTitle } from 'redux/actions/employerActions/Configurations/jobActions';
import { fetchLovByKey } from 'redux/actions/lovActions';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'themes/colors';
import PinterestIcon from 'asset/icons/pinterest.svg';
import { get } from 'lodash';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formContainer: {
    backgroundColor: 'white',
    padding: '2em',
    borderRadius: 4,
    [theme.breakpoints.down('md')]: {
      marginTop: '2em',
      paddingLeft: '0.25em',
      paddingRight: '0.25em',
      paddingBottom: '1em',
    },
  },
  jobID: {
    border: '1px solid #797D89',
    borderRadius: '4px',
    padding: '1px 4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  chip: {
    margin: '0.15rem',
    backgroundColor: `${colors.blue1}`,
    color: 'white',
  },
  detailsGrid: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
  },
  qualifyGrid: {
    margin: '1rem 0',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  gridSet: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'grid',
    },
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  innerTitle: {
    marginLeft: '10px'
  }
}));

const PreviewJobPosting = (props) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  let formData = values;
  const [qualificationName, setQualificationName] = useState('');
  const [jobTitleName, setJobTitleName] = useState('');
  const dispatch = useDispatch();
  const getQualifications = async () => {
    const [{ payload }, { payload: jobTitles }] = await Promise.all([
      dispatch(fetchLovByKey('qualification')),
      dispatch(onGetAllJobTitle({}))
    ]);
    if (payload && payload.data) {
      const qualificationObj = payload.data.items.find(qualification => qualification.id === formData.qualification);
      if (qualificationObj && qualificationObj.name) {
        await setQualificationName(qualificationObj.name);
      }
    }
    if (jobTitles && jobTitles.length) {
      const jobTitleObj = jobTitles.find(jobTitle => jobTitle.id === formData.jobTitle);
      if (jobTitleObj && jobTitleObj.title) {
        await setJobTitleName(jobTitleObj.title);
      }
    }
  };
  const { onNext, setStepNumber } = props;
  const employerProfileData = useSelector(state => get(state, ['userProfile', 'employer']));
  let employeeBenifitData = [];
  if (employerProfileData && employerProfileData.employeeBenefits && employerProfileData.employeeBenefits.length) {
    employeeBenifitData = `✅${employerProfileData.employeeBenefits.map(benefit => benefit.name).join('\n\n✅')}`;
  }
  const companyDetailsAddress = `${get(employerProfileData, 'address[0].city', '')}, ${get(employerProfileData, 'address[0].state', '')}, ${get(employerProfileData, 'address[0].country', '')}`;
  const companyValues = employerProfileData.companyValues;

  useEffect(() => {
    getQualifications();
  }, [formData]);
  return (
    <>
      <Grid
        fullWidth className={classes.formContainer} container direction="column" spacing={1}>
        <Grid container style={{ marginBottom: '1rem' }} justifyContent="space-between">
          <Grid container style={{ marginBottom: '1.5rem' }} >
            <Grid item xs={10} style={{ display: 'flex' }}>
              <img
                src={PinterestIcon}
                alt="pinterest Icon"
                style={{
                  width: '50px',
                  marginRight: '0.8rem',
                  marginBottom: '19px',
                }}
              />
              <div>
                <Box className={classes.gridSet}>
                  <Box>
                    <Typography
                      variant="h5"
                    >
                      {jobTitleName}
                    </Typography>
                  </Box>
                  <Box className={classes.jobID}>
                    <Typography variant="subtitle1">Job ID : {formData.jobCode}
                    </Typography>
                  </Box>
                </Box>
                <Box display={'flex'} alignItems="center">
                  <Typography variant="subtitle2" style={{ fontSize: '16px', color: '#797D89', fontWeight: 500 }}>
                  </Typography>
                </Box>
                <Typography variant="subtitle2" style={{ fontSize: '14px' }}>
                  Positions - {formData.openPositions}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'end' }}>
              <Button variant="outlined" color="secondary" onClick={() =>onNext(formData, 1, setStepNumber, 'back')} style={{ margin: '5px 5px 5px 0px' }}>Edit</Button>
            </Grid>
          </Grid>
        </Grid>
        <CardContent style={{ padding: '5px 30px 15px 30px' }}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={3} className={classes.detailsGrid}>
              <Typography variant="subtitle1">Experience</Typography>
              <Typography variant="subtitle2">{formData.experienceMin} - {formData.experienceMax} Years</Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.detailsGrid}>
              <Typography variant="subtitle1">Salary</Typography>
              <Typography variant="subtitle2">{formData.salaryMin} - {formData.salaryMax} {formData.currency}</Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.detailsGrid}>
              <Typography variant="subtitle1">Location</Typography>
              <Typography variant="subtitle2">Pune, Maharashtra</Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.detailsGrid}>
              <Typography variant="subtitle1">Working Hours</Typography>
              <Typography variant="subtitle2">{formData.workTimingsFrom} - {formData.workTimingsTo}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0} className={classes.qualifyGrid}>
            <Grid item xs={12} md={3} >
              <Typography variant="subtitle1">Qualification</Typography>
              <Typography variant="subtitle2">{qualificationName}</Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.detailsGrid}>
              <Typography variant="subtitle1">Specific Qualification</Typography>
              <Typography variant="subtitle2">{formData.qualificationSpecific}</Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.detailsGrid}>
              <Typography variant="subtitle1">Job type</Typography>
              <Typography variant="subtitle2">{formData.jobType}</Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.detailsGrid}>
              <Typography variant="subtitle1">Seniority level</Typography>
              <Typography variant="subtitle2">{formData.seniorityLevel}</Typography>
            </Grid>
          </Grid>
          {formData.jobDescription && <Grid container spacing={0} style={{ marginBottom: '1rem' }}>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1">Job Description</Typography>
              <Typography variant="subtitle2">{formData.jobDescription}</Typography>
            </Grid>
          </Grid>}
          {formData.requiredSkills.length > 0 && <Grid container spacing={0} style={{ marginBottom: '1rem' }}>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1">Required Skills </Typography>
              {
                formData.requiredSkills.map((skillItem) => {
                  return skillItem && (
                    <Chip label={skillItem.name} className={classes.chip} />
                  );
                })
              }

            </Grid>
          </Grid>}
          {formData.preferredSkills.length > 0 && <Grid container spacing={0} style={{ marginBottom: '1rem' }}>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1">Preferred Skills</Typography>
              {
                formData.preferredSkills.map((skillItem) => {
                  return skillItem && (
                    <Chip label={skillItem.name} className={classes.chip} />
                  );
                })
              }

            </Grid>
          </Grid>}
        </CardContent>
        <Divider />
        <CardContent style={{ padding: '25px 30px 15px 30px' }}>
          <Grid container justifyContent="space-between" style={{ marginBottom: '0.8rem' }}>
            <Typography variant="subtitle1" style={{ alignSelf: 'end' }}>Roles & Responsiblities</Typography>
            <Button variant="outlined" color="secondary" onClick={() =>onNext(formData, 2, setStepNumber, 'back')} >Edit</Button>
          </Grid>
          <Grid container spacing={0} style={{ marginBottom: '1rem' }}>
            <Grid item xs={12} md={12}>
              <TextField
                multiline
                rows={5}
                variant="outlined"
                fullWidth
                value={formData.rolesAndResponsibilities}
                disabled
              />
              {/* 
                keping it for future 
                <RichTextEditor readOnly={true} label={'Roles and Responsiblities'} name="rolesAndResponsibilities" width={'100%'} height={'150px'} /> 
              */}
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginBottom: '1rem' }}>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1">Perks, Benefits & Culture<br /></Typography>
              <TextField
                multiline
                rows={5}
                variant="outlined"
                fullWidth
                value={employeeBenifitData}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginBottom: '1rem' }}>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1">Impact of Position<br /></Typography>
              <TextField
                multiline
                rows={5}
                variant="outlined"
                fullWidth
                value={formData.impactOfPosition}
                disabled
              />
              {/* 
                keeping it for future
                <RichTextEditor readOnly={true} label={'Impact of Position'} name="impactOfPosition" width={'100%'} height={'150px'} /> 
              */}
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle1">Company Details<br /></Typography>
            <Typography>{employerProfileData.about}</Typography>
            <Typography variant="subtitle1" className={classes.subTitle}>Website: <Typography className={classes.innerTitle}>{employerProfileData.website}</Typography></Typography>
            <Typography variant="subtitle1" className={classes.subTitle}>Address: <Typography className={classes.innerTitle}>{companyDetailsAddress}</Typography></Typography>
          </Grid>
          <Grid container spacing={0} style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle1">Values of Company<br /></Typography>
            <Typography>{companyValues}</Typography>
          </Grid>
        </CardContent>
      </Grid>
    </>
  );
};

export default PreviewJobPosting;
