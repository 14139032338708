import { Container, Grid, InputLabel, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import * as moment from 'moment';

const ResearchInnovationForm = ({ formik, id, setIsError }) => {
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setErrorMsg('');
    setIsError(false);
    if (!moment(moment().add(1, 'days').format('YYYY-MM-DD')).isAfter(formik?.values?.issuedOn)) {
      setErrorMsg('Issue Date should not be greater than todays date');
      setIsError(true);
    } 
  }, [formik.values.issuedOn]);

  return (<Container style={{ padding: '2rem' }} id={id}>
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputLabel htmlFor="TextField-input" required>Title</InputLabel>
          <TextField variant="outlined"
            id="title"
            placeholder='Enter title'
            name="title"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.title}
            fullWidth
            helperText={formik.errors.title}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel htmlFor="bootstrap-input">Issued On</InputLabel>
          <TextField
            variant="outlined"
            id="date"
            name="issuedOn"
            placeholder='Date'
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.issuedOn}
            inputProps={{
              max: moment().format('YYYY-MM-DD'),
            }}
            helperText={formik.errors.issuedOn}
          />
          {
            errorMsg && (formik.touched.issuedOn) && (
              <Typography style={{ color: 'red', fontSize: '0.75rem', 'fontWeight': '400' }}>{errorMsg}</Typography>
            )
          }
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="TextField-input">Description</InputLabel>
          <TextField
            id="multiline-static"
            placeholder="Enter description"
            multiline
            rows={4}
            variant="outlined"
            name={'description'}
            onChange={formik.handleChange}
            value={formik.values.description}
            fullWidth />
        </Grid>
      </Grid>
    </form>
  </Container >
  );
};

export default ResearchInnovationForm;