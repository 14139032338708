import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDecisionTableData } from 'redux/restAPIs/employerAPI/decision';
import { EmployerActions } from '../actionTypes';

export const onGetDecisionTableData = createAsyncThunk(
  EmployerActions.GET_DECISION_DATA,
  async (payload) => {
    const response = await getDecisionTableData(payload);
    return response.data;
  }
);