import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import DPSummarySection from 'view/common/DPSummarySection';
import StaticQuestions from 'view/employer/pages/Configuration/StaticQuestion';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { formatData } from 'view/common/utils/interviewerUtil';

const InterviewPreparation = () => {
  const interviewPrepDetails = useSelector(state => state.interviewManagement.interviewPrepDetails);
  const myScheduleData = useSelector(state => state.interviewManagement.mySchedule);

  const [interviewSummary, setinterviewSummary] = useState([]);

  useEffect(() => {
    if (!isEmpty(myScheduleData) && myScheduleData?.interviewDetails) {
      const { interviewDetails } = myScheduleData;
      const data = formatData(interviewDetails[0]);
      setinterviewSummary(data);
    }
    return () => {
      setinterviewSummary([]);
    };
  }, [myScheduleData]);
  
  useEffect(() => {
    if (interviewPrepDetails && !isEmpty(interviewPrepDetails)) {
      const data = formatData(interviewPrepDetails);
      setinterviewSummary(data);
    }
    return () => {
      setinterviewSummary([]);
    };
  }, [interviewPrepDetails]);

  return (
    <>
      <Box>
        <DPSummarySection SummaryDetails={interviewSummary} />
        <StaticQuestions showContent={true} usedIn="interviewPrep" />
      </Box>
    </>
  );
};

export default InterviewPreparation;
