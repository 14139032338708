import * as React from 'react';
import { makeStyles, styled } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Link,
  Typography,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import { linearProgressClasses } from '@mui/material/LinearProgress';

const useStyle = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0.5rem'
  },
  cardContent: {
    padding: '0 1rem'
  },
  card: {
    marginTop: '15px',
    border: '1px solid #E4E4E4',
    background: '#fafafa',
    borderRadius: 4,
  },
  link: {
    fontSize: '12px',
  },
  barTitle: {
    fontSize: '12px',
    textAlign: 'end',
    marginRight: '7px'
  },
  viewBtn: {
    padding: 0,
    fontWeight: 300
  },
  company: {
    fontWeight: 300,
    lineHeight: '22px'
  },
  smIconBtn: {
    display: 'flex',
    padding: 0,
    marginRight: '1rem'
  }
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 0,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#FFBEA3'
  }
}));


const DPBarChart = ({ chartData }) => {
  const classes = useStyle();
  return (
    chartData?.map((item) => {
      const { data, title, subTitle } = item;
      return (
        <Card elevation={0} className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {title}
                  </Typography>
                </Grid>
                {subTitle && <Grid item xs={8} style={{ textAlign: 'right' }}>
                  <Link variant='text' className={classes.link} >{ subTitle }</Link>
                </Grid>}
              </Grid>

              {data?.map(bar => (
                <Grid container spacing={0} className={classes.gridContainer}>
                  <Grid item xs={3}>
                    <Typography className={classes.barTitle}>
                      {bar.title}
                    </Typography>
                  </Grid>

                  <Grid item xs={8} className={classes.barGrid}>
                    <BorderLinearProgress
                      variant="determinate"
                      style={{ Color: 'red' }}
                      value={bar.percent}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Typography style={{ fontSize: '10px', marginLeft: '5px' }}>
                      {bar.percent}%
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </div>
          </CardContent>
        </Card>
      );
    }));
};
export default DPBarChart;