import { get, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onEmployerProfileDetails } from 'redux/actions/employerActions/employerAuthentication';
import Routes from './route/Routes';

const Employer = () => {
  const companyInfo = useSelector(state => get(state, ['userProfile', 'employer'], {}));
  const dispatch = useDispatch();

  useEffect(async() => {
    if (isEmpty(companyInfo)) {
      await dispatch(onEmployerProfileDetails({}));
    }
  }, []);

  return <Routes />;
};

export default Employer;
