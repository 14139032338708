import { createSlice } from '@reduxjs/toolkit';
import { uploadResume } from 'redux/actions/uploadResume';

const initialState = {
  posts: [],
  status: 'idle',
  error: null,
};

const uploadResumeSlice = createSlice({
  name: 'uploadResumeSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [uploadResume.pending]: (state) => {
      state.status = 'loading';
    },
    [uploadResume.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.error = action?.payload;
    },
    [uploadResume.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action?.payload;
    },
  },
});

export default uploadResumeSlice.reducer;
