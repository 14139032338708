import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { NEWS } from 'mockData/News';
import { 
  getMySchedule, 
  onApplyJob, 
  onGetJobDetails, 
  onGetRecommendedJobs, 
  onReferJob, 
  onSaveJob, 
  onGetNews, 
  onGetLearning,
  onGetAppliedJobs,
  onGetReferredJobs,
  onGetSavedJobs,
  setDrawer,
  onProfileStats,
  onTopCompanies,
  onKeySkills
} from 'redux/actions/applicantDashboard';

const initialState = {
  recommendedJob: {
    content: [],
    status: 'idle',
    error: null,
  },
  jobDetailsById: {
  },
  jobDetails: {
  },
  applyJobs: {},
  news: NEWS,
  learning: [],
  isDrawerOpen: false,
  myAppliedJobs: [],
  myReferredJobs: [],
  mySavedJobs: [],
  profileStats: {},
  topCompanies: {},
  keySkills: {}
};

const applicantDashboard = createSlice({
  name: 'getJobsMe',
  initialState,
  reducers: {},
  extraReducers: {
    [onGetRecommendedJobs.pending]: (state) => {
      state.recommendedJob.status = 'loading';
    },
    [onGetRecommendedJobs.fulfilled]: (state, action) => {

      state.recommendedJob.status = 'succeeded';
      let prevContent = [];
      if (action?.payload?.number > 1) {
        prevContent = JSON.parse(JSON.stringify(state.recommendedJob.content));
      }
      state.recommendedJob = {
        ...action.payload,
        content: [...prevContent, ...get(action, ['payload', 'content'], [])]
      };
    },
    [onGetRecommendedJobs.rejected]: (state, action) => {
      state.recommendedJob.status = 'failed';
      state.recommendedJob.error = action.payload;
    },
    /* Job Detaile */
    [onGetJobDetails.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetJobDetails.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.jobDetails = action.payload;
    },
    [onGetJobDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    /* Apply Jobs */
    [onApplyJob.pending]: (state) => {
      state.status = 'loading';
    },
    [onApplyJob.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.applyJobs = action.payload;
    },
    [onApplyJob.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    // Save Job
    [onSaveJob.pending]: (state) => {
      state.status = 'loading';
    },
    [onSaveJob.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.saveJob = action.payload;
    },
    [onSaveJob.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    // Refer Job
    [onReferJob.pending]: (state) => {
      state.status = 'loading';
    },
    [onReferJob.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.referJob = action.payload;
    },
    [onReferJob.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    // get applicant schedule
    [getMySchedule.pending]: (state) => {
      state.status = 'loading';
    },
    [getMySchedule.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.mySchedule = action.payload;
    },
    [getMySchedule.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    /* news */
    [onGetNews.pending]: (state) => {
      state.news.status = 'loading';
    },
    [onGetNews.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.news = action.payload;
    },
    [onGetNews.rejected]: (state) => {
      state.news.status = 'failed';
    },

    /* Learning */
    [onGetLearning.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetLearning.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.learning = action.payload;
    },
    [onGetLearning.rejected]: (state) => {
      state.status = 'failed';
    },

    /* My Applied Jobs */
    [onGetAppliedJobs.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetAppliedJobs.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.myAppliedJobs = action.payload;
    },
    [onGetAppliedJobs.rejected]: (state) => {
      state.status = 'failed';
    },

    /* My Refereed Jobs */
    [onGetReferredJobs.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetReferredJobs.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.myReferredJobs = action.payload;
    },
    [onGetReferredJobs.rejected]: (state) => {
      state.status = 'failed';
    },

    /* My Saved Jobs */
    [onGetSavedJobs.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetSavedJobs.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.mySavedJobs = action.payload;
    },
    [onGetSavedJobs.rejected]: (state) => {
      state.status = 'failed';
    },

    /* Drawer */
    [setDrawer.fulfilled]: (state, action) => {
      state.isDrawerOpen = action.payload;
    },
    /* Profile stats*/
    [onProfileStats.pending]: (state) => {
      state.status = 'loading';
    },
    [onProfileStats.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.profileStats = action.payload;
    },
    [onProfileStats.rejected]: (state) => {
      state.status = 'failed';
    },
    /* Profile stats*/
    [onTopCompanies.pending]: (state) => {
      state.status = 'loading';
    },
    [onTopCompanies.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.topCompanies = action.payload;
    },
    [onTopCompanies.rejected]: (state) => {
      state.status = 'failed';
    },
    /* Profile stats*/
    [onKeySkills.pending]: (state) => {
      state.status = 'loading';
    },
    [onKeySkills.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.keySkills = action.payload;
    },
    [onKeySkills.rejected]: (state) => {
      state.status = 'failed';
    },
  },
});

export default applicantDashboard.reducer;