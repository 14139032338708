import React, { useState } from 'react';
import * as yup from 'yup';
import { YupValidator } from '../../../../../utils/YupValidator';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Formik, Form } from 'formik';
import { Grid, Button, Typography, InputLabel } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormikInputField } from '../../../../ui-components';

const useStyles = makeStyles(theme => ({
  labelSubtext: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '1.5rem',
    color: '#24283C',
  },
  check: {
    display: 'flex',
    marginTop: '2px'
  },
  selectType: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '5rem',
    marginLeft: '-10px',
    [theme.breakpoints.down('md')]: {
      gap: '0rem',
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
  },
  radioBtnLabel: {
    fontFamily: 'poppins',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    color: '#24283C',
  },
}));

const AddQuestionForm = ({ initialValues, addQuestionorEditQuestion, updateQuestion, usedIn }) => {
  const [checked, setChecked] = useState(updateQuestion ? updateQuestion.mandatory : false);
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedValue, setSelectedValue] = React.useState('textBox');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const validationSchema = yup.object({
    question: YupValidator('Required'),
  });

  const handleCheckedChange = (e) => {
    const { checked } = e.target;
    setChecked(checked);
  };

  const handleSubmit = async (values, actions) => {
    const questionDetails = {
      id: values && values.id,
      question: values && values.question,
      mandatory: checked,
      type: 'TEXT',
    };

    const prepQuestionDetails ={
      id: values && values.id,
      questionTitle : values && values.question,
    };
    if (usedIn === 'interviewPrep') {
      addQuestionorEditQuestion(prepQuestionDetails, () => {
        actions.setTouched({});
      }); 
    } else {
      addQuestionorEditQuestion(questionDetails, () => {
        actions.setTouched({});
      });
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {() => (
          <Form>
            <Grid container spacing={2} direction={matchesMD ? 'column' : 'row'}>
              <Grid item md={matchesMD ? 12 : 12}>
                <InputLabel htmlFor="TextField-input">Question</InputLabel>
                <FormikInputField localePrefix="DHIYO.CONFIGURATION.STATIC_QUESTIONS.ADD_FORM.QUESTION" name="question" placeholder="Enter your question" />
                <div className={classes.check}>
                  <div>
                    <input type="checkbox" name="mandatory" checked={checked} onChange={handleCheckedChange} />
                  </div>
                  <span className={classes.labelSubtext}>Mandatory Question</span>
                </div>
              </Grid>

              <Grid item md={matchesMD ? 12 : 12}>
                <InputLabel htmlFor="TextField-input">Choose Answer Type</InputLabel>

                <div className={classes.selectType}>
                  <div className={classes.radioSection}>
                    <Radio checked={selectedValue === 'radioButton'} onChange={handleChange} value="radioButton" name="radio-buttons" disabled inputProps={{ 'aria-label': 'radioButton' }} />
                    <Typography variant="subtitle2">Radio Button</Typography>
                  </div>
                  <div className={classes.radioSection}>
                    <Radio checked={selectedValue === 'checkBox'} onChange={handleChange} value="checkBox" name="radio-buttons" disabled inputProps={{ 'aria-label': 'checkBox' }} />
                    <Typography variant="subtitle2">Check Box</Typography>
                  </div>
                  <div className={classes.radioSection}>
                    <Radio checked={selectedValue === 'dropDown'} onChange={handleChange} value="dropDown" name="radio-buttons" disabled inputProps={{ 'aria-label': 'dropDown' }} />
                    <Typography variant="subtitle2">Custom List (Dropdown)</Typography>
                  </div>
                  <div className={classes.radioSection}>
                    <Radio checked={selectedValue === 'textBox'} onChange={handleChange} value="textBox" name="radio-buttons" inputProps={{ 'aria-label': 'textBox' }} />
                    <Typography variant="subtitle2">Text Box</Typography>
                  </div>
                </div>
              </Grid>

              <Grid item md={matchesMD ? 12 : 6} style={{ paddingTop: 0 }}>
                <FormikInputField localePrefix="DHIYO.CONFIGURATION.STATIC_QUESTIONS.ADD_FORM.QUESTION" name="answer" placeholder="Enter your answer" disabled />
              </Grid>

              <Grid item md={matchesMD ? 12 : 12} style={{ marginTop: '10px' }}>
                <Button type="submit" variant="contained" color="secondary" style={{ minWidth: 100 }} disableRipple disableElevation>
                  {updateQuestion ? 'Update' : 'Add'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddQuestionForm;
