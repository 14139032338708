import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  cardContainer: {
    margin: '5.8rem 10rem',
  },
  spanText: {
    color: '#000',
    paddingLeft: '0.5rem'
  }
}));


const Help = () => {
  const classes = useStyle();

  return (
    <Box className={classes.cardContainer}>
      <Typography style={{ fontFamily: 'poppins', fontSize: '14px', marginBottom: '2rem' }}>
        Help
      </Typography>
      <Card sx={{ minWidth: 275 }} >
        <CardContent>
          <Typography style={{ fontFamily: 'poppins', fontSize: '20px', fontWeight: '600', lineHeight: '30px' }}>
            About Deepailytics
          </Typography>
          <Typography style={{ fontFamily: 'poppins', fontSize: '16px', fontWeight: '400', lineHeight: '24px', marginTop: '1rem' }}>
            With over 200 million unique visitors every month from over 60 different countries, Deepailytics has become the catalyst for putting the world to work. It has armed the recruiters with the right tools and resources that are required to improve the effectiveness of their recruitment processes. Also allows professionals to network, get inspired, get job offers and assignments, gain recognition and enhance their commercial potential.
            <br />
            The top most job Portal for freshers' hiring in India with a database of over 1 Crore resumes. Professional Placement Portal which focuses on strengthening the healthcare industry by helping motivated and talented doctors propel their careers in India.
          </Typography>
        </CardContent>
      </Card>
      <Typography style={{ fontFamily: 'poppins', fontSize: '16px', fontWeight: '400', marginTop: '3rem' }}>
        Please drop your queries at query@deepailytics.com
      </Typography>
      <Typography style={{ fontFamily: 'poppins', fontSize: '16px', fontWeight: '600', marginTop: '1rem' }}>
        Contact Us at +91 78410 45100
      </Typography>
    </Box>
  );
};

export default Help;