import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { fetchLovByKey } from 'redux/actions/lovActions';

const initialState = {};

const lovSlice = createSlice({
  name: 'lovSlice',
  initialState,
  reducers: {},
  extraReducers: {
    // eslint-disable-next-line no-unused-vars
    [fetchLovByKey.pending]: (state) => {
      state.lovLoadingStatus = true;
    },
    [fetchLovByKey.fulfilled]: (state, action) => {
      const key = get(action, ['meta', 'arg']);
      const data = get(action, ['payload', 'data', 'items']);
      state[key] = data;
      state.lovLoadingStatus = false;
      // state.status = 'succeeded';
    },
    [fetchLovByKey.rejected]: (state, action) => {
      // state.status = 'failed';
      state.error = action.payload;
      state.lovLoadingStatus = false;
    },
  },
});

export default lovSlice.reducer;
