const timezoneList = [
  {
    'id': 'Pacific/Midway',
    'name': 'Midway Island, Samoa'
  },
  {
    'id': 'Pacific/Pago_Pago',
    'name': 'Pago Pago'
  },
  {
    'id': 'Pacific/Honolulu',
    'name': 'Hawaii'
  },
  {
    'id': 'America/Anchorage',
    'name': 'Alaska'
  },
  {
    'id': 'America/Vancouver',
    'name': 'Vancouver'
  },
  {
    'id': 'America/Los_Angeles',
    'name': 'Pacific Time (US and Canada)'
  },
  {
    'id': 'America/Tijuana',
    'name': 'Tijuana'
  },
  {
    'id': 'America/Edmonton',
    'name': 'Edmonton'
  },
  {
    'id': 'America/Denver',
    'name': 'Mountain Time (US and Canada)'
  },
  {
    'id': 'America/Phoenix',
    'name': 'Arizona'
  },
  {
    'id': 'America/Mazatlan',
    'name': 'Mazatlan'
  },
  {
    'id': 'America/Winnipeg',
    'name': 'Winnipeg'
  },
  {
    'id': 'America/Regina',
    'name': 'Saskatchewan'
  },
  {
    'id': 'America/Chicago',
    'name': 'Central Time (US and Canada)'
  },
  {
    'id': 'America/Mexico_City',
    'name': 'Mexico City'
  },
  {
    'id': 'America/Guatemala',
    'name': 'Guatemala'
  },
  {
    'id': 'America/El_Salvador',
    'name': 'El Salvador'
  },
  {
    'id': 'America/Managua',
    'name': 'Managua'
  },
  {
    'id': 'America/Costa_Rica',
    'name': 'Costa Rica'
  },
  {
    'id': 'America/Montreal',
    'name': 'Montreal'
  },
  {
    'id': 'America/New_York',
    'name': 'Eastern Time (US and Canada)'
  },
  {
    'id': 'America/Indianapolis',
    'name': 'Indiana (East)'
  },
  {
    'id': 'America/Panama',
    'name': 'Panama'
  },
  {
    'id': 'America/Bogota',
    'name': 'Bogota'
  },
  {
    'id': 'America/Lima',
    'name': 'Lima'
  },
  {
    'id': 'America/Halifax',
    'name': 'Halifax'
  },
  {
    'id': 'America/Puerto_Rico',
    'name': 'Puerto Rico'
  },
  {
    'id': 'America/Caracas',
    'name': 'Caracas'
  },
  {
    'id': 'America/Santiago',
    'name': 'Santiago'
  },
  {
    'id': 'America/St_Johns',
    'name': 'Newfoundland and Labrador'
  },
  {
    'id': 'America/Montevideo',
    'name': 'Montevideo'
  },
  {
    'id': 'America/Araguaina',
    'name': 'Brasilia'
  },
  {
    'id': 'America/Argentina/Buenos_Aires',
    'name': 'Buenos Aires, Georgetown'
  },
  {
    'id': 'America/Godthab',
    'name': 'Greenland'
  },
  {
    'id': 'America/Sao_Paulo',
    'name': 'Sao Paulo'
  },
  {
    'id': 'Atlantic/Azores',
    'name': 'Azores'
  },
  {
    'id': 'Canada/Atlantic',
    'name': 'Atlantic Time (Canada)'
  },
  {
    'id': 'Atlantic/Cape_Verde',
    'name': 'Cape Verde Islands'
  },
  {
    'id': 'UTC',
    'name': 'Universal Time UTC'
  },
  {
    'id': 'Etc/Greenwich',
    'name': 'Greenwich Mean Time'
  },
  {
    'id': 'Europe/Belgrade',
    'name': 'Belgrade, Bratislava, Ljubljana'
  },
  {
    'id': 'CET',
    'name': 'Sarajevo, Skopje, Zagreb'
  },
  {
    'id': 'Atlantic/Reykjavik',
    'name': 'Reykjavik'
  },
  {
    'id': 'Europe/Dublin',
    'name': 'Dublin'
  },
  {
    'id': 'Europe/London',
    'name': 'London'
  },
  {
    'id': 'Europe/Lisbon',
    'name': 'Lisbon'
  },
  {
    'id': 'Africa/Casablanca',
    'name': 'Casablanca'
  },
  {
    'id': 'Africa/Nouakchott',
    'name': 'Nouakchott'
  },
  {
    'id': 'Europe/Oslo',
    'name': 'Oslo'
  },
  {
    'id': 'Europe/Copenhagen',
    'name': 'Copenhagen'
  },
  {
    'id': 'Europe/Brussels',
    'name': 'Brussels'
  },
  {
    'id': 'Europe/Berlin',
    'name': 'Amsterdam, Berlin, Rome, Stockholm, Vienna'
  },
  {
    'id': 'Europe/Helsinki',
    'name': 'Helsinki'
  },
  {
    'id': 'Europe/Amsterdam',
    'name': 'Amsterdam'
  },
  {
    'id': 'Europe/Rome',
    'name': 'Rome'
  },
  {
    'id': 'Europe/Stockholm',
    'name': 'Stockholm'
  },
  {
    'id': 'Europe/Vienna',
    'name': 'Vienna'
  },
  {
    'id': 'Europe/Luxembourg',
    'name': 'Luxembourg'
  },
  {
    'id': 'Europe/Paris',
    'name': 'Paris'
  },
  {
    'id': 'Europe/Zurich',
    'name': 'Zurich'
  },
  {
    'id': 'Europe/Madrid',
    'name': 'Madrid'
  },
  {
    'id': 'Africa/Bangui',
    'name': 'West Central Africa'
  },
  {
    'id': 'Africa/Algiers',
    'name': 'Algiers'
  },
  {
    'id': 'Africa/Tunis',
    'name': 'Tunis'
  },
  {
    'id': 'Africa/Harare',
    'name': 'Harare, Pretoria'
  },
  {
    'id': 'Africa/Nairobi',
    'name': 'Nairobi'
  },
  {
    'id': 'Europe/Warsaw',
    'name': 'Warsaw'
  },
  {
    'id': 'Europe/Prague',
    'name': 'Prague Bratislava'
  },
  {
    'id': 'Europe/Budapest',
    'name': 'Budapest'
  },
  {
    'id': 'Europe/Sofia',
    'name': 'Sofia'
  },
  {
    'id': 'Europe/Istanbul',
    'name': 'Istanbul'
  },
  {
    'id': 'Europe/Athens',
    'name': 'Athens'
  },
  {
    'id': 'Europe/Bucharest',
    'name': 'Bucharest'
  },
  {
    'id': 'Asia/Nicosia',
    'name': 'Nicosia'
  },
  {
    'id': 'Asia/Beirut',
    'name': 'Beirut'
  },
  {
    'id': 'Asia/Damascus',
    'name': 'Damascus'
  },
  {
    'id': 'Asia/Jerusalem',
    'name': 'Jerusalem'
  },
  {
    'id': 'Asia/Amman',
    'name': 'Amman'
  },
  {
    'id': 'Africa/Tripoli',
    'name': 'Tripoli'
  },
  {
    'id': 'Africa/Cairo',
    'name': 'Cairo'
  },
  {
    'id': 'Africa/Johannesburg',
    'name': 'Johannesburg'
  },
  {
    'id': 'Europe/Moscow',
    'name': 'Moscow'
  },
  {
    'id': 'Asia/Baghdad',
    'name': 'Baghdad'
  },
  {
    'id': 'Asia/Kuwait',
    'name': 'Kuwait'
  },
  {
    'id': 'Asia/Riyadh',
    'name': 'Riyadh'
  },
  {
    'id': 'Asia/Bahrain',
    'name': 'Bahrain'
  },
  {
    'id': 'Asia/Qatar',
    'name': 'Qatar'
  },
  {
    'id': 'Asia/Aden',
    'name': 'Aden'
  },
  {
    'id': 'Asia/Tehran',
    'name': 'Tehran'
  },
  {
    'id': 'Africa/Khartoum',
    'name': 'Khartoum'
  },
  {
    'id': 'Africa/Djibouti',
    'name': 'Djibouti'
  },
  {
    'id': 'Africa/Mogadishu',
    'name': 'Mogadishu'
  },
  {
    'id': 'Asia/Dubai',
    'name': 'Dubai'
  },
  {
    'id': 'Asia/Muscat',
    'name': 'Muscat'
  },
  {
    'id': 'Asia/Baku',
    'name': 'Baku, Tbilisi, Yerevan'
  },
  {
    'id': 'Asia/Kabul',
    'name': 'Kabul'
  },
  {
    'id': 'Asia/Yekaterinburg',
    'name': 'Yekaterinburg'
  },
  {
    'id': 'Asia/Tashkent',
    'name': 'Islamabad, Karachi, Tashkent'
  },
  {
    'id': 'Asia/Calcutta',
    'name': 'India'
  },
  {
    'id': 'Asia/Kathmandu',
    'name': 'Kathmandu'
  },
  {
    'id': 'Asia/Novosibirsk',
    'name': 'Novosibirsk'
  },
  {
    'id': 'Asia/Almaty',
    'name': 'Almaty'
  },
  {
    'id': 'Asia/Dacca',
    'name': 'Dacca'
  },
  {
    'id': 'Asia/Krasnoyarsk',
    'name': 'Krasnoyarsk'
  },
  {
    'id': 'Asia/Dhaka',
    'name': 'Astana, Dhaka'
  },
  {
    'id': 'Asia/Bangkok',
    'name': 'Bangkok'
  },
  {
    'id': 'Asia/Saigon',
    'name': 'Vietnam'
  },
  {
    'id': 'Asia/Jakarta',
    'name': 'Jakarta'
  },
  {
    'id': 'Asia/Irkutsk',
    'name': 'Irkutsk, Ulaanbaatar'
  },
  {
    'id': 'Asia/Shanghai',
    'name': 'Beijing, Shanghai'
  },
  {
    'id': 'Asia/Hong_Kong',
    'name': 'Hong Kong'
  },
  {
    'id': 'Asia/Taipei',
    'name': 'Taipei'
  },
  {
    'id': 'Asia/Kuala_Lumpur',
    'name': 'Kuala Lumpur'
  },
  {
    'id': 'Asia/Singapore',
    'name': 'Singapore'
  },
  {
    'id': 'Australia/Perth',
    'name': 'Perth'
  },
  {
    'id': 'Asia/Yakutsk',
    'name': 'Yakutsk'
  },
  {
    'id': 'Asia/Seoul',
    'name': 'Seoul'
  },
  {
    'id': 'Asia/Tokyo',
    'name': 'Osaka, Sapporo, Tokyo'
  },
  {
    'id': 'Australia/Darwin',
    'name': 'Darwin'
  },
  {
    'id': 'Australia/Adelaide',
    'name': 'Adelaide'
  },
  {
    'id': 'Asia/Vladivostok',
    'name': 'Vladivostok'
  },
  {
    'id': 'Pacific/Port_Moresby',
    'name': 'Guam, Port Moresby'
  },
  {
    'id': 'Australia/Brisbane',
    'name': 'Brisbane'
  },
  {
    'id': 'Australia/Sydney',
    'name': 'Canberra, Melbourne, Sydney'
  },
  {
    'id': 'Australia/Hobart',
    'name': 'Hobart'
  },
  {
    'id': 'Asia/Magadan',
    'name': 'Magadan'
  },
  {
    'id': 'SST',
    'name': 'Solomon Islands'
  },
  {
    'id': 'Pacific/Noumea',
    'name': 'New Caledonia'
  },
  {
    'id': 'Asia/Kamchatka',
    'name': 'Kamchatka'
  },
  {
    'id': 'Pacific/Fiji',
    'name': 'Fiji Islands, Marshall Islands'
  },
  {
    'id': 'Pacific/Auckland',
    'name': 'Auckland, Wellington'
  },
  {
    'id': 'Asia/Kolkata',
    'name': 'Mumbai, Kolkata, New Delhi'
  },
  {
    'id': 'Europe/Kiev',
    'name': 'Kiev'
  },
  {
    'id': 'America/Tegucigalpa',
    'name': 'Tegucigalpa'
  },
  {
    'id': 'Pacific/Apia',
    'name': 'Independent State of Samoa'
  }
];
export default timezoneList;