import { createAsyncThunk } from '@reduxjs/toolkit';
import { addEmpUser, addInterviewer, getAllEmpUsers, getAllInterviewers, getJobPostsById, getUserById, patchUserActiveStatus } from 'redux/restAPIs/employerAPI/userManagement';
import { EmployerActions } from '../actionTypes';

export const onAddEmpUser = createAsyncThunk(
  EmployerActions.ADD_EMP_USER,
  async (payload) => {
    const { type, data } = payload;
    const caller = type === 'user' ? addEmpUser : addInterviewer;
    const response = await caller(data);
    return response.data;
  }
);

export const getInterviewers = createAsyncThunk(
  EmployerActions.GET_INTERVIEWERS,
  async (payload) => {
    const response = await getAllInterviewers(payload);
    return response.data;
  }
);

export const getEmpUsers = createAsyncThunk(
  EmployerActions.GET_EMP_USERS,
  async (payload) => {
    const response = await getAllEmpUsers(payload);
    return response.data;
  }
);

export const getEmpUserById = createAsyncThunk(
  EmployerActions.GET_INTERVIEWER_BY_ID,
  async (payload) => {
    const { id, userType } = payload;
    const response = await getUserById(id, userType);
    return response.data;
  }
);

export const fetchJobPostsByUser = createAsyncThunk(
  EmployerActions.GET_JOB_POSTS_BY_USER,
  async (payload) => {
    const response = getJobPostsById(payload);
    return response.data;
  }
);

export const onUserActiveStatusChange = createAsyncThunk(
  EmployerActions.ON_USER_ACTIVE_STATUS_CHANGE,
  async (payload) => {
    const response = patchUserActiveStatus(payload);
    return response.data;
  }
);