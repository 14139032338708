const { endPoints } = require('constants/employerEndpoints');
const { callApi } = require('middleware/api');

export const addJobTitle = (data) => {
  return callApi(data, 'POST', endPoints.configuration.jobTitle, true);
};

export const getJobTitleList = (data) => {
  return callApi(null, 'GET', `${endPoints.configuration.jobTitle}/readByPage?keyword=${data.search}&Size=${data.pageSize}&pageNo=${data.pageNo}&sortBy=${data.sortBy}`, true);
};

export const getAllJobTitles = () => {
  return callApi(null, 'GET', `${endPoints.configuration.allJobTitle}`, true);
};

export const editJobTitle = (data) => {
  return callApi(data, 'PUT', endPoints.configuration.jobTitle, true);
};

export const deleteJobTitle = (id) => {
  return callApi(null, 'DELETE', `${endPoints.configuration.jobTitle}/${id}`, true);
};
export const getAllJobTitleList = () => {
  return callApi(null, 'GET', `${endPoints.configuration.jobTitle}/all`, true);
};

export const getAllJobTitleLocation = () => {
  return callApi(null, 'GET', `${endPoints.configuration.companyLocation}/all`, true);
};

export const getAIJobDescription = (data) => {
  return callApi(data, 'POST', `${endPoints.configuration.AIjobdescription}/openai/generate_job_description`, false, true);
};