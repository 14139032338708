import RegisterAccount from 'view/applicant/pages/Registration/RegisterAccount';
import RegisterNow from 'view/applicant/pages/Registration/RegisterNow';
import LoginForm from '../components/Login/LoginForm';
import EmployerRegisterForm from 'view/employer/pages/Registration/EmployerRegisterForm';
import EmployerHome from 'view/employer/pages/Home/EmployerHome';
import EmployerLoginForm from 'view/employer/pages/Login/EmployerLoginForm';
import InterviewerLoginForm from 'view/interviewer/pages/Login/InterviewerLoginForm';
import { pathOr } from 'ramda';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

export const UserForms = {
  loginForm: 'loginForm',
  registerForm: 'registerForm',
  buildProfile: 'buildProfile',
  employerLoginForm:'employerLoginForm',
  employerRegisterForm:'employerRegisterForm',
  employerHome:'employerHome',
  interviewerLoginForm: 'interviewerLoginForm'
};

export const UserInfoAllForms = {
  [UserForms.loginForm]: LoginForm,
  [UserForms.registerForm]: RegisterNow,
  [UserForms.buildProfile]: RegisterAccount,
  [UserForms.employerLoginForm]: EmployerLoginForm,
  [UserForms.employerRegisterForm]: EmployerRegisterForm,
  [UserForms.employerHome]: EmployerHome,
  [UserForms.interviewerLoginForm]: InterviewerLoginForm,
};

export const getUserInfoFormRenderer = (renderer) => {
  return UserInfoAllForms[renderer];
};

export const getUserType = () => {
  const role = localStorage.getItem('deepailytics.user.role');
  switch (role) {
  case 'ROLE_APPLICANT':
    return 'applicant';
  case 'ROLE_INTERVIEWER':
    return 'interviewer';
  default:
    return 'employer';
  }
};

export const isProfileCompleted = () => {
  
  const userType = getUserType();

  const profileData = useSelector(state => pathOr('', ['userProfile', userType], state));

  if (!isEmpty(profileData)) {
    const { profileCompleted } = profileData;
    localStorage.setItem('deepailytics.user.isProfileComplete', profileCompleted);
    return profileCompleted;
  }

  // return false for interviewer as we only want to show logout option
  return userType === 'interviewer' ? false : localStorage.getItem('deepailytics.user.isProfileComplete') === 'true';
};
