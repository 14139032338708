import { createSlice } from '@reduxjs/toolkit';
import { onGetAllOrgRoles } from 'redux/actions/employerActions/organizationRoleActions';

const initialState = {
  roles: [],
  status: 'idle',
  error: null,
};

const organizationRoles = createSlice({
  name: 'organizationRoles',
  initialState,
  reducers: {},
  extraReducers: {
    [onGetAllOrgRoles.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetAllOrgRoles.fulfilled]: (state, action) => {
      state.roles = action.payload;
      state.status = 'succeeded';
    },
    [onGetAllOrgRoles.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default organizationRoles.reducer;
