import { getLocationFormatter } from 'utils/DPTableFormatters';
import ShortlistPopup from 'view/employer/pages/JobPosting/AllJobsPosting/ShortlistPopup';

export const employerActivityRecommendedCols = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 100,
    dataPath: ['applicant', 'firstName']
  },
  {
    id: 'jobTitle',
    label: 'Job Profile',
    minWidth: 180,
    dataPath: ['jobDetail', 'companyJobTitle', 'title']
  },
  {
    id: 'location',
    label: 'Location',
    minWidth: 40,
    formatter: getLocationFormatter(['applicant'])
  },
  {
    id: 'compatibility',
    label: 'Comp',
    minWidth: 40,
    dataPath: ['compatibility']
  },
  {
    id: 'salary',
    label: 'Expected Salary',
    minWidth: 60,
    align: 'center',
    dataPath: ['applicant', 'salaryMax']
  },
  {
    id: 'recommendationType',
    label: 'Source',
    minWidth: 120,
    cellRenderer: 'colorCellRenderer',
    rendererProps: {
      colorMap: {
        recommendation: ['Recommended', '#FFEBEB', '#F95D5D'],
        applied: ['Applied', '#CEFFDB', '#19B942'],
        similarSelected: ['Similar Selected', '#19B942', '#5F5DC5']
      },
      fieldPath: ['recommendationType']
    }
  },
  {
    id: 'action',
    label: 'Actions',
    minWidth: 240,
    cellRenderer: 'btnCellRenderer',
    rendererProps: {
      btnMap: [{
        label: 'Shortlist',
        variant: 'contained',
        onClick: () => { },
        Renderer: ShortlistPopup
      },
      {
        label: 'Revoke',
        variant: 'outlined',
        onClick: () => { }
      }
      ],
      fieldPath: ['status']
    }
  },
];


export const employerActivityAppliedCols = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 100,
    dataPath: ['applicant', 'firstName']
  },
  {
    id: 'jobTitle',
    label: 'Job Profile',
    minWidth: 180,
    dataPath: ['jobDetail', 'companyJobTitle', 'title']
  },
  {
    id: 'location',
    label: 'Location',
    minWidth: 150,
    formatter: getLocationFormatter(['applicant'])
  },
  {
    id: 'compatibility',
    label: 'Comp',
    minWidth: 40,
    dataPath: ['compatibility']
  },
  {
    id: 'salary',
    label: 'Expected Salary',
    minWidth: 60,
    align: 'center',
    dataPath: ['applicant', 'salaryMax']
  },
  {
    id: 'recommendationType',
    label: 'Source',
    minWidth: 120,
    cellRenderer: 'colorCellRenderer',
    rendererProps: {
      colorMap: {
        recommendation: ['Recommended', '#FFEBEB', '#F95D5D'],
        applied: ['Applied', '#CEFFDB', '#19B942'],
        similarSelected: ['Similar Selected', '#19B942', '#5F5DC5']
      },
      fieldPath: ['recommendationType']
    }
  },
  {
    id: 'action',
    label: 'Actions',
    minWidth: 120,
    cellRenderer: 'btnCellRenderer',
    rendererProps: {
      btnMap: [{
        label: 'Shortlist',
        variant: 'contained',
        onClick: () => { },
        Renderer: ShortlistPopup,
      },
      {
        label: 'Revoke',
        variant: 'outlined',
        onClick: () => { }
      }
      ],
      fieldPath: ['status']
    }
  },
];

export const employerActivityShortlistedCols = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 100,
    dataPath: ['applicant', 'firstName']
  },
  {
    id: 'jobProfile',
    label: 'Job Profile',
    minWidth: 100,
    dataPath: ['jobDetail', 'companyJobTitle', 'title']
  },
  {
    id: 'location',
    label: 'Location',
    minWidth: 120,
    formatter: getLocationFormatter(['applicant'])
  },
  {
    id: 'source',
    label: 'Source',
    minWidth: 40,
  },
  {
    id: 'interviewStatus',
    label: 'Interview Status',
    minWidth: 60,
    align: 'center',
    dataPath: ['state']
  },
  {
    id: 'interviewRounds',
    label: 'Interview Rounds',
    minWidth: 120,
    dataPath: ['round']
  }
];

export const employerActivityReportCols = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 100
  },
  {
    id: 'jobProfile',
    label: 'Job Profile',
    minWidth: 30
  },
  {
    id: 'location',
    label: 'Location',
    minWidth: 120,
  },
  {
    id: 'interviewRounds',
    label: 'Interview Rounds',
    minWidth: 120,
  },
  {
    id: 'report',
    label: 'Reports',
    minWidth: 120
  },
  {
    id: 'action',
    label: 'Actions',
    minWidth: 120,
  },
];

export const employerActivityRejectedCols = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 100
  },
  {
    id: 'jobProfile',
    label: 'Job Profile',
    minWidth: 30
  },
  {
    id: 'location',
    label: 'Location',
    minWidth: 120,
  },
  {
    id: 'interviewRounds',
    label: 'Interview Rounds',
    minWidth: 120,
  },
  {
    id: 'report',
    label: 'Reports',
    minWidth: 120
  },
  {
    id: 'reason',
    label: 'Reasons',
    minWidth: 120,
  },
];

export const EATabColumns = {
  recommended: employerActivityRecommendedCols,
  applied: employerActivityAppliedCols,
  shortlisted: employerActivityShortlistedCols,
  reported: employerActivityReportCols,
  rejected: employerActivityRejectedCols
};

export const EANestedTabWiseList = [
  {
    id: 'recommended',
    label: 'Recommended ',
  },
  {
    id: 'applied',
    label: 'Applied',
  },
  {
    id: 'shortlisted',
    label: 'Shortlisted',
  },
  {
    id: 'reported',
    label: 'Reports',
  },
  {
    id: 'rejected',
    label: 'Rejected'
  }
];

export const TABS = [
  { Type: 'NEW' },
  { Type: 'APPLIED' },
  { Type: 'SHORTLISTED' },
];
