import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import PHONE_ICON from 'asset/icons/phone.svg';
import EMAIL_ICON from 'asset/icons/gmail.svg';
import { IconButton, Tooltip } from '@material-ui/core';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: { 
    display: 'flex',
    alignItems: 'center',
    height: '91px',
    padding:'20px',
    [theme.breakpoints.down('sm')]:{
      height: '100%'
    }
  },
  subtitle1: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  title: {
    fontSize: '20px',
    lineHeight: '30px'
  },
  img: {
    width: '40px',
    height: '40px'
  }
}));

const UserCard = ({ data, onCardClick }) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        background: 'white',
        marginTop: '1rem',
        padding: '1rem',
        cursor: 'pointer',
        borderRadius: 4
      }}
      onClick={() => onCardClick(data)}
      className={ classes.root }
    >
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} style={{ display: 'flex', alignSelf: 'center' }}>
          <Avatar style={{ marginRight: '1rem' }} />
          <Typography variant="h5" className={classes.title}>
            {data.name}
            <Typography variant="subtitle1" className={classes.subtitle1}>
              {
                get(data, ['role', 'displayName'], '')
              }
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} md={2} style={{ alignSelf: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', }}>
            <Tooltip title="Contact">
              <IconButton aria-label="delete" type="button" >
                <img src={PHONE_ICON} />
              </IconButton>
            </Tooltip>
            <Typography variant="body2" component="div" className={classes.subtitle1}>{data.contactNumber}</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={4} style={{ alignSelf: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', }}>
            <Tooltip title="E-Mail">
              <IconButton aria-label="delete" type="button" className={classes.img}>
                <img src={EMAIL_ICON} />
              </IconButton>
            </Tooltip>
            <Typography variant="body2" component="div" className={classes.subtitle1}>{data.email}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserCard;