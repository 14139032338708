import React from 'react';
import JobDetails from 'view/applicant/pages/CandidateProfile/JobDetails';

const JobDetailsInterviewer = ({ jobId }) => {
  return (
    <>
      <JobDetails jobIdInterviewer={jobId} isInterviewer />
    </>
  );
};

export default JobDetailsInterviewer;