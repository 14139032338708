import { Box, Container, Grid, Typography, Breadcrumbs, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import FeedbackForm from 'view/applicant/pages/CandidateProfile/FeedbackForm';
import JobDetails from 'view/applicant/pages/CandidateProfile/JobDetails';
import ProfileStats from 'view/common/components/ProfileStats/ProfileStats1';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    marginTop: '1rem'
  },
  color: {
    color: '#333'
  },
  mainGrid: {
    marginTop:'38px',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
}));

const JobContainer = () => {
  const classes = useStyle();
  return (
    <Container style={{ marginTop: '5rem', marginBottom: '5rem' }}>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.color}>
              <Link
                variant="h6"
                to="/applicant"
                className={`${classes.color} MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary`}>
                <b>Home</b>
              </Link>
              <Typography variant="subtitle2">
                Job Details
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <JobDetails />
          </Grid>
          <Grid item xs={12} md={4} className={classes.mainGrid}>
            <ProfileStats
              sectionDataPath={['userProfile', 'applicantProfileCompletion']}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <FeedbackForm />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default JobContainer;
