import * as React from 'react';
import { Box, Button, Grid, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Divider } from '@material-ui/core';
// import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
  },
  cardContainer: {
    margin: '1rem 2rem',
    backgroundColor: 'white'
  },
  spanText: {
    color: '#000',
    paddingLeft: '0.5rem'
  },
  radioRoot: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center'
  }
}));

const InterviewFilters = ({ onClose }) => {
  // const dispatch = useDispatch();

  const onClearClick = () => {
  };
  const classes = useStyle();

 


  return (

    <Box className={classes.cardContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Typography variant="h4">Filters</Typography>
          <Button
            style={{ marginLeft: '1rem' }}
            onClick={() => onClearClick()}
            variant='text'
            color='secondary'
          >
            Clear All
          </Button>
        </Grid>
        <Divider style={{ width: 'inherit' }} />
        <Grid item xs={12} md={12} >
          <FormControl style={{ width: '80%' }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Yet to start"
              name="radio-buttons-group"
              className={classes.radioRoot}
            >
              <FormControlLabel value="Yet to start" control={<Radio />} label="Yet to start" />
              <FormControlLabel value="Ongoing" control={<Radio />} label="Ongoing" />
              <FormControlLabel value="Finished" control={<Radio />} label="Finished" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid container xs={12} style={{ margin: '1rem 10px', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            type="button"
            name="cancel"
            color='secondary'
            value="cancel"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button variant="contained" color='secondary' type="submit" style={{ marginLeft: '1rem' }}>Apply</Button>
        </Grid>
      </Grid>
    </Box>

  );
};

export default InterviewFilters;