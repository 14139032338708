const KEYS = {
  orgRoles: 'orgRoles',
  id: 'id'
};
const defaultData = [
  {
    id: '1',
    role: 'HR Head',
    designation: 'Talent Acquisition Head',
    reportsTo: null,
    createJob:true,
    scheduleInterview:true,
    'createJobTitle':true,
    'addTeamMember':true,
  },
  {
    id: '2',
    role: 'HR Manager',
    designation: 'Talent Acquisition Manager',
    reportsTo:  {
      id : '1',
      role:'HR Head'
    },
    createJob:true,
    scheduleInterview:true,
    'createJobTitle':true,
    'addTeamMember':true,
  },
  {
    id: '3',
    role: 'HR Associate',
    designation: 'Talent Acquisition Executive',
    reportsTo: {
      id : '2',
      role:'HR Manager'
    },
        
    createJob:false,
    scheduleInterview:false,
    'createJobTitle':true,
    'addTeamMember':true,
  },
];


  
export function insertOrgRoles(data) {
  let orgRoles = getAllOrgRoles();
  data['id'] = generateOrgRoleId();
  orgRoles.push(data);
  localStorage.setItem(KEYS.orgRoles, JSON.stringify(orgRoles));
}

export function deleteOrgRole(id) {
  let orgRoles = getAllOrgRoles();
  orgRoles = orgRoles.filter(x => x.id != id);
  localStorage.setItem(KEYS.orgRoles, JSON.stringify(orgRoles));
}

export function updateOrgRole(data) {
  let orgRoles = getAllOrgRoles();
  let recordIndex = orgRoles.findIndex(x => x.id == data.id);
  orgRoles[recordIndex] = { ...data };
  localStorage.setItem(KEYS.orgRoles, JSON.stringify(orgRoles));
}

export function generateOrgRoleId() {
  if (localStorage.getItem(KEYS.id) == null) {
    localStorage.setItem(KEYS.id, '0');
  }
  let id = parseInt(localStorage.getItem(KEYS.id));
  localStorage.setItem(KEYS.id, (++id).toString());
  return id;
}

export function getAllOrgRoles() {
  if (localStorage.getItem(KEYS.orgRoles) == null) {
    localStorage.setItem(KEYS.orgRoles, JSON.stringify(defaultData));
  }
  let orgRoles = JSON.parse(localStorage.getItem(KEYS.orgRoles));
  return orgRoles; 
}