import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const YupValidator = (type, isRequired = true) => {
  const { t : translate } = useTranslation();
  let yupValidator = null;
  const linkValidatorExp =
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
  switch (type) {
  case 'Weblink':
    yupValidator = yup
      .string()
      .matches(linkValidatorExp, translate('DHIYO.FORM_VALIDATION_MSG.INVALID_FORMAT'));
    break;
  case 'ZipCode':
    yupValidator = yup
      .string()
      .matches(/^[0-9]+$/, translate('DHIYO.FORM_VALIDATION_MSG.ZIPCODE'))
      .min(6, translate('DHIYO.FORM_VALIDATION_MSG.ZIPCODE'))
      .max(6, translate('DHIYO.FORM_VALIDATION_MSG.ZIPCODE'));
    break;
  case 'Email':
    yupValidator = yup
      .string()
      .email(translate('DHIYO.FORM_VALIDATION_MSG.REQUIRED'));
    break;
  default:
    yupValidator = yup.string();
  }
  if (yupValidator && isRequired) {
    yupValidator = yupValidator.required(
      translate('DHIYO.FORM_VALIDATION_MSG.REQUIRED')
    );
  }
  return yupValidator;
};