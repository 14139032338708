import addCompanyLocation from 'redux/slices/employerSlices/addCompanyLocation';
import companyInfo from 'redux/slices/employerSlices/companyInfo';
import lovSlice from 'redux/slices/lovSlice';
import uploadResumeSlice from 'redux/slices/uploadResume';
import userAuthenticationSlice from 'redux/slices/userAuthentication';
import userProfileSlice from 'redux/slices/userProfile';
import addJobTitle from 'redux/slices/employerSlices/addJobTitle';
import addStaticQuestions from 'redux/slices/employerSlices/addQuestion';
import addJobPosting from 'redux/slices/jobPosting';
import userManagement from 'redux/slices/employerSlices/userManagement';
import organizationRoles from 'redux/slices/employerSlices/organizationRoles';
import applicantDashboard from 'redux/slices/applicantDashboard';
import employerDashboard from 'redux/slices/employerSlices/employerDashboard';
import interviewManagement from 'redux/slices/interviewerSlices/interviewManagement';
import employerInterviewManagement from 'redux/slices/employerSlices/employerInterviewManagement';

const combinedReducers = {
  userAuthentication: userAuthenticationSlice,
  userProfile: userProfileSlice,
  uploadResume: uploadResumeSlice,
  addCompanyLocation: addCompanyLocation,
  companyInfo: companyInfo,
  lov: lovSlice,
  addJobTitle: addJobTitle,
  addStaticQuestions: addStaticQuestions,
  addJobPosting: addJobPosting,
  userManagement: userManagement,
  organizationRoles: organizationRoles,
  applicantDashboard: applicantDashboard,
  employerDashboard: employerDashboard,
  interviewManagement: interviewManagement,
  employerInterviewManagement: employerInterviewManagement 
};

export default combinedReducers;