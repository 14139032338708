import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import {
  Typography, Grid, Card, CardContent, Avatar, Box, Tab, Tabs, CircularProgress
} from '@material-ui/core';
import { onMySchedule } from 'redux/actions/interviewerActions/interviewerSchedule';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { find, isEmpty, result, startsWith } from 'lodash';
import round1 from 'asset/icons/round1.svg';
import round2 from 'asset/icons/round2.svg';
import round3 from 'asset/icons/round3.svg';
import moment from 'moment';
import { onSetInterviewPrepDetails } from 'redux/actions/interviewerActions/interviewPrepQuestion';
import { useParams } from 'react-router-dom';
import { scheduleFilterTab } from 'constants/applicant/applicant';
import clsx from 'clsx';
import { getScheduleFilterDate } from 'view/common/utils/dateUtils';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '130vh',
    background: 'linear-gradient(172.7deg, #FBF5FA 0%, #F5F2F8 54.17%, #F4F8FD 100%)',
  },
  titleBasicDetail: {
    display: 'initial',
    ...theme.typography.h5,
    color: '#555555', // theme.palette.common.gray4,
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContent: {
    padding: '1.3rem 0.8rem 0rem 0.8rem',
  },
  card: {
    marginTop: '15px',
    borderRadius: 4,
    boxShadow: 'box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05)',
    cursor: 'pointer'
  },
  roundLabel: {
    alignSelf: 'baseline'
  },
  iconBtn: {
    background: '#fff',
    margin: '0 1rem',
  },
  linkbtn1: {
    border: '1px solid #e4e4e4',
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dae1fba3',
      border: '1px solid #6361CD',
    },
    '&:active': {
      backgroundColor: '#6361CD',
      color: '#ffffff',
    },
  },
  active: {
    backgroundColor: '#dae1fba3',
    border: '1px solid #6361CD',
  },
  tab: {
    minWidth: '100px'
  },
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px'
  }
}));

const MySchedule = ({ handleClick, setCandidateId, setJobId }) => {
  const param = useParams();
  const classes = useStyles();
  const [mySchedule, setMySchedule] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeId, setActiveId] = useState(0);
  const dispatch = useDispatch();
  const myScheduleData = useSelector(state => state.interviewManagement.mySchedule);
  const [currentTab, setCurrentTab] = useState(0);
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(async () => {
    setIsLoading(true);
    const currentFilter = await getScheduleFilterDate(currentTab);
    await dispatch(onMySchedule(currentFilter));
    setIsLoading(false);
  }, [currentTab]);

  const a11yProps = (index) => {
    return {
      id: `my-schedule-tab-${index}`,
      'aria-controls': `my-schedule-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    return () => {
      dispatch(onSetInterviewPrepDetails({}));
    };
  }, []);

  const handleCardClick = (data) => {
    dispatch(onSetInterviewPrepDetails(data));
  };

  useEffect(() => {
    setMySchedule(myScheduleData);
    if (param?.interviewId) {
      const selectedInterviewDetails = result(find(myScheduleData.interviewDetails, 
        ({ interviewDetail }) => interviewDetail.id === param?.interviewId), 'interviewDetail');
      const selectedJobDetail = result(find(myScheduleData.interviewDetails, 
        ({ interviewDetail }) => interviewDetail.id === param?.interviewId), 'jobDetail');
      const selectedApplicant = result(find(myScheduleData.interviewDetails, 
        ({ interviewDetail }) => interviewDetail.id === param?.interviewId), 'applicantDetail');
      setActiveId(selectedInterviewDetails?.id || '');
      setJobId(selectedJobDetail?.jobId || '');
      setCandidateId(selectedApplicant?.applicantId || '');
    } else {
      setActiveId(myScheduleData?.interviewDetails?.[0]?.interviewDetail?.id || '');
      setCandidateId(myScheduleData?.interviewDetails?.[0]?.applicantDetail.applicantId || '');
      setJobId(myScheduleData?.interviewDetails?.[0]?.jobDetail.jobId || '');
    }
  }, [myScheduleData]);

  const getClasses = (cards) => {
    return `${classes.card} ${classes.linkbtn1}
    ${activeId === cards?.interviewDetail?.id ? classes.active : ''}`;
  };

  const getEmployeName = (eachEvent) => {
    if (startsWith(location.pathname, '/employer')){
      return `Interviewer: ${eachEvent?.interviewDetail?.interviewerName}`;
    } else { 
      return `Organiser: ${eachEvent?.interviewDetail?.organiserName}`;
    }
  };

  const renderInterviewDetails = () => (
    !isLoading && mySchedule?.interviewDetails?.map(cards => (
      <Card key={cards?.interviewDetail?.id || cards?.applicantDetail?.applicantId} elevation={0} className={getClasses(cards)}
        onClick={() => {
          handleClick(cards); setActiveId(cards?.interviewDetail?.id); handleCardClick(cards);
        }}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item xs={3} style={{ borderRight: '1px solid #cccccc', paddingRight: '3px' }}>
              <span style={{ fontSize: '18px' }}><b>{moment(cards.interviewDetail.startTime).format('D')}</b></span> <span style={{ fontSize: '11px' }}>{moment(cards.interviewDetail.startTime).format('MMM')}, {moment(cards.interviewDetail.startTime).format('YYYY')}</span><br />
              <span style={{ fontSize: '14px' }}><b>{moment(cards.interviewDetail.startTime).format('h:mm a')}</b></span>
            </Grid>
            <Grid item xs={9} style={{ display: 'flex' }}>
              <Box>
                <Avatar style={{ margin: '0 7px' }} />
              </Box>
              <Box style={{ width: '100%' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Box>
                    <Typography variant="h6">
                      {cards.applicantDetail.firstName} {cards.applicantDetail.lastName}
                    </Typography>
                  </Box>
                  {cards.interviewDetail.status === 'REVIEW_PENDING' ? (
                    <Box><span style={{ fontSize: '11px' }}>Pending</span></Box>
                  ) : (
                    <Box>
                      {cards.interviewDetail.roundNo === 1 && (
                        <img src={round1} className={classes.roundLabel} />
                      )}
                      {cards.interviewDetail.roundNo === 2 && (
                        <img src={round2} className={classes.roundLabel} />
                      )}
                      {cards.interviewDetail.roundNo === 3 && (
                        <img src={round3} className={classes.roundLabel} />
                      )}
                    </Box>
                  )}
                </Box>
                <Box style={{ marginBottom: '5px' }}>
                  <Typography variant="body1" style={{ fontSize: '13px', marginTop: '-6px' }}>
                    {getEmployeName(cards)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" style={{ fontSize: '13px', marginTop: '-6px' }}>
                    Position: {cards.jobDetail.position}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );

  return (
    <div className={classes.root}>
      <>
        <Grid container style={{ padding: '1rem', marginTop: '3rem' }}>
          <Grid item xs={6} md={12} >
            <Typography
              align="left"
              variant="h5"
              className={classes.titleBasicDetail}>
              My Schedule
            </Typography>
          </Grid>
          <Grid item xs={6} md={12} >
            <Tabs value={currentTab} onChange={handleChange} aria-label="my-schedule" >
              {
                R.map((tab) => {
                  return <Tab key={tab.label} label={tab.label} {...a11yProps(tab.id)} className={classes.tab} />;
                })(scheduleFilterTab)
              }
            </Tabs>
          </Grid>
          <Grid item xs={12} className={clsx({ [classes.circularProgress]: isLoading })}>
            {isLoading && <CircularProgress color="secondary" />}
            {isEmpty(mySchedule?.interviewDetails) && !isLoading &&
              <Card elevation={0}
                className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={9} style={{ display: 'flex' }}>
                      <Box style={{ width: '100%' }}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          No Interviews Assigned
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            }
            {renderInterviewDetails()}
          </Grid>
        </Grid>
      </>
    </div >
  );
};

export default React.memo(MySchedule);

MySchedule.defaultProps = {
  handleClick: () => {}, 
  setCandidateId: () => {}, 
  setJobId: () => {} 
};