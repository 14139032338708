import React from 'react';
import { Chip } from '@mui/material';
import { get } from 'lodash';

const ColorCellRenderer = ({ colorMap, fieldPath, row }) => {
  const value = get(row, [...fieldPath]);
  const [label, bgcolor, color] = get(colorMap, value?.toLowerCase(), ['', '', '']);
  return (
    <div>
      <Chip label={label} style={{ backgroundColor: bgcolor, color }} />
    </div>
  );
};

export default ColorCellRenderer;