
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import clsx from 'clsx';

const useStyle = makeStyles(() => ({ 
  cardContainer: {
    background: '#fff',
    marginBottom: '15px',
    marginTop: '20px',
    boxShadow: 'none',
    padding: '0',
    '&:nth-child(1)': {
      background: 'linear-gradient(90deg, rgba(140, 158, 255, 0.3) 0%, rgba(212, 219, 255, 0.3) 100%)',
      backdropFilter: 'blur(4px)',
      borderRightColor: '#8C9EFF',
      borderRight: '5px solid',
    },
    '&:nth-child(2)': {
      background: 'linear-gradient(90deg, rgba(251, 218, 174, 0.3) 0%, rgba(255, 241, 221, 0.3) 100%)',
      backdropFilter: 'blur(4px)',
      borderRightColor: '#FBDAAE',
      borderRight: '5px solid',
    },
    '&:nth-child(3)': {
      background: 'linear-gradient(90deg, rgba(133, 235, 156, 0.3) 0%, rgba(214, 255, 223, 0.3) 100%)',
      backdropFilter: 'blur(4px)',
      borderRightColor: '#85EB9C',
      borderRight: '5px solid',
    },
    '&:nth-child(4)': {
      background: 'linear-gradient(90deg, rgba(255, 140, 181, 0.3) 0%, rgba(255, 212, 227, 0.3) 100%)',
      backdropFilter: 'blur(4px)',
      borderRightColor: '#FF8CB5',
      borderRight: '5px solid',
    },
  },
  
  cardTitle: {
    fontSize: '20px',
    lineHeight: '28px'
  },
  linkIcon: {
    color: '#24283C',
    marginLeft: '5px'
  },
  link:{
    color: '#24283C',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0
  },
  cardContent: {
    padding: '1rem'
  },
  blue: {
    background: 'linear-gradient(90deg, rgba(140, 158, 255, 0.3) 0%, rgba(212, 219, 255, 0.3) 100%) !important',
    backdropFilter: 'blur(4px) !important',
    borderRightColor: '#8C9EFF !important',
  },
  orange: {
    background: 'linear-gradient(90deg, rgba(251, 218, 174, 0.3) 0%, rgba(255, 241, 221, 0.3) 100%) !important',
    backdropFilter: 'blur(4px) !important',
    borderRightColor: '#FBDAAE !important',
  },
  green: {
    background: 'linear-gradient(90deg, rgba(133, 235, 156, 0.3) 0%, rgba(214, 255, 223, 0.3) 100%) !important',
    backdropFilter: 'blur(4px) !important',
    borderRightColor: '#85EB9C !important',
  },
  pink: {
    background: 'linear-gradient(90deg, rgba(255, 140, 181, 0.3) 0%, rgba(255, 212, 227, 0.3) 100%) !important',
    backdropFilter: 'blur(4px) !important',
    borderRightColor: '#FF8CB5 !important',
  },
  white: {
    background: 'white !important',
    backgroundColor: '#fff',
    backdropFilter: 'blur(4px) !important',
    borderRightColor: '#8C9EFF !important',
  },
  title: {
    fontSize: '16px',
    color: '#333333',
    fontFamily: 'Poppins',
    fontWeight: '500'
  },
  duration: {
    fontSize: '12px',
    color: '#828282',
    fontFamily: 'Poppins',
    fontWeight: '500',
    marginTop: '0.1rem'
  },
  lectures: {
    fontSize: '12px',
    color: '#828282',
    fontFamily: 'Poppins',
    fontWeight: '500'
  },
  rating: {
    fontSize: '12px',
    color: '#333333',
    fontFamily: 'Poppins',
    fontWeight: '500',
    marginTop: '0.4rem'
  },
  description: {
    fontSize: '12px',
    color: '#333333',
    fontFamily: 'Poppins',
    fontWeight: '500',
    marginTop: '0.4rem',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  d_flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dashboardCard: {
    height: 'auto',
    maxHeight: '220px',
    minHeight: '160px'
  }
}));

const LearningCard = (props) => {
  const classes = useStyle();
  const { data, bgcolor, viewAllLink, height } = props;
  return (
    <Card className={clsx( classes.cardContainer, classes[bgcolor])}>
      <CardContent className={clsx(classes.cardContent, { [classes.d_flex]: isEmpty(data), [classes.dashboardCard]: height })}>
        { !isEmpty(data) ? <> 
          {data?.CourseTitle ? <>
            <Box>
              <Typography className={classes.title}>
                {data?.CourseTitle}
              </Typography>
              <Typography className={classes.duration}>
                {data?.Duration}
              </Typography>
              <Typography className={classes.lectures}>
                {data?.NoOfLectures}
              </Typography>
              <Button size='small' variant="contained" color="secondary" >
                <Typography style={{ fontSize: '12px', color: '#FFFFFF', fontFamily: 'Poppins', fontWeight: '400' }}>
                Rating {data?.Rating}
                </Typography>
              </Button>
            </Box>
            <Box >
              <Typography className={classes.description}>
                {data?.Description}
              </Typography>
            </Box>
          </>:
            <Typography className={classes.cardTitle}> {data?.title}</Typography>
          }
          {viewAllLink && <Link to={viewAllLink} className={classes.link} variant="text">View All<ArrowForwardIcon fontSize='small' className={classes.linkIcon} /></Link>}
        </> :
          <Typography variant='subtitle1' style={{ textAlign: 'center' }}>No Record Found</Typography>
        }
      </CardContent>
    </Card>
  );
};

export default LearningCard;

LearningCard.defaultProps = {
  bgcolor: '',
  viewAllLink: '',
  height: false
};