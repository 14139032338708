import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Chip, CircularProgress } from '@material-ui/core';
import { useField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../themes/colors';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { filter, get, isEmpty, isNull } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLovByKey } from 'redux/actions/lovActions';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: '1rem 1rem 1rem 0',
    backgroundColor: `${colors.blue1}`,
    color: 'white',
  },
  button: {
    color: theme.palette.common.blue,
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
  }
}));

const FormikChipSelector = ({
  localePrefix,
  name,
  label,
  maxAllowedSelection = -1,
  apiKey,
  isAIDescriptionActionRequired, 
  handleAIDescriptionReqObject,
  AIDescriptionReqObject,
  ...rest
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const options = useSelector(state => get(state, ['lov', apiKey], []));
  const [, meta, helpers] = useField(name);
  let { value } = meta;
  let lbl = null;
  const [dropdownOptions, setDropdownOptions] = useState(isNull(options) ? [] : options);
  const [userOptions, setUserOptions] = useState('');
  const [isFetchedNewly, setIsFetchedNewly] = useState(false);
  const lovLoadingStatus = useSelector((state) => {
    return get(state, 'lov.lovLoadingStatus');
  });

  if (localePrefix !== null) {
    lbl = translate(`${localePrefix}.LABEL`);
  }
  if (label) {
    lbl = label;
  }

  const { setValue } = helpers;
  if (maxAllowedSelection === -1) {
    maxAllowedSelection = dropdownOptions.length;
  }
  const [selectedItem, setSelectedItem] = React.useState(null);
  let initialValues = dropdownOptions.filter(item =>
    !isEmpty(value) ? !value.includes(item) : true
  );
  // eslint-disable-next-line no-unused-vars
  const [itemList, setItemList] = React.useState(initialValues);

  const onBtnClick = () => {
    if (selectedItem !== null) {
      const exist = value.find(existingValue => !existingValue.deleted && existingValue.name === selectedItem.name);
      if (!exist) {
        setValue([...value, selectedItem]);
        setItemList(prevState =>
          prevState.filter(item => item.name !== selectedItem.name)
        );
      }
    } else {
      if (!userOptions) {
        return;
      }
      const newOption = {
        name: userOptions,
        id: `custom_${userOptions}`
      };
      setDropdownOptions([...dropdownOptions, newOption]);
      setValue([...value, newOption]);
      setItemList(prevState =>
        prevState.filter(item => item.name !== newOption.name)
      );
      setUserOptions('');
    }
    setSelectedItem(null);
  };
  const autoSuggestionItemSelectEvt = (event, newValue) => {
    setSelectedItem(newValue);
  };

  const handleDelete = (itemToRemove) => {
    const newValues = value.map((obj) => {
      return {
        ...obj,
        deleted: obj.deleted || false
      };
    });
    const itemToDelete = newValues.findIndex(item => !item.deleted && item.name === itemToRemove.name);
    newValues[itemToDelete].deleted = true;

    setValue(newValues);
    setItemList(prevState => [
      ...prevState,
      ...filter(dropdownOptions, { name: newValues[itemToDelete].name }),
    ]);
  };

  useEffect(async() => {
    if (isEmpty(options) && !isFetchedNewly) {
      await dispatch(fetchLovByKey(apiKey));
      setIsFetchedNewly(true);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(options)) {
      setDropdownOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (isAIDescriptionActionRequired) {
      let filteredName = value?.filter(obj => !obj.deleted)?.map(item=> item.name);
      handleAIDescriptionReqObject({ ...AIDescriptionReqObject, preferredSkills: filteredName });
    }
  }, [value]);

  if (lovLoadingStatus) {
    return <CircularProgress color="inherit" />;
  }

  return (
    <Grid container direction="column" >
      <Grid item container justifyContent="flex-start" alignItems="center">
        <Grid item xs={11} md={4}>
          <Autocomplete
            options={dropdownOptions}
            getOptionSelected={option => option.name === selectedItem.name}
            getOptionLabel={option => option.name}
            value={selectedItem}
            onChange={autoSuggestionItemSelectEvt}
            renderInput={params => (
              <TextField
                style={{ width: '97%' }}
                {...params}
                placeholder={lbl}
                value={userOptions}
                onChange={e => setUserOptions(e.target.value)}
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && Boolean(meta.error) ? meta.error : undefined}
                variant="outlined" />
            )}
            {...rest} />
        </Grid>
        <Grid item xs={1}>
          <Button
            key={`${name}_BTN`}
            size="small"
            className={classes.button}
            disableRipple
            onClick={onBtnClick}
            disabled={!( value && value.filter(data => !data.deleted).length < maxAllowedSelection)}>
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {value &&
          value.map((data) => {
            if (data.deleted) {
              return;
            }
            let lbl = filter(dropdownOptions, { name: data }).map(v => v.name);
            return (
              <Chip
                key={data.id || data}
                label={isEmpty(lbl) ? data.name : lbl}
                color="secondary"
                onDelete={() => handleDelete(data)}
                className={classes.chip} />
            );
          })}
      </Grid>
    </Grid>
  );
};

FormikChipSelector.propTypes = {
  localePrefix: PropTypes.string,
  name: PropTypes.string.isRequired,
  maxAllowedSelection: PropTypes.number,
};

export default FormikChipSelector;
