import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  image: {
    width: '7rem',
    height: '7rem',
    color: '#fff',
    backgroundColor: '#dae1fb',
    display: 'inline-flex',
    cursor: 'pointer'
  },
});

// Component needs update after API Integration
const ImageAvatar = ({ isReadOnly }) => {
  const [uploadedImage, setImage] = useState(null);
  const classes = useStyles();

  const imageUploader = React.useRef(null);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <ImageAvatarWrapper>
        {isReadOnly ? (
          <Avatar
            alt="Profile Image"
            className={classes.image}
            src={uploadedImage} />
        ) : (
          <>
            <InputImage
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              ref={imageUploader} />
            <div onClick={() => imageUploader.current.click()}>
              <Avatar
                alt="Profile Image"
                className={classes.image}
                src={uploadedImage} />
            </div>
          </>
        )}
      </ImageAvatarWrapper>
    </>
  );
};

const ImageAvatarWrapper = styled.div`
display: "flex";
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
`;

const InputImage = styled.input`
  display: none;
`;

export default ImageAvatar;

ImageAvatar.propTypes = {
  isReadOnly: PropTypes.bool,
};

ImageAvatar.defaultProps = {
  isReadOnly: true,
};
