import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPrepQuestionsList, addPrepQuestion, deletePrepQuestion, editPrepQuestion } from 'redux/restAPIs/interviewerAPI/interviewPrepQuestions';
import * as types from '../../../constants/index';
import { InterviewerActions } from '../actionTypes';

export const onGetPrepQuestion = createAsyncThunk(types.GET_ALL_QUESTIONS_SUCCESS, async (id) => {
  const response = await getPrepQuestionsList(id);
  return response.data;
});
export const onAddPrepQuestion = createAsyncThunk(types.ADD_QUESTIONS_SUCCESS, async (body) => {
  const response = await addPrepQuestion(body);
  return response.data;
});
export const onDeletePrepQuestion = createAsyncThunk(types.DELETE_QUESTIONS_SUCCESS, async ({ interviewId, questionId }) => {
  const response = await deletePrepQuestion({ interviewId, questionId });
  return response.data;
});
export const onEditPrepQuestion = createAsyncThunk(types.EDIT_QUESTIONS_SUCCESS, async () => {
  const response = await editPrepQuestion();
  return response.data;
});
export const onSetInterviewPrepDetails = createAsyncThunk(InterviewerActions.SET_INTERVIEW_PREP_DETAILS, (data) => {
  return data;
});
