import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import DPTImer from 'view/common/DPTimer';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { result, find, isEmpty } from 'lodash';

const VideoCall = () => {
  const param = useParams();
  const myScheduleData = useSelector(state => state.interviewManagement.myCustomSchedule);
  const [candidateDetails, setcandidateDetails] = useState({
    remainingTime:'',
    interviewLink:''
  });
  
  useEffect(() => {
    if (!isEmpty(myScheduleData) && param?.interviewId){
      const selectedScheduled = result(find(myScheduleData.interviewDetails, 
        ({ interviewDetail }) => interviewDetail.id === param?.interviewId), 'interviewDetail');
      const remainingTime = new Date(selectedScheduled?.startTime).getTime() - new Date().getTime();
      setcandidateDetails({ remainingTime: remainingTime, interviewLink: selectedScheduled?.interviewMeetingDetailsDTO?.interviewMeetingUrl });    
    }
  }, [myScheduleData]);

  return (
    <Box>
      <Box>
        <DPTImer isOnlytimer remainingTime={candidateDetails.remainingTime} link={candidateDetails.interviewLink} />
      </Box>
    </Box>
  );
};

export default VideoCall;