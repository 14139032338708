import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { get, startsWith } from 'lodash';
import { interviewManagementTabs } from 'constants/interviewer/interviewSchedule';
import DPOtherDetails from 'view/common/DPOtherDetails/DPOtherDetails';
import InterviewDetails from './InterviewDetails';
import DPTabs from 'view/common/DPTabs/DPTabs';
import { setSelectedTab } from 'redux/slices/interviewerSlices/interviewManagement';
import { useDispatch, useSelector } from 'react-redux';
import DPPrimaryDetails from 'view/common/DPPrimaryDetails';

const pastInterviews = [
  {
    id: 1,
    name: 'Interview Details',
    round: 'Round 1',
    component: (
      <DPPrimaryDetails
        title="Comments:"
        content="UI/UX Designer with a strong design aesthetic who can bring a modern, contemporary and minimalistic style to all our market facing collateral. The ideal candidate would be a person who is a visual thinker and can combine a deep understanding of design with disciplined execution to manage and deliver multiple projects on time."
        boxBorder={false}
        question="Does the candidate have the appropriate educational qualifications, training, or technical skills for this position?"
        legends={[{ remark: 'Average', ratings: 3 }]}
        primary
      />
    )
  },
  {
    id: 2,
    name: 'Interview Details',
    round: 'Round 2',
    component: (
      <DPPrimaryDetails 
        title="Comments:" 
        content="" 
        boxBorder={false} 
        question="Did the candidate demonstrate effective communication skills during the interview?" 
        legends={[{ remark: 'above Average', ratings: 4 }]} 
        primary 
      />
    )
  },
  {
    id: 3,
    name: 'Interview Details',
    round: 'Round 3',
    component: (
      <DPPrimaryDetails 
        title="Comments:" 
        content="" 
        boxBorder={false} 
        question="Did the candidate show enthusiasm for the position and the company?" 
        legends={[{ remark: 'Average', ratings: 3 }]} 
        primary 
      />
    )
  }
];
const InterviewerDashboard = ({ candidateId, jobId }) => {
  const dispatch = useDispatch();
  const currentTab = useSelector(state => get(state, ['interviewManagement', 'currentTab'], 'interview_prep'));
  useEffect(() => {
    const isInterviewID = location.pathname?.split('/')?.[3];
    const isVideoCallPath = startsWith(location.pathname, '/employer') || isInterviewID;
    if (isVideoCallPath) {
      dispatch(setSelectedTab('video_call'));
    }
    return () => {
      
    };
  }, []);
  
  return (
    <Box
      sx={{
        marginTop: '1rem',
      }}
    >
      <DPTabs
        tabList={interviewManagementTabs}
        dispatcher={setSelectedTab}
        selectedTab={currentTab}
      />
      <InterviewDetails currentTab={currentTab} candidateId={candidateId} jobId={jobId} />
      {currentTab === 'interview_prep' && (pastInterviews || []).map((item) => {
        return (
          <DPOtherDetails key={item.id} data={item}>
            {item.component}
          </DPOtherDetails>
        );
      })}
    </Box>
  );
};

export default InterviewerDashboard;
