export const ApplicantActions = {
  AUTH_LOGIN: 'AUTH_LOGIN',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_REGISTER: 'AUTH_REGISTER',
  AUTH_VERIFYEMAIL: 'AUTH_VERIFYEMAIL',
  AUTH_RESENDOTP: 'AUTH_RESENDOTP',
  AUTH_USER_PROFILE_DETAILS: 'AUTH_USER_PROFILE_DETAILS',
  USER_PERSONAL_DETAILS_ADD: 'USER_PERSONAL_DETAILS_ADD',
  USER_EXPERIENCE_DETAILS_ADD_EDIT: 'USER_EXPERIENCE_DETAILS_ADD_EDIT',
  AUTH_USER_EDUCATION_DETAILS: 'AUTH_USER_EDUCATION_DETAILS',
  AUTH_USER_PROFESSIONAL_DETAILS: 'AUTH_USER_PROFESSINAL_DETAILS',
  AUTH_USER_ACHIEVEMENTS_DETAILS: 'AUTH_USER_ACHEIVEMENTS_DETAILS',
  AUTH_USER_CERTIFICATIONS_DETAILS: 'AUTH_USER_CERTIFICATIONS_DETAILS',
  AUTH_USER_SOCIALCONTRIBUTIONS_DETAILS: 'AUTH_USER_SOCIALCONTRIBUTIONS_DETAILS',
  AUTH_USER_INNOVATIONS_DETAILS: 'AUTH_USER_INNOVATIONS_DETAILS',
  AUTH_USER_PROJECTS_DETAILS: 'AUTH_USER_PROJECTS_DETAILS',
  USER_EDUCTAION_DETAILS_ADD_EDIT: 'USER_EDUCTAION_DETAILS_ADD_EDIT',
  AUTH_FORGOT_PASSWORD: 'AUTH_FORGOT_PASSWORD',
  GET_RECOMMENDED_JOBS: 'GET_RECOMMENDED_JOBS',
  GET_JOB_DETAILS: 'GET_JOB_DETAILS',
  APPLY_FOR_JOBS: 'APPLY_FOR_JOBS',
  SAVE_JOB: 'SAVE_JOB',
  REFER_JOB: 'REFER_JOB',
  GET_NEWS_DETAILS: 'GET_NEWS_DETAILS',
  GET_LEARNING_DETAILS: 'GET_LEARNING_DETAILS',
  GET_APPLIED_JOBS: 'GET_APPLIED_JOBS',
  GET_REFERRED_JOBS: 'GET_REFERRED_JOBS',
  GET_SAVED_JOBS: 'GET_SAVED_JOBS',
  SET_DRAWER: 'SET_DRAWER',
  GET_PROFILE_STATS: 'GET_PROFILE_STATS',
  GET_TOP_COMPANIES_STATS: 'GET_TOP_COMPANIES_STATS',
  GET_KEY_SKILLS_STATS: 'GET_KEY_SKILLS_STATS'
};

export const EmployerActions = {
  AUTH_LOGIN: 'AUTH_LOGIN',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_REGISTER: 'AUTH_REGISTER',
  AUTH_VERIFYEMAIL: 'AUTH_VERIFYEMAIL',
  AUTH_RESENDOTP: 'AUTH_RESENDOTP',
  AUTH_EMPLOYER_PROFILE_DETAILS: 'AUTH_EMPLOYER_PROFILE_DETAILS',
  ADD_COMPANY_INFO: 'ADD_COMPANY_INFO',
  GET_ORGANIZATION_ROLES: 'GET_ORGANIZATION_ROLES',
  ON_USER_ACTIVE_STATUS_CHANGE: 'ON_USER_ACTIVE_STATUS_CHANGE',
  GET_INTERVIEWERS:'GET_INTERVIEWERS',
  GET_INTERVIEWER_BY_ID: 'GET_INTERVIEWER_BY_ID',
  GET_JOB_POSTS_BY_USER: 'GET_JOB_POSTS_BY_USER',
  GET_EMP_USERS: 'GET_EMP_USERS',
  ADD_EMP_USER: 'ADD_EMP_USER',
  GET_ALL_JOB_TITLES: 'GET_ALL_JOB_TITLES',
  AUTH_FORGOT_PASSWORD: 'AUTH_FORGOT_PASSWORD',
  GET_JOB_DETAILS: 'GET_JOB_DETAILS',
  GET_ALL_JOBS: 'GET_ALL_JOBS',
  GET_All_JOBS_CRITERIA: 'GET_All_JOBS_CRITERIA',
  GET_APPLIED_JOBS: 'GET_APPLIED_JOBS',
  GET_PROFILE_DETAILS: 'GET_PROFILE_DETAILS',
  GET_INTERVIEW_SCHEDULED_CALLS: 'GET_INTERVIEW_SCHEDULED_CALLS',
  GET_DECISION_DATA: 'GET_DECISION_DATA',
  SET_SIMILAR_CANDIDATE_FLAG: 'SET_SIMILAR_CANDIDATE_FLAG',
  GET_JOB: 'GET_JOB',
  GET_CANDIDATE_BY_JOB_ID : 'GET_CANDIDATE_BY_JOB_ID',
  GET_SIMILAR_APPLICANTS: 'GET_SIMILAR_APPLICANTS',
  GET_CANDIDATE_RECOMMENDED: 'GET_CANDIDATE_RECOMMENDED',
  GET_CANDIDATE_SHORTLISTED: 'GET_CANDIDATE_SHORTLISTED',
  PATCH_SHORTLIST_CANDIDATE: 'PATCH_SHORTLIST_CANDIDATE',
  GET_CUSTOM_MY_SCHEDULE: 'GET_CUSTOM_MY_SCHEDULE',
  GET_INTERVIEW_SLOTS: 'GET_INTERVIEW_SLOTS',
  SHARE_INTERVIEW_SLOTS: 'SHARE_INTERVIEW_SLOTS',
  SHARE_INTERVIEW_SLOTS_CANDIDATE: 'SHARE_INTERVIEW_SLOTS_CANDIDATE',
  GET_STATS_COUNT: 'GET_STATS_COUNT'
};

export const InterviewerActions = {
  AUTH_LOGIN: 'AUTH_LOGIN',
  GET_MY_SCHEDULE: 'GET_MY_SCHEDULE',
  SET_SCHEDULE: 'SET_SCHEDULE',
  SET_INTERVIEW_PREP_DETAILS: 'SET_INTERVIEW_PREP_DETAILS',
  GET_INTERVIEWER_SLOTS: 'GET_INTERVIEWER_SLOTS',
  CONFIRM_INTERVIEWER_SLOTS: 'CONFIRM_INTERVIEWER_SLOTS',
  SET_INTERVIEWER_SLOTS: 'SET_INTERVIEWER_SLOTS',
  GET_MY_SCHEDULE_WITH_FILTER: 'GET_MY_SCHEDULE_WITH_FILTER'
};

export const CommonActions = {
  FETCH_LOV_DATA: 'FETCH_LOV_DATA',
};