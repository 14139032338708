const { endPoints } = require('constants/employerEndpoints');
const { callApi } = require('middleware/api');

export const addQuestion = (data) => {
  return callApi(data, 'POST', endPoints.configuration.staticQuestions, true);
};

export const getQuestionsList = () => {
  return callApi(null, 'GET', `${endPoints.configuration.staticQuestions}/all`, true);
};

export const editQuestion = (data) => {
  return callApi(data, 'PUT', endPoints.configuration.staticQuestions, true);
};

export const deleteQuestion = (id) => {
  return callApi(null, 'DELETE', `${endPoints.configuration.staticQuestions}/${id}`, true);
};
