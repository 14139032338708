import { Container, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Descision_data } from 'mockData/RecommendedJobsData';
import React from 'react';
import HeaderCards from './HeaderCards';
import ComposerPanelWrapper from 'view/common/wrappers/ComposerPanelWrapper';
import DecisionTable from './DecisionTable';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    margin: '25px 0 20px 0 ',
  },
  mainGrid: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  cardResp: {
    padding: '8px',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

const index=()=> {
  const theme = useTheme();

  const RendererExample = () => {
    return (
      null
    );
  };
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  
  const PanelConfig = {
    isOpen: false,
    anchor: 'right',
    Renderer: matchesSM ? RendererExample : null, // ComponentRenderer
    rendererConfigs: {},
  };
  const classes = useStyle();
  let headerData = Descision_data;
  return (
    <ComposerPanelWrapper {...{ panelConfig: PanelConfig }}>
      <Container style={{ marginTop: '5rem', marginBottom: '5rem', minHeight: '100vh', padding: 0 }}>
        <Grid container style={{ marginBottom: '20px', justifyContent: 'space-between', flexDirection: 'row' }}>
          <HeaderCards className={classes.mainGrid} headerData={headerData} />
        </Grid>
        <Grid>
          <DecisionTable />
        </Grid>
      </Container>
    </ComposerPanelWrapper>
  );
};

export default index; 