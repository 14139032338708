import React from 'react';
import { Link, Breadcrumbs, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ScheduleForm from './ScheduleForm';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingTop: '3em',
    paddingLeft: '10em',
    paddingRight: '10em',
    paddingBottom: '10em',
    [theme.breakpoints.down('md')]: {
      marginTop: '3em',
      paddingLeft: '2em',
      paddingRight: '2em',
      paddingBottom: '5em',
    },
  },
  title: {
    ...theme.typography.title,
  },
  linkLabel: {
    ...theme.typography.linkLabel,
  },
  label: {
    ...theme.typography.linkLabel,
    fontWeight: 400,
  }
}));



export default function Schedule() {
  const classes = useStyles();
  const schedulePageHeaderData = {
    pageRoute: '/employer/calendar',
    pageRouteTitle: 'Calendar',
    pageTitle: 'Schedule Interview',
    pageSubTitle: 'Fill the details and create new Interview'
  };

  return (
    <Grid container direction="column" className={classes.mainContainer} spacing={1}>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="secondary" href={schedulePageHeaderData.pageRoute} className={classes.linkLabel}>
            {schedulePageHeaderData.pageRouteTitle}
          </Link>
          <Typography className={classes.label}>{schedulePageHeaderData.pageTitle}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item container direction="column" style={{ marginTop: '2rem', marginBottom: '1rem' }}>
        <Grid>
          <Typography className={classes.title}>{schedulePageHeaderData.pageTitle}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{schedulePageHeaderData.pageSubTitle}</Typography>
        </Grid>
      </Grid>

      <ScheduleForm />

    </Grid>
  );
}
