import React from 'react';
import { Grid, Box, Typography, makeStyles, Button, Card, IconButton, Divider, CardContent } from '@material-ui/core';
import { Note_Data } from 'mockData/CandidateCardData';
import deletecard from 'asset/icons/delete.svg';
import editcard from 'asset/icons/editcard.svg';
import * as R from 'ramda';

const useStyle = makeStyles(() => ({
  iconBtn: {
    margin: '7px',
    width: '17px'
  },
  jobCard: {
    paddingBottom: '0.5rem'
  },
  actionIcon: {
    alignSelf: 'center',
    padding: 0
  },
  margin: {
    marginRight: '1rem'
  }
}));

// HrNotes

const HrNotes = () => {
  const classes = useStyle();
  return (
    <Box>
      {
        R.map((data) => {
          return (
            <>
              <Card elevation={0} className={classes.jobCard} align='left'>
                <CardContent style={{ padding: '8px' }}>
                  <Grid container>
                    <Grid item xs={12} style={{ display: 'flex' }}>
                      <Grid container>

                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'baseline' }}>
                          <Typography variant='subtitle2' className={classes.margin}>
                            {data.note}
                          </Typography>
                          <Typography variant="subtitle1" className={classes.margin}>
                            {data.date}
                          </Typography>
                          <IconButton className={classes.actionIcon} >
                            <img src={editcard} alt='edit card icon' className={classes.iconBtn} />
                          </IconButton>
                          <IconButton className={classes.actionIcon}>
                            <img src={deletecard} alt='delete card icon' className={classes.iconBtn} />
                          </IconButton>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body1">
                            {data.status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Divider />
            </>
          );
        })(Note_Data)
      }
      <Button variant="text" color="secondary" style={{ float: 'left' }}>Add Note</Button>
    </Box>
  );
};
export default HrNotes;