import { createAsyncThunk } from '@reduxjs/toolkit';
import { 
  applyForJobs, 
  getJobDetailsById, 
  getRecommendedJobs, 
  mySchedule, 
  referJob, 
  saveJob, 
  getNewsDetails, 
  getLearningDetails,
  getAppliedJobs,
  getReferredJobs,
  getSavedJobs,
  getProfileStats,
  getTopCompanies,
  getKeySkills
} from 'redux/restAPIs/applicantDashboard';
import { ApplicantActions } from './actionTypes';

export const onGetRecommendedJobs = createAsyncThunk(
  ApplicantActions.GET_RECOMMENDED_JOBS,
  async (payload) => {
    const response = await getRecommendedJobs(payload);
    return response.data;
  }
);

export const onGetNews = createAsyncThunk(
  ApplicantActions.GET_NEWS_DETAILS,
  async () => {
    const response = await getNewsDetails();
    return response.data;
  }
);

export const onGetAppliedJobs = createAsyncThunk(
  ApplicantActions.GET_APPLIED_JOBS,
  async (payload) => {
    const response = await getAppliedJobs(payload);
    return response.data;
  }
);

export const onGetReferredJobs = createAsyncThunk(
  ApplicantActions.GET_REFERRED_JOBS,
  async (payload) => {
    const response = await getReferredJobs(payload);
    return response.data;
  }
);

export const onGetSavedJobs = createAsyncThunk(
  ApplicantActions.GET_SAVED_JOBS,
  async (payload) => {
    const response = await getSavedJobs(payload);
    return response.data;
  }
);

export const onGetLearning = createAsyncThunk(
  ApplicantActions.GET_LEARNING_DETAILS,
  async (payload) => {
    const response = await getLearningDetails(payload);
    return response.data;
  }
);

export const onGetJobDetails = createAsyncThunk(
  ApplicantActions.GET_JOB_DETAILS,
  async (payload) => {
    const response = await getJobDetailsById(payload);
    const details = { ...response.data };
    details.savedKeywords = details.keywords;
    details.keywords = details.keywords.map((keyword) => {
      return {
        ...keyword,
        name: keyword.keyword
      };
    });
    details.savedPreferredSkills = details.preferredSkills;
    details.preferredSkills = details.preferredSkills.map((pSkill) => {
      return {
        ...pSkill,
        name: pSkill.skill
      };
    });
    details.savedRequiredSkills = details.requiredSkills;
    details.requiredSkills = details.requiredSkills.map((rSkill) => {
      return {
        ...rSkill,
        name: rSkill.skill
      };
    });
    details.savedJobFunctions = details.jobFunctions;
    details.jobFunctions = details.jobFunctions.map((jobFunction) => {
      return {
        ...jobFunction,
        name: jobFunction.jobFunction
      };
    });
    return details;
  }
);

export const onApplyJob = createAsyncThunk(
  ApplicantActions.APPLY_FOR_JOBS,
  async (payload) => {
    const response = await applyForJobs(payload);
    return response.data;
  }
);

export const onSaveJob = createAsyncThunk(
  ApplicantActions.SAVE_JOB,
  async (payload) => {
    const response = await saveJob(payload);
    return response.data;
  }
);

export const onReferJob = createAsyncThunk(
  ApplicantActions.REFER_JOB,
  async (payload) => {
    const response = await referJob(payload);
    return response.data;
  }
);

export const getMySchedule = createAsyncThunk(
  ApplicantActions.REFER_JOB,
  async () => {
    const response = await mySchedule();
    return response.data;
  }
);

export const onProfileStats = createAsyncThunk(
  ApplicantActions.GET_PROFILE_STATS,
  async () => {
    const response = await getProfileStats();
    return response.data;
  }
);


export const onTopCompanies = createAsyncThunk(
  ApplicantActions.GET_TOP_COMPANIES_STATS,
  async () => {
    const response = await getTopCompanies();
    return response.data;
  }
);

export const onKeySkills = createAsyncThunk(
  ApplicantActions.GET_KEY_SKILLS_STATS,
  async () => {
    const response = await getKeySkills();
    return response.data;
  }
);

export const setDrawer = createAsyncThunk( ApplicantActions.SET_DRAWER,value => value);

