import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import HeaderCardsEmployer from '../../../applicant/pages/Home/HeaderCardsEmployer';
import JobCardListEmployer from '../../../applicant/pages/Home/JobCardListEmployer';

const useStyle = makeStyles(theme => ({
  mainGrid: {
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

const EmployerDashboard = () => {
  const classes = useStyle();
  return (
    <Box style={{ marginBottom: '3rem' }}>
      <Grid container spacing={4} className={classes.mainGrid}>
        <Grid item xs={12} md={4}>
          <HeaderCardsEmployer />
        </Grid>
        <Grid item xs={12} md={8}>
          <JobCardListEmployer />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployerDashboard;
