import React from 'react';
import { Grid, Box } from '@material-ui/core';
import CandidateProfile from './CandidateProfile';

const ApplicantUserProfile = () => {

  return (
    <Box sx={{
      paddingTop: '37px'
    }}>
      <Grid container xs={12} alignItems="center">
        <Grid item xs={12} style={{ textAlign: 'end' }}>
          <CandidateProfile />
        </Grid>
      </Grid>
    </Box>
  );
};
export default ApplicantUserProfile;