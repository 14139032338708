import { createSlice } from '@reduxjs/toolkit';
import { onAddJobPost } from 'redux/actions/employerActions/JobPosting/jobPostingActions';
import { get } from 'lodash';

const initialState = {
  id: '',
  jobTitle: '',
  jobLocation: '',
  keywords: [],
  savedKeywords: [],
  jobCode: '',
  openPositions: 0,
  interviewRounds: 0,
  seniorityLevel: null,
  jobType: null,
  jobDescription: null,
  requiredSkills: [],
  savedRequiredSkills: [],
  preferredSkills: [],
  savedPreferredSkills: [],
  qualification: null,
  qualificationSpecific: null,
  experienceMin: null,
  experienceMax: null,
  currency: 'INR',
  salaryMin: null,
  salaryMax: null,
  workTimingsFrom: '09:00',
  workTimingsTo: '18:00',
  timeZone: '+05:30',
  jobFunctions: [],
  savedJobFunctions: [],
  rolesAndResponsibilities: null,
  impactOfPosition: null,
  hiringManager: null,
  savedHiringManager: null,
  savedStaticQuestions: [],
  staticQuestions: [],
  interviewerDetails: [{ id: Math.random(), name: '', email_id: '', phone: '' }],
  savedInterviewerDetails: [],
  status: 'idle',
  error: null,
};

const addJobPosting = createSlice({
  name: 'addJobTitles',
  initialState,
  reducers: {
    resetJobPosting: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    // add addJobPosting
    [onAddJobPost.pending]: (state) => {
      state.status = 'loading';
    },
    [onAddJobPost.fulfilled]: (state, action) => {
      const data = get(action, ['payload']);
      if (data) {
        Object.keys(state).forEach((key) => {
          if (data[key]) {
            if (['requiredSkills', 'preferredSkills'].includes(key)) {
              const name = key.replace(/^./, str => str.toUpperCase());
              data[`saved${name}`] = data[key];
              data[key] = data[key].map((skill) => {
                return {
                  ...skill,
                  name: skill.skill
                };
              });
            } else if (key === 'keywords') {
              data['savedKeywords'] = data[key];
              data[key] = data[key].map((keyword) => {
                return {
                  ...keyword,
                  name: keyword.keyword
                };
              });
            } else if (key === 'jobFunctions') {
              data['savedJobFunctions'] = data[key];
              data[key] = data[key].map((jobFunction) => {
                return {
                  ...jobFunction,
                  name: jobFunction.jobFunction
                };
              });
            }
            state[key] = data[key];
          }
        });
      }
      state.status = 'succeeded';
    },
    [onAddJobPost.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const { resetJobPosting } = addJobPosting.actions;
export default addJobPosting.reducer;
