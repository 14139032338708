import React from 'react';
import { Box, Grid } from '@material-ui/core';
import CandidateCard from '../../../../employer/pages/JobPosting/ApplicantProfile/CandidateProfile';
import DPChart from 'view/common/DpChart/DPChart';

const Profile = ({ candidateId }) => {

  const topCompany = [
    {
      title: 'react',
      percent: '80'
    },
    {
      title: 'Designing',
      percent: '65'
    },
    {
      title: 'Pinterest',
      percent: '52'
    },
    {
      title: 'Twitter',
      percent: '42'
    },
    {
      title: 'Accenture',
      percent: '82'
    },
    {
      title: 'TCS',
      percent: '32'
    }
  ];
  return (
    <Box>
      <CandidateCard candidateId={candidateId} isInterviewer >
        <Grid container xs={12} style={{ display: 'flex', width: '100%' }}>
          <Grid item xs={5}>
            <DPChart topCompany={topCompany} title={'key Skills'} />
          </Grid>
          <Grid item xs={5} style={{ marginLeft: '2rem' }}>
            <DPChart topCompany={topCompany} title={'Profile stats'} />
          </Grid>
        </Grid>
      </CandidateCard>
    </Box>
  );
};

export default Profile;