import React, { useState, useRef, useEffect } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Typography, Grid, FormControl, InputLabel, TextField, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormikChipSelector, FormikSelectField, FormikInputField, MultiStepForm, FormStep, FormikTextField } from '../../../../ui-components';
import MinusIcon from 'asset/Minus.svg';
import PlusIcon from 'asset/Plus.svg';
import Tootip from 'asset/ToolTip.svg';
import { experienceList, jopTypeList } from '../Components/List';
import { lovKeys } from 'utils/LOVUtils';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { YupValidator } from 'utils/YupValidator';
import { Link, useNavigate, useParams } from 'react-router-dom';

// components
import CustomRadioButton from '../../../../common/components/CustomCheckBox/CustomCheckBox';
import CompanyInformation from '../Components/CompanyInformation';
import InterviewDetails from '../Components/InterviewDetails';
import StaticQuestion from '../Components/StaticQuestion';
import PreviewJobPosting from '../Components/PreviewJobPosting';
import { toast } from 'react-toastify';

// Actions
import { onAddJobPost, onUpdateJobPost, onChangeJobPostStatus, onSetJobStaticQuestion, onSetJobEmployeeParticipant } from 'redux/actions/employerActions/JobPosting/jobPostingActions';
import { onGetAllJobTitle,onGetAllJobLocation, onGetAIJobDescription } from 'redux/actions/employerActions/Configurations/jobActions';
import { onGetJobEmployeeParticipant, onGetJobStaticQuestion } from 'redux/actions/employerActions/JobPosting/jobPostingActions';
import { onGetJobDetails } from 'redux/actions/applicantDashboard';

const getFormattedCompanyInfo = (data) => {
  if (data.status === 'idle') {
    return {
      id: '',
      jobTitle: '',
      jobLocation: '',
      keywords: [],
      savedKeywords: [],
      openPositions: 0,
      interviewRounds: 0,
      seniorityLevel: '',
      jobType: '',
      jobCode: '',
      jobDescription: '',
      preferredSkills: [],
      savedPreferredSkills: [],
      requiredSkills: [],
      savedRequiredSkills: [],
      jobFunctions: [],
      savedJobFunctions: [],
      qualification: '',
      workTimingsFrom: '09:00',
      workTimingsTo: '18:00',
      timeZone: '+05:30',
      qualificationSpecific: '',
      experienceMin: '',
      experienceMax: '',
      currency: 'INR',
      salaryMin: '',
      salaryMax: '',
      rolesAndResponsibilities: '',
      interviewer: [],
      hiringManager: '',
      savedHiringManager: '',
      hiringManagerEmail: '',
      hiringManagerContactNumber: '',
      impactOfPosition: '',
      interviewerDetails: [{ id: Math.random(), name: '', email_id: '', phone: '' }],
      savedInterviewerDetails: [],
      staticQuestions: [],
      savedStaticQuestions: []
    };
  }
  return data;
};
const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingTop: '3em',
    paddingLeft: '10em',
    paddingRight: '10em',
    paddingBottom: '10em',
    [theme.breakpoints.down('md')]: {
      marginTop: '3em',
      paddingLeft: '2em',
      paddingRight: '2em',
      paddingBottom: '5em',
    },
  },
  title: {
    ...theme.typography.title,
  },
  linkLabel: {
    ...theme.typography.linkLabel,
  },
  label: {
    ...theme.typography.linkLabel,
    fontWeight: 400,
  },
  formContainer: {
    backgroundColor: 'white',
    padding: '2em',
    borderRadius: 4,
  },
  fieldWidth: {
    width: '320px',
    height: '44px',
  },
  positionCount: {
    width: 50,
    '&.MuiInputBase-input.Mui-disabled': {
      textAlign: 'center',
    }
  },
  disabled: {
    '&$disabled': {
      textAlign: 'center'
    }
  },

  experienceSelectBtn: {
    height: '50px',
  },
  check: {
    display: 'flex',
  },
  salarySection: {
    paddingRight: '1em',
    paddingTop: '1em',
  },
  counts: {
    display: 'flex',
    gap: '10px',
    cursor: 'pointer',
  },
  positionSection: {
    display: 'flex',
    width: '30%',
  },
  jobTitileSection: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  inputLabel: {
    color: '#000', marginBottom: '0.5rem',
  },
  chipSelect: {
    display: 'flex', gap: '16px', flexWrap: 'wrap',
  },
  circularProgress: {
    margin: '0% auto', display: 'flex', zIndex: 1
  },
}));

export default function AddJobPost() {
  const formikRef = useRef();
  const classes = useStyles();
  const [openPositions, setOpenPositions] = useState(1);
  const [nextClicked, setNextClicked] = useState(false);
  const [interviewRounds, setInterviewRounds] = useState(1);
  const [seniority, setSeniority] = useState('');
  const [jobType, setJobType] = useState('');
  const [jobTitleList, setJobTitleList] = useState([]);
  const [jobLocationList, setJobLocationList] = useState('');
  const [checkDesciprion, setCheckDesciprion] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAILoading, setIsAILoading] = useState(false);
  const [steps, setSteps] = useState({});
  const [jobDescRequired, setJobDescRequired] = useState(false);
  const [AIDescriptionReqObject, handleAIDescriptionReqObject] = useState({
    jobTitle:'',
    preferredSkills: [],
    company:'',
    jobLocation:'' });
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const addJobPostingData = useSelector(state => get(state, ['addJobPosting']));
  const addStaticQuestionsData = useSelector(state => get(state, ['addStaticQuestions', 'staticQuestions']));

  const [formData, setFormData] = useState(getFormattedCompanyInfo(addJobPostingData));
  let titlesList = [];
  let locationList = [];
  const navigate = useNavigate();
  const params = useParams();
  const validationSchema = yup.object({
    jobTitle: YupValidator('Required'),
    jobCode: YupValidator('Required'),
    jobLocation: YupValidator('Required'),
    // jobDescription: YupValidator('Required'),
    keywords: yup
      .array()
      .compact(value => value.deleted)
      .min(1, translate('DHIYO.FORM_VALIDATION_MSG.REQUIRED')),
    preferredSkills: yup
      .array()
      .compact(value => value.deleted)
      .min(1, translate('DHIYO.FORM_VALIDATION_MSG.REQUIRED')),
    requiredSkills: yup
      .array()
      .compact(value => value.deleted)
      .min(1, translate('DHIYO.FORM_VALIDATION_MSG.REQUIRED')),
    qualification: YupValidator('Required'),
    experienceMin: yup.number().typeError('Please enter valid number').min(0, 'Min experience should be > 0').required('Min experience is required'),
    experienceMax: yup.number().typeError('Please enter valid number').min(yup.ref('experienceMin'), 'Max experience must be same or greater than min experience').required('Max experience is required'),
    currency: YupValidator('Required'),
    salaryMin: yup.number().typeError('Please enter valid number').min(1, 'Min salary should be > 0').required('Min salary is required'),
    salaryMax: yup.number().typeError('Please enter valid number').min(yup.ref('salaryMin'), 'Max Salary must be same or greater than min salary').required('Max salary is required'),
  });

  const validationSchema2 = yup.object({
    jobFunctions: yup
      .array()
      .min(1, translate('DHIYO.FORM_VALIDATION_MSG.REQUIRED'))
  });
  const validationSchema3 = yup.object({
    hiringManager: yup.object().test('hiringManager', 'Please select Hiring Manager', val => val && val.email).required(),
    interviewerDetails: yup
      .array()
      .test('interviewerDetails', 'Please add interviewer', val => val && val.length > 0 && val.find(interviewer => interviewer && interviewer.email)).required('Please add interviewer')
  });

  useEffect(() => {
    if (!params || !params.id) {
      setFormData(getFormattedCompanyInfo(addJobPostingData));
    }
  }, [addJobPostingData]);

  const handleCheckedChange = (e) => {
    setIsAILoading(true);
    const { checked } = e.currentTarget;
    const payload = {
      Role: AIDescriptionReqObject.jobTitle,
      Seniority: seniority,
      Skills: AIDescriptionReqObject.preferredSkills
    };
    // setIsAILoading(true);
    if (checked) { 
      dispatch(onGetAIJobDescription(payload)).then((response) => {
        if (response && response.payload) {
          const { About_Job }= response.payload;
          const data = About_Job;
          setCheckDesciprion(checked);
          setFormData({ ...formData, jobDescription: data });
          setIsAILoading(false);
        } else {
          setCheckDesciprion(!checked);
          setIsAILoading(false);
        }
      }).catch(() => {
        setIsAILoading(false);
        setCheckDesciprion(!checked);
      });
    } else {
      setCheckDesciprion(checked);
      setIsAILoading(false);
      setFormData(getFormattedCompanyInfo(addJobPostingData));
    }

  };
  const currencyData = [{
    name: 'INR',
    id: 'INR',
    select: true
  }, {
    name: 'USD',
    id: 'USD'
  }, {
    name: 'EUR',
    id: 'EUR'
  }];
  const GetAllJobTitle = () => {
    setIsLoading(true);
    dispatch(onGetAllJobTitle({})).then((response) => {
      if (response && response.payload) {
        const jobTitles = response.payload.map((job) => {
          return {
            ...job,
            name: job.title
          };
        });
        setIsLoading(false);
        setJobTitleList(jobTitles);
      }
    });
  };

  const GetAllJobLocation = () => {
    setIsLoading(true);
    dispatch(onGetAllJobLocation({})).then((response) => {
      if (response && response.payload) {
        const list = response.payload.map((location) => {
          return { ...location, name: `${location.city}, ${location.state}` };
        });
        setJobLocationList(list);
      }
      setIsLoading(false);
    });
  };

  const getJobDetails = async () => {
    if (params && params.id) {
      setIsLoading(true);
      const response = await dispatch(onGetJobDetails(params));
      if (response && response.payload) {
        const { payload: interviewerResponse } = await dispatch(onGetJobEmployeeParticipant(params));
        const details = { ...response.payload };
        details.timeZone = details.timeZone || '+05:30';
        details.workTimingsFrom = details.workTimingsFrom || '09:00';
        details.workTimingsTo = details.workTimingsTo || '18:00';
        const interviewers = get(interviewerResponse, 'data.content', []).filter(participant => participant.employeeType === 'INTERVIEWER');
        const hiringManager = get(interviewerResponse, 'data.content', []).find(participant => participant.employeeType === 'HIRING_MANAGER');
        details.interviewerDetails = interviewers.length ? interviewers : [{ id: Math.random(), name: '', email_id: '', phone: '' }];
        details.savedInterviewerDetails = interviewers;
        details.hiringManager = hiringManager;
        details.savedHiringManager = hiringManager;
        const { payload: staticQuestionResponse } = await dispatch(onGetJobStaticQuestion(params));
        const staticQuetions = get(staticQuestionResponse, 'data', []);
        details.staticQuestions = staticQuetions;
        details.savedStaticQuestions = staticQuetions;
        setOpenPositions(details.openPositions);
        setInterviewRounds(details.interviewRounds);
        setSeniority(details.seniorityLevel);
        setJobType(details.jobType);
        setFormData(details);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    GetAllJobTitle();
    GetAllJobLocation();
    getJobDetails();
  }, []);

  jobTitleList && jobTitleList.find((job) =>{
    titlesList.push(job.title);
  });

  jobLocationList && jobLocationList.find((location) =>{
    locationList.push({ id: location.id, name: `${location.ciy, location.state}` });
  });

  const onSave = async (values) => {
    try {
      await dispatch(onChangeJobPostStatus({ id: values.id, status: 'OPEN' }));
      toast.success('Job post created successfully');
      navigate('/employer/jobposting', { state: { role:'employer' } });
    } catch (error) {
      // ToDo: Commmented for future use
    }
  };

  const onNext = async (values, stepNumber, setStepNumber, action = 'next') => {
    if (stepNumber === 4 && action === 'back') {
      return setStepNumber(stepNumber - 1);
    }
    setNextClicked(true);
    setJobDescRequired(false);
    if (!values.jobDescription) {
      window.scrollTo(0, 0);
      toast.error('Job description required');
      return setJobDescRequired(true);
    }
    if (!openPositions || !interviewRounds || !seniority || !jobType) {
      toast.error('Some rquired fields are not entered. Please check to continue');
      window.scrollTo(0, 0);
      return;
    }
    let callback = formData.id ? onUpdateJobPost : onAddJobPost;
    let payload = {
      id: formData.id,
      jobLocation: values.jobLocation,
      jobTitle: values.jobTitle,
      jobDescByAi: checkDesciprion,
      jobStatus: 'DRAFT',
      jobDescription: values.jobDescription,
      qualification: values.qualification,
      jobCode: values.jobCode,
      qualificationSpecific: values.qualificationSpecific,
      experienceMax: values.experienceMax,
      experienceMin: values.experienceMin,
      seniorityLevel: seniority,
      jobType: jobType,
      openPositions: openPositions,
      interviewRounds: interviewRounds,
      keywords: values.keywords.map((value, i) => {
        const isSaved = values.savedKeywords.find(keyword => keyword.keyword === value.keyword);
        return {
          'id': isSaved ? value.id : null,
          'deleted': value.deleted || false,
          'sequenceNumber': value.sortOrder || i,
          'keyword': value.name,
          'jobDetailId': formData.id || null,
        };
      }),
      preferredSkills: values.preferredSkills.map((value, i) => {
        const isSaved = values.savedPreferredSkills.find(skill => skill.skill === value.skill);
        return {
          'id': isSaved ? value.id : null,
          'deleted': value.deleted || false,
          'sequenceNumber': value.sortOrder || i,
          'skill': value.name,
          'jobDetailId': formData.id || null,
          'skillType': 'PREFERRED',
        };
      }),
      requiredSkills: values.requiredSkills.map((value, i) => {
        const isSaved = values.savedRequiredSkills.find(skill => skill.skill === value.skill);
        return {
          'id': isSaved ? value.id : null,
          'deleted': value.deleted || false,
          'sequenceNumber': value.sortOrder || i,
          'skill': value.name,
          'jobDetailId': formData.id || null,
          'skillType': 'REQUIRED',
        };
      }),
      currency: values.currency,
      salaryMin: Number(values.salaryMin || 0),
      salaryMax: Number(values.salaryMax || 0)
    };
    if (stepNumber > 0 || formData.id) {
      if (stepNumber === 1 && !values.rolesAndResponsibilities && action === 'next') {
        return toast.warning('Roles & Responsiblities required');
      }
      if (stepNumber === 1 && !values.impactOfPosition && action === 'next') {
        return toast.warning('Impact Of Position required');
      }
      callback = onUpdateJobPost;
      payload = {
        ...payload,
        id: formData.id,
        workTimingsFrom: values.workTimingsFrom,
        workTimingsTo: values.workTimingsTo,
        timeZone: values.timeZone,
        rolesAndResponsibilities: values.rolesAndResponsibilities,
        impactOfPosition: values.impactOfPosition,
        jobFunctions: values.jobFunctions.map((value) => {
          const isSaved = values.savedJobFunctions.find(jobFunction => jobFunction.jobFunction === value.jobFunction);
          return {
            'id': isSaved ? value.id : null,
            'deleted': value.deleted || false,
            'sequenceNumber': 0,
            'jobFunction': value.name,
            'jobDetailId': formData.id || null,
          };
        })
      };
    }
    if (stepNumber > 1) {
      callback = onSetJobEmployeeParticipant;
      const interviewerDetails = values.interviewerDetails.filter(detail => detail && detail.email);
      payload = interviewerDetails.map((value) => {
        const isSaved = values.savedInterviewerDetails.find(interviewer => interviewer.name === value.name);
        return {
          ...value,
          id: isSaved ? value.id : null,
          jobDetailId: formData.id,
          employeeType: 'INTERVIEWER',
          participantType: 'EMPLOYER',
          tenant: null,
          employeeId: value.id
        };
      });
      if (values.hiringManager) {
        const isSaved = values.savedHiringManager && values.savedHiringManager.name === values.hiringManager.name;
        payload.push({
          ...values.hiringManager,
          id: isSaved ? values.hiringManager.id : null,
          jobDetailId: formData.id,
          employeeType: 'HIRING_MANAGER',
          participantType: 'EMPLOYER',
          employeeId: values.hiringManager.id
        });
      }
    }
    if (stepNumber > 2) {
      callback = onSetJobStaticQuestion;
      const questions = addStaticQuestionsData.filter(question => question.question)
        .map((filteredQuestion) => {
          const isSaved = !filteredQuestion.id.startsWith('custom_') && values.staticQuestions.find(savedQuestion => savedQuestion.id === filteredQuestion.id);
          return {
            ...filteredQuestion,
            id: isSaved ? isSaved.id : null,
            jobId: formData.id,
            deleted: !filteredQuestion.isSelected
          };
        });
      payload = questions;
    }
    try {
      const resp = await dispatch(callback(payload));
      if (resp.payload) {
        if (action === 'back') {
          setStepNumber(stepNumber - 1);
        } else {
          setStepNumber(stepNumber + 1);
        }
      }
    } catch (error) {
      setIsLoading(false);
      // ToDo: Commmented for future use
    }
  };

  return (
    <Grid container direction="column" className={classes.mainContainer} spacing={1}>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="secondary"
            to="/employer/jobposting"
            className={'MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary'}>
            {translate('DHIYO.JOBPOSTING_ADD.ROOT_LINK')}
          </Link>
          <Typography className={classes.label}>{translate('DHIYO.JOBPOSTING_ADD.SUB_LINK')}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item container direction="column" style={{ marginTop: '2rem', marginBottom: '1rem' }}>
        <Grid>
          <Typography className={classes.title}>Preview</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">Check the details before posting</Typography>
        </Grid>
      </Grid>
      {isLoading && <CircularProgress color="secondary" className={classes.circularProgress} />}

      {!isLoading && <MultiStepForm
        initialValues={formData}
        onNext={(values, stepNumber, setStepNumber) => {
          setSteps({ stepNumber, setStepNumber });
          onNext(values, stepNumber, setStepNumber);
        }}
        onBack={(values, stepNumber, setStepNumber) => {
          setSteps({ stepNumber, setStepNumber });
          onNext(values, stepNumber, setStepNumber, 'back');
        }}
        onSubmit={(values) => {
          onSave(values);
        }}
        formikRef={formikRef}
        // width={'500px'}
        // float={'right'}
      >
        <FormStep stepName="Step 01" validationSchema={validationSchema}>

          <Grid container className={classes.formContainer}>
            <Grid item xs={12}>
              <Typography variant="h5">{translate('DHIYO.JOBPOSTING_ADD.FORM-01.HEADER.BASIC')}</Typography>
              <Typography variant="subtitle1">
                {translate('DHIYO.JOBPOSTING_ADD.FORM-01.HEADER.BASIC_DETAILS')}
              </Typography>
            </Grid>
            <Grid item container spacing={2} style={{ marginTop: '1rem' }}>
              <Grid item xs={12} md={4} >
                {jobTitleList && jobTitleList.length > 0 && 
                  <>
                    <div className={classes.jobTitileSection}>
                      <InputLabel required>
                        Job Title
                      </InputLabel>
                      <img src={Tootip} />
                    </div>
                    <FormikSelectField
                      dropDownData={jobTitleList}
                      handleAIDescriptionReqObject={handleAIDescriptionReqObject}
                      AIDescriptionReqObject={AIDescriptionReqObject}
                      isAIDescriptionActionRequired={true}
                      localePrefix="DHIYO.JOBPOSTING_ADD.JOB_POSTING_FORM.JOBTITLE"
                      id="jobTitle"
                      name="jobTitle" />
                  </>}
              </Grid>
              <Grid item xs={12} md={4}>
                {jobLocationList && jobLocationList.length > 0 && 
                <>
                  <InputLabel required>
                    Job Location
                  </InputLabel>
                  <FormikSelectField
                    dropDownData={jobLocationList}
                    handleAIDescriptionReqObject={handleAIDescriptionReqObject}
                    AIDescriptionReqObject={AIDescriptionReqObject}
                    isAIDescriptionActionRequired={true}
                    localePrefix="DHIYO.JOBPOSTING_ADD.JOB_POSTING_FORM.JOBLOCATION"
                    id="jobLocation"
                    name="jobLocation" />
                </>}
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel required>Job Code</InputLabel>
                <FormControl xs={12} md={4}>
                  <FormikInputField
                    label="Job Code"
                    id="jobCode"
                    name="jobCode"
                    maxLength={50}
                    disabled={formData.jobCode && params && params.id} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel required>Skill keywords</InputLabel>
                <FormikChipSelector
                  apiKey={lovKeys.jobKeywords}
                  handleAIDescriptionReqObject={handleAIDescriptionReqObject}
                  AIDescriptionReqObject={AIDescriptionReqObject}
                  isAIDescriptionActionRequired={true}
                  localePrefix="DHIYO.JOBPOSTING_ADD.JOB_POSTING_FORM.KEYWORDS"
                  maxAllowedSelection={5}
                  name="keywords" />
              </Grid>
              <Grid item xs={6} md={2}>
                <InputLabel className={classes.inputLabel} required>Open Positions</InputLabel>
                <div className={classes.counts}>
                  <img
                    src={MinusIcon}
                    alt="open-positions"
                    onClick={() => {
                      if (openPositions) {
                        setOpenPositions(openPositions - 1);
                      }
                    }}
                  />
                  <TextField className={classes.positionCount} InputProps={{
                    classes: {
                      disabled: classes.disabled
                    }
                  }} size="small" variant="outlined" name="openPositions" type="text" value={openPositions} disabled />
                  <img
                    src={PlusIcon}
                    onClick={() => {
                      setOpenPositions(openPositions + 1);
                    }}
                  />
                </div>
                {nextClicked && openPositions === 0 && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="mui-29185-helper-text">Field is mandatory</p>}
              </Grid>
              <Grid item xs={6} md={2}>
                <InputLabel className={classes.inputLabel} required>Interview Rounds</InputLabel>
                <div className={classes.counts}>
                  <img
                    src={MinusIcon}
                    alt="plus-icon"
                    onClick={() => {
                      if (interviewRounds > 0) {
                        setInterviewRounds(interviewRounds - 1);
                      }
                    }}
                  />
                  <TextField className={classes.positionCount} InputProps={{
                    classes: {
                      disabled: classes.disabled
                    }
                  }} size="small" variant="outlined" name="interviewRounds" type="text" value={interviewRounds} disabled />
                  <img
                    src={PlusIcon}
                    onClick={() => {
                      setInterviewRounds(interviewRounds + 1);
                    }}
                  />
                </div>
                {nextClicked && interviewRounds === 0 && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="mui-29185-helper-text">Field is mandatory</p>}
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel} required>Seniority Level</InputLabel>
                <div className={classes.chipSelect}>
                  {experienceList.length &&
                    experienceList.map(experience => (
                      <CustomRadioButton
                        id={experience.id}
                        name="Seniority"
                        labelText={experience.label}
                        checked={seniority === experience.id}
                        onChange={() => {
                          setSeniority(experience.id);
                        }}
                        classnames={classes.experienceSelectBtn}
                      />
                    ))}
                </div>
                {nextClicked && !seniority && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="mui-29185-helper-text">Field is mandatory</p>}
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel} required>Job Type</InputLabel>
                <div className={classes.chipSelect}>
                  {jopTypeList.length &&
                    jopTypeList.map(job => (
                      <CustomRadioButton
                        id={job.id}
                        labelText={job.label}
                        checked={jobType === job.id}
                        onChange={() => {
                          setJobType(job.id);
                        }}
                        classnames={classes.experienceSelectBtn}
                      />
                    ))}
                </div>
                {nextClicked && !jobType && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="mui-29185-helper-text">Field is mandatory</p>}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{translate('DHIYO.JOBPOSTING_ADD.FORM-01.HEADER2.BASIC')}</Typography>
                <Typography variant="subtitle1" className={classes.inputLabel}>
                  {translate('DHIYO.JOBPOSTING_ADD.FORM-01.HEADER2.BASIC_DETAILS')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <input type="checkbox" id="checkDesc" name="jobDescByAi" checked={checkDesciprion} style={{ alignSelf: 'center', cursor: 'pointer' }} disabled={isAILoading} onChange={handleCheckedChange} />
                <Typography variant="h6" htmlFor="checkDesc" style={{ marginLeft: '10px' }}>Do you want AI to write job description for you?</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} md={8} style={{ marginTop: '10px' }}>
                  <div className={classes.jobTitileSection}>
                    <InputLabel required>
                      Job Description
                    </InputLabel>
                    <img src={Tootip} />
                  </div>
                  { 
                    isAILoading ? <CircularProgress color="secondary" className={classes.circularProgress} />
                      :
                      <FormikTextField multiline rows={8} placeholder="Write a short Job Description" 
                        maxRows={10} variant="outlined" name="jobDescription" id="jobDescription" />
                   
                  }

                </Grid>
                {nextClicked && jobDescRequired && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="mui-29185-helper-text">Field is mandatory</p>}
              </Grid>
              <Grid item xs={12}>
                <InputLabel required>Required Skills</InputLabel>
                <FormikChipSelector apiKey={lovKeys.jobKeywords} localePrefix="DHIYO.JOBPOSTING_ADD.JOB_POSTING_FORM.REQUIRED_SKILLS" name="requiredSkills" maxAllowedSelection={5} />
              </Grid>
              <Grid item xs={12}>
                <InputLabel required>Preferred Skills</InputLabel>
                <FormikChipSelector apiKey={lovKeys.jobKeywords} localePrefix="DHIYO.JOBPOSTING_ADD.JOB_POSTING_FORM.PREFERRED_SKILLS" name="preferredSkills" maxAllowedSelection={5} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{translate('DHIYO.JOBPOSTING_ADD.FORM-01.HEADER3.BASIC')}</Typography>
                <Typography variant="subtitle1">
                  {translate('DHIYO.JOBPOSTING_ADD.FORM-01.HEADER3.BASIC_DETAILS')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel required>Qualification</InputLabel>
                <FormikSelectField
                  apiKey={lovKeys.qualification}
                  localePrefix="DHIYO.JOBPOSTING_ADD.JOB_POSTING_FORM.QUALIFICATION"
                  displayKey="name"
                  name="qualification" />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>Any Specific Qualification</InputLabel>
                {/* <FormControl fullWidth>
                    <TextField className={classes.fieldWidth} variant="outlined" name="openPositions" type="text" placeholder="Ex. Diploma in Fine Arts" />
                  </FormControl> */}
                <FormikInputField
                  className={classes.fieldWidth}
                  localePrefix="DHIYO.JOBPOSTING_ADD.JOB_POSTING_FORM.ANY_SPECIFICATION"
                  id="qualificationSpecific"
                  name="qualificationSpecific"
                  key={'qualificationSpecific'}
                  minLength={0}
                  maxLength={50} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{translate('DHIYO.JOBPOSTING_ADD.FORM-01.HEADER4.BASIC')}</Typography>
                <Typography variant="subtitle1">
                  {translate('DHIYO.JOBPOSTING_ADD.FORM-01.HEADER3.BASIC_DETAILS')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputLabel required style={{ textTransform: 'none' }}>Experience (in years)</InputLabel>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <FormControl>
                    <FormikInputField
                      label="Minimum"
                      id="experienceMin"
                      name="experienceMin"
                      maxLength={50} />
                  </FormControl>

                  <FormControl>
                    <FormikInputField
                      label="Maximum"
                      id="experienceMax"
                      name="experienceMax"
                      maxLength={50} />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <InputLabel required>Annual Salary</InputLabel>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <FormControl>
                    <FormikSelectField
                      dropDownData={currencyData}
                      label="Currency"
                      id="currency"
                      name="currency" />
                  </FormControl>
                  <FormControl>
                    <FormikInputField
                      label="Minimum"
                      id="salaryMin"
                      name="salaryMin"
                      maxLength={50} />
                  </FormControl>
                  <FormControl>
                    <FormikInputField
                      label="Maximum"
                      id="salaryMax"
                      name="salaryMax"
                      maxLength={50} />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </FormStep>
        <FormStep stepName="Step 02" validationSchema={validationSchema2}>
          <CompanyInformation />
        </FormStep>
        <FormStep stepName="Step 03" validationSchema={validationSchema3}>
          <InterviewDetails />
        </FormStep>
        <FormStep stepName="Step 04">
          <StaticQuestion jobId={formData.id} />
        </FormStep>
        <FormStep stepName="Step 05" isPreview={true}>
          <PreviewJobPosting onNext={onNext} {...steps} />
        </FormStep>
      </MultiStepForm>}
    </Grid>
  );
}
