import { CardContent, Grid, Typography, Box, makeStyles, Divider } from '@material-ui/core';
import React from 'react';
import { formatEmptyValues, getFormattedAddress } from 'utils/valueFormatter';
import JobDetails from './JobDetails';

const useStyle = makeStyles(() => ({
  type: {
    color: '#797D89',
    fontWeight: 500,
  },
}));

const PersonalDetailsView = ({ formData }) => {
  const classes = useStyle();
  return (
    <Box
      fullWidth >
      <CardContent style={{ padding: '25px 30px 15px 30px' }}>
        <Box sx={{ marginBottom: '1rem' }}>
          <Typography><b>
            {`${formatEmptyValues(formData, 'firstName', '')} ${formatEmptyValues(formData, 'lastName', '')}`}
          </b></Typography>
          <Typography>{getFormattedAddress(formData)}</Typography>
        </Box>
        <Grid container spacing={0} style={{ marginBottom: '1rem' }}>
          <Grid item xs={6} md={4} align={'left'} style={{ marginBottom: '1rem' }}>
            <Typography className={classes.type}>Experience<br /></Typography>
            <Typography>{formData.experienceCount} {formData.experiencePeriod}</Typography>
          </Grid>
          <Grid item xs={6} md={4} style={{ marginBottom: '1rem' }}>
            <Typography className={classes.type}>Mobile Number</Typography>
            <Typography>{formatEmptyValues(formData, 'contactNumber', '-')}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.type}>Email ID</Typography>
            <Typography>{formatEmptyValues(formData, 'email', '-')}</Typography>
          </Grid>
        </Grid>
        <Divider />
        <JobDetails formData={formData} />
      </CardContent>
    </Box>
  );
};

export default PersonalDetailsView;