import React, { useState } from 'react';
import { InputLabel, Grid, Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import RoundOneIcon from 'asset/icons/round1.svg';
import RoundTwoIcon from 'asset/icons/round2.svg';

import { FormikTextField } from '../../../../ui-components';
const useStyles = makeStyles(theme => ({
  formContainer: {
    backgroundColor: 'white',
    padding: '2em',
    borderRadius: 4,
  },
  fieldWidth: {
    width: '320px',
    height: '44px',
  },
  disabled: {
    '&$disabled': {
      textAlign: 'center',
    },
  },
  counts: {
    display: 'flex',
    gap: '10px',
    cursor: 'pointer',
  },
  jobTitleSection: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  inputLabel: {
    color: '#000',
    marginBottom: '0.5rem',
  },
  infoContainer: {
    paddingTop: '1em',
    paddingLeft: '1em',
    paddingBottom: '1em',
    width: '68%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '1em',
      paddingLeft: '1em',
      paddingBottom: '1em',
    },
  },
  enabledForm: {
    opacity: '1',
  },
  disabledForm: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  button: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    width: 'max-content'
  },
  skipComment: {
    display: 'inline-flex'
  }
}));

const CandidateInfo = ({ enableForm, setEnableForm, candidateInformation }) => {
  const classes = useStyles();
  const [showSkipCommentBox, setShowSkipCommentBox] = useState(false);
  const { name, profile, experience, salary, interviewerName } = candidateInformation;
  const enableFormFlag = () => {
    setEnableForm(!enableForm);
    setShowSkipCommentBox(false);
  };

  const showCommentDialog = () => {
    setShowSkipCommentBox(!showSkipCommentBox);
  };

  const submitSkipReason = () => {
    setShowSkipCommentBox(false);
    setEnableForm(true);
    //  TODO: apicall to submit skip reason
  };

  return <>
    {candidateInformation ? <Grid container>
      <Grid item xs={12} md={12}>
        <Typography variant="body2">Candidate Information({name})</Typography>
      </Grid>

      <Box className={classes.infoContainer} style={{ backgroundColor: '#ebebf5', marginBottom: '1rem' }} >

        <Grid item xs={12} md={12} style={{ display: 'flex', gap: '16px' }}>
          <Grid item xs={4} md={4}>
            <Typography variant="subtitle1" className={classes.font}>
              Profile
              <Typography variant="h6" className={classes.font}>
                {profile}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography variant="subtitle1" className={classes.font}>
              Experience
              <Typography variant="h6" className={classes.font}>
                {experience}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography variant="subtitle1" className={classes.font}>
              Expected Salary
              <Typography variant="h6" className={classes.font}>
                {salary}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          {/** TODO : Dynamically generate list based on round count */}
          <Typography variant="subtitle1" className={classes.font}>
            Interview Details
            <Grid>
              <Grid>
                <span style={{ display: 'flex', gap: '16px' }}><img src={RoundOneIcon} className={classes.roundLabel} /> - Done</span>
              </Grid>
            </Grid>
            <Grid>
              <Typography variant="h6" className={classes.font}>
                Interviewer - {interviewerName}
              </Typography>
            </Grid>
            <Grid>
              <span style={{ display: 'flex', gap: '16px' }}><img src={RoundTwoIcon} className={classes.roundLabel} /> -
                <Button size="small"
                  className={classes.button} color="secondary" onClick={enableFormFlag} > Schedule</Button>
                <Button size="small"
                  className={classes.button} color="secondary" onClick={showCommentDialog}> Skip</Button>
              </span>
            </Grid>
            <Grid>
              <Typography variant="h6" className={classes.font}>
                Upcoming Interview
              </Typography>
            </Grid>
            {showSkipCommentBox &&
              <Grid>
                <Typography variant="h6" className={classes.font}>
                  <Grid item xs={12} md={12} style={{ marginTop: '10px', padding: '0px 1rem 1rem 0px' }}>
                    <div className={classes.jobTitleSection}>
                      <InputLabel required>
                        <Typography variant="h6" className={classes.skipComment} style={{ fontSize: '13px' }}> Please provide a reason for skipping Round 3</Typography>
                      </InputLabel>
                    </div>
                    <FormikTextField multiline rows={2} placeholder="Enter your text here"
                      maxRows={3} variant="outlined" name="jobDescription" id="jobDescription" />
                  </Grid>
                </Typography>
                <Button
                  variant="contained"
                  onClick={submitSkipReason}
                  color="secondary">
                  Submit
                </Button>
              </Grid>
            }
          </Typography>
        </Grid>
      </Box>
    </Grid> : null}
  </>;
};
export default CandidateInfo;