const { endPoints } = require('constants/employerEndpoints');
const { callApi } = require('middleware/api');

export const addInterviewQuestion = (data) => {
  return callApi(data, 'POST', endPoints.configuration.interviewQuestions, true);
};

export const getInterviewQuestionList = () => {
  return callApi(null, 'GET', `${endPoints.configuration.interviewQuestions}/all`, true);
};

export const editInterviewQuestion = (data) => {
  return callApi(data, 'PUT', endPoints.configuration.interviewQuestions, true);
};

export const deleteInterviewQuestion = (id) => {
  return callApi(null, 'DELETE', `${endPoints.configuration.interviewQuestions}/${id}`, true);
};