import { isEmpty, map } from 'lodash';
import moment from 'moment';
import { capitalizeFirstLetter } from 'view/common/utils/stringUtils';

const formatLocation = (data) => {
  const { city = '', state = '' } = data;
  return `${!isEmpty(city) ? capitalizeFirstLetter(city) : ''}${!isEmpty(state) ? ',' : ''}${!isEmpty(state) ? capitalizeFirstLetter(state) : ''}`;
};

export const formatCandidateData = (data) => {
  return map(data, (item) => {
    return {
      ...item,
      location: formatLocation(item.applicant)
    };
  });
};

export const getInterviewTime = (time) => {
  return moment(time).format('h:mm A');
};

export const getInterviewDate = (time) => {
  return moment(time, ['HH:mm']).format('ll');
};