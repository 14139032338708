/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { onAddCompanyLocation, onDeleteCompanyLocation, onEditCompanyLocation, onGetLocationsList } from 'redux/actions/employerActions/Configurations/companyLocationsAction';

const initialState = {
  posts: [],
  status: 'idle',
  error: null,
};

const addCompanyLocation = createSlice({
  name: 'addCompanyLocation',
  initialState,
  reducers: {},
  extraReducers: {
    // add location
    [onAddCompanyLocation.pending]: (state) => {
      state.status = 'loading';
    },
    [onAddCompanyLocation.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onAddCompanyLocation.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    // list all locations
    [onGetLocationsList.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetLocationsList.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onGetLocationsList.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    // edit location
    [onEditCompanyLocation.pending]: (state) => {
      state.status = 'loading';
    },
    [onEditCompanyLocation.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onEditCompanyLocation.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    // delete location
    [onDeleteCompanyLocation.pending]: (state) => {
      state.status = 'loading';
    },
    [onDeleteCompanyLocation.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onDeleteCompanyLocation.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default addCompanyLocation.reducer;
