import React from 'react';
import { Box } from '@material-ui/core';
import UserProfile from './UserProfile';
import UserPostsTable from './UserPostsTable';

const UserProfileview = ({ userType, userDetails, userData }) => {
  return (
    <Box>
      <UserProfile 
        userDetails={userDetails} 
        userType={userType} 
        userData={userData} 
      />
      <UserPostsTable userType={userType} userData={userData} />
    </Box>
  );
};

export default UserProfileview;