const { endPoints } = require('constants/employerEndpoints');
const { callApi } = require('middleware/api');

export const addLocation = (data) => {
  return callApi(data, 'POST', endPoints.configuration.companyLocation, true);
};

export const getLocationsList = (data) => {
  return callApi(null, 'GET', `${endPoints.configuration.companyLocation}/readByPage?pageSize=${data.pageSize}&pageNo=${data.pageNo}`, true);
};

export const editLocation = (data) => {
  return callApi(data, 'PUT', endPoints.configuration.companyLocation, true);
};

export const deleteLocation = (id) => {
  return callApi(null, 'DELETE', `${endPoints.configuration.companyLocation}/${id}`, true);
};