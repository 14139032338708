import { Box, Grid, IconButton, InputLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import React from 'react';
import EditIcon from 'asset/icons/EditIcon.svg';
import DeleteIcon from 'asset/icons/DeleteIcon.svg';
import DPQuestionRating from '../DPQuestionRating';

const useStyle = makeStyles(() => ({
  box: {
    padding: '1.5rem'
  }
}));

export default function DPInterviewQuestion({ question, questionNo, openInPopup, setConfirmDialog, onDelete, disabled }) {
  const classes = useStyle();
  return (
    <Box className={classes.box}>
      <Grid container spacing={0}>
        <Grid item xs={12} align={'left'} style={{ marginBottom: '0.3rem' }}>
          <Typography variant="h6">
            {`Question ${questionNo}`}
            <Box style={{ display: 'initial', marginLeft: '1rem' }}>
              <IconButton color="secondary" aria-label="Edit Data" onClick={() => openInPopup(question.id)}>
                <img src={EditIcon} />
              </IconButton>
              <IconButton
                style={{ padding: 0 }}
                color="secondary"
                aria-label="Delete Data"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: 'Are you sure to delete this record?',
                    subTitle: 'You can\'t undo this operation',
                    onConfirm: () => {
                      onDelete(question.id);
                    }
                  });
                }}>
                <img src={DeleteIcon} />
              </IconButton>
            </Box>
          </Typography>
          <Typography variant="subtitle2">{question.question}</Typography>
          <Box style={{ marginTop: '0.5rem' }}>{question.isRatingApplicable && <DPQuestionRating disabled />}</Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>Comments</InputLabel>
          <TextField fullWidth multiline={4} variant="outlined" placeholder="Enter your comments here" value="" disabled={disabled} />
        </Grid>
      </Grid>
    </Box>
  );
}
