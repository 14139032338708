import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Link,
  Typography,
  Button,
  Grid,
  Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import DPBarChart from 'view/common/DPBarChart';
import { get } from 'lodash';
import { useEffect } from 'react';
import { onProfileStats } from 'redux/actions/applicantDashboard';

const useStyle = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0.5rem'
  },
  cardContent: {
    padding: '0 1rem'
  },
  card: {
    marginTop: '15px',
    border: '1px solid #E4E4E4',
    background: '#fafafa',
    borderRadius: 4,
  },
  link: {
    fontSize: '12px',
  },
  barTitle: {
    fontSize: '12px',
    textAlign: 'end',
    marginRight: '7px'
  },
  viewBtn: {
    padding: 0,
    fontWeight: 300
  },
  company: {
    fontWeight: 300,
    lineHeight: '22px'
  },
  smIconBtn: {
    display: 'flex',
    padding: 0,
    marginRight: '1rem'
  }
}));

const profileStatsData = [
  {
    title: 'LeaderShip',
    percent: '40'
  },
  {
    title: 'Technology',
    percent: '35'
  },
  {
    title: 'Social',
    percent: '32'
  }
];

const topCompany = [
  {
    title: 'Google',
    percent: '80'
  },
  {
    title: 'Accenture',
    percent: '65'
  },
  {
    title: 'Pinterest',
    percent: '52'
  },
  {
    title: 'Twitter',
    percent: '42'
  }
];

const keySkill = [
  {
    title: 'Designing',
    percent: '80'
  },
  {
    title: 'Wireframing',
    percent: '65'
  },
  {
    title: 'Prototyping',
    percent: '52'
  },
  {
    title: 'Iconography',
    percent: '42'
  }
];

const mentorList = [
  {
    name: 'Donald Hanley',
    course: '2 Design Courses'
  },
  {
    name: 'Anna Mull',
    course: '5 Design Courses'
  },
  {
    name: 'Petey Cruiser',
    course: '3 Design Courses'
  },
];

const ProfileStats = ({
  sectionDataPath
}) => {
  // eslint-disable-next-line no-unused-vars
  const sectionData = useSelector(state => pathOr([], sectionDataPath, state));
  const dispatch = useDispatch();
  const profileStats = useSelector(state => get(state, ['applicantDashboard', 'profileStats'], ''));
  console.log('profileStats', profileStats);
  const classes = useStyle();

  useEffect(() => {
    dispatch(onProfileStats());
  }, []);
  
  return (
    <> 
      <DPBarChart chartData={[{ data : [...profileStatsData], title: 'Profile Stats', subTitle: 'Define your area of improvement' }]} />
      <DPBarChart chartData={[{ data: [...topCompany ], title: 'Top Companies hiring similar Profile' }, { data: [...keySkill], title: 'Key Skills' }]} />
      <div style={{ marginTop: '1rem' }}>
        <Grid container spacing={0} style={{ marginBottom: '1rem' }}>
          <Grid item xs={8}>
            <Typography variant="h6">Suggested Mentors</Typography>
          </Grid>

          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <Link variant="text" style={{ color: '#24283C' }}>
              See All
            </Link>
          </Grid>
        </Grid>

        <Grid container spacing={0} xs={12}>
          <Grid item xs={12}>
            {mentorList.map(mentor => (
              <Card elevation={0}>
                <CardContent className={classes.cardContent}>
                  <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={9} style={{ display: 'flex' }}>
                      <Avatar className={classes.smIconBtn} />
                      <Typography variant="h6">
                        {mentor.name}
                        <Typography
                          variant="subtitle1"
                          className={classes.company}
                        >
                          {mentor.course}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'end' }}>
                      <Button variant="outlined"
                        color="secondary"
                        className={classes.viewBtn}
                      >
                        view
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))
            }

          </Grid>
        </Grid>
      </div>

    </>
  );
};
export default ProfileStats;