import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { Divider, makeStyles, Typography, InputLabel, FormControl, Input } from '@material-ui/core';
import { colors } from '../../../../themes/colors';
import { useDispatch } from 'react-redux';
import { onForgotPassword } from 'redux/actions/userAuthentication';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
  newPassword: Yup.string().min(6).max(15).required('Required'),
  confirmPassword: Yup.string().min(6).max(15).required('Required'),
});
const useStyle = makeStyles(theme => ({
  loginBtn: {
    backgroundColor: colors.blue2,
    '&:hover': {
      backgroundColor: colors.blue1,
    },
    color: colors.white,
    fontSize: 16,
    fontWeight: 200,
    padding: '14px 16px',
    lineHeight: 1.5,
  },
  formContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  topSection: {
    marginBottom: '1rem'
  },    
  customInput: {
    marginBottom: '0.8rem',
    '& .MuiInputBase-input': {
      borderRadius: 6,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '20px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:focus': {
        boxShadow: '0px 4px 10px 3px rgba(0, 0, 0, 0.11)',
        borderColor: '#1565D8',
      },
    },
  },
  showHidePswd: {
    color: '#6361CD', position: 'absolute', right: '1rem',
  },
}));

const ForgotPasswordForm = ({ forgotPasswordEmail, forgotPasswordOTP, role }) => {

  const classes = useStyle();
  const dispatch = useDispatch();
  const [typeValues, setTypeValues] = useState({
    newPasswordVisibility: false,
    confirmPasswordVisibility: false
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      showPassword: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const reqObject = {
        'email': forgotPasswordEmail,
        'otp': forgotPasswordOTP,
        'password': values.newPassword,
        'event': 'FORGOT_PASSWORD'
      };
      if (values.newPassword !== values.confirmPassword){
        toast.error('Password mismatch');
        return;
      }
      dispatch(onForgotPassword({ reqObject, role })).then(async() => {
        window.location.href=`/${role}/login`;
        toast.info('Password changed successfully. Please login again.');
      });
    },
  });

  const handleClickNewPasswordVisibility = () => {
    setTypeValues({ ...typeValues, newPasswordVisibility: !typeValues.newPasswordVisibility });
  };

  const handleClickConfirmPasswordVisibility = () => {
    setTypeValues({ ...typeValues, confirmPasswordVisibility: !typeValues.confirmPasswordVisibility });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div className={classes.topSection}>
        <Typography variant="h4" className={classes.title}>Reset Password</Typography>
        <Typography variant="subtitle1">Set new password for your account</Typography>
      </div>
      <Divider />
      <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
        <InputLabel required htmlFor="outlined-adornment-password">
          New Password
        </InputLabel>
        <FormControl fullWidth variant="outlined" className={classes.customInput} >
          <Input
            required
            disableUnderline
            fullWidth
            id="newPassword"
            name="newPassword"
            placeholder="Enter password"
            type={typeValues.newPasswordVisibility ? 'text' : 'password'}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helpertext={formik.touched.newPassword && formik.errors.newPassword}
            endAdornment={
              <InputAdornment>
                <IconButton
                  className={classes.showHidePswd}
                  onClick={handleClickNewPasswordVisibility}
                  onMouseDown={handleMouseDownPassword}
                  aria-label="toggle password visibility"
                >
                  {typeValues.newPasswordVisibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <InputLabel required htmlFor="outlined-adornment-password">
          Confirm Password
        </InputLabel>
        <FormControl fullWidth variant="outlined" className={classes.customInput} >
          <Input
            required
            disableUnderline
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Enter password"
            type={typeValues.confirmPasswordVisibility ? 'text' : 'password'}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helpertext={formik.touched.confirmPassword && formik.errors.confirmPassword}
            endAdornment={
              <InputAdornment>
                <IconButton
                  className={classes.showHidePswd}
                  onClick={handleClickConfirmPasswordVisibility}
                  onMouseDown={handleMouseDownPassword}
                  aria-label="toggle password visibility"
                >
                  {typeValues.confirmPasswordVisibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Button color="primary" variant="contained" fullWidth type="submit" className={classes.loginBtn}>
          Reset Password
        </Button>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;