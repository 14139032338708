import React, { useState, useEffect } from 'react';
import { Icon, Grid, Button, Typography, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Popup, Notification, ConfirmDialog } from '../../../../ui-components';
import { onAddQuestion, onEditQuestion, onDeleteQuestion, onGetQuestionList } from 'redux/actions/employerActions/Configurations/staticActions';
import { onGetJobStaticQuestion } from 'redux/actions/employerActions/JobPosting/jobPostingActions';
import { setStaticQuestions } from 'redux/slices/employerSlices/addQuestion';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash-es';
import { makeStyles } from '@material-ui/core/styles';
import AddQuestionsForm from './AddQuestionsForm';
import QuestionList from './QuestionsList';
import { useParams } from 'react-router-dom';
import { get, now } from 'lodash';
import { onAddPrepQuestion, onDeletePrepQuestion, onGetPrepQuestion } from 'redux/actions/interviewerActions/interviewPrepQuestion';

const useStyles = makeStyles(theme => ({
  headerSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2rem'
  },
  title: {
    fontWeight: 500,
    fontSize: 18,
    color: '#24283C',
    marginLeft: '7px'
  },
  circularProgress: {
    margin: '0% auto',
    display: 'flex',
    zIndex: 1
  },
  addQstBtn: {
    textAlign: 'end',
    margin: '10px 0',
    [theme.breakpoints.down('md')]: {
      textAlign: 'initial',
      margin: '10px 10px'
    }
  }
}));

const StaticQuestions = ({ showContent, usedIn, jobID }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const interviewPrepDetails = useSelector(state => state.interviewManagement.interviewPrepDetails);
  const interviewID = interviewPrepDetails?.interviewDetail?.id;
  const [updateData, setUpdateData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const { t: translate } = useTranslation();
  const [updateQuestion, setUpdateQuestion] = useState('');
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: ''
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: ''
  });
  const enableConfirmDialog = (data) => {
    setConfirmDialog(data);
  };
  const params = useParams();

  const getJobStaticQuestions = async () => {
    if (((params && params.id) || jobID) && usedIn === 'JobPostPreview') {
      setIsLoading(true);
      const payload = {
        id: params && params.id ? params.id : jobID
      };
      const { payload: staticQuestionResponse } = await dispatch(onGetJobStaticQuestion(payload));
      const existingStaticQuetions = get(staticQuestionResponse, 'data', []);
      setIsLoading(false);
      return existingStaticQuetions.map((question) => {
        return {
          ...question,
          isSelected: true
        };
      });
    } else {
      return [];
    }
  };

  const GetQuestionsList = async () => {
    setIsLoading(true);
    switch (usedIn) {
    case 'interviewPrep':
      {
        const response = await dispatch(onGetPrepQuestion(interviewID));
        let updatedArray = [];
        if (response && response.payload?.prepQuestions) {
          response.payload?.prepQuestions?.map(({ id, questionTitle }) => {
            updatedArray.push({
              id,
              question: questionTitle
            });
          });
        }
        setQuestionList(updatedArray);
        setIsLoading(false);
      }
      break;

    default:
      {
        const existingList = await getJobStaticQuestions();
        setQuestionList(existingList);
        if (!existingList.length) {
          const response = await dispatch(onGetQuestionList({}));
          setQuestionList(response && response.payload);
        }
        setIsLoading(false);
      }
      break;
    }
  };

  useEffect(async () => {
    if (updateData && usedIn !== 'interviewPrep') {
      GetQuestionsList();
    }
    if (interviewPrepDetails?.interviewDetail?.id && updateData && usedIn === 'interviewPrep') {
      await setQuestionList([]);
      GetQuestionsList();
    }
  }, [updateData, interviewPrepDetails]);

  const openInPopup = (id) => {
    let filterQustion = filter(questionList, { id }, []);
    if (filterQustion.length > 0) {
      setUpdateQuestion(filterQustion[0]);
      setModal(true);
    }
  };

  const addQuestionorEditQuestion = async (questionDetails, resetForm) => {
    setUpdateData(false);
    if (!questionDetails.id) {
      // Add Question
      switch (usedIn) {
      case 'JobPostPreview':
        {
          questionDetails.id = `custom_${now()}`;
          questionDetails.isSelected = true;
          await setQuestionList([...questionList, questionDetails]);
          dispatch(setStaticQuestions(questionList));
          // setUpdateData(true);
          setModal(false);
          setNotify({
            isOpen: true,
            message: 'Question Added Successfully',
            type: 'success'
          });
        }
        break;

      case 'interviewPrep':
        {
          await dispatch(onAddPrepQuestion({ data: questionDetails, id: interviewID })).then((response) => {
            {
              response && response.id && resetForm();
              setUpdateData(true);
              setModal(false);
              setNotify({
                isOpen: true,
                message: 'Question Added Successfully',
                type: 'success'
              });
            }
          });
        }
        break;

      default: {
        await dispatch(onAddQuestion(questionDetails)).then((response) => {
          {
            response && response.id && resetForm();
            setUpdateData(true);
            setModal(false);
            setNotify({
              isOpen: true,
              message: 'Question Added Successfully',
              type: 'success'
            });
          }
        });
      }
      }
    } else {
      // update Question
      switch (usedIn) {
      case 'JobPostPreview':
        {
          const index = questionList.findIndex(que => que.id === questionDetails.id);
          const queList = [...questionList];
          queList[index] = questionDetails;
          await setQuestionList(queList);
          dispatch(setStaticQuestions(queList));
          setModal(false);
        }

        break;
      case 'interviewPrep':
        {
          await dispatch(onAddPrepQuestion({ data: questionDetails, id: interviewID })).then((response) => {
            {
              response && response.id && resetForm();
              setUpdateData(true);
              setModal(false);
              setNotify({
                isOpen: true,
                message: 'Question Added Successfully',
                type: 'success'
              });
            }
          });
        }
        break;
      default:
        {
          await dispatch(onEditQuestion(questionDetails)).then((response) => {
            {
              response && response.id && resetForm();
              setUpdateData(true);
              setModal(false);
              setNotify({
                isOpen: true,
                message: 'JobTitle Updated Successfully',
                type: 'success'
              });
            }
          });
        }
        break;
      }
    }
  };

  const onDelete = async (id) => {
    setUpdateData(false);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    if (usedIn === 'interviewPrep') {
      await dispatch(onDeletePrepQuestion({ interviewId: interviewID, questionId: id }));
    } else {
      await dispatch(onDeleteQuestion(id));
    }
    setUpdateData(true);
    setNotify({
      isOpen: true,
      message: 'Deleted Successfully',
      type: 'error'
    });
  };

  const initialValues = {
    id: updateQuestion ? updateQuestion.id : '',
    question: updateQuestion ? updateQuestion.question : '',
    mandatory: updateQuestion ? updateQuestion.mandatory : '',
    type: 'TEXT'
  };

  return (
    <>
      <Grid container direction="column" spacing={1}>
        {!showContent && (
          <Grid container className={classes.headerSection}>
            <Grid item xs={12} md={6}>
              <Typography className={classes.title}>Earlier added questions</Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.addQstBtn}>
              <Button
                fullWidth={false}
                variant="contained"
                color="secondary"
                startIcon={<Icon>add_circle</Icon>}
                onClick={() => {
                  setModal(true);
                  setUpdateQuestion('');
                }}>
                {translate('DHIYO.CONFIGURATION.STATIC_QUESTIONS.ADD_FORM.FORM_NAME')}
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item style={{ marginTop: '1em' }} xs={12}>
          {isLoading && <CircularProgress color="secondary" className={classes.circularProgress} />}
          <QuestionList 
            usedIn={usedIn} 
            questionList={questionList} 
            onDelete={onDelete} 
            openInPopup={openInPopup} 
            setConfirmDialog={enableConfirmDialog} 
            confirmDialog={confirmDialog} 
            isLoading={isLoading} 
            showContent={showContent} />
        </Grid>
        {showContent && (
          <Grid className={classes.headerSection}>
            <Button
              fullWidth={false}
              variant="contained"
              color="secondary"
              startIcon={<Icon>add_circle</Icon>}
              onClick={() => {
                setModal(true);
                setUpdateQuestion('');
              }}>
              {translate('DHIYO.CONFIGURATION.STATIC_QUESTIONS.ADD_FORM.FORM_NAME')}
            </Button>
          </Grid>
        )}
      </Grid>

      <Popup 
        title={updateQuestion ? 'Update Question' : translate('DHIYO.CONFIGURATION.STATIC_QUESTIONS.ADD_FORM.FORM_NAME')} 
        openPopup={modal} 
        setOpenPopup={setModal}>
        <AddQuestionsForm 
          updateQuestion={updateQuestion} 
          initialValues={initialValues} 
          onCancel={() => setModal(false)} 
          addQuestionorEditQuestion={addQuestionorEditQuestion} 
          usedIn={usedIn} />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default StaticQuestions;