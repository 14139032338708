import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Divider, IconButton, InputLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  formContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  topSection: {
    marginBottom: '1rem'
  },    
  customInput: {
    marginBottom: '0.8rem !important',
    '& .MuiInputBase-input': {
      borderRadius: 6,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '20px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:focus': {
        boxShadow: '0px 4px 10px 3px rgba(0, 0, 0, 0.11)',
        borderColor: '#1565D8',
      },
    },
  },
  showHidePswd: {
    color: '#6361CD', position: 'absolute', right: '1rem',
  },
  grid: {
    backgroundColor: 'white',
    justifyContent: 'center',
    borderRadius: '10px',
    padding: '10px'
  },
  loginBtn: {
    backgroundColor: '#2754CE',
    '&:hover': {
      backgroundColor: '#6361CD',
    },
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 200,
    padding: '14px 16px',
    lineHeight: 1.5,
  },
  paper: {
    marginTop: '45%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '14px',
  },
  resendbtn: {
    '&.MuiButtonBase-root ': {
      color: '#797D89',
      width: '325px',
      marginTop: '20px',
    }
  },
  closeButton: {
    marginLeft: '31rem',
    marginTop: '-3rem',
  }
}));

const ReferJob = ({ processReferJob, onClose }) => {
  const classes = useStyles();

  const formikReferJob = useFormik({
    initialValues: {
      email: '',
      referName: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
      referName: Yup.string().required('Full Name is Required'),
    }),
    onSubmit: (values) => {
      processReferJob(values.email, values.referName);
      onClose();
    },
  });

  return (
    <Container component="main" maxWidth="sm" >
      <CssBaseline />
      <div className={classes.paper }>
        <div style={{ marginLeft: '3rem' }}>
          <Typography variant="h4">
            Refer Job
          </Typography>
        </div>
        <Grid
          item
          className={classes.closeButton}
          xs={1}
          container>
          <IconButton
            color="secondary"
            aria-label="Close Dialog">
            <CloseIcon onClick={onClose} />
          </IconButton>
        </Grid>
        <Divider />
        <Grid
          container className={classes.grid}
        >
          <form onSubmit={formikReferJob.handleSubmit} className={classes.formContainer}>
            <InputLabel required>
              Name
            </InputLabel>
            <TextField
              required
              InputProps={{ disableUnderline: true }}
              className={classes.customInput}
              variant="standard"
              fullWidth
              id="referName"
              name="referName"
              placeholder="Enter Full Name"
              value={formikReferJob.values.referName}
              onChange={formikReferJob.handleChange}
              error={formikReferJob.touched.referName && Boolean(formikReferJob.errors.referName)}
              helperText={formikReferJob.touched.referName && formikReferJob.errors.referName} />

            <InputLabel required>
              Email Address
            </InputLabel>
            <TextField
              required
              InputProps={{ disableUnderline: true }}
              className={classes.customInput}
              variant="standard"
              fullWidth
              id="email"
              name="email"
              placeholder="Enter email address"
              value={formikReferJob.values.email}
              onChange={formikReferJob.handleChange}
              error={formikReferJob.touched.email && Boolean(formikReferJob.errors.email)}
              helperText={formikReferJob.touched.email && formikReferJob.errors.email} />
            <Button
              color="primary"
              fullWidth type="submit"
              className={classes.loginBtn}>
                Refer
            </Button>
          </form>
        </Grid>
      </div>
    </Container>
  );
};

export default ReferJob;
