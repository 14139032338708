import React, { useEffect, useState } from 'react';
import { Typography, Tab, Tabs, makeStyles } from '@material-ui/core';
import * as R from 'ramda';
import DPTable from 'view/common/DPTable';
import { useDispatch } from 'react-redux';
import { fetchJobPostsByUser } from 'redux/actions/employerActions/userManagementActions';
import { UMNestedTabWiseList, UMTabColumns } from 'constants/employer/userManagement';
import { isEmpty } from 'lodash';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    marginBottom: '1rem',
    marginTop: '3rem',
  },
}));

// ToDo: Implement pagination refer : src/view/employer/pages/JobPosting/AllJobsPosting/Activity.js after API is fixed
const UserPostsTable = ({ userType, userData }) => {
  const classes = useStyle();
  const [currentTab, setCurrentTab] = useState(0);
  const dispatch = useDispatch();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const setPageNumber = () => { };
  const setPageSize = () => { };

  useEffect(() => {
    if (!isEmpty(userData)) {
      dispatch(fetchJobPostsByUser({ id: userData.id, pageNo: 0, pageSize: 10 }));
    }
  }, [userData]);

  return (
    <>
      <Typography className={classes.pageTitle}>Activity Details</Typography>
      <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example" >
        {
          R.map((tab) => {
            return <Tab label={tab.label} {...a11yProps(tab.id)} />;
          })(UMNestedTabWiseList[userType])
        }
      </Tabs>
      <DPTable
        columns={UMTabColumns[UMNestedTabWiseList[userType][currentTab].id]}
        rowsData={[]}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        totalElements={0}
      />
    </>
  );
};

export default React.memo(UserPostsTable);