import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyle = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
    width: 'fit-content',
    height: '26px',
    borderRadius: '4px',
    '&.MuiBox-root': {
      textTransform: 'uppercase',
    },
  },
  _5: {
    background: '#E0FFE3',
    '&.MuiTypography-root': { color: '#23AF31', background: '#23AF31' },
  },
  _4: {
    background: '#E4FFFF',
    '&.MuiTypography-root': { color: '#44B8B8', background: '#44B8B8' },
  },
  _3: {
    background: '#FFECD7',
    '&.MuiTypography-root': { color: '#DE8318', background: '#DE8318' },
  },
  _2: { background: '#FFEFF6', '&.MuiTypography-root': { color: '#E04A89', background: '#E04A89' } },
  _1: { background: '#FFF9E3', '&.MuiTypography-root': { color: '#E7B503', background: '#E7B503' } },
  l_5: {
    background: '#E0FFE3',
    '&.MuiTypography-root': { color: '#23AF31' },
  },
  l_4: {
    background: '#E4FFFF',
    '&.MuiTypography-root': { color: '#44B8B8' },
  },
  l_3: {
    background: '#FFECD7',
    '&.MuiTypography-root': { color: '#DE8318' },
    '&.MuiTypography-body1': {
      color: '#DE8318',
    },
  },
  l_2: { background: '#FFEFF6', '&.MuiTypography-root': { color: '#E04A89' } },
  l_1: { background: '#FFF9E3', '&.MuiTypography-root': { color: '#E7B503' } },
  round: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff !important',
    height: '15px',
    width: '15px',
    top: '5px',
    borderRadius: '10px',
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: 0
  },
  label: {
    '&.MuiTypography-body1': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      marginLeft: '5px',
    },
  },
}));

const DPLegends = ({ remark, ratings }) => {
  const classes = useStyle();

  return (
    <Box key={`${ratings}_box_legend`} style={{ marginRight: '18px' }} className={clsx(classes.box, classes[`_${ratings}`])}>
      <Typography key={`${ratings}_number`} className={clsx(classes.round, classes[`_${ratings}`])}>
        {ratings}
      </Typography>
      <Typography key={`${ratings}_label`} className={clsx(classes.label, classes[`l_${ratings}`])}>
        {remark}
      </Typography>
    </Box>
  );
};

export default DPLegends;

DPLegends.defaultProps = {
  label: 'Legend Name',
  number: 1,
};

DPLegends.propTypes = {
  label: PropTypes.string,
  number: PropTypes.number,
};
