import React, { useState } from 'react';
import DPCalendar from 'view/common/DPCalendar/DPCalendar';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import SlotsConfirmationPopup from './ConfirmSlotPopover';
import ConfirmApplicantSlotPopover from './ConfirmApplicantSlotPopover';
import { getFormatedDate } from 'view/common/utils/dateUtils';

const ConfirmSlot = ({ currentTab, dispatcher, slots, usedIn }) => {
  
  const dispatch = useDispatch();
  const [slotsData, setSlots] = useState([]);

  useEffect(() => {
    dispatch(dispatcher());
  }, []);
  
  useEffect(() => { 
    if (!isEmpty(slots)) {
      switch (usedIn) {
      case 'applicant': {
        let __slots = slots.filter( item => !item.organiserConfirmation && item.interviewerConfirmation);
        setSlots(slotApplicantJson(__slots));        
      }
        break;
      default: {
        let __slots = slots.filter( item => !item.organiserConfirmation);
        setSlots(slotInterviewerJson(__slots));        
      }
        break;
      }
    }
  }, [slots]);

  const fetchRemoteData = (query) => {
    const { start, end } = query;
    const filter = {
      startTime: getFormatedDate(start),
      endTime: getFormatedDate(end)
    };
    dispatch(dispatcher(filter));
  };

  const getSlotsColor = (isbooked) => {
    return isbooked?'#64C364':'#6361cd';    
  };

  const slotInterviewerJson = (data) => {
    return (data || []).map(eachEvent => ({
      event_id: eachEvent.id,
      title: `Organiser: ${eachEvent?.organiserName}, Job Title: ${eachEvent?.jobTitle}`,
      start: new Date(eachEvent?.startTime),
      end: new Date(eachEvent?.endTime),
      color: getSlotsColor(eachEvent?.interviewerConfirmation),
      organiserName: eachEvent?.organiserName,
      jobTitle: eachEvent?.jobTitle,
      companyDetailsId: eachEvent?.companyDetailsId,
      applicantConfirmation: eachEvent?.applicantConfirmation,
      interviewerConfirmation: eachEvent?.interviewerConfirmation,
      organiserConfirmation: eachEvent?.organiserConfirmation,
      slotID: eachEvent?.interviewers?.[0]?.interviewSlotId
    }));
  };
  
  const slotApplicantJson = (data) => {
    return (data || []).map(eachEvent => ({
      event_id: eachEvent.id,
      title: `${eachEvent?.companyName} (${eachEvent?.jobTitle})` ,
      start: new Date(eachEvent?.startTime),
      end: new Date(eachEvent?.endTime),
      color: getSlotsColor(eachEvent?.applicantConfirmation),
      companyName: eachEvent?.companyName,
      jobTitle: eachEvent?.jobTitle,
      companyDetailsId: eachEvent?.companyDetailsId,
      applicantConfirmation: eachEvent?.applicantConfirmation,
      interviewerConfirmation: eachEvent?.interviewerConfirmation,
      organiserConfirmation: eachEvent?.organiserConfirmation,
      slotID: eachEvent?.interviewers?.[0]?.interviewSlotId
    }));
  };

  const customEditorComponent = () => usedIn === 'applicant' ? ConfirmApplicantSlotPopover : SlotsConfirmationPopup;

  return (
    <DPCalendar 
      key={currentTab} 
      events={slotsData} 
      CustomEditor={customEditorComponent()} 
      fetchRemoteData={fetchRemoteData} 
      usedIn='confirmSlot' 
      startTime={9} 
    />
  );
};

export default ConfirmSlot;
