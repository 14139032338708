import { Grid, Box, Button, Chip, Divider, TextField, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { difference, filter, get, isEmpty, isNull, map, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onGetAllJobTitleList } from 'redux/actions/employerActions/Configurations/jobActions';
import { setSelectedUser } from 'redux/slices/employerSlices/userManagement';
import { colors } from 'themes/colors';
import SuccessModal from 'view/common/components/SuccessModal/SuccessModal';
import { SelectField } from 'view/ui-components';
import * as Yup from 'yup';
import { onGetAllOrgRoles } from 'redux/actions/employerActions/organizationRoleActions';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import { getEmpUsers, getInterviewers, onAddEmpUser } from 'redux/actions/employerActions/userManagementActions';
import { countryCodeMobileRegExp } from 'view/common/utils/formUtils';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.25),
    backgroundColor: `${colors.blue1}`,
    color: 'white',
  },
}));

const validationSchema = {
  user: Yup.object({
    role: Yup.string().required('This field is required'),
    name: Yup.string().required('This field is required'),
    email: Yup.string().email('Invalid email').required('This field is required'),
    contactNumber: Yup.string().required('Mobile number is required')
      .matches(countryCodeMobileRegExp, 'Contact number is not valid'),
    addTeamMembersCount: Yup.string().required('This field is required'),
  }),
  interviewer: Yup.object({
    name: Yup.string().required('This field is required'),
    email: Yup.string().email('Invalid email').required('This field is required'),
    contactNumber: Yup.string().required('Mobile number is required')
      .matches(countryCodeMobileRegExp, 'Contact number is not valid'),
  })
};

const InitialValues = {
  interviewer: {
    name: '',
    email: '',
    contactNumber: '',
  },
  user: {
    role: '',
    name: '',
    email: '',
    contactNumber: '',
    employeeJobTitleXrefs: [],
    addTeamMembersCount: 0,
    employeeJobTitles: []
  }
};

const TEAM_MEMBERS = [{
  id: 5,
  name: '5'
},
{
  id: 10,
  name: '10'
},
{
  id: 15,
  name: '15'
},
{
  id: 20,
  name: '20'
}];

const getFormattedPayload = (data) => {
  const { role } = data;
  return omit({
    ...data,
    employeeJobTitleXrefs: map(data.employeeJobTitleXrefs, (item) => {
      return {
        jobTitle: {
          id: item.id,
        }
      };
    }),
    role: { id: role }
  }, ['employeeJobTitles']);
};

const getFormattedFormData = (data) => {
  if (isEmpty(data)) {
    return data;
  }
  const updatedData = {
    ...data,
    employeeJobTitleXrefs: map(get(data, ['employeeJobTitleXrefs'], []), (item) => {
      return {
        ...item,
        title: get(item, ['jobTitle', 'title'], ''),
      };
    }),
    role: get(data, ['role', 'id'], '')
  };
  return updatedData;
};

const UserForm = ({ onCancel, currentTab, formData = null }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);
  const jobTitles = useSelector(state => get(state, ['addJobTitle', 'allJobTitles'], []));
  const roleInfo = useSelector(state => get(state, ['organizationRoles'], {}));
  const { status: roleStatus, roles } = roleInfo;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getFormattedFormData(formData) || InitialValues[currentTab],
    validationSchema: validationSchema[currentTab],
    onSubmit: (values) => {
      const payload = currentTab === 'interviewer' ? values : getFormattedPayload(values, roles);
      onAddUser(payload);
    },
  });

  const onAddUser = (payload) => {
    dispatch(onAddEmpUser({
      type: currentTab,
      data: payload,
      isEdit: !isEmpty(formData)
    })).then((res) => {
      if (res.payload) {
        onCancel();
        setShowSuccess(true);
        formik.resetForm(getFormattedFormData(res.payload));
        if (isEmpty(formData)) {
          dispatch(setSelectedUser({}));
          const dispatcher = currentTab === 'interviewer' ? getInterviewers : getEmpUsers;
          dispatch(dispatcher({
            pageNo: 0,
            pageSize: 25,
            search: ''
          }));
        }
      }
    });
  };

  const onJobTitleAdd = () => {
    const fieldValue = formik.values.employeeJobTitles;
    if (fieldValue) {
      const updatedJobTitles = [...formik.values.employeeJobTitleXrefs, ...filter(jobTitles, item => item.id === fieldValue)];
      formik.setFieldValue('employeeJobTitleXrefs', updatedJobTitles);
      formik.setFieldValue('employeeJobTitles', '');
    }
  };

  const onJobTitleDelete = (id) => {
    const updatedJobTitles = filter(formik.values.employeeJobTitleXrefs, item => item.id !== id);
    formik.setFieldValue('employeeJobTitleXrefs', updatedJobTitles);
  };

  const onRoleSelect = (e) => {
    formik.setFieldValue('role', e.target.value);
  };

  const onJobTitleChange = (e) => {
    formik.setFieldValue('employeeJobTitles', e.target.value);
  };
  useEffect(() => {
    if (isNull(jobTitles)) {
      dispatch(onGetAllJobTitleList());
    }
  }, [jobTitles]);

  useEffect(() => {
    if (roleStatus === 'idle') {
      dispatch(onGetAllOrgRoles({
        pageNo: 0,
        pageSize: 100,
        search: ''
      }));
    }
  }, []);

  return (
    <Box>
      {/* {({ errors, touched }) => ( */}
      <form onSubmit={formik.handleSubmit} >
        {
          currentTab !== 'interviewer' && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor="TextField-input" required>User Role</InputLabel>
                <SelectField
                  dropdownOptions={roles}
                  onChange={onRoleSelect}
                  value={formik.values.role}
                  localePrefix="DHIYO.CONFIGURATION.USER_MANAGEMENT.ADD_USER_FORM.ROLE"
                  label="Role"
                  displayKey="displayName"
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                />
              </Grid>
            </Grid>
          )
        }

        <Grid container spacing={3}>
          <Grid item xs={12} md={4} >
            <InputLabel htmlFor="TextField-input" required>Full Name</InputLabel>
            <TextField variant="outlined"
              name="name"
              type="text"
              placeholder='Enter full name'
              onChange={formik.handleChange}
              value={formik.values.name}
              fullWidth
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel htmlFor="TextField-input" required>Email</InputLabel>
            <TextField variant="outlined"
              name="email"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder='Enter email address'
              fullWidth
              disabled={!isEmpty(formData)}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel htmlFor="TextField-input" >Phone</InputLabel>
            <MaterialUiPhoneNumber id="contactNumber"
              variant="outlined"
              name="contactNumber"
              placeholder='Enter phone'
              type="tel"
              defaultCountry='in'
              onChange={value => formik.setFieldValue('contactNumber', value)}
              value={formik.values.contactNumber}
              fullWidth
              disabled={!isEmpty(formData) && formik.values.contactNumber}
              error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
              helperText={formik.touched.contactNumber && formik.errors.contactNumber}
            />
          </Grid>
        </Grid>
        {
          currentTab !== 'interviewer' && (
            <div style={{ marginTop: '1rem' }}>
              <InputLabel htmlFor="TextField-input">How many Team Members can be added by this user?</InputLabel>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <SelectField
                    dropdownOptions={TEAM_MEMBERS}
                    onChange={e => formik.setFieldValue('addTeamMembersCount', e.target.value)}
                    value={formik.values.addTeamMembersCount}
                    localePrefix="DHIYO.CONFIGURATION.USER_MANAGEMENT.ADD_USER_FORM.TEAM_MEMBERS"
                    label=""
                    error={formik.touched.addTeamMembersCount && Boolean(formik.errors.addTeamMembersCount)}
                    helperText={formik.touched.addTeamMembersCount && formik.errors.addTeamMembersCount}
                  />
                </Grid>
              </Grid>
            </div>
          )
        }
        {
          currentTab !== 'interviewer' && (
            <Grid container spacing={3}>
              <Grid item xs={10} md={8}>
                <InputLabel htmlFor="TextField-input">Job Title Handling</InputLabel>
                <SelectField
                  dropdownOptions={isNull(jobTitles) ? [] : difference(jobTitles, formik.values.employeeJobTitleXrefs)}
                  onChange={onJobTitleChange}
                  value={formik.values.employeeJobTitles}
                  localePrefix="DHIYO.CONFIGURATION.USER_MANAGEMENT.ADD_USER_FORM.EMPLOYEE_JOB_TITLE"
                  displayKey="title"
                />
              </Grid>
              <Grid item xs={2} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Button color='secondary' onClick={onJobTitleAdd} style={{ marginTop: '1rem' }}>Add</Button>
              </Grid>
            </Grid>
          )
        }
        {
          map(formik.values.employeeJobTitleXrefs, (item) => {
            return (
              <Chip
                label={get(item, ['title'], '')}
                color="secondary"
                onDelete={() => onJobTitleDelete(item.id)}
                className={classes.chip}
              />
            );
          })
        }
        <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
        <Box sx={{ display: 'flex', columnGap: '1rem' }}>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
          >
            {currentTab !== 'interviewer' ? (
              `${isEmpty(formData) ? 'Add User' : 'Update User'}`
            ) : (
              `${isEmpty(formData) ? 'Add Interviewer' : 'Update Interviewer'}`
            )}

          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Box>
      </form>
      {/* )} */}
      <SuccessModal
        open={showSuccess}
        setOpen={setShowSuccess}
        title={'User added successfully'}
        subtitle={'User have been added successfully to the list and can access the system'}
      />
    </Box>
  );
};

export default UserForm;