/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { FieldArray, Field } from 'formik';
import * as yup from 'yup';
import { YupValidator } from '../../../../../utils/YupValidator';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Typography, Breadcrumbs, Button, InputLabel, Badge } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import editprofile from 'asset/icons/editprofile.svg';
import {
  PhoneField,
  FormikInputField,
  ImageAvatar,
  FormikAutoSuggestMultiple,
  FormikChipSelector,
  FormikSelectField,
  MultiStepForm,
  FormStep,
} from '../../../../ui-components';
import { colors } from '../../../../../themes/colors';
import { lovKeys } from 'utils/LOVUtils';
import { useDispatch, useSelector } from 'react-redux';
import { filter, get, includes, isObject, startsWith } from 'lodash';
import { postCompanyInfo } from 'redux/restAPIs/employerAPI/companyInfo';
import { Link, useNavigate } from 'react-router-dom';
import { onEmployerProfileDetails } from 'redux/actions/employerActions/employerAuthentication';
import { assocPath } from 'ramda';
import { pathOr } from 'ramda';
import { countryCodeMobileRegExp } from 'view/common/utils/formUtils';

const getFormattedCompanyInfo = (data) => {
  if (!data.profileCompleted) {
    return {
      companyName: get(data, 'companyName', ''),
      industries: [],
      address: [
        {
          companyAddress: '',
          city: '',
          state: '',
          country: '',
          zipcode: '',
          isPrimaryAddressType: true,
        },
      ],

      website: get(data, 'website', ''),
      email: get(data, 'email', ''),
      phone: '',
      contactDetails: [
        {
          name: '',
          email: '',
          phone: '',
        },
      ],
      employeeBenefits: [],
      socialMediaAccounts: [
        { name: '', link: '' },
      ],
      employeeStrength: '',
      about: '',
      mission: '',
      companyValues: '',
    };
  }
  return {
    ...data,
    industries: (get(data, 'industries', []) || []).map(item => item.id)
  };
};

const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingTop: '3em',
    paddingLeft: '10em',
    paddingRight: '10em',
    paddingBottom: '10em',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2em',
      paddingRight: '2em',
      paddingBottom: '3em',
    },
    '&& .MuiOutlinedInput-input': {
      padding: '20px 13px'
    }
  },
  title: {
    ...theme.typography.title,
  },
  titleBasicDetail: {
    ...theme.typography.title,
    color: '#24283C',
  },
  rightAligned: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  spaceAround: { display: 'flex', justifyContent: 'space-around' },
  imageContainer: {
    height: '100px',
    width: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 15,
    backgroundColor: '#EEF3F9',
  },
  profilePhoto: {
    height: '70px',
    width: '70px',
  },
  linkLabel: {
    ...theme.typography.linkLabel,
  },
  label: {
    ...theme.typography.linkLabel,
    fontWeight: 400,
  },
  Caption: {
    ...theme.typography.Caption,
  },
  button: {
    color: theme.palette.common.blue,
    textTransform: 'none',
  },
  deleteButton: {
    color: '#6360CD',
    textTransform: 'none',
  },
  formContainer: {
    backgroundColor: colors.white,
    padding: '2em',

    [theme.breakpoints.down('md')]: {
      paddingBottom: '1em',
    },
  },
  input: {
    '&& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: '11px 4px'
    }
  }
}));

const useHelperTextStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '3rem'
  }
}));

export default function CompanyInfo() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const industryList = useSelector(state => get(state, ['lov', lovKeys.industries], []));
  const empBenefits = useSelector(state => get(state, ['lov', lovKeys.employeeBenefits], []));
  const empStrengthList = useSelector(state => get(state, ['lov', lovKeys.employeeCount], []));

  const companyInfo = useSelector(state => get(state, ['userProfile', 'employer'], {}));
  const [formData, setFormData] = useState(getFormattedCompanyInfo(companyInfo));

  const navigate = useNavigate();

  const validationSchema2 = yup.object({
    about: YupValidator('Required'),
    mission: YupValidator('Required'),
    companyValues: YupValidator('Required'),
    employeeBenefits: yup
      .array()
      .min(1, translate('DHIYO.FORM_VALIDATION_MSG.REQUIRED')),
  });

  const validationSchema = yup.object({
    companyName: YupValidator('Required'),
    address: yup.array().of(
      yup.object().shape({
        country: YupValidator('Required'),
        companyAddress: YupValidator('Required'),
        state: YupValidator('Required'),
        city: YupValidator('Required'),
        zipcode: yup.string().min(6).max(6).required('Required')
          .matches(/^[0-9]+$/, 'Enter valid zipcode')
          .nullable(true)
          .transform(value => (!value ? null : value)),
      })
    ),
    website: YupValidator('WebLink'),
    email: YupValidator('Email'),
    phone: yup.string().required('Mobile number is required')
      .matches(countryCodeMobileRegExp, 'Contact number is not valid'),
    socialMediaAccounts: yup.array().of(
      yup.object().shape({
        link: YupValidator('WebLink'),
      })
    ),
    contactDetails: yup.array().of(
      yup.object().shape({
        name: YupValidator('Required'),
        email: YupValidator('Email'),
        phone: YupValidator('Required'),
      })
    ),
  });

  const socialMediaData = [
    { 'id': 'TWITTER', 'name': 'Twitter' },
    { 'id': 'FACEBOOK', 'name': 'Facebook' },
    { 'id': 'LINKEDIN', 'name': 'LinkedIn' },
    { 'id': 'INDEED', 'name': 'Indeed' }
  ];

  const getFormattedPayload = (values) => {
    const industries = filter(industryList, item => includes(values.industries, item.id));
    const empStrength = get(values, 'employeeStrength');
    const employeeStrength = isObject(empStrength) ? empStrength : empStrengthList.find(item => item.id === get(values, 'employeeStrength', ''));
    return {
      ...values,
      industries,
      employeeBenefits: values.employeeBenefits.map((item) => {
        if (startsWith(item.id, 'custom')) {
          return {
            name: item.name
          };
        }
        return {
          ...item,
          refId: item.id
        };
      }),
      employeeStrength
    };
  };
  const onSave = async (values) => {
    const payload = getFormattedPayload(values);
    await postCompanyInfo({ ...payload, id: companyInfo.id });
    await dispatch(onEmployerProfileDetails({}));
  };

  const onSocialMediaRemove = (index) => {
    setFormData(assocPath(['socialMediaAccounts', index, 'deleted'], true, formData));
  };

  useEffect(() => {
    setFormData(getFormattedCompanyInfo(companyInfo));
  }, [companyInfo]);

  const helperTextStyles = useHelperTextStyles();

  return (
    <Grid
      container
      direction="column"
      className={classes.mainContainer}
      spacing={1}>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="secondary"
            to="/employer/configuration"
            className={`${classes.linkLabel} MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary`}>
            {translate('DHIYO.CONFIGURATION.HEADER.TITLE')}
          </Link>

          <Typography className={classes.label}>
            {translate('DHIYO.CONFIGURATION.COMPANY_INFO.HEADER.TITLE')}
          </Typography>
        </Breadcrumbs>
      </Grid>

      <Grid item container direction="column">
        <Grid>
          <Typography className={classes.title}>
            {translate('DHIYO.CONFIGURATION.COMPANY_INFO.HEADER.TITLE')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {translate('DHIYO.CONFIGURATION.COMPANY_INFO.HEADER.FILL_DETAILS')}
          </Typography>
        </Grid>
      </Grid>

      <MultiStepForm
        initialValues={formData}
        onSubmit={(values) => {
          onSave(values);
          navigate('/employer/configuration');
        }}
        formikRef={formikRef}
      >
        <FormStep stepName="Step 01" validationSchema={validationSchema}>
          <Grid
            item
            className={classes.formContainer}
            container
            direction="column"
            spacing={2}>

            <Grid item container direction="column">
              <Grid>
                <Typography className={classes.titleBasicDetail}>
                  {translate(
                    'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.HEADER.BASIC'
                  )}
                </Typography>
              </Grid>
              {/* this */}
              <Grid item>
                <Typography variant="subtitle1">
                  {translate(
                    'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.HEADER.BASIC_DETAILS'
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12} md={4}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <img src={editprofile} alt='edit profile icon' style={{ width: '30px' }} />
                  }
                >
                  <ImageAvatar isReadOnly={false} />
                </Badge>
              </Grid>
            </Grid>

            <Grid item container spacing={2} style={{ paddingTop: '1em' }}>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor="TextField-input" required>Company Name</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.COMPANY_FIELD"
                  id="companyName"
                  name="companyName"
                  maxLength={50} />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor="TextField-input">Industry</InputLabel>
                <FormikAutoSuggestMultiple
                  localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.INDUSTRY_FIELD"
                  id="companyIndustry"
                  name="industries"
                  className={classes.input}
                  multiple
                  apiKey={lovKeys.industries}
                />
              </Grid>
            </Grid>

            <FieldArray name="address">
              {({ push, remove, form }) => (
                <>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="TextField-input" required>City</InputLabel>
                      <FormikInputField
                        localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.CITY_FIELD"
                        id="address[0].city"
                        name="address[0].city"
                        key="address_city_1"
                        maxLength={50} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="TextField-input" required>State</InputLabel>
                      <FormikInputField
                        localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.STATE_FIELD"
                        id="address[0].state"
                        name="address[0].state"
                        key="address_state_1"
                        maxLength={50} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="TextField-input" required>Country</InputLabel>
                      <FormikInputField
                        localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.COUNTRY_FIELD"
                        id="address[0].country"
                        name="address[0].country"
                        key="address_country_1"
                        maxLength={50} />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={2}
                    style={{ paddingTop: '1em' }}>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="TextField-input" required>Company Address</InputLabel>
                      <FormikInputField
                        localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.ADDRESS_FIELD"
                        id="address[0].companyAddress"
                        name="address[0].companyAddress"
                        maxLength={100} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="TextField-input" required>Zipcode</InputLabel>
                      <FormikInputField
                        localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.ZIPCODE_FIELD"
                        id="address[0].zipcode"
                        name="address[0].zipcode"
                        maxLength={6} />
                    </Grid>
                    <Grid item xs={12} md={4} />
                  </Grid>
                </>
              )}
            </FieldArray>

            <Grid item container spacing={2}>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor="TextField-input" required>Website</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.WEBSITE_FIELD"
                  id="website"
                  name="website"
                  maxLength={50} />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor="TextField-input" required>Email Address</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.MAIL_FIELD"
                  id="email"
                  name="email"
                  maxLength={50} />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor="TextField-input" required>Phone</InputLabel>
                <Field
                  type="tel"
                  name="phone"
                  localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.MOB_FIELD"
                  component={PhoneField} />
              </Grid>
            </Grid>

            <FieldArray name="socialMediaAccounts">
              {({ push, remove, form }) => (
                <Grid item container alignItems="center">
                  <InputLabel htmlFor="TextField-input">Social Media Accounts</InputLabel>
                  {form.values.socialMediaAccounts.map((p, index) => {
                    const name = `socialMediaAccounts[${index}].name`;
                    const link = `socialMediaAccounts[${index}].link`;
                    if (pathOr(false, ['socialMediaAccounts', index, 'deleted'], formData)) {
                      return null;
                    }
                    
                    return (
                      <Grid
                        item
                        container
                        spacing={2}
                        alignItems="center">
                        <Grid key={p.id} item xs={5} md={4}>
                          
                          <FormikSelectField
                            dropDownData={socialMediaData}
                            localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.SOCMEDIA_FIELD"
                            key={name}
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={7} md={4}>
                          <FormikInputField
                            localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.SOCMEDIA_URL_FIELD"
                            key={link}
                            name={link}
                            value={p.link}
                            FormHelperTextProps={{
                              classes:{
                                root:helperTextStyles.root
                              }
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          md={4}
                          container

                          justifyContent={matchesSM ? 'center' : 'flex-start'}>
                          {index > 0 ? (
                            <IconButton
                              aria-label="delete"
                              disableRipple
                              onClick={() => p.id ? onSocialMediaRemove(index) : remove(index)}
                              className={classes.margin}>
                              <DeleteIcon className={classes.deleteButton} />
                            </IconButton>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid item container>
                    <Grid item>
                      <Button
                        size="medium"
                        className={classes.button}
                        type="button"
                        disableRipple
                        onClick={() =>
                          push({
                            name: '',
                            link: '',
                          })
                        }>
                        Add More
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </FieldArray>

            <Grid
              item
              container
              direction="column"
              style={{ marginTop: '2em' }}>
              <Grid>
                <Typography className={classes.titleBasicDetail}>
                  {translate(
                    'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.EMPLOYER_CONTACT_FIELD.LABEL'
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {translate(
                    'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.EMPLOYER_CONTACT_PERSON_INFO.LABEL'
                  )}
                </Typography>
              </Grid>
            </Grid>

            <FieldArray name="contactDetails">
              {({ push, remove, form }) => (
                <Grid item container spacing={4}>
                  <>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="TextField-input" required>Primary Contact Person</InputLabel>
                      <FormikInputField
                        localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.PRIMARY_CONTACT_PERSON_FIELD"
                        key="contactDetails_name"
                        name="contactDetails[0].name"
                        maxLength={50} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="TextField-input" required>Email</InputLabel>
                      <FormikInputField
                        localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.MAIL_FIELD"
                        key="contactDetails_email"
                        name="contactDetails[0].email"
                        maxLength={50} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="TextField-input" required>Phone</InputLabel>
                      <Field
                        type="tel"
                        key="contactDetails_phone"
                        name="contactDetails[0].phone"
                        localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.MOB_FIELD"
                        component={PhoneField} />
                    </Grid>
                  </>
                </Grid>
              )}
            </FieldArray>

          </Grid>
        </FormStep>
        <FormStep stepName="Step 02" validationSchema={validationSchema2}>
          <Grid
            item
            className={classes.formContainer}
            container
            direction="column"
            spacing={2}>
            <Grid
              item
              container
              direction="column"
              style={{ marginTop: '2em' }}>
              <Grid>
                <Typography className={classes.titleBasicDetail}>
                  {translate(
                    'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.OTHER_DETAIL_HEADER.LABEL'
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {translate(
                    'DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.OTHER_DETAIL_HEADER.SUBLABEL'
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12} md={10}>
                <InputLabel htmlFor="TextField-input" required>About Company</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.ABOUT_CMPNY_FIELD"
                  id="about"
                  name="about"
                  maxLength={250}
                  showLength
                  multiline
                  rows={4} />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12} md={10}>
                <InputLabel htmlFor="TextField-input" required>Misson</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.MISSION_FIELD"
                  id="mission"
                  name="mission"
                  maxLength={250}
                  showLength
                  multiline
                  rows={4} />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12} md={10}>
                <InputLabel htmlFor="TextField-input" required>Values of Company</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.VALUE_OF_CMPNY_FIELD"
                  id="companyValues"
                  name="companyValues"
                  maxLength={250}
                  showLength
                  multiline
                  rows={4} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="TextField-input">Employees Count</InputLabel>
              <FormikSelectField
                apiKey={lovKeys.employeeCount}
                localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.EMP_COUNT_FIELD"
                name="employeeStrength" />
            </Grid>
            <Grid item>
              <InputLabel htmlFor="TextField-input" required>Employee Benefits</InputLabel>
              <FormikChipSelector
                id="employeeBenefits"
                apiKey={lovKeys.employeeBenefits}
                localePrefix="DHIYO.CONFIGURATION.COMPANY_EDIT_FORM.EMP_BENIFIT_FIELD"
                name="employeeBenefits"
              />
            </Grid>
          </Grid>
        </FormStep>
      </MultiStepForm>
    </Grid>
  );
}
