import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyle = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContent: {
    padding: '1.3rem 1.3rem 0 1.3rem'
  },
  card: {
    marginTop: '15px',
    border: '2px solid #6360CD',
    boxShadow: '0px 2px 6px rgba(99, 96, 205, 0.4)',
    borderRadius: 4,
  }
}));

const ScheduleCardDetails = (props) => {
  const classes = useStyle();
  const { data } = props;
  return (
    <Card
      onClick={() => {}}
      className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={3} md={3} style={{ borderRight: '1px solid #cccccc' }}>
            <span style={{ fontSize: '24px' }}><b>{moment(data.interviewDetail.startTime).format('D')}</b></span> <span style={{ fontSize: '13px' }}>{moment(data.interviewDetail.startTime).format('MMM')}, {moment(data.startTime).format('YYYY')}</span><br />
            <span style={{ fontSize: '12px' }}><b>{moment(data.interviewDetail.startTime).format('h:mm a')} - {moment(data.interviewDetail.endTime).format('h:mm a')}</b></span>
          </Grid>
          <Grid item xs={9} md={9} style={{ display: 'contents' }}>
            <Grid item xs={2} className={classes.gridContainer} >
              <Avatar />
            </Grid>
            <Grid item xs={7} md={7}>
              <Typography>
                <b>{data?.jobDetail?.companyName || 'Job'}</b>
                <Typography variant="subtitle2">
                  Interviewer: {data?.interviewDetail?.interviewerName || ''}
                </Typography>
                <Typography variant="subtitle2">
                  Position: {data?.jobDetail?.position || ''}
                </Typography>
                <Typography variant="h6">
                  <a href={data?.interviewDetail?.interviewMeetingDetailsDTO?.interviewMeetingUrl || ''}>Interview Link</a>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ScheduleCardDetails;