import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { filter } from 'lodash';
import ViewOrgRoles from './viewOrgRoles';
import { Popup, Notification, ConfirmDialog } from '../../../../ui-components';
import { useTranslation } from 'react-i18next';
import * as companyLocService from '../../../../../utils/compLocService';
import * as orgRoleService from '../../../../../utils/orgRoleService';

export default function OrganisationRoles() {
  const [openPopup, setOpenPopup] = useState(false);
  // const initialValue = {
  //   id: "",
  //   role: "",
  //   designation: "",
  //   reportsTo: null,
  //   createJob:false,
  //   scheduleInterview:false,
  //   "createJobTitle":false,
  //   "addTeamMember":false,
  // } ;
  // const [recordForEdit, setRecordForEdit] = useState(initialValue);
  const { t : translate } = useTranslation();
 
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const [orgRolesData, setOrgRolesData] = useState(
    orgRoleService.getAllOrgRoles()
  );

  const openInPopup = (id) => {
    let selectedRow = filter(orgRolesData, { id }, []);
    if (selectedRow.length > 0) {
      // setRecordForEdit(selectedRow[0]);
      setOpenPopup(true);
    }
  };

  const enableConfirmDialog = (data) => {
    setConfirmDialog(data);
  };
  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    companyLocService.deleteCompanyLocation(id);
    setOrgRolesData(companyLocService.getAllCompanyLocations());
    setNotify({
      isOpen: true,
      message: 'Deleted Successfully',
      type: 'error',
    });
  };
  
  return (
    <>
      <Grid container direction="column" spacing={1} xs={12}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" xs={12}>
            <Button color="secondary" variant="outlined" type="cancel">Deactivate Role</Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ViewOrgRoles
            data={orgRolesData}
            openInPopup={openInPopup}
            key="viewLocations"
            onDelete={onDelete}
            setConfirmDialog={enableConfirmDialog}
            confirmDialog={confirmDialog} />
        </Grid>
      </Grid>
      <Popup
        title={translate('DHIYO.CONFIGURATION.ORG_ROLES.ADD_FORM.FORM_NAME')}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}>
       
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog} />
    </>
  );
}
