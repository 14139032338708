import { makeStyles } from '@material-ui/styles';
import { Breadcrumbs, Typography, Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getEmpUserById } from 'redux/actions/employerActions/userManagementActions';
import { RoutePath } from 'view/employer/route/Constants';
import UserProfileview from './UserProfileview';
import { find, get } from 'lodash';

const UserTypeMap = {
  user: 'users',
  interviewer: 'interviewers'
};
const useStyles = makeStyles(() => ({
  breadLink: {
    color: '#24283C',
    fontWeight: 600,
  },
}));

const UserDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id, userType } = params;

  const usersList = useSelector(state => get(state, ['userManagement', UserTypeMap[userType], 'content'], []));
  const userManagementApiStatus = useSelector(state => get(state, ['userManagement', 'userUpdateStatus'], ''));
  const classes = useStyles();

  const [userDetails, setUserDetails] = useState({});
  const [userData, setUserData] = useState({});

  const getUserDetails = () => {
    dispatch(getEmpUserById({ id, userType })).then((resp) => {
      setUserDetails(resp.payload);
    }).catch(() => {
      setUserDetails({});
    });
  };
  
  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    setUserData(find(usersList, item => item.id === id));
  }, [usersList]);

  useEffect(() => {
    if (userManagementApiStatus === 'succeeded'){
      getUserDetails();
    }
  }, [userManagementApiStatus]);
  
  return (
    <Container style={{ marginTop: '5rem', marginBottom: '5rem', minHeight: '100vh' }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="secondary"
          to={RoutePath.userManagement} 
          className={`${classes.linkLabel} MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary`}>
          User Management
        </Link>
        <Typography color="text.primary">User Details</Typography>
      </Breadcrumbs>
      <UserProfileview
        userDetails={userDetails}
        userData={userData}
        userType={userType}
      />
    </Container>
  );
};

export default UserDetails;