import React from 'react';
import { UserForms } from '../../utils/userProfileUtil';
import { useLocation } from 'react-router-dom';
import UserProfileWrapper from '../../wrappers/UserProfileWrapper';

const Login = () => {
  const location = useLocation();
  
  return (
    <UserProfileWrapper
      renderer={UserForms.loginForm}
      role={location.state.role} />
  );
};

export default Login;