import { Box, Container, Grid, Typography, Breadcrumbs, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ActivityCard from '../AllJobsPosting/ActivityCard';
import AppliedCandidates from './AppliedCandidates';
import CandidateCard from './CandidateProfile';
import RecommendedCandidates from './RecommendedCandidates';
import OtherDetails from '../../../../common/components/OtherDetails/OtherDetails';
import SimilarCandidates from './SimilarCandidates';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    marginTop: '1rem'
  },
  color: {
    color: '#333'
  },
  mainGrid: {
    marginTop: '38px',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
}));

const ApplicantJobProfileContainer = () => {
  const classes = useStyle();
  const { applicantId, jobId } = useParams();
  const similarCandidateData = useSelector(state => get(state, ['employerDashboard', 'similarCandidate'], {}));
  const candidateData = useSelector(state => get(state, ['employerDashboard', 'applicantsProfileById', applicantId], {}));
  const { email } = candidateData;

  return (
    <Container style={{ marginTop: '5rem', marginBottom: '5rem' }}>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.color}>
              <Link
                color="secondary"
                to="/employer/jobposting"
                className={`${classes.linkLabel} MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary`}>
                Job Posting
              </Link>
              <Link
                color="secondary"
                to={`/employer/activity/${jobId}`}
                className={`${classes.linkLabel} MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary`}>
                Job Details
              </Link>
              <Typography variant="subtitle2">
                Candidate Details
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ActivityCard />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <CandidateCard candidateId={applicantId} />
            <Grid container style={{ marginTop: '3rem' }}>
              <Grid item xs={12} align="left" style={{ marginBottom: '0.7rem' }}>
                <Typography className={classes.pageTitle}> Other Details
                </Typography>
              </Grid>
              <Grid item xs={12} >
                <OtherDetails />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            {similarCandidateData.isSimilarCandidate && 
              <div style={{ height: '550px', overflowX: 'auto' }}>
                <SimilarCandidates email={email} />
                <RecommendedCandidates />
              </div>
            }
            {!similarCandidateData.isSimilarCandidate && <RecommendedCandidates />}
            <AppliedCandidates />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ApplicantJobProfileContainer;
