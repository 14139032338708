import moment from 'moment';

const FORMAT = 'YYYY-MM-DDT00:00:00';
const FORMAT_SLOT = 'YYYY-MM-DDTHH:mm:ss';

export const getFormatedDate = date => moment(date).format(FORMAT_SLOT);
export const getFormatedDateSlot = date => moment(date).format(FORMAT_SLOT);

export const getCurrentStartOfMonth = () => moment().startOf('month').format(FORMAT);
export const getCurrentEndOfMonth = () =>moment().endOf('month').format(FORMAT);
export const getCurrentStartOfWeek = () =>moment().startOf('week').format(FORMAT);
export const getCurrentEndOfWeek = () =>moment().endOf('week').format(FORMAT);
export const getCurrentDate = () => moment().format(FORMAT);
export const getNextDate = () => moment(moment().add(1, 'days')).format(FORMAT);

export const getScheduleFilterDate = (filterChoice) => {
  switch (filterChoice) {
  case 1: {
    return {
      startDate:  getCurrentStartOfMonth(), 
      endDate: getCurrentEndOfMonth()
    };
  }
  case 2: {
    return {
      startDate: getCurrentStartOfWeek(), 
      endDate: getCurrentEndOfWeek()
    };
  }
  default: {
    return {
      startDate: getCurrentDate(), 
      endDate: getNextDate() 
    };
  }
  }
};