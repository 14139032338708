import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { colors } from '../../../../themes/colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: colors.background2,
    width: '100%',
    zIndex: 1302,
    position: 'relative',
  },

  gridItem: {
    padding: '0.8rem 1rem',
  },
  footerPolicyText: {
    color: colors.gray4,
    fontSize: '10px',
    marginBottom: 0,
    textAlign: 'end',
    fontWeight: 100,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  footerRightsText: {
    color: colors.gray4,
    fontSize: '10px',
    marginBottom: 0,
    fontWeight: 100,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}));

export default function Footer() {
  const { t : translate } = useTranslation();
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.gridItem}>
        <Grid item xs={6}>
          <Typography
            display="block"
            color="secondary"
            gutterBottom
            noWrap
            className={classes.footerRightsText}>
            &copy; 2021 | {translate('DHIYO.FOOTER.RIGHTS')} | DHIYO
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            display="block"
            color="secondary"
            gutterBottom
            noWrap
            className={classes.footerPolicyText}>
            {translate('DHIYO.FOOTER.POLICY')}  | {translate('DHIYO.FOOTER.TC')} 
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}
