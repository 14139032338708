import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Countdown from 'react-countdown';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  rootCountdown: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    position: 'relative',
    margin: '1rem auto',
    width: '927px',
    height: '562px',
    background: 'rgba(0, 0, 0, 0.8)',
    border: '1px solid #535353',
    borderRadius: '10px',
  },
  countdown: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '60px',
    lineHeight: '90px',
    textAlign: 'center',
    color: '#FFFFFF'
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '45px',
    textAlign: 'center',
    color: '#555555',
  },
  subTitle: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '27px',
    textAlign: 'center',
    color: '#24283C',
  },
  linkTitle: {
    fontWeight: '400',
    fontSize: '18px'
  },
  contact: {
    color: '#6361CD',
    cursor: 'pointer'
  },
  link: {
    color: '#6361CD',
    cursor: 'pointer',
    lineHeight: 2
  },
  btnBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    position: 'relative',
    margin: '1rem auto',
    width: '927px',
    height: 'auto',
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end'
  }
}));

const Timer = ({ children, isContent, remainingTime, link }) => {
  const [showBtn, setShowBtn] = useState(false);
  const [time, setTime] = useState(100);
  const classes = useStyles();
  // Random component
  const Completionist = () => <span className={classes.countdown}>You are good to go!</span>;
  // Renderer callback with condition
  const renderer = ({ completed, formatted }) => {
    const { hours, minutes, seconds } = formatted;
    if (completed) {
      // Render a completed state
      return children ? <>{ children }</> : <Completionist />;
    } else {
      // Render a countdown
      return <span className={classes.countdown} >{hours}:{minutes}:{seconds}</span>;
    }
  };

  useEffect(() => {
    if (remainingTime) {
      setTime(Date.now() + remainingTime);
    } else {
      setTime(100);
    }
  }, [remainingTime]);

  useEffect(() => {
    if (remainingTime && remainingTime < 0) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  }, [remainingTime]);

  useEffect(() => {
    return () => {
      setShowBtn(false);
    };
  },[]);
  
  const renderStartButton =(timeLeft) => {
    if (timeLeft < 300000) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  };

  return (
    <Grid container direction='column' justifyContent='center'>
      <Grid item>
        <Typography className={classes.title}>Hang Tight!</Typography>
        <Typography className={classes.subTitle}>Interview will start very soon.</Typography>
      </Grid>
      <Grid item>
        <Box className={classes.rootCountdown}>
          <Countdown
            key={time}
            date={time}
            renderer={renderer}
            daysInHours={true}
            autoStart={true}
            onTick={({ total })=>renderStartButton(total)}
          />
        </Box>
        {showBtn? <Box className={classes.btnBox}>
          <Grid item className={classes.btnContainer} style={{ marginTop: '20px' }}>
            <Button variant="contained" color="secondary">
              Start
            </Button>
          </Grid>
        </Box>
          :
          null}
      </Grid>
      {isContent && <Grid container item direction='column' justifyContent='center' alignItems='center' style={{ marginTop: '20px' }}>
        <Typography className={classes.linkTitle}>Copy the below link to share:</Typography>
        <Typography className={classes.link}>{ link }</Typography>
      </Grid>
      }
      {isContent && <Grid container item direction='column' justifyContent='center' alignItems='center' style={{ marginTop: '20px', marginBottom: '20px' }} >
        <Typography className={classes.linkTitle}>To join by phone instead,
          <a href='#' className={classes.contact}>dial (US) +1 405-695-6097 </a>
          and enter this PIN: 863 615 613# More numbers: https://t.meet/duz-vzja-wbk
        </Typography>
      </Grid>}
    </Grid>
  );
};

export default Timer;

Timer.defaultProps = {
  Component: null,
  remainingTime: 30000,
  isContent: false
};