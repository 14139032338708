import { createAsyncThunk } from '@reduxjs/toolkit';
import { InterviewerActions } from '../actionTypes';
import { confirmSlots, getInterviewerMyScheduleWithFilter, getMySchedule, getSlots, setSchedule } from 'redux/restAPIs/interviewerAPI/interviewerSchedule';

export const onMySchedule = createAsyncThunk(
  InterviewerActions.GET_MY_SCHEDULE,
  async (data) => {
    const response = await getMySchedule(data);
    return response.data;
  });

export const onInterviewerMyScheduleWithFilter = createAsyncThunk(
  InterviewerActions.GET_MY_SCHEDULE_WITH_FILTER,
  async (data) => {
    const response = await getInterviewerMyScheduleWithFilter(data);
    return response.data;
  });
  
export const onSetSchedule = createAsyncThunk(
  InterviewerActions.SET_SCHEDULE,
  async (data) => {
    const response = await setSchedule(data);
    return response.data;
  });

export const onGetInterviewerSlots = createAsyncThunk(InterviewerActions.GET_INTERVIEWER_SLOTS, async ({ startTime, endTime }) => {
  const response = await getSlots({ startTime, endTime });
  return response.data.slots;
});
export const onConfirmSlots = createAsyncThunk(InterviewerActions.CONFIRM_INTERVIEWER_SLOTS, async (body) => {
  const response = await confirmSlots(body);
  return response.data;
});
export const setSlotsConfirmed = createAsyncThunk(InterviewerActions.SET_INTERVIEWER_SLOTS, (data) => {
  return data;
});