import React, { useState, useEffect } from 'react';
import { Typography, Tab, Tabs, makeStyles, Button, Menu } from '@material-ui/core';
import * as R from 'ramda';
import DPTable from 'view/common/DPTable';
import { decisionNestedTabWiseList, decisionTablColumns } from 'constants/employer/decision';
import { Grid } from '@mui/material';
import DecisionFilter from './DecisionFilter';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { onGetDecisionTableData } from 'redux/actions/employerActions/decision';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
    marginBottom: '1rem',
    marginTop: '3rem',
  },
  filterBtn: {
    marginLeft: '100px'
  },
  mainContainer: {
    
  },
}));

const DecisionTable = () => {
  const classes = useStyle();
  const [currentTab, setCurrentTab] = useState(0);
  const employerDetails = useSelector(state => get(state, [''], ''));
  const recentJobsTableData = useSelector(state => get(state, [''], [] ));
  const historyTableData = useSelector(state => get(state, [''], [] ));
  const [entryStatus, setEntryStatus] = useState(currentTab);

  const totalRecentJobsTableData = useSelector(state => get(state, [''], [] ));
  const totalHistoryTableData = useSelector(state => get(state, [''], [] ));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const rowData = currentTab === 0 ? recentJobsTableData : historyTableData;
  const totalElements = currentTab === 0 ? totalRecentJobsTableData : totalHistoryTableData;
  
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
    setEntryStatus(newValue);
    setPageSize(10);
    setPageNumber(0);
  };
  


  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetDecisionTableData({
      entryStatus: entryStatus === 0 ? ['OPEN', 'HIRED', 'EXPIRED'] : ['CLOSED'],
      companyDetailsId: employerDetails.id,
      pageNo: pageNumber,
      pageSize: pageSize,
      sortBy: ['lastUpdatedOn'],
      currentTab: currentTab === 0 ? 'RECENT_JOBS' : 'HISTORY'
    }));
  }, [currentTab, pageNumber, pageSize]);

  return (
    <Grid
      container
      direction="column"
      className={classes.mainContainer}
      spacing={2}>
      <Grid container spacing={0} className={classes.pageTitleContainer} alignItems="baseline">
        <Grid item xs={12}>
          <Typography className={classes.pageTitle}>All Job Posting</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example" >
            {
              R.map((tab) => {
                return <Tab label={tab.label} {...a11yProps(tab.id)} />;
              })(decisionNestedTabWiseList)
            }
          </Tabs>
        </Grid>

        <Grid item xs={12} md={3} style={{ paddingBottom: '2rem', textAlign: 'end' }}> 
          <Button
            onClick={handleOpen}
            color='secondary'
            type="button"
            variant="outlined"
            style={{ marginRight: '1rem' }}
          >Filters</Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <DecisionFilter onClose={handleClose}
              currentTab={currentTab}
              formLabel={decisionNestedTabWiseList[currentTab]} />
          </Menu>
        </Grid>
      </Grid>
      <DPTable
        columns={decisionTablColumns[decisionNestedTabWiseList[currentTab].id]}
        rowsData={rowData}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        totalElements={totalElements || 0}
      />
    </Grid>
  );
};

export default DecisionTable; 