import { getEmpUsers, getInterviewers } from 'redux/actions/employerActions/userManagementActions';

export const addEmpUserMap = {
  user: 'User',
  interviewer: 'Interviewer'
};

export const userManagementTabs = [
  {
    id: 'user',
    label: 'User',
  },
  {
    id: 'interviewer',
    label: 'Interviewer',
  },
];

export const userManagementTabConfigs = {
  user: {
    dataPath: ['userManagement', 'users'],
    pageOptionsPath: ['userManagement', 'pageOptions'],
    dispatcher: getEmpUsers,
    userType: 'user'
  },
  interviewer: {
    dataPath: ['userManagement', 'interviewers'],
    pageOptionsPath: ['userManagement', 'pageOptions'],
    dispatcher: getInterviewers,
    userType: 'interviewer'
  }
};

export const userManagementPageSizeOptions = [
  {
    value: 5,
    label: 5
  },
  {
    value: 10,
    label: 10
  },
  {
    value: 20,
    label: 20
  },
  {
    value: 30,
    label: 30
  }
];

export const userManagementJobPostCols = [
  {
    id: 'title',
    label: 'Job Title',
    minWidth: 100
  },
  {
    id: 'manager',
    label: 'Hiring Manager',
    minWidth: 30
  },
  {
    id: 'location',
    label: 'Location',
    minWidth: 120,
  },
  {
    id: 'positions',
    label: 'Number of Position',
    minWidth: 120,
  },
  {
    id: 'posted',
    label: 'Posted on',
    minWidth: 60,
    align: 'center',
  },
  {
    id: 'applications',
    label: 'Applications',
    minWidth: 120,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 120,
  },
  {
    id: 'action',
    label: 'Actions',
    minWidth: 120,
  },
];

export const userManagementInterviewsCols = [
  {
    id: 'title',
    label: 'Job Title',
    minWidth: 100
  },
  {
    id: 'candidate',
    label: 'Candidate',
    minWidth: 30
  },
  {
    id: 'interviewer',
    label: 'Interviewer',
    minWidth: 120,
  },
  {
    id: 'source',
    label: 'Source',
    minWidth: 120,
  },
  {
    id: 'datetime',
    label: 'Date & Time',
    minWidth: 60,
    align: 'center',
  },
  {
    id: 'interviewRounds',
    label: 'Interview Rounds',
    minWidth: 120,
  },
  {
    id: 'action',
    label: 'Actions',
    minWidth: 120,
  },
];

export const userManagementRecruitedCols = [
  {
    id: 'title',
    label: 'Job Title',
    minWidth: 100
  },
  {
    id: 'candidate',
    label: 'Candidate',
    minWidth: 30
  },
  {
    id: 'source',
    label: 'Source',
    minWidth: 120,
  },
  {
    id: 'interviewRounds',
    label: 'Interview Rounds',
    minWidth: 120,
  },
  {
    id: 'action',
    label: 'Actions',
    minWidth: 120,
  },
];

export const UMTabColumns = {
  jobPost: userManagementJobPostCols,
  interviews: userManagementInterviewsCols,
  recruited: userManagementRecruitedCols
};

export const UMNestedTabWiseList = {
  interviewer: [
    {
      id: 'interviews',
      label: 'Interviews',
    },
  ],
  user: [
    {
      id: 'jobPost',
      label: 'Job Posts',
    },
    {
      id: 'interviews',
      label: 'Interviews',
    },
    {
      id: 'recruited',
      label: 'Recruited',
    },
  ]
};