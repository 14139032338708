import React, { useState, useEffect } from 'react';
import { FieldArray, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, InputLabel, TextField, makeStyles, Button } from '@material-ui/core';
import AutocompleteSelect from 'view/ui-components/atom/AutocompleteSelect';
import { colors } from 'themes/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { onGetAllInterviewers, onGetAllHiringManagers } from 'redux/actions/employerActions/Configurations/jobActions';
import { compact, includes } from 'lodash';

const useStyles = makeStyles(theme => ({
  formContainer: {
    backgroundColor: 'white',
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      marginTop: '2em',
      paddingLeft: '0.25em',
      paddingRight: '0.25em',
      paddingBottom: '1em',
    },
  },
  titleBasicDetail: {
    ...theme.typography.title,
    color: '#24283C',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '30px',
  },
  subHeading: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: colors.gray1,
  },
  button: {
    color: colors.blue1,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '10px',
  },
  deleteButton: {
    color: '#6361CD',
    textTransform: 'none',
  },
  interviewFiled: {
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '4px',
  }
}));

const InterviewDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [hiringManagers, setHiringManager] = useState([]);
  const [interviewers, setInterviewers] = useState([]);
  const [, meta] = useField('hiringManager');
  let { value } = meta;
  const GetAllInterviewers = () => {
    dispatch(onGetAllInterviewers({})).then((response) => {
      if (response && response.payload) {
        const Interviewers = response.payload.map((detail) => {
          return {
            ...detail,
            phone: detail.contactNumber
          };
        });
        setInterviewers(Interviewers);
      }
    });
  };
  const GetAllHiringManagers = () => {
    dispatch(onGetAllHiringManagers({})).then((response) => {
      if (response && response.payload) {
        const HiringManager = response.payload.map((detail) => {
          return {
            ...detail,
            phone: detail.contactNumber
          };
        });
        setHiringManager(HiringManager);
      }
    });
  };

  useEffect(() => {
    GetAllInterviewers();
    GetAllHiringManagers();
  }, []);
  return (
    <>
      <Grid item className={classes.formContainer} container direction="column" spacing={2}>
        <Grid item container direction="column">
          <Grid>
            <Typography variant="h5">{translate('DHIYO.JOBPOSTING_ADD.FORM-03.HEADER.BASIC')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {translate('DHIYO.JOBPOSTING_ADD.FORM-03.HEADER.BASIC_DETAILS')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <InputLabel required>Name</InputLabel>
            <AutocompleteSelect
              // className={classes.interviewFiled}
              dropDownData={hiringManagers}
              label="Select Hiring Manager"
              name="hiringManager"
              displayValue={value?.name || ''}
              id="hiringManager"
              metaError="Hiring Manager is required" />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputLabel>Email ID</InputLabel>
            <TextField value={value ? value.email : ''} disabled fullWidth variant="outlined" name="hiringManagerEmail" type="text" placeholder="Enter Email Id" />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputLabel>Phone</InputLabel>
            <TextField value={value ? value.phone : ''} disabled fullWidth variant="outlined" name="hiringManagerContactNumber" type="text" placeholder="Enter Phone" />
          </Grid>
        </Grid>

        <Grid item container direction="column">
          <Grid>
            <Typography className={classes.titleBasicDetail}>{translate('DHIYO.JOBPOSTING_ADD.FORM-03.HEADER1.BASIC')}</Typography>
          </Grid>

          <Grid item>
            <Typography className={classes.subHeading} variant="body2">
              {translate('DHIYO.JOBPOSTING_ADD.FORM-03.HEADER.BASIC_DETAILS')}
            </Typography>
          </Grid>
        </Grid>

        <FieldArray name="interviewerDetails">
          {({ push, form }) => {
            let interviewerDetails = compact(form.values.interviewerDetails);
            // form.values.interviewerDetails = compact(form.values.interviewerDetails); // form.value.interviewerDetails is non changable or readonly object cannot reassign to it
            if (form.values.interviewerDetails.length === 0) {
              interviewerDetails = [{ id: Math.random(), name: '', email_id: '', phone: '' }];
            }
            const selectedInterviewersList = compact(form.values.interviewerDetails);
            const selectedInterviewerIds = (selectedInterviewersList || []).map(detail => detail.id);
            const unselectedInterviewer = interviewers.filter(intDetails => !includes(selectedInterviewerIds, intDetails.id));
            return (
              <Grid item container alignItems="center">
                {interviewerDetails.map((interviewer, index) => {
                  const name = `interviewerDetails[${index}]`;
                  return interviewer && !interviewer.deleted && (
                    <Grid
                      item
                      container
                      alignItems="center"
                      spacing={2}
                      style={{ marginBottom: '0.5rem' }}>
                   
                      <Grid item xs={12} md={4}>
                        <InputLabel required>Inteviewer Name</InputLabel>
                        <AutocompleteSelect
                          dropDownData={unselectedInterviewer}
                          label={'Select Inteviewer Name'}
                          name={name}
                          displayValue={interviewer?.name || ''}
                          id={name}
                          metaError="Interviewer is required" />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <InputLabel>Email ID</InputLabel>
                        <TextField automation-testid="interviewerEmail" value={interviewer?.email || ''} disabled fullWidth variant="outlined" name="openPositions" type="text" placeholder="Enter Email Id" />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <InputLabel>Phone</InputLabel>
                        <TextField automation-testid="interviewerPhone" value={interviewer?.phone || ''} disabled fullWidth variant="outlined" name="openPositions" type="text" placeholder="Enter Phone" />
                      </Grid>

                      <Grid
                        item
                        xs={1}
                        container
                        justifyContent={matchesSM ? 'flex-end' : 'flex-start'}>
                        {index > 0 ? (
                          <IconButton
                            aria-label="delete"
                            disableRipple
                            onClick={() => interviewer.id ? form.setFieldValue(`interviewerDetails[${index}].deleted`, true) : ''}
                            style={{ padding: 0, top: '8px' }}
                          >
                            <DeleteIcon className={classes.deleteButton} />
                          </IconButton>
                        ) : (
                          ''
                        )}
                      </Grid>
                
                    </Grid>
                  );
                })}
                <Grid item container>
                  <Grid item>
                    <Button
                      size="small"
                      className={classes.button}
                      type="button"
                      disableRipple
                      disabled={interviewerDetails.filter(interviewer => interviewer && !interviewer.deleted).length > 2}
                      onClick={() =>
                        push({
                          id: Math.random(),
                          name: '',
                          email_id: '',
                          phone: '',
                        })
                      }>
                    Add More Interviewer
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </FieldArray>
      </Grid>
    </>
  );
};

export default InterviewDetails;
