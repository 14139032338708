import FileText from 'asset/icons/jobapplied.svg';
import ProfileVisits from 'asset/icons/ProfileVisits.svg';
import Bookmark from 'asset/icons/savedjobs.svg';
import Shortlisted from 'asset/icons/shortlisted.svg';
import InterviewSchedule from 'asset/icons/interviewSchedule.svg';
import Dropped from 'asset/icons/dropped.svg';
import ThumbsUp from 'asset/icons/thumbsup.svg';
import HalfCircle from 'asset/icons/HalfCircle.svg';
import Files from 'asset/icons/Files.svg';

export const ExperienceMap = [
  {
    value: 0,
    label: '1yr',
  },
  {
    value: 20,
    label: '2yr',
  },
  {
    value: 40,
    label: '3yr',
  },
  {
    value: 60,
    label: '4yr',
  },
  {
    value: 80,
    label: '5yr',
  },
  {
    value: 100,
    label: '6+yr',
  },
];

export const SalaryRange = [
  {
    value: 0,
    label: '2',
  },
  {
    value: 20,
    label: '4',
  },
  {
    value: 40,
    label: '6',
  },
  {
    value: 60,
    label: '8',
  },
  {
    value: 80,
    label: '10',
  },
  {
    value: 100,
    label: '12+',
  },
];

export const Compatability = [
  {
    value: 0,
    label: '10',
  },
  {
    value: 12,
    label: '20',
  },
  {
    value: 24,
    label: '30',
  },
  {
    value: 36,
    label: '40',
  },
  {
    value: 48,
    label: '50',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 70,
    label: '70',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 90,
    label: '90',
  },
  {
    value: 100,
    label: '100',
  },
];

export const Location = [
  {
    label: 'Pune',
  },
  {
       
    label: 'Mumbai',
  },
  {
    label: 'Beed',
  },
  {
      
    label: 'Solapur',
  },
  {
       
    label: 'Nagpur',
  },
  {
    label: 'Patna',
  },
];

export const PostedOn = [
  {
    label: 'Recently',
  },
  {
       
    label: 'Week Ago',
  },
  {
    label: '15 days Ago',
  },
  {
        
    label: 'One Month Ago',
  },
  {
    label: 'Six Months Ago',
  },
  {
    label: '1 Year Ago',
  },
];

export const HeaderCards_Data = [
  {
    id: 'one',
    icon: FileText,
    title: 'Jobs Applied',
    number: '10'

  },
  {
    id: 'two',
    icon: ProfileVisits,
    title: 'Profile Visits',
    number: '120'
  },
  {
    id: 'three',
    icon: Bookmark,
    title: 'Saved Jobs',
    number: '5'
  },
  {
    id: 'four',
    icon: Shortlisted,
    title: 'Shortlisted',
    number: '10'
  },
];

export const JrHeaderCards_Data = [
  {
    id: 'one',
    icon: ThumbsUp,
    title: 'Recommendations',
    number: '10'

  },
  {
    id: 'two',
    icon: FileText,
    title: 'Applied',
    number: '15'
  },
  {
    id: 'three',
    icon: Shortlisted,
    title: 'Shortlisted',
    number: '25'
  },
];

export const HeaderCards_Data_Employer = [
  {
    id: 'TOTAL',
    icon: FileText,
    title: 'Total Applications',
    number: '800'

  },
  {
    id: 'SHORTLISTED',
    icon: Shortlisted,
    title: 'Shortlisted Candidates',
    number: '500'
  },
  {
    id: 'INTERVIEW',
    icon: InterviewSchedule,
    title: 'Interview Schedule',
    number: '254'
  },
  {
    id: 'ARCHIVED',
    icon: Dropped,
    title: 'Dropped Candidates',
    number: '25'
  },
];

export const Descision_data = [
  {
    id: 'one',
    icon: Files,
    title: 'Job Postings',
    number: '80'

  },
  {
    id: 'two',
    icon: HalfCircle,
    title: 'Hirings',
    number: '150'
  },
  {
    id: 'three',
    icon: InterviewSchedule,
    title: 'Interviews',
    number: '5'
  }
];