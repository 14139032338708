import React from 'react';
import { Grid, Box, makeStyles, Typography,
  AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import question from 'asset/icons/accQuestion.svg';
import report from 'asset/icons/accReport.svg';
import notes from 'asset/icons/accNotes.svg';
import InterviewReport from '../../../employer/pages/JobPosting/ApplicantProfile/InterviewReport';
import StaticQuestions from '../../../employer/pages/JobPosting/ApplicantProfile/StaticQuestions';
import HrNotes from '../../../employer/pages/JobPosting/ApplicantProfile/HrNotes';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
  },
  accordian: {
    background: '#EEF3F9',
    border: '1px solid #6361CD',
    boxShadow: 'none',
    borderRadius: '50px'
  },
  '&.Mui-expanded': {
    margin: '10px 0'
  },
  accordDetails: {
    background: 'white', marginTop: '1rem', padding: '28px', borderRadius: 4, display: 'block'
  },
}));

const OtherDetails = () => {
  const classes = useStyle();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>

      {/* Static Questions */}
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: '1rem' }}>
          <Accordion
            style={{ background: 'transparent', boxShadow: 'none' }}
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              className={classes.accordian}
              disableElevation
              expandIcon={<ArrowDropDownIcon fontSize='large' color='secondary' />}
              aria-controls="panel1a-content"
              id="panel1a-header"  
            >
              <img src={question} alt='pinterest Icon' style={{ width: '35px', marginRight: '0.5rem' }} />
              <Typography variant="h6" style={{ alignSelf: 'center' }}><b>Static Questions</b></Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.accordDetails}>
              <StaticQuestions />
            </AccordionDetails>
          </Accordion>  
        </Grid>
      </Grid>

      {/* Interview Report */}
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: '1rem' }}>
          <Accordion
            style={{ background: 'transparent', boxShadow: 'none' }}
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              className={classes.accordian}
              disableElevation
              expandIcon={<ArrowDropDownIcon fontSize='large' color='secondary' />}
              aria-controls="panel2a-content"
              id="panel2a-header"  
            >
              <img src={report} alt='pinterest Icon' style={{ width: '35px', marginRight: '0.5rem' }} />
              <Typography variant="h6" style={{ alignSelf: 'center' }}><b>Interview Report</b></Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.accordDetails}>
              <InterviewReport />
            </AccordionDetails>
          </Accordion>  
        </Grid>
      </Grid>

      {/* HR Notes */}
      <Grid container>
        <Grid item xs={12}>
          <Accordion
            style={{ background: 'transparent', boxShadow: 'none' }}
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary
              className={classes.accordian}
              disableElevation
              expandIcon={<ArrowDropDownIcon fontSize='large' color='secondary' />}
              aria-controls="panel1a-content"
              id="panel1a-header"  
            >
              <img src={notes} alt='pinterest Icon' style={{ width: '35px', marginRight: '0.5rem' }} />
              <Typography variant="h6" style={{ alignSelf: 'center' }}><b>HR Notes</b></Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.accordDetails}>
              <Grid container>
                <Grid item xs={12}>
                  <HrNotes />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>  
        </Grid>
      </Grid>
    </Box>
  );
};
export default OtherDetails;