import React from 'react';
import * as yup from 'yup';
import { YupValidator } from '../../../../../utils/YupValidator';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Formik, Form, Field } from 'formik';
import { Grid, Button, Paper, InputLabel } from '@material-ui/core'; 
import { useTheme } from '@material-ui/core/styles';
import { FormikInputField } from '../../../../ui-components';
import { CheckboxWithLabel } from 'formik-material-ui';

// const useStyles = makeStyles(() => ({
// }));

export default function AddFeedbackQuestionsForm(props) {
  // const classes = useStyle();
  const { addOrEdit, recordForEdit } = props;
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const handleSubmit = async (values, actions) => {
    addOrEdit(values, () => {
      actions.setTouched({});
    });
  };
  const onCancelClick = (formik) => {
    const isEdit = recordForEdit && recordForEdit.id;
    if (isEdit !== '') {
      props.onCancel();
    } else {
      formik.resetForm();
    }
  };
  const validationSchema = yup.object({
    question: YupValidator('Required')
  });

  return (
    <Paper elevation={0}>
      <Formik
        initialValues={recordForEdit}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {formik => (
          <Form>
            <Grid
              container
              spacing={1}
              direction={matchesMD ? 'column' : 'row'}>
              <Grid item md={matchesMD ? 12 : 12}>
                <InputLabel>Question</InputLabel>
                <FormikInputField
                  localePrefix="DHIYO.CONFIGURATION.INTERVIEW_QUESTIONS.FORM.ADD_QUESTION"
                  name="question" />
                <Field
                  style={{ fontFamily: 'poppins', padding: '5px 5px 5px 8px' }}
                  type="checkbox"
                  component={CheckboxWithLabel}
                  name="isRatingApplicable"
                  Label={{ label:  'Rating applicable' }} />
              </Grid>
              
              {matchesMD ? <Grid item md={9} /> : ''}
              <Grid item md={12} container justifyContent={'flex-end'}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={e => onCancelClick(formik, e)}
                  type="button"
                  style={{ marginRight: 10 }}
                  disableRipple>
                  {recordForEdit && recordForEdit.id !== ''
                    ? 'Cancel'
                    : 'Reset'}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: 10 }}
                  disableRipple
                  type="submit">
                  {recordForEdit && recordForEdit.id !== ''
                    ? 'Update'
                    : 'Add'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}
