import { createAsyncThunk } from '@reduxjs/toolkit';
import { ADD_INTERVIEW_QUESTIONS_SUCCESS, EDIT_INTERVIEW_QUESTIONS_SUCCESS, GET_ALL_INTERVIEW_QUESTIONS_SUCCESS, DELETE_INTERVIEW_QUESTIONS_SUCCESS } from 'constants';
import { addInterviewQuestion, deleteInterviewQuestion, editInterviewQuestion, getInterviewQuestionList } from 'redux/restAPIs/employerAPI/interviewQuestions';

export const onAddInterviewQuestion = createAsyncThunk(
  ADD_INTERVIEW_QUESTIONS_SUCCESS,
  async (payload) => {
    const response = await addInterviewQuestion(payload);
    return response.data;
  }
);

export const onEditInterviewQuestion = createAsyncThunk(
  EDIT_INTERVIEW_QUESTIONS_SUCCESS,
  async (payload) => {
    const response = await editInterviewQuestion(payload);
    return response.data;
  }
);

export const onGetInterviewQuestionList = createAsyncThunk(
  GET_ALL_INTERVIEW_QUESTIONS_SUCCESS,
  async (payload) => {
    const response = await getInterviewQuestionList(payload);
    return response.data;
  }
);

export const onDeleteInterviewQuestion = createAsyncThunk(
  DELETE_INTERVIEW_QUESTIONS_SUCCESS,
  async (payload) => {
    const response = await deleteInterviewQuestion(payload);
    return response.data;
  }
);