import { Box, Container, Grid, Typography, Breadcrumbs } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import JobDetails from 'view/applicant/pages/CandidateProfile/JobDetails';
import AppliedCandidates from 'view/employer/pages/JobPosting/ApplicantProfile/AppliedCandidates';
import RecommendedCandidates from 'view/employer/pages/JobPosting/ApplicantProfile/RecommendedCandidates';

const JobDetailsContainer = () => {
  return (
    <Container style={{ marginTop: '5rem', marginBottom: '5rem' }}>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="secondary"
                to="/employer/jobposting"
                className={'MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary'}>
                Job Posting
              </Link>
              <Typography variant="subtitle2">
                Job Details
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <JobDetails />
          </Grid>
          <Grid item xs={12} md={4} style={{ marginTop: '-15px' }}>
            <RecommendedCandidates />
            <AppliedCandidates />
          </Grid>
        </Grid>

      </Box>
    </Container>
  );
};

export default JobDetailsContainer;