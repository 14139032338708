import React, { useEffect } from 'react';
import { Grid, IconButton, Box, Typography, makeStyles } from '@material-ui/core';
import { onGetAppliedJobs } from 'redux/actions/employerActions/employerDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';
import blackArrow from 'asset/blackArrow.svg';
import { formatCandidateData } from 'utils/dataFormatters';
import CandidateCard from 'view/common/components/CandidateCard/CandidateCard';

const useStyle = makeStyles(theme => ({
  pageTitle: {
    ...theme.typography.title,
  },
  iconBtn: {
    padding: '7px'
  },
  jobCard: {
    marginBottom: '1.3rem',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    padding: '0.5rem 0'
  },
  percent: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'right',
    color: '#FF5B15',
    marginTop: '0.3rem'
  },
  smallBtn: {
    fontWeight: 100,
    marginTop: '0.5rem'
  }
}));

// AppliedCandidates

const AppliedCandidates = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const data = useSelector(state => get(state, ['employerDashboard', 'jobApplicants', 'content'], []));

  useEffect(() => {
    dispatch(onGetAppliedJobs({
      id: params.jobId,
      pageNo: 0,
      pageSize: 10
    }));
  }, []);

  const onArrowClick = (tabId) => {
    navigate(`/employer/activity/${params.jobId}`,{
      state: {
        currentTab: 'Applied',
        tabId
      }
    });
  };

  return (
    <Box sx={{
      paddingTop: '37px'
    }}>

      <Grid container xs={12} alignItems="center" style={{ marginBottom: '0.6rem' }}>
        <Grid item xs={10} >
          <Typography className={classes.pageTitle}>Applied ({get(data, ['applied'], 0)})
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'end' }}>
          <IconButton className={classes.iconBtn}>
            <img src={blackArrow} alt='Arrow Icon' onClick={() => onArrowClick('applied')} />
          </IconButton>
        </Grid>
      </Grid>
      {(formatCandidateData(data.slice(0, 4))).map((item) => {
        return (
          <CandidateCard data={item} />
        );
      })}
    </Box>
  );
};
export default AppliedCandidates;