import React from 'react';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';

const defaultFormatter = (row, column) => {
  return column.dataPath ? get(row, column.dataPath, '') : row[column.id];
};

const DefaultCellRenderer = ({ column, row }) => {
  const { formatter } = column;
  const value = formatter ? formatter(row) : defaultFormatter(row, column);
  return <Typography>{value}</Typography>;
};

export default DefaultCellRenderer;