/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { onAddJobTitle,onDeleteJobTitle,onEditJobTitle,onGetAllJobTitleList,onGetJobTitleList } from 'redux/actions/employerActions/Configurations/jobActions';

const initialState = {
  jobTitles: [],
  status: 'idle',
  error: null,
  allJobTitles: null,
};

const addJobTitles = createSlice({
  name: 'addJobTitles',
  initialState,
  reducers: {},
  extraReducers: {
    // add location
    [onAddJobTitle.pending]: (state) => {
      state.status = 'loading';
    },
    [onAddJobTitle.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onAddJobTitle.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    // list all locations
    [onGetJobTitleList.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetJobTitleList.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onGetJobTitleList.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    // edit location
    [onEditJobTitle.pending]: (state) => {
      state.status = 'loading';
    },
    [onEditJobTitle.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onEditJobTitle.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    // delete location
    [onDeleteJobTitle.pending]: (state) => {
      state.status = 'loading';
    },
    [onDeleteJobTitle.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [onDeleteJobTitle.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    // Get all job title list
    [onGetAllJobTitleList.pending]: (state) => {
      state.status = 'loading';
    },
    [onGetAllJobTitleList.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.allJobTitles = action.payload;
    },
    [onGetAllJobTitleList.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default addJobTitles.reducer;
