import { createSlice } from '@reduxjs/toolkit';
import { 
  onGetAllJobs, 
  onGetAppliedJobs, 
  onGetJobDetailsById, 
  onGetJobsCriteria, 
  onGetProfileDetails, 
  setSimilarCandidateFlag, 
  onGetCandidates,
  onGetCandidatesRecommended,
  onGetShortlistedCandidates,
  onShortList,
  onGetStatsData
} from 'redux/actions/employerActions/employerDashboard';
import { onCustomMySchedule } from 'redux/actions/employerActions/interviewScheduled';


const initialState = {
  jobs: {
    content: [],
    status: 'idle',
    error: null,
  },
  jobDetailsById: {
  },
  jobsByCriteria: {
  },
  jobApplicants: {
  },
  candidatesData: {
    recommended: {
      content: []
    },
    applied: {
      content: []
    },
    shortlisted: {
      content: []
    },
  },
  applicantsProfileById: {
  },
  similarCandidate: {
    status: null,
    isSimilarCandidate: false
  },
  shortListed: false,
  employerMySchedule: {
    
  },
  statsDetails: {

  }
};

const employerDashboard = createSlice({
  name: 'getJobs',
  initialState,
  reducers: {},
  extraReducers: {
    /* All Job Details */
    [onGetAllJobs.pending]: (state) => {
      state.jobs.status = 'loading';
      state.shortListed = false;
    },
    [onGetAllJobs.fulfilled]: (state, action) => {
      state.jobs.status = 'succeeded';
      state.jobs = action.payload;
      state.shortListed = false;
    },
    [onGetAllJobs.rejected]: (state, action) => {
      state.jobs.status = 'failed';
      state.jobs.error = action.payload;
      state.shortListed = false;
    },
    /* Job Details*/
    [onGetJobDetailsById.pending]: (state) => {
      state.jobDetailsById.status = 'loading';
    },
    [onGetJobDetailsById.fulfilled]: (state, action) => {
      state.jobDetailsById.status = 'succeeded';
      state.jobDetailsById = action.payload;
    },
    [onGetJobDetailsById.rejected]: (state, action) => {
      state.jobDetailsById.status = 'failed';
      state.jobDetailsById.error = action.payload;
    },
    /* Job Criteria */
    [onGetJobsCriteria.pending]: (state) => {
      state.jobsByCriteria.status = 'loading';
    },
    [onGetJobsCriteria.fulfilled]: (state, action) => {
      state.jobsByCriteria.status = 'succeeded';
      const byCriteria = {
        ...state.jobsByCriteria
      };
      const { meta: { arg: { currentTab } } } = action;
      byCriteria[currentTab] = action.payload;
      state.jobsByCriteria = byCriteria;
    },
    [onGetJobsCriteria.rejected]: (state, action) => {
      state.jobsByCriteria.status = 'failed';
      state.jobsByCriteria.error = action.payload;
    },

    /* Applied Jobs */
    [onGetAppliedJobs.pending]: (state) => {
      state.jobApplicants.status = 'loading';
    },
    [onGetAppliedJobs.fulfilled]: (state, action) => {
      state.jobApplicants.status = 'succeeded';
      state.jobApplicants = action.payload;
    },
    [onGetAppliedJobs.rejected]: (state, action) => {
      state.jobApplicants.status = 'failed';
      state.jobApplicants.error = action.payload;
    },

    /* Applied Candidates */
    [onGetCandidates.pending]: (state) => {
      state.candidatesData.status = 'loading';
    },
    [onGetCandidates.fulfilled]: (state, action) => {
      state.candidatesData.status = 'succeeded';
      state.candidatesData.applied = action.payload;
    },
    [onGetCandidates.rejected]: (state, action) => {
      state.candidatesData.status = 'failed';
      state.candidatesData.error = action.payload;
    },

    /* Shortlist */
    [onShortList.pending]: (state) => {
      state.status = 'loading';
    },
    [onShortList.fulfilled]: (state) => {
      state.status = 'succeeded';
      state.shortListed = true;
    },
    [onShortList.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    /* Recommended */
    [onGetCandidatesRecommended.pending]: (state) => {
      state.candidatesData.status = 'loading';
    },
    [onGetCandidatesRecommended.fulfilled]: (state, action) => {
      state.candidatesData.status = 'succeeded';
      state.candidatesData.recommended = action.payload;
    },
    [onGetCandidatesRecommended.rejected]: (state, action) => {
      state.candidatesData.status = 'failed';
      state.candidatesData.error = action.payload;
    },

    /* GET Shortlisted */
    [onGetShortlistedCandidates.pending]: (state) => {
      state.candidatesData.status = 'loading';
    },
    [onGetShortlistedCandidates.fulfilled]: (state, action) => {
      state.candidatesData.status = 'succeeded';
      state.candidatesData.shortlisted = action.payload;
    },
    [onGetShortlistedCandidates.rejected]: (state, action) => {
      state.candidatesData.status = 'failed';
      state.candidatesData.error = action.payload;
    },

    /* Employer Custom My Schedule */
    [onCustomMySchedule.pending]: (state) => {
      state.status = 'loading';
    },
    [onCustomMySchedule.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.employerMySchedule = action.payload;
    },
    [onCustomMySchedule.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    /* Profile Details */
    [onGetProfileDetails.pending]: (state) => {
      state.applicantsProfileById.status = 'loading';
    },
    [onGetProfileDetails.fulfilled]: (state, action) => {
      const { meta: { arg: { id } } } = action;
      state.applicantsProfileById.status = 'succeeded';
      state.applicantsProfileById[id] = action.payload;
    },
    [onGetProfileDetails.rejected]: (state, action) => {
      state.profileDetails.status = 'failed';
      state.applicantsProfileById.error = action.payload;
    },
    [setSimilarCandidateFlag.fulfilled]: (state, action) => {
      state.similarCandidate.status = 'succeeded';
      state.similarCandidate.isSimilarCandidate = action.payload;
    },
    [setSimilarCandidateFlag.rejected]: (state, action) => {
      state.similarCandidate.status = 'failed';
      state.similarCandidate.error = action.payload;
    },
    /* job card  */

    [onGetStatsData.pending]: (state) => {
      state.statsDetails.status = 'loading';
    },
    [onGetStatsData.fulfilled]: (state, action) => {
      state.statsDetails.status = 'succeeded';
      state.statsDetails = { ...state.statsDetails, ...action.payload };
    },
    [onGetStatsData.rejected]: (state, action) => {
      state.statsDetails.status = 'failed';
      state.statsDetails.error = action.payload;
    },
  },
});

export default employerDashboard.reducer;  