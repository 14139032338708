import { createAsyncThunk } from '@reduxjs/toolkit';
import { addQuestion,editQuestion,deleteQuestion,getQuestionsList } from 'redux/restAPIs/employerAPI/staticQuestions';
import { ADD_QUESTIONS_SUCCESS,EDIT_QUESTIONS_SUCCESS,GET_ALL_QUESTIONS_SUCCESS,DELETE_QUESTIONS_SUCCESS } from '../../../../constants/index';
export const onAddQuestion = createAsyncThunk(
  ADD_QUESTIONS_SUCCESS,
  async (payload) => {
    const response = await addQuestion(payload);
    return response.data;
  }
);

export const onEditQuestion = createAsyncThunk(
  EDIT_QUESTIONS_SUCCESS,
  async (payload) => {
    const response = await editQuestion(payload);
    return response.data;
  }
);

export const onGetQuestionList = createAsyncThunk(
  GET_ALL_QUESTIONS_SUCCESS,
  async (payload) => {
    const response = await getQuestionsList(payload);
    return response.data;
  }
);

export const onDeleteQuestion = createAsyncThunk(
  DELETE_QUESTIONS_SUCCESS,
  async (payload) => {
    const response = await deleteQuestion(payload);
    return response.data;
  }
);