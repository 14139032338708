import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../themes/colors';

const PercentageBar = ({
  children,
  percent,
  height,
  variant,
  baseColor,
  filledColor,
  borderColor,
  showBorder,
  outerBarClasses,
  innerBarClasses,
}) => {
  return (
    <OuterBar
      percent={percent}
      variant={variant}
      height={height}
      baseColor={baseColor}
      borderColor={borderColor}
      showBorder={showBorder}
      className={outerBarClasses}>
      <InnerBar
        percent={percent}
        height={height}
        variant={variant}
        filledColor={filledColor}
        showBorder={showBorder}
        className={innerBarClasses}>
        {children}
      </InnerBar>
    </OuterBar>
  );
};

const OuterBar = styled.div`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  border: ${({ showBorder, borderColor }) =>
    `${showBorder && `1px solid ${borderColor}`}`};
  background: ${({ baseColor }) => baseColor};
  border-radius: 15px;
`;

const InnerBar = styled.div`
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0px;
  text-align: start;
  width: ${({ percent }) => `${percent}%`};
  background-color: ${({ filledColor }) => filledColor};
  height: ${({ showBorder, height }) =>
    `${showBorder ? height - 2 : height}px`};
  border-radius: 15px;
`;

export default PercentageBar;

PercentageBar.propTypes = {
  children: PropTypes.node,
  percent: PropTypes.number.isRequired,
  height: PropTypes.number,
  variant: PropTypes.string,
  baseColor: PropTypes.string,
  borderColor: PropTypes.string,
  showBorder: PropTypes.bool,
  innerBarClasses: PropTypes.string,
  outerBarClasses: PropTypes.string,
};

PercentageBar.defaultProps = {
  height: 25,
  variant: 'default',
  baseColor: colors.background3,
  borderColor: colors.background3,
  showBorder: false,
  filledColor: colors.blue1,
  innerBarClasses: '',
  outerBarClasses: '',
};
