import { endPoints } from 'constants/employerEndpoints';
import { callApi } from 'middleware/api';

export const getInterviewScheduledCalls = (data) => {
  const { pageNo, pageSize } = data;
  return callApi(data, 'GET', `${endPoints.interviewScheduledCalls}?pageNo=${pageNo}&pageSize=${pageSize}`, true);
};

export const getCandidateInfo = (data) => {
  const { id } = data;
  return callApi(data, 'GET', `${endPoints.candidateByJobProfiles}${id}/SHORTLISTED`, true);
};

export const getCustomMySchedule = (data) => {
  const { startDate, endDate } = data;
  return callApi(null, 'GET', `${endPoints.customMySchedule}?startDate=${startDate}&endDate=${endDate}`, true);
};

export const getInterviewSlots = (data) => {
  return callApi(null, 'GET', `${endPoints.getInterviewSlots}?startTime=${data.startDate}&endTime=${data.endDate}`, true);
};

export const shareSlot = (data) => {
  return callApi(data, 'POST', `${endPoints.shareSlot}`, true);
};

export const shareSlotCandidate = (data) => {
  return callApi(data, 'POST', `${endPoints.shareSlotCandidate}`, true);
};
