/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { onForgotPassword, onRegisterNow, onResendOtp, onSignIn, onSignOut, onVerfiyEmail } from 'redux/actions/userAuthentication';
import { userAuthenticationState } from '../initialStates/userAuthenticationState';

export const userAuthenticationSlice = createSlice({
  name: 'userAuthentication',
  initialState: userAuthenticationState,
  reducers: {
    onLogin: (state) => {
      return state;
    },
  },
  extraReducers: {
    [onSignIn.fulfilled]: (state, action) => {
      console.log('test', state, action);
      // Add user to the state array
    },
    [onSignIn.rejected]: (state, action) => {
      console.log('test rejected', state, action);
      // state.status = 'failed';
      // Add user to the state array
    },

    [onSignOut.fulfilled]: (state, action) => {
      console.log('signedOut', state, action);
      // Add user to the state array
    },
    [onSignOut.rejected]: (state, action) => {
      console.log('signOut rejected', state, action);
      // state.status = 'failed';
      // Add user to the state array
    },

    [onRegisterNow.fulfilled]: (state, action) => {
      console.log('test', state, action);
    },
    [onRegisterNow.rejected]: (state, action) => {
      console.log('test', state, action);
    },

    [onVerfiyEmail.fulfilled]: (state, action) => {
      if (get(action, ['payload'], '') === 'Email Verified Successfully') {
        state.currentRegisterStep = 2;
      }
    },
    [onVerfiyEmail.rejected]: (state, action) => {
      console.log('test', state, action);
    },

    [onResendOtp.fulfilled]: (state, action) => {
      console.log('test', state, action);
    },
    [onResendOtp.rejected]: (state, action) => {
      console.log('test', state, action);
    },

    // on forgot password
    [onForgotPassword.fulfilled]: (state, action) => {
      console.log('onForgotPassword', state, action);
    },
    [onForgotPassword.rejected]: (state, action) => {
      console.log('onForgotPassword', state, action);
    }
  }
});

// Action creators are generated for each case reducer function
export const { onLogin } =
  userAuthenticationSlice.actions;

export default userAuthenticationSlice.reducer;
